import React from "react";
import {
  CheckboxContainer,
  ChecklistContainer,
  ChecklistLabel,
  HiddenCheckbox,
  Label,
  StyledCheckbox,
} from "./Styles";

type PropT = {
  checked: boolean;
  label: string;
  onChange: Function;
  id: number | string;
  disabled?: boolean;
  defaultChecked?: boolean;
};

export default function SharedCheckList({
  checked,
  label,
  onChange,
  id,
  disabled,
  defaultChecked
}: PropT) {
  return (
    <ChecklistContainer disabled={disabled}>
      <div>
        <CheckboxContainer>
          <HiddenCheckbox onChange={() => onChange(id)}  checked={!checked} />
          <Label htmlFor={HiddenCheckbox} checked={checked} />
          <StyledCheckbox onChange={() => onChange(id)} checked={!checked} />
        </CheckboxContainer>
        <ChecklistLabel onClick={() => onChange(id)}>{label}</ChecklistLabel>
        
      </div>
    </ChecklistContainer>
  );
}

