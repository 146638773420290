import { useHistory } from 'react-router-dom'
import {
	ParentCont,
	ActionButton2,
	ReportBody,
	SubTitle2,
	SubTitle3,
} from './Styles'
import Success from 'App/assets/images/Auth/success.svg'
import {
	setBusinessLists,
	setCurrentBusiness,
} from 'store/actions/businessAction'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { setCurrentUser } from 'store/actions/userAction'
import { setKycBoardingRec } from 'store/actions/boardingAction'
import { setKycStartAddress } from 'store/actions/kycstartAction'
import { useState } from 'react'
import consoleLog from 'shared/utils/consoleLog'
import { FaLastfmSquare } from 'react-icons/fa'

import ConfettiExplosion, { ConfettiProps } from './confetti';
import { getLastApiCallTime, removeLastApiCallTime } from 'shared/utils/authToken'


const largeProps: ConfettiProps = {
	force: 0.8,
	duration: 3000,
	particleCount: 300,
	width: 1600,
	colors: ['#041E43', '#1471BF', '#5BB4DC', '#FC027B', '#66D805'],
  };

function GatewayComplete() {
	const businesses = useSelector((store: RootStateOrAny) => store.businesses)
	const [loading, setLoading] = useState<boolean>(false)
	const [isLargeExploding, setIsLargeExploding] = useState(false);
	const dispatch = useDispatch()

	const history = useHistory()
	const continueEvent = async () => {
		setLoading(true)
		await (async () => {
			await dispatch(setCurrentUser());
			await dispatch(setBusinessLists())
			await dispatch(setKycBoardingRec())
			await dispatch(setKycStartAddress(''))
			setLoading(false)
			const lastCallDetails = getLastApiCallTime();
			if (lastCallDetails && lastCallDetails.path) {
				window.location.href = lastCallDetails.path;
				// removeLastApiCallTime();
			} else {
				history.push('/overview')
			}
			
		})()
	}
	return (
		<ParentCont>
			
			
			<ReportBody>
				<ConfettiExplosion {...largeProps} />
				<img src={Success} alt='Success' />
				<SubTitle2>Congratulations</SubTitle2>
				<SubTitle3>You have successfully completed your registration</SubTitle3>
				<ActionButton2 disabled={loading} onClick={continueEvent}>
					Go To Dashboard
				</ActionButton2>
			</ReportBody>
		</ParentCont>
	)
}

export default GatewayComplete

function dispatch(arg0: any) {
	throw new Error('Function not implemented.')
}
