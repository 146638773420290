import styled, { css } from "styled-components";

import { color, sizes } from "shared/utils/styles";
import { Button, Form } from "shared/components";
import breakpoints from "shared/utils/breakpoints";

import BreakPoints from "shared/utils/Breakpoint";

type btnPropT = {
  isClicked: boolean;
};
type Props = {
  removeLastchildBorder?: boolean;
  type?: string;
};
export const Layout = styled.div`
  display: block;
  /* flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap; */
  width: 100%;
  height: auto;
  flex-shrink: 0;
  border-radius: 20px;
  background: #fff;
  padding: 25px;
  gap: 25px;

  @media ${breakpoints.device.max.xs} {
    flex-direction: column;
  }

  @media ${breakpoints.device.max.sm} {
    flex-direction: row;
    padding: 5px;
  }
`;

export const LeftLayoutPanel = styled.div`
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.8);
  border: 0px solid rgba(0, 0, 0, 0.8);
  font-size: 30px;
  text-align: center;
  gap: 15px;
  width: 100%;
  @media ${breakpoints.device.max.xs} {
    padding: 0;
    width: 100%;
  }
`;

export const RightLayoutPanel = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 0.8);
  border: 0px solid rgba(0, 0, 0, 0.8);
  font-size: 30px;
  text-align: center;
  gap: 15px;
  width: 100%;
  margin-top:20px;

  @media ${breakpoints.device.max.xs} {
    flex-direction: column;
    width: 100%;
  }
`;

export const BottomLayoutPanel = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 0.8);
  border: 0px solid rgba(0, 0, 0, 0.8);
  font-size: 30px;
  text-align: center;
  gap: 15px;
  width: 100%;
  margin-top:20px;
  overflow-x: scroll;

  @media ${breakpoints.device.max.xs} {
    flex-direction: column;
    width: 100%;
  }
`;

export const Panels = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 50%;

  @media ${breakpoints.device.max.xs} {
    flex-direction: column;
    width: 100%;
  }
`;
export const PanelTitle = styled.div`
  text-align: left;
  font-size: 18px;
  margin: 10px;
  color: var(--primary-black, #292929);
  /*font-family: KumbhSansNormal;*/
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.32px;
  font-weight: 700;
`;

export const SettlementSummary=styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  width: 38% !important;
  height: 134px;
  padding: 10px 24px;
  justify-content: space-around;
  align-items: center;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid #d7d7d7;
  & > :first-child {
    color: var(--primary-black, #292929);
    text-align: left;
    width: 100%;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  & > :nth-child(2) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100% !important;
  }
  @media ${breakpoints.device.max.sm} {
    width: 310px !important;
    padding: 10px 24px;
  }

  @media ${breakpoints.device.max.xs} {
    padding: 0;
    width: 100% !important;
    padding: 5px;
  }
`
export const AmountSummary = styled.div`
  
  width: 60% !important;
  height: 134px;
  padding: 10px 24px;
  border-radius: 10px;
  border: 1px solid #d7d7d7;

  &>div{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 2px;
    justify-content: space-around;
    align-items: center;
    flex-shrink: 0;

    & > :first-child {
      color: var(--primary-black, #292929);
      text-align: left;
      width: 100%;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    & > :nth-child(2) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100% !important;
    }
  }
  

  & > .overlay{
    width: 100%;
    height: 100%;
    position: relative;
    left: 50%;
    top: 50%;
    border-radius: 10px;
    background-color: rgba(98, 0, 1, 0.3);
    transform: translate(-50%, -50%);
    /* backdrop-filter: blur(0.2rem);
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2); */
  }
  @media ${breakpoints.device.max.sm} {
    width: 310px !important;
    padding: 10px 24px;
  }

  @media ${breakpoints.device.max.xs} {
    padding: 0;
    width: 100% !important;
    padding: 5px;
  }
`;

export const MainAmount = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 11px;
  & > .title {
    color: var(--primary-black, #292929);
    text-align: left;
    width: 100%;

    /* Body Text/16px/regular 
        font-family: KumbhSansNormal;*/
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    @media ${breakpoints.device.max.xs} {
      width: 100% !important;
    }
  }
  & > .amount {
    color: #760000;
    text-align: center;
    /*font-family: KumbhSansNormal;*/
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;

export const MainNumber = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 7px;
  margin-top: 15px;

  .main {
    color: var(--primary-black, #292929);
    text-align: center;
    /*font-family: KumbhSansNormal;*/
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .title {
    color: var(--primary-black, #292929);
    text-align: center;
    /*font-family: KumbhSansNormal;*/
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

export const GraphAreaLayout = styled.div`
  width: 100%;
  min-height: 300px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid #d7d7d7;

  @media ${breakpoints.device.max.sm} {
    width: 310px !important;
  }

  @media ${breakpoints.device.max.xs} {
    padding: 0;
    width: 100% !important;
  }
`;
export const InnerPanelLayout = styled.div`
  width: 30% !important;
  min-height: 305px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid #d7d7d7;

  @media ${breakpoints.device.max.sm} {
    width: 260px !important;
  }

  @media ${breakpoints.device.max.xs} {
    padding: 0;
    width: 100% !important;
  }
`;

export const ContentHeader = styled.div`
  width: 100%;
  height: 40px;
  flex-shrink: 0;
  background: #fbfbfb;
  color: var(--primary-black, #292929);
  /* Body Text/16px/regular
    font-family: KumbhSansNormal; */
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  display: flex;
  justify-content: space-between;
  padding: 10px;
`;

export const ContentRow = styled.div<Props>`
  width: 100%;
  // height: 40px;
  height: 45px;
  flex-shrink: 0;
  background: #fff;
  border-bottom: 0.2px solid var(--body-color-grey, #828282);
  color: var(--primary-black, #292929);
  /* Body Text/16px/regular
    font-family: KumbhSansNormal; */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  overflow: auto;

  &:last-child {
    ${(props) =>
      props.removeLastchildBorder &&
      css`
        border-bottom: 0px;
      `}
  }
`;

export const ViewAll = styled.div`
  color: #760000;
  /*font-family: KumbhSansNormal;*/
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  margin-top: 5px;
  cursor: pointer;
`;

export const ServiceTabsCont = styled.div`
  width: 100%;
  /* border: 1px solid #ccc; */
  padding: 0.4rem 0.88rem;
  background: #fff;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  gap: 1.5rem;

  span {
    font-size: 0.88rem;
    cursor: pointer;
    font-weight: 500;
    :hover {
      color: #760000;
    }
  }
`;

export const LoadingCont = styled.div`
  margin-top: 4rem;
`;
export const NoRecordCont = styled.div`
  margin-top: 4rem;
  font-size: 0.88rem;
  font-weight: 400;
  h4 {
    font-size: 0.88rem;
    font-weight: 400;
  }
`;

export const HeaderBtnContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const TopBtnsContainer = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  width: 42%;
  border-radius: 4rem;
  background-color: #e1e6ef;
  position: relative;
  margin-top: 1rem;
  margin-bottom: 0.3rem;

  @media (${BreakPoints.xs}) {
    width: 100%;
    height: 38px;
    justify-content: space-between;
    /* border: 2px solid red; */
  }
`;

export const Btn = styled.button<btnPropT>`
  height: 40px;
  width: 10.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(prop) => (prop.isClicked ? color.fountain : "#e1e6ef")};
  color: ${(prop) => (prop.isClicked ? "#fff" : "#292929")};
  border-radius: 4rem;
  transition: all ease-out 0.2s;
  cursor: pointer;

  @media (${BreakPoints.xs}) {
    height: 35px;
    width: 5rem;
    font-size: 0.8rem;
    /* border: 2px solid red; */
  }
`;


export const ChartSecton = styled.div<Props>`
  width: 100%;
  height: 100%;
  /* max-width: 30rem; */
  padding: 1.5rem 1.5rem 2rem;
  border-radius: 0.5rem;

  margin: auto;
  
  &>.h2 {
      margin: 0 0 2.5rem;
  } 
`

export const Chart=styled.div`
  width: 100%;
  display: block;
  /* grid-template-columns: auto; */
  /* gap: .5em 0; */
  align-items: center;
  margin: 0;
  padding: 0;
  list-style-type: none;
  grid-auto-columns: 1fr;
  
`

export const GraphContainer=styled.div<Props>`
  display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: space-between;
    margin-bottom: 10px;

    &>p {
      font-size: 16px;
      text-align: left;
    }
    &>div {
      width: 80%;
      height: 29px;
      background: #E1E7F8;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0rem 0.2rem;
      border-radius: 50px;
      text-align: center;
      color: #fff;
      margin-bottom: 10px;

      &>span {
        font-weight: bold;
        cursor: pointer;
        border-radius: 50px;
        font-size: 16px;
        height: 22px;
        padding-top:3px;
        

        ${(props)=>props.type=='low' && css`
          background-color: rgb(231, 130, 15, .5);
        `}
        ${(props)=>props.type=='medium' && css`
          background-color: rgb(71, 116, 255, .5);
        `}
        ${(props)=>props.type=='high' && css`
          background-color: rgba(15, 112, 0, .5);
        `}
      }
    }
`;