import { useEffect, useState } from "react";
import { RootStateOrAny, useSelector } from "react-redux";

import EmptyState from "shared/components/FreeComp/EmptyState";
import { GradientLoader } from "shared/components";
import {
  EmptyContainer,
  LeftTitle,
  RightLabel,
  SectionDiv,
  SectionTitle,
  SectionUnit,
  TopDiv,
} from "Customers/Style";
import toast from "shared/utils/toast";
import api from "shared/utils/api";
import DashboardSubHeader from "shared/components/DashboardSubHeader";
import { currencyFormat100 } from "shared/utils/moneyFormat";
import { useParams } from "react-router-dom";
import Table from "shared/components/Table";
import SharedCheckList from "shared/section_components/SharedCheckList";
import Layout from "Layout";
import { getStatusColor, getStatusDivColor } from "shared/utils/statusColor";
import { useHistory } from "react-router-dom";
import { Icon, PageError } from "shared/components";
import shouldPerform, { getRoleName } from "shared/utils/assignRoles";

import consoleLog from "shared/utils/consoleLog";
import summaryData from "shared/utils/dataSummary";
import { getStoreLocalStorage, storeToLocalStorage } from "shared/utils/authToken";

export default () => {
  let { id: customerId }: any = useParams();
  const [loading, setLoading] = useState(false);
  const [custData, setCustData] = useState<any>([]);
  const [objResponse, setObjResponse] = useState<any>({});
  const business = useSelector((store: RootStateOrAny) => store.business);
  const currentUser = useSelector((store: RootStateOrAny) => store.currentUser);
  const history = useHistory();
  const [openDownload, setOpenDownload] = useState<boolean>(false);
  const [fetchedPayload, setFetchedPayload] = useState<any>({});
  const [filteredFormState, setFilteredFormState] = useState<any>({});

  let titleDiv = (
    <span
      style={{ cursor: "pointer" }}
      onClick={() => history.push("/customers")}
    >
      <Icon type="arrow-left-circle" size={20} />
      <span style={{ marginLeft: 10 }}>Customer Transaction Details</span>
    </span>
  );
  const getCustomerDetails = async (obj: any) => {
    setLoading(true);
    try {
      
      const res: any = await api.get(
        `/business/${business.id}/customers/${customerId}`, obj
      );
      //consoleLog("res ", res);
      setFetchedPayload(res?.payload)
      let resultData = res?.payload?.data;
      resultData = resultData instanceof Array ? resultData : [];
      let resData = [];
      for (let el of resultData) {
        let obj: any = {};
        obj["serviceName"] = el?.Services?.name;
        obj["amount"] = currencyFormat100(el["amount"]);
        obj["charge"] = currencyFormat100(el["charge"]);
        obj["remitedAmount"] = currencyFormat100(el["remitedAmount"]);
        if (
          el["status"]
            ?.toString()
            .toLowerCase()
            .includes("init")
        ) {
          obj["status"] = getStatusDivColor("TERMINATED");
        } else {
          obj["status"] = getStatusDivColor(el["status"]);
        }

        resData.push({ ...el, ...obj });
      }
      if (resData.length > 0) {
        let obj: any = {};
        obj["email"] = resData[0]?.Customer?.email;
        obj["paymentName"] = resData[0]?.Customer?.fullName;
        obj["phone"] = resData[0]?.Customer?.phone;
        setObjResponse(obj);
      }
      setCustData(resData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      consoleLog(error);
    }
  };

  useEffect(() => {
    const now = new Date();
    const startDate=`${now.getFullYear()}-${now.getMonth()}-${now.getDate() - 7}`;
    
    let reqObj = {
      page: 1,
      limit: 10,
      start_date: startDate,
      end_date: `${now.getFullYear()}-${now.getMonth()}-${now.getDate()}`
    };
    let currentPage=getStoreLocalStorage("currentPage")
    if(currentPage){
      reqObj.page=currentPage
    }else{
      storeToLocalStorage("currentPage", reqObj?.page)
    }
    setFilteredFormState(reqObj)
    getCustomerDetails(reqObj);
  }, []);

  const goToPage = async (val: number) => {
    let obj: any = { ...filteredFormState };

    obj.page = val;
    await getCustomerDetails(obj);
  };

  const myFunction = (val: any) => {
    history.push(`/my-apps/${val?.serviceId}/transactions/${val?.id}`);
  };

  const columnHeaders = [
    { id: "createdAt", label: "Date" },
    { id: "reference", label: "Transaction ID" },
    { id: "amount", label: "Amount" },
    { id: "charge", label: "Charges" },
    { id: "remitedAmount", label: "Settled Amount" },
    { id: "gatewayChannel", label: "Channel"},
    { id: "serviceName", label: "App" },
    { id: "status", label: "Status" },
  ];

  let arrLeft = [
    { id: "email", label: "Customer Email" },
    { id: "phone", label: "Phone Number" },
  ];

  // let objResponse: any = {};
  let arrRight = [
    { type: "header", label: "Subscription" },
    { id: "paymentName", label: "Payment Name" },
    { type: "check", label: "UnSubscribe" },
  ];
  const handleChange = () => {};
  const switchItems = (obj: any = {}) => {
    if (obj.type === "header") {
      return <SectionTitle>{obj?.label}</SectionTitle>;
    }
    if (obj.type === "check") {
      return (
        <SharedCheckList
          onChange={handleChange}
          checked={true}
          label={obj.label}
          id={1} //discuss
        />
      );
    }
    if (obj.id) {
      return (
        <>
          <LeftTitle>{obj.label}</LeftTitle>{" "}
          <RightLabel>{obj?.id && objResponse[obj?.id]}</RightLabel>
        </>
      );
    }
  };

  let displayContent = (arr: any = [], idKey = 0) => {
    return (
      <SectionDiv key={`Sec${idKey}`}>
        {arr.map((el: any, idx: number) => {
          return (
            <SectionUnit key={`S${idKey}${idx}`}>{switchItems(el)}</SectionUnit>
          );
        })}
      </SectionDiv>
    );
  };

  let data: any = [...custData];

  let rowCount = data.length;
  if (!shouldPerform(getRoleName(currentUser, business?.id), "VCustomers")) {
    history.push("/authenticate");
    return null;
  }

  return (
    //<Layout PageTitle={titleDiv}>
    <div style={{ marginLeft: -15, marginRight: -15 }}>
      <DashboardSubHeader
        title={<>Customer Transaction Details</>}
        btnTitle="Download Summary"
        useBack
        widthActionBtn
        excelExport
        dateFilter={false}
        callBackFn={() => setOpenDownload(!openDownload)}
        openDownload={openDownload}
        setOpenDownload={setOpenDownload}
        excelExportData={summaryData(rowCount, columnHeaders, data)}
      />
      {loading ? (
        <GradientLoader />
      ) : (
        <>
          {rowCount === 0 ? (
            <EmptyContainer>
              <EmptyState stateTitle={"No Transaction Details"} />
            </EmptyContainer>
          ) : (
            <>
              <TopDiv>
                {displayContent(arrLeft, 0)}
                {displayContent(arrRight, 1)}
              </TopDiv>

              <Table
                headData={columnHeaders}
                bodyData={data ?? []}
                hoverLink
                serialNumber
                clickFunction={myFunction}
                totalPages={fetchedPayload?.totalPages ?? 0}
                currentpage={fetchedPayload?.currentPage ?? 0}
                goToPage={goToPage}
              />
            </>
          )}
        </>
      )}
    </div>
    //</Layout>
  );
};
