import React, { useEffect } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import {
  Route,
  Redirect,
  useRouteMatch,
  Switch,
  Router,
} from "react-router-dom";
import history from "browserHistory";
import Layout from "Layout";
import { PageError } from "shared/components";

import CreateMandate from "./CreateMandate";
import Home from "./Home";
import AddTeam from "Settings/Team/AddTeamForm";

export default function MandateManagement() {
  const match = useRouteMatch();
  const dispatch = useDispatch();

  
  return (
    //<Layout PageTitle="Team">
    <Router history={history}>
      <Switch>
        <Redirect exact from="/my-apps/:serviceId/mandate-management" to="/my-apps/:serviceId/mandate-management/overview" />
        <Route path="/my-apps/:serviceId/mandate-management/overview" component={Home} />
        <Route path="/my-apps/:serviceId/mandate-management/create-mandate" component={CreateMandate} />
        <Route component={PageError} />
      </Switch>
    </Router>
    //</Layout>
  );
}
