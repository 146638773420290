import styled from "styled-components";
import { color } from "shared/utils/styles";

export const PermissionsContainer = styled.div`
  padding: 1rem 2rem;
  /*position: fixed;overflow: auto !important;*/

  background: #fff;
  h3 {
    color: ${color.fountain};
  }
`;

export const PermModule = styled.div`
  margin-bottom: 2rem;
`;

export const ModuleHeader = styled.h4`
  padding: 1rem 0;
  border-bottom: 1px solid ${color.backgroundMedium};
`;

export const PermissionListContainer = styled.div`
  margin-top: 0.8rem;
`;

export const PermissionList = styled.div`
  display: flex;
  align-items: center;
  gap: 0.4rem;
  margin-bottom: 0.4rem;
  transition: all ease 0.8s;
`;

export const ListPointer = styled.div`
  width: 6px;
  height: 6px;
  background: rgba(130, 130, 130, 0.9);
  border-radius: 10rem;
`;

export const Lists = styled.span`
  font-size: 0.9rem;
  color: #292929;
  transition: all ease 0.8s;
`;

export const ViewMore = styled.div`
  margin-top: 1rem;
  padding: 0rem;
  color: ${color.fountain};
  border-bottom: 1px solid ${color.fountain};
  width: 30%;
  font-size: 0.86rem;
  text-align: center;
  cursor: pointer;
`;
