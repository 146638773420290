import { useEffect, useState } from "react";

import Layout from "Layout";
import { useParams } from "react-router-dom";
import BackButton from "shared/components/BackButton";
import Preloader from "shared/components/preloader";
import { formatDateInSlashes } from "shared/utils/dateTime";
import { formatAmount } from "shared/utils/formatAmount";
import chargeBackClass from "../ChargeBacks/chargeBackClass";
import Accept from "./ChargeBackOptions/accept";
import Decline from "./ChargeBackOptions/decline";
import {
  DataContainer,
  DataDesc,
  DataLabel,
  DataText,
  PageBody,
  PageBodyChild1,
  PageBodyChild2,
  SecondaryData,
  SecondaryLabel,
  SecondaryTitle,
  TertiaryCont,
  TertiaryContHeader,
  TertiaryContTitle,
} from "./Styles";
import consoleLog from "shared/utils/consoleLog";
import { GradientLoader } from "shared/components";

function Index({ match }: any) {
  const [data, setData] = useState<any>([]);
  const { id }: any = useParams();
  const [loading, setLoading] = useState(false);

  const checkRemark = (x: any) => {
    if (x === null) {
      return "AWAITING RESPONSE";
    } else if (x == "ACCEPTED") {
      return "ACCEPTED";
    } else if (x == "DECLINED") {
      return "DECLINED";
    } else {
      return null;
    }
  };

  const remark = checkRemark(data?.thread?.push.type || data?.thread);

  const toggle = () => {
    if (remark === "AWAITING RESPONSE") {
      return false;
    } else {
      return true;
    }
  };

  const getChargeBack = async (id: any) => {
    setLoading(true);
    try {
      const response = await chargeBackClass.getSingleChargeBack(id);
      setData(response);
      consoleLog("PAYLOAD", response);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getChargeBack(id);
  }, [id]);

  const [chargeStatus, setChargeStatus] = useState(remark || "ACCEPTED");
  consoleLog("first", remark);

  if (loading) {
    return <GradientLoader />;
  }

  const renderItem = () => {
    if (remark == "ACCEPTED") {
      return <Accept toggle={toggle} getChargeBack={getChargeBack} />;
    } else if (remark == "DECLINED") {
      return <Decline toggle={toggle} getChargeBack={getChargeBack} />;
    } else {
      return (
        <Accept toggle={toggle} getChargeBack={getChargeBack} /> && (
          <Decline toggle={toggle} getChargeBack={getChargeBack} />
        )
      );
    }
  };

  consoleLog(chargeStatus, "ddd");
  return (
    <>
      <BackButton link="/charge-backs" label="Charge Backs" />
      <PageBody>
      <PageBodyChild1>
          <DataDesc>
            <DataLabel>Amount Paid</DataLabel>

            <DataText>
              NGN {formatAmount(data?.amount - data?.charge)}
              
            </DataText>
          </DataDesc>

          <DataDesc>
            <DataLabel>Transaction ID</DataLabel>

            <DataText>{data?.transactionId}</DataText>
          </DataDesc>

          <DataDesc>
            <DataLabel>Amount</DataLabel>

            <DataText>
              NGN {formatAmount(data?.amount)}
              
            </DataText>
          </DataDesc>

          <DataDesc>
            <DataLabel>Charge</DataLabel>

            <DataText>
              NGN {data?.charge}
              
            </DataText>
          </DataDesc>

          <DataDesc>
            <DataLabel>Paid at</DataLabel>

            {formatDateInSlashes(data?.paidAt)}
          </DataDesc>

          <DataDesc>
            <DataLabel>Remark</DataLabel>

            <DataText>{remark}</DataText>
          </DataDesc>
        </PageBodyChild1>

        <PageBodyChild2>
          <SecondaryLabel>Dispute Claim</SecondaryLabel>

          <DataContainer>
            <DataDesc>
              <SecondaryTitle>Created on</SecondaryTitle>

              <SecondaryData>
                {formatDateInSlashes(data?.createdAt)}
              </SecondaryData>
            </DataDesc>

            <DataDesc>
              <SecondaryTitle>Status</SecondaryTitle>

              <SecondaryData>{data?.status}</SecondaryData>
            </DataDesc>

            <DataDesc>
              <SecondaryTitle>Refund Request</SecondaryTitle>
              NGN {formatAmount(data?.amount)}
              
            </DataDesc>
          </DataContainer>

          <TertiaryCont>
            <TertiaryContHeader>
              <TertiaryContTitle
                onClick={() => setChargeStatus("ACCEPTED")}
                style={{
                  fontWeight: chargeStatus === "ACCEPTED" ? "700" : "400",
                }}
              >
                Accept
              </TertiaryContTitle>

              <TertiaryContTitle
                onClick={() => setChargeStatus("DECLINED")}
                style={{
                  fontWeight: chargeStatus === "DECLINED" ? "700" : "400",
                }}
              >
                Decline
              </TertiaryContTitle>
            </TertiaryContHeader>

            {/* {renderItem()} */}

            {chargeStatus === "ACCEPTED" ? (
              <Accept toggle={toggle} getChargeBack={getChargeBack} />
            ) : (
              <Decline toggle={toggle} getChargeBack={getChargeBack} />
            )}
          </TertiaryCont>
        </PageBodyChild2>
      </PageBody>
    </>
    
  );
}

export default Index;
