import styled from "styled-components";
import breakpoints from "shared/utils/breakpoints";

export const SystemStatusPage = styled.div``;

export const ActionButtons = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const UserModal = styled.div`
  width: 60%;
  padding: 45px 5px;
  @media ${breakpoints.device.max.xs} {
    padding: 5px;
  }
`;

export const ModalContentRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  @media ${breakpoints.device.max.xs} {
    display: block;
  }
`;

export const ModalContentLeft = styled.div`
  width: 30%;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
  @media ${breakpoints.device.max.xs} {
    width: 100%;
  }
`;

export const ModalContentRight = styled.div`
  width: 60%;
  font-weight: 600;
  font-size: 16px;
  color: #000000;
  @media ${breakpoints.device.max.xs} {
    width: 100%;
  }
`;
