import breakpoints from 'shared/utils/breakpoints'
import styled from 'styled-components'
import { Button } from 'shared/components'
import { color, font } from 'shared/utils/styles'

export const ParentCont = styled.div`
	background-color: #e5e5e5;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
`

export const ReportBody = styled.div`
	display: flex;
	background-color: #fff;
	justify-content: center;
	width: 60%;
	min-height: 70vh;
	align-items: center;
	flex-direction: column;
	box-shadow: 0px 4px 100px rgba(0, 0, 0, 0.04);
	border-radius: 20px;
	@media ${breakpoints.device.max.sm} {
		width: 100%;
		height: 100%;
	}
`

export const SubTitle = styled.p`
	color: #828282;
	${font.size(16)}
	text-align: center;
`

export const SubTitle2 = styled.p`
	color: #292929;
	margin-top: 25px;
	font-weight: 600;
	${font.size(24)}
	line-height: 28px;
	text-align: center;
`

export const SubTitle3 = styled.p`
	color: #292929;
	margin-top: 10px;
	font-weight: 400;
	${font.size(18)}
	line-height: 18.75px;
	text-align: center;
`

export const ActionButton2 = styled(Button)`
	margin: 2em 0;
	color: #fff;
	width: 40%;
	background-color: ${color.fountain};
	@media ${breakpoints.device.max.sm} {
		width: 90%;
	}
`
