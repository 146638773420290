import { useEffect } from "react";
import { login } from "apiServices/authService";
import { overviewPath } from "apiServices/paths";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Form, Icon } from "shared/components";
import {
  ActionButton,
  ComponentBody,
  ComponentFooter,
  FormElement,
  StyledLink,
  Title,
  FormRow,
  FormRowLeft,
  FormRowRight,
  OverflowDiv,
  PasswordCont,
} from "./Styles";
import api from "shared/utils/api";
import logger from "shared/utils/logger";
import { getClientDetails } from "shared/utils/location";
import { setCurrentUser } from "store/actions/userAction";
import {
  setBusinessLists,
  setCurrentBusiness,
} from "store/actions/businessAction";
import {
  onChangeTextHandler,
  validations,
  internalValidations,
} from "shared/utils/formValidation";
import RadioButton from "shared/components/RadioButton";
import BackLogo from "App/assets/images/Auth/Back.svg";
import consoleLog from "shared/utils/consoleLog";
import toast from "shared/utils/toast";
import { BackImage, Back } from "../AccountRecovery/Styles";

const NewEmailAddress = ({ opt, setNewPage, formstate, setFormstate }: any) => {
  const [fstate, setFstate] = useState({} as any);
  const [isLoading, setIsLoading] = useState(false);
  const [isFormValid, setFormValid] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();
  const inputArray = [
    { id: "email", title: "Email Address", type: "email", required: true },
  ];

  useEffect(() => {
    let obj = internalValidations(
      inputArray.filter((x) => x.required),
      fstate
    );
    // consoleLog(formstate, "formstate");
    consoleLog(obj, "obj");
    if (
      Object.values(obj).filter((el: any) => el === true).length ===
      inputArray.filter((x) => x.required).length
    ) {
      setFormValid(true);
    } else {
      setFormValid(false);
    }
  }, [fstate]);

  if (opt !== 4) {
    history.push("/authenticate");
    return <></>;
  }

  const handleBack = () => {
    setNewPage(3);
  };

  const displayFormInput = () => {
    return inputArray.map((elm: any, idx) => {
      switch (elm.type) {
        case "text":
          return (
            <Form.Field.Input
              key={`INP${idx}`}
              name={elm.id}
              type={elm.type}
              value={fstate[elm.id] ?? ""}
              label={elm.title}
              onChange={(val: any) =>
                onChangeTextHandler(val, elm.id, fstate, setFstate)
              }
              // tip="Kindly enter your BVN"
            />
          );
        case "email":
          return (
            <Form.Field.Input
              key={`INP${idx}`}
              name={elm.id}
              type={elm.type}
              label={elm.title}
              value={fstate[elm.id] ?? ""}
              // onChange={(value: any) => elm.onChange(value)}
              onChange={(val: any) =>
                onChangeTextHandler(val, elm.id, fstate, setFstate)
              }
              // tip="Kindly enter your BVN"
            />
          );
      }
    });
  };

  const onSubmit = async () => {
    // setIsLoading(true);
    setIsLoading(true);
    try {
      let { email } = fstate;
      let response = await api.post(`/account/recovery`, {
        email,
        tempId: formstate?.tempId,
      });

      toast.success(response?.message);
      if (response?.payload instanceof Object)
        setFormstate({ ...formstate, ...response?.payload });
      setNewPage(5);
    } catch (error) {
      // @ts-ignore
      toast.error(error);
    } finally {
      setIsLoading(false);
      // setNewPage(5); ///remove later
    }

    // history.push("/authenticate/set-forgot-password");
  };

  const initialValues = () => {
    let obj: any = {};
    for (let elm of inputArray) {
      obj[elm.id] = "";
    }
    return obj;
  };

  return (
    <ComponentBody>
      <Back onClick={handleBack}>
        <BackImage src={BackLogo} />
      </Back>
      <Title>Enter New Email Address</Title>
      {/* @ts-ignore */}
      <Form
        enableReinitialize
        initialValues={initialValues()}
        validations={validations(
          inputArray.filter((x) => x.required),
          Form
        )}
        onSubmit={() => {}}
      >
        <FormElement>
          <OverflowDiv>{displayFormInput()}</OverflowDiv>
          <ActionButton
            type="button"
            variant="primary"
            disabled={!isFormValid}
            isWorking={isLoading}
            onClick={onSubmit}
          >
            Confirm Email
          </ActionButton>
        </FormElement>
      </Form>
    </ComponentBody>
  );
};

export default NewEmailAddress;
