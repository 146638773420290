import { ActionButton, ButtonContainer, FormBody, FormContainer, FormElement, SubAgentForm, SubTitle, TitleCase } from "./styles"
import { useState } from "react";
import { Form, Icon, OTPInput } from "shared/components";
import toast from "shared/utils/toast";
import api from "shared/utils/api";
import { color } from "shared/utils/styles";

import { OtpContainer } from "Auth/pages/ReceiveOtp/Styles";

type PropsT={
    agentId?: string;
    initialValues: any;
    step: number;
    setStep: Function;
}
const Pin=({agentId, initialValues, step, setStep}: PropsT)=>{
    const [initialPinValues, setinitialPinValues] = useState({
		pin: initialValues?.pin || ''
	})
    const [isLoginIn, setIsLoginIn] = useState(false)
	const [otp, setOtp] = useState("");
    const [confirmOtp, setConfirmOtp] = useState("");
    const [error, setError] = useState(false);
    
    const handleChange = async (otp: string) => {
        setError(false);
        if (otp.length === 6) {
            setOtp(otp);
            onKeyChange("pin", otp)
        }
    }

    const handleConfirmChange = async (otp: string) => {
        setError(false);
        if (otp.length === 6) {
            setConfirmOtp(otp)
            onKeyChange("pin", otp)
        }
    }

    const onKeyChange=(key: string, value: any)=>{
        if(otp != confirmOtp){
            setError(true)
        }else{
            setError(false)
            let oldValues: any=initialPinValues;
			oldValues[key]=value;
            setinitialPinValues(oldValues)
        }
    }

    const onSubmit = async () => {
		
		try{
			if (initialPinValues) {
                setIsLoginIn(true)
    
                let subAgent = {
                    ...initialPinValues,
                    id: agentId,
                }
    
                // // console.log("Pin: ", subAgent)
                const res = await api.post('/agency-banking/account/pin', subAgent)
                // consoleLog('success ', res);
                toast.success(res.message)
                setStep(6)
            }
		}catch(error: any){
			// // console.log(error)
			const resMessage = error.message === 'Successfully retrieved business agents' ? 'Error sub agent already exists' : error.message;
			toast.error(`${resMessage}`)
		}finally{
			setIsLoginIn(false)
		}
	}

    return(
        <>
            <TitleCase>Terminal Access Code</TitleCase>
            <SubTitle>
                With your corporate account number, you are guaranteed of your
                settlement
            </SubTitle>
            <FormBody>
                <Form
                enableReinitialize
                initialValues={initialPinValues}
                validations={{
                    pin: [Form.is.required(),  Form.is.maxLength(6)]
                }}
                onSubmit={onSubmit} 
                validate={function ({ }: {}) {
                    throw new Error('Function not implemented.')
                } } 
                validateOnBlur={true}					>
                    <FormElement>
                        <SubAgentForm>
                
                            <OtpContainer>
                                {" "}
                                <OTPInput
                                    autoFocus
                                    isNumberInput
                                    length={6}
                                    hasError={error}
                                    errorStyle={{ borderColor: "red" }}
                                    inputStyle={{
                                        border: "1px solid #828282",
                                        width: (100/6)-2+"%",
                                        height: "78px",
                                        fontSize: "30px",
                                        color: "#000",
                                        fontWeight: "400",
                                        caretColor: "#000",
                                        textAlign: "center",
                                        borderRight: "0px",
                                    }}
                                    focusStyle={
                                        {
                                        //border: "1px solid #CFD3DB",
                                        }
                                    }
                                    firstIndexStyle={{
                                        borderTopLeftRadius: "8px",
                                        borderBottomLeftRadius: "8px",
                                        borderRight: "0px",
                                    }}
                                    lastIndexStyle={{
                                        borderTopRightRadius: "8px",
                                        borderBottomRightRadius: "8px",
                                        borderRight: "1px solid #828282",
                                        borderLeft: "1px solid #828282",
                                    }}
                                    className="otpContainer"
                                    inputClassName="otpInput"
                                    separator={
                                        <span
                                        style={{
                                            marginLeft: "10px",
                                            marginRight: "10px",
                                            marginTop: "10%",
                                            fontSize: "50px",
                                            color: "black",
                                            fontWeight: "bold",
                                        }}
                                        >
                                        -
                                        </span>
                                    }
                                    onChangeOTP={handleChange}
                                />
                            </OtpContainer>

                            <OtpContainer>
                                {" "}
                                <OTPInput
                                    autoFocus
                                    isNumberInput
                                    length={6}
                                    hasError={error}
                                    errorStyle={{ borderColor: "red" }}
                                    inputStyle={{
                                        border: "1px solid #828282",
                                        width: (100/6)-2+"%",
                                        height: "78px",
                                        fontSize: "30px",
                                        color: "#000",
                                        fontWeight: "400",
                                        caretColor: "#000",
                                        textAlign: "center",
                                        borderRight: "0px",
                                    }}
                                    focusStyle={
                                        {
                                        //border: "1px solid #CFD3DB",
                                        }
                                    }
                                    firstIndexStyle={{
                                        borderTopLeftRadius: "8px",
                                        borderBottomLeftRadius: "8px",
                                        borderRight: "0px",
                                    }}
                                    lastIndexStyle={{
                                        borderTopRightRadius: "8px",
                                        borderBottomRightRadius: "8px",
                                        borderRight: "1px solid #828282",
                                        borderLeft: "1px solid #828282",
                                    }}
                                    className="otpContainer"
                                    inputClassName="otpInput"
                                    separator={
                                        <span
                                        style={{
                                            marginLeft: "10px",
                                            marginRight: "10px",
                                            marginTop: "10%",
                                            fontSize: "50px",
                                            color: "black",
                                            fontWeight: "bold",
                                        }}
                                        >
                                        -
                                        </span>
                                    }
                                    onChangeOTP={handleConfirmChange}
                                />
                            </OtpContainer>
                        </SubAgentForm>
                        <ButtonContainer>
                            <>
                                {step>1 && 
                                    <ActionButton
                                    type="button"
                                    variant={color.greyColor}
                                    onClick={()=>{setStep(step-1)}}
                                    >
                                        Back
                                    </ActionButton>
                                }
                                
                                <ActionButton
                                    type="submit"
                                    isWorking={isLoginIn}
                                    onClick={onSubmit}
                                >
                                    Finally submit
                                </ActionButton>
                            </>
                        </ButtonContainer>
                    </FormElement>
                </Form>	
            </FormBody>
        </>
    )
}

export default Pin;