import { useLayoutEffect, useState } from "react";
import { useHistory, Switch } from "react-router-dom";

import { Route } from "react-router";
import {
  getStoredAuthToken,
  removeStoredAuthToken,
} from "shared/utils/authToken";
import routes from "./Router";

import { Modal } from "shared/components/ModalBox/Styles";
import { useSelector, RootStateOrAny } from "react-redux";
import { FormBody, ModalBody, PageBody } from "./Styles";
import consoleLog from "shared/utils/consoleLog";
import LoginFace from "shared/components/LoginFace";

const AppRoute = ({ component: Component, layout: Layout, ...rest }: any) => {
  consoleLog(rest.path, "PATH");
  consoleLog(rest.path, "PATH");
  return (
    <Route
      {...rest}
      render={(props) => (
        //<Layout>
        <Component {...props}></Component>
        //</Layout>
      )}
    />
  );
};

const Authenticate = () => {
  const history = useHistory();
  const currentUser = useSelector((store: RootStateOrAny) => store.currentUser);

  useLayoutEffect(() => {
    const token = getStoredAuthToken();
    if (token) {
      //
      if (
        !currentUser ||
        !(currentUser instanceof Object) ||
        Object.keys(currentUser).length === 0
      ) {
        removeStoredAuthToken();
        history.push("/authenticate");
      } else {
        history.push("/overview");
      }
    }
  }, []);

  return (
    <>
      {/* <PageBody>
        <ModalBody>
          <Modal>
            <FormBody> */}
      <LoginFace>
        <Switch>
          {routes.map((i, index) => {
            return (
              <AppRoute
                exact={!i.nonExact}
                key={i.path}
                path={i.path}
                layout={i.layout}
                component={i.component}
              />
            );
          })}
        </Switch>
      </LoginFace>
      {/* </FormBody>
          </Modal>
        </ModalBody>
      </PageBody> */}
    </>
  );
};

export default Authenticate;
