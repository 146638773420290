// import { getCurrentBusiness } from "shared/utils/businessesData";
import { ActionTypes } from "../constants/actionType";

const initialState = {
  service: {},
};

export const serviceReducer = (
  state: any = initialState.service,
  action: any
) => {
  switch (action.type) {
    case ActionTypes.SET_SERVICE:
      return action.payload;
    default:
      return state;
  }
};
