import { useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";

import RightPic from "App/assets/images/services/rightImgService.svg";
import { Form } from "shared/components";
import {
  ActionButton,
  Back,
  ButtonContainer,
  Container,
  FormBody,
  FormContainer,
  Image,
  SubTitle,
  TitleCase,
} from "./Style";

import bankAccountClass from "Settings/BankAccount/bankAccountClass";
import GradientLoader from "shared/components/GradientLoader/index2";
import { similarity } from "shared/utils/classes/stringCompare";
import { onChangeTextHandler, validations } from "shared/utils/formValidation";
import toast from "shared/utils/toast";
import subaccountClass from "SubAccount/subaccountClass";
import consoleLog from "shared/utils/consoleLog";

export default ({ fetchData, openModal, setStep, addBack, addRecord }: any) => {
  const [dropDown, setDropDown] = useState(false);
  const [dropDown2, setDropDown2] = useState(false);
  const [bankList, setbankList] = useState([]);
  const [accountNumber, setaccountNumber] = useState<string>("");
  const [accountName, setaccountName] = useState<string>("");
  const [selectedBank, setselectedBank] = useState<any>({});
  const [btnActive, setbtnActive] = useState(false);
  const [btnText, setbtnText] = useState("Add bank account");
  const [banknameStatus, setbanknameStatus] = useState("");
  const [fstate, setFstate] = useState({} as any);
  const [loading, setLoading] = useState(false);
  const [isFormValid, setFormValid] = useState(false);

  const currentbusiness = useSelector(
    (store: RootStateOrAny) => store.business
  );
  const dispatch = useDispatch();
  const getAllBanks = async () => {
    setLoading(true);

    const banks = await subaccountClass.getAllBanks();
    const sortedBanks = banks.sort((a: any, b: any) =>
      a.name > b.name ? 1 : b.name > a.name ? -1 : 0
    );

    if (sortedBanks) {
      let useArr = banks.map((el: any) => {
        let obj: any = {};
        obj.label = el.name;
        obj.value = el.id;
        obj.code = el.code;
        return obj;
      });
      setbankList(useArr);
      setLoading(false);

      // consoleLog(useArr, "useArr");
    }
  };

  //=========================================================

  const getAccountName = async () => {
    setbanknameStatus("Loading..");
    try {
      if (
        fstate.bankId &&
        fstate.accountNumber &&
        fstate.accountNumber.length === 10
      ) {
        let bankDetails: any = bankList.find(
          (el: any) => el.value === fstate.bankId
        );

        let bankCode = bankDetails?.code;
        const res = await subaccountClass.getAccountName(
          fstate.accountNumber,
          bankCode
        );
        consoleLog(res, "ress");

        if (res?.accountName) {
          setbanknameStatus("");
          setFstate({ ...fstate, accountName: res.accountName });
          //   setFormValid(true);
          // setaccountName(res.accountName);
        } else {
          toast.error(res?.message ?? "Unable to fetch Account Name");
          setFormValid(false);
        }
      }
    } catch (err) {
      // consoleLog(err, "errr");
      //@ts-ignore
      toast.error(err ?? "Unable to fetch Account Name");
      setFormValid(false);
    } finally {
      setbanknameStatus("");
    }

    // consoleLog("bank name", res);
  };

  const checkActiveButton = () => {
    const similarityValue =
      similarity(currentbusiness.businessName, fstate.accountName) * 100;
    if (
      fstate.accountName !== "" &&
      fstate.accountNumber !== "" &&
      fstate.bankId !== "" &&
      similarityValue >= 80
    ) {
      //   setbtnActive(true);
      setFormValid(true);
    } else {
      toast.error(
        "Sorry you can't add this account! Reasons: Name on account didn't match your business name."
      );
    }
  };

  const addBankAccount = async () => {
    // consoleLog("bank", selectedBank);

    const similarityValue =
      similarity(currentbusiness.businessName, fstate.accountName) * 100;
    setbtnText("Processing...");
    // setFormValid(false);
    let bankDetails: any = bankList.find(
      (el: any) => el.value === fstate.bankId
    );
    let bankName = bankDetails?.label;
    consoleLog(bankName, bankDetails, "bank Name");
    const data = {
      status: "VERIFIED",
      // bankName: bankName,
      bankId: fstate.bankId,
      accountNumber: fstate.accountNumber,
      bankAccountName: fstate.accountName,
    };

    if (isFormValid && similarityValue >= 80) {
      const res = await bankAccountClass.addBankAccount(
        data,
        currentbusiness.id
      );
      if (res && res.status === true) {
        consoleLog(res, "ressss");
        toast.success("Bank account added successfully!");
        setbtnText("Create");
        openModal && openModal(); //close
        fetchData && fetchData();
        addRecord && addRecord(res?.payload?.id);
      } else {
        toast.error("Failed!. Account details not added");
        setbtnText("Add bank account");
      }
    } else {
      toast.error("Failed!. Account details not matched");
      setbtnText("Add bank account");
    }
  };

  useEffect(() => {
    getAllBanks();
  }, []);
  useEffect(() => {
    if (fstate.accountName && fstate.accountName !== "") checkActiveButton(); //checking if we have fetched account name
  }, [fstate.accountName]);

  const inputArray = [
    {
      id: "bankId",
      title: "Bank Name For Payout",
      type: "select",
      options: () => {
        let optArr = [{ label: " Select bank", value: "" }, ...bankList];

        return optArr;
      },
    },
    {
      id: "accountNumber",
      title: "Bank Account Number",
      type: "acctNum",
      // onkeyup: (event: React.KeyboardEvent<HTMLInputElement>) => {
      //   getAccountName();
      //   return;
      // },
    },
    { id: "accountName", title: "Account Name", type: "text", disabled: true },
  ];

  const initialValues = () => {
    let obj: any = {};
    for (let elm of inputArray) {
      obj[elm.id] = "";
    }
    return obj;
  };

  const displayFormInput = () => {
    return inputArray.map((elm, idx) => {
      //// console.log("elm ", elm);
      switch (elm.type) {
        case "text":
          return (
            <Form.Field.Input
              key={`INP${idx}`}
              name={elm.id}
              type={elm.type}
              label={elm.title}
              value={fstate[elm.id] ?? ""}
              disabled={elm.disabled ?? false}
              onChange={(val: any) => {
                onChangeTextHandler(val, elm.id, fstate, setFstate);
              }}
              // tip="Kindly enter your BVN"
            />
          );
        case "acctNum":
          return (
            <Form.Field.Input
              key={`INP${idx}`}
              name={elm.id}
              type="number"
              label={elm.title}
              pattern="\d*"
              value={fstate[elm.id] ?? ""}
              maxlength="10"
              // onKeyUp={(e: any) => elm.onkeyup && elm.onkeyup(e)}
              onChange={(val: any) => {
                if (val.length <= 10)
                  onChangeTextHandler(val, elm.id, fstate, setFstate);
              }}
              // tip="Kindly enter your BVN"
            />
          );
        case "select":
          return (
            <Form.Field.Select
              key={`INP${idx}`}
              name={elm.id}
              label={elm.title}
              options={elm.options && elm.options()}
              value={fstate[elm.id] ?? ""}
              onChange={(val: any) => {
                onChangeTextHandler(val, elm.id, fstate, setFstate);
              }}
            />
          );
      }
    });
  };

  useEffect(() => {
    getAccountName();
  }, [fstate.bankId, fstate.accountNumber]);

  const onSubmit = () => {};
  // if (loading) return <GradientLoader />; ///look for another loader

  return (
    <Container>
      {loading ? (
        <GradientLoader />
      ) : (
        <FormContainer>
          <TitleCase>Add Account</TitleCase>
          <SubTitle></SubTitle>
          <FormBody>
            {/* @ts-ignore */}
            <Form
              enableReinitialize
              initialValues={initialValues()}
              validations={validations(inputArray, Form)}
              onSubmit={onSubmit}
            >
              <>
                {displayFormInput()}
                {banknameStatus}
                <ButtonContainer>
                  {addBack ? (
                    <Back onClick={() => setStep && setStep(1)}>Back</Back>
                  ) : (
                    <Back></Back>
                  )}

                  <ActionButton
                    disabled={!isFormValid || btnText === "Processing..."}
                    onClick={addBankAccount}
                  >
                    {btnText}
                  </ActionButton>
                </ButtonContainer>
              </>
            </Form>
          </FormBody>
        </FormContainer>
      )}

      <Image src={RightPic} />
    </Container>
  );
};
