import SecureLS from "secure-ls";
let ls = new SecureLS({ encodingType: "aes" });

export const storeBusinesses = (businesses: any) =>
  ls.set("businesses", businesses);

export const storeCurrentBusiness = (currentBusinesses: any) =>
  ls.set("currentBusiness", currentBusinesses);
export const getCurrentBusiness = () => ls.get("currentBusiness");

export const storeBusinessID = (businessID: string) =>
  ls.set("businessID", businessID);
export const getStoreBusinessID = () => ls.get("businessID");

export const getstoredBusinesses = () => ls.get("businesses");
export const removestoredBusinesses = () => ls.remove("businesses");
