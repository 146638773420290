import * as React from "react";
import { Dropdown, Menu, ServiceActionBtn } from "./Style";
import { useEffect } from "react";

type Props = {
  btnTtitle: string;
  menus: any;
  onClickEvent: Function;
  labelTitle?: boolean;
  backgroundColor?: string;
  width?: string;
};
const DropdownButton = ({
  btnTtitle,
  menus,
  onClickEvent,
  labelTitle,
  backgroundColor,
  width,
}: Props) => {
  const [open, setOpen] = React.useState(false);
  const [datas, setData] = React.useState<any>(menus || []);
  const [title, setTitle] = React.useState<any>(btnTtitle);

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleMenuOne = (value: any) => {
    // do something
    if (labelTitle) {
      setTitle(value);
      setData(menus.filter((x: any) => x.value != value));
    }
    setOpen(false);
    onClickEvent(value);
  };

  React.useEffect(() => {
    if (labelTitle) {
      setData(menus.filter((x: any) => x.value != title));
    }
  }, []);
  const renderMenus = datas.map((item: any, id: number) => (
    <li className="menu-item" onClick={() => handleMenuOne(item.value)}>
      <button>{item.title}</button>
    </li>
  ));

  const selectRef = React.useRef<any>(null);
  //====Todo:to be converted to customhook later
  useEffect(() => {
    const handleOutsideClick = (event: any) => {
      if (selectRef.current && !selectRef.current?.contains(event.target)) {
        setOpen(false);
      }
    };

    if (open) {
      document.addEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [open]);

  return (
    <Dropdown ref={selectRef}>
      <ServiceActionBtn
        backgroundColor={backgroundColor}
        width={width}
        onClick={handleOpen}
      >
        {title}
      </ServiceActionBtn>
      {open ? <Menu>{renderMenus}</Menu> : null}
    </Dropdown>
  );
};

export default DropdownButton;
