/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import 'quill/dist/quill.snow.css';

import { Content } from './Styles';

const propTypes = {
  content: PropTypes.string.isRequired,
};

type PropT={
  content: string,
  [x:string] : any
}

const TextEditedContent = ({ content, ...otherProps }: PropT) => (
  <div className="ql-snow">
    <Content className="ql-editor" dangerouslySetInnerHTML={{ __html: content }} {...otherProps} />
  </div>
);

// TextEditedContent.propTypes = propTypes;

export default TextEditedContent;
