import { useHistory } from "react-router-dom";
import { Icon } from "shared/components";
import { BackButtonContainer } from "./Styles";

const BackButton = ({ link, label, ...rest }: any) => {
  const history = useHistory();

  const handleClick = () => {
    if (link) {
      history.push(link);
    } else {
      history.goBack();
    }
  };

  return (
    <BackButtonContainer onClick={handleClick} {...rest}>
      <Icon
        // style={{ marginTop: "7px" }}
        type="arrow-left-circle"
        size="28"
        color="#760000"
      />
      <span>{label || "Back"}</span>
    </BackButtonContainer>
  );
};

export default BackButton;
