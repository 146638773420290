import { useEffect, useState } from "react";
import {
  LeftPanel,
  RightPanel,
  RightPanelAuxCont,
  CloseBtn,
  DescriptionBox,
  RightPanelAuxCon2,
  DescriptionText,
  DescriptionBtn,
  FormCont,
  TitleCont,
  Back,
  FormTitle,
  FormSubTitle,
} from "KYC-refactored/PersonalDetails/Styles";
import { useHistory } from "react-router-dom";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { ParentCont } from "KYC-refactored/Style";
import api from "shared/utils/api";
import EmptyState from "shared/components/EmptyState";
import { Invite, InviteTitle } from "./Styles";
import { SpinnerCircular } from "spinners-react";
import { setBusinessLists } from "store/actions/businessAction";
import toast from "shared/utils/toast";
import { Icon } from "shared/components";
import { setCurrentUser } from "store/actions/userAction";

const Invitation=()=>{
    const history = useHistory();
    const [visible, setVisible] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [invitations, setInvitations] = useState<any>([]);

    const currentUser = useSelector((store: RootStateOrAny) => store.currentUser);

    const dispatch = useDispatch();
    
    const goBack = () => {
        history.goBack()
    };

    const acceptInvite=async(obj: any)=>{
        setIsProcessing(true);
        try{
            
            const invite_accept: any = await api.post(
            "/authentication/accept-invite",
            { invite_id: obj?.id, user_id: currentUser?.id }
            );
            // console.log("Accept: ", invite_accept)

            if (invite_accept.status) {
                toast.success(invite_accept.message);
                await dispatch(setCurrentUser());
                await dispatch(setBusinessLists());
                getInvitations();
            }else{
                toast.error(invite_accept.message);
            }
        }catch(error: any){
            // console.log("Error: ", error);
            toast.error(error.message);
        }finally{
            setIsProcessing(false);
        }
    }

    const rejectInvite=async(obj: any)=>{
        setIsProcessing(true);
        try{
            
            const invite_accept: any = await api.get(`/authentication/decline-invite/${obj?.id}`);
            // console.log("Accept: ", invite_accept)

            if (invite_accept.status) {
                toast.success(invite_accept.message);
                // await dispatch(setBusinessLists());
                getInvitations();
            }else{
                toast.error(invite_accept.message);
            }
        }catch(error: any){
            // console.log("Error: ", error);
            toast.error(error.message);
        }finally{
            setIsProcessing(false);
        }
    }

    const getInvitations=async()=>{
        setIsLoading(true)
        try{
            const resp=await api.post("/authentication/my-invitations", {email: currentUser?.email});
            if(resp.status){
                setInvitations(resp.payload);
            }
            // console.log(resp)
        }catch(error: any){

        }finally{
            setIsLoading(false);
        }
    }
    
    const generateMap=invitations.map((obj: any, ind: number)=>(
        <Invite
        key={ind}
        
        status={'PENDING'}
        >
            <InviteTitle>{obj?.business?.businessName} invites you as {obj?.roles}</InviteTitle>
            <div className="date">{obj?.createdAt}</div>
            <div className="actions">
                <Icon type={"check"} size={20} onClick={() => { acceptInvite(obj); }}/>
                <Icon type={"close"} size={20} onClick={() => { rejectInvite(obj); }}/>
            </div>
        </Invite>
    ))
    // console.log(generateMap)

    useEffect(()=>{
        getInvitations();
    }, [])
    return(
        <ParentCont>
            <LeftPanel>
                <FormCont>
                    <TitleCont>
                        <Back onClick={goBack}>
                            <Icon type={"chevron-left"} size={20} />
                        </Back>
                        <FormTitle>Pending invitations</FormTitle>
                    </TitleCont>

                    <FormSubTitle>
                        With your corporate account number, you are guaranteed of yoursettlement.
                    </FormSubTitle>

                    {isLoading && <SpinnerCircular />}
                    {!isLoading && !invitations && <EmptyState stateTitle={""} />}
                    {!isLoading && invitations &&
                        <>
                            {isProcessing && <SpinnerCircular />}
                            {generateMap}
                        </>
                    }
                </FormCont>
            </LeftPanel>

            <RightPanel>
                <RightPanelAuxCont>
                    <CloseBtn onClick={() => goBack()}>
                        <Icon type={"close"} size={20} />
                    </CloseBtn>
                </RightPanelAuxCont>

                <RightPanelAuxCon2>
                    <DescriptionBox>
                        <DescriptionText>
                            Join over 152,000 customers that use our products
                        </DescriptionText>

                        <DescriptionBtn
                            onClick={() =>
                            window.open(
                                "https://medium.com/@fountainpay",
                                "noopener,noreferrer"
                            )
                            }
                        >
                            Learn more
                        </DescriptionBtn>
                    </DescriptionBox>
                </RightPanelAuxCon2>
            </RightPanel>
        </ParentCont>
    )
}

export default Invitation;