import React, { useState, useEffect, useCallback, useLayoutEffect } from 'react'
import { RootStateOrAny, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { GradientLoader } from 'shared/components'
import { roles } from 'shared/utils/assignRoles'
// import useApi from "shared/hooks/api";
import {
	ActionButton,
	AddTeamForm,
	DetailsContainer,
	FirstStep,
	FirstStepCard,
	FormContainer,
	Input,
	InputGroup,
	InputLabel,
	Mode,
	PageContainer,
	Select,
	ServiceBox,
	ServiceName,
	ServicesBox,
	Step,
	StepContainer,
	StepLine,
	StepsBox,
	StepsContainer,
	TeamFormContainer,
} from './Styles'
import teamOBJ from 'Settings/Team/teamClass'
import consoleLog from 'shared/utils/consoleLog'
import shouldPerform, { getRoleName } from 'shared/utils/assignRoles'

import toast from 'shared/utils/toast'
import MandatesClass from "../mandateClass";
import PermissionDetails from 'Settings/Team/Invite/Permission'
import CreateForm from './CreateForm'
import Spinner from 'shared/components/spinner/spinner'

type StepsT = {
	id: number
	status: boolean
	title: string
}[]

export default function CreateMandate() {
	const currentUser = useSelector((store: RootStateOrAny) => store.currentUser);
	const [isAuth, setIsAuth] = useState(false);
	const [isloading, setIsloading] = useState(false);
	const history = useHistory()

	const params: any=useParams();
	
	const business = useSelector((store: RootStateOrAny) => store.business)

	const confirmService=async()=>{
		setIsloading(true)
		try{

		}catch(err: any){

		}finally{
			setIsloading(false)
		}
	}

	// if (!shouldPerform(getRoleName(currentUser, business?.id), 'CTeams')) {
	// 	history.push('/authenticate')
	// 	return null
	// }

	useEffect(()=>{
		if(params && params?.id){
			setIsAuth(true);
			confirmService()
		}
	}, [params])
	return (
		<PageContainer>
			<TeamFormContainer fromAuth={isAuth}>
				{isloading && <GradientLoader />}
				{!isloading && <CreateForm />
				}
			</TeamFormContainer>

			<DetailsContainer>
				
			</DetailsContainer>
		</PageContainer>
	)
}
