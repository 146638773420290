import { useState } from "react";
import { Form, PageOverlay } from "shared/components";
import { FormElement, ActionButton } from "./Styles";
import api from "shared/utils/api";
import toast from "shared/utils/toast";
import { useHistory } from "react-router-dom";
import Preloader from "shared/components/preloader";
import { Selectors2 } from "./Styles";
import SharedCheckList from "shared/section_components/SharedCheckList";
import consoleLog from "shared/utils/consoleLog";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { setBusinessLists } from "store/actions/businessAction";
import { setCurrentUser } from "store/actions/userAction";
import { getTempId } from "shared/utils/authToken";

type PropT = {
  isClicked: boolean;
};

const Forms = ({ isClicked }: PropT) => {
  const [isLoadingBvn, setIsLoadingBvn] = useState(false);
  const [isLoginIn, setIsLoginIn] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const history = useHistory();
  const [termsAndCons, setTermsAndCons] = useState<boolean>(true);
  const [isValidate, setIsValidate] = useState<boolean>(false);

  const [fullName, setFullName] = useState("");
  const [phone, setPhone] = useState("");
  const [verification, setVerification] = useState({ bvn: "", nin: "" });
  const [verContent, setVerContent] = useState<any>({});

  const dispatch = useDispatch();

  const currentUser = useSelector((store: RootStateOrAny) => store.currentUser);

  //Test Bvn is bvn: ”54651333604”

  //Test Nin is	"nin": 12345678909,

  const initialValues = {
    reg_email: currentUser?.email,
    number: isClicked ? verification.bvn : verification.nin,
    firstName: fullName.split(" ")[0],
    lastName: fullName.split(" ")[1],
    phone: phone,
    isBvn: isClicked ? true : false,
    is_validated: false,
  };

  // if (isLoginIn) {
  //   return <GradientLoader />;
  // }
  function isValid(p: any) {
    var phoneRe = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g;
    return phoneRe.test(p);
  }
  const verifyBvn = async (value: any) => {
    if (isNaN(value)) return;
    // if (!/^\d+$/.test(value)) return;
    setVerification({
      bvn: isClicked ? value : "",
      nin: !isClicked ? value : "",
    });
    if (value.length === 11) {
      const values: any = {
        isBvn: isClicked ? true : false,
        number: parseInt(value),
      };

      setIsLoadingBvn(true);

      try {
        const data: any = await api.post("/authentication/validate", {
          ...values,
        });
        consoleLog("Response: ", data);
        if (data?.status && data?.payload.bvn) {
          const fullNamee = `${data?.payload.lastName} ${data?.payload.firstName} ${data?.payload.middleName}`;
          setFullName(fullNamee);

          const phoneNumber = isValid(data?.payload.phoneNumber)
            ? data?.payload.phoneNumber
            : "";

          setPhone(phoneNumber); //["+234", phoneNumber].join("")
          let vetData = data?.payload;
          vetData.phone = phone;
          setVerContent(vetData);
          setIsValidate(true);
          toast.success(data?.message);
          setFormValid(true);
        } else if (data?.message === "Verified identity retrieved") {
          const fullNamee = `${data?.payload.lastName} ${data?.payload.firstName}`;
          setFullName(fullNamee);

          //const phoneNumber = data?.payload.phoneNumber;
          const phoneNumber = isValid(data?.payload.phoneNumber)
            ? data?.payload.phoneNumber
            : "";
          setPhone(phoneNumber); //["+234", phoneNumber].join("")
          setIsValidate(true);
          toast.success(data?.message);
          setFormValid(true);
        } else {
          consoleLog("ErrorBvn1");
          consoleLog(data?.message, "ErrorBvn1");
          setFullName("");
          setPhone("");
          setFormValid(false);
          toast.error(data?.message ?? "Please try again");
        }
      } catch (error) {
        setFullName("");
        setPhone("");
        setFormValid(false);
        consoleLog("ErrorBvn2");
        consoleLog(error, "ErrorBvn2");
        toast.error("Please try again");
      } finally {
        setIsLoadingBvn(false);
      }
    }
  };

  const onSubmit = async (value: any, form: any) => {
    setIsLoginIn(true);

    try {
      //consoleLog("Post data", values);return;
      const values: any = {
        number: isClicked ? verification.bvn : verification.nin,
        ...value,
        date_of_birth: verContent["date_of_birth"],
        phone:
          verContent &&
          typeof verContent["phoneNumber"] == "string" &&
          verContent["phoneNumber"] != ""
            ? verContent["phoneNumber"]
            : phone,
        address: verContent["address"],
        email: verContent["email"],
        enrollment_bank: verContent["enrollment_bank"],
        enrollment_branch: verContent["enrollment_branch"],
        gender: verContent["gender"],
        lga_of_residence: verContent["lga_of_residence"],
        marital_status: verContent["marital_status"],
        nationality: verContent["nationality"],
        registration_date: verContent["registration_date"],
        state_of_residence: verContent["state_of_residence"],
        watch_listed: verContent["watch_listed"],
        is_validated: isValidate,
      };
      const resp: any = await api.post("/authentication/commit", {
        ...values,
      });
      //consoleLog("Response: ", resp);return;
      consoleLog("Response to check Invite T: ", resp);

      if (resp.status) {
        const invitations = await api.post("/authentication/my-invitations", {
          email: currentUser?.email,
        });
        // console.log(invitations);
        if (invitations.status) {
          const invite_accept: any = await api.post(
            "/authentication/accept-invite",
            { email: currentUser?.email, user_id: currentUser?.id }
          );
          // // console.log("Accept: ", invite_accept)

          if (invite_accept.status) {
            await dispatch(setCurrentUser());
            await dispatch(setBusinessLists());
            history.push("/");
          } else {
            history.push("/invitations");
          }
        } else {
          toast.success(resp.message);
          history.push("/kyc/business-type");
        }
      } else {
        toast.error(resp.message);
      }
    } catch (error) {
      // @ts-ignore
      // console.log("Error: ", error);
      toast.error("Please try again");
    } finally {
      setIsLoginIn(false);
    }
  };

  const checkFormValidity = () => {
    setFormValid(verification && phone && fullName ? true : false);
  };

  const jump = () => {
    history.push("/kyc/business-type");
  };

  return (
    <>
      {/* @ts-ignore */}
      <Form
        enableReinitialize
        initialValues={initialValues}
        validations={{
          number: [Form.is.required(), Form.is.maxLength(11)],
          firstName: Form.is.required(),
          phone: [Form.is.required(), Form.is.maxLength(200)],
        }}
        onSubmit={onSubmit}
      >
        <FormElement>
          {isLoadingBvn && <PageOverlay top={200} right={400} />}
          <Form.Field.Input
            style={{ color: "black" }}
            name="number"
            type="number"
            label={
              isClicked
                ? "Bank Verification Number (BVN)"
                : "National Identification Number (NIMC)"
            }
            onChange={verifyBvn}
            value={isClicked ? verification.bvn : verification.nin}
            disabled={isLoadingBvn}
          />
          <Form.Field.Input
            name="name"
            type="text"
            label="Name"
            onChange={(e: any) => {
              setFullName(e);
              checkFormValidity();
            }}
            value={fullName}
            disabled={false}
            // tip="Kindly enter your name"
          />

          <Form.Field.Input
            name="phone"
            type="tel"
            label="Phone Number"
            value={phone}
            onChange={(e: any) => {
              setPhone(e);
              checkFormValidity();
            }}
            disabled={false}
            // tip="Kindly enter your Phone Number"
          />

          {/* <SharedCheckList
            key={'termsAndCondition'}
            onChange={() => setTermsAndCons(!termsAndCons)}
            checked={termsAndCons}
            label={'I hereby accept the terms and privacy'}
            id={'termsAndCondition'}
        /> */}

          <Selectors2>
            <ActionButton
              //delete bellow later
              //onClick={onSubmit}
              disabled={!formValid}
              type="submit"
              variant="primary"
              isWorking={isLoginIn}
            >
              Save and Continue
            </ActionButton>
          </Selectors2>
        </FormElement>
      </Form>
    </>
  );
};

export default Forms;
