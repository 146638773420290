import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import { store } from "store";
import AppWrapper from "./AppWrapper";
import Preloader from "shared/components/preloader";

const App = () => {
  // const history = useHistory();

  // useEffect (() => {
  //   const user = getStoredAuthToken();
  //   if (!user) {
  //     history.push("/authenticate/")
  //   }
  // },[])

  let persistor = persistStore(store);
   

  return (
    <Provider store={store}>
      <PersistGate loading={<Preloader />} persistor={persistor}>
        {" "}
        {/* Set context */}
        <AppWrapper />
        {/* Now App has access to context */}
      </PersistGate>
    </Provider>
  );
};

export default App;
