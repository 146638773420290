
import { useEffect, useState } from "react";
import Preloader from "shared/components/preloader";
import { Route, Router, Switch, useHistory } from "react-router-dom";
import CreatePrice from "./CreatePrice";
import Pricelist from "./Pricelist";
import { GradientLoader, PageError } from "shared/components";

const Pricing = () => {
    const [loading, setLoading] = useState(false);
    
    const history = useHistory();

    if (loading) {
        return <GradientLoader />;
    }

    return (
        <>
            <Router history={history}>
                <Switch>

                    <Route
                    exact
                    path="/agency/pricing"
                    component={Pricelist}
                    />

                    <Route
                    exact
                    path="/agency/pricing/add-new"
                    component={CreatePrice}
                    />

                    <Route
                    exact
                    path="/agency/pricing/update/:id"
                    component={CreatePrice}
                    />
                    <Route component={PageError} />
                </Switch>
            </Router>
        </>
    );
};

export default Pricing;
