import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: whitesmoke;
`;
export const TopDiv = styled.div`
  background: #760000;
  padding: 10px 33px;
`;
export const WholeDiv = styled.div`
  width: 456px;
  margin-bottom: 23px;
`;

export const TopDivContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;
export const LeftTopDiv = styled.div``;
export const RightTopDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const TopText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 170%;
  /* or 27px */

  color: #ffffff;
`;
export const TopText2 = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 136%;
  /* identical to box height, or 33px */

  letter-spacing: 0.02em;

  color: #ffffff;
`;
export const BottomDivContainer = styled.div`
  width: 100%;
  padding: 20px 33px;
`;
export const BottomFirstDiv = styled.div`
  width: 100%;

  // font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 170%;
  /* or 27px */

  text-align: center;

  /* Primary black */

  color: #292929;
  margin-top: 4px;
  margin-bottom: 24px;
`;

export const BottomSecondDiv = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-bottom: 16px;
`;

export const Divider = styled.div`
  border: 0.2px dashed #828282;
  width: 100%;
  margin-bottom: 24px;
`;

export const LeftText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 170%;
  text-align: left;
  color: #828282;
`;
export const RightText = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 170%;
  text-align: right;

  color: #292929;
`;

export const BottomThirdDiv = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 16px;
  margin-top: 20px;
`;

export const BottomInnerThirdDiv1 = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 170%;
  color: #828282;
`;
export const BottomInnerThirdDiv2 = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 170%;
  /* or 20px */

  text-align: center;
  text-decoration-line: underline;

  color: #760000;
`;

export const FooterDiv = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
`;

export const ImageDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
