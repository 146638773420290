import Login from "./pages/Login";
import Signup from "./pages/Signup";
import OXSignup from "./pages/0XSignup";
import SetPassword from "./pages/SetPassword";
import ResetPassword from "./pages/ResetPassword";
import MainLayout from "./Layouts/MainLayout";
import ForgotPassword from "./pages/ForgotPassword";
import SessionTimeout from "./pages/SessionTimeout";
import ReportLayout from "./Layouts/ReportLayout";
import OtpFailed from "./pages/report/OtpFailed";
import OtpSuccess from "./pages/report/OtpSuccess";
import PasswordSuccess from "./pages/report/PasswordSuccess";
import ReceiveOtp from "./pages/ReceiveOtp";
import ReceiveResetOtp from "./pages/ReceiveResetOtp";
import SetForgotPassword from "./pages/SetForgotPassword";
import OtpResetFailed from "./pages/report/OtpResetFailed";
import OtpResetSuccess from "./pages/report/OtpResetSuccess";
import ResetPasswordSuccess from "./pages/report/ResetPasswordSuccess";
import AccountRecovery from "./pages/AccountRecovery";
import AuthenticateNewMember from "Auth/AuthenticateNewMember";

// import RememberYou from "./pages/RememberYou";
import { authPath } from "apiServices/paths";
import CreateMandate from "Services/MandateManagement/CreateMandate";

const routes = [
  {
    path: authPath.LOGIN,
    component: Login,
    layout: MainLayout,
  },
  {
    path: authPath.SIGNUP,
    component: Signup,
    layout: MainLayout,
  },

  {
    path: authPath.OXSIGNUP,
    component: OXSignup,
    layout: MainLayout,
  },
  {
    path: authPath.ACCOUNT_RECOVERY,
    component: AccountRecovery,
    layout: MainLayout,
  },

  // {
  //   path: authPath.REMEMBER_YOU,
  //   component: RememberYou,
  //   layout: MainLayout,
  // },
  {
    path: authPath.OTP,
    component: ReceiveOtp,
    layout: ReportLayout,
  },
  {
    path: `${authPath.CREATE_MANDATE}/:id`,
    component: CreateMandate,
    layout: ReportLayout,
  },
  {
    path: authPath.RESET_OTP,
    component: ReceiveResetOtp,
    layout: ReportLayout,
  },
  {
    path: `${authPath.RESET_OTP}/:auth`,
    component: ReceiveResetOtp,
    // layout: "",
  },
  {
    path: authPath.SET_PASSWORD,
    component: SetPassword,
    layout: MainLayout,
  },
  {
    path: authPath.SET_FORGOT_PASSWORD,
    component: SetForgotPassword,
    layout: MainLayout,
  },

  {
    path: authPath.RESET_PASSWORD,
    component: ResetPassword,
    layout: MainLayout,
  },
  {
    path: authPath.FORGOT_PASSWORD,
    component: ForgotPassword,
    layout: MainLayout,
  },
  {
    path: "/authenticate/session-timeout",
    component: SessionTimeout,
    layout: MainLayout,
  },
  {
    path: `${authPath.OTP_FAILED}/:auth`,
    component: OtpFailed,
    layout: ReportLayout,
  },
  {
    path: authPath.OTP_FAILED,
    component: OtpFailed,
    layout: ReportLayout,
  },
  {
    path: `${authPath.OTP_RESET_FAILED}/:auth`,
    component: OtpResetFailed,
    layout: ReportLayout,
  },
  {
    path: authPath.OTP_RESET_FAILED,
    component: OtpResetFailed,
    layout: ReportLayout,
  },
  {
    path: authPath.OTP_SUCCESS,
    component: OtpSuccess,
    layout: ReportLayout,
  },
  {
    path: `${authPath.OTP_SUCCESS}/:auth`,
    component: OtpSuccess,
    layout: ReportLayout,
  },
  {
    path: authPath.OTP_RESET_SUCCESS,
    component: OtpResetSuccess,
    layout: ReportLayout,
  },
  {
    path: `${authPath.OTP_RESET_SUCCESS}/:auth`,
    component: OtpResetSuccess,
    layout: ReportLayout,
  },
  
  {
    path: authPath.PASSWORD_SUCCESS,
    component: PasswordSuccess,
    layout: ReportLayout,
  },
  {
    path: authPath.PASSWORD_RESET_SUCCESS,
    component: ResetPasswordSuccess,
    layout: ReportLayout,
  },
  {
    path: authPath.ADD_NEW_MEMEBER,
    component: AuthenticateNewMember,
    layout: MainLayout,
    nonExact: true,
  },
];

export default routes;
