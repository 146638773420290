import React, { useEffect, useMemo, useState } from "react";
import { Button, Form } from "shared/components";
import {
  Strength,
  StrengthBar,
  Point,
  Bar,
  Title,
  IndicatorRemark,
  IndicatorRemarkLabel,
  StrengthMeter,
  Subtitle,
  Label,
} from "./Styles";

import Listing from "App/assets/icons/Listing-Icon.svg";
import Fullfilled from "App/assets/icons/fullfilled.png";
import Verified from "App/assets/icons/verified.png";

const PasswordMeter = ({ password, setStatusFn }: any) => {
  const [msg, setMsg] = useState("");
  const [meters, setMeters] = useState<any[]>([]);
  const [minimumChar, setMinimumChar] = useState(false);
  const [upperChar, setUpperChar] = useState(false);
  const [numberChar, setNumberChar] = useState(false);
  const [specialChar, setSpecialChar] = useState(false);
  const [allValidated, setAllValidated] = useState(false);
  const [validColor, setValidColor] = useState("");
  let steps: string[] = [];
  const colors = ["orangered", "orangered", "orange", "#37D65C"];

  let meterss = [
    {
      title: "Poor",
      color: "",
    },
    {
      title: "Weak",
      color: "",
    },
    {
      title: "Normal",
      color: "",
    },
    {
      title: "Strong",
      color: "",
    },
  ];

  const checkStrength = (p: any) => {
    let force = 0;
    const regex = /[$-/:-?{-~!"^_@`\[\]]/g;

    const lowerLetters = /[a-z]+/.test(p);
    const upperLetters = /[A-Z]+/.test(p);
    const numbers = /[0-9]+/.test(p);
    const symbols = regex.test(p);

    const flags = [lowerLetters, upperLetters, numbers, symbols];

    let passedMatches = 0;
    for (const flag of flags) {
      passedMatches += flag === true ? 1 : 0;
    }

    force += 2 * p.length + (p.length >= 10 ? 1 : 0);
    force += passedMatches * 10;

    // short password
    force = p.length <= 6 ? Math.min(force, 10) : force;

    // poor variety of characters
    force = passedMatches === 1 ? Math.min(force, 10) : force;
    force = passedMatches === 2 ? Math.min(force, 20) : force;
    force = passedMatches === 3 ? Math.min(force, 30) : force;
    force = passedMatches === 4 ? Math.min(force, 40) : force;

    return force;
  };

  const innitMethod = (value: any) => {
    setBarColors(4, "#DDD");
    passwordStepMarker(steps);
    if (value) {
      const c = getColor(checkStrength(value));
      setBarColors(c.idx, c.col);

      const pwdStrength = checkStrength(value);
      getStepRemarks(password);
      passwordStepMarker(steps);

      switch (c.idx) {
        case 1:
          setMsg("Poor");
          break;
        case 2:
          setMsg("Not Good");
          break;
        case 3:
          setMsg("Average");
          break;
        case 4:
          setMsg("Good");
          break;
      }
    } else {
      setMsg("");
    }
  };

  const getColor = (s: number) => {
    let idx = 0;
    if (s <= 10) {
      idx = 0;
    } else if (s <= 20) {
      idx = 1;
    } else if (s <= 30) {
      idx = 2;
    } else if (s <= 40) {
      idx = 3;
    } else {
      idx = 4;
    }
    return {
      idx: idx + 1,
      col: colors[idx],
    };
  };

  const setBarColors = (count: number, col: string) => {
    for (let n = 0; n < count; n++) {
      meterss[n].color = col;
      setMeters(meterss);
    }
  };
  const getStepRemarks = (p: string) => {
    const regex = /[$-/:-?{-~!"^_@`\[\]]/g;

    const lowerLetters = /[a-z]+/.test(p);
    if (lowerLetters) {
      steps.push("lower");
    }
    const upperLetters = /[A-Z]+/.test(p);
    if (upperLetters) {
      steps.push("uppers");
    }
    const numbers = /[0-9]+/.test(p);
    if (numbers) {
      steps.push("numbers");
    }
    const symbols = regex.test(p);
    if (symbols) {
      steps.push("symbols");
    }
    if (p.length >= 8) {
      steps.push("minimum");
    }
  };

  const passwordStepMarker = (event: string[]) => {
    setAllValidated(false);
    setMinimumChar(false);
    setUpperChar(false);
    setNumberChar(false);
    setSpecialChar(false);
    setValidColor("");

    if (event.includes("minimum")) {
      setMinimumChar(true);
    }
    if (event.includes("uppers")) {
      setUpperChar(true);
    }

    if (event.includes("numbers")) {
      setNumberChar(true);
    }

    if (event.includes("symbols")) {
      setSpecialChar(true);
    }

    if (minimumChar && upperChar && numberChar && specialChar) {
      setAllValidated(true);
      setValidColor("#000000"); //'#34D24A';
    }
  };

  useEffect(() => {
    setMeters(meterss);
    innitMethod(password);
  }, [password]);

  useEffect(() => {
    if (setStatusFn !== undefined) {
      setStatusFn(allValidated);
    }
  }, [allValidated]);

  return (
    <Strength>
      <StrengthBar>
        {meters.map((data: any, ind: number) => {
          return (
            <Point key={ind}>
              <Bar color={data.color}></Bar>
              <Title>{data.title}</Title>
            </Point>
          );
        })}
      </StrengthBar>
      <StrengthMeter>
        <Subtitle>Your password must contain the following:</Subtitle>
        <IndicatorRemark>
          <IndicatorRemarkLabel>
            <span>
              {!minimumChar && !allValidated && <img src={Listing} />}
              {minimumChar && !allValidated && <img src={Fullfilled} />}
              {minimumChar && allValidated && <img src={Verified} />}
            </span>
            <Label>Minimum 8 characters</Label>
          </IndicatorRemarkLabel>
          <IndicatorRemarkLabel>
            <span>
              {!upperChar && !allValidated && <img src={Listing} />}
              {upperChar && !allValidated && <img src={Fullfilled} />}
              {upperChar && allValidated && <img src={Verified} />}
            </span>
            <Label>Contain 1 Capital Letter</Label>
          </IndicatorRemarkLabel>
        </IndicatorRemark>
        <IndicatorRemark>
          <IndicatorRemarkLabel>
            <span>
              {!numberChar && !allValidated && <img src={Listing} />}
              {numberChar && !allValidated && <img src={Fullfilled} />}
              {numberChar && allValidated && <img src={Verified} />}
            </span>
            <Label>Contain 1 number</Label>
          </IndicatorRemarkLabel>
          <IndicatorRemarkLabel>
            <span>
              {!specialChar && !allValidated && <img src={Listing} />}
              {specialChar && !allValidated && <img src={Fullfilled} />}
              {specialChar && allValidated && <img src={Verified} />}
            </span>
            <Label>Contain 1 Special Letter</Label>
          </IndicatorRemarkLabel>
        </IndicatorRemark>
      </StrengthMeter>
    </Strength>
  );
};

export default PasswordMeter;
