import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Route,
  Redirect,
  useRouteMatch,
  Switch,
  Router,
} from "react-router-dom";
import history from "browserHistory";
import Home from "./Home";
import ManageRole from "./ManageRole";
import InviteMember from "./Invite";
import Layout from "Layout";
import { PageError } from "shared/components";

export default function Teams() {
  const match = useRouteMatch();
  const dispatch = useDispatch();
  return (
    //<Layout PageTitle="Team">
    <Router history={history}>
      <Switch>
        <Redirect exact from={`${match.path}/`} to={`${match.path}/overview`} />
        <Route path={`${match.path}/overview/:id`} component={Home} />
        <Route path={`${match.path}/overview`} component={Home} />
        <Route path={`${match.path}/invite/:id`} component={InviteMember} />
        <Route path={`${match.path}/invite`} component={InviteMember} />
        <Route component={PageError} />
      </Switch>
    </Router>
    //</Layout>
  );
}
