import React from 'react'

import Button from 'shared/components/Button'
import Tooltip from 'shared/components/Tooltip'

import {
	clearLocalStorage,
	removeStoredAuthToken,
} from 'shared/utils/authToken'

import feedbackImage from './assets/lock.png'
import {
	FeedbackDropdown,
	FeedbackImageCont,
	FeedbackImage,
	FeedbackParagraph,
} from './Styles'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { logout } from 'store/actions/logout'
import FpLogo from 'App/assets/images/logo.svg'

const LogoutTooltip = (tooltipProps: any) => {
	const dispatch = useDispatch()
	const history = useHistory()
	return (
		<Tooltip
			width={300}
			{...tooltipProps}
			renderContent={() => (
				<FeedbackDropdown>
					<FeedbackImageCont>
						<FeedbackImage src={FpLogo} alt='Give feedback' />
					</FeedbackImageCont>

					<FeedbackParagraph>You are about to logout.</FeedbackParagraph>
					<FeedbackParagraph>
						Are you sure you still want to logout?
					</FeedbackParagraph>

					<Button>Cancel</Button>
					<Button
						variant='fpStyle'
						style={{ marginLeft: 40 }}
						onClick={async () => {
							clearLocalStorage()
							await dispatch(logout())

							history.push('/authenticate')

							// removeStoredRefreshToken();
							// window.location.href = "/authenticate";
						}}>
						Logout
					</Button>
				</FeedbackDropdown>
			)}
		/>
	)
}

export default LogoutTooltip
