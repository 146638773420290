import { ProfilePicture } from "shared/components"
import { InputContainer } from "./Style"
import { ActionButton, UpdateButton } from "../AddSubAgent/styles"
import { useHistory, useParams } from "react-router-dom";
import api from "shared/utils/api";
import { useState } from "react";
import toast from "shared/utils/toast";
import { isDisabled } from '@testing-library/user-event/dist/utils';
import Spinner from "shared/components/spinner/spinner";
import { numberFormat_extra } from "shared/utils/utility";

const Profiles=({agentDetail}: any)=>{
    const params: any = useParams();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isGen, setIsGen] = useState<boolean>(false);
    
    // // console.log(agentDetail)
    const resetPassword=async()=>{
        setIsLoading(true);
        try{
            const payload={
                business_id: agentDetail.businessId,
                agent_id: agentDetail.id
            }
    
            const resp=await api.post("/agency-banking/reset-agents-password", payload);
            if(resp.status){
                toast.success("Password reset successfully")
            }else{
                toast.error("Error occured while trying to reset password.")
            }
        }catch(error: any){
            // console.log(error)
        }finally{
            setIsLoading(false);
        }
    }

    const regenerateAccount=async()=>{
        setIsGen(true);
        try{
            const payload={
                agentId: agentDetail.uuid,
                businessId: agentDetail.businessId,
            }
            const resp=await api.post("/agency-banking/create-account-number", payload);
            if(resp.status){
                agentDetail.bankName=resp.payload.bankName;
                agentDetail.accountNumber=resp.payload.accountNumber;
                toast.success("Account number generated successfully")
            }else{
                toast.error("Error occured while generatng account information.")
            }
        }catch(error: any){
            // console.log(error)
        }finally{
            setIsGen(false);
        }
    }
    return(
        <div style={{display: "flex", flexDirection:"row", gap:"10px"}}>
            <div style={{display: "block", width: "60%", alignContent:"center"}}>
                <p style={{ fontSize: "28px" }}>Profile Detail</p>
                <InputContainer><label>First name</label><p>{agentDetail.firstName}</p> </InputContainer>
                <InputContainer><label>Middle name</label><p>{agentDetail.middleName}</p></InputContainer>
                <InputContainer><label>Last name</label><p>{agentDetail.lastName}</p></InputContainer>
                <InputContainer><label>Gender</label><p>{agentDetail.gender && agentDetail.gender.toLowerCase()}</p></InputContainer>
                <InputContainer><label>Email</label><p>{agentDetail.email && agentDetail.email.toLowerCase()}</p></InputContainer>
                <InputContainer><label>Phone number</label><p>{agentDetail.phone && agentDetail.phone.toLowerCase()}</p></InputContainer>
                <InputContainer><label>LGA</label><p>{agentDetail.lga}</p></InputContainer>
                <InputContainer><label>State</label><p>{agentDetail.state}</p> </InputContainer>
                <InputContainer><label>Country</label><p>{agentDetail.country}</p> </InputContainer>

                <div style={{display: "flex", flexDirection:"row", justifyContent:"left", gap:"10px"}}>
                    <UpdateButton
                        onClick={() => history.push(`/agency/agents/update/${params.id}`)}
                    >
                        Update
                    </UpdateButton>

                    <UpdateButton
                        onClick={() => resetPassword()}
                        isDisabled={isLoading}
                    >
                        {isLoading && <Spinner />}
                        {!isLoading && 'Reset password'}
                    </UpdateButton>
                </div>
            </div>
            <div style={{width: "35%"}}>
                <p style={{ fontSize: "28px" }}>Account Information</p>
                {/* <p
                    style={{
                    fontSize: "0.94rem",
                    color: "#6e7787",
                    }}
                >
                    Balance: <strong> {numberFormat_extra(450000)}</strong>
                </p> */}
                <p>
                    <hr style={{ opacity: "0.2" }} />
                </p>
                <p
                    style={{
                    fontSize: "0.94rem",
                    color: "#6e7787",
                    }}
                >
                    Account Information
                </p>
                <InputContainer>
                    Bank name: {agentDetail.bankName}
                </InputContainer>
                <InputContainer>
                    Account no: {agentDetail.accountNumber}
                </InputContainer>
                <div style={{display: "flex", flexDirection:"row", justifyContent:"left", gap:"10px"}}>
                    <ActionButton
                        isWorking={isGen}
                        onClick={() => regenerateAccount}
                    >
                        Generate account information
                    </ActionButton>
                </div>
                <div style={{display: "flex", flexDirection:"row", justifyContent:"left", gap:"10px"}}>
                    {agentDetail.status=='ACTIVE' &&
                        <ActionButton
                            isWorking={isGen}
                            onClick={() => regenerateAccount}
                        >
                            Deactivate Agent
                        </ActionButton>
                    }

                    {agentDetail.status=='INACTIVE' &&
                        <ActionButton
                            isWorking={isGen}
                            onClick={() => regenerateAccount}
                        >
                            Activate Agent
                        </ActionButton>
                    }
                </div>
            </div>
        </div>
    )
}

export default Profiles;