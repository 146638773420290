import React from "react";

import {
  Container,
  FormContainer,
  Image,
  SubTitle,
  TitleCase,
  FormBody,
  ButtonContainer,
  Back,
  ActionButton,
} from "./Style";
import RightPic from "App/assets/images/services/rightPicCustomer.svg";
import { Form } from "shared/components";

const AddCustomer = () => {
  const inputArray = [
    { id: "fullName", title: "Full Name", type: "text" },
    { id: "emailAddress", title: "Email Address", type: "email" },
    { id: "phoneNumber", title: "Phone Number", type: "tel" },
  ];

  const initialValues = () => {
    let obj: any = {};
    for (let elm of inputArray) {
      obj[elm.id] = "";
    }
    return obj;
  };

  const testMobileNumber = (val: string) => {
    return /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(
      val
    );
  };

  const testEmail = (val: string) => {
    return /.+@.+\..+/.test(val);
  };

  const validations = () => {
    let obj: any = {};
    for (let elm of inputArray) {
      let val: any = "";
      switch (elm.type) {
        case "text":
          val = Form.is.required();
          break;
        case "email":
          val = [
            Form.is.required(),
            Form.is.match(testEmail, "Invalid Email Address"),
          ];
          break;
        case "tel":
          val = [
            Form.is.required(),
            Form.is.match(testMobileNumber, "Invalid Phone Number"),
          ];
          break;
      }
      if (val === "") continue;
      obj[elm.id] = val;
    }
    return obj;
  };

  const onSubmit = () => {};

  const displayFormInput = () => {
    return inputArray.map((elm, idx) => {
      return (
        <Form.Field.Input
          key={`INP${idx}`}
          name={elm.id}
          type={elm.type}
          label={elm.title}
          // tip="Kindly enter your BVN"
        />
      );
    });
  };

  return (
    <Container>
      <FormContainer>
        <TitleCase>Add Customer</TitleCase>
        <SubTitle>
          With your corporate accoun number, you are guaranteed of your
          settlement
        </SubTitle>
        <FormBody>
          {/* @ts-ignore */}
          <Form
            enableReinitialize
            initialValues={initialValues()}
            validations={validations()}
            onSubmit={onSubmit}
          >
            <>
              {displayFormInput()}
              <ButtonContainer>
                <Back>Back</Back>
                <ActionButton>Add</ActionButton>
              </ButtonContainer>
            </>
          </Form>
        </FormBody>
      </FormContainer>

      <Image src={RightPic} />
    </Container>
  );
};

export default AddCustomer;
