import React, { useState } from "react";

import {
  MapContainer,
  StateContainer,
  ViewBtn,
  ViewContainer,
} from "./../Styles";
import { SubAccountData } from "../../../shared/components/FakeDB/SubAccountData";
import EmptyState from "../../../shared/components/EmptyState";
import Table from "shared/components/Table";
import Layout from "Layout";
import DashboardSubHeader from "shared/components/DashboardSubHeader";
import { Switch } from "shared/components";
import consoleLog from "shared/utils/consoleLog";
import { Modal } from "shared/components";
import { color } from "shared/utils/styles";
import { Map, Marker } from "pigeon-maps";
import { osm } from "pigeon-maps/providers";

function Terminal() {
  const [modal, setModal] = useState(false);
  const [searchData, setSearchData] = useState("");

  const openModal = () => {
    setModal(!modal);
  };

  const toggle = () => {
    consoleLog("toggle");
  };

  const headData: any = [
    { id: "date", label: "Date" },
    { id: "time", label: "Time" },
    { id: "location", label: "Location" },
    { id: "amount", label: "Amount" },
    { id: "latlong", label: "Lat and Long" },
    { id: "action", label: "" },
  ];

  const SearchForData = () => {};
  const viewGeolocation = () => {};

  const dummyData = [
    {
      date: "27-04-2024",
      time: "5:00",
      location: "No1, wesco bustop, offilesha, Akure",
      amount: "NGN4500",
      latlong: "0.20003, 0.4090505",
      action: (
        <ViewContainer onClick={() => setModal(!modal)}>
          <ViewBtn>View map</ViewBtn>
        </ViewContainer>
      ),
    },
  ];

  const locations: any = {
    lat: 7.612708921669891,
    long: 5.1847703330591415,
  };

  return (
    <div>
      <DashboardSubHeader
        title={"Terminal: #03094840"}
        btnTitle=""
        callBackFn={() => ""} //setModal(true)
        setSearchData={SearchForData}
        withSearch={true}
        withFilter={false}
        widthActionBtn={false}
        arrToFilter={[]}
        applyFilter={() => ""}
        clickedParam={""}
        getParams={() => ""}
        setStartDate={() => {}}
        setEndDate={() => {}}
      />
      <div>
        <Table
          headData={headData}
          bodyData={dummyData}
          hoverLink
          serialNumber
          clickFunction={viewGeolocation}
          totalPages={0}
          currentpage={0}
          emptyStateMessage="No Geolocation records"
        />
      </div>

      <Modal
        overFlow={true}
        isOpen={modal}
        width={800}
        containerWidth={false}
        withCloseIcon={true}
        onClose={() => setModal(false)}
        renderContent={() => (
          <MapContainer>
            <Map
              provider={osm}
              height={500}
              width={1070}
              defaultCenter={[locations.lat, locations.long]}
              defaultZoom={11}
            >
              <Marker width={50} anchor={[locations.lat, locations.long]} />
            </Map>
          </MapContainer>
        )}
      />
    </div>
  );
}

export default Terminal;
