import {
  ActionButton,
  StatisticsAmountBlue,
  StatisticsAmountBrown,
  StatisticsAmountGreen,
  StatisticsAmountRed,
  StatisticsBox,
  StatisticsBoxFour,
  StatisticsCircleBlue,
  StatisticsCircleBrown,
  StatisticsCircleGreen,
  StatisticsCircleRed,
  StatisticsDate,
  StatisticsHeading,
  StatisticsRow,
} from "Agency/Styled";
import Layout from "Layout";
import EmptyState from "shared/components/FreeComp/EmptyState";
import Table from "shared/components/Table";
import BrownIcon from "../../App/assets/images/icons/agency-brown-statistics.svg";
import BlueIcon from "../../App/assets/images/icons/agency-blue-statistics.svg";
import GreenIcon from "../../App/assets/images/icons/agency-green-statistics.svg";
import RedIcon from "../../App/assets/images/icons/agency-red-statistics.svg";
import TotalIcon from "../../App/assets/images/icons/agency-total-transaction.svg";
import { OverviewPage } from "./Styles";
import {
  TotalAmount,
  TotalContainer,
  TotalContainerLeft,
  TotalContainerRIght,
  TotalContainerRIghtCircle,
  TotalHeading,
} from "Agency/Transactions/Styled";
import { currencyFormat } from "shared/utils/utility";
import { useEffect, useState } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import api from "shared/utils/api";
import { Spinner } from "shared/components";

const todaysDate = new Date().toLocaleDateString("en-us", {
  year: "numeric",
  month: "short",
  day: "numeric",
});
const Overview = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [totalTransaction, setTotalTransactions] = useState<number>(0.0);
  const [agents, setAgents] = useState<number>(0);
  const [mappedTerminal, setMappedTerminal] = useState<number>(0);
  const [daily, setDaily] = useState<string>("0.00");
  const [weekly, setWeekly] = useState<string>("0.00");
  const [monthly, setMonthly] = useState<string>("0.00");
  const [yearly, setAYearly] = useState<string>("0.00");
  const [summary, setsummary] = useState<any>({});

  const business = useSelector((store: RootStateOrAny) => store.business);

  const getBusinessSummary = async () => {
    setIsLoading(true);
    try {
      const res = await api.get(`/agency-banking/summary/${business.id}`);
      const {
        totalAgents,
        totalMappedTerminals,
        totalActiveTerminals,
        sumOfTodaysTransactions,
        noOfTodaysTransactions,
        sumOfWeeklyTransactions,
        noOfWeeklyTransactions,
        sumOfMonthlyTransactions,
        noOfMonthlyTransactions,
        sumOfYearlyTransactions,
        noOfYearlyTransactions,
        sumOfTotalTransactions,
        sumOfSuccessfulTransactions,
        sumOfPendingTransactions,
        sumOfFailedTransactions,
      } = res?.payload;
      setAgents(totalAgents);
      setMappedTerminal(totalMappedTerminals);
      setDaily(sumOfTodaysTransactions);
      setWeekly(sumOfWeeklyTransactions);
      setMonthly(sumOfMonthlyTransactions);
      setAYearly(sumOfYearlyTransactions);
      setTotalTransactions(sumOfTotalTransactions);

      setsummary(res?.payload);
    } catch (error) {
      // // console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getBusinessSummary();
  }, []);
  return (
    <>
      <OverviewPage>
        <StatisticsRow>
          <StatisticsBox>
            <StatisticsCircleBlue>
              <img alt="current balance" src={BlueIcon} />
            </StatisticsCircleBlue>
            <StatisticsHeading>Total agent</StatisticsHeading>
            <StatisticsDate>as at {todaysDate}</StatisticsDate>
            <StatisticsAmountBlue>
              {isLoading ? <Spinner /> : agents}
            </StatisticsAmountBlue>
          </StatisticsBox>
          <StatisticsBox>
            <StatisticsCircleGreen>
              <img alt="current balance" src={GreenIcon} />
            </StatisticsCircleGreen>
            <StatisticsHeading>Total mapped terminals</StatisticsHeading>
            <StatisticsDate>as at {todaysDate}</StatisticsDate>
            <StatisticsAmountGreen>
              {isLoading ? <Spinner /> : mappedTerminal}
            </StatisticsAmountGreen>
          </StatisticsBox>
          <StatisticsBox>
            <StatisticsCircleRed>
              <img alt="current balance" src={RedIcon} />
            </StatisticsCircleRed>
            <StatisticsHeading>Total active terminals</StatisticsHeading>
            <StatisticsDate>as at {todaysDate}</StatisticsDate>
            <StatisticsAmountRed>
              {isLoading ? <Spinner /> : summary?.totalActiveTerminals}
            </StatisticsAmountRed>
          </StatisticsBox>
        </StatisticsRow>

        <StatisticsRow>
          <StatisticsBoxFour>
            <StatisticsCircleBlue>
              <img alt="current balance" src={BlueIcon} />
            </StatisticsCircleBlue>
            <StatisticsHeading>Daily Transaction</StatisticsHeading>
            <StatisticsAmountBlue>
              {isLoading ? <Spinner /> : `NGN${daily}`}
            </StatisticsAmountBlue>
          </StatisticsBoxFour>
          <StatisticsBoxFour>
            <StatisticsCircleGreen>
              <img alt="current balance" src={GreenIcon} />
            </StatisticsCircleGreen>
            <StatisticsHeading>Weekly Transaction</StatisticsHeading>
            <StatisticsAmountGreen>
              {isLoading ? <Spinner /> : `NGN${weekly}`}
            </StatisticsAmountGreen>
          </StatisticsBoxFour>
          <StatisticsBoxFour>
            <StatisticsCircleRed>
              <img alt="current balance" src={RedIcon} />
            </StatisticsCircleRed>
            <StatisticsHeading>Monthly Transaction</StatisticsHeading>
            <StatisticsAmountRed>
              {isLoading ? <Spinner /> : `NGN${monthly}`}
            </StatisticsAmountRed>
          </StatisticsBoxFour>
          <StatisticsBoxFour>
            <StatisticsCircleBrown>
              <img alt="current balance" src={BrownIcon} />
            </StatisticsCircleBrown>
            <StatisticsHeading>Yearly Transaction</StatisticsHeading>
            <StatisticsAmountBrown>
              {isLoading ? <Spinner /> : `NGN${yearly}`}
            </StatisticsAmountBrown>
          </StatisticsBoxFour>
        </StatisticsRow>

        <TotalContainer>
          <TotalContainerLeft>
            <TotalHeading>Total Transaction</TotalHeading>
            <TotalAmount>
              {isLoading ? <Spinner /> : currencyFormat(totalTransaction)}
            </TotalAmount>
          </TotalContainerLeft>
          <TotalContainerRIght>
            <TotalContainerRIghtCircle>
              <img alt="current balance" src={TotalIcon} />
            </TotalContainerRIghtCircle>
          </TotalContainerRIght>
        </TotalContainer>

        <StatisticsRow>
          <StatisticsBox>
            <StatisticsCircleBlue>
              <img alt="current balance" src={BlueIcon} />
            </StatisticsCircleBlue>
            <StatisticsHeading>Pending Transactions</StatisticsHeading>
            <StatisticsDate>as at {todaysDate}</StatisticsDate>
            <StatisticsAmountBlue>
              {isLoading ? (
                <Spinner />
              ) : (
                `NGN${summary?.sumOfPendingTransactions ?? 0.0}`
              )}
            </StatisticsAmountBlue>
          </StatisticsBox>
          <StatisticsBox>
            <StatisticsCircleGreen>
              <img alt="current balance" src={GreenIcon} />
            </StatisticsCircleGreen>
            <StatisticsHeading>Successful Transactions</StatisticsHeading>
            <StatisticsDate>as at {todaysDate}</StatisticsDate>
            <StatisticsAmountGreen>
              {isLoading ? (
                <Spinner />
              ) : (
                `NGN${summary?.sumOfSuccessfulTransactions ?? 0.0}`
              )}
            </StatisticsAmountGreen>
          </StatisticsBox>
          <StatisticsBox>
            <StatisticsCircleRed>
              <img alt="current balance" src={RedIcon} />
            </StatisticsCircleRed>
            <StatisticsHeading>Failed Transactions</StatisticsHeading>
            <StatisticsDate>as at {todaysDate}</StatisticsDate>
            <StatisticsAmountRed>
              {isLoading ? (
                <Spinner />
              ) : (
                `NGN${summary?.sumOfFailedTransactions ?? 0.0}`
              )}
            </StatisticsAmountRed>
          </StatisticsBox>
        </StatisticsRow>
      </OverviewPage>
    </>
  );
};

export default Overview;
