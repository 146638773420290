import history from "browserHistory";
import {
  Redirect,
  Route,
  Router,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";

import Welcome from "./Welcome";
import PersonalDetails from "./PersonalDetails";
import BusinessType from "./BusinessType";
import GatewayBusinessDetails from "./Gateway/BusinessDetails";
import AddAccount from "./Gateway/AddAccount";
import GatewayUploadDocs from "./Gateway/UploadDocs";
import KycComplete from "./KycComplete";
import AgencyBusinessDetails from "./AgencyBanking/BusinessDetails";
import ResidentialInfo from "./AgencyBanking/ResidentialInfo";
import BusinessInformation from "./AgencyBanking/BusinessInfo";
import ChooseBusiness from "./Welcome/chooseBusiness";
import AgencyUploadDocs from "./AgencyBanking/UploadDocs";
import { PageError } from "shared/components";
import { ParentCont } from "./Style";
import { useLayoutEffect } from "react";
import { getStoredAuthToken } from "shared/utils/authToken";

const KYC = () => {
  useLayoutEffect(() => {
    const token = getStoredAuthToken();
    if (!token) {
      history.push("/authenticate");
    }
  }, []);
  return (
    <ParentCont>
      <Router history={history}>
        <Switch>
          <Route
            path={"/kyc/agency-banking-upload-documents"}
            component={AgencyUploadDocs}
          />
          <Route
            path={"/kyc/business-information"}
            component={BusinessInformation}
          />
          <Route
            path={"/kyc/residential-information"}
            component={ResidentialInfo}
          />
          <Route
            path={"/kyc/agency-banking-business-details"}
            component={AgencyBusinessDetails}
          />
          <Route path={"/kyc/complete"} component={KycComplete} />
          <Route
            path={"/kyc/gateway-upload-documents"}
            component={GatewayUploadDocs}
          />
          <Route path={"/kyc/add-account-details"} component={AddAccount} />
          <Route
            path={"/kyc/gateway-business-details"}
            component={GatewayBusinessDetails}
          />
          <Route path={"/kyc/business-type"} component={BusinessType} />
          {/* KycBoardingRec not addded below */}
          <Route path={"/kyc/personal-details"} component={PersonalDetails} />

          <Route exact path={"/kyc/welcome"} component={Welcome} />
          <Route
            exact
            path={"/kyc/choose-business"}
            component={ChooseBusiness}
          />
          <Redirect exact from="/kyc" to="/kyc/welcome" />
          <Route path="*" component={PageError} />
        </Switch>
      </Router>
    </ParentCont>
  );
};

export default KYC;
