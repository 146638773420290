import { useEffect, useState } from "react";
import { Checkbox, Input } from "shared/components";
import { FilterInputCont, FilterParamsCont, FilterSec, FilterText, InputHolder, PopUpSubHead, TextDescription } from "shared/components/DashboardSubHeader/Styles";
import Daterange from "./Daterange";

const date = new Date(); 
let currentDay= String(date.getDate()).padStart(2, '0');
let currentMonth = String(date.getMonth()+1).padStart(2,"0");
let currentYear = date.getFullYear();
// we will display the date as DD-MM-YYYY 
let currentDate = `${currentDay}/${currentMonth}/${currentYear}`

type PropT = {
    onProcessFilter: Function
}
const CustomerFilter=({onProcessFilter}: PropT)=>{
    const [start, setStart] = useState<string>(currentDate);
    const [end, setEnd] = useState<string>(currentDate);
    const [search, setSearch] = useState<string>("");

    const handleSelection=(startDate: string, endDate: string)=>{
        setStart(startDate);
        setEnd(endDate);
        onChangeTextHandler();
    }
    const onChangeTextHandler=()=>{
        onProcessFilter({date:{start, end}, search})
    }

    return (
        <FilterSec>
            <PopUpSubHead>Search transaction</PopUpSubHead>
            <FilterText>
                <TextDescription>Search</TextDescription>
                <Input
                  name={"search"}
                  type="text"
                  value={search}
                  onChange={(e: any) => {
                        setSearch(e); 
                        onChangeTextHandler();
                    }
                }
                />
              </FilterText>

            <PopUpSubHead>Date range</PopUpSubHead>
            <FilterInputCont>
                <Daterange handleSelection={handleSelection} />
                {/* <InputHolder>
                    <Input
                        name={"start"}
                        type={"date"}
                        value={start}
                        placeholder={"Start date"}
                        onChange={(e: any) =>{setStart(e); onChangeTextHandler();}}
                    />
                </InputHolder>

                <InputHolder>
                    <Input
                        name={"end"}
                        type={"date"}
                        value={end}
                        placeholder={"End date"}
                        onChange={(e: any) =>{setEnd(e); onChangeTextHandler();}}
                    />
                </InputHolder> */}
            </FilterInputCont>

        </FilterSec>
    );
}

export default CustomerFilter;