import { useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { Form, Icon } from "shared/components";
import ImageLoader from "shared/components/ImageLoader";
import ServicesClass from "shared/utils/classes/services/service";
import { onChangeTextHandler, validations } from "shared/utils/formValidation";
import toast from "shared/utils/toast";
import subaccountClass from "SubAccount/subaccountClass";
import {
  Back,
  Button,
  ButtonCont,
  Container,
  FormBody,
  FormContainer,
  ImageDiv,
  SubTitle,
  TitleCase,
  Break,
} from "./Style";
import shouldPerform, { getRoleName } from "shared/utils/assignRoles";
import consoleLog from "shared/utils/consoleLog";
import { useHistory } from "react-router-dom";

interface Props {
  openModal: Function;
  fetchData: Function;
  backFn?: any;
}

const AddSubAcccount = ({ fetchData, openModal, backFn }: Props) => {
  const [dropDown, setDropDown] = useState(false);
  const [dropDown2, setDropDown2] = useState(false);
  const [bankList, setbankList] = useState([]);
  const [accountNumber, setaccountNumber] = useState<string>("");
  const [accountName, setaccountName] = useState<string>("");
  const [selectedBank, setselectedBank] = useState<any>({});
  const [serviceList, setserviceList] = useState([]);
  const [selectedService, setselectedService] = useState<any>({});
  const [subaccountName, setsubaccountName] = useState<string>("");
  const [btnActive, setbtnActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [btnText, setbtnText] = useState("Create");
  const [banknameStatus, setbanknameStatus] = useState("");
  const [fstate, setFstate] = useState({} as any);
  const currentUser = useSelector((store: RootStateOrAny) => store.currentUser);
  const history = useHistory();
  const currentbusiness = useSelector(
    (store: RootStateOrAny) => store.business
  );
  const dispatch = useDispatch();
  const inputArray: any = [
    {
      id: "bankName",
      title: "Bank Name For Payout",
      type: "select",
      options: () => {
        let optArr = [{ label: "Select bank", value: "" }, ...bankList];
        return optArr;
      },
      required: true,
    },

    {
      id: "accountNumber",
      title: "Bank Account Number",
      type: "acctNum",
      required: true,
    },
    {
      id: "accountName",
      title: "Account Name",
      type: "text",
      required: true,
      disabled: true,
    },
    {
      id: "subaccountName",
      title: "Name of Sub Account",
      type: "text",
      required: true,
    },
  ];

  const displayFormInput = () => {
    return inputArray.map((elm: any, idx: any) => {
      switch (elm.type) {
        case "select":
          return (
            <Form.Field.Select
              key={`INP${idx}`}
              name={elm.id}
              label={elm.title}
              options={elm.options()}
              onChange={(val: any) => {
                onChangeTextHandler(val, elm.id, fstate, setFstate);
                // alert(val);
              }}
              value={fstate[elm.id]}
              //  renderOption={""}
              //  renderValue={""}
              // tip="Kindly enter your user password."
            />
          );
        case "text":
          return (
            <>
              <Form.Field.Input
                key={`INP${idx}`}
                name={elm.id}
                type={elm.type}
                value={fstate[elm.id] ?? ""}
                label={elm.title}
                disabled={elm.disabled}
                //   placeholder={elm?.placeholder}
                onChange={(val: any) =>
                  onChangeTextHandler(val, elm.id, fstate, setFstate)
                }
                // tip="Kindly enter your BVN"
              />
              {elm.id === "accountName" && banknameStatus}
            </>
          );
        case "acctNum":
          return (
            <Form.Field.Input
              key={`INP${idx}`}
              name={elm.id}
              type="number"
              label={elm.title}
              pattern="\d*"
              value={fstate[elm.id] ?? ""}
              maxlength="10"
              // onKeyUp={(e: any) => elm.onkeyup && elm.onkeyup(e)}
              onChange={(val: any) => {
                if (val.length <= 10)
                  onChangeTextHandler(val, elm.id, fstate, setFstate);
              }}
              onFocus={() => setIsLoading(true)}
              onBlur={() => setIsLoading(false)}
              // tip="Kindly enter your BVN"
            />
          );
      }
    });
  };

  const initialValues = () => {
    let obj: any = {};
    for (let elm of inputArray) {
      obj[elm.id] = "";
    }
    return obj;
  };
  const getAllBanks = async () => {
    const banks = await subaccountClass.getAllBanks();
    const sortedBanks = banks.sort((a: any, b: any) =>
      a.name > b.name ? 1 : b.name > a.name ? -1 : 0
    );
    if (sortedBanks) {
      let result = sortedBanks.map((el: any) => {
        return { value: el?.id, label: el?.name, code: el?.code };
      });
      setbankList(result);
    }
  };

  //=========================================================
  const getAllServices = async () => {
    const res = await ServicesClass.getAllServices(currentbusiness.id);
    setserviceList(res);
    // consoleLog("all", res);
    //================= To be fetch LS not BackEnd ==========
  };


  const getAccountName = async () => {
    setIsLoading(true);
    setFstate({ ...fstate, accountName: "" });
    let bankObj: any = bankList.find((el: any) => el.value === fstate.bankName);
    let bankCode;
    if (bankObj) {
      bankCode = bankObj?.code;
    }


    if (bankCode && fstate?.accountNumber?.length > 9) {
      setbanknameStatus("Loading..");
      try {
        const res = await subaccountClass.getAccountName(
          fstate.accountNumber,
          bankCode
        );
        if (res && res?.accountName) {
          setFstate({ ...fstate, accountName: res.accountName });
          //   setbtnActive(true);
        }
      } catch (error) {
        setbtnActive(false);
        setFstate({ ...fstate, accountName: "" });
        consoleLog(error + "Error..1");
      } finally {
        setbanknameStatus("");
      }
      // consoleLog("bank name", res);
    } else {
      setbtnActive(false);
    }
    setIsLoading(false);
  };

  const checkActiveButton = () => {
    if (
      fstate.subaccountName &&
      fstate.accountName &&
      fstate.accountNumber &&
      fstate.accountNumber?.length === 10 &&
      fstate.bankName &&
      !isLoading
    ) {
      setbtnActive(true);
    } else {
      setbtnActive(false);
    }
  };

  const createSubAccount = async () => {
    setbtnText("Processing...");
	try{
		let bankObj: any = bankList.find((el: any) => el.value === fstate.bankName);
		let bankCode = "";
		let bankName = "";
		let bankId = "";
		if (bankObj) {
			bankCode = bankObj?.code;
			bankName = bankObj?.label;
			bankId = bankObj?.value;
		}
		const data = {
			name: fstate.subaccountName,
			// bankName,
			accountNumber: fstate.accountNumber,
			bankAccountName: fstate.accountName,
			// bankCode,
			bankId,
		};
		// consoleLog("DATA:", data);

		if (btnActive) {
			const res = await subaccountClass.createSubAccount(data);
			if (res === "Subaccount created successfully") {
				consoleLog("create", res);
				toast.success(res);
				if (backFn) {
					backFn && backFn();
					fetchData(fstate.subaccountName);
				} else {
					setTimeout(() => {
						openModal();
						fetchData();
						//window.location.reload(); // To be remove later
					}, 4000);
				}
			}
		}
	}catch(error: any){
		//
	}finally{
		setbtnText("Create");
	}
    
  };

  useEffect(() => {
    getAllBanks();
    getAllServices();
  }, []);

  useEffect(() => {
    getAccountName();
  }, [fstate.accountNumber, fstate.bankName]);

  useEffect(() => {
    checkActiveButton();
  }, [fstate]);

  const onSubmit = () => {};

  if (
    !shouldPerform(
      getRoleName(currentUser, currentbusiness?.id),
      "CPaySplitSub"
    )
  ) {
    history.push("/authenticate");
    return null;
  }

  return (
    <Container>
      <FormContainer>
        <TitleCase>Create Subaccount</TitleCase>
        <SubTitle>
          This feature allows you to add to your business profile a <Break />
          complimentary service you render
        </SubTitle>
        <FormBody>
          {/* @ts-ignore */}

          <Form
            enableReinitialize
            initialValues={initialValues()}
            validations={validations(inputArray, Form)}
            onSubmit={onSubmit}
          >
            <>
              {displayFormInput()}
              <ButtonCont>
                {backFn && (
                  <Back onClick={backFn}>
                    <Icon type="arrow-left-circle" size={20} />
                  </Back>
                )}
                <Button isActive={btnActive} onClick={createSubAccount}>
                  {btnText}
                </Button>
              </ButtonCont>
            </>
          </Form>
        </FormBody>
      </FormContainer>
      <ImageDiv>
        <ImageLoader
          source={"/assets/place-holders/Tablet-login-rafiki.png"}
          width="220"
          height="500"
          alt="Add account"
          draggable="false"
          style={{
            pointerEvents: "none",
          }}
        />
      </ImageDiv>
      {/* <Image
        src="/assets/place-holders/Tablet-login-rafiki.png"
        alt="login image"
        draggable="false"
        style={{ pointerEvents: "none" }}
      /> */}
    </Container>
  );
};

export default AddSubAcccount;
