import styled from 'styled-components';

export default styled.div`
  width: 100%;
  text-align: center;
  position: absolute;
  top: 50%;
    left: 0;
    right: 0;
    margin: auto;
`;
