import { getCurrentBusiness } from "shared/utils/businessesData";
import { ActionTypes } from "../constants/actionType";


const initialState = {
  businesses: [],
  currentBusiness: null,
  loading: true,
  currentBusinesKycs:{id: "", status:true, platform:''} 
};

export const businessesReducer = (
  state: any = initialState.businesses,
  action: any
) => {
  switch (action.type) {
    case ActionTypes.SET_BUSINESS_LISTS:
      return action.payload;
    default:
      return state;
  }
};

export const businessesLoading = (
  state: any = initialState.loading,
  action: any
) => {
  switch (action.type) {
    case ActionTypes.SET_BUSINESS_LIST_LOADING:
      return false;
    default:
      return state;
  }
};

export const businessReducer = (
  state: any = initialState.currentBusiness,
  action: any
) => {
  switch (action.type) {
    case ActionTypes.SET_CURRENT_BUSINESS: {
      return action.payload;
    }
    default:
      return getCurrentBusiness() || state;
  }
};

export const businessKycReducer = (
  state: any = initialState.currentBusinesKycs,
  action: any
) => {
  switch (action.type) {
    case ActionTypes.SET_CURRENT_BUSINESS_KYC: {
      return action.payload;
    }
    default:
      return state;
  }
};
