import Layout from "Layout";
import { Redirect, Route, useRouteMatch } from "react-router-dom";
import Main from "./Main";
import Report from "./StatusReport";
import { SystemStatusPage } from "./Styled";

function SystemStatus() {
  const match = useRouteMatch();
  return (
    //<Layout PageTitle="System Status">
      <SystemStatusPage>
        <Route path={`${match.path}/all`} render={() => <Main />} />
        <Route path={`${match.path}/report`} render={() => <Report />} />
        {match.isExact && <Redirect to={`${match.url}/all`} />}
      </SystemStatusPage>
    //</Layout>
  );
}

export default SystemStatus;
