import breakpoints from "shared/utils/breakpoints";
import styled from "styled-components";

export const TransactionsPage = styled.div``;

export const TotalContainer = styled.div`
  background: #760000;
  box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.05);
  border-radius: 18px;
  display: flex;
  justify-content: space-between;
  padding: 25px 35px;
  margin-bottom: 25px;
`;

export const TotalContainerLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const TotalHeading = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  margin-bottom: 15px;
`;

export const TotalAmount = styled.p`
  font-weight: 600;
  font-size: 32px;
  line-height: 38px;
  letter-spacing: -0.02em;
  color: #ffffff;
  @media ${breakpoints.device.max.xs} {
    font-size: 24px;
    margin-right: 15px;
  }
`;

export const TotalContainerRIght = styled.div``;

export const TotalContainerRIghtCircle = styled.div`
  border-radius: 50%;
  background-color: #ffffff;
  width: 108px;
  height: 108px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const StateContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
