import Layout from "Layout";
import { useEffect, useState } from "react";
import DashboardSubHeader from "shared/components/DashboardSubHeader";
import Table from "shared/components/Table";
import EmptyState from "shared/components/FreeComp/EmptyState";
import Api from "shared/utils/api";
import { useHistory } from "react-router-dom";
import chargeBackClass from "./chargeBackClass";
import { Dot } from "./Styles";
import { useSelector, RootStateOrAny } from "react-redux";
import Preloader from "shared/components/preloader";
import toast from "shared/utils/toast";
import api from "shared/utils/api";
import consoleLog from "shared/utils/consoleLog";
import shouldPerform, { getRoleName } from "shared/utils/assignRoles";
import { GradientLoader } from "shared/components";

function Index() {
  const [visible, setVisible] = useState(false);
  const history = useHistory();
  const [data, setData] = useState<any>([]);
  const [chargeBack, setChargeBack] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const currentUser = useSelector((store: RootStateOrAny) => store.currentUser);

  const business = useSelector((store: RootStateOrAny) => store.business);
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");

  const [filterFstate, setFilterFstate] = useState({});
  const [filteredFormState, setFilteredFormState] = useState<any>({});

  const [params, setParams] = useState<any>({});
  const [openDownload, setOpenDownload] = useState<boolean>(false);

  const formatMyDate = (value: string) => {
    let formatedDate = [];
    let arr = [];
    formatedDate.push(value.split("-")[0]);
    formatedDate.push(value.split("-")[1]);
    formatedDate.push(value.split("-")[2].split("T")[0]);
    arr.push(formatedDate[2]);
    arr.push(formatedDate[1]);
    arr.push(formatedDate[0]);
    let properDate = arr.join("/");
    return properDate;
  };

  const headData: any = [
    { id: "amount", label: "Amount" },
    { id: "accountName", label: "Customer Name" },
    { id: "createdAt", label: "Created on" },
    { id: "dueDate", label: "Due" },
    { id: "status", label: "Status" },
  ];

  const CheckStatus: any = (props: any) => {
    return (
      <p>
        <Dot
          style={{
            backgroundColor:
              props.status === "OPEN"
                ? "red"
                : props.status === "CLOSED"
                ? "green"
                : "orange",
          }}
        ></Dot>
        {props.status}
      </p>
    );
  };

  const disputes =
    data instanceof Array &&
    data.map((dispute: any) => ({
      amount: `${dispute.amount
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}.00`,
      id: dispute.id,
      accountName: dispute.accountName,
      // createdAt: new Date(dispute?.createdAt)?.toLocaleDateString(),
      createdAt: formatMyDate(dispute.createdAt),
      // dueDate: new Date(dispute?.dueDate)?.toLocaleDateString(),
      dueDate: formatMyDate(dispute.updatedAt),
      status: <CheckStatus status={dispute.status} />,
      origStatus: dispute.status,
    }));

  const getChargeBack = async (obj = {}) => {
    setLoading(true);
    obj = obj instanceof Object ? obj : {};
    const response = await chargeBackClass.getChargeBack(currentUser.id, obj);
    if (response?.status === "success") {
      setData(response?.data);
      setChargeBack(response?.data);
      setLoading(false);
    } else {
      setLoading(false);
      consoleLog("err ", response?.data);
      let message: any =
        response?.data === undefined
          ? "error fetching dispute"
          : response?.data;
      toast.error(message);
    }
  };

  const searchChargeBack = async (e: string) => {
    if (e === "") {
      setData(chargeBack);
    } else {
      try {
        const response: any = await api.get(
          `/fp_admin/dispute/business/${business.id}?page=1&search=${e}&items=10`,
          filteredFormState
        );
        // consoleLog('result search ', response);
        if (response.payload.lenght < 1) {
          toast.error(response.message);
        } else {
          toast.success(response.message);
        }
        setData(response.payload);
      } catch (error) {
        // consoleLog(error);
        //@ts-ignore
        toast.error(error);
      }
    }
  };

  const clickTableRow = (data: any) => {
    history.push(`/charge-back-detail/${data.id}`);
  };

  const chargeBackFilter = [
    {
      id: "createdAt",
      type: "date",
      title: "Date",
      list: [
        { title: "Start Date", name: "startDate", type: "start" },
        { title: "End Date", name: "endDate", type: "end" },
      ],
    },
    {
      id: "status",
      type: "flag",
      title: "Status",
      list: [
        { title: "Awaiting Response", name: "awaitingResponse" },
        { title: "Resolved", name: "resolved" },
      ],
    },
    {
      id: "resolution",
      type: "flag",
      title: "Resolution",
      list: [
        { title: "Auto Accepted", name: "autoAccepted" },
        { title: "Accepted", name: "accepted" },
        { title: "Declined", name: "declined" },
      ],
    },
  ];

  const filterChargeBacks = async (obj: any) => {
    consoleLog(obj, "Object");
    obj = obj instanceof Object ? obj : {};
    setFilteredFormState(obj);

    await getChargeBack({
      ...obj,
    });
  };

  const resetFilter = () => {
    setData(chargeBack);
  };

  const donwloadChargeback = async () => {
    ///not implemented
    setLoading(true);
    try {
      const response: any = await api.get(
        `/fp_admin/dispute/${business.id}/download?period=day&startDate=${startDate}&endDate=${endDate}`
      );
      // consoleLog('response :> ', response);
      if (response.payload.length > 0) {
        toast.success(response.message);
        const result = response.blob();
        const url = window.URL.createObjectURL(new Blob([result]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `chargeback_download_sumary.pdf`);
        // document.body.appendChild(link);
        link.click();
        // link.parentNode.removeChild(link);
      } else {
        toast.error("Sorry you dont have any charge back data");
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      // consoleLog(error);
      //@ts-ignore
      toast.error(error);
    }
    setStartDate("");
    setEndDate("");
  };

  const donwload = () => {
    if (startDate !== "" && endDate === "") {
      toast.error("Please enter an end date for your download");
    } else if (startDate === "" && endDate !== "") {
      toast.error("Please enter a start date for your download");
    } else {
      // donwloadChargeback(); //not working....
    }
  };

  //consoleLog(disputes, "disputes");

  let summaryData = () => {
    let resArr: any = [];
    let nArr: any = [];
    for (let el of headData) {
      nArr.push(el.label);
    }
    resArr.push(nArr);
    if (disputes instanceof Array) {
      for (let el of disputes as any) {
        nArr = [];
        for (let elm of headData) {
          let val = elm?.id;
          if (val == "status") {
            val = "origStatus";
          }
          if (val && el[val]) nArr.push(el[val]);
        }
        resArr.push(nArr);
      }
    }

    return resArr;
  };
  if (!shouldPerform(getRoleName(currentUser, business?.id), "MDisputes")) {
    history.push("/authenticate");
    return null;
  }

  // consoleLog(chargeBack, "chargeBack");
  // consoleLog(chargeBack?.length ?? 0, "chargeBack");

  return (
    <>
      <DashboardSubHeader
        count={chargeBack?.length ?? 0}
        title={`Disputes`}
        btnTitle="Download Summary"
        callBackFn={() => setOpenDownload(!openDownload)}
        clickedParam={"Customer Email"}
        setSearchData={searchChargeBack}
        withSearch={(chargeBack?.length ?? 0) > 0 ? true : false}
        withFilter={(chargeBack?.length ?? 0) > 0 ? true : false}
        widthActionBtn={(chargeBack?.length ?? 0) > 0 ? true : false}
        arrToFilter={chargeBackFilter}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        resetFilter={resetFilter}
        // fstate={filterFstate}
        // setFstate={setFilterFstate}
        getParams={setParams}
        applyFilter={filterChargeBacks}
        openDownload={openDownload}
        setOpenDownload={setOpenDownload}
        // downloadData={donwload}
        excelExport
        excelExportData={summaryData()}
        setFilterFormState
      />

      {loading && <GradientLoader />}
      {!chargeBack || chargeBack?.length === 0 ? (
        <div>
          <EmptyState
            openModal={() => setVisible(true)}
            stateTitle="No Disputes"
            statePara=""
            stateBtnText=""
          />
        </div>
      ) : (
        <Table
          headData={headData}
          bodyData={disputes}
          clickFunction={clickTableRow}
          hoverLink={clickTableRow}
        />
      )}
    </>
  );
}

export default Index;
