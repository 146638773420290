import { useState } from "react";
import { setDatasets } from "react-chartjs-2/dist/utils";
import {
  Heading,
  TableContainer,
  TableDetails,
  TableRootContainer,
  PaginationNext,
  PaginationPrev,
  PaginationButtonsContainer,
  PaginationContainer,
} from "./Styles";
import TableHead from "./TableHead";
import TableRow from "./TableRow";
import consoleLog from "shared/utils/consoleLog";
import EmptyState from "../EmptyState";
import Pagination from "../Pagination";

interface Props{
  headData: any;
  bodyData?: any;
  hoverLink?: any
  clickFunction?: any;
  rowStyle?: any;
  serialNumber?: boolean;
  currentpage?: number;
  numberOfItems?: number;
  totalPages?: number;
  goToPage?: any;
  emptyStateMessage?: string;
}
const Table = ({
  headData,
  bodyData,
  hoverLink,
  clickFunction,
  serialNumber,

  currentpage = 1,
  totalPages = 1,
  numberOfItems = 10,
  goToPage,
  emptyStateMessage="No data to display"
}: Props) => {

  if (typeof currentpage === "string") {
    currentpage = Number(currentpage);
  }

  return (
    <>
      <TableRootContainer>
        <TableContainer>
          <thead>
            <Heading>
              {serialNumber && <TableHead key="sn" item="S/N" />}
              {headData.map((data: any) => {
                return (
                  <TableHead
                    key={data.id}
                    item={data.label}

                    // onClick={() => sorting(data.id)}
                  />
                );
              })}
            </Heading>
          </thead>
          {bodyData.length ? (
            <TableDetails>
              {bodyData.map((data: any, index: number) => {
                return (
                  <TableRow
                    serialNumber={serialNumber}
                    index={index}
                    key={`${index}}a`}
                    data={data}
                    headData={headData}
                    hoverLink={hoverLink}
                    clickFunction={clickFunction}
                  />
                );
              })}
            </TableDetails>
          ):(
            <TableDetails>
              <tr>
                <td colSpan={headData.length + 1} style={{textAlign: "center"}}>
                  <EmptyState stateTitle={emptyStateMessage} />
                </td>
              </tr>
                
            </TableDetails>
          )}
          
        </TableContainer>
      </TableRootContainer>
      {bodyData instanceof Array && bodyData.length > 0 && totalPages > 0 && (
        // <Pagination totalPage={totalPages} rowPerPage={numberOfItems} currentPage={currentpage} onTriggerPageChange={goToPage} onTriggerRowChange={()=>{}} />
        <PaginationContainer>
          <div></div>
          <PaginationButtonsContainer>
            {currentpage > 1 && (
              <PaginationPrev
                onClick={() => goToPage && goToPage(currentpage - 1)}
              >
                {"< "}Prev
              </PaginationPrev>
            )}
            <div>
              {currentpage} of {totalPages} pages
            </div>
            {totalPages > 1 && (
              <>
                {currentpage < totalPages && (
                  <PaginationNext
                    onClick={() => goToPage && goToPage(currentpage + 1)}
                  >
                    Next {` >`}
                  </PaginationNext>
                )}
              </>
            )}
          </PaginationButtonsContainer>
        </PaginationContainer>
      )}
    </>
  );
};

export default Table;
