import React, { useEffect, useState } from "react";
import { Route, Redirect, useRouteMatch, useHistory } from "react-router-dom";
import useApi from "shared/hooks/api";

import { PageLoader, PageError } from "shared/components";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { setUsers } from "../store/actions/userAction";

import Layout from "Layout";
import BusinessSettings from "./Business";
import { SettingsPage } from "./Styles";
import Teams from "./Team";
import BankAccount from "./BankAccount";
import WebHook from "./WebHook";
import ApiSettings from "./Api";
import AccountSettings from "./AccountSettings";
import SettingsTab from "./SettingsTab";
import AccountRecovery from "./AccountRecovery";
import ChangeLoginDetails from "./ChangeLoginDetails";
import shouldPerform, { getRoleName } from "shared/utils/assignRoles";
import Transfer from "./Business/Transfer";

const Transactions = () => {
  const match = useRouteMatch();
  const history = useHistory();
  const currentUser = useSelector((store: RootStateOrAny) => store.currentUser);
  const business = useSelector((store: RootStateOrAny) => store.business);

  // const dispatch = useDispatch();
  // const business = useSelector((store: RootStateOrAny) => store.business);

  // dispatch(setUsers());

  // const getQuestions = () => {
  //   dispatch(getRecoveryQuestions(business.id, business.uuid));
  // };

  // useEffect(() => {
  //   getQuestions();
  // }, [])
  if (!shouldPerform(getRoleName(currentUser, business?.id), "CSettings")) {
    history.push("/authenticate");
    return null;
  }

  return (
    //<Layout PageTitle="Settings">
    <SettingsPage>
      <SettingsTab />
      <Route
        path={`${match.path}/general`}
        render={() => <BusinessSettings />}
      />

      <Route path={`${match.path}/teams`} render={() => <Teams />} />
      <Route
        path={`${match.path}/bank-account`}
        render={() => <BankAccount />}
      />

      <Route path={`${match.path}/web-hook`} render={() => <WebHook />} />
      <Route path={`${match.path}/api`} render={() => <ApiSettings />} />
      <Route
        path={`${match.path}/account-settings`}
        render={() => <AccountSettings />}
      />

      <Route
        path={`${match.path}/account-recovery`}
        render={() => <AccountRecovery />}
      />

      <Route
        path={`${match.path}/change-login-details`}
        render={() => <ChangeLoginDetails />}
      />

      <Route
        path={`${match.path}/transfer`}
        render={() => <Transfer />}
      />

      
      {match.isExact && <Redirect to={`${match.url}/general`} />}

      {/* {match.isExact && <Redirect to={`${match.url}/lists`} />} */}
    </SettingsPage>
    //</Layout>
  );
};

export default Transactions;
