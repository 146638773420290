import { useEffect } from "react";
import { useParams } from "react-router-dom";
import Preloader from "shared/components/preloader";

export default () => {
  let { url, termUrl, paReq, md }: any = useParams();
  useEffect(() => {
    let elm: any = document.querySelector("#frm");
    //elm && elm?.submit();
  }, []);
  

  const reFormat = (val?: string) => {
    if (val) {
      return val.replace(/____/gi, "/").trim();
    }
  };

  return (
    <>
      {/*<GradientLoader />*/}
      <div style={{ display: "none" }}>
        <form id="frm" method="post" action={reFormat(url)}>
          <input type="text" value={reFormat(termUrl)} name="TermUrl" />
          <input type="text" value={reFormat(paReq)} name="PaReq" />
          <input type="text" value={reFormat(md)} name="MD" />
        </form>
      </div>
    </>
  );
};
