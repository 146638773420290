import styled from "styled-components";
import { font } from "shared/utils/styles";

export const ParentCont = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const Indicator = styled.p`
    height: 40px;
    width: 40px;
    border-radius: 50%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    font-weight: 500;
    ${font.size(24)};
    line-height: 28.13px;
`;

export const Divider = styled.div`
    height: 60px
    width: 1px;
    border: 1px solid rgba(250, 214, 214, 0.55);
`;