import { useEffect, useState } from "react";
import { RootStateOrAny, useSelector } from "react-redux";

import RightPic from "App/assets/images/services/rightImgService.svg";
import {
  ActionButton,
  Back,
  ButtonContainer,
  Container,
  FormBody,
  FormContainer,
  SubTitle,
  TitleCase,
} from "./Style";

import { Form } from "shared/components";
import { onChangeTextHandler, validations } from "shared/utils/formValidation";
import toast from "shared/utils/toast";

import GradientLoader from "shared/components/GradientLoader/index2";
import ImageLoader from "shared/components/ImageLoader";
import api from "shared/utils/api";
import { storeTempId } from "shared/utils/authToken";
import consoleLog from "shared/utils/consoleLog";
import OtpModal from "../../shared/components/OtpModal";

const FirstStep = ({ setVisible, setStep, edit, setReload }: any) => {
  const business = useSelector((store: RootStateOrAny) => store.business);
  const service = useSelector((store: RootStateOrAny) => store.service);
  const currentUserEmail = useSelector(
    (store: RootStateOrAny) => store?.currentUser?.email
  );

  const [fstate, setFstate] = useState({} as any);
  const [loading, setLoading] = useState(false);
  const [accountList, setAccountList] = useState([]);
  const [openOtp, setOpenOtp] = useState(false);
  const [selectValue, setSelectValue] = useState("");
  //consoleLog(currentUserEmail, "currentUser");
  let emailType = "verification";

  const handleOtpModal = async () => {
    if (!fstate || !fstate.accountDetails || fstate.accountDetails === "") {
      setStep(2);
    } else {
      if (selectValue !== "") {
        const userData = {
          email: currentUserEmail,
        };
        setOpenOtp(true);
        consoleLog(userData, "userData");
        try {
          const res: any = await api.post("/shared/send-auth-otp", {
            ...userData,
            emailType,
          });
          consoleLog(res, "respayLoad OTP");
          let payload: any = res?.payload;
          if (!payload?.tempId) {
            toast.error("OTP not sent");
          } else {
            storeTempId(payload.tempId);
          }
        } catch (error) {
          //@ts-ignore
          toast.error(error ?? "Something went wrong");
        }
      }
    }
  };

  const inputArray = [
    {
      id: "accountDetails",
      title: "Account Number",
      type: "select",
      options: () => {
        let optArr = [
          { label: "--- Add Account Number ---", value: "" },
          ...accountList,
        ];
        // if (service && service.bankAccount) {
        //   let bankAccount: any;
        //   if (service.bankAccount instanceof Object) {
        //     if (!(service.bankAccount instanceof Array)) {
        //       bankAccount = [service.bankAccount];
        //     } else {
        //       bankAccount = service.bankAccount;
        //     }
        //     for (let elm of bankAccount) {
        //       if (elm.accountNumber && elm.bankName) {
        //         optArr.push({
        //           label: `${elm.accountNumber}(${elm.bankName})`,
        //           value: `${elm.accountNumber}(${elm.bankName})`,
        //         });
        //       }
        //     }
        //   }
        // }
        return optArr;
      },
    },
  ];

  const getBankAccounts = async () => {
    setLoading(true);
    try {
      const res = await api.get(
        `/client/business/${business.id}/bank-accounts`
      );

      if (res.status === true && res.payload && res.payload instanceof Array) {
        let data = res.payload.map((elm: any) => {
          let obj: any = {};
          obj.label = `${elm.accountNumber}(${elm.bank.name})`;
          obj.value = elm.id;
          return obj;
        });
        if (service && service.bankAccount && service.bankAccount.id) {
          setFstate({ ...fstate, accountDetails: service.bankAccount.id });
        }
        setAccountList(data);
      } else {
        toast.error(res.message ?? "Unable to fetch Account List");
      }
      consoleLog(res, "resList");

      setLoading(false);
    } catch (error) {
      //@ts-ignore
      toast.error(error.message ?? "Unable to fetch Account List");
      consoleLog(error);
    } finally {
      setLoading(false);
    }
  };

  const selectedValue = () => {
    if (service && service.bankAccount && service.bankAccount.id) {
      return service.bankAccount.id;
    }
    // if (val.length > 1) return val[1]?.value;
    return "";
  };

  const initialValues = () => {
    let obj: any = {};
    for (let elm of inputArray) {
      obj[elm.id] = "";
    }
    return obj;
  };

  const displayFormInput = () => {
    return inputArray.map((elm, idx) => {
      switch (elm.type) {
        case "select":
          return (
            <Form.Field.Select
              key={`INP${idx}`}
              name={elm.id}
              label={elm.title}
              options={elm.options()}
              onChange={(val: any) => {
                onChangeTextHandler(val, elm.id, fstate, setFstate);
                setSelectValue(val);
                // alert(val);
              }}
              value={fstate[elm.id] ?? selectedValue()}
              //  renderOption={""}
              //  renderValue={""}
              // tip="Kindly enter your user password."
            />
          );
      }
    });
  };

  const changeDetails = async () => {
    if (!fstate || !fstate.accountDetails || fstate.accountDetails === "") {
      setStep(2);
    } else {
      ///add account to service
      try {
        let response;

        response = await api.put(`/services/${service?.id}`, {
          bankAccountId: fstate.accountDetails,
        });

        if (response.status === true) {
          toast.success(response.message);
          setVisible(false);
          setReload();
        } else {
          toast.error(response.message);
        }
        consoleLog(response, "response");
      } catch (err) {
        consoleLog(err, "error");
        // @ts-ignore
        toast.error(err);
      } finally {
        // setLoading(false);
      }
    }
  };

  useEffect(() => {
    getBankAccounts();
  }, []);

  // if (loading) return <GradientLoader />; ///look for another loader
  // consoleLog("ACT:", fstate.accountDetails);
  return (
    <>
      {loading ? (
        <GradientLoader />
      ) : (
        <>
          {openOtp ? (
            <OtpModal
              callBackFn={changeDetails}
              type="auth-user"
              emailType={emailType}
              closeModal={setOpenOtp}
            />
          ) : (
            <Container>
              <FormContainer>
                <TitleCase>{edit ? "Change" : "Add"} Account</TitleCase>
                <SubTitle></SubTitle>
                <FormBody>
                  {/* @ts-ignore */}
                  <Form
                    enableReinitialize
                    initialValues={initialValues()}
                    validations={validations(inputArray, Form)}
                    // onSubmit={onSubmit}
                  >
                    <>
                      {displayFormInput()}
                      <ButtonContainer>
                        <Back></Back>
                        <ActionButton onClick={handleOtpModal}>
                          {edit
                            ? !fstate.accountDetails
                              ? "Create"
                              : "Change"
                            : !fstate.accountDetails
                            ? "Create"
                            : "Add"}
                        </ActionButton>
                      </ButtonContainer>
                    </>
                  </Form>
                </FormBody>
              </FormContainer>

              <ImageLoader
                source={RightPic}
                width="230"
                height="440"
                draggable="false"
                onDragStart={() => alert()}
                style={{ pointerEvents: "none" }}
              />
              {/* <Image src={RightPic} /> */}
            </Container>
          )}
        </>
      )}
    </>
  );
};

export default FirstStep;
