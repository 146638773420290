import { useEffect, useRef, useState } from "react";
import consoleLog from "shared/utils/consoleLog";
import { Container, DivText, OptionDiv, OptionItem } from "./Styles";

export default function({ content, index }: any) {
  const [display, setDisplay] = useState(false);
  const divRef = useRef(null as any);
  const displayContent = () => {
    
    if (content instanceof Array) {
      return content.map((el: any, ind: number, arr: any) => {
        return (
          <OptionItem
            onClick={() => {
              el?.link && el.link();
              setDisplay(false);
            }}
            key={`${index}_${ind}`}
          >
            {el?.title}
          </OptionItem>
        );
      });
    }
    return null;
  };

  useEffect(() => {
    const closeOutsideDiv = (e: any) => {
      consoleLog(display, "ellipsis");
      if (divRef.current && display && !divRef.current.contains(e.target)) {
        setDisplay(false);
      }
    };
    document.addEventListener("mousedown", closeOutsideDiv);

    return () => document.removeEventListener("mousedown", closeOutsideDiv);
  }, [divRef, display]);

  return (
    <Container ref={divRef}>
      <DivText onClick={() => setDisplay(!display)}>...</DivText>
      {display && <OptionDiv>{displayContent()}</OptionDiv>}
    </Container>
  );
}
