import { Button } from "shared/components";
import { color } from "shared/utils/styles";
import styled, { css } from "styled-components";
type Props = {
  mode?: boolean;
};
export const ServicePageContainer = styled.div`
  width: 100%;
  background: #fff;
  min-height: 40vh;
  padding: 2rem;
  border-radius: 5px;

  h1 {
    font-weight: 700;
    font-size: 1.4rem;
    display: flex;
    align-items: center;
  }
`;

export const EmptyState=styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
export const ActionButton = styled(Button)`
  color: #fff;
  width: 25%;
  background-color: ${color.fountain};
  margin-top:30px;
`;

export const WalletEmptyStateDesc=styled.div`
  width: 50%;
  min-height: 30px;
  padding: 20px 10px;
  background-color: rgba(252, 102, 102, .1);
  border-radius: 5px;
  text-align: center;
`;
export const WalletSummaryDetails= styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
  &>:first-child{
    border: 1px solid #F1F2F5;
    &>.fa{
      position: relative;
      top: 23px;
    }
  }
  &>:nth-child(2){
    border: 1px solid #F1F2F5;
    &>.fa{
      position: relative;
      top: 15px;
    }
  }
  &>:last-child{
    border: 1px solid #F1F2F5;
  }
`;

export const WalletSummaryCards= styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap:20;
  width: 33%;
  height: 140px;
  border-radius: 5px;
  padding: 20px 5px 15px 5px;
  padding-right: 5px;

  &:hover{
    background: #F1F2F5;
    cursor: pointer;
    &>.fa{
      color: #FFF;
    }
  }
  
  &>.fa{
    position: relative;
    top: 13px;
    left: -7px;
    color:#F1F2F5;
  }
  &>div{
    display: flex;
    flex-direction: column;
    align-items: right;
    text-align: right;
  }
  &>div p{
    font-size: 30px;
    z-index: 9999;
    color: #B3B3B3;
    text-align: right !important;
  }
  &>div h1{
    font-size: 14px;
    font-weight: 300;
    text-align: right !important;
    color: #B3B3B3;
    
  }
  &>div .bookbalance{
    font-size: 16px;
  }
  &>div .bookbalanceheader{
    font-size: 14px;
  }
`;

export const ServicePageSubHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.5px solid #000;
  margin-top: 0.6rem;
  padding-bottom: 0.8rem;

  p {
    font-size: 0.95rem;
  }
`;

export const ServicePageActionButtons = styled.div`
  display: flex;
  gap: 2rem;
  align-items: center;
  justify-content: end;

  button {
    /* padding: 0.8rem 1.5rem; */
    color: #fff;
    background: #1b222f;
    border-radius: 0.25rem;
    width: 10rem;
    height: 44px;
    cursor: pointer;
  }
`;

export const ServicePageContent = styled.div`
  width: 100%;
  margin-top: 1rem;
`;



export const KeyWithIcon = styled.div`
  width: 50%;
  display: flex;
  justify-content: space-between;
  align-items: left;
  gap: 2rem;
`;

export const ModeSpan = styled.span<Props>`
  background: #760000;
  color: #fff;
  padding: 0.2rem 0.4rem;
  font-size: 0.6rem;
  margin-bottom: 1rem;
  display: block;
  margin-left: 8px;
  border-radius: 20px;
  ${(props) =>
    props.mode &&
    css`
      background: green;
    `}
`;

export const EditButton = styled.div`
  border-radius: 4px;
  padding: 0.2rem;
  min-width: 20%;
  text-align: center;
  font-size: 0.88rem;
  color: #760000;
  border: 0.56px solid #760000;
  cursor: pointer;
`;
