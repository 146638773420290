import {
  Card,
  CardLeft,
  CardRight,
  CardRow,
  DownloadButton,
  ReportPage,
} from "./Styled";

function Report() {
  return (
    <ReportPage>
      <Card>
        <CardRow>
          <CardLeft>Activity Log: </CardLeft>
          <CardRight>Payment</CardRight>
        </CardRow>
        <CardRow>
          <CardLeft>Activity Description: </CardLeft>
          <CardRight>
            Sends money the sum of N200,000 to tiamiyu for the goods i purchased
            from himSends money the sum of N200,000 to tiamiyu for the goods i
            purchased from him
          </CardRight>
        </CardRow>
        <CardRow>
          <CardLeft>Time: </CardLeft>
          <CardRight>11:00pm</CardRight>
        </CardRow>
        <CardRow>
          <CardLeft>Date: </CardLeft>
          <CardRight>9th June 2022</CardRight>
        </CardRow>
      </Card>
      <div style={{ textAlign: "center" }}>
        <DownloadButton>Download Report</DownloadButton>
      </div>
    </ReportPage>
  );
}

export default Report;
