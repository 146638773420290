import {
  ActionButton,
  ButtonContainer,
  FormBody,
  FormElement,
  SubAgentForm,
  SubTitle,
  TitleCase,
} from "./styles";
import { useState } from "react";
import { Form } from "shared/components";
import api from "shared/utils/api";
import consoleLog from "shared/utils/consoleLog";
import { color } from "shared/utils/styles";
import toast from "shared/utils/toast";

type PropsT = {
  agentId?: string;
  businessId: string;
  initialValues: any;
  step: number;
  setStep: Function;
  setAgentId: Function;
  setInitialValues: Function;
};
const FirstPage = ({
  agentId,
  businessId,
  setAgentId,
  initialValues,
  setInitialValues,
  step,
  setStep,
}: PropsT) => {
  const [defaultValues, setDefaultValues] = useState({
    bvn: initialValues?.bvn || "",
    first_name: initialValues?.firstName || "",
    last_name: initialValues?.lastName || "",
    email: initialValues?.email || "",
    phone: initialValues?.phone || "",
    trade_name: initialValues?.tradeName || "",
  });

  const [isLoginIn, setIsLoginIn] = useState(false);
  const [phoneError, setPhoneError] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<boolean>(false);

  const testMobileNumber = (val: string) => {
    return /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(
      val
    );
  };

  const testEmail = (val: string) => {
    return /.+@.+\..+/.test(val);
  };
  // else if (key == "email" && !testEmail(value)) {
  //     setEmailError(true);

  const onKeyChange = (key: string, value: any) => {
    if (key == "phoneNo" && !testMobileNumber(value)) {
      setPhoneError(true);
    } else {
      let oldValues: any = initialValues;
      oldValues[key] = value;
      setDefaultValues(oldValues);

      setInitialValues((prevFormValues: any) => ({
        ...prevFormValues,
        [key]: value,
      }));

      setDefaultValues((prevFormValues) => ({
        ...prevFormValues,
        [key]: value,
      }));
    }
  };

  const onSubmit = async () => {
    try {
      if (defaultValues) {
        setIsLoginIn(true);

        if (agentId) {
          let subAgent = {
            ...initialValues,
            id: agentId,
            business_id: businessId,
          };
          // // console.log("Values: ", subAgent);
          const res = await api.post(
            "/agency-banking/profile/update",
            subAgent
          );
          toast.success(res.message);
        } else {
          let subAgent = {
            ...initialValues,
            business_id: businessId,
          };
          // // console.log("payload f===>", subAgent);
          const res = await api.post("/agency-banking/create-agents", subAgent);
          consoleLog("Response: ", res);
          setAgentId(res?.payload.uuid);
          toast.success(res.message);
        }
        setStep(2);
      }
    } catch (err) {
      const error: any = err;
      // // console.log(error);
      const resMessage =
        error.message === "Successfully retrieved business agents"
          ? "Error sub agent already exists"
          : error.message;
      toast.error(`${resMessage}`);
    } finally {
      setIsLoginIn(false);
    }
  };

  return (
    <>
      <TitleCase>Agent personal details</TitleCase>
      <SubTitle>
        With your corporate account number, you are guaranteed of your
        settlement
      </SubTitle>
      <FormBody>
        <Form
          enableReinitialize
          initialValues={initialValues}
          validations={{
            bvn: [
              Form.is.required(),
              Form.is.maxLength(11),
              Form.is.minLength(11),
            ],
            firstName: [Form.is.required()],
            lastName: [Form.is.required()],
            email: [Form.is.required(), Form.is.email()],
            password: [Form.is.required(), Form.is.maxLength(200)],
            phone: [Form.is.required()],
            tradeName: [Form.is.required()],
          }}
          onSubmit={onSubmit}
          validate={function({}: {}) {
            // throw new Error('Function not implemented.')
          }}
          validateOnBlur={true}
        >
          <FormElement>
            <SubAgentForm>
              {/* @ts-ignore */}
              <Form.Field.Input
                name="tradeName"
                type="text"
                label="Trade Name"
                value={initialValues?.trade_name}
                onChange={(e: any) => onKeyChange("trade_name", e)}
                // tip="Kindly enter your BVN"
              />
              <Form.Field.Input
                name="bvn"
                type="text"
                label="BVN"
                value={initialValues?.bvn}
                onChange={(e: any) => {
                  if (isNaN(e)) {
                    return false;
                  } else {
                    onKeyChange("bvn", e);
                  }
                }}
                // tip="Kindly enter your BVN"
              />
              <Form.Field.Input
                name="firstName"
                type="text"
                label="First name"
                value={initialValues?.first_name}
                onChange={(e: any) => onKeyChange("first_name", e)}
                // tip="Kindly enter your BVN"
              />

              <Form.Field.Input
                name="lastName"
                type="text"
                label="Last name"
                value={initialValues?.last_name}
                onChange={(e: any) => onKeyChange("last_name", e)}
                // tip="Kindly enter your BVN"
              />
              <Form.Field.Input
                name="email"
                type="email"
                label="Email"
                value={initialValues?.email}
                onChange={(e: any) => onKeyChange("email", e)}
                // tip="Kindly enter your BVN"
              />
              <Form.Field.Input
                name="phoneNo"
                type="tel"
                label="Phone number"
                value={initialValues?.phone}
                onChange={(e: any) => onKeyChange("phone", e)}
                // tip="Kindly enter your BVN"
              />
            </SubAgentForm>
            <ButtonContainer variant={agentId}>
              {agentId != "" && (
                <ActionButton
                  variant={color.transparent}
                  onClick={() => {
                    setStep(step + 1);
                  }}
                >
                  Skip
                </ActionButton>
              )}
              <>
                {step > 1 && (
                  <ActionButton
                    type="button"
                    variant={color.greyColor}
                    onClick={() => {
                      setStep(step - 1);
                    }}
                  >
                    Back
                  </ActionButton>
                )}

                <ActionButton
                  type="submit"
                  isWorking={isLoginIn}
                  onClick={onSubmit}
                >
                  Next
                </ActionButton>
              </>
            </ButtonContainer>
          </FormElement>
        </Form>
      </FormBody>
    </>
  );
};

export default FirstPage;
