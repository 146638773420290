import styled, { css } from 'styled-components';

type PropT = {
  top? : number,
  left?: number,
  color?: string,
  hoverColor?: string,
  code?:string,
  size?: number
  isHover?: boolean;
}

export const StyledIcon = styled.i<PropT>`
  display: inline-block;
  font-size: ${props => `${props.size}px`};
  ${props =>
    props.left || props.top ? `transform: translate(${props.left}px, ${props.top}px);` : ''}
  &:before {
    content: "${props => props.code}";
    color: ${props => props.color} !important;
    font-family: "icomoon" !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  &:hover{
    color: "${props=>props.hoverColor}";
    ${props=>props.isHover &&
      css`
        cursor: pointer;
      `
    }
    
  }
`;
