import styled from "styled-components";
import { color } from "../../shared/utils/styles";

export const KeyContainer = styled.div`
  margin-top: 1.4rem;
  padding: 0.8rem 0;
  width: 65%;
`;

export const KeyLabel = styled.span`
  font-size: 14px;
  color: ${color.textDark};
`;

export const KeyWrapper = styled.div`
  display: flex;
  margin-top: 0.5rem;
  width: 100%;
  padding: 0.5 0rem;
  padding-left: 1.4rem;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${color.backgroundLight};
`;
