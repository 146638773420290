import React, { useState } from "react";
import { Sectionbutton } from "shared/section_components/Styles";
import { KeyWrapper, KeyContainer, KeyLabel } from "./Styles";

type KeyRowType = { APIkey: { id: number; keyName: string; keyValue: string } };

export default function KeysComponent({ APIkey }: KeyRowType) {
  const [btnText, setbtnText] = useState<string>("Copy Key");

  const handleCopyKey = (key: any) => {
    setbtnText("Copied");
    navigator.clipboard.writeText(key.keyValue);
    setTimeout(() => {
      setbtnText("Copy Key");
    }, 2000);
  };
  return (
    <>
      <KeyContainer key={APIkey.id}>
        <KeyLabel>{APIkey.keyName}</KeyLabel>
        <KeyWrapper>
          <span>{APIkey.keyValue}</span>
          <Sectionbutton onClick={() => handleCopyKey(APIkey)}>
            <div style={{ width: "6.5rem" }}> {btnText}</div>
          </Sectionbutton>
        </KeyWrapper>
      </KeyContainer>
    </>
  );
}
