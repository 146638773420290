import styled from 'styled-components';

import { color, font, mixin } from 'shared/utils/styles';
import { Icon } from 'shared/components';

import errorPage from './assets/error-page.jpg';
/*${mixin.backgroundImage(imageBackground)}*/
export const ErrorPage = styled.div`
  
`;

export const ErrorPageInner = styled.div`
  margin: 0 auto;
  max-width: 1440px;
`;

export const ErrorBox = styled.div`
  position: relative;
  margin: 0 auto;
  max-width: 580px;
  padding: 32px;
  border-radius: 3px;
  text-align: center;
`;

export const StyledIcon = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  left:-30px;
`;

export const Title = styled.h1`
  margin-bottom: 16px;
  
  ${font.size(29)}
`;


