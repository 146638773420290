import { Button } from "shared/components";
import breakpoints from "shared/utils/breakpoints";
import { color } from "shared/utils/styles";
import styled from "styled-components";

export const TypeButtonGroup = styled.div`
  margin-top: 25px;
  text-align: left;
`;
export const TypeButton = styled.button`
  width: 138px;
  background: #0e314c;
  border-radius: 5px;
  color: #ffffff;
  padding: 15px 25px;
  cursor: pointer;
  margin-right: 10px;
  background: #ffffff;
  color: rgba(130, 130, 130, 0.9);
  border: 1px solid rgba(130, 130, 130, 0.9);
  &.selected {
    background: #0e314c;
    color: #ffffff;
    border: 1px solid #0e314c;
  }
  @media ${breakpoints.device.max.xs} {
    width: 100%;
    margin-bottom: 10px;
  }
`;

export const InActiveTypeButton = styled.button`
  background: #ffffff;
  color: rgba(130, 130, 130, 0.9);
  width: 138px;
  border-radius: 5px;
  border: 1px solid rgba(130, 130, 130, 0.9);
  padding: 15px 25px;
  cursor: pointer;
  margin-right: 10px;
`;

export const Selectors = styled.div`
  text-align: left;
`;

export const Selector = styled.div`
  cursor: pointer;
  margin-bottom: 30px;
`;

export const SelectorHeader = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #292929;
  margin-bottom: 10px;
`;

export const SelectorBody = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #828282;
`;

export const SelectorButton = styled(Button)`
  margin: 2px 0;
  width: 43%;
  height: 48px;
  float: right;
  font-weight: 600;
  font-size: 18px;
  // background-color: ${color.fountain};
`;

export const TransSplitContainer = styled.div`
  margin-top: 35px;
  text-align: left;
`;
export const TransSplitTitle = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #292929;
`;

export const SplitInputRow = styled.div`
  justify-content: space-between;
  margin-bottom: 25px;
  @media ${breakpoints.device.max.xs} {
    flex-direction: column;
  }
`;

export const TransSplitIcon = styled.div`
  font-weight: 600;
  font-size: 24px;
  /* line-height: 16px; */
  color: #292929;
  padding-top: 40px;
  display: flex;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
  @media ${breakpoints.device.max.xs} {
    padding-top: 10px;
    margin-right: 0;
  }
`;
