import history from 'browserHistory';
import { queryStringToObject, addToQueryString, omitFromQueryString } from 'shared/utils/url';

const open = (param :number|string) =>
  history.push({
    pathname: history.location.pathname,
    search: addToQueryString(history.location.search, { [`modal-${param}`]: true }),
  });

const close = (param :number|string) =>
  history.push({
    pathname: history.location.pathname,
    search: omitFromQueryString(history.location.search, [`modal-${param}`]),
  });

const isOpen = (param :number|string) => !!queryStringToObject(history.location.search)[`modal-${param}`];

export const createQueryParamModalHelpers = (param :number|string) => ({
  open: () => open(param),
  close: () => close(param),
  isOpen: () => isOpen(param),
});
