import breakpoints from "shared/utils/breakpoints";
import styled from "styled-components";
import { Button } from "shared/components";
import { color, font, mixin } from 'shared/utils/styles'

type Props = {
  status?: boolean;
};
export const ParentCont = styled.div`
  background-color: #e5e5e5;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const ReportBody = styled.div`
  display: flex;
  background-color: #fff;
  justify-content: center;
  width: 60%;
  min-height: 70vh;
  align-items: center;
  flex-direction: column;
  box-shadow: 0px 4px 100px rgba(0, 0, 0, 0.04);
  border-radius: 20px;
  img {
    width: 30%;
  }
  @media ${breakpoints.device.max.sm} {
    width: 100%;
    height: 100%;
  }
`;

export const ReportBodyBusi = styled.div`
  display: flex;
  background-color: #fff;
  justify-content: center;
  width: 60%;
  height: 70vh;
  align-items: center;
  flex-direction: column;
  box-shadow: 0px 4px 100px rgba(0, 0, 0, 0.04);
  border-radius: 20px;
  img {
    width: 30%;
  }
  @media ${breakpoints.device.max.sm} {
    width: 100%;
    height: 100%;
  }
`;

export const SubTitle = styled.p`
  color: #828282;
  ${font.size(16)}
  text-align: center;
`;

export const SubTitle2 = styled.p`
  color: #292929;
  margin-top: 25px;
  font-weight: 600;
  ${font.size(24)}
  line-height: 28px;
  text-align: center;
`;

export const SubTitle3 = styled.p`
  color: #292929;
  margin-top: 10px;
  font-weight: 400;
  ${font.size(18)}
  line-height: 18.75px;
  text-align: center;
`;

export const ActionButton2 = styled(Button)`
  margin: 2em 0;
  color: #fff;
  width: 40%;
  background-color: ${color.fountain};
  @media ${breakpoints.device.max.sm} {
    width: 90%;
  }
`;

export const ActionButtonSignOut = styled(Button)`
  margin: 2em 0;
  color: ${color.fountain};
  width: 40%;
  background-color: ${color.greyColor};
  @media ${breakpoints.device.max.sm} {
    width: 90%;
  }
  &:hover {
    background-color: ${color.fountain};
    color: #fff;
  }
`;

export const ActionButtonExit1 = styled(Button)`
  margin: 2em 3em;
  color: #fff;
  width: 40%;
  min-width: 200px;
  background-color: ${color.fountain};
  @media ${breakpoints.device.max.sm} {
    width: 90%;
  }
  &:hover {
    background-color: ${color.greyColor};
  }
`;

export const ActionButtonExit2 = styled(Button)`
  margin: 2em 3em;
  color: #fff;
  min-width: 200px;
  width: 40%;
  background-color: ${color.greyColor};
  @media ${breakpoints.device.max.sm} {
    width: 90%;
  }
  &:hover {
    background-color: ${color.fountain};
  }
`;

export const CloseBtn = styled.p`
  background-color: #fff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: right;
  &:hover {
    cursor: pointer;
  }
`;

export const CloseWrapper = styled.div`
  height: 100vh;

  padding-top: 5%;
`;

export const ExitPageWrapper = styled.div`
  background: #fbfbfb;
  box-shadow: 0px 4px 100px rgba(0, 0, 0, 0.04);
  border-radius: 20px;
  min-width: 300px;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 30px;
  flex-direction: column;
`;

export const ExitLabel = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 32px;
  line-height: 38px;
  text-align: center;
  letter-spacing: -0.02em;
`;

export const ExitButtonWrapper = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
`;

export const BusLabelWrapper = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  letter-spacing: -0.02em;
  min-height: 40px;
  height: 10%;
  padding-top: 1.5em;
`;

export const BusContentWrapper = styled.div`
  height: 80%;
  overflow: auto;
  min-height: 200px;
  margin-bottom: 10px;
  padding-right: 10px;
  margin-top: 10px;
  ${mixin.scrollableY}
	${mixin.customScrollbar()}
`;

export const BusContent = styled.div`
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 13px 10px;
  gap: 10px;

  width: 392px;
  height: 54px;

  background: #ffffff;
  /* Grey Border 1 */

  border: 1px solid rgba(215, 215, 215, 0.6);
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
  &:hover {
    background-color: ${color.greyColor};
    color: #fff !important;
    cursor: pointer;
  }
`;

export const BusItem1 = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  &:hover {
    color: #fff;
  }
`;
export const BusItem1_1 = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  /* Primary Black */

  color: #292929;
  &:hover {
    color: #fff;
  }
`;

export const BusItem1_2 = styled.div<Props>`
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */

  color: ${(props) => (props?.status ? "#219653" : "#EB5757")};
  &:hover {
    color: #fff;
  }
`;

export const BusItem2 = styled.div`
  align-self: flex-end;
`;
