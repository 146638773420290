import { memo, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { AboutTooltip, Icon, LogoutTooltip } from "shared/components";
// import {
//   getstoredBusinesses,
//   storeBusinessID,
// } from "shared/utils/businessesData";

import FPlogo from "App/assets/images/fp_logo.svg";
// import useCurrentUser from "shared/hooks/currentUser";
import { setCurrentBusiness } from "store/actions/businessAction";
import {
  BItem,
  Bottom,
  ImageAvatar,
  Item,
  ItemBody,
  ItemId,
  ItemText,
  LogoLink,
  LogoText,
  MenuContainer,
  NavLeft,
} from "./Styles";
import consoleLog from "shared/utils/consoleLog";

type PropT = {
  [x: string]: any;
};

const NavbarLeft = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [user, setUser] = useState<any>({});

  // const currentUser: any = useCurrentUser();
  const businesses = useSelector((store: RootStateOrAny) => store.businesses);

  const currentUser = useSelector((store: RootStateOrAny) => store.currentUser);
  const currentBusiness = useSelector(
    (store: RootStateOrAny) => store.business
  );

  const addBusinesses = () => history.push("/kyc");
  const openBusiness = ({ business }: any) => {
    dispatch(setCurrentBusiness(business));
  };

  const openSettings = () => {
    history.push("/profile");
  };

  // consoleLog(currentUser, 'currentUser')

  let profileImage = () => {
    let img = currentUser?.avatar;
    if (img && String(img).length > 0) {
      return `data:image/png;base64,${String(img).trim()}`;
    }
    return FPlogo;
  };

  let businessImage = (logoId: any) => {
    if (
      logoId &&
      String(logoId).length > 0 &&
      currentUser?.businesses instanceof Array
    ) {
      let findLogo = currentUser?.businesses.find(
        (el: any) => el.id === logoId
      );
      if (findLogo && findLogo?.logo && String(findLogo?.logo).length > 0)
        return findLogo?.logo;
    }
    return FPlogo;
  };

  return (
    <NavLeft>
      <LogoLink to="/profile">
        <ImageAvatar>
          <img
            src={profileImage()}
            alt=""
            style={{
              marginLeft: "0",
              width: 50,
              height: 50,
              borderRadius: "50%",
            }}
          />
        </ImageAvatar>
        <LogoText>
          Welcome
          <p>{`${currentUser?.firstName || ""} ${currentUser?.lastName ||
            ""}`}</p>
          {/* <p>(Admin)</p> */}
        </LogoText>
      </LogoLink>

      <MenuContainer style={{ marginTop: "1rem" }}>
        {businesses && (
          <>
            <div>
              {Object.values(businesses)?.map((business: any) => {
                const { businessName } = business;
                return (
                  <Item
                    key={business?.id}
                    onClick={() => {
                      openBusiness({ business }); //====== Open a business
                      // consoleLog('business in navLeft', business)
                      if (
                        business?.BusinessKyc instanceof Array &&
                        business?.BusinessKyc.length === 1
                      ) {
                        if (
                          business?.BusinessKyc[0] &&
                          business?.BusinessKyc[0].type === "GATEWAY"
                        )
                          history.push("/overview");
                        if (
                          business?.BusinessKyc[0] &&
                          business?.BusinessKyc[0].type === "AGENCY"
                        )
                          history.push("/agency/overview");
                      } else {
                        history.push("/overview");
                      }
                    }}
                    isActive={currentBusiness?.id === business?.id}
                  >
                    <i>
                      <img
                        src={businessImage(business?.id)}
                        alt=""
                        style={{
                          marginLeft: "0",
                          borderRadius: "50%",
                          width: 25,
                          height: 25,
                        }}
                      />
                    </i>

                    {/* <Icon type="stack" size={20} /> */}
                    <ItemBody>
                      <ItemText>
                        {businessName.length > 14
                          ? businessName.slice(0, 14) + "..."
                          : businessName}
                      </ItemText>
                      <ItemId>{business?.businessId}</ItemId>
                    </ItemBody>
                  </Item>
                );
              })}
            </div>
          </>
        )}
      </MenuContainer>

      <Item onClick={() => addBusinesses()} id="addBusiness">
        <Icon type="plus" size={22} top={1} left={3} />
        <ItemText>Add Business</ItemText>
      </Item>

      <Bottom>
        <BItem onClick={openSettings}>
          <Icon type="user" size={15} />
          <ItemText>Profile</ItemText>
        </BItem>

        <AboutTooltip
          placement="right"
          offset={{ top: -150 }}
          renderLink={(linkProps: PropT) => (
            <BItem {...linkProps}>
              <Icon type="help" size={15} />
              <ItemText>About Us</ItemText>
            </BItem>
          )}
        />

        <LogoutTooltip
          placement="right"
          offset={{ top: -100 }}
          renderLink={(linkProps: PropT) => (
            <BItem {...linkProps}>
              <Icon type="log_out_outlined_logout_sign" size={15} />
              <ItemText>Logout</ItemText>
            </BItem>
          )}
        />
      </Bottom>
    </NavLeft>
  );
};

export default memo(NavbarLeft);
