import styled from "styled-components";

export const BackButtonContainer = styled.div`
  display: flex;
  margin-bottom: 15px;
  align-items: center;
  cursor: pointer;
  & span {
    margin-left: 10px;
    margin-bottom: 3px;
    color: #760000;
  }
`;
