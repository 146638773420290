import { useState } from "react";

import { Button } from "shared/components";
import breakpoints from "shared/utils/breakpoints";
import { copyToClipboard } from "shared/utils/browser";
import toast from "shared/utils/toast";
import styled from "styled-components";

interface Props {
  link?: any;
  buttonProps?: any;
  disabled?: any;
  style?: any;
}
const CopyLinkButton = ({ link, disabled, style, ...buttonProps }: Props) => {
  const [isLinkCopied, setLinkCopied] = useState(false);

  const handleLinkCopy = () => {
    setLinkCopied(true);
    setTimeout(() => setLinkCopied(false), 2000);
    copyToClipboard(link || window.location.href);
    toast.success("Copied to clipboard!");
  };

  return (
    <CopyContainer>
      <Button
        icon="link"
        onClick={disabled ? handleLinkCopy : () => null}
        {...buttonProps}
        style={style}
      >
        <span>{isLinkCopied ? "Link Copied" : "Copy link"}</span>
      </Button>
    </CopyContainer>
  );
};

export default CopyLinkButton;

const CopyContainer = styled.div`
  @media ${breakpoints.device.max.xs} {
    padding: 0px;
    margin-left: 10px;
    span {
      display: none;
    }
  }
`;
