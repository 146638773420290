import { useState } from "react";
import { ImageContainer, ImagePlaceholder } from "./Styled";

const ImageLoader = ({ height, width, source, ...rest }: any) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  const imageStyle = !imageLoaded ? { display: "none" } : {};

  const handleImageLoaded = () => {
    setImageLoaded(true);
  };

  return (
    <ImageContainer>
      {!imageLoaded && <ImagePlaceholder height={height} width={width} />}
      <img
        src={source}
        style={imageStyle}
        onLoad={handleImageLoaded}
        {...rest}
      />
    </ImageContainer>
  );
};

export default ImageLoader;
