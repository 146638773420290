import React, { useState } from "react";
import { Route, Redirect, useRouteMatch } from "react-router-dom";
import { Link } from "react-router-dom";
import { StyledMenu } from "./Styles";

type menu = {
  id: number;
  title: string;
  path: string;
  isActive: boolean;
  toogle: Function;
};

const activeborder = {
  borderBottom: "1px solid orange",
};

export default function Tab({ id, title, path, isActive, toogle }: menu) {
  const match = useRouteMatch();

  const constructMenu = (
    id: number,
    title: string,
    path: string,
    isActive: boolean,
    toogle: any
  ) => (
    <div onClick={() => toogle(id)}>
      <Link to={`${match.path}${path}`}>
        <StyledMenu key={id} mennuActive={isActive}>
          {title}
        </StyledMenu>
      </Link>
    </div>
  );

  return <>{constructMenu(id, title, path, isActive, toogle)}</>;
}
