// import PaymentLinkImg from "App/assets/images/paymentlink/paymentlink.svg";
import PaymentLinkModalImg from "App/assets/images/services/paymentLinkModal.svg";
import { useEffect, useState } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { AddSubAccountStyle } from "Services/AddSubAccount/Styles";
import { Form } from "shared/components";
import api from "shared/utils/api";
import { onChangeTextHandler } from "shared/utils/formValidation";
import toast from "shared/utils/toast";
import AddSubAccountModal from "SubAccount/AddSubAccount";
import subaccountClass from "SubAccount/subaccountClass";
import consoleLog from "shared/utils/consoleLog";

import ImageLoader from "shared/components/ImageLoader";
import {
  ActionButton,
  FormBody,
  FormContainer,
  FormElement,
  FormTitle,
  LeftPane,
  RightPane,
  Selectors,
} from "../PaymentLink/Styles";
import {
  SplitInputRow,
  TransSplitContainer,
  TransSplitIcon,
  TransSplitTitle,
  TypeButton,
  TypeButtonGroup,
} from "./Styles";

type PropT = {
  onCreate: Function;
  modalClose: Function;
  chargeType: String;
  handleBack: Function;
};

// const subAccounts = [
//   {
//     value: "main-account",
//     label: "Main Account",
//   },
// ];

type accountPropT = {
  id: number;
  sn: number;
  subaccountname: string;
  servicename: string;
  bank_account: any;
  Bank_name: string;
  account_name: string;
}[];

type subAccountPropT = {
  name: string;
  bankName: string;
  accountNumber: string;
  accountName: string;
  bankCode: string;
};

const AddSplitting = ({ title, splitRecord, getUpdatedSplitting }: any) => {
  const [fstate, setFstate] = useState({} as any);
  const [needAmount, setNeedAmount] = useState(false);
  const [subAccounts, setSubAccounts] = useState<accountPropT>([]);
  const [isLoading, setisLoading] = useState(true);
  const [selected, setSelected] = useState("Percentage");
  const [isProcessing, setIsProcessing] = useState(false);

  //subaccount states
  const [showSubAccount, setShowSubAccount] = useState(false);
  const [bankList, setbankList] = useState([]);
  const [subAccountForm, setSubAccountsForm] = useState<subAccountPropT>({
    name: "",
    bankName: "",
    accountNumber: "",
    accountName: "",
    bankCode: "",
  });
  const [banknameStatus, setbanknameStatus] = useState("");
  const [accountError, setAccountError] = useState(false);
  const [isSubFormValid, setSubFormValid] = useState(false);
  // const [isProcessing, setIsProcessing] = useState(false);

  const [isFormValid, setFormValid] = useState(false);

  const [yourShare, setYourShare] = useState(100);
  const [sharePercentage, setSharePercentage] = useState(0);

  // const [yourAmount, setYourAmount] = useState(0);
  const [fixedAmount, setFixedAmount] = useState(0);

  const service = useSelector((store: RootStateOrAny) => store.service);

  // let accountList: accountPropT = [];
  // consoleLog("SPLITTING REC:", splitRecord);
  // consoleLog("STATE REC:", fstate);
  // consoleLog("SUB ACCOUNTS REC:", subAccounts);

  const currentbusiness = useSelector(
    (store: RootStateOrAny) => store.business
  );

  // const [{ data, error, setLocalData }, fetchData] = useApi.get(
  //   `/client/business/${currentbusiness?.id}/sub-accounts`
  // );

  const getAllSubAccounts = async (val?: string) => {
    try {
      setisLoading(true);
      const response = await api.get(
        `/client/business/${currentbusiness?.id}/sub-accounts`
      );
      if (response.status === true) {
        const subs = response.payload;
        // setisLoading(false);
        const accountList = subs.map((account: any, index: any) => {
          const acc = {
            id: account.id,
            sn: index + 1,
            subaccountname: account.name,
            servicename: account.servicename
              ? account.servicename
              : "No service name",
            bank_account: account.accountNumber,
            Bank_name: account.bankName,
            account_name: account.accountName,
          };
          return acc;
        });
        setSubAccounts(accountList);
        if (val) {
          // alert(val);
          // consoleLog(accountList, "accountList");
          let findObj = accountList.find(
            (el: any) => el.subaccountname === val
          );
          if (findObj) {
            // alert(2);
            setFstate({ ...fstate, subaccount: findObj?.id });
          }
        }
        return accountList;
      }
    } catch (error) {
    } finally {
      setisLoading(false);
    }
  };

  const onSubmit = async () => {
    try {
      setIsProcessing(true);
      const formdata = {
        subAccountId: fstate.subaccount,
        type: selected === "Fixed" ? "FLAT" : "PERCENTAGE",
        value: selected === "Percentage" ? sharePercentage : fixedAmount,
      };
      let resp;
      if (splitRecord) {
        // splittingFormState.paymentLinkId = response.payload.id;
        resp = await api.patch(
          `/services/${service?.id}/payment-splitting/${splitRecord?.id}`,
          {
            // id: splittingFormState.id,
            type: formdata.type,
            value: formdata.value,
            subAccountId: formdata.subAccountId,
            status: splitRecord.status,
          }
        );
      } else {
        resp = await api.post(
          `/services/${service?.id}/payment-splitting`,
          formdata
        );
      }
      toast.success(resp.message);
      // getUpdatedSplitting();
      // onClose();
    } catch (error) {
      consoleLog("SPLIT ERROR:", error);
      toast.error("Couldn't process splitting");
      // getUpdatedSplitting();
    } finally {
      setIsProcessing(false);
    }
  };

  // const onSubmit = async (values) => {
  //   if (isFormValid) {
  //     try {
  //       setIsProcessing(true);
  //       formstate.type =
  //         selectedTab === "one-time" ? "ONE_TIME" : ["SUBSCRIPTION"];
  //       const response = await api.post(
  //         `/services/${service?.id}/payment-link`,
  //         formstate
  //       );
  //       if (response.status === true) {
  //         if (
  //           splittingFormState.value &&
  //           response.payload.id &&
  //           response.payload instanceof Object
  //         ) {
  //           splittingFormState.paymentLinkId = response.payload.id;
  //           const resp = await api.post(
  //             `/services/${service?.id}/payment-splitting`,
  //             splittingFormState
  //           );
  //           if (resp.status === true) {
  //             toast.success(resp.message);
  //           } else {
  //             toast.error(resp.message);
  //           }
  //         }
  //         setIsProcessing(false);
  //         history.push(`/my-apps/dashboard`);
  //         toast.success(response.message);
  //       } else {
  //         toast.error(response.message);
  //       }
  //     } catch (error) {
  //       setIsProcessing(false);
  //       // @ts-ignore
  //       toast.error(error);
  //     }
  //   }
  // };

  const inputArray = [
    {
      id: "amount",
      title: "Amount",
      type: "conditionaltext",
      condition: needAmount,
    },
    {
      id: "subaccount",
      title: "Sub Account",
      type: "select",
      options: [
        { label: "--- Select Sub Account ---", value: "" },
        ...subAccounts.map((sub) => ({
          label: sub?.subaccountname,
          value: sub?.id,
        })),
      ],
    },
    {
      id: "addsub",
      title: "addsub",
      type: "addsub",
    },
  ];

  const getAccountName = async () => {
    try {
      setbanknameStatus("Loading...");
      setAccountError(false);
      // @ts-ignore
      const accountNumber = subAccountForm.accountNumber;
      const selectedBank: any = bankList.find(
        (bank: any) => bank.id === subAccountForm.bankName
      );
      const res = await subaccountClass.getAccountName(
        accountNumber,
        selectedBank.code
      );
      if (res.accountName) {
        setbanknameStatus("");
        setSubAccountsForm({
          ...subAccountForm,
          accountName: res.accountName,
        });
        // setaccountName(res.accountName);
      } else {
        setAccountError(true);
        setbanknameStatus("");
      }
      // consoleLog("bank name", res);
    } catch (error) {
      setAccountError(true);
      setbanknameStatus("");
    }
  };

  const inputSubAccountArray = [
    {
      id: "name",
      title: "Name",
      type: "text",
      // @ts-ignore
      value: subAccountForm.name,
      // @ts-ignore
      onChange: (val: any) =>
        setSubAccountsForm({ ...subAccountForm, name: val }),
      disabled: false,
    },
    {
      id: "bankName",
      title: "Bank name",
      type: "select",
      options: [
        { label: "--- Select Bank ---", value: "" },
        ...bankList.map((sub: any) => ({
          label: sub?.name,
          value: sub?.id,
        })),
      ],
    },
    {
      id: "accountNumber",
      title: "Account Number",
      type: "accountNumber",
      // @ts-ignore
      value: subAccountForm.accountNumber,
      // @ts-ignore
      onChange: (val: any) =>
        setSubAccountsForm({ ...subAccountForm, accountNumber: val }),
      disabled: false,
    },
    {
      id: "accountName",
      title: "Account Name",
      type: "text",
      // @ts-ignore
      value: subAccountForm.accountName,
      // @ts-ignore
      onChange: (val: any) =>
        setSubAccountsForm({ ...subAccountForm, accountName: val }),
      disabled: true,
    },
  ];

  const initialValues = () => {
    let obj: any = {};
    for (let elm of inputArray) {
      obj[elm.id] = "";
    }
    return obj;
  };

  const displayFormInput = () => {
    return inputArray.map((elm, idx) => {
      switch (elm.type) {
        case "addsub":
          return (
            <AddSubAccountStyle onClick={() => setShowSubAccount(true)}>
              Add Sub Account
            </AddSubAccountStyle>
          );
        case "text":
          return (
            <Form.Field.Input
              key={`INP${idx}`}
              name={elm.id}
              type={elm.type}
              label={elm.title}
              value={fstate[elm.id] ?? ""}
              // onChange={(value: any) => elm.onChange(value)}
              onChange={(val: any) =>
                onChangeTextHandler(val, elm.id, fstate, setFstate)
              }
              // tip="Kindly enter your BVN"
            />
          );
        case "select":
          return (
            <Form.Field.Select
              key={`INP${idx}`}
              name={elm.id}
              label={elm.title}
              options={elm.options}
              onChange={(val: any) => {
                onChangeTextHandler(val, elm.id, fstate, setFstate);
                // alert(val);
              }}
              value={fstate.subaccount}
              // value={fstate[elm.id] ?? selectedValue()}
              // options={[{ label: "" }]}
              // onChange={(e: any) => {}}
              //  renderOption={""}
              //  renderValue={""}
              // tip="Kindly enter your user password."
            />
          );
      }
    });
  };

  const getAllBanks = async () => {
    const banks = await subaccountClass.getAllBanks();
    const sortedBanks = banks.sort((a: any, b: any) =>
      a.name > b.name ? 1 : b.name > a.name ? -1 : 0
    );
    if (sortedBanks) {
      setbankList(sortedBanks);
    }
  };

  const handleShare = (percentage: any) => {
    const regexp = new RegExp("^[0-9]*$");
    if (!regexp.test(percentage) || +percentage < 0 || +percentage > 100) {
      return;
    }
    setSharePercentage(+percentage);
    setYourShare(100 - +percentage);
  };

  const handleFixedAmount = (fixedAmount: any) => {
    const regexp = new RegExp("^[0-9]*$");
    if (!regexp.test(fixedAmount) || +fixedAmount < 0) {
      return;
    }
    setFixedAmount(+fixedAmount);
  };

  const displaySubAccountFormInput = () => {
    return inputSubAccountArray.map((elm, idx) => {
      switch (elm.type) {
        case "text":
          return (
            <Form.Field.Input
              key={`INP${idx}`}
              name={elm.id}
              type={elm.type}
              label={elm.title}
              value={elm.value}
              // @ts-ignore
              onChange={(value: any) => elm.onChange(value)}
              disabled={elm.disabled}
              // tip="Kindly enter your BVN"
            />
          );
        case "accountNumber":
          return (
            <>
              <Form.Field.Input
                key={`INP${idx}`}
                name={elm.id}
                type={elm.type}
                label={elm.title}
                value={elm.value}
                maxLength={10}
                // @ts-ignore
                onChange={(value: any) => elm.onChange(value)}
                disabled={elm.disabled}
                // tip="Kindly enter your BVN"
              />
              <div style={{ textAlign: "left" }}>
                {banknameStatus}
                {accountError ? (
                  <small style={{ color: "red" }}>
                    Wrong account number, please check and try again.
                  </small>
                ) : null}
              </div>
            </>
          );
        case "select":
          return (
            <Form.Field.Select
              key={`INP${idx}`}
              name={elm.id}
              label={elm.title}
              options={elm.options}
              onChange={(val: any) => {
                onChangeTextHandler(
                  val,
                  elm.id,
                  subAccountForm,
                  setSubAccountsForm
                );
              }}
              value={subAccountForm.bankName}
            />
          );
      }
    });
  };

  useEffect(() => {
    if (splitRecord) {
      setFstate({ ...fstate, subaccount: splitRecord.subAccountId });
      setSelected(splitRecord.type === "FLAT" ? "Fixed" : "Percentage");
      if (splitRecord.type === "FLAT") {
        // setFixedAmount(splitRecord.value);
        handleFixedAmount(splitRecord.value);
      } else {
        setSharePercentage(splitRecord.value);
        handleShare(splitRecord.value);
      }
    }
  }, [subAccounts]);

  useEffect(() => {
    getAllSubAccounts();
  }, []);

  // useEffect(() => {
  //   if (data?.payload.length) {
  //     setisLoading(false);
  //     const accountList = data?.payload.map((account: any, index: any) => {
  //       const acc = {
  //         id: account.id,
  //         sn: index + 1,
  //         subaccountname: account.name,
  //         servicename: account.servicename
  //           ? account.servicename
  //           : "No service name",
  //         bank_account: account.accountNumber,
  //         Bank_name: account.bankName,
  //         account_name: account.accountName,
  //       };
  //       return acc;
  //     });
  //     setSubAccounts(accountList);
  //   }
  //   if (!data) {
  //     // setisLoading(true);
  //   }
  // }, [data]);

  useEffect(() => {
    if (!fstate.subaccount) {
      setFormValid(false);
      return;
    }
    if (
      //@ts-ignore
      (selected === "Percentage" && !sharePercentage) ||
      //@ts-ignore
      (selected === "Fixed" && !fixedAmount)
    ) {
      setFormValid(false);
      return;
    }
    setFormValid(true);
  }, [fstate, selected, sharePercentage, fixedAmount]);

  useEffect(() => {
    if (
      !subAccountForm.name ||
      !subAccountForm.bankName ||
      !subAccountForm.accountName ||
      !subAccountForm.accountNumber
    ) {
      setSubFormValid(false);
      return;
    }
    setSubFormValid(true);
  }, [subAccountForm]);

  const updateSubAccountList = async () => {
    try {
      const subs = await getAllSubAccounts();
      setShowSubAccount(false);
      setFstate({ ...fstate, subAccountId: subs[subs.length - 1].id });
    } catch (error) {}
  };

  const onCreate = async () => {
    try {
      setIsProcessing(true);
      // @ts-ignore
      subAccountForm.bankCode = bankList.find(
        (bank: any) => bank.id === subAccountForm.bankName
      ).code;
      const res = await subaccountClass.createSubAccount(subAccountForm);
      // consoleLog("RESPONSE TO ADD SUBACCOUNT:", res);
      if (res === "Subaccount created successfully") {
        updateSubAccountList();
        //set showBankform to false
        // set resturn id to selected bank id
        // setFstate({ ...fstate, subAccountId: res.payload.id });
      }
      // consoleLog(formdata);
    } catch (error) {
      consoleLog(error);
    } finally {
      setIsProcessing(false);
    }
  };

  useEffect(() => {
    getAllBanks();
  }, []);

  useEffect(() => {
    if (subAccountForm.bankName && subAccountForm.accountNumber.length === 10) {
      getAccountName();
    }
  }, [subAccountForm.accountNumber]);

  const openModal = () => {};
  const fetchData = (val?: string) => getAllSubAccounts(val);

  const backFn = () => {
    setShowSubAccount(false);
    setFstate({ ...fstate, subaccount: "" });
  };
  if (showSubAccount)
    return (
      <AddSubAccountModal
        backFn={backFn}
        openModal={openModal}
        fetchData={fetchData}
      />
    );

  return (
    <FormBody>
      <LeftPane>
        <div style={{ width: "400px" }}>
          <FormTitle>{title}</FormTitle>
          <TypeButtonGroup>
            <TypeButton
              onClick={() => setSelected("Percentage")}
              className={selected === "Percentage" ? "selected" : ""}
            >
              Percentage
            </TypeButton>
            <TypeButton
              onClick={() => setSelected("Fixed")}
              className={selected === "Fixed" ? "selected" : ""}
            >
              Fixed
            </TypeButton>
          </TypeButtonGroup>

          {/* @ts-ignore  */}
          <Form
            enableReinitialize
            initialValues={{
              subaccount: "",
              amount: 0,
            }}
            validations={{
              subaccountperc: Form.is.required(),
              subaccountflat: Form.is.required(),
            }}
            onSubmit={onSubmit}
          >
            <FormElement>
              <FormContainer>
                {isLoading ? (
                  <div
                    style={{
                      height: "50px",
                      marginTop: "25px",
                      textAlign: "left",
                    }}
                  >
                    Loading...
                  </div>
                ) : (
                  displayFormInput()
                )}

                <TransSplitContainer>
                  <TransSplitTitle>Transaction Split</TransSplitTitle>
                </TransSplitContainer>
                <SplitInputRow
                  style={{
                    display: selected === "Percentage" ? "flex" : "block",
                  }}
                >
                  {/* <Form.Field.Input
                    name="share"
                    type="text"
                    placeholder={selected === "Percentage" ? "100%" : "1000"}
                    label="Original amount"
                  /> 
                  <TransSplitIcon>-</TransSplitIcon>*/}

                  {selected === "Percentage" ? (
                    <>
                      {/* <Form.Field.Input
                        name="subaccountperc"
                        type="text"
                        placeholder=""
                        value={sharePercentage}
                        onChange={(e: any) => handleShare(e)}
                        label="Subaccount share"
                      /> */}
                      <Form.Field.Input
                        name="subaccountperc"
                        type="text"
                        placeholder={
                          selected === "Percentage" ? "100%" : "e.g 1000"
                        }
                        label="Subaccount share"
                        value={sharePercentage}
                        required
                        onChange={(e: any) => handleShare(e)}
                      />
                      <TransSplitIcon>=</TransSplitIcon>
                      <Form.Field.Input
                        name="subaccountperc"
                        type="text"
                        placeholder={
                          selected === "Percentage" ? "100%" : "1000"
                        }
                        disabled
                        label="Your share"
                        value={`${yourShare}%`}
                      />
                    </>
                  ) : (
                    <Form.Field.Input
                      name="subaccountflat"
                      type="text"
                      placeholder=""
                      required
                      label="Subaccount share"
                      value={fixedAmount}
                      onChange={(e: any) => handleFixedAmount(e)}
                    />
                  )}
                </SplitInputRow>
              </FormContainer>

              <Selectors>
                <ActionButton
                  type="submit"
                  variant="primary"
                  onClick={onSubmit}
                  isWorking={isProcessing}
                  disabled={!isFormValid || isProcessing}
                  style={{ marginRight: "10px" }}
                >
                  Save
                </ActionButton>
              </Selectors>
            </FormElement>
          </Form>
        </div>
      </LeftPane>
      <RightPane>
        <ImageLoader
          source={PaymentLinkModalImg}
          width="220"
          height="450"
          alt="Add account"
          style={{ width: "100%", height: "auto" }}
        />
        {/* <img
          src={PaymentLinkModalImg}
          alt="Add account"
          style={{ width: "100%", height: "auto" }}
        /> */}
      </RightPane>
    </FormBody>
  );
};

export default AddSplitting;
