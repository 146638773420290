import React, { useEffect, useState } from "react";
import { Button, Form, Icon } from "shared/components";
import {
  ActionButton,
  ComponentBody,
  ComponentFooter,
  FormElement,
  StyledLink,
  Title,
  PasswordCont,
} from "./Styles";
import toast from "shared/utils/toast";
import { useHistory } from "react-router-dom";
import api from "shared/utils/api";
import Preloader from "shared/components/preloader";
import * as Yup from "yup";
import PasswordMeter from "../PasswordMeter";
import {
  getStoreLocalStorage,
  getTempId,
  holdPass,
  storeTempId,
} from "shared/utils/authToken";
import { authPath } from "apiServices/paths";
import { overviewPath } from "apiServices/paths";
import consoleLog from "shared/utils/consoleLog";

const Schema = Yup.object().shape({
  password: Yup.string().required("Please Enter your password"),
  confirmPassword: Yup.string()
    .required()
    .oneOf([Yup.ref("password"), null], "Password must match"),
});

const SetPassword = () => {
  const [isLoginIn, setIsLoginIn] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPass, setconfirmPass] = useState("");

  const [passwordVisibleA, setPasswordVisibleA] = useState(false);
  const [passwordVisibleB, setPasswordVisibleB] = useState(false);
  const [isEntryValidated, setisEntryValidated] = useState(false);
  const [isFormValid, setisFormValid] = useState(false);

  const history = useHistory();

  const initlValues = {
    password: "",
    confirmPassword: "",
  };

  const onSubmit = async (value: any, form: any) => {
    holdPass(password);
    setIsLoginIn(true);
    const invitation = getStoreLocalStorage("verificationHash");
    let tempId: any = getTempId();
    let invitat = getStoreLocalStorage("invitation");
    const values: any = {
      tempId,
      password,
      invitation: invitat ? true : false,
    };
    consoleLog("password stage ", values);
    
    try {
      const { payload }: any = await api.post("/authentication/signup", {
        ...values,
      });

      consoleLog("Payload: ", payload);
      storeTempId(payload.tempId);
      setIsLoginIn(false);
      // history.push(overviewPath.OVERVIEW);
      history.push(authPath.PASSWORD_SUCCESS);
    } catch (error) {
      const err: any = error;
      toast.error(err ?? "Something went wrong");
      setIsLoginIn(false);
    }
  };

  const handleKeyPressEvent = (value: any) => {
    setPassword(value);
  };

  const handlePressEvent = (value: any) => {
    setconfirmPass(value);
  };

  const handleValidation = () => {
    const isValid =
      password.length >= 8 &&
      confirmPass.length >= 8 &&
      password === confirmPass;
    setisFormValid(isValid);
  };

  useEffect(() => {
    handleValidation();
  }, [confirmPass, password]);

  return (
    <ComponentBody>
      <Title>SET PASSWORD</Title>
      {/* @ts-ignore */}
      <Form
        enableReinitialize
        initialValues={initlValues}
        validationSchema={Schema}
        onSubmit={onSubmit}
      >
        <FormElement>
          <PasswordCont>
            <Form.Field.Input
              name="password"
              type={!passwordVisibleA ? "password" : "text"}
              label="Password"
              onChange={handleKeyPressEvent}
              // tip="Kindly enter your user email address."
            />

            <Icon
              onClick={() => setPasswordVisibleA(!passwordVisibleA)}
              type={passwordVisibleA ? "eye" : "eye-blocked"}
              style={{
                color: "#760000",
                fontSize: 18,
                position: "absolute",
                right: 5,
                top: 40,
              }}
            />
          </PasswordCont>

          <PasswordCont>
            <Form.Field.Input
              name="confirmPassword"
              type={!passwordVisibleB ? "password" : "text"}
              label="Confirm password"
              onChange={handlePressEvent}
              // tip="Kindly enter your user password."
            />

            <Icon
              onClick={() => setPasswordVisibleB(!passwordVisibleB)}
              type={passwordVisibleB ? "eye" : "eye-blocked"}
              style={{
                color: "#760000",
                fontSize: 18,
                position: "absolute",
                right: 5,
                top: 40,
              }}
            />
          </PasswordCont>

          <ActionButton
            type="submit"
            variant="primary"
            isWorking={isLoginIn}
            disabled={isEntryValidated && isFormValid ? false : true}
          >
            Set Password
          </ActionButton>
        </FormElement>
      </Form>
      <PasswordMeter
        password={password}
        setStatusFn={(value: boolean) => setisEntryValidated(value)}
      />
    </ComponentBody>
  );
};

export default SetPassword;
