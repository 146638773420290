import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useRouteMatch,
} from "react-router-dom";

export default function Index() {
  const match = useRouteMatch();
  const dispatch = useDispatch();
  return (
    
    <div><h1>Testing page</h1></div>
  );
}
