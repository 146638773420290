import api from "shared/utils/api";
import toast from "shared/utils/toast";
import useApi from "shared/hooks/api";
import consoleLog from "shared/utils/consoleLog";

class chargeBack {
  async getChargeBack(id: any, obj: object) {
    try {
      const response: any = await api.get(
        // `/fp_admin/dispute/user/${id}`
        `/chargeback/dispute/user/${id}`,
        obj
      );
      // consoleLog(response);
      if (response?.payload)
        return { status: "success", data: response?.payload };
    } catch (error) {
      // @ts-ignore
      toast.error(error);
      // consoleLog("error fetching dispute", error);
      return { status: "failed", data: error };
    }
  }

  async getSingleChargeBack(id: any) {
    try {
      const response = await api.get(`/fp_admin/dispute/${id}`);
      consoleLog("response", response);
      if (response.payload) return response.payload;
    } catch (error) {
      // @ts-ignore
      toast.error(error);
      consoleLog("error fetching single dispute", error);
    }
  }

  async changeStatus(id: any, payload: any) {
    consoleLog("first", id, payload);
    try {
      const response = await api.post(
        `/fp_admin/dispute/${id}/thread`,
        payload
      );
      consoleLog("response", response);
      if (response.message) return response.message;
    } catch (error) {
      //@ts-ignore
      toast.error(error);
      consoleLog("error changing dispute status", error);
    }
  }
}

const chargeBackClass = new chargeBack();
export default chargeBackClass;
