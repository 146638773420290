
import { getStoreBusinessID } from "shared/utils/businessesData";
import api from "shared/utils/api";
import consoleLog from "shared/utils/consoleLog";

class Service {
  async getAllServices(id: any) {
    consoleLog("current", id);
    const res = await api.get(`/client/business/${id}/services`);
    return res?.payload;
  }

  getService(id: any) {
    this.getAllServices(id).then((res) => {
      const service = res.filter((data: any) => data.id === id);
      consoleLog(service);
      return service;
    });
  }

  async getServiceName(id: any) {
    const services = await this.getAllServices(id);
    const service = services.find((data: any) => data.id === id);
    consoleLog({ service });
    const data = service.name;
    consoleLog("data", data);
    return data;
  }
}

const ServicesClass = new Service();
export default ServicesClass;
