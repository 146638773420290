import { useCallback, useEffect, useState } from "react";
import { GraphAreaLayout, PanelTitle, ChartSecton, Chart, GraphContainer } from "./Style";
import {
  PieChart,
  Pie,
  Sector,
  Cell,
  ResponsiveContainer,
  Legend,
} from "recharts";
import api from "shared/utils/api";
import { RootStateOrAny, useSelector } from "react-redux";
import { Spinner } from "shared/components";

const styles = {
  legend: {
    fontSize: "12px",
    display: "inline-block",
    align: "center",
  },
};


const GraphArea = () => {
  const [totalValues, setTotalValues] = useState(0.0);
  const [datas, setDatas] = useState<any>([]);
  const [width, setWidth]   = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  const [isloading, setisloading] = useState(false);

  const service = useSelector((store: RootStateOrAny) => store.service);

  const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042" , "#ff4242" , "#2b1962", "#ff42d0"];

  const getAmountDetails=async()=>{
    setisloading(true);
    try{
      const details=await api.get(`/services/${service?.id}/channel-summary`);
      // console.log("Detail: ", details?.payload);
      if(details.status){
        await setTotalValues(arrSum(details?.payload));
        const totoal=arrSum(details?.payload)
        const values=await details?.payload.map((el: any, ind: number)=>{
          const perc=(el.value/totoal) * 100;
          let type='low';
          
          if(perc>49 && perc<89){
            type='medium';
          }

          if(perc>89){
            type='high';
          }
          return ({
            perc:`${perc.toFixed(2)}%`,
            type: type,
            value: el.value,
            channel: el.channel
          })
        })
        await setDatas(values);
        
      }
    }catch(err: any){

    }finally{
      setisloading(false);
    }
    
  }

  const arrSum = (array: any) =>
    array.reduce(
      (sum: number, num: any) =>
        sum + (Array.isArray(num.value) ? arrSum(num.value) : num.value * 1),
      0
    );

  const onPieEnter = () => {};

  const updateDimensions = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  }

  // // console.log("Channel data: ", datas)
  const renderChart=datas.map((el: any, ind: number)=>{
    return(
      <GraphContainer type={el.type} key={ind}>
          <p>{el.channel}</p>
          <div>
              <span style={{width: `${((el.value/totalValues) * 100).toFixed(2)}%`}}>&nbsp;{((el.value/totalValues) * 100).toFixed(2)}%</span>
          </div>
      </GraphContainer>
    )
  })

  useEffect(() => {
    getAmountDetails()
  }, []);

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  return (
    <GraphAreaLayout>
      {isloading && 
        <div className="overlay">
          <Spinner />
        </div>
      }
      {!isloading && 
        <ChartSecton>
          <Chart>
            {renderChart}
          </Chart>
        </ChartSecton>
      }
      {/* <ResponsiveContainer  height={height/2+40}>
        
        <PieChart width={500} height={100} onMouseEnter={onPieEnter}>
          <Pie
            data={datas}
            nameKey="channel"
            dataKey="value"
            
            cy={150}
            labelLine={true}
            label={({
              cx,
              cy,
              midAngle,
              innerRadius,
              outerRadius,
              value,
              index
            }) => {
              const RADIAN = Math.PI / 180;
              // eslint-disable-next-line
              const radius = 25 + innerRadius + (outerRadius - innerRadius);
              // eslint-disable-next-line
              const x = cx + radius * Math.cos(-midAngle * RADIAN);
              // eslint-disable-next-line
              const y = cy + radius * Math.sin(-midAngle * RADIAN);
              // console.log("Total value: ", totalValues)
              return (
                <text
                  x={x}
                  y={y}
                  fill={COLORS[index % COLORS.length]}
                  textAnchor={x > cx ? "start" : "end"}
                  fontSize={"15px"}
                  dominantBaseline="central"
                >
                  {(((value / totalValues) * 100).toFixed(2)+"%")}
                </text>
              );
            }}
            innerRadius="20%"
            outerRadius="40%"
            fill="#8884d8"
            paddingAngle={5}
            isAnimationActive={true}
          >
            {datas.map((entry: any, index: number) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          <Legend wrapperStyle={styles.legend} />
        </PieChart>
      </ResponsiveContainer> */}
    </GraphAreaLayout>
  );
};

export default GraphArea;
