import {
    ParentCont,
    Indicator,
    Divider
} from './Styles'
import {Icon} from "shared/components";

function PageIndicator({pageArr, page}:any) {
  return (
    <ParentCont>
       {
        pageArr.map((cur:any,index:number, status:boolean) => (
            <ParentCont key={index}>
                <Indicator
                    style={{
                        color: page >= (index + 1) ? '#fff' : '#760000',
                        backgroundColor: page >= (index + 1) ? '#760000' : 'rgba(250, 214, 214, 0.55)',
                    }}
                >
                    {
                        page > (index + 1) ? (
                            <Icon type={'cloud-check'} size={25} />
                        ) :
                        (page > (index + 1)) && status ? (
                            <Icon type={'cloud-check'} size={25} />
                        )
                        : (
                            (index + 1)
                        )
                    } 
                </Indicator>
                <Divider 
                    style={{
                        backgroundColor: page > (index + 1) ? '#760000' : 'rgba(250, 214, 214, 0.55)',
                        display: pageArr.length === (index + 1) ? 'none' : 'block' 
                    }}
                /> 
            </ParentCont>
        ))
       }
    </ParentCont>
  )
};

export default PageIndicator;