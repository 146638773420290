import { useHistory } from "react-router-dom";
import {
  CardBottomText,
  CardCont,
  CardInnerCont,
  CardLinkDiv,
  CardTopText,
} from "./styles";
import { useState } from "react";

interface CardInt {
  topText: string;
  bottomText: string;
  expand?: boolean;
  link?: string;
}

const OverviewRightCard = ({ topText, bottomText, expand, link }: CardInt) => {
  const history = useHistory();
  return (
    <CardCont expand={expand}>
      <CardInnerCont>
        <CardTopText>{topText}</CardTopText>
        <CardBottomText>{bottomText}</CardBottomText>
      </CardInnerCont>
      {link && (
        <CardLinkDiv onClick={() => history.push(link)}>See All</CardLinkDiv>
      )}
    </CardCont>
  );
};

export default OverviewRightCard;
