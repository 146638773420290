import styled from "styled-components";

import breakpoints from "shared/utils/breakpoints";
import { font } from "shared/utils/styles";

type Props = {
  withBottomMargin: string;
};

export const ModalBody = styled.div`
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/opacity/see-through */
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  // text-align: center;
  width: 100%;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LeftPane = styled.div`
  min-width: 65%;
  max-width: 70%;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  vertical-align: middle;
  padding-top: 30px;
  background: #fbfbfb;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  @media ${breakpoints.device.max.xs} {
    max-width: 100%;
  }
`;

export const RightPane = styled.div`
  width: 35%;
  margin: 2em 1em;
  order: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #fff;
  @media ${breakpoints.device.max.xs} {
    width: 100%;
    margin: 15px 0;
  }
`;

export const imageStyle = {
  width: "70%",
  margin: "auto",
};

export const ImageContainer = styled.div`
  background: #fbfbfb;
  border-radius: 20px;
  padding: 15px 5px;
  width: 75%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ImageContainer2 = styled.div`
  background: #fbfbfb;
  border-radius: 20px;
  padding: 15px 5px;
  width: 75%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: end;
`;

export const RightTopPane = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  @media ${breakpoints.device.max.xs} {
    flex-direction: row;
    justify-content: center;
  }
`;
export const RightBottomPane = styled.p`
  color: #828282;
  text-align: center;
  ${font.size(14)}
`;

export const FormBody = styled.div`
  display: flex;
  // padding: 25px 40px 35px;
  justify-content: space-between;
  min-height: 70vh;
  @media ${breakpoints.device.max.xs} {
    flex-direction: column;
  }
`;
