import styled from "styled-components";

export const Dot = styled.div`
  width: 10px;
  height: 10px;
  padding: 5px;
  margin-right: 10px;
  color: transparent;
  border-radius: 5px;
  display: inline-block;
`;