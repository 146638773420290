import styled from 'styled-components';
import { font, sizes, color, mixin, zIndexValues } from 'shared/utils/styles';

export const Page = styled.div`
  width: 100%;
  position: relative;
  float: left;
  margin-right:-10px;
`;

export const Item = styled.div`
  position: relative;
  width: 100%;
  height: 42px;
  line-height: 42px;
  padding-left: 88%;
  color: #deebff;
  transition: color 0.1s;
  display: flex;
  flex-direction: row;
  ${mixin.clickable}
  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }
`;

export const ItemText = styled.span`
  opacity: 1;
  text-transform: uppercase;
  transition: all 0.1s;
  transition-property: right, visibility, opacity;
  ${font.bold}
  ${font.size(12)}
`;