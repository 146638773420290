import styled from "styled-components";
import { keyframes } from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;

export const LoaderContainer = styled.div`
  margin-top: 5rem;
  position: relative;
`;

const spin = keyframes`
 from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const FirstContainer = styled.div`
  width: 200px;
  height: 200px;
  border-radius: 50%;
  position: relative;
  border: 3px solid #f3f3f3;
  background-color: #fcfafa;
  border-radius: 50%;
  border-top: 3px solid #ad3232;
  border-left: 3px solid #ad3232;
  width: 200px;
  height: 200px;
  -webkit-animation: spin 2s linear infinite;
  animation: ${spin} 2s linear infinite;
`;

export const SecondContainer = styled.div`
  position: relative;
  background-color: white;
  width: 160px;
  height: 160px;
  border-radius: 50%;
  top: -11.5rem;
  left: 1.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  /* box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px; */
  box-shadow: rgba(137, 165, 193, 0.2) 0px 8px 24px;
`;

export const Dot = styled.div`
  position: relative;
  width: 10px;
  height: 10px;
  background-color: #ad3232;
  border: 2px solid white;
  border-radius: 999px;
  border: 6px solid #fff;
  top: 1rem;
  right: -9.9rem;
`;

export const SpinImage = styled.img`
  width: 4rem;
  height: 4rem;
`;
