import lodash, { values } from "lodash";
import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { Link, useRouteMatch } from "react-router-dom";
import { DatePicker, GradientLoader, Icon, Input } from "shared/components";
import overviewClass from "./overviewClass";
import {
  AmountLabel,
  ApplyButton,
  Balance,
  BalanceContainer,
  BalanceInfo,
  BalanceLabel,
  ButtonRow,
  CancelButton,
  ChartBackground,
  CurrencyLabel,
  CustomFilterBox,
  CustomFilterContent,
  DateRow,
  DownloadSummaryBtn,
  GraphButton,
  GraphContainer,
  LedgerLabel,
  NoSettlement,
  PageBottomBody,
  PageMidBody,
  SettlementLink,
  SubHeader,
  SubHeaderChildren,
  SubHeaderChildren2,
  Transaction,
  Transaction2,
  TransactionAmount,
  TransactionButton,
  TransactionDesc,
  TransactionDesc2,
  TransactionLabel,
  ActionoloadButtonPdf,
  FileDownloadSelect,
  ExportDropdownDiv,
  ExportText,
  CardTray,
  TRevenueText,
  TRevenueFigure,
  RightStatementDiv,
} from "./Styles";
import {
  PopUpCont,
  PopUp,
  DownloadSec,
  DownloadSec2,
  InputHolder,
  LabelHolder,
  DownloadOptFooter,
  ResetDownloadButton,
  ActionoloadButton,
  FormRow,
} from "shared/components/DashboardSubHeader/Styles";

import BarChart from "./BarChart";
import api from "shared/utils/api";
import toast from "shared/utils/toast";
import { calenderDate, formatDate } from "shared/utils/dateTime";
import {
  setBusinessLists,
  setCurrentBusiness,
} from "store/actions/businessAction";
import { setCurrentUser } from "store/actions/userAction";
import { ActionTypes } from "store/constants/actionType";
import ExcelExport from "shared/components/ExcelExport";
import consoleLog from "shared/utils/consoleLog";
import shouldPerform, { getRoleName } from "shared/utils/assignRoles";
import { useHistory } from "react-router-dom";
import { covertToNaira } from "shared/utils/formatAmount";
import RadioButton from "shared/components/RadioButton";
import { onChangeTextHandler } from "shared/utils/formValidation";
import PdfExport from "shared/components/PdfExport/Index";
import {
  PDFDownloadLink,
  Document,
  Page,
  Text,
  View,
  StyleSheet,
} from "@react-pdf/renderer";
import CaretDown from "App/assets/images/caretdown.svg";
import Tour from "App/assets/images/tour.svg";
import DocumentationIcon from "App/assets/images/documentation.svg";
import AccountIcon from "App/assets/images/accountsettings.svg";
import KnowledgeBaseIcon from "App/assets/images/knowledgebase.svg";
import OverviewTopCard from "shared/components/OverviewTopCard";
import OverviewRightCard from "shared/components/OverviewRightCard";

import { useTour } from "@reactour/tour";
import { useAppContext } from "Layout/tour/context";
import { tourSteps } from "shared/utils/toursteps";

const Overview = () => {
  const {
    setState,
    state: { run },
  } = useAppContext();

  // const userProfile = useSelector((store: RootStateOrAny) => store.users);
  // consoleLog("USER PROFILE: ", userProfile)
  const history = useHistory();

  const currentbusiness = useSelector(
    (store: RootStateOrAny) => store.business
  );
  const businesses = useSelector((store: RootStateOrAny) => store.businesses);
  const currentUser = useSelector((store: RootStateOrAny) => store.currentUser);

  // consoleLog(businesses, "businesses");
  // consoleLog(currentbusiness, "currentbusiness");

  let firstActiveBusiness =
    businesses instanceof Array &&
    businesses.find((el) => el.status === "ACTIVE");
  if (!firstActiveBusiness) {
    firstActiveBusiness = [];
  }

  const [periodTransactionSum, setperiodTransactionSum] = useState<
    string | number
  >("");

  const [sendData, setSendData] = useState<any>({});
  const [availableBalance, setavailableBalance] = useState<any>("");
  const [showCustom, setShowCustom] = useState<any>(false);
  const [ledgerBalance, setledgerBalance] = useState<any>("");
  const [transactions, settransactions] = useState<[]>([]);
  const [totalAmountLabel, settotalAmountLabel] = useState("");
  const [isData, setisData] = useState<boolean>(false);
  const [selectedLabel, setSelectedLabel] = useState("day");
  const [startDate, setStartDate] = useState(new Date().toISOString());
  const [endDate, setEndDate] = useState(new Date().toISOString());
  const [totalSettlement, setTotalSettlement] = useState<number>(0);
  const [totalTransactions, setTotalTransactions] = useState<number>(0);
  const [settlemnts, setSettlements] = useState<any>([]);
  const [openDownload, setOpenDownload] = useState<boolean>(false);
  const [fstate, setFstate] = useState({
    value: "Excel Sheet",
  } as any);
  const match = useRouteMatch();
  const dispatch = useDispatch();

  const downloadFileOpt = [
    {
      label: "Excel Sheet",
      value: true,
    },
    {
      label: "Pdf File",
      value: false,
    },
  ];

  //================= Component methods ==============
  //Get today's transactions
  const todayTransactions = useCallback(async () => {
    // consoleLog("today transaction called on business id", currentbusiness?.id);
    setSelectedLabel("day");
    const res = await overviewClass.getTodayTractions(currentbusiness?.id);
    const amounts = res?.map((record: any) => covertToNaira(record?.amount));
    // const arr = res.slice(0, 7);
    const arr = res;
    // consoleLog("today transaction ", arr);
    setperiodTransactionSum(lodash.sum(amounts));
    settransactions(arr);
    settotalAmountLabel("Today");
  }, []);

  //Get other transactions using filter
  const getTransactions = useCallback(async (filterby: any) => {
    setSelectedLabel(filterby);
    const res = await overviewClass.getTransactions(
      currentbusiness?.id,
      filterby
    );
    // consoleLog("transactions graph", res);
    const amounts = res?.map((record: any) => covertToNaira(record?.amount));
    // const arr = res.slice(0, 7);
    const arr = res;
    setperiodTransactionSum(lodash.sum(amounts));
    // consoleLog(arr, "Transaction Array");
    settransactions(arr);
    settotalAmountLabel(`This ${filterby}`);
  }, []);

  const getCustomTransactions = useCallback(async (filterby: any) => {
    setSelectedLabel(filterby);
    setShowCustom(!showCustom);
    const res = await overviewClass.getCustomTransactions(
      filterby,
      currentbusiness?.id,
      startDate,
      endDate
    );
    consoleLog("get custom trans ", res);
    const amounts = res?.map((record: any) => covertToNaira(record?.amount));
    // const arr = res.slice(0, 7);
    const arr = res;
    setperiodTransactionSum(lodash.sum(amounts));
    settransactions(arr);
    settotalAmountLabel(`This ${filterby}`);
  }, []);

  //Get business balance
  const getBusinessBalance = useCallback(async (id: any) => {
    const response = await overviewClass.getBusinessBalance(id);
    //// console.log(response, "BusinessBalance");
    setavailableBalance(
      response?.availableBalance
        ? covertToNaira(response?.availableBalance)
        : "0"
    );
    setledgerBalance(
      response?.legderBalance ? covertToNaira(response?.legderBalance) : "0"
    );
  }, []);

  //Sum up total settlement amount
  const sumUpAmount = (payload: any) => {
    let sum = 0;
    payload &&
      payload instanceof Array &&
      payload.forEach((cur: any) => (sum += cur.amount));
    return covertToNaira(sum);
  };

  const getAccountSettings = useCallback(async () => {
    // alert();
    // await dispatch(setCurrentUser());
    const getCurrenUser = await setCurrentUser();
    // consoleLog("GET MY USER:", getCurrenUser);
    if (!getCurrenUser) {
      history.push("/authenticate");
    }
    dispatch({
      type: ActionTypes.SET_USERS,
      payload: getCurrenUser ?? {},
    });
    await dispatch(setBusinessLists());
    // await dispatch(setCurrentBusiness(businesses[0] ?? []));
    await dispatch(setCurrentBusiness(firstActiveBusiness ?? []));
  }, []);

  //Get settlement data
  const getSettlementData = useCallback(async () => {
    try {
      const response: any = await api.get(
        `/client/business/${currentbusiness?.id}/settlements` //,
        // {
        //   businessId: currentbusiness?.id,
        // }
      );
      setSettlements(response?.payload);
      // consoleLog(response.payload, "all settlement")
      setTotalSettlement(sumUpAmount(response?.payload));
    } catch (error) {
      //toast.error(`${error}`);
    }
  }, []);

  //Get transaction data
  const getTransactionData = useCallback(async () => {
    try {
      const response: any = await api.get(
        // `/client/business/${currentbusiness?.id}/transactions`,
        `/business/${currentbusiness?.id}/transactions?filter=${selectedLabel}`
        // {
        //   businessId: currentbusiness?.id,
        // }
      );
      // consoleLog('get transactions data ', response)
      setTotalTransactions(sumUpAmount(response?.payload));
      // consoleLog(response.payload, "all transctaion");
    } catch (error) {
      // consoleLog("Error:", error);
      toast.error(`${error}`);
    }
  }, []);

  //======================================================

  useEffect(() => {
    //setdata(currentbusiness);
    (() => {
      if (
        currentbusiness &&
        currentbusiness !== null &&
        !(currentbusiness instanceof Array)
      ) {
        setisData(true);
        getBusinessBalance(currentbusiness?.id); //get business balance
        todayTransactions(); //Getting today's transactions for graph
        getSettlementData();
        getTransactionData();
      } else {
        getAccountSettings();
      }
    })();
    // handleClickStart();
  }, [currentbusiness]); //currentbusiness

  // consoleLog(calenderDate(new Date()));
  let refineSendData = () => {
    let nArr: any = [],
      resArr: any = [];
    if (
      sendData.labels instanceof Array &&
      sendData.datasets instanceof Array &&
      sendData?.datasets[0]?.data instanceof Array
    ) {
      for (let i = 0; i < sendData.labels.length; i++) {
        nArr = [];
        nArr.push(sendData.labels[i]);
        nArr.push(sendData.datasets[0].data[i] ?? "");
        resArr.push(nArr);
      }
      // consoleLog(resArr, "resss");
      return resArr;
    } else {
      return [];
    }
  };

  let summaryArray = [
    { id: "Period", label: totalAmountLabel },
    { id: "Total Revenue", label: periodTransactionSum },
    { id: "Data", label: refineSendData() },
    { id: "Available Balance", label: availableBalance },
    { id: "Total Settlement", label: totalSettlement },
    { id: "Total Transaction", label: totalTransactions },
  ];

  const MyDoc = ({ data }: any) => {
    const styles = StyleSheet.create({
      page: {
        backgroundColor: "#fff",
        color: "#000",
        display: "flex",
        flexDirection: "column",
      },
      section: {
        margin: 10,
        padding: 10,
        display: "flex",
        flexDirection: "row",
        marginTop: 20,
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
        borderColor: "#000",
        borderBottomWidth: 1,
      },
      text: {
        flexWrap: "wrap",
        width: 100,
        textAlign: "center",
      },
      viewer: {
        zIndex: 9999,
        width: window.innerWidth, //the pdf viewer will take up all of the width and height
        height: window.innerHeight,
      },
    });
    return (
      <Document>
        <Page size="A3" style={styles.page}>
          {data.map((cur: any, id: any) => (
            <View key={id} style={styles.section}>
              {cur.map((entry: any, idx: any) => (
                <Text key={idx} style={styles.text}>
                  {entry}
                </Text>
              ))}
            </View>
          ))}
        </Page>
      </Document>
    );
  };

  let summaryData = () => {
    let nArr: any = [],
      resArr: any = [];
    for (let el of summaryArray) {
      nArr = [];
      if (el.label instanceof Array) {
        nArr.push(el.id);
        nArr.push("");
        resArr.push(nArr);
        for (let elm of el.label) {
          resArr.push(elm);
        }
      } else {
        nArr.push(el.id);
        nArr.push(el.label);
        resArr.push(nArr);
      }
    }
    return resArr;
  };

  // consoleLog(summaryArray, "summaryArray");

  const handleClickStart = () => {
    setState({ run: true, tourActive: true, steps: tourSteps });
  };

  let topCardArray = [
    {
      icon: Tour,
      text: "Start Dashboard Tour",
    },
    {
      icon: KnowledgeBaseIcon,
      text: "Knowledge Base",
      link: "https://fountainpay.ng/",
      target: "_blank",
    },
    {
      icon: DocumentationIcon,
      text: "Documentation",
      link: "https://docs.fountainpay.ng/",
      target: "_blank",
    },
    {
      icon: AccountIcon,
      text: "Account Settings",
      link: "/settings/general",
    },
  ];

  let rightCardArray = [
    {
      topText: "Last Settlement",
      bottomText: `NGN 
      ${
        settlemnts?.length > 0
          ? settlemnts[settlemnts.length - 1].amount
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          : 0
      }
      .00`,
      link: "",
    },
    {
      topText: "Next Settlement",
      bottomText: `NGN
      ${
        settlemnts?.length > 2
          ? settlemnts[settlemnts?.length - 2].amount
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          : 0
      }
      .00`,
    },
    {
      topText: "Today Transaction",
      bottomText: `NGN
      ${totalTransactions.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
      .00`,
      expand: true,
    },
    {
      topText: "Total Settlement",
      bottomText: `NGN
      ${totalSettlement.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
      .00`,
      expand: true,
    },
  ];

  return (
    //<Layout PageTitle="Overview">
    <>
      <SubHeader>
        <SubHeaderChildren>
          <GraphButton
            onClick={todayTransactions}
            isActive={selectedLabel == "day"}
          >
            Today
          </GraphButton>

          <GraphButton
            onClick={() => getTransactions("week")}
            isActive={selectedLabel == "week"}
          >
            7 Days
          </GraphButton>

          <GraphButton
            onClick={() => getTransactions("month")}
            isActive={selectedLabel == "month"}
          >
            30 Days
          </GraphButton>

          <GraphButton
            onClick={() => getTransactions("year")}
            isActive={selectedLabel == "year"}
          >
            1 Year
          </GraphButton>

          <CustomFilterBox>
            <GraphButton onClick={() => setShowCustom(!showCustom)}>
              Custom{" "}
              <Icon
                style={{ marginTop: "5px" }}
                type="chevron-down"
                size={20}
              />
            </GraphButton>
            {showCustom && (
              <CustomFilterContent>
                <DateRow>
                  <DatePicker
                    // @ts-ignore
                    onChange={(value) => {
                      setStartDate(value);
                      // const valueToYear = new Date(value).getFullYear();
                      // if (valueToYear === new Date().getFullYear()) {
                      //   setStartDate(value);
                      // }
                    }}
                    withTime={false}
                    value={startDate}
                    className=""
                    style={{
                      width: "100%",
                    }}
                  />
                  <DatePicker
                    // @ts-ignore
                    onChange={(value) => {
                      setEndDate(value);
                      // const valueToYear = new Date(value).getFullYear();
                      // if (valueToYear === new Date().getFullYear()) {
                      //   setEndDate(value);
                      // }
                    }}
                    withTime={false}
                    value={endDate}
                    className=""
                    style={{ width: "100%" }}
                  />
                </DateRow>
                <ButtonRow>
                  <CancelButton onClick={() => setShowCustom(!showCustom)}>
                    Cancel
                  </CancelButton>
                  <ApplyButton
                    onClick={() => {
                      getCustomTransactions("custom");
                    }}
                  >
                    Apply
                  </ApplyButton>
                </ButtonRow>
              </CustomFilterContent>
            )}
          </CustomFilterBox>
        </SubHeaderChildren>

        <SubHeaderChildren2>
          <CurrencyLabel>NGN</CurrencyLabel>

          {shouldPerform(
            getRoleName(currentUser, currentbusiness?.id),
            "DSummary"
          ) && (
            <PopUpCont>
              <ExportDropdownDiv onClick={() => setOpenDownload(true)}>
                <ExportText>Export</ExportText>
                <img src={CaretDown} alt="" />
              </ExportDropdownDiv>
              {/* <DownloadSummaryBtn onClick={() => setOpenDownload(true)}>
                Download summary
              </DownloadSummaryBtn> */}

              {openDownload && (
                <PopUp>
                  <DownloadSec2>
                    {/* {downloadFileOpt.map((elm) => (
                      <RadioButton
                        key={elm.label}
                        label={elm.label}
                        value={elm.value}
                        checked={elm.label === fstate.value}
                        id={elm.label}
                        group={elm?.label}
                        onChange={() => {
                          setFstate({ value: elm.label });
                        }}
                      />
                    ))} */}
                    <FileDownloadSelect
                      onClick={() => setFstate({ value: "Excel Sheet" })}
                      isActive={fstate.value === "Excel Sheet" ? true : false}
                    >
                      <Icon
                        type={"file-text2"}
                        style={{
                          color:
                            fstate.value === "Excel Sheet" ? "#fff" : "#000",
                          marginRight: 10,
                          borderRadius: 20,
                        }}
                      />
                      Excel Sheet
                    </FileDownloadSelect>

                    <FileDownloadSelect
                      onClick={() => setFstate({ value: "Pdf File" })}
                      isActive={fstate.value === "Pdf File" ? true : false}
                    >
                      <Icon
                        type={"file-empty"}
                        style={{
                          color: fstate.value === "Pdf File" ? "#fff" : "#000",
                          marginRight: 10,
                          borderRadius: 20,
                        }}
                      />
                      Pdf File
                    </FileDownloadSelect>
                  </DownloadSec2>
                  <DownloadSec>
                    <InputHolder>
                      <LabelHolder>Start Date</LabelHolder>
                      <Input
                        name={`downloadStartDate`}
                        type={"date"}
                        // onChange={(e: any) => setFilterDateParams(e, "Download Start Date")}
                      />
                    </InputHolder>

                    <InputHolder>
                      <LabelHolder>End Date</LabelHolder>
                      <Input
                        name={`downloadStartDate`}
                        type={"date"}
                        max={calenderDate(new Date())}
                        // onChange={(e: any) => setFilterDateParams(e, "Download End Date")}
                      />
                    </InputHolder>
                  </DownloadSec>

                  <DownloadOptFooter>
                    <ResetDownloadButton
                      onClick={() => {
                        setOpenDownload(false);
                        setFstate({ value: "Excel Sheet" });
                      }}
                    >
                      Cancel
                    </ResetDownloadButton>
                    {fstate.value === "Excel Sheet" ? (
                      <ExcelExport data={summaryData()}>
                        <ActionoloadButton
                          onClick={() => {
                            setOpenDownload(false);
                          }}
                        >
                          Download
                        </ActionoloadButton>
                      </ExcelExport>
                    ) : (
                      <ActionoloadButtonPdf>
                        <PDFDownloadLink
                          document={<MyDoc data={summaryData()} />}
                          fileName={`download-summary-${new Date().getTime()}.pdf`}
                        >
                          {({ blob, url, loading, error }) =>
                            loading ? "Loading..." : "Download"
                          }
                        </PDFDownloadLink>
                      </ActionoloadButtonPdf>
                    )}
                  </DownloadOptFooter>
                </PopUp>
              )}
            </PopUpCont>
          )}
        </SubHeaderChildren2>
      </SubHeader>
      <CardTray>
        {topCardArray.map((card, index) => (
          <OverviewTopCard
            key={`${index}Card`}
            text={card.text}
            icon={card.icon}
            link={card.link}
            clickFn={handleClickStart}
            target={card.target}
          />
        ))}
      </CardTray>

      <PageMidBody>
        <GraphContainer>
          <ChartBackground>
            <AmountLabel>
              {/* Total Revenue ( <b>{totalAmountLabel} </b> ) */}
              <TRevenueText>Total Revenue</TRevenueText>
              <TRevenueFigure>
                NGN
                {periodTransactionSum
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                .00
              </TRevenueFigure>
            </AmountLabel>
            <BarChart
              setSendData={setSendData}
              selectedLabel={selectedLabel}
              transaction={transactions}
              startDate={startDate}
              endDate={endDate}
            />
          </ChartBackground>
        </GraphContainer>

        <RightStatementDiv>
          {rightCardArray.map((card, index) => (
            <OverviewRightCard
              key={`${index}RCard`}
              topText={card.topText}
              bottomText={card.bottomText}
              expand={card.expand}
            />
          ))}
        </RightStatementDiv>
      </PageMidBody>
    </>

    //</Layout>
  );
};

export default Overview;
