import styled from "styled-components";
import { color, font } from "shared/utils/styles";
import { Button, Form } from "shared/components";
import { Link } from "react-router-dom";
import Background from "../../App/assets/images/Auth/bg.png";
import breakpoints from "shared/utils/breakpoints";

export const ComponentBody = styled.div`
  width: 80%;
  margin: 2em auto;
`;

export const ActionButton = styled(Button)`
  margin: 2em 0;
  color: #fff;
  width: 100%;
  background-color: ${color.fountain};
`;

export const FormElement = styled(Form.Element)``;

export const StyledLink = styled(Link)`
  color: ${color.fountain} !important;
  ${font.size(14)}
`;

export const ComponentFooter = styled.p`
  color: #292929;
  ${font.size(14)}
`;

export const Title = styled.h1`
  color: ${color.fountain};
  ${font.size(24)}
  margin-bottom: 4rem;
`;

export const PageBody = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  // background: red;
  background-image: url(${Background});
  background-repeat: no-repeat;
  background-size: 100% 100%;
`;

export const ModalBody = styled.div`
  background-image: url("../../App/assets/images/Auth/bg.png");
  color: white;
  background-repeat: no-repeat;
  background-size: cover;

  width: 100%;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FormBody = styled.div`
  display: flex;
  // padding: 25px 40px 35px;
  justify-content: space-between;
  min-height: 70vh;
  align-items: center;
`;

export const LeftPane = styled.div`
  min-width: 65%;
  max-width: 70%;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  vertical-align: middle;
  padding-top: 30px;
  background: #fbfbfb;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  @media ${breakpoints.device.max.xs} {
    max-width: 100%;
  }
`;

export const RightPane = styled.div`
  width: 35%;
  margin: 2em 1em;
  order: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #fff;
  @media ${breakpoints.device.max.xs} {
    width: 100%;
    margin: 15px 0;
  }
`;

export const RightTopPane = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 6rem;
  height: 100%;
  @media ${breakpoints.device.max.xs} {
    flex-direction: row;
    justify-content: center;
  }
`;
export const RightBottomPane = styled.p`
  color: #828282;
  text-align: center;
  ${font.size(14)}
`;

export const ImageContainer = styled.div`
  background: #fbfbfb;
  border-radius: 20px;
  padding: 15px 5px;
  width: 75%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ImageContainer2 = styled.div`
  background: #fbfbfb;
  border-radius: 20px;
  padding: 15px 5px;
  width: 75%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: end;
`;

export const imageStyle = {
  width: "70%",
  margin: "auto",
};

export const PasswordCont = styled.div`
  position: relative;
`;
