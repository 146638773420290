import React from "react";
import { Spinner } from "shared/components";
import Modal from "shared/quickComponents/modal";
import { ModalButton, ModalButtonsContainer, ModalContent } from "./Styles";

type PropT = {
  isModalOpen: boolean;
  handleCloseModal: Function;
  handleYesModalButton: Function;
  showIndicator: boolean;
  handleNoModalButton: Function;
  message: string;
};

export default function index({
  isModalOpen,
  handleCloseModal,
  handleNoModalButton,
  handleYesModalButton,
  showIndicator,
  message,
}: PropT) {
  return (
    <Modal isOpen={isModalOpen} closeButton={false} onClose={handleCloseModal}>
      <ModalContent>
        <p>{message}</p>

        <ModalButtonsContainer>
          <ModalButton onClick={() => handleYesModalButton()}>
            {showIndicator ? <Spinner color="#fff" /> : "Yes"}
          </ModalButton>
          <ModalButton onClick={() => handleNoModalButton()} option={true}>
            No
          </ModalButton>
        </ModalButtonsContainer>
      </ModalContent>
    </Modal>
  );
}
