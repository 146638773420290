import Layout from "Layout";
import { TransactionsPage } from "./Styled";
import { RootStateOrAny, useSelector } from "react-redux";

import DashboardSubHeader from "shared/components/DashboardSubHeader";
import Table from "shared/components/Table";
import { useEffect, useState } from "react";
import api from "shared/utils/api";
import toast from "shared/utils/toast";
import Preloader from "shared/components/preloader";
import consoleLog from "shared/utils/consoleLog";
import { getStatusColor } from "shared/utils/statusColor";
import summaryData from "shared/utils/dataSummary";
import {
  getStoreLocalStorage,
  storeToLocalStorage,
} from "shared/utils/authToken";
import { GradientLoader } from "shared/components";
import { formatDateComma } from "shared/utils/dateTime";

const Transactions = () => {
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(false);

  const business = useSelector((store: RootStateOrAny) => store.business);
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [openDownload, setOpenDownload] = useState<boolean>(false);
  const [fetchedData, setFetchedData] = useState<any>([]);
  const [filterededData, setFilteredData] = useState<any>([]);
  const [totalTransaction, setTotalTransactions] = useState<number>(0.0);
  const [params, setParams] = useState<any>({});
  const [downloadQuery, setDownloadQuery] = useState<any>({});
  const [searchData, setSearchData] = useState<any>({});
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(50);

  const headData = [
    { id: "transactionId", label: "Reference" },
    { id: "terminalId", label: "TerminalId" },
    { id: "mode", label: "Payment Mode" },
    { id: "type", label: "Type" },
    { id: "amount", label: "Amount" },
    { id: "createdOn", label: "Date and Time" },
    { id: "status", label: "Status" },
  ];

  const calculateTransaction = (transactions: any) => {
    let amount = 0;
    transactions instanceof Array &&
      transactions.forEach(
        (transaction: any) => (amount = amount + transaction.amount)
      );
    setTotalTransactions(amount);
  };
  const fixData = () => {
    calculateTransaction(fetchedData);
    // .toString().charAt(0).toUpperCase() + transaction.type.toString().slice(1)
    const bodyData: any =
      fetchedData instanceof Array &&
      fetchedData.map((transaction: any) => ({
        transactionId: transaction?.reference,
        terminalId: transaction.terminalId,
        mode: transaction.channel,
        amount: `${
          transaction.currency
        }${transaction?.amount
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}.00`,
        createdOn: formatDateComma(transaction?.updatedAt),
        type:
          transaction.type &&
          (transaction.type.toLowerCase().includes("debit") ? (
            <div style={{ color: getStatusColor("terminated") }}>
              {transaction.type}
            </div>
          ) : (
            <div style={{ color: getStatusColor("successful") }}>
              {transaction.type}
            </div>
          )),
        status:
          transaction.status &&
          (transaction.status.toLowerCase().includes("init") ? (
            <div style={{ color: getStatusColor("pending") }}>
              Pending
            </div>
          ) : (
            <div style={{ color: getStatusColor(transaction?.status) }}>
              {transaction?.status?.toString().charAt(0).toUpperCase() + transaction?.status?.toString().slice(1)}
            </div>
          )),
      }));
    setFilteredData(bodyData);
  };

  const getTransactions = async (otherObj?: any) => {
    setLoading(true);
    try {
      let reqObj = {
        business_id: business.id,
        ...otherObj,
      };
      let currentPage: any = getStoreLocalStorage("currentPage");
      if (currentPage && !reqObj?.page) {
        reqObj.page = currentPage;
      } else {
        storeToLocalStorage("currentPage", reqObj?.page);
      }
      setDownloadQuery(reqObj);
      // console.log("Query: ", reqObj);
      const res = await api.post(`/agency-banking/transactions`, reqObj);

      consoleLog("res ", res?.payload?.data);
      if (res.payload) {
        setFetchedData(res?.payload?.data);
        setData(res?.payload);
      }
    } catch (error) {
      const err: any = error;
      // console.log(error);
      toast.error(`${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  const transactionFilter = [
    { id: "createdAt", type: "date", title: "Date", list: ["Start Date"] },
    {
      id: "status",
      type: "flag",
      title: "Status",
      list: [
        { title: "Init", filterParam: "INIT" },
        { title: "Failed", filterParam: "FAILED" },
        { title: "Successful", filterParam: "SUCCESSFUL" },
        { title: "Abandon", filterParam: "ABANDON" },
        { title: "Cancelled", filterParam: "CANCELLED" },
      ],
    },
  ];

  const SearchForData = (e: any) => {
    let searchedArr: any = [];
    data.forEach((transaction: any) => {
      if (
        transaction.customer.fullname.toLowerCase().indexOf(e.toLowerCase()) !==
        -1
      ) {
        searchedArr.push(transaction);
      }
    });
    setFetchedData(searchedArr);
  };

  const searchTransactions = async (obj: any) => {
    obj = obj instanceof Object ? obj : {};
    storeToLocalStorage("currentPage", 1);
    await getTransactions({
      ...obj,
    });
  };

  const resetFilter = () => {
    setFetchedData(data?.data);
  };

  useEffect(() => {
    if (fetchedData) {
      fixData();
    }
  }, [fetchedData]);

  useEffect(() => {
    const date = new Date();
    let currentDay = String(date.getDate()).padStart(2, "0");
    let currentMonth = String(date.getMonth() + 1).padStart(2, "0");
    let currentYear = date.getFullYear();

    date.setUTCDate(date.getUTCDate() - 7);

    const last7Days = `${date.getUTCFullYear()}/${(date.getUTCMonth() + 1)
      .toString()
      .padStart(2, "0")}/${date
      .getUTCDate()
      .toString()
      .padStart(2, "0")}`;

    let endDate = `${currentYear}/${currentMonth}/${currentDay}`;
    setStartDate(last7Days);
    setEndDate(endDate);
    searchTransactions({
      start: last7Days,
      end: endDate,
      search: "",
      successful: true,
      failed: true,
      terminated: true,
      card: true,
      qrpay: true,
      transfer: true,
    });
  }, []);

  return (
    <TransactionsPage>
      <DashboardSubHeader
        title={`${fetchedData.length || 0} Transactions`}
        btnTitle="Download Summary"
        callBackFn={() => setOpenDownload(!openDownload)}
        openDownload={openDownload}
        setOpenDownload={setOpenDownload}
        dateFilter={false}
        setSearchData={SearchForData}
        withSearch={false}
        withFilter={fetchedData.length > 0 ? true : true}
        filterType="agencyTransaction"
        arrToFilter={transactionFilter}
        resetFilter={resetFilter}
        applyFilter={searchTransactions}
        getParams={setParams}
        clickedParam={"Transaction Description"}
        widthActionBtn={fetchedData.length > 0 ? true : false}
        downloadParameter={downloadQuery}
        downloadUrl={"/agency-banking/transactions/query"}
        setFilterFormState
      />
      {loading && <GradientLoader />}
      {!loading && (
        <Table
          headData={headData}
          bodyData={filterededData ?? []}
          totalPages={data?.totalPages}
          hoverLink
          serialNumber
          clickFunction={() => {}}
          emptyStateMessage="No transaction recorded yet for today."
        />
      )}
    </TransactionsPage>
  );
};

export default Transactions;
