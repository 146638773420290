import { Button } from "shared/components";
import breakpoints from "shared/utils/breakpoints";
import { color } from "shared/utils/styles";
import styled from "styled-components";

export const PageLayout = styled.div`
  width: 100%;
  //padding: 0 0.6rem 1.4rem 1.4rem;
  margin-top: 2.4rem;
  /* background-color: white; */
  @media ${breakpoints.device.max.xs} {
    margin-top: 10px;
  }
`;

export const PageTitleCont = styled.div`
    width: 100%;
    border-bottom: 1px solid grey;
    height: 45px
`;

export const PageTitle = styled.p`
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: -0.02em;
    color: #292929;
`;

export const FormCont = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 850px;
    @media ${breakpoints.device.max.md} {
        flex-direction: column;
        width: 100%;
    }
`;

export const AuxFormCont = styled.div`
  width: 40%;
  margin-bottom: 20px;
    @media ${breakpoints.device.max.md} {
        width: 100%;
    }
`;

export const ActionButton = styled(Button)`
  margin: 1em 0;
  color: #fff;
  width: 138px;
  height: 54px;
  float: right;
  background-color: ${color.fountain};
`;

export const ParentFormCont = styled.div`
    display: flex;
    flex-direction: column;
    // border: 1px solid red;
`;