import { useEffect, useState } from "react";
import { Checkbox, Input } from "shared/components";
import {
  FilterInputCont,
  FilterParamsCont,
  FilterSec,
  FilterText,
  InputHolder,
  PopUpSubHead,
  TextDescription,
} from "shared/components/DashboardSubHeader/Styles";
import { DateRangePicker } from "rsuite";
import Daterange from "./Daterange";

const date = new Date();
let currentDay = String(date.getDate()).padStart(2, "0");
let currentMonth = String(date.getMonth() + 1).padStart(2, "0");
let currentYear = date.getFullYear();
// we will display the date as DD-MM-YYYY
let currentDate = `${currentYear}-${currentMonth}-${currentDay}`;

date.setUTCDate(date.getUTCDate() - 30);
const last7Days = `${date.getUTCFullYear()}-${(date.getUTCMonth() + 1)
  .toString()
  .padStart(2, "0")}-${date
  .getUTCDate()
  .toString()
  .padStart(2, "0")}`;

type PropT = {
  onProcessFilter: Function;
  initialState: any;
};
const TransactionFilter = ({ onProcessFilter, initialState }: PropT) => {
  const [startDate, setStartDate] = useState<string | undefined>(
    initialState?.start ?? last7Days
  );
  const [endDate, setEndDate] = useState<string | undefined>(
    initialState?.end ?? currentDate
  );
  const [search, setSearch] = useState<string | undefined>(
    initialState?.search ?? ""
  );

  const [successful, setSuccessful] = useState<boolean | undefined>(
    initialState?.successful ?? true
  );
  const [failed, setFailed] = useState<boolean | undefined>(
    initialState?.failed ?? true
  );
  const [terminated, setTerminated] = useState<boolean | undefined>(
    initialState?.terminated ?? true
  );

  const [card, setCard] = useState<boolean | undefined>(
    initialState?.card ?? true
  );
  const [qrpay, setQrpay] = useState<boolean | undefined>(
    initialState?.qr ?? true
  );
  const [eBanking, setEBanking] = useState<boolean | undefined>(
    initialState?.ebanking ?? true
  );
  const [directDebit, setDirectDebit] = useState<boolean | undefined>(
    initialState?.direct_debit ?? true
  );
  const [transfer, setTransfer] = useState<boolean | undefined>(
    initialState?.transfer ?? true
  );
  const [mobileMoney, setMobileMoney] = useState<boolean | undefined>(
    initialState?.mobile_money ?? true
  );

  const onChangeTextHandler = async (obj = {}) => {
    //onProcessFilter({date:{start, end}, search, status:{successful, failed, terminated}, channel:{card, qrpay, internetBanking, directDebit}})
    const payload: any = {
      start: startDate,
      end: endDate,
      search,
      successful,
      failed,
      terminated,
      card,
      qr: qrpay,
      ebanking: eBanking,
      direct_debit: directDebit,
      transfer,
      mobile_money: mobileMoney,
      ...obj,
    };
    // console.log("Payload: ", payload);
    await onProcessFilter(payload);
  };

  const handleSelection = async (fromDate: string, toDate: string) => {
    //alert(`Selected dates 1: ${fromDate}, ${toDate}`)
    // console.log(`Filter dates: ${fromDate}, ${toDate}`)
    await setEndDate(toDate);
    await setStartDate(fromDate);
    onChangeTextHandler({ start: fromDate, end: toDate });
  };

  // useEffect(() => {
  //   onChangeTextHandler();
  // }, [
  //   startDate,
  //   endDate,
  //   search,
  //   successful,
  //   failed,
  //   terminated,
  //   card,
  //   qrpay,
  //   eBanking,
  //   directDebit,
  //   transfer,
  //   mobileMoney,
  // ]);

  return (
    <FilterSec>
      <PopUpSubHead>Search transaction</PopUpSubHead>
      <FilterText>
        <TextDescription>Search</TextDescription>
        <Input
          name={"search"}
          type='text'
          placeholder='Transaction reference no'
          value={search}
          onChange={async (e: any) => {
            await setSearch(e);
            onChangeTextHandler({ search: e });
          }}
        />
      </FilterText>

      <PopUpSubHead>Date range</PopUpSubHead>
      <FilterInputCont>
        <Daterange
          handleSelection={handleSelection}
          initialState={{ startDate, endDate }}
        />
      </FilterInputCont>

      <PopUpSubHead>Payment channels</PopUpSubHead>
      <FilterParamsCont>
        <Checkbox
          onChange={async (e: any) => {
            await setCard(!card);
            await onChangeTextHandler({ card: !card });
          }}
          label={"Card"}
          value={card}
          name={"card"}
          checked={!!card}
        ></Checkbox>
        <Checkbox
          onChange={async (e: any) => {
            await setQrpay(!qrpay);
            await onChangeTextHandler({ qr: !qrpay });
          }}
          label={"QR Pay"}
          value={qrpay}
          checked={!!qrpay}
          name={"qrpay"}
        ></Checkbox>
        <Checkbox
          onChange={async (e: any) => {
            await setDirectDebit(!directDebit);
            await onChangeTextHandler({ direct_debit: !directDebit });
          }}
          label={"Internet Banking"}
          value={directDebit}
          checked={!!directDebit}
          name={"directDebit"}
        ></Checkbox>
        <Checkbox
          onChange={async (e: any) => {
            await setEBanking(!eBanking);
            await onChangeTextHandler({ ebanking: !eBanking });
          }}
          label={"Direct debit"}
          value={eBanking}
          name={"eBanking"}
          checked={!!eBanking}
        ></Checkbox>
        <Checkbox
          onChange={async (e: any) => {
            await setTransfer(!transfer);
            await onChangeTextHandler({ transfer: !transfer });
          }}
          label={"Transfer"}
          value={transfer}
          name={"transfer"}
          checked={!!transfer}
        ></Checkbox>
        <Checkbox
          onChange={async (e: any) => {
            await setMobileMoney(!mobileMoney);
            await onChangeTextHandler({ mobile_money: !mobileMoney });
          }}
          label={"Mobile Money"}
          value={mobileMoney}
          name={"mobileMoney"}
          checked={!!mobileMoney}
        ></Checkbox>
      </FilterParamsCont>

      <PopUpSubHead>Transaction status</PopUpSubHead>
      <FilterParamsCont>
        <Checkbox
          onChange={async (e: any) => {
            // alert(successful);
            await setSuccessful(!successful);
            await onChangeTextHandler({ successful: !successful });
          }}
          label={"Successful"}
          value={successful}
          name={"successful"}
          checked={!!successful}
        ></Checkbox>
        <Checkbox
          onChange={async (e: any) => {
            await setFailed(!failed);
            await onChangeTextHandler({ failed: !failed });
          }}
          label={"Failed"}
          value={failed}
          name={"failed"}
          checked={!!failed}
        ></Checkbox>
        <Checkbox
          onChange={async (e: any) => {
            await setTerminated(!terminated);
            await onChangeTextHandler({ terminated: !terminated });
          }}
          label={"In progress"}
          value={terminated}
          name={"terminated"}
          checked={!!terminated}
        ></Checkbox>
      </FilterParamsCont>
    </FilterSec>
  );
};

export default TransactionFilter;
