import { useEffect, useLayoutEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import Layout from "Layout";
import {
  KycCheckerCont,
  CloseContainer,
  CloseBtn,
  CloseText,
  AlertBox,
  AlertTitle,
  AlertBtn,
  AlertCont,
  AlertText,
} from 'Layout/Styles'
import EmptyFile from "App/assets/images/placeholder/empty-file.svg";
import { Icon } from "shared/components";
import { useHistory } from "react-router-dom";

import { getStoredAuthToken } from "shared/utils/authToken";
import {
  setBusinessLists,
  setCurrentBusiness,
} from "store/actions/businessAction";
import { setCurrentUser } from "store/actions/userAction";
import { ActionTypes } from "store/constants/actionType";
import toast from "shared/utils/toast";
import api from "shared/utils/api";
import consoleLog from "shared/utils/consoleLog";

export default function AgencyAd() {
  const dispatch = useDispatch();
  const history = useHistory();
  const businesses = useSelector((store: RootStateOrAny) => store.businesses);
  const [kycStatus, setKycStatus] = useState<boolean>(false);
  const [gatewaykycStep, setGatewayKycStep] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<string>("");
  const [agencyKycStep, setAgencyKycStep] = useState<number>(0);

  let firstActiveBusiness =
    businesses instanceof Array &&
    businesses.find((el) => el.status === "ACTIVE");
  if (!firstActiveBusiness) {
    firstActiveBusiness = [];
  }
  const currentbusiness = useSelector(
    (store: RootStateOrAny) => store.business
  );

  const [navOpened, setNavOpened] = useState(false);
  const kycStat = true;

  const toggleNav = () => {
    setNavOpened(!navOpened);
  };

  const gotoKYC = () => {
    const page = window.location;
    if (!page.toString().includes("kyc")) {
      history.push("/kyc");
    }
  };

  // const getAccountSettings = async () => {
  //   // await dispatch(setCurrentUser());
  //   const getCurrenUser = await setCurrentUser();
  //   // consoleLog("GET MY USER:", getCurrenUser);
  //   dispatch({
  //     type: ActionTypes.SET_USERS,
  //     payload: getCurrenUser ?? {},
  //   });
  //   await dispatch(setBusinessLists());
  //   // await dispatch(setCurrentBusiness(businesses[0] ?? []));
  //   await dispatch(setCurrentBusiness(firstActiveBusiness ?? []));
  // };

  const checkForKyc = async () => {
    try {
      const res: any = await api.get(`/business/${firstActiveBusiness.id}`);
      let payload;
      if (res?.payload) {
        payload = res.payload;
      }
      // consoleLog(payload);
      if (
        payload?.BusinessKyc instanceof Array &&
        ((payload?.BusinessKyc[0] &&
          payload?.BusinessKyc[0].status === "PENDING") ||
          payload?.BusinessKyc.length === 0)
      ) {
        setKycStatus(true);
        if (
          payload?.BusinessKyc instanceof Array &&
          payload.BusinessKyc[0] &&
          payload.BusinessKyc[0].residentialMeta === null
        ) {
          setAgencyKycStep(1);
        } else if (
          payload?.BusinessKyc instanceof Array &&
          payload.BusinessKyc[0] &&
          payload.BusinessKyc[0].businessMeta === null
        ) {
          setAgencyKycStep(2);
        } else if (
          payload?.BusinessKyc instanceof Array &&
          payload.BusinessKyc[0] &&
          payload.BusinessKyc[0].uploadMeta === null
        ) {
          setAgencyKycStep(3);
        }
      } else {
        setKycStatus(false);
      }
      setGatewayKycStep(
        payload?.BusinessKyc instanceof Array &&
          payload.BusinessKyc[0] &&
          payload?.BusinessKyc[0].step
      );
    } catch (error) {
      //@ts-ignore
      toast.error(typeof error === "string" && error);
      consoleLog(error);
    }
  };

  const jumpToKyc = () => {
    if (!currentPage.includes("agency")) {
      if (gatewaykycStep === 1) {
        history.push("/kyc/add-account-details");
      } else {
        history.push("/kyc/gateway-upload-documents");
      }
    } else {
      history.push("/kyc/residential-information");
      // if(agencyKycStep === 1) {
      //   history.push('/kyc/residential-information');
      // } else if (agencyKycStep === 2) {
      //   history.push('/kyc/business-information');
      // } else if (agencyKycStep === 3) {
      //   history.push('/kyc/agency-banking-upload-documents');
      // }
    }
  };

  const goBack = () => {
    window.history.go(-1);
  };

  useLayoutEffect(() => {
    const token = getStoredAuthToken();
    if (!token) {
      history.push("/authenticate");
    }
    ///getAccountSettings();
  }, []);

  useEffect(() => {
    setCurrentPage(window.location.pathname);
    checkForKyc();
    const token = getStoredAuthToken();
    if (
      token &&
      (firstActiveBusiness?.status !== "ACTIVE" ||
        Object.keys(businesses).length === 0)
    ) {
      gotoKYC();
    }
  }, [businesses]);
  return (

      <KycCheckerCont>
          <CloseContainer>
            <CloseBtn onClick={goBack}>
              <Icon
                type={"close"}
                style={{ color: "#000", paddingTop: 5 }}
              />
            </CloseBtn>
            <CloseText>Close</CloseText>
          </CloseContainer>

          <AlertBox>
            <img src={EmptyFile} alt="empty file" />
            <AlertTitle>Whoops!!</AlertTitle>
            <AlertText>
              To continue using agency banking , click the button below to
              activate agency banking.
            </AlertText>
            <AlertBtn onClick={jumpToKyc}>
              {currentPage.includes("agency")
                ? "Activate agency banking"
                : "Activate gateway"}
            </AlertBtn>
          </AlertBox>
        </KycCheckerCont>
  );
}
