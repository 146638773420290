import api from "shared/utils/api";
import { storeCurrentBusiness } from "shared/utils/businessesData";
import { ActionTypes } from "../constants/actionType";
import consoleLog from "shared/utils/consoleLog";

export interface CurrentBusinessKyc {
  id?: string;
  status?: boolean;
  platform?: string;
}

export const setBusinessLists = (
  businesses: any = [],
  check = false,
  cache = true,
  setMostUpatedBusiness = false
) => {
  return async (dispatch: any) => {
    try {
      let res: any = {};
      if (businesses && businesses.length && businesses.length > 0) {
        res.payload = businesses;
      } else {
        res = await api.get("/client/business", undefined, cache);
      }

      consoleLog(res, "business");

      if (!res.payload || res.payload.length == 0) {
        storeCurrentBusiness({});
        //window.location.href="kyc"
      }
      // consoleLog("BUSINESES:", res.payload);

      await dispatch({
        type: ActionTypes?.SET_BUSINESS_LISTS,
        payload: res?.payload ?? {},
      });
      if (res.payload.length && !check) {
        // consoleLog(res.payload, "from businesslist active business");
        const currentActiveBusiness = res.payload
          ? await res.payload.filter((biz: any) => biz.status === "ACTIVE")
          : {};
        if (currentActiveBusiness.length) {
          await dispatch(setCurrentBusiness(currentActiveBusiness[0]));
        } else {
          await dispatch(setCurrentBusiness(res.payload[0]));
        }
      }

      if (setMostUpatedBusiness) {
        businesses = res?.payload;
        const currentActiveBusiness = businesses.filter(
          (biz: any) => biz.status === "ACTIVE"
        );
        if (currentActiveBusiness.length) {
          currentActiveBusiness.sort(
            (a: any, b: any) =>
              new Date(a?.updatedAt).getTime() -
              new Date(b?.updatedAt).getTime()
          );
          await dispatch(
            setCurrentBusiness(
              currentActiveBusiness[currentActiveBusiness.length - 1]
            )
          );
        }
      }
    } catch (error) {
      consoleLog(error);
    }
  };
};

export const setCurrentBusiness = (business?: any) => {
  return async (dispatch: any) => {
    try {
      if (business) {
        storeCurrentBusiness(business);
        await dispatch({
          type: ActionTypes.SET_CURRENT_BUSINESS,
          payload: business ?? {},
        });

        const kycs = business?.BusinessKyc;
        consoleLog("Current business: ", kycs);
        if (kycs && kycs.length > 0) {
          const pendingKyc = kycs.find((kyc: any) => kyc.status === "PENDING");
          if (pendingKyc) {
            await dispatch(
              setCurrentBusinessKyc({
                id: pendingKyc.id,
                status: false,
                platform: pendingKyc.type,
              })
            );
            return;
          } else {
            await dispatch(setCurrentBusinessKyc({}));
            return;
          }
          await dispatch(
            setCurrentBusinessKyc({
              id: "notAnID",
              status: true,
              platform: "AllValid",
            })
          );
        }
      }
    } catch (error) {
      consoleLog(error);
    }
  };
};

export const setCurrentBusinessKyc = (kyc?: CurrentBusinessKyc) => {
  return {
    type: ActionTypes.SET_CURRENT_BUSINESS_KYC,
    payload: kyc ?? {},
  };
};
