import styled from "styled-components";

export const EmptyContainer = styled.div`
  margin-top: 20px;
`;

export const LittleTitle = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 20px;
  color: #ccc;
  text-transform: capitalize;
`;

export const PageTitleComp = styled.div`
  justify-content: left;
  flex-direction: row;
  display: flex;
  gap: 20px;
  width: 100%;
  font-size: 24px;
  text-transform: capitalize;
`;

export const PageTitleAmt = styled.div`
  color: #000;
  text-transform: capitalize;
`;

export const PageCustomer = styled.div`
  color: #000;
  text-transform: capitalize;
`;
