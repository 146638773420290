import { uniqueId } from "lodash";

import DatePicker from "shared/components/DatePicker";
import Input from "shared/components/Input";
import Select from "shared/components/Select";
import Textarea from "shared/components/Textarea";
import TextEditor from "shared/components/TextEditor";

import {
  ChildLabel,
  ChildLabelSelect,
  FieldError,
  FieldLabel,
  FieldTip,
  InputGroup,
  StyledField,
} from "./Styles";

type PropT = {
  className: string;
  label: string;
  childLabel?: String;
  tip: string;
  error: string;
  name: string;
  childLabelInput?: string;
  [x: string]: any;
};

const defaultProps = {
  className: undefined,
  label: undefined,
  childLabel: undefined,
  tip: undefined,
  error: undefined,
  name: undefined,
};

const generateField = (FormComponent: any) => {
  const FieldComponent = ({
    className,
    label,
    tip,
    error,
    name,
    ...otherProps
  }: PropT) => {
    const fieldId = uniqueId("form-field-");

    return (
      <StyledField
        className={className}
        // @ts-ignore
        hasLabel={!!label}
        data-testid={name ? `form-field:${name}` : "form-field"}
      >
        {label && <FieldLabel htmlFor={fieldId}>{label}</FieldLabel>}
        <FormComponent
          id={fieldId}
          invalid={!!error}
          name={name}
          {...otherProps}
        />
        {tip && <FieldTip>{tip}</FieldTip>}
        {error && <FieldError>{error}</FieldError>}
      </StyledField>
    );
  };

  // FieldComponent.propTypes = propTypes;
  FieldComponent.defaultProps = defaultProps;

  return FieldComponent;
};

const generateGroupField = (FormComponent: any) => {
  const FieldComponent = ({
    className,
    label,
    childLabel,
    tip,
    error,
    name,
    childLabelInput,
    ...otherProps
  }: PropT) => {
    const fieldId = uniqueId("form-field-");

    return (
      <StyledField
        className={className}
        // @ts-ignore
        hasLabel={!!label}
        data-testid={name ? `form-field:${name}` : "form-field"}
      >
        {label && <FieldLabel htmlFor={fieldId}>{label}</FieldLabel>}
        <InputGroup>
          {childLabelInput === "select" ? (
            <ChildLabelSelect>{childLabel}</ChildLabelSelect>
          ) : (
            childLabel && <ChildLabel>{childLabel}</ChildLabel>
          )}
          <FormComponent
            id={fieldId}
            invalid={!!error}
            name={name}
            {...otherProps}
          />
        </InputGroup>
        {tip && <FieldTip>{tip}</FieldTip>}
        {error && <FieldError>{error}</FieldError>}
      </StyledField>
    );
  };

  // FieldComponent.propTypes = propTypes;
  FieldComponent.defaultProps = defaultProps;

  return FieldComponent;
};

export default {
  Input: generateField(Input),
  InputGroup: generateGroupField(Input),

  Select: generateField(Select),
  Textarea: generateField(Textarea),
  TextEditor: generateField(TextEditor),
  DatePicker: generateField(DatePicker),
};
