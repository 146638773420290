import { LogActivityData } from "AppTypes/index";
import history from "browserHistory";
import api from "shared/utils/api";
import { getStoredLogs, storeLogs } from "shared/utils/authToken";
import consoleLog from "shared/utils/consoleLog";

interface LogReqPayload {
  resource: string;
  action: string[];
  pagination?: object;
  range?: object;
  sorting?: object;
}
class Logger_class {
  async logActivities(event: string, data: LogActivityData) {
    const formatted = await this.formatLogData(data);
    // consoleLog("outgoing data", formatted);
    if (event == "remote") {
      // consoleLog(formatted)
      const res = await this.postLogInDb(formatted);
      // consoleLog("logger response:", res);
    } else {
      await storeLogs(formatted);
    }
  }
  async formatLogData(data: LogActivityData): Promise<LogActivityData> {
    return data as LogActivityData;
  }

  async postLogInDb(data: LogActivityData) {
    try {
      const user = await api.post("/console/system-logs/create", data);
      // user?.data && history.push("/dashboard/over-view");
      // // consoleLog("sending log details ==>", data);
      return true;
    } catch (error) {
      return false;
    }
  }
}

const logger = new Logger_class();
export default logger;
