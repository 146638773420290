import Layout from "Layout";

import { RootStateOrAny, useSelector } from "react-redux";

import Table from "shared/components/Table";
import { useEffect, useState } from "react";
import api from "shared/utils/api";
import toast from "shared/utils/toast";
import Preloader from "shared/components/preloader";
import { currencyFormat } from "shared/utils/utility";
import DashboardSubHeader from "shared/components/DashboardSubHeader";
import { useHistory } from "react-router-dom";
import consoleLog from "shared/utils/consoleLog";
import {
  EditBtn,
  GroupedBtn,
  PlanStatus,
  TableActions,
} from "Agency/Inventory/Styles";
import { GradientLoader } from "shared/components";

const Pricelist = () => {
  const [loading, setLoading] = useState(false);
  const business = useSelector((store: RootStateOrAny) => store.business);
  const [fetchedData, setFetchedData] = useState<any>([]);
  const [showHide, setshowHide] = useState<any>({});
  const history = useHistory();

  const headData = [
    { id: "pricingName", label: "Name" },
    { id: "pricingValue", label: "Value" },
    { id: "pricingCreated", label: "Created" },
    { id: "pricingStatus", label: "Status" },
    { id: "funcProps", label: "" },
  ];
  const normalizeValues = (value: any) => {
    let values: string = "";
    for (var ln in value) {
      const keys = Object.keys(value[ln]);
      // // console.log("Keys: ", keys);
      values += keys.map((key) => {
        // // console.log("Value: ", value[ln][key]);
        const returnedValue = `${key.toString().toUpperCase()}: ${value[ln][key]} `;
        return returnedValue;
      });
      values += "\t\n";
    }

    return values;
  };
  const getPricing: any = async () => {
    setLoading(true);
    try {
      const res = await api.get(`/agency-banking/pricing/${business.id}`);

      // // console.log("Pricing: ", res)
      consoleLog("res ", res.payload);
      setFetchedData(res?.payload);
    } catch (err) {
      const error: any = err;
      //   // console.log(error);
      toast.error(`${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (plan: any) => {
    // // console.log(plan);
    history.push("/agency/pricing/update/" + plan.uuid);
    // setpageAction(action);
    // setisModalOpen(true);
    // setpricingName(plan.name);
    // plan.status === "PRIVATE" ? setisPrivate(true) : setisPrivate(false);
    // plan.status === "PUBLIC" ? setisPublic(true) : setisPublic(false);
    // setplanType(plan.type);
    // setpercentageValue(plan.value.percentage);
    // setfixedValue(plan.value.flat);
    // setSelectedPlanID(plan.id);
  };

  const viewComplete = (str: string, show: boolean) => {
    return !show && str.length > 20
      ? str.substring(0, 40) + "..."
      : // : str.substring(0, 7) + "...";
        str;
  };

  const bodyData: any =
    fetchedData instanceof Array &&
    fetchedData.map((plan: any) => {
      const planValue = JSON.stringify(plan.value);
      // consoleLog(plan, "plan value");
      return {
        pricingName: plan.name,
        pricingCreated: plan.createdAt.split("T")[0],
        pricingUpdated: plan.updateAt.split("T")[0],
        // createdOn: new Date(transaction?.updatedAt).toLocaleString("en-GB", {
        //     year: "numeric",
        //     month: "numeric",
        //     day: "numeric",
        //     hour: "2-digit",
        //     minute: "2-digit",
        //     hour12: true,
        // }),
        pricingStatus: plan.status,
        pricingValue: (
          <>
            {viewComplete(normalizeValues(plan.value), showHide[plan.name])}
            <EditBtn
              onClick={() => {
                setshowHide({ ...showHide, [plan.name]: !showHide[plan.name] });
                // handleEdit(plan);
              }}
            >
              {showHide[plan.name] ? "Hide" : "View"}
            </EditBtn>
          </>
        ),
        priceRawValue: plan.value,
        // pricingValue: planValue.substring(0, 40) + "...",
        funcProps: (
          <TableActions>
            <PlanStatus>{plan.status}</PlanStatus>
            <GroupedBtn>
              {/* pass plan into handleEdit function */}
              <EditBtn
                onClick={() => {
                  handleEdit(plan);
                }}
              >
                Edit
              </EditBtn>
            </GroupedBtn>
          </TableActions>
        ),
      };
    });

  useEffect(() => {
    getPricing();
  }, []);

  if (loading) {
    return <GradientLoader />;
  }

  return (
    <>
      <DashboardSubHeader
        count={bodyData.length}
        title="Pricing"
        widthActionBtn={true}
        btnTitle="Set a new price"
        callBackFn={() => history.push("/agency/pricing/add-new")} //setModal(true)
        withSearch={bodyData.length > 0}
        clickedParam={"Customer Name"}
      />
      <Table
        headData={headData}
        bodyData={bodyData ?? []}
        hoverLink
        serialNumber
        emptyStateMessage="Yet to set your prices."
      />
    </>
  );
};

export default Pricelist;
