import CocaCola from "App/assets/images/Auth/CocaCola.svg";
import Forbes from "App/assets/images/Auth/Forbes.svg";
import Sony from "App/assets/images/Auth/Sony.svg";

import {
  LeftPane,
  RightPane,
  ModalBody,
  ImageContainer,
  ImageContainer2,
  FormBody,
  RightTopPane,
  RightBottomPane,
  imageStyle,
} from "./Styles";

const MainLayout = ({ children }: any) => {
  return (
    <>
      <FormBody>
        <LeftPane>{children}</LeftPane>
        <RightPane>
          <RightTopPane>
            <ImageContainer>
              <img src={CocaCola} alt="CocaCola" style={imageStyle} />
            </ImageContainer>

            <ImageContainer2>
              <img src={Forbes} alt="Forbes" style={imageStyle} />
            </ImageContainer2>

            <ImageContainer>
              <img src={Sony} alt="Sony" style={imageStyle} />
            </ImageContainer>
          </RightTopPane>
          <RightBottomPane>
            Join over 152,000 customers that make payments on our platform.
          </RightBottomPane>
        </RightPane>
      </FormBody>
    </>
  );
};

export default MainLayout;
