
import React, { useEffect, useState } from "react";
import {
  DetailsRow,
  DetailsRowContent,
  EditButton,
  ModeSpan,
  ServicePageActionButtons,
  ServicePageContainer,
  ServicePageContent,
  ServicePageSubHeader,
} from "./Styles";
import { RootStateOrAny, useSelector } from "react-redux";
import { GradientLoader, Modal, Switch } from "shared/components";
import useServiceMode from "shared/hooks/useServiceMode";
import Table from "shared/components/Table";
import { getStatusDivColor } from "shared/utils/statusColor";
import { formatDate, formateDate, formateDateString, getDateFromString } from "shared/utils/utility";
import moment from "moment";
import AddService from "Services/AddService";
import api from "shared/utils/api";
import toast from "shared/utils/toast";
import EmptyState from "shared/components/EmptyState";
import { EmptyContainer } from "Services/Transactions/TransactionList/Styles";

export default function AccountDetails() {
  const service = useSelector((store: RootStateOrAny) => store.service);
  const { serviceMode, loadingMode, toogleMode } = useServiceMode(service);
  const [live, setlive] = useState(service.mode === "TEST" ? false : true);
  const [ accountLists, setAccountLists ] = useState<any>([]);
  const [IsLoadingStatus, setIsLoadingStatus] = useState(false);
  const { mode, name, serviceId, status, createdAt } = service;
  const [visible, setvisible] = useState(false);
  const [ isLloadingAccount, setIsLloadingAccount ] = useState<boolean>(true);

  const columnHeaders = [
    { id: "accountId", label: "Account ID" },
    { id: "dateCreated", label: "Date Created" },
    { id: "accountName", label: "Account Name" },
    { id: "bankName", label: "Bank Name" },
    { id: "accountNo", label: "Account Number" },
    { id: "currency", label: "Currency" },
    { id: "type", label: "Type" },
    { id: "default", label: "Default" },
    { id: "status", label: "Status" },
  ];

  const getAccountLists=async()=>{
    setIsLloadingAccount(true)
    try {
      const accounts = await api.get(`/services/${service?.id}/bank-account`);
      // console.log("Bank accounts: ", accounts)
      if (
        accounts?.status == true &&
        accounts?.payload instanceof Array
      ) {
        // console.log("Payload: ", accounts.payload)
        const listOfAccount=accounts.payload.map((account: any)=>{
          return{
            accountId: account?.subAccountId,
            accountName: account?.bankAccountName,
            accountNo: account?.accountNumber,
            bankName: account?.bank?.name,
            bankLogo: account?.bank?.logo,
            currency: account?.currency, 
            type: account?.type, 
            status: SwitchComp(account?.status, account.id, account.serviceId),
            default: SwitchDefault(account?.useDefault, account.id, account.serviceId),
            dateCreated: getDateFromString(account?.createdAt),
          }
        })
        setAccountLists(listOfAccount);
      } else {
        setAccountLists([]);
      }
    } catch (err) {
      // console.log(err);
    }finally{
      setIsLloadingAccount(false)
    }
  }
  
  const changeStatus = async (
    statuss: boolean,
    subAccId: number,
    bizId: number,
    type: string
  ) => {
    alert(type)
    setIsLoadingStatus(true);
    try {
      let url= `/client/business/${bizId}/sub-accounts/${subAccId}/status/${type}`;
      const { message, status }: any = await api.put(
        url,
        {
          isActive: !statuss,
        }
      );
      toast.success(message);
      if (status) {
        await getAccountLists();
      }
      setIsLoadingStatus(false);
    } catch (error) {
      setIsLoadingStatus(false);

      // @ts-ignore
      toast.error(error);
    }
  };

  const SwitchComp = (isActive: boolean, subAccId: number, bizId: number) => {
    return (
      <Switch
        id={subAccId}
        status={isActive}
        toggleMethod={() => changeStatus(isActive, subAccId, bizId, "isActive")}
        label={""}
        color={"blue"}
      />
    );
  };

  const SwitchDefault = (isActive: boolean, subAccId: number, bizId: number) => {
    return (
      <Switch
        id={subAccId}
        status={isActive}
        toggleMethod={() => changeStatus(isActive, subAccId, bizId, "useDefault")}
        label={""}
        color={"blue"}
      />
    );
  };

  const handleEdit = () => {
    setvisible(true);
  };

  useEffect(() => {
    getAccountLists();
  }, []);

  return (
    <div>
      <ServicePageContainer>
        <h1>
          App account details
          <ModeSpan mode={service.mode == "LIVE" ? true : false}>
            {mode}
          </ModeSpan> 
        </h1>
        <ServicePageSubHeader>
          <p>Find account numbers attached to your your app here</p>
          
        </ServicePageSubHeader>

        <ServicePageContent>
          {/* <DetailsRow>
            <DetailsRowContent>
              <h4>Service Name</h4>
              <h4>Created by</h4>
            </DetailsRowContent>
            <button onClick={() => ""}>Edit</button>
          </DetailsRow> */}
          {isLloadingAccount && (
            <GradientLoader />
          )}
          {!isLloadingAccount && !accountLists && (
            <EmptyContainer>
              <EmptyState stateTitle={"No Transaction"} />
            </EmptyContainer>
          )}
          {!isLloadingAccount && accountLists && (
            <Table
              headData={columnHeaders}
              bodyData={accountLists}
              clickFunction={() => ""}
              hoverLink
            />
          )}
          
        </ServicePageContent>
      </ServicePageContainer>

      <Modal
        isOpen={visible}
        width={800}
        withCloseIcon={true}
        onClose={() => setvisible(false)}
        overFlow={true}
        containerWidth={false}
        renderContent={() => (
          <AddService edit={true} setVisible={() => setvisible(false)} />
        )}
        {...{}}
      />
    </div>
  );
}
