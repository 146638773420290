import breakpoints from "shared/utils/breakpoints";
import styled from "styled-components";
import { color } from "../../shared/utils/styles";

type PropT = {
  mennuActive: boolean;
};

export let TabsWrapper = styled.div`
  margin-top: 2rem;
  width: 100%;
  padding: 1.4rem 0.2rem;
  overflow-x: auto;
  background: #fff;
  border: 0.1rem solid ${color.fountainLight};
  border-radius: 0.4rem;
  @media ${breakpoints.device.max.xs} {
    margin-top: 0px;
    padding-right: 15px;
  }
`;

export let TabList = styled.ul`
  list-style: none;
  display: flex;
  justify-content: flex-start;
  gap: 4.4rem;
  color: ${color.textDark};
  padding: 0 1rem;
  align-items: center;
`;

export let StyledMenu = styled.li<PropT>`
  ${(props) => props.mennuActive && ` border-bottom: 1px solid orange;`}
`;
