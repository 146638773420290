import { useEffect } from "react";
import { useParams } from "react-router-dom";
import Preloader from "shared/components/preloader";
import { reFormat } from "shared/utils/url";
export default () => {
  let {MD, JWT, TermUrl, ACSUrl}: any = useParams();
  // ACSUrl, TermUrl, jwt, MD;

  useEffect(() => {
    let elm: any = document.querySelector("#frm");
    elm && elm?.submit();
  }, []);

  return (
    <>
      {/*<GradientLoader />*/}
      <div style={{ display: "none" }}>
        <form id="frm" method="post" action={reFormat(ACSUrl)}>
          <input type="text" value={reFormat(JWT)} name="JWT" />
          <input type="text" value={reFormat(MD)} name="MD" />
        </form>
      </div>
    </>
  );
};
