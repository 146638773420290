import React, {useState} from "react";
import SharedCheckList from "shared/section_components/SharedCheckList";
import RadioButton from "shared/components/RadioButton";
import { PageLayout, Divider } from "shared/section_components/Styles";
import {
  OptionsContainer,
  PreferenceContainer,
  SaveButton,
  SectionContainer,
  SectionTitle,
} from "./Styles";
import consoleLog from "shared/utils/consoleLog";
import { onChangeTextHandler } from "shared/utils/formValidation";

export default function AccountSettings() {
  const [transacFee, setTransacFee] = useState<boolean>(false);
  const [checkedValues, setCheckedValues] = useState<any>({});
  const [params, setParams] = useState<any>({});

  const getCheckedData = (filterParam: string, title: string) => {
    onChangeTextHandler(
      filterParam,
      `${title}`,
      checkedValues,
      setCheckedValues
    );

    let obj: any = {};
    obj[`${title}`] = filterParam;
    setParams({ ...checkedValues, ...obj });
    // consoleLog(params)
  };

  const handleChange = (e: any) => {
    consoleLog("do soomething");
  };

  const accountSettings = [
    {
      id: 1,
      field: "Who should pay the transaction fees?",
      options: {
        Customers: false,
        "Charge me for the transaction": false,
      },
    },
  ];

  // consoleLog(accountSettings[0].options["Charge me for the transaction"]);

  return (
    <PageLayout>
      <div style={{ marginTop: "2rem" }}>
        {/* <SectionContainer>
          <PreferenceContainer>
            <SectionTitle>Who should pay the transaction fees?</SectionTitle>
            <OptionsContainer onChange={handleChange}>
              <RadioButton
                label="Customers"
                value="customer"
                id="inside"
                group="transaction fee"
              />

              <RadioButton
                label="Charge me for the transaction fees"
                value="me"
                id="outside"
                group="transaction fee"
              />
            </OptionsContainer>
          </PreferenceContainer>
          <SaveButton>Save</SaveButton>
        </SectionContainer> */}

        <SectionContainer>
          <PreferenceContainer>
            <SectionTitle>Transaction Emails</SectionTitle>
            <OptionsContainer>
              <div>
                <SharedCheckList
                  onChange={() => getCheckedData('email me transac', 'Transaction Email')}
                  checked={checkedValues['Transaction Email'] === 'email me transac' ? true : false}
                  label="Email me every transactions"
                  id={1}
                />
              </div>

              <div>
                <SharedCheckList
                  onChange={() => getCheckedData('email customer', 'Transaction Email')}
                  checked={checkedValues['Transaction Email'] === 'email customer' ? true : false}
                  label="Email customers every transactions"
                  id={2}
                />
              </div>
            </OptionsContainer>
          </PreferenceContainer>
          <SaveButton>Save</SaveButton>
        </SectionContainer>

        {/* <SectionContainer>
          <PreferenceContainer>
            <SectionTitle>What method of payment do you want?</SectionTitle>
            <OptionsContainer>
              <SharedCheckList
                onChange={() => getCheckedData('enable qr', 'Method of payment')}
                checked={checkedValues['Method of payment'] === 'enable qr' ? true : false}
                label="Enable QR Payment"
                id={3}
              />

              <SharedCheckList
                onChange={() => getCheckedData('dashboard payment', 'Method of payment')}
                checked={checkedValues['Method of payment'] === 'dashboard payment' ? true : false}
                label="Enable dashboard payment options"
                id={4}
              />

              <SharedCheckList
                onChange={() => getCheckedData('card payment', 'Method of payment')}
                checked={checkedValues['Method of payment'] === 'card payment' ? true : false}
                label="Enable card payment "
                id={5}
              />
            </OptionsContainer>
          </PreferenceContainer>
          <SaveButton>Save</SaveButton>
        </SectionContainer> */}

        <SectionContainer>
          <PreferenceContainer>
            <SectionTitle>
              Send notification emails to team members
            </SectionTitle>
            <OptionsContainer onChange={handleChange}>
              <RadioButton
                label="Send to business email address only"
                value="business"
                id="business email only"
                group="team notification"
              />

              <RadioButton
                label="Send to all dashboard users"
                value="dashboard users"
                id="dashboard users"
                group="team notification"
              />

              <RadioButton
                label="Send to specific users only"
                value="specific users"
                id="specific users"
                group="team notification"
              />
            </OptionsContainer>
          </PreferenceContainer>
          <SaveButton>Save</SaveButton>
        </SectionContainer>

        <SectionContainer>
          <PreferenceContainer>
            <SectionTitle>How do you want to get your earnings?</SectionTitle>
            <OptionsContainer onChange={handleChange}>
              <RadioButton
                label="Settle to bank account"
                value="bank account"
                id="bank account"
                group="getearnings"
              />

              <RadioButton
                label="Settle to wallet"
                value="wallet"
                id="wallet"
                group="getearnings"
              />
            </OptionsContainer>
          </PreferenceContainer>
          <SaveButton>Save</SaveButton>
        </SectionContainer>

        <SectionContainer>
          <PreferenceContainer>
            <SectionTitle>Two Factor Authentication</SectionTitle>
            <OptionsContainer onChange={handleChange}>
              <RadioButton
                label="Enable two factor authentication for transfers"
                value="bank account"
                id="authentication for transfers"
                group="authentication for transfers"
              />

              <RadioButton
                label="Enable two factor authentication for login"
                value="authlogin"
                id="authlogin"
                group="authentication for transfers"
              />
            </OptionsContainer>
          </PreferenceContainer>
          <SaveButton>Save</SaveButton>
        </SectionContainer>

        <SectionContainer>
          <PreferenceContainer>
            <SectionTitle>Transfer Receipts</SectionTitle>
            <OptionsContainer>
              <SharedCheckList
                onChange={() => getCheckedData('true', 'Transfer Receipt')}
                checked={checkedValues['Transfer Receipt'] === 'true' ? true : false}
                label="Send email receipts for successful transfers"
                id={6}
              />
            </OptionsContainer>
          </PreferenceContainer>
          <SaveButton>Save</SaveButton>
        </SectionContainer>

        <SectionContainer>
          <PreferenceContainer>
            <SectionTitle>Subscription Email</SectionTitle>
            <OptionsContainer>
              <SharedCheckList
                onChange={() => getCheckedData('true', 'Subscription Email')}
                checked={checkedValues['Subscription Email'] === 'true' ? true : false}
                label="Allow customers cancel subscription"
                id={7}
              />
            </OptionsContainer>
          </PreferenceContainer>
          <SaveButton>Save</SaveButton>
        </SectionContainer>

        <SectionContainer>
          <PreferenceContainer>
            <SectionTitle>Balance History</SectionTitle>
            <OptionsContainer>
              <SharedCheckList
                onChange={() => getCheckedData('true', 'Balance History')}
                checked={checkedValues['Balance History'] === 'true' ? true : false}
                label="Enable balance history for ledger balance"
                id={8}
              />
            </OptionsContainer>
          </PreferenceContainer>
          <SaveButton>Save</SaveButton>
        </SectionContainer>

        <Divider />
      </div>
    </PageLayout>
  );
}
