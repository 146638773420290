import React, {
  Fragment,
  useState,
  useRef,
  useEffect,
  useCallback,
  ReactNode,
} from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";

import useOnOutsideClick from "shared/hooks/onOutsideClick";
import useOnEscapeKeyDown from "shared/hooks/onEscapeKeyDown";

import {
  ScrollOverlay,
  ClickableOverlay,
  StyledModal,
  CloseIcon,
  FooterStyle
} from "./Styles";


type PropT = {
  className: string;
  testid: string;
  variant: "center" | "aside";
  width: number | string;
  minWidth?: number | string;
  radius?: number;
  maxHeight?: number;
  withCloseIcon: boolean;
  isOpen: boolean;
  onClose: () => void;
  renderLink: ({}) => void;
  renderContent: any;
  renderFooter?: any;
  overFlow?: boolean;
  containerWidth?: boolean;
};

const defaultProps = {
  className: undefined,
  testid: "modal",
  variant: "center",
  width: 600,
  maxHeight: 400,
  withCloseIcon: true,
  isOpen: undefined,
  onClose: () => {},
  renderLink: (x: any) => {},
  overFlow: false,
  containerWidth: true,
};

const Modal = ({
  className,
  testid,
  variant,
  width,
  maxHeight,
  minWidth,
  radius,
  withCloseIcon,
  isOpen: propsIsOpen,
  onClose: tellParentToClose,
  renderLink,
  renderContent,
  renderFooter,
  overFlow,
  containerWidth,
}: PropT) => {
  const [stateIsOpen, setStateOpen] = useState(false);
  const isControlled = typeof propsIsOpen === "boolean";
  const isOpen = isControlled ? propsIsOpen : stateIsOpen;

  const $modalRef = useRef();
  const $clickableOverlayRef = useRef();

  const closeModal = useCallback(() => {
    if (!isControlled) {
      setStateOpen(false);
    } else {
      tellParentToClose();
    }
  }, [isControlled, tellParentToClose]);

  useOnOutsideClick($modalRef, isOpen, closeModal, $clickableOverlayRef);
  useOnEscapeKeyDown(isOpen, closeModal);

  useEffect(() => {
    if (!overFlow) {
      document.body.style.overflow = "hidden";
    }

    return () => {
      document.body.style.overflow = "visible";
    };
  }, [isOpen]);

  return (
    <Fragment>
      <>
        {!isControlled && renderLink({ open: () => setStateOpen(true) })}

        {isOpen &&
          ReactDOM.createPortal(
            <ScrollOverlay>
              {/* @ts-ignore */}
              <ClickableOverlay variant={variant} ref={$clickableOverlayRef}>
                <StyledModal
                  className={className}
                  variant={variant}
                  width={width}
                  minWidth={minWidth}
                  data-testid={testid}
                  containerWidth={containerWidth}
                  radius={radius}
                  // @ts-ignore
                  ref={$modalRef}
                >
                  <>
                    {withCloseIcon && (
                      <CloseIcon
                        type="close"
                        variant={variant}
                        onClick={closeModal}
                      />
                    )}
                    {renderContent({ close: closeModal })}
                  </>
                  {/* <FooterStyle>
                    {renderFooter()}
                  </FooterStyle> */}
                </StyledModal>
              </ClickableOverlay>
            </ScrollOverlay>,
            $root
          )}
      </>
    </Fragment>
  );
};

const $root: any = document.getElementById("root");

// Modal.propTypes = propTypes;
Modal.defaultProps = defaultProps;

export default Modal;
