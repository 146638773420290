import Background1 from "App/assets/images/LoginFace/bg.avif";
import Background2 from "App/assets/images/LoginFace/bkgrnd2.svg";
// import Background3 from "App/assets/images/LoginFace/bkgrnd3.svg";
import Ad1 from "App/assets/images/LoginFace/ad1.svg";
import Ad2 from "App/assets/images/LoginFace/ad2.svg";
import { useEffect, useState } from "react";
import consoleLog from "shared/utils/consoleLog";

import {
  BkgroundImageDiv,
  ButtonDiv,
  OvalDiv,
  TBody,
  TextDiv,
  TopTitle,
  UpperCircle,
  Image,
} from "./Styles";

const DynamicBackground = () => {
  const [imgState, setImageState] = useState(0);
  const [ovalState, setOvalState] = useState(0);

  // let arrImg = [Background1];
  let arrImg = [];
  let arrOval: any = [
    {
      img: Ad1,
      color: "#FB29CD",
    },
    {
      img: Ad2,
      color: "#fb5629",
    },
  ];
  useEffect(() => {
    const interval = setTimeout(() => {
      setImageState(imgState + 1);
      //   consoleLog("backgrd image ever sec");
    }, 5000);
    return () => clearTimeout(interval);
  }, [imgState]);

  useEffect(() => {
    const interval = setTimeout(() => {
      setOvalState(ovalState + 1);
      //   consoleLog("backgrd image ever sec");
    }, 6000);
    return () => clearTimeout(interval);
  }, [ovalState]);

  let obj = arrOval[ovalState % arrOval.length];

  return (
    <a href="https://fountainpay.ng/about" target="_blank">
      <BkgroundImageDiv
      // backgroundImage={arrImg[imgState % arrImg.length]}
      >
        <Image src={obj?.img} />
      </BkgroundImageDiv>
    </a>
  );
};

export default DynamicBackground;
