import styled, { css } from "styled-components";
import breakpoints from "shared/utils/breakpoints";

type Props={
  disabled?: boolean;
}
export const CardCont = styled.div<Props>`
  display: flex;
  width: 22%;
  height: 150px;
  padding: 20px;
  justify-content: center;
  align-items: center;
  gap: 232px;
  flex-shrink: 0;
  border-radius: 10px;
  background: rgba(250, 214, 214, 0.5);

  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.05);
  cursor: pointer;

  ${props=>props.disabled && css`
    pointer-events: none;
    opacity: 0.4;
  `}
  @media ${breakpoints.device.max.md} {
    width: 48%;
    margin-top: 5px;
  }
  @media ${breakpoints.device.max.xs} {
    width: 100%;
    margin-top: 5px;
  }
`;

export const CardInnerCont = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;

export const InnerText = styled.div`
  color: #760000;
  text-align: center;

  font-size: 20px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
`;
