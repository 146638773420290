import api from "shared/utils/api";

import consoleLog from "shared/utils/consoleLog";
class Invite {
  async getServices(businessID: any) {
    try {
      const response = await api.get("/services", {
        business_id: businessID,
      });
      if (response.payload) {
        return response.payload;
      }
    } catch (error) {
      return [];
    }
  }

  async getTeamMemberDetails(businessId: string, teamId?: string) {
    try {
      const response = await api.get(`/business/${businessId}/team/${teamId}`);

      consoleLog(response, "DetailTeamMember");
      if (response.payload) {
        return response.payload;
      } else return null;
    } catch (error) {
      consoleLog(error);
      return null;
    }
  }
}

const inviteOBJ = new Invite();
export default inviteOBJ;
