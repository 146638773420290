import React, { useEffect, useState } from "react";
import {
  AssetsForm,
  ButtonContainer,
  CCFormContainer,
  CategoryForm,
  Dropdown,
  DropdownBtn,
  DropdownContent,
  DropdownItem,
  DropdownText,
  InputGroup,
  PageHeader,
} from "../Styles";
import { Icon, Input, Spinner, Textarea } from "shared/components";
import { useHistory } from "react-router-dom";
import { BsChevronDown } from "react-icons/bs";
import { InventoryService } from "../inventory-service";
import { RootStateOrAny, useSelector } from "react-redux";
import { IFecthedCategories, IFecthedCategory } from "../inventory-models";
import toast from "shared/utils/toast";

export default function CreateAsset() {
  const currentBusiness = useSelector(
    (store: RootStateOrAny) => store.business
  );
  const currentUser = useSelector((store: RootStateOrAny) => store.currentUser);
  const initValues = {
    name: "",
    business_id: currentBusiness.id,
    user_id: currentUser.id,
    cost: 0,
    quantity: 0,
    description: "",
    category_id: "",
    status: "ACTIVE",
  };

  const [formValues, setFormValues] = useState(initValues);
  const [isloading, setisloading] = useState(false);
  const [selectedCategory, setselectedCategory] = useState<any>({});
  const [dropDown, setDropDown] = useState(false);
  const [categoryList, setcategoryList] = useState<any[]>([]);
  const [isformValid, setisformValid] = useState(false);

  const handleChange = (name: string, value: any) => {
    if (name === "cost" || name === "quantity") {
      if (isNaN(value)) return false;

      setFormValues((prevFormValues: any) => ({
        ...prevFormValues,
        [name]: parseFloat(value),
      }));
    }
    setFormValues((prevFormValues: any) => ({
      ...prevFormValues,
      [name]: value,
    }));
  };

  const history = useHistory();

  const handleValidation = () => {
    const { name, cost, quantity, description, category_id } = formValues;

    const isvalid =
      name.length > 5 &&
      cost > 10 &&
      quantity > 0 &&
      description.length > 5 &&
      category_id.length > 10;

    setisformValid(isvalid);
  };

  const getcategpries = async () => {
    const response = await InventoryService.GetCategories(currentBusiness?.id);
    if (response.status) {
      const data: IFecthedCategories = Object.values(response.payload);
      const transformedList = data.map((category: IFecthedCategory) => {
        return {
          id: category.uuid,
          name: category.name,
        };
      });

      setcategoryList(transformedList);
    }
  };

  const handleCreateAsset = async (e: any) => {
    e.preventDefault();
    setisloading(true);
    const response = await InventoryService.CreateAsset(formValues);

    if (response.status) {
      toast.success(response.message);
      setisloading(false);
      setFormValues(initValues);
    } else {
      setisloading(false);
    }
  };

  useEffect(() => {
    getcategpries();
  }, []);

  useEffect(() => {
    handleValidation();
  }, [formValues]);

  return (
    <div>
      <PageHeader>
        <div onClick={() => history.goBack()}>
          <Icon
            type="arrow-left-circle"
            size="20"
            style={{ marginTop: "4px", cursor: "Pointer" }}
          />
        </div>
        <h2>Create assets</h2>
      </PageHeader>
      <CCFormContainer>
        <AssetsForm onSubmit={handleCreateAsset}>
          <InputGroup>
            <div>
              <p>Name</p>
              <Input
                type="text"
                name="input"
                value={formValues.name}
                placeholder="Asset name"
                onChange={(value: any) => handleChange("name", value)}
                styleContainer={{ marginBottom: 0 }}
              />
            </div>
            <div style={{ marginTop: "1.8rem" }}>
              <Dropdown>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => setDropDown(!dropDown)}
                >
                  <span style={{ width: "80%", paddingLeft: "0.4rem" }}>
                    {selectedCategory.name
                      ? selectedCategory.name
                      : " Select category"}
                  </span>
                  <DropdownBtn>
                    <BsChevronDown style={{ fontSize: 20, color: "#828282" }} />
                  </DropdownBtn>
                </div>

                <DropdownContent
                  style={{ display: dropDown ? "block" : "none" }}
                >
                  {categoryList.map((category: any) => (
                    <DropdownItem key={category.id}>
                      <DropdownText
                        onClick={() => {
                          setDropDown(false);
                          setselectedCategory(category);
                          setFormValues((prevFormValues: any) => ({
                            ...prevFormValues,
                            ["category_id"]: category.id,
                          }));
                        }}
                      >
                        {category.name}
                      </DropdownText>
                    </DropdownItem>
                  ))}
                </DropdownContent>
              </Dropdown>
            </div>
          </InputGroup>

          <InputGroup>
            <div>
              <p>Cost</p>
              <Input
                type="text"
                name="input"
                value={formValues.cost}
                placeholder="Asset cost"
                onChange={(value: any) => handleChange("cost", value)}
                styleContainer={{ marginBottom: 0 }}
              />
            </div>
            <div>
              <p>Quantity</p>
              <Input
                type="text"
                name="input"
                value={formValues.quantity}
                placeholder=""
                onChange={(value: any) => handleChange("quantity", value)}
                styleContainer={{ marginBottom: 0 }}
              />
            </div>
          </InputGroup>

          <InputGroup>
            <div>
              <p>Description</p>
              <Textarea
                value={formValues.description}
                onChange={(e: any) => handleChange("description", e)}
              />
            </div>
          </InputGroup>

          <button disabled={isformValid ? false : true}>
            {isloading ? <Spinner /> : "Create"}
          </button>
        </AssetsForm>
      </CCFormContainer>
    </div>
  );
}
