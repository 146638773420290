import { useEffect, useState } from "react";
import { Form, GradientLoader } from "shared/components";
import useAddLibrary from "shared/hooks/useAddLibrary";
import { onChangeTextHandler } from "shared/utils/formValidation";
import {
  ActionButton,
  ComponentBody,
  FormElement,
  LinkDescription,
  Title,
  ErrorTitle,
  DivLabel,
  DivInput,
} from "./Styles";
import consoleLog from "shared/utils/consoleLog";
import { useFPCheckout, closePaymentModal } from "fountainpay-react";

import PayBox from "./PayBox";
import api from "shared/utils/api";
import { useHistory } from "react-router-dom";

const LinkForm = ({ paymentDetails }: any) => {
  const initialValues = {
    amount: 0,
    fullname: "",
    email: "",
    phone: "",
    tnxRef: "" + Math.floor(Math.random() * 1000000000 + 1),
  };
  consoleLog(paymentDetails, "paymentDetails");
  const history = useHistory();

  const [formState, setFormState] = useState(initialValues);
  const [isFormValid, setFormValid] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [argsData, setArgsData] = useState({} as any);

  // const [scriptLoaded] = useAddLibrary(
  //   "https://modal.fountainpay.ng/inlineTest.js",
  //   "Fountainpay"
  // );

  // let config: any =
  //   Object.keys(argsData).length > 0
  //     ? {
  //         publicKey: paymentDetails.publicKey,
  //         tnxRef: "" + Math.floor(Math.random() * 1000000000 + 1),
  //         amount: argsData.amount,
  //         currency: "NGN",
  //         channels: ["card", "qrcode", "directDebit"],
  //         customer: {
  //           email: argsData.email,
  //           phoneNo: argsData.phone,
  //           lastName: argsData.names[0],
  //           otherName: argsData.names[1] || "",
  //         },
  //       }
  //     : {};
  // const  handlePayment = useFPCheckout(argsData);

  const divClose = () => {
    let divContainer: any = document.querySelectorAll("#widContainer");
    if (divContainer) {
      for (let i = 0; i < divContainer.length; i++) {
        divContainer[i].style.display = "none";
      }
    }
  };

  const closeEmptyWidContainer = () => {
    let divContainer: any = document.querySelectorAll("#widContainer");
    if (divContainer) {
      for (let i = 0; i < divContainer.length; i++) {
        if (
          window.getComputedStyle(
            divContainer[i]?.firstElementChild?.firstElementChild
          ).display == "none"
        ) {
          divContainer[i].style.display = "none";
        }
      }
    }
  };

  // @ts-ignore

  const handlePayment = useFPCheckout(argsData);
  const onSubmit = async () => {
    try {
      // console.log("Checkout data: ", argsData);
      setIsProcessing(true);
      handlePayment({
        callback: async (response: any) => {
          consoleLog(response);
          history.push(`/reciept/${formState.tnxRef}/${paymentDetails.slug}`);
          // if (argsData?.webhookUrl) {
          // } else {
          //   //confirm transaction
          //   history.push(`/reciept/${formState.tnxRef}/${paymentDetails.id}`);
          //   // let { privateKey } = paymentDetails;
          //   // const resp = await api.get(
          //   //   `/transactions/verify/${response?.payload?.tnx_ref}`,
          //   //   { SecretKey: privateKey }
          //   // );
          //   // consoleLog(resp, "response__");
          //   // if (
          //   //   response.status == true &&
          //   //   response?.payload?.status === "SUCCESSFUL"
          //   // ) {
          //   //   //print out the reciept
          //   //   alert("send Reciept");
          //   // } else {
          //   //   //show failed response
          //   //   alert("send Failed Reciept");
          //   // }
          // }
          closePaymentModal(); // this will close the modal programmatically
        },
        close: () => {},
      });
      onChangeTextHandler(
        "" + Math.floor(Math.random() * 1000000000 + 1),
        "tnxRef",
        formState,
        setFormState
      );
      let elem: any = document.querySelectorAll("#widget-FPPopup");
      divClose();
      consoleLog(elem, "ELEMENT");
      if (elem) {
        for (let i = 0; i < elem.length; i++) {
          if (i == elem.length - 1) {
            let div = document.createElement("div");
            div.setAttribute(
              "style",
              `top: 0px;left: 0px;width: 100vw;height: 100vh;background-color: transparent;
              position: absolute;`
            );
            div.setAttribute("id", `widContainer`);

            elem[i].style.display = "flex";
            elem[i].style.position = "absolute";
            elem[i].style.top = "0";
            elem[i].style.left = "30%";
            div.appendChild(elem[i]);
            document.body.appendChild(div);
          } else {
            elem[i].style.display = "none";
          }
        }
      }
    } catch (error) {
      consoleLog(error, "ErrorHanld");
    } finally {
      setIsProcessing(false);
    }
  };

  // const handleSuccess = (tnx_ref: any) => {
  //   consoleLog("tnx_ref:", tnx_ref);
  //   window.location.href = paymentDetails.redirectUrl;
  // };

  useEffect(() => {
    if (paymentDetails?.amount) {
      setFormState({ ...formState, amount: paymentDetails?.amount });
    }
  }, [paymentDetails]);

  useEffect(() => {
    let timer = setInterval(() => {
      closeEmptyWidContainer();
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (
      !formState?.amount ||
      formState?.amount <= 0 ||
      !formState?.fullname ||
      !formState?.email ||
      !formState?.phone ||
      !/^(\+\d{1,2}\s?)?(\(\d{1,4}\)|\d{1,4})[-.\s]?\d{1,10}[-.\s]?\d{1,10}$/.test(
        formState.phone
      ) ||
      //validate phone number
      //validate email address
      !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(formState.email)
    ) {
      setFormValid(false);
      return;
    }

    setFormValid(true);
    const names = formState.fullname.split(" ");
    const data = {
      publicKey: paymentDetails.publicKey,
      redirectUrl: paymentDetails.redirectUrl,
      tnxRef: formState.tnxRef,
      amount: formState.amount || 0,
      narration: paymentDetails.description || "Payment from shared link.",
      currency: paymentDetails?.currency || "NGN",
      customer: {
        email: formState.email || "",
        phoneNo: formState.phone || "",
        lastName: names[0],
        otherName: names[1] || "",
      },
      metadata: {
        container: "",
        isModal: true,
        minimized: false,
        theme: {
          disableDarkMode: false,
          mode: "light",
        },
      },
      close: function(error: any) {
        alert(error);
      },
    };

    setArgsData(data);
  }, [formState]);

  const inputArray: any = [
    {
      id: "fullname",
      title: "Full name",
      type: "text",
      required: true,
    },
    {
      id: "email",
      title: "Email address",
      type: "email",
      required: true,
    },
    {
      id: "phone",
      title: "Phone number e.g 08022...",
      type: "text",
      required: true,
    },
    {
      id: "amount",
      title: "Amount",
      type: paymentDetails?.amountType !== "DYNAMIC" ? "div" : "text",
      required: true,
      disabled: paymentDetails?.amountType !== "DYNAMIC",
    },
  ];

  const displayFormInput = () => {
    return inputArray.map((elm: any, idx: any) => {
      switch (elm.type) {
        case "text":
          return (
            <Form.Field.Input
              key={idx}
              name={elm.id}
              type={elm.type}
              //@ts-ignore
              value={formState[elm.id] ?? ""}
              label={elm.title}
              disabled={elm.disabled}
              //   placeholder={elm?.placeholder}
              onChange={(val: any) => {
                if (
                  elm.id === "phone" &&
                  !/^[+]?\d+$/.test(val) &&
                  val !== "" &&
                  val !== "+"
                )
                  return;
                onChangeTextHandler(val, elm.id, formState, setFormState);
              }}
              // tip="Kindly enter your BVN"
            />
          );
        case "email":
          return (
            <Form.Field.Input
              key={idx}
              name={elm.id}
              type={elm.type}
              //@ts-ignore
              value={formState[elm.id] ?? ""}
              label={elm.title}
              disabled={elm.disabled}
              //   placeholder={elm?.placeholder}
              onChange={(val: any) =>
                onChangeTextHandler(val, elm.id, formState, setFormState)
              }
              // tip="Kindly enter your BVN"
            />
          );

        case "div":
          return (
            <DivLabel style={{ marginTop: 20 }}>
              <div>Amount</div>
              {/* @ts-ignore */}
              <DivInput>{formState[elm.id] ?? "0"}</DivInput>
            </DivLabel>
          );
      }
    });
  };

  // if (!scriptLoaded) {
  //   return <GradientLoader />;
  // }

  const titlePayment = () => {
    if (paymentDetails?.type === "ONE_TIME")
      return "This is a one-time payment";

    return (
      <>
        This is a subscription payment
        <br />
        {paymentDetails?.subscriptionPlan &&
          `Payment Plan: ${paymentDetails?.subscriptionPlan}  `}
        {paymentDetails?.frequency &&
          `Number of Times: ${paymentDetails?.frequency}  `}
      </>
    );
  };

  return (
    <ComponentBody>
      {paymentDetails.name ? (
        <>
          <Title>{paymentDetails.name}</Title>
          <LinkDescription>{titlePayment()}</LinkDescription>
          {/* <PayBox config={argsData} /> */}

          {/* @ts-ignore */}
          <Form
            enableReinitialize
            initialValues={{
              fullname: "",
              email: "",
              phone: "",
              amount: 0,
            }}
            validations={{
              email: [Form.is.required(), Form.is.email()],
              phone: [Form.is.required()],
              fullname: [Form.is.required()],
              amount: [Form.is.required()],
            }}
            onSubmit={onSubmit}
          >
            <FormElement>
              {displayFormInput()}
              <div style={{ display: "flex", justifyContent: "end" }}>
                <ActionButton
                  type="submit"
                  variant="primary"
                  isWorking={isProcessing}
                  disabled={!isFormValid}
                >
                  {isProcessing ? "loading" : "Pay"}
                </ActionButton>
              </div>
            </FormElement>
          </Form>
        </>
      ) : (
        <ErrorTitle>Payment Link is not valid!</ErrorTitle>
      )}
    </ComponentBody>
  );
};

export default LinkForm;
