import styled from "styled-components";

type PropT={
    top?: number;
    left?: number;
    right?: number;
}
export const Container = styled.div<PropT>`
 width: 100%;
 height: 100%;
 position: fixed;
 top: 0;
 left: 0;
 left: 0;
 right: 0;
 bottom: 0;
 ${props => (props.top ? `padding-top: ${props.top}px !important;` : 'padding-top: 0;')}
 ${props => (props.left ? `padding-left: ${props.left}px !important;` : 'padding-left: 0;')}
 ${props => (props.right ? `padding-left: ${props.right}px !important;` : 'padding-right: 0;')}
 background-color: rgba(255,255,255,0); /* Black background with opacity */
 backdrop-filter: blur(10px);
 z-index: 9999; /* Specify a stack order in case you're using a different order for other elements */
 cursor: pointer;
`;