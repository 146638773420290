import { useState } from "react";
import {
  ParentCont,
  LeftPanel,
  RightPanel,
  RightPanelAuxCont,
  CloseBtn,
  DescriptionBox,
  RightPanelAuxCon2,
  DescriptionText,
  DescriptionBtn,
  FormCont,
  TitleCont,
  Back,
  FormTitle,
  FormSubTitle,
  Selectors,
  SelectorButton,
  NavCont,
  NavIndicator,
} from "./Styles";
import { Icon, Modal } from "shared/components";
import { useHistory } from "react-router-dom";
import Forms from "./Forms";
import ExitPage from "KYC-refactored/Welcome/exitPage";
import { clearLocalStorage } from "shared/utils/authToken";
import { useDispatch } from "react-redux";
import { logout } from "store/actions/logout";

function PersonalDetails() {
  const history = useHistory();
  const [isClicked, setIsClicked] = useState<boolean>(true);
  const [visible, setVisible] = useState<boolean>(false);
  const [comp, setComp] = useState(1);
  const dispatch = useDispatch();

  const handlePresenceChange = () => {
    setIsClicked(!isClicked);
  };

  const goBack = () => {
    history.push("/kyc");
  };
  const redirectToChoose = async () => {
    clearLocalStorage();
    await dispatch(logout());

    history.push("/authenticate");
    return;
  };
  const showComponent = () => {
    switch (comp) {
      default:
        return <ExitPage setVisible={setVisible} yesClick={redirectToChoose} />;
    }
  };

  return (
    <>
      <ParentCont>
        <LeftPanel>
          <FormCont>
            <TitleCont>
              <Back onClick={goBack}>
                <Icon type={"chevron-left"} size={20} />
              </Back>
              <FormTitle>Personal Details</FormTitle>
            </TitleCont>

            <FormSubTitle>
              With your corporate account number, you are guaranteed of your
              settlement.
            </FormSubTitle>

            <NavCont>
              <Selectors>
                {/* <SelectorButton
                  onClick={handlePresenceChange}
                  style={{ backgroundColor: isClicked ? "#fff" : "transparent" }}
                >
                  BVN
                </SelectorButton> */}
                {/* <SelectorButton
                  onClick={handlePresenceChange}
                  style={{ backgroundColor: !isClicked ? "#fff" : "transparent" }}
                >
                  NIMC
                </SelectorButton> */}
              </Selectors>
              {/* <NavIndicator
                style={{
                  translate: isClicked ? 0 : "202%",
                }}
              /> */}
            </NavCont>

            <Forms isClicked={isClicked} />
          </FormCont>
        </LeftPanel>

        <RightPanel>
          <RightPanelAuxCont>
            <CloseBtn onClick={() => setVisible(true)}>
              <Icon type={"close"} size={20} />
            </CloseBtn>
          </RightPanelAuxCont>

          <RightPanelAuxCon2>
            <DescriptionBox>
              <DescriptionText>
                Join over 152,000 customers that use our products
              </DescriptionText>

              <DescriptionBtn
                onClick={() =>
                  window.open(
                    "https://medium.com/@fountainpay",
                    "noopener,noreferrer"
                  )
                }
              >
                Learn more
              </DescriptionBtn>
            </DescriptionBox>
          </RightPanelAuxCon2>
        </RightPanel>
      </ParentCont>
      <Modal
        isOpen={visible}
        width={800}
        withCloseIcon={false}
        onClose={() => setVisible(false)}
        overFlow={true}
        containerWidth={false}
        renderContent={showComponent}
        // {...addedProps}
      />
    </>
  );
}

export default PersonalDetails;
