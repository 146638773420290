import consoleLog from "shared/utils/consoleLog";

export const filteredDataFn = (
  data: any[],
  columnHeaders: any[],
  searchData = "",
  filteredColumn = ""
) => {
  if (searchData.trim() === "") return data;
  let result = [];
  let columnToFilter = [];
  if (filteredColumn.trim() === "") {
    columnToFilter = columnHeaders.map((el) => el.id);
  } else {
    let findElm = columnHeaders.find((el) => el.id === filteredColumn);
    if (findElm) {
      columnToFilter = [findElm.id];
    } else {
      columnToFilter = columnHeaders.map((el) => el.id);
    }
  }

  searchData = searchData.trim().toLocaleLowerCase();

  for (let el of data) {
    for (let elmId of columnToFilter) {
      let strElm = String(el[elmId])
        .trim()
        .toLocaleLowerCase();
      if (strElm.includes(searchData)) {
        result.push(el);
        break;
      }
    }
  }
  // consoleLog(result);
  return result;
};
