import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import chargeBackClass from "ChargeBacks/chargeBackClass";
import toast from "shared/utils/toast";

import { Cont, Para, Message, Button } from "./Styles";
import { useParams } from "react-router-dom";
import consoleLog from "shared/utils/consoleLog";

const initialValues = {
  message: "",
  type: "DECLINED",
};

function Decline({ toggle, getChargeBack }: any) {
  const { id }: any = useParams();
  const value = toggle();

  const onSubmit = async () => {
    const userData = { ...values };
    consoleLog(userData);
    try {
      const response = await chargeBackClass.changeStatus(id, userData);
      consoleLog(response);
      toast.success("Status Changed");
      getChargeBack(id);
    } catch (error) {
      consoleLog(error);
    }
  };

  const {
    handleChange,
    values,
    handleBlur,
    handleSubmit,
    errors,
    touched,
  } = useFormik({
    initialValues,
    onSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });
  return (
    <Cont>
      <Para>Reason for decline</Para>

      <form onSubmit={handleSubmit}>
        {!value && (
          <>
            <Message
              placeholder="Type your message here"
              value={values.message}
              name="message"
              onChange={handleChange}
              onBlur={handleBlur}
            />

            <Para>
              Please upload receipts that show that you delivered value to these
              customers
            </Para>

            <Button
              type="button"
              style={{ backgroundColor: "white", color: "grey", marginTop: 20 }}
            >
              Upload
            </Button>
          </>
        )}

        <Button
          type="submit"
          style={{
            backgroundColor: "#FF2424",
            marginTop: 25,
            opacity: value ? 0.5 : 1,
          }}
          disabled={value}
        >
          Decline
        </Button>
      </form>
    </Cont>
  );
}

export default Decline;
