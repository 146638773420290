import { useEffect, useState } from "react";
// import { Modal } from "shared/components/ModalBox/Styles";
import MainLayout from "Auth/Layouts/MainLayout";
import { useParams } from "react-router-dom";
import Modal from "shared/quickComponents/modal";
import api from "shared/utils/api";
import consoleLog from "shared/utils/consoleLog";
import LinkForm from "./LinkForm";
import {
  FormBody,
  ModalBody,
  PageBody,
  TestMode,
  ModalContent,
  RightPanel,
} from "./Styles";
import { GradientLoader } from "shared/components";
import { ErrorTitle } from "./LinkForm/Styles";

export default function PaymentLinkModal() {
  const [loading, setLoading] = useState<boolean | string>(true);
  const [paymentDetails, setPaymentDetails] = useState({} as any);

  const { id }: any = useParams();

  const getPayment = async () => {
    try {
      const response = await api.get(`/checkout/payment-link/${id}`);
      consoleLog(response, "response__");
      if (response.status == true) {
        if (response?.payload?.status === "ACTIVE") {
          setPaymentDetails(response.payload);
          setLoading(false);
        } else {
          setLoading("Payment is deactivated");
        }
      }
    } catch (err) {
      consoleLog(err, "error");
      setLoading(typeof err === "string" ? err : false);
    }
  };

  const rightSide =
    paymentDetails?.mode === "TEST" ? (
      <TestMode>This is a Test Mode</TestMode>
    ) : (
      false
    );

  useEffect(() => {
    getPayment();
  }, []);

  if (typeof loading === "boolean" && loading === true)
    return <GradientLoader />;

  return (
    <PageBody>
      <ModalBody>
        <ModalContent>
          {loading ? (
            typeof loading === "string" ? (
              <ErrorTitle>{loading}</ErrorTitle>
            ) : (
              <p>Loading...</p>
            )
          ) : (
            <LinkForm paymentDetails={paymentDetails} />
          )}
        </ModalContent>
      </ModalBody>
    </PageBody>
  );
}
