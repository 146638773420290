import { AttributeContainer, Checkmark, Input, Label } from "./Styles";

type PropT = {
  label?: string;
  value?: any;
  id: any;
  group: string;
  onChange?: any;
  checked?: any;
};

export default function RadioButton({
  label,
  value,
  id,
  group,
  onChange,
  checked,
}: PropT) {
  return (
    <>
      <AttributeContainer>
        <Label htmlFor={id}>
          {label}
          <Input
            type="radio"
            name={group}
            id={id}
            onChange={onChange}
            value={value}
            checked={checked}
          />
          <Checkmark />
        </Label>
      </AttributeContainer>
    </>
  );
}
