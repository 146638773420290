import { Button, Form } from "shared/components";
import breakpoints from "shared/utils/breakpoints";
import { color, font } from "shared/utils/styles";
import styled from "styled-components";

export const FormBody = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-height: 60vh;
`;

export const RightPane = styled.div`
  width: 35%;
  order: 2;
  display: flex;
  margin: 1em;
  @media ${breakpoints.device.max.xs} {
    display: none;
  }
`;

export const FormTitle = styled.h1`
  font-weight: 700;
  font-size: 24px;
  color: black;
  text-align: left;
`;
export const FormSubTitle = styled.p`
  font-weight: 400;
  font-size: 14px;
  color: #828282;
  text-align: left;
`;

export const Back = styled.p`
  font-weight: 400;
  font-size: 14px;
  color: #828282;
  // text-align: left;
`;

export const Selectors = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1em 0;
`;

export const SelectorButton = styled(Button)`
  margin: 2px 0;
  width: 43%;
  height: 48px;
  float: right;
  font-weight: 600;
  font-size: 18px;
  // background-color: ${color.fountain};
`;
export const LeftPane = styled.div`
  min-width: 55%;
  max-width: 65%;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  vertical-align: middle;
  padding-top: 30px;
  background: #fbfbfb;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  padding: 25px 40px 35px;
  @media ${breakpoints.device.max.xs} {
    min-width: 100%;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }
`;

export const ActionButton = styled(Button)`
  color: #fff;
  width: 70%;
  float: right;
  background-color: ${color.fountain};
`;

export const FormElement = styled(Form.Element)`
  width: 100%;
`;

export const UploadContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 15px;
`;

export const UploadLabel = styled.label`
  display: block;
  padding-bottom: 5px;
  color: #5e6c84;
  font-family: "KumbhSansNormal";
  font-weight: normal;
  font-size: 13px;
  text-align: left;
`;

export const UploadBox = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid grey;
  border-radius: 5px;
  padding: 10px;
`;

export const UploadInput = styled.div`
  background-color: #ffffff;
  flex: 1 1 auto;
  text-align: left;
  margin-right: 5px;
  font-size: 12px;
`;

export const UploadButton = styled.label`
  width: 84px;
  height: 39px;
  background: #828282;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FieldError = styled.div`
  margin-top: 6px;
  text-align: left;
  line-height: 1;
  color: ${color.danger};
  ${font.medium}
  ${font.size(12.5)}
`;
