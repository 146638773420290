import React from "react";
import { useHistory } from "react-router-dom";
import { ServiceTabsCont } from "../Style";
import { RootStateOrAny, useSelector } from "react-redux";

export default function SeriviceHeadTabs() {
  const service = useSelector((store: RootStateOrAny) => store.service);
  const history = useHistory();
  return (
    <div>
      <ServiceTabsCont>
        <span onClick={() => history.push("../my-apps/account-details")}>
          Account details &#187;
        </span>
        <span>Payment splitting &#187;</span>
        <span onClick={() => history.push(`/my-apps/${service?.id}/api-keys`)}>
          Api keys &#187;
        </span>
      </ServiceTabsCont>
    </div>
  );
}
