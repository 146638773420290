import { login } from "apiServices/authService";
import { overviewPath } from "apiServices/paths";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Form, Icon } from "shared/components";
import {
  ActionButton,
  ComponentBody,
  ComponentFooter,
  FormElement,
  StyledLink,
  Title,
  FormRow,
  FormRowLeft,
  FormRowRight,
  BackImage,
  Back,
  PasswordCont,
} from "./Styles";
import api from "shared/utils/api";
import logger from "shared/utils/logger";
import { getClientDetails } from "shared/utils/location";
import { setCurrentUser } from "store/actions/userAction";
import {
  setBusinessLists,
  setCurrentBusiness,
} from "store/actions/businessAction";
import { onChangeTextHandler, validations } from "shared/utils/formValidation";
import RadioButton from "shared/components/RadioButton";
import BackLogo from "App/assets/images/Auth/Back.svg";
import consoleLog from "shared/utils/consoleLog";
import RememberYou from "../RememberYou";
import AnswerQuestions from "../AnswerQuestions";
import NewEmailAddress from "../NewEmailAddress";
import SetForgotPassword from "../SetForgotPassword";
import LoginFace2 from "shared/components/LoginFace/index2";

const AccountRecoveryLogin = () => {
  const [fstate, setFstate] = useState({} as any);
  const [isLoginIn, setIsLoginIn] = useState(false);
  const [newPage, setNewPage] = useState<any>();
  const [formstate, setFormstate] = useState<any>({});

  const history = useHistory();
  const dispatch = useDispatch();

  const inputArray = [
    {
      id: "optRememberEmail",
      title: "",
      type: "radio",
      options: [
        {
          label: "I can remember my email address",
          value: 1,
        },
        {
          label: "I cannot remember or I don't have access to my email address",
          value: 2,
        },
      ],
    },
  ];

  const handleBack = () => {
    history.goBack();
  };

  const displayFormInput = () => {
    return inputArray.map((elm, idx) => {
      switch (elm.type) {
        case "radio":
          return (
            <FormRow>
              {/* <FormRowLeft>{elm?.title}</FormRowLeft> */}
              <FormRowRight>
                {elm?.options?.map((item: any) => (
                  <RadioButton
                    label={item.label}
                    // checked={fstate[elm.id]===item}
                    value={item.value}
                    checked={item.value === fstate[elm.id]}
                    id={item.label}
                    group={elm?.id}
                    onChange={(val: any) => {
                      onChangeTextHandler(
                        item.value,
                        elm.id,
                        fstate,
                        setFstate
                      );
                    }}
                  />
                ))}
              </FormRowRight>
            </FormRow>
          );
      }
    });
  };

  const onSubmit = () => {
    // setIsLoginIn(true);
    if (fstate?.optRememberEmail === 1) {
      history.push("/authenticate/reset-password");
    } else {
      setNewPage(fstate?.optRememberEmail);
    }
  };

  const switchNewPages = () => {
    switch (newPage) {
      case 2:
        return (
          <RememberYou
            opt={fstate?.optRememberEmail}
            setNewPage={setNewPage}
            formstate={formstate}
            setFormstate={setFormstate}
          />
        );
      case 3:
        return (
          <AnswerQuestions
            opt={newPage}
            setNewPage={setNewPage}
            formstate={formstate}
            setFormstate={setFormstate}
          />
        );
      case 4:
        return (
          <NewEmailAddress
            opt={newPage}
            setNewPage={setNewPage}
            formstate={formstate}
            setFormstate={setFormstate}
          />
        );

      case 5:
        return (
          <SetForgotPassword
            opt={newPage}
            setNewPage={setNewPage}
            formstate={formstate}
            setFormstate={setFormstate}
          />
        );

      default:
        return (
          <ComponentBody>
            <Back onClick={handleBack}>
              <BackImage src={BackLogo} />
            </Back>
            <Title>Account Recovery</Title>
            {/* @ts-ignore */}
            <Form enableReinitialize onSubmit={() => {}}>
              <FormElement>
                {displayFormInput()}
                <ActionButton
                  type="button"
                  variant="primary"
                  disabled={!fstate?.optRememberEmail}
                  isWorking={isLoginIn}
                  onClick={onSubmit}
                >
                  Continue
                </ActionButton>
              </FormElement>
            </Form>
          </ComponentBody>
        );
    }
  };

  return <LoginFace2 chgValue={Math.random()}>{switchNewPages()}</LoginFace2>;
};

export default AccountRecoveryLogin;
