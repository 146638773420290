import { color } from "shared/utils/styles";
import styled from "styled-components";

type TabPropT = {
  isactive: boolean;
};

export const SubHeader = styled.div`
  height: 72px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.05);
  margin-bottom: 50px;
  padding-left: 20px;
  padding-right: 20px;
`;

export const SubHeaderChildren = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const SubHeaderChildren2 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 580px;
`;

export const StateContainer = styled.div`
  width: 100%;
  margin-top: 1rem;
  padding: 2rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${color.backgroundMedium};
  border-radius: 1rem;
`;

export const PanelContainer = styled.div`
  width: 80%;
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-top: 1.8rem;
  margin-bottom: 2.4rem;
`;

export const PaneParent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const AssignBtn = styled.button`
  padding: 0.6rem 1rem;
  border-radius: 0.4rem;
  color: #fff;
  background-color: #0e314c;
  cursor: pointer;
`;
export const Tab = styled.span<TabPropT>`
  padding: 0.2rem 0;
  border-bottom: ${(props) => (props.isactive ? "1.4px solid #760000" : "")};
  color: ${(props) => (props.isactive ? "#760000" : "")};
  cursor: ${(props) => (props.isactive ? "" : "Pointer")};
`;

export const ActionsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1.4rem;
  justify-content: flex-end;
`;

export const ActionTab = styled.div`
  display: flex;
  align-items: center;
  gap: 0.4rem;
  font-size: 0.88rem;
  margin-left: 1.4rem;
`;

export const CreateCatContainer = styled.div`
  background: #fbfbfb;
  width: 50rem;
  padding: 2.5rem;
  min-height: 40vh;
  border-radius: 1rem;

  h2 {
    font-size: 1.5rem;
    margin-bottom: 0.2rem;
  }
  p {
    font-size: 0.88rem;
    color: #828282;
  }
`;

export const DeleteCatContainer = styled.div`
  background: #fbfbfb;
  width: 50rem;
  /* padding: 2.5rem; */
  min-height: 34vh;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const DeleteContent = styled.div`
  h2 {
    font-size: 1.5rem;
    margin-bottom: 0.2rem;
    width: 80%;
    margin: auto;
    text-align: center;
  }
  p {
    font-size: 0.88rem;
    color: #828282;
  }
`;

export const DeleteBtnContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  gap: 2rem;
  button {
    padding: 0.88rem 0rem;
    color: #fff;
    border-radius: 0.2rem;
    background-color: #760000;
    font-weight: 800;
    cursor: pointer;
    border: none;
    height: 3.2rem;
    min-width: 8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    /* width: 40%; */

    :disabled {
      background-color: #d7d7d7;
      cursor: default;
    }
  }
`;

export const NoBtn = styled.button`
  padding: 0.88rem 0rem;
  color: #fff;
  border-radius: 0.2rem;
  background-color: #333 !important;
  font-weight: 800;
  cursor: pointer;
  border: none;
  height: 3.2rem;
  min-width: 8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 40%; */

  :disabled {
    background-color: #d7d7d7;
    cursor: default;
  }
`;

export const CCFormContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  margin-top: 2rem;
`;

export const CategoryForm = styled.form`
  width: 68%;
  /* border: 1px solid red; */
  p {
    color: #292929;
    margin-bottom: 0.5rem;
  }

  input[type="text"] {
    width: 100%;
    padding: 1rem 0.4rem;
    background: #fff;
    /* outline: none; */
    border: 1px solid #d7d7d7;
    border-radius: 0.2rem;

    ::placeholder {
      font-size: 0.88rem;
    }
    :focus {
      border-color: #d7d7d7;
      box-shadow: 0 0 5px rgba(0, 123, 255, 0.4);
    }
  }

  input[type="checkbox"] {
    background-color: initial !important;
    cursor: default !important;
    appearance: auto !important;
    box-sizing: border-box !important;
    margin: 3px 3px 3px 4px !important;
    padding: initial !important;
    border: initial !important;
  }

  button {
    padding: 0.88rem 0rem;
    color: #fff;
    border-radius: 0.2rem;
    background-color: #760000;
    font-weight: 800;
    cursor: pointer;
    border: none;
    height: 3.2rem;
    min-width: 8rem;
    display: flex;
    align-items: center;
    justify-content: center;

    :disabled {
      background-color: #d7d7d7;
      cursor: default;
    }
  }
`;

export const AssetsForm = styled.form`
  width: 100%;
  margin-top: 1rem;
  /* border: 1px solid red; */
  p {
    color: #292929;
    margin-bottom: 0.5rem;
  }

  input {
    width: 100%;
    padding: 1rem 0.4rem;
    background: #fff;
    /* outline: none; */
    border: 1px solid #d7d7d7;
    border-radius: 0.2rem;

    ::placeholder {
      font-size: 0.88rem;
    }
    :focus {
      border-color: #d7d7d7;
      box-shadow: 0 0 5px rgba(0, 123, 255, 0.4);
    }
  }

  textarea {
    /* width: 100%; */
    padding: 1rem 0.4rem;
    background: #fff;
    min-height: 8rem !important;
    /* outline: none; */
    border: 1px solid #d7d7d7;
    border-radius: 0.2rem;

    ::placeholder {
      font-size: 0.88rem;
    }
    :focus {
      border-color: #d7d7d7;
      box-shadow: 0 0 5px rgba(0, 123, 255, 0.4);
    }
  }

  button {
    padding: 0.88rem 0rem;
    color: #fff;
    border-radius: 0.2rem;
    background-color: #760000;
    font-weight: 800;
    cursor: pointer;
    border: none;
    height: 3.2rem;
    min-width: 8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    /* width: 40%; */

    :disabled {
      background-color: #d7d7d7;
      cursor: default;
    }
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
`;

export const TableActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5rem;
`;

export const PlanStatus = styled.span`
  display: block;
  width: 10%;
`;

export const GroupedBtn = styled.div`
  display: flex;
  gap: 2rem;
  align-items: center;
  align-content: center;
`;

export const EditBtn = styled.span`
  /* background: ${color.fountain}; */
  background: #f1e6e6;
  color:#000;
  /* color: ${color.textDark}; */
  border-radius: 8rem;
  padding: 0.6rem 1rem;
  font-size: 12px;
  cursor: pointer;
`;

export const PageHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const InputGroup = styled.div`
  width: 80%;
  display: flex;
  flex-direction: "row";
  gap: 2rem;
  /* justify-content: space-between; */
  align-items: center;
  /* flex-wrap: wrap; */
  /* border: 1px solid red; */
  margin-bottom: 4rem;

  div {
    width: 100%;
  }
`;

export const Dropdown = styled.div`
  position: relative;
  display: block;
  width: 100%;
  border: 1px solid #d7d7d7;
  background: #ffffff;
  border-radius: 5px;
  &:hover {
    transition: ease-in-out 0.5s;
    border: 1px solid lightblue;
  }
  &:focus {
    border: 1px solid lightblue;
  }
`;

export const DropdownBtn = styled.div`
  display: flex;
  justify-content: flex-end;
  height: 54px;
  width: 100%;
  background-color: #fff;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
`;

export const DropdownContent = styled.div`
  width: 100%;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.9);
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(255, 255, 255, 0.9);
  z-index: 999;
  height: 200px;
  overflow: hidden;
  overflow-y: scroll;
`;

export const DropdownItem = styled.div`
  color: black;
  padding: 12px 16px;
  display: block;
  cursor: pointer;
  border-bottom: 0.5px solid #d7d7d7;
  :hover {
    background-color: #f0f1f7;
  }
`;

export const DropdownText = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #292929;
`;

export const AgentListContainer = styled.div`
  width: 100%;
  margin-top: 2rem;
  min-height: 2rem;
  max-height: 10rem;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const AgentListRow = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border: 0.5px solid #828282;
  padding: 1rem 0.8rem;
  border-radius: 0.2rem;
  margin-bottom: 0.8rem;
`;
