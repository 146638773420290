export const testMobileNumber = (val: string) => {
  return /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(
    val
  );
};

export const testAcctNumber = (val: string) => {
  return /^\d{10}$/.test(val);
};

export const testEmail = (val: string) => {
  return /.+@.+\..+/.test(val); // && val.indexOf("+") < 0;
};

export const testAmount = (val: string) => {
  // return /^[0-9]*$/.test(val);
  return /^\d*\.?\d*$/.test(val);
};

export const testUrl = (val: string) => {
  if (val.trim() === "") return true;
  return /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/.test(
    val
  );
};

export const validations = (inputArray: any, Form: any) => {
  let obj: any = {};
  for (let elm of inputArray) {
    let val: any = "";
    switch (elm.type) {
      case "text":
        val = Form.is.required();
        break;
      case "email":
        val = [
          Form.is.required(),
          Form.is.match(testEmail, "Invalid Email Address"),
        ];
        break;
      case "tel":
        val = [
          Form.is.required(),
          Form.is.match(testMobileNumber, "Invalid Phone Number"),
        ];
        break;
      case "amount":
        val = [Form.is.required(), Form.is.match(testAmount, "Invalid Amount")];
        break;
      case "url":
        val = [Form.is.match(testUrl, "Invalid Url")];
        // val = [Form.is.required(), Form.is.match(testUrl, "Invalid Url")];
        break;
      case "acctNum":
        val = [
          Form.is.required(),
          Form.is.match(testAcctNumber, "Invalid Account Number"),
        ];
        break;
    }
    if (val === "") continue;
    obj[elm.id] = val;
  }
  return obj;
};

export const internalValidations = (inputArray: any, formstate: any) => {
  if (!formstate) return [];
  let obj: any = {};
  for (let elm of inputArray) {
    let val: any = "";
    switch (elm.type) {
      case "select":
      case "file":
      case "text":
        val = formstate[elm.id] && String(formstate[elm.id]).trim() !== "";
        break;
      case "url":
        val = formstate[elm.id] && testUrl(String(formstate[elm.id]).trim());
        break;
      case "amount":
        val = formstate[elm.id] && testAmount(String(formstate[elm.id]).trim());
        break;
      case "email":
        val =
          formstate[elm.id] &&
          String(formstate[elm.id]).trim() !== "" &&
          testEmail(String(formstate[elm.id]).trim());
        break;
      case "tel":
        val =
          formstate[elm.id] &&
          String(formstate[elm.id]).trim() !== "" &&
          testMobileNumber(String(formstate[elm.id]).trim());

        break;
      case "acctNum":
        val =
          formstate[elm.id] &&
          String(formstate[elm.id]).trim() !== "" &&
          testAcctNumber(String(formstate[elm.id]).trim());
        break;
      case "conditionalselect":
        val = formstate[elm.id] && String(formstate[elm.id]).trim() !== "";
        break;
    }
    if (val === "") continue;
    obj[elm.id] = val;
  }
  return obj;
};

export const onChangeTextHandler = (
  val: any,
  name: any,
  formstate: any,
  setFormstate: any
) => {
  let obj: any = {};
  obj[name] = val;
  setFormstate({ ...formstate, ...obj });
};

export function isFileImage(file: any) {
  return file && file["type"].split("/")[0] === "image";
}
