import { useState, useEffect } from "react";
import {
  ParentCont,
  LeftPanel,
  RightPanel,
  RightPanelAuxCont,
  CloseBtn,
  DescriptionBox,
  RightPanelAuxCon2,
  DescriptionTitle,
  DescriptionText,
  DescriptionBtn,
  FormCont,
  TitleCont,
  Back,
  FormTitle,
  FormSubTitle,
  FormElement,
  Selectors,
  ActionButton,
  MiddlePanel,
  UploadContainer,
  UploadLabel,
  UploadBox,
  UploadInput,
  UploadButton,
  FieldError,
  TrashBtn,
  UploadedFile,
  HeadingCont,
  DownldCnt,
} from "./Styles";
import { Icon, Form } from "shared/components";
import { useHistory } from "react-router-dom";
import api from "shared/utils/api";
import {
  getStoreLocalStorage,
  omniGetter,
  omniRemover,
  omniSaver,
} from "shared/utils/authToken";
import toast from "shared/utils/toast";
import { useDispatch } from "react-redux";
import PageIndicator from "shared/components/PageIndicator";
import { AgencyBankingPages } from "../../PageList";
import { uploadFile } from "apiServices/authService";
import consoleLog from "shared/utils/consoleLog";
import { RootStateOrAny, useSelector } from "react-redux";
import { setKycBoardingRec } from "store/actions/boardingAction";
import checkExistingKycBoarding from "shared/utils/checkExistingKycBoarding";
import { shortenUrl } from "shared/utils/ellipsis";
import { BsCloudDownload } from "react-icons/bs";

function UploadDocuments() {
  const business = useSelector((store: RootStateOrAny) => store.business);
  const [fileObj, setFileObj] = useState<any>({});
  const kycBoardingRec = useSelector(
    (store: RootStateOrAny) => store.kycBoardingRec
  );
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [isUploadingPass, setIsUploadingPass] = useState<boolean>(false);
  const [isUploadingBill, setIsUploadingBill] = useState<boolean>(false);
  const [isUploadingGuarantor, setIsUploadingGuarantor] = useState<boolean>(
    false
  );
  const [isUploadingProof, setIsUploadingProof] = useState<boolean>(false);
  const [identityCard, seIdentityCard] = useState<string>(
    kycBoardingRec?.identityCard ?? ""
  );
  const [passport, setPassport] = useState<string>(
    kycBoardingRec?.passport ?? ""
  );
  const [bill, setBill] = useState<string>(kycBoardingRec?.bill ?? "");
  const [guarantor, setGuarantor] = useState<string>(
    kycBoardingRec?.guarantor ?? ""
  );
  const [proof, setProof] = useState<string>(kycBoardingRec?.proof ?? "");
  const [error, setError] = useState<any>({
    identityCard: false,
    passport: false,
    bill: false,
    guarantor: false,
    proof: false,
  });
  const kycStart = useSelector((store: RootStateOrAny) => store.kycStart);
  const [isDisabled, setDisabled] = useState(true);

  const updateUploadedFiles = (file: any, keyName: string) => {
    // // console.log("selected file", file);
    const { name } = file;
    let uploadedFileNames = Object.values(fileObj).filter(
      (el: any) => el !== keyName
    );
    // console.log("selected file", name);
    const fileNameExists = uploadedFileNames.includes(name);
    if (fileNameExists) {
      toast.error("File already uploaded");
      return false;
    } else {
      // uploadedFileNames.push(name);
      setFileObj({ ...fileObj, [keyName]: name });
      return true;
    }
  };

  useEffect(() => {
    if (identityCard && passport && bill && guarantor && proof) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [identityCard, passport, bill, guarantor, proof]);

  const initialValues = {
    accountNumber: "",
    bankName: "",
    bankAccountName: "",
  };

  const history = useHistory();

  const getUploadedDcoments = () => {};

  const validateUploads = () => {
    const errors = {} as any;
    if (!passport) {
      errors.passport = true;
    }
    if (!identityCard) {
      errors.identityCard = true;
    }
    if (!bill) {
      errors.bill = true;
    }
    if (!guarantor) {
      errors.guarantor = true;
    }
    if (!proof) {
      errors.proof = true;
    }
    if (Object.keys(errors).length > 0) {
      setError(errors);
      return true;
    }
    return false;
  };

  const onSubmit = async () => {
    setIsLoading(true);
    try {
      if (!business?.id) {
        business.id = kycBoardingRec?.businessId;
      }
      let findRecord = kycBoardingRec?.businessKycId
        ? { id: kycBoardingRec?.businessKycId }
        : business?.BusinessKyc instanceof Array &&
          business?.BusinessKyc.find(
            (el: any) => el?.type.toLowerCase() === "agency"
          );
      if (findRecord) {
        const res: any = await api.patch(
          `/business/${business.id}/kyc/${findRecord.id}`,
          {
            step: 3,
            status: "APPROVED",
            uploadMeta: {
              identityCard,
              passport,
              bill,
              guarantor,
              proof,
            },
          }
        );
        toast.success(res.message);
        await dispatch(
          setKycBoardingRec(
            kycBoardingRec instanceof Object && {
              ...kycBoardingRec,
              identityCard,
              passport,
              bill,
              guarantor,
              proof,
              businessId: business.id,
            }
          )
        );

        continueEvent();
      } else {
        toast.error(
          "Unable to to upload documentation, Kyc documentation not properly done."
        );
      }

      setIsLoading(false);
    } catch (error) {
      // @ts-ignore
      toast.error(error);
      // console.log(error);
      setIsLoading(false);
    }
  };

  const uploadIdentityCard = async (files: any) => {
    if (files[0].size > 5000000) {
      return toast.error(
        "Please slected file should have a maximum size of 5mb."
      );
    }
    if (!updateUploadedFiles(files[0], "identity")) return false;

    try {
      setIsUploading(true);
      let formData = new FormData();
      formData.append("certificate", files[0]);

      const response = await uploadFile(formData);
      consoleLog(response, "response");
      if (response.status === true) {
        const identity = response.payload?.fileUrl;
        // omniSaver("identity", identity);
        seIdentityCard(identity);
      }
    } catch (error) {
      // @ts-ignore
      toast.error(error);

      // consoleLog(error.toString());
    } finally {
      setIsUploading(false);
    }
  };

  const uploadPassport = async (files: any) => {
    if (files[0].size > 5000000) {
      return toast.error(
        "Please slected file should have a maximum size of 5mb."
      );
    }
    if (!updateUploadedFiles(files[0], "passport")) return false;

    try {
      setIsUploadingPass(true);
      let formData = new FormData();
      formData.append("certificate", files[0]);
      const response = await uploadFile(formData);
      if (response.status === true) {
        const passport = response.payload?.fileUrl;
        // omniSaver("passport", passport);
        setPassport(passport);
        setIsUploadingPass(false);
      }
    } catch (error) {
      // @ts-ignore
      toast.error(error);
      setIsUploadingPass(false);
      // consoleLog(error.toString());
    }
  };

  const uploadUtilityBill = async (files: any) => {
    if (files[0].size > 5000000) {
      return toast.error(
        "Please slected file should have a maximum size of 5mb."
      );
    }
    if (!updateUploadedFiles(files[0], "bill")) return false;

    try {
      setIsUploadingBill(true);
      let formData = new FormData();
      formData.append("certificate", files[0]);
      const response = await uploadFile(formData);
      if (response.status === true) {
        const utilityBill = response?.payload?.fileUrl;
        // omniSaver("utility_Bill", utilityBill);
        // localStorage.setItem("article", article);
        setBill(utilityBill);
        setIsUploadingBill(false);
      }
    } catch (error) {
      // @ts-ignore
      toast.error(error);
      setIsUploadingBill(false);
      // consoleLog(error.toString());
    }
  };

  const uploadGuarantorForm = async (files: any) => {
    if (files[0].size > 5000000) {
      return toast.error(
        "Please slected file should have a maximum size of 5mb."
      );
    }

    if (!updateUploadedFiles(files[0], "guarantor")) return false;

    try {
      setIsUploadingGuarantor(true);
      let formData = new FormData();
      formData.append("certificate", files[0]);
      const response = await uploadFile(formData);
      if (response.status === true) {
        const guarantor = response?.payload?.fileUrl;
        // consoleLog('uploaded guarntora', guarantor)
        // omniSaver("gurantor_form", guarantor);
        setGuarantor(guarantor);
        setIsUploadingGuarantor(false);
      }
    } catch (error) {
      // @ts-ignore
      toast.error(error);
      setIsUploadingGuarantor(false);
      // consoleLog(error.toString());
    }
  };

  const uploadProofOfIdentity = async (files: any) => {
    if (files[0].size > 5000000) {
      return toast.error(
        "Please slected file should have a maximum size of 5mb."
      );
    }

    if (!updateUploadedFiles(files[0], "proof")) return false;

    try {
      setIsUploadingProof(true);
      let formData = new FormData();
      formData.append("certificate", files[0]);
      const response = await uploadFile(formData);
      if (response.status === true) {
        const proof = response?.payload?.fileUrl;
        // omniSaver("proof_of_identity", proof);
        setProof(proof);
        setIsUploadingProof(false);
      }
    } catch (error) {
      // @ts-ignore
      toast.error(error);
      setIsUploadingProof(false);
      // consoleLog(error.toString());
    }
  };

  const continueEvent = () => {
    history.push("/kyc/complete");
  };

  const goBack = () => {
    history.push("/kyc/business-information");
  };

  const close = () => {
    history.push("/overview");
  };

  let urlAddress = history?.location?.pathname;

  return (
    <ParentCont>
      <LeftPanel>
        <FormCont>
          <TitleCont>
            {urlAddress !== kycStart && (
              <Back onClick={goBack}>
                <Icon type={"chevron-left"} size={20} />
              </Back>
            )}
            <FormTitle>Upload Documents</FormTitle>
          </TitleCont>

          <FormSubTitle>
            With your corporate account number, you are guaranteed of your
            settlement.
          </FormSubTitle>

          <Form
            enableReinitialize
            initialValues={initialValues}
            validations={{
              accountNumber: Form.is.required(),
              bankName: Form.is.required(),
              accountName: Form.is.required(),
            }}
            onSubmit={onSubmit}
            validate={() => {}}
            validateOnBlur={false}
          >
            <>
              <FormElement>
                <UploadContainer>
                  <UploadLabel>Idenity Card</UploadLabel>
                  <input
                    type="file"
                    onChange={(e) => uploadIdentityCard(e.target.files)}
                    name="identityCard"
                    id="identityCard"
                    disabled={isUploading}
                    hidden
                  />
                  <UploadBox>
                    <Icon
                      type={"upload"}
                      color={"#760000"}
                      style={{ marginRight: 10 }}
                    />
                    <UploadButton htmlFor="identityCard">
                      {isUploading ? "Loading" : `Click to Add files`}
                    </UploadButton>
                  </UploadBox>
                  {error.identityCard && (
                    <FieldError>Identy Card is required</FieldError>
                  )}
                </UploadContainer>
                {identityCard && (
                  <UploadInput>
                    <UploadedFile>{shortenUrl(identityCard)}</UploadedFile>

                    <TrashBtn onClick={() => seIdentityCard("")}>
                      <Icon type={"trash"} color={"black"} />
                    </TrashBtn>
                  </UploadInput>
                )}

                <UploadContainer>
                  <UploadLabel>Passport Photograph</UploadLabel>
                  <input
                    type="file"
                    onChange={(e) => uploadPassport(e.target.files)}
                    name="passport"
                    id="passport"
                    disabled={isUploadingPass}
                    hidden
                  />
                  <UploadBox>
                    <Icon
                      type={"upload"}
                      color={"#760000"}
                      style={{ marginRight: 10 }}
                    />
                    <UploadButton htmlFor="passport">
                      {isUploadingPass ? "Loading" : `Click to Add files`}
                    </UploadButton>
                  </UploadBox>
                  {error.passport && (
                    <FieldError>Passport Photograph is required</FieldError>
                  )}
                </UploadContainer>
                {passport && (
                  <UploadInput>
                    <UploadedFile>{shortenUrl(passport)}</UploadedFile>

                    <TrashBtn onClick={() => setPassport("")}>
                      <Icon type={"trash"} color={"black"} />
                    </TrashBtn>
                  </UploadInput>
                )}

                <UploadContainer>
                  <UploadLabel>Utility Bill</UploadLabel>
                  <input
                    type="file"
                    onChange={(e) => uploadUtilityBill(e.target.files)}
                    name="bill"
                    id="bill"
                    disabled={isUploadingBill}
                    hidden
                  />
                  <UploadBox>
                    <Icon
                      type={"upload"}
                      color={"#760000"}
                      style={{ marginRight: 10 }}
                    />
                    <UploadButton htmlFor="bill">
                      {isUploadingBill ? "Loading" : `Click to Add files`}
                    </UploadButton>
                  </UploadBox>
                  {error.bill && (
                    <FieldError>Utility Bill is required</FieldError>
                  )}
                </UploadContainer>
                {bill && (
                  <UploadInput>
                    <UploadedFile>{shortenUrl(bill)}</UploadedFile>

                    <TrashBtn onClick={() => setBill("")}>
                      <Icon type={"trash"} color={"black"} />
                    </TrashBtn>
                  </UploadInput>
                )}

                <UploadContainer>
                  <DownldCnt>
                    <UploadLabel>Guarantor Form</UploadLabel>
                    <HeadingCont>
                      <a
                        href="https://bringforthjoy.s3.us-east-2.amazonaws.com/FP-Documents/CHARACTER+CONFIRMATION+FORM+%40.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <BsCloudDownload
                          style={{
                            marginLeft: 10,
                            color: "#760000",
                            cursor: "pointer",
                          }}
                        />
                      </a>
                    </HeadingCont>
                  </DownldCnt>

                  <input
                    type="file"
                    onChange={(e) => uploadGuarantorForm(e.target.files)}
                    name="guarantor"
                    id="guarantor"
                    disabled={isUploadingGuarantor}
                    hidden
                  />
                  <UploadBox>
                    <Icon
                      type={"upload"}
                      color={"#760000"}
                      style={{ marginRight: 10 }}
                    />
                    <UploadButton htmlFor="guarantor">
                      {isUploadingGuarantor ? "Loading" : `Click to Add files`}
                    </UploadButton>
                  </UploadBox>
                  {error.bill && (
                    <FieldError>Guarantor Form is required</FieldError>
                  )}
                </UploadContainer>
                {guarantor && (
                  <UploadInput>
                    <UploadedFile>{shortenUrl(guarantor)}</UploadedFile>

                    <TrashBtn onClick={() => setGuarantor("")}>
                      <Icon type={"trash"} color={"black"} />
                    </TrashBtn>
                  </UploadInput>
                )}

                <UploadContainer>
                  <UploadLabel>Proof of Identity</UploadLabel>
                  <input
                    type="file"
                    onChange={(e) => uploadProofOfIdentity(e.target.files)}
                    name="proof"
                    id="proof"
                    disabled={isUploadingProof}
                    hidden
                  />
                  <UploadBox>
                    <Icon
                      type={"upload"}
                      color={"#760000"}
                      style={{ marginRight: 10 }}
                    />
                    <UploadButton htmlFor="proof">
                      {isUploadingProof ? "Loading" : `Click to Add files`}
                    </UploadButton>
                  </UploadBox>
                  {error.proof && (
                    <FieldError>Proof Of Identity is required</FieldError>
                  )}
                </UploadContainer>
                {proof && (
                  <UploadInput>
                    <UploadedFile>{shortenUrl(proof)}</UploadedFile>

                    <TrashBtn onClick={() => setProof("")}>
                      <Icon type={"trash"} color={"black"} />
                    </TrashBtn>
                  </UploadInput>
                )}
              </FormElement>
              <Selectors>
                <ActionButton
                  onClick={onSubmit}
                  type="submit"
                  variant="primary"
                  isWorking={isLoading}
                  disabled={isDisabled}
                >
                  {checkExistingKycBoarding(
                    `identityCard,passport,bill,guarantor,proof`,
                    kycBoardingRec
                  )
                    ? "Update"
                    : "Save"}{" "}
                  and Continue
                </ActionButton>
              </Selectors>
            </>
          </Form>
        </FormCont>
      </LeftPanel>

      <MiddlePanel>
        <PageIndicator pageArr={AgencyBankingPages} page={3} />
      </MiddlePanel>

      <RightPanel>
        <RightPanelAuxCont>
          <CloseBtn onClick={close}>
            <Icon type={"close"} size={20} />
          </CloseBtn>
        </RightPanelAuxCont>

        <RightPanelAuxCon2>
          <DescriptionBox>
            <DescriptionTitle>Agency Banking</DescriptionTitle>

            <DescriptionText>
              Be ahead of late payment, switch to an instant epay platform.
            </DescriptionText>

            <DescriptionBtn
              onClick={() =>
                window.open(
                  "https://medium.com/@fountainpay",
                  "noopener,noreferrer"
                )
              }
            >
              Learn more
            </DescriptionBtn>
          </DescriptionBox>
        </RightPanelAuxCon2>
      </RightPanel>
    </ParentCont>
  );
}

export default UploadDocuments;
