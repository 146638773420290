import React, { useEffect, useState } from "react";
import { Button, Form } from "shared/components";
import {
  ActionButton,
  ComponentBody,
  ComponentFooter,
  FormElement,
  StyledLink,
  Title,
} from "./Styles";
import { getStoredAuthToken, storeAuthToken } from "shared/utils/authToken";
import toast from "shared/utils/toast";
import { useHistory } from "react-router-dom";
import api from "shared/utils/api";
import { authPath } from "apiServices/paths";
import consoleLog from "shared/utils/consoleLog";

const ResetPassword = () => {
  const [isLoginIn, setIsLoginIn] = useState(false);
  const history = useHistory();

  const onSubmit = async (values: any, form: any) => {
    consoleLog(values);
    setIsLoginIn(true);

    try {
      const { payload }: any = await api.post("", {
        ...values,
      });
      consoleLog("reset password screen", payload);
      localStorage.setItem("tempId", payload.tempId);
      setIsLoginIn(false);
      // toast.success("Login was successful.");
      history.push(authPath.RESET_OTP);
    } catch (error) {
      // toast.error(error);
      setIsLoginIn(false);
    }
  };
  return (
    <ComponentBody>
      <Title>RESET PASSWORD</Title>
      {/* @ts-ignore */}
      <Form
        enableReinitialize
        initialValues={{
          email: "",
        }}
        validations={{
          email: [Form.is.required(), Form.is.email()],
        }}
        onSubmit={onSubmit}
      >
        <FormElement>
          <Form.Field.Input
            name="email"
            label="Enter Email address"
            // tip="Kindly enter your user email address."
          />

          <ActionButton type="submit" variant="primary" isWorking={isLoginIn}>
            Send Request
          </ActionButton>
        </FormElement>
      </Form>
    </ComponentBody>
  );
};

export default ResetPassword;
