import styled from "styled-components";

import { color, font } from "shared/utils/styles";
import { Button, Form } from "shared/components";

export const ReportBody = styled.div`
  text-align: center;
  margin: 0 auto;
  height: 100%;
  // width: 100%;
`;

export const Title = styled.h1`
  color: #292929;
  padding: 10px 0;
  ${font.size(40)}

`;

export const imagestyle = {
  width: "40px",
};

export const SubTitle = styled.p`
  color: #828282;
  ${font.size(16)}
`;

export const ActionButton = styled(Button)`
  margin: 2em 0;
  color: #fff;
  width: 200px;
  background-color: ${color.fountain};
`;
