import { is } from "cypress/types/bluebird";
import e from "express";
import { map } from "lodash";
import React, { useState } from "react";
import { Input } from "shared/components";
import SharedCheckList from "shared/section_components/SharedCheckList";
import {
  SharedInput,
  SharedDisbaledInput,
} from "shared/section_components/ShareInput";
import {
  PageLayout,
  SectionHeader,
  SectionHeaderLabel,
  Sectionbutton,
} from "../../shared/section_components/Styles";
import { FormContainer } from "./Styles";
import consoleLog from "shared/utils/consoleLog";

export default function WebHook() {
  const [isChecked, setisChecked] = useState(true);
  let [data, setdata] = useState({
    options: [
      {
        id: 1,
        isChecked: true,
        label: "Recieve Webhook response in JSON format",
      },
      { id: 2, isChecked: true, label: "Enable Webhook retries" },
      { id: 3, isChecked: false, label: "Enable failed transaction Webhook" },
      { id: 4, isChecked: false, label: "Enable V3 Webhook" },
    ],
  });

  const handleChange = (id: number) => {
    setdata((prevstate) => ({
      options: prevstate.options.map((el) =>
        el.id === id ? { ...el, isChecked: !el.isChecked } : el
      ),
    }));
    consoleLog(data);
  };

  const checklists = data.options.map((item) => (
    <SharedCheckList
      key={item.id}
      onChange={handleChange}
      checked={item.isChecked}
      label={item.label}
      id={item.id}
    />
  ));
  return (
    <PageLayout>
      <SectionHeader>
        <SectionHeaderLabel>Live Web Hook</SectionHeaderLabel>
        <Sectionbutton>Save</Sectionbutton>
      </SectionHeader>
      <div style={{ marginTop: "2rem" }}>
        <form>
          <FormContainer>
            <SharedInput label="URL" onChange={() => consoleLog("")} />
            <SharedInput label="Secret Bash" onChange={() => consoleLog("")} />
            <br />
            {checklists}
          </FormContainer>
        </form>
      </div>
    </PageLayout>
  );
}
