export const authPath = {
  LOGIN: "/authenticate",
  SIGNUP: "/authenticate/signup",
  OXSIGNUP: "/authenticate/ox8086-signup",
  OTP: "/authenticate/otp",
  RESET_OTP: "/authenticate/otp-reset",
  SET_PASSWORD: "/authenticate/set-password",
  SET_FORGOT_PASSWORD: "/authenticate/set-forgot-password",
  RESET_PASSWORD: "/authenticate/reset-password",
  FORGOT_PASSWORD: "/authenticate/forgot-password'",
  OTP_FAILED: "/authenticate/otp-failed",
  OTP_RESET_FAILED: "/authenticate/otp-reset-failed",
  OTP_SUCCESS: "/authenticate/otp-success",
  OTP_RESET_SUCCESS: "/authenticate/otp-reset-success",
  PASSWORD_SUCCESS: "/authenticate/success",
  PASSWORD_RESET_SUCCESS: "/authenticate/password-success",
  ACCOUNT_RECOVERY: "/authenticate/account-recovery",
  ADD_NEW_MEMEBER: "/authenticate/invitation/:id",
  CREATE_MANDATE: "/authenticate/mandate/create-mandate",
  // REMEMBER_YOU: "/authenticate/remember-you",
};

export const overviewPath = {
  OVERVIEW: "/overview",
  KYC: "/kyc",
};
