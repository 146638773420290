import React, { useEffect, useState } from "react";
import { useSelector, RootStateOrAny } from "react-redux";
import {
  Container,
  FormContainer,
  Image,
  SubTitle,
  TitleCase,
  FormBody,
  ButtonContainer,
  Back,
  ActionButton,
  FormElement,
  ImageContainer,
  SubAgentForm,
  Input,
  InputGroup,
  InputLabel,
  FromGroup,
} from "./styles";
import { Form, Icon } from "shared/components";
import RightPic from "App/assets/images/services/agent.svg";
import api from "shared/utils/api";
import consoleLog from "shared/utils/consoleLog";
import toast from "shared/utils/toast";
import { PasswordCont } from "Auth/pages/Login/Styles";
import FirstPage from "./FirstPage";
import UploadDocuments from "./UploadDocuments";
import Contact from "./Contact";
import Nextofkin from "./Nextofkin";
import Pin from "./Pin";
import { useHistory, useParams } from "react-router-dom";
import { color } from "shared/utils/styles";

const AddSubAgent = () => {
  const business = useSelector((store: RootStateOrAny) => store.business);
  const [isLoginIn, setIsLoginIn] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(true);
  const [agentDetail, setAgentDetail] = useState<any>({});
  const [step, setStep] = useState(1);
  const [agentId, setAgentId] = useState("");

  type firstPageObjT = {
    bvn: string;
    first_name: string;
    last_name: string;
    email: string;
    phone: string;
    trade_name: string;
  };
  const [defaultValues, setDefaultValues] = useState<firstPageObjT>({
    bvn: "",
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    trade_name: ""
  });

  type secondPageObjT = {
    address: string;
    landmark: string;
    town: string;
    lga: string;
    state: string;
    country: string;
  };

  const [defaultContactValues, setDefaultContactValues] = useState<
    secondPageObjT
  >({
    address: "",
    landmark: "",
    town: "",
    lga: "",
    state: "",
    country: "",
  });

  const [initialNextValues, setinitialNextValues] = useState({
    name: "",
    gender: "",
    relationship: "",
    phone: "",
    email: "",
    address: "",
    landmark: "",
    town: "",
    lga: "",
    state: "",
    country: "",
  });

  const [initialDocValues, setinitialDocValues] = useState({
    identity_card: "",
    passport: "",
    utility_bill: "",
    guarantor_form: "",
    proof: "",
  });

  const [initialPinValues, setinitialPinValues] = useState({
    pin: "",
  });

  const params: any = useParams();

  const history = useHistory();

  const getAgentDetails = async () => {
    try {
      const res = await api.get(`/agency-banking/agents/${params.id}/detail`);
      // // console.log("Resp: ", res);
      if (res.status) {
        setAgentDetail(res.payload);
      }
    } catch (err) {
      const error: any = err;
      toast.error(error?.message);
    } finally {
    }
  };

  useEffect(() => {
    if (agentDetail) {
      setDefaultValues({
        bvn: agentDetail?.bvn || "",
        first_name: agentDetail?.firstName || "",
        last_name: agentDetail?.lastName || "",
        email: agentDetail?.email || "",
        phone: agentDetail?.phone || "",
        trade_name: agentDetail?.tradeName || ""
      });
      setDefaultContactValues({
        address: agentDetail?.address || "",
        landmark: agentDetail?.landmark || "",
        town: agentDetail?.town || "",
        lga: agentDetail?.lga || "",
        state: agentDetail?.state || "",
        country: agentDetail?.country || "",
      });
      setinitialNextValues({
        name: agentDetail?.agentKYC?.nextOfkin?.name || "",
        gender: agentDetail?.agentKYC?.nextOfkin?.gender || "",
        relationship: agentDetail?.agentKYC?.nextOfkin?.relationship || "",
        phone: agentDetail?.agentKYC?.nextOfkin?.phone || "",
        email: agentDetail?.agentKYC?.nextOfkin?.email || "",
        address: agentDetail?.agentKYC?.nextOfkin?.address || "",
        landmark: agentDetail?.agentKYC?.nextOfkin?.landmark || "",
        town: agentDetail?.agentKYC?.nextOfkin?.town || "",
        lga: agentDetail?.agentKYC?.nextOfkin?.lga || "",
        state: agentDetail?.agentKYC?.nextOfkin?.state || "",
        country: agentDetail?.agentKYC?.nextOfkin?.country || "",
      });
      setinitialPinValues({
        pin: agentDetail?.pin || "",
      });
      setinitialDocValues({
        identity_card: agentDetail?.agentKYC?.identityCard || "",
        passport: agentDetail?.agentKYC?.passport || "",
        utility_bill: agentDetail?.agentKYC?.utilityBill || "",
        guarantor_form: agentDetail?.agentKYC?.guarantorForm || "",
        proof: agentDetail?.agentKYC?.proof || "",
      });
    }
  }, [agentDetail]);

  useEffect(() => {
    if (params?.id) {
      setAgentId(params.id);
      getAgentDetails();
    }
  }, []);

  useEffect(() => {
    if (step > 5) {
      history.push("/agency/agents");
    }
  }, [step]);

  return (
    <Container>
      <FormContainer>
        {step == 1 && (
          <FirstPage
            agentId={agentId}
            businessId={business.id}
            setAgentId={setAgentId}
            initialValues={defaultValues}
            setInitialValues={setDefaultValues}
            step={step}
            setStep={setStep}
          />
        )}
        {step == 2 && (
          <Contact
            agentId={agentId}
            initialValues={defaultContactValues}
            setInitialValues={setDefaultContactValues}
            step={step}
            setStep={setStep}
          />
        )}
        {step == 3 && (
          <Nextofkin
            agentId={agentId}
            initialValues={initialNextValues}
            setInitialValues={setinitialNextValues}
            step={step}
            setStep={setStep}
          />
        )}
        {step == 4 && (
          <UploadDocuments
            agentId={agentId}
            initialValues={initialDocValues}
            step={step}
            setStep={setStep}
          />
        )}
        {step == 5 && (
          <Pin
            agentId={agentId}
            initialValues={initialPinValues}
            step={step}
            setStep={setStep}
          />
        )}
      </FormContainer>

      <ImageContainer>
        <Image src={RightPic} alt="image" />
      </ImageContainer>
    </Container>
  );
};

export default AddSubAgent;
