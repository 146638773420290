import { Icon } from "shared/components";
import {
  Content,
  ContentRow,
  EditContainer,
} from "shared/section_components/Styles";

type AccountRowProp = {
  acct: {
    id: number;
    bankName: string;
    bankAccountName: string;
    accountNumber: string;
    currency: string;
    bank: any;
  };
  removeAccount: Function;
  editAccount: Function;
};

export default function AccountRow({
  acct,
  removeAccount,
  editAccount,
}: AccountRowProp) {
  return (
    <ContentRow>
      <Content>{acct?.bank?.name}</Content>
      <Content>{acct.accountNumber}</Content>
      <Content>{acct.bankAccountName}</Content>
      <Content>NGN</Content>
      <Content>
        <EditContainer onClick={() => editAccount(acct.id)}>
          <Icon type="pencil" color="green" />
          <span style={{ marginLeft: "0.4rem", color: "green" }}>Edit</span>
        </EditContainer>
      </Content>
      <Content>
        <EditContainer onClick={() => removeAccount(acct.id)}>
          <Icon type="trash" color="red" />
          <span style={{ marginLeft: "0.4rem" }}>Remove</span>
        </EditContainer>
      </Content>
    </ContentRow>
  );
}
