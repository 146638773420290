import api from "shared/utils/api";
import { getCurrentBusiness } from "shared/utils/businessesData";
import toast from "shared/utils/toast";
import consoleLog from "shared/utils/consoleLog";

class Subaccount {
  async getSubaccounts(id: any) {
    try {
      const res = await api.get(`/client/business/${id}/sub-accounts`);
      return res;
    } catch (error: any) {
      consoleLog(error);
      toast.error(error?.message);
    }
  }

  async getAllBanks() {
    try {
      const res = await api.get("/checkout/bank");
      //// console.log('res.payload', res.payload)
      return res.payload;
    } catch (error: any) {
      consoleLog(error);
      toast.error(error?.message);
    }
  }

  async getAccountName(accountNumber: string, bankCode: string) {
    try {
      const res = await api.post("/checkout/bank/verify-account", {
        accountNumber: accountNumber,
        bankCode: bankCode,
      });

      return res.payload;
    } catch (error: any) {
      consoleLog(error, "Something went wrong, can't fetch list of banks");
      // @ts-ignore
      toast.error(error?.message);
      // @ts-ignore
      throw new Error(error);
    }
  }

  async createSubAccount(data: any) {
    const businessID = getCurrentBusiness().id;
    consoleLog("current business id", businessID);
    try {
      const res = await api.post(
        `/client/business/${businessID}/sub-accounts`,
        {
          name: data.name,
          // bankName: data.bankName,
          accountNumber: data.accountNumber,
          // bank_account_name: data.accountName,
          // bankCode: data.bankCode,
          bankAccountName: data.bankAccountName,
          currency: "NGN",
          type: "SUB_ACCOUNT",
          bankId: data.bankId,
        }
      );
      return res?.message;
    } catch (error: any) {
      toast.error(error?.message ?? "Subaccount not created, please try again.");
      consoleLog("SUB ACCOUNT", error);
    }
  }
}

const subaccountClass = new Subaccount();
export default subaccountClass;
