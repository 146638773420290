import React, { useEffect, useState } from "react";
import {
  AssetsForm,
  AssignBtn,
  CCFormContainer,
  InputGroup,
  PageHeader,
  PaneParent,
  PanelContainer,
  Tab,
} from "../Styles";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { GradientLoader, Icon, Input, Textarea } from "shared/components";
import { InventoryService } from "../inventory-service";
import Table from "shared/components/Table";
import AssignAsset from "./assign-asset";
import api from "shared/utils/api";
import {
  currencyFormat,
  formatDateTime,
  formateDate,
  formateDateString,
  numFormatter,
} from "shared/utils/utility";

export default function AssetsOverview() {
  const location: any = useLocation();
  const data = location.state && location.state.data;
  const [currentTab, setcurrentTab] = useState("details");
  const [openAssignedModal, setopenAssignedModal] = useState(false);

  const history = useHistory();
  const param: any = useParams();
  const assetId = param.id;

  const AssetDetails = () => {
    const [isloading, setisloading] = useState(true);
    const [assetObj, setassetObj] = useState<any>({});

    const fetchAssetDetails = async () => {
      const response = await InventoryService.GetAsset(assetId);
      if (response.status) {
        const {
          name,
          cost,
          quantity,
          description,
          Category,
        } = response.payload;

        setisloading(false);
        const obj = {
          name,
          category: Category.name ?? "",
          cost,
          quantity,
          description,
        };
        setassetObj(obj);
      } else {
        setisloading(false);
      }
      // // console.log("asset info", response);
    };

    useEffect(() => {
      fetchAssetDetails();
    }, []);

    return (
      <div>
        {isloading ? (
          <GradientLoader />
        ) : (
          <div>
            <CCFormContainer>
              <AssetsForm>
                <InputGroup>
                  <div>
                    <p>Name</p>
                    <Input
                      type="text"
                      name="input"
                      value={assetObj.name ?? ""}
                      placeholder="Asset name"
                      onChange={(value: any) => ""}
                      styleContainer={{ marginBottom: 0 }}
                    />
                  </div>
                  <div>
                    <p>Category</p>
                    <Input
                      type="text"
                      name="input"
                      value={assetObj.category ?? ""}
                      placeholder="Category"
                      onChange={(value: any) => ""}
                      styleContainer={{ marginBottom: 0 }}
                    />
                  </div>
                </InputGroup>

                <InputGroup>
                  <div>
                    <p>Cost</p>
                    <Input
                      type="text"
                      name="input"
                      value={assetObj.cost ?? ""}
                      placeholder="Asset cost"
                      onChange={(value: any) => ""}
                      styleContainer={{ marginBottom: 0 }}
                    />
                  </div>
                  <div>
                    <p>Quantity</p>
                    <Input
                      type="text"
                      name="input"
                      value={assetObj.quantity ?? ""}
                      placeholder=""
                      onChange={(value: any) => ""}
                      styleContainer={{ marginBottom: 0 }}
                    />
                  </div>
                </InputGroup>

                <InputGroup>
                  <div>
                    <p>Description</p>
                    <Textarea value={assetObj.description ?? ""} />
                  </div>
                </InputGroup>
              </AssetsForm>
            </CCFormContainer>
          </div>
        )}
      </div>
    );
  };

  const AssetHistory = () => {
    return <div>history</div>;
  };

  const AssetAssignee = () => {
    const [agents, setagents] = useState<any>([]);
    const [isAgentsloading, setisAgentsloading] = useState(false);

    const headData: any = [
      { id: "agentName", label: "Agent Name" },
      // { id: "location", label: "Location" },
      { id: "category", label: "Category" },
      { id: "asset", label: "Asset" },
      { id: "quantity", label: "Quantity" },
      { id: "cost", label: `Cost(${"NGN"})` },
      { id: "date", label: `Date Assigned` },
    ];

    const fetchAgents = async () => {
      if (isAgentsloading) return;
      setisAgentsloading(true);
      try {
        // const response = await InventoryService.GetAgentsAssigned(assetId);
        const response = await api.get(
          `/agency-banking/asset/agents/${assetId}`
        );

        if (response?.status) {
          const data = Object.values(response.payload);
          const transformed =
            data.length &&
            data.map((obj: any) => {
              return {
                agentName: obj?.agent,
                category: obj?.category,
                asset: obj?.asset,
                location: "--",
                quantity: obj.quantity,
                cost: currencyFormat(obj?.cost, ""),
                date: formatDateTime(obj?.dateAssigned),
              };
            });

          setagents(transformed);
        }
      } catch (error) {
        // // console.log(error);
      } finally {
        setisAgentsloading(false);
      }
    };

    useEffect(() => {
      fetchAgents();
    }, []);

    return (
      <div>
        {isAgentsloading ? (
          <GradientLoader />
        ) : (
          <Table
            headData={headData}
            bodyData={agents}
            hoverLink
            serialNumber
            clickFunction={() => ""}
            totalPages={0}
            currentpage={0}
            emptyStateMessage="No Agents"
          />
        )}

        {openAssignedModal && (
          <AssignAsset
            isopen={openAssignedModal}
            setisOpen={() => setopenAssignedModal(false)}
            data={data}
            apiCall={fetchAgents}
          />
        )}
      </div>
    );
  };

  return (
    <div>
      <PageHeader>
        <div onClick={() => history.goBack()}>
          <Icon
            type="arrow-left-circle"
            size="20"
            style={{ marginTop: "4px", cursor: "Pointer" }}
          />
        </div>
        <h2>Assets</h2>
      </PageHeader>

      <PaneParent>
        <PanelContainer>
          <Tab
            isactive={currentTab === "details" ? true : false}
            onClick={() => setcurrentTab("details")}
          >
            Details
          </Tab>
          <Tab
            isactive={currentTab === "history" ? true : false}
            onClick={() => setcurrentTab("history")}
          >
            History
          </Tab>

          <Tab
            isactive={currentTab === "assignee" ? true : false}
            onClick={() => setcurrentTab("assignee")}
          >
            Users/Assignee
          </Tab>
        </PanelContainer>
        {currentTab === "assignee" && (
          <AssignBtn onClick={() => setopenAssignedModal(true)}>
            Assign
          </AssignBtn>
        )}
      </PaneParent>

      {currentTab === "details" && <AssetDetails />}
      {currentTab === "history" && <AssetHistory />}
      {currentTab === "assignee" && <AssetAssignee />}
    </div>
  );
}
