export const emailFormat = (payload: any) => {
  let arr: any = ["email", "supportEmail"];
  if (payload instanceof Object) {
    for (let el of arr) {
      if (payload[el]) {
        payload[el] = payload[el]
          ?.toString()
          .trim()
          .toLowerCase();
      }
    }
  }

  return payload;
};
