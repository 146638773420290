import { useState, useEffect, useRef } from "react";
import {
  ParentCont,
  LeftPanel,
  RightPanel,
  RightPanelAuxCont,
  CloseBtn,
  DescriptionBox,
  RightPanelAuxCon2,
  DescriptionTitle,
  DescriptionText,
  DescriptionBtn,
  FormCont,
  TitleCont,
  Back,
  FormTitle,
  FormSubTitle,
  FormElement,
  Selectors,
  ActionButton,
  GroupInput,
} from "./Styles";
import { Icon, Form, GradientLoader } from "shared/components";
import { useHistory } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";
import { Industries } from "./industries";
import api from "shared/utils/api";
import { omniSaver, storeToLocalStorage } from "shared/utils/authToken";
import toast from "shared/utils/toast";
import consoleLog from "shared/utils/consoleLog";
import Preloader from "shared/components/preloader";
import {
  setBusinessLists,
  setCurrentBusiness,
} from "store/actions/businessAction";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { setKycBoardingRec } from "store/actions/boardingAction";
import checkExistingKycBoarding from "shared/utils/checkExistingKycBoarding";
const categoryOptions = Object.values(Industries).map((role) => ({
  value: role,
  label: role,
}));

function BusinessDetails() {
  const bottomDiv = useRef(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selExtend, setSelExtend] = useState<boolean>(false);
  const kycBoardingRec = useSelector(
    (store: RootStateOrAny) => store.kycBoardingRec
  );
  const [regtype, setRegtype] = useState<string>(kycBoardingRec?.regtype ?? "");
  const [rcNumber, setRcNumber] = useState<string>(
    kycBoardingRec?.rcNumber ?? ""
  );
  const [rcBusinessName, setRcBusinessName] = useState<string>(
    kycBoardingRec?.rcBusinessName ?? ""
  );
  const [industryType, setIndustryType] = useState<string>(
    kycBoardingRec?.industryType ?? ""
  );
  const [nameLoading, setNameLoading] = useState<boolean>(false);
  const [loadingCategories, setLoadingCategories] = useState<boolean>(false);
  const [categoryList, setCategoryList] = useState<any>([]);
  const history = useHistory();
  const dispatch = useDispatch();

  const businesses = useSelector((store: RootStateOrAny) => store.businesses);
  const business = useSelector((store: RootStateOrAny) => store.business);

  const initialValues = {
    registrationNumber: rcNumber,
    businessName: rcBusinessName,
    industryType: industryType,
    registrationType: regtype,
  };

  const onSubmit = async (values: any, form: any) => {
    omniSaver("businessName", rcBusinessName);
    setIsLoading(true);
    try {
      let resp: any;
      let businessId: any;
      let businessKycId: any;

      if (kycBoardingRec?.businessId) {
        let findRecord =
          business?.BusinessKyc instanceof Array &&
          business?.BusinessKyc.find(
            (el: any) => el?.type.toLowerCase() === "gateway"
          );
        if (findRecord?.id) {
          resp = await api.patch(
            `/business/${kycBoardingRec?.businessId}/kyc/${findRecord?.id}`,
            {
              step: 1,
              businessName: values.businessName,
              businessKycType: "GATEWAY",
              categoryId: values.industryType,
              registrationType: values.registrationType,
              registrationNumber: values.registrationNumber.toString(),
            }
          );
          businessId = kycBoardingRec?.businessId;
        } else {
          toast.error("Unable to update record");
          setIsLoading(false);
          return;
        }
      } else {
        resp = await api.post("/business/", {
          businessName: values.businessName,
          businessKycType: "GATEWAY",
          categoryId: values.industryType,
          registrationType: values.registrationType,
          registrationNumber: values.registrationNumber.toString(),
        });
        const message = resp?.message;
        const payload = resp?.payload;
        consoleLog("Returned business details: ", payload);
        toast.success(message);
        businessId = payload?.businessId;
      }

      const res = await api.get(`/client/business/${businessId}`);
      consoleLog("Current business details: ", res);
      let newBusinesses = [];
      newBusinesses.push(res?.payload);
      if (
        !businesses ||
        !(businesses instanceof Array) ||
        businesses.length === 0
      ) {
        await dispatch(setBusinessLists(newBusinesses));
        await dispatch(setCurrentBusiness(res?.payload));
      } else {
        await dispatch(setCurrentBusiness(res?.payload));
      }

      //omniSaver("business_id", businessId);
      //omniSaver("business_kyc_id", businessKycId);
      await dispatch(
        setKycBoardingRec(
          kycBoardingRec instanceof Object && {
            ...kycBoardingRec,
            rcNumber,
            rcBusinessName,
            industryType,
            regtype,
            businessId: businessId,
          }
        )
      );
      continueEvent();
      setIsLoading(false);
    } catch (error) {
      // consoleLog(error, "BusinessName");
      // @ts-ignore
      toast.error(error);
      setIsLoading(false);
    }
  };

  const verifyRcNumber = async (value: any, deepSearch: boolean=false) => {
    
    setRcNumber(value);
    
    if (value.length >=6 ) {
      setNameLoading(true);
      setRcBusinessName("")
      
      const values: any = {
        isRcn: true,
        number: parseInt(value),
      };

      setIsLoading(true);
      JSON.stringify({
        rc_number: value,
        rc_type: regtype,
        rc_deep: deepSearch
      })
      try {
        const { status, message, payload }: any = await api.post(
          "/business/kyc/verify",
          {
            rc_number: value,
            rc_type: regtype,
            rc_deep: deepSearch
          }
        );
        if (status && payload.rcNumber) {
          setRcNumber(payload.rcNumber);

          setRcBusinessName(payload.companyName);
          // toast.success(message);
        } else {
          toast.error(message);
          toast.error("Invalid registration number, please try again.");
        }
      } catch (error) {
        toast.error("Please try again.");
      }finally{
        setIsLoading(false);
        setNameLoading(false);
      }
    }
  };

 

  const continueEvent = () => {
    history.push("/kyc/add-account-details");
  };

  const industryCatgory = (categoryList: any) =>
    categoryList.map((cat: any) => ({ value: cat?.id, label: cat?.name }));

  const getIndustryType = async () => {
    setLoadingCategories(true);
    try {
      const businessList: any = await api.get("/business/categories");
      if (!businessList) {
        toast.warning("No Business List");
        return;
      }
      const { payload } = businessList;
      setCategoryList(payload);
      setLoadingCategories(false);
    } catch (error) {
      setLoadingCategories(false);
      // consoleLog(error);
      // @ts-ignore
      toast.error(error);
    }
  };

  const checkDisabled = () => {
    let arr = [regtype, rcBusinessName, rcNumber, industryType];

    let isNotDisabled: boolean = true;
    for (let el of arr) {
      isNotDisabled =
        isNotDisabled &&
        (typeof el === "string" || typeof el === "number") &&
        el.toString().trim() !== "";
    }
    return !isNotDisabled;
  };
  useEffect(() => {
    setSelExtend(false);
  }, [industryType]);

  useEffect(() => {
    getIndustryType();
    // quickPopulateUI();
  }, []);

  const goBack = () => {
    history.push("/kyc/business-type");
  };

  const close = () => {
    history.push("/overview");
  };

  if (isLoading) {
    <GradientLoader />;
  }

  return (
    <ParentCont>
      <LeftPanel extend={selExtend}>
        <FormCont>
          <TitleCont>
            <Back onClick={goBack}>
              <Icon type={"chevron-left"} size={20} />
            </Back>
            <FormTitle>Business Details</FormTitle>
          </TitleCont>

          <FormSubTitle>
            With your corporate account number, you are guaranteed of your
            settlement.
          </FormSubTitle>

          <Form
            enableReinitialize
            initialValues={initialValues}
            validations={{
              industryType: Form.is.required(),
              registrationType: Form.is.required(),
              registrationNumber: Form.is.required(),
              businessName: Form.is.required(),
            }}
            onSubmit={onSubmit}
            validate={() => {}}
            validateOnBlur={false}
          >
            <FormElement>
              <Form.Field.Select
                name="registrationType"
                label="Registration Type"
                options={categoryOptions}
                onChange={(e: any) => setRegtype(e)}
              />
              <GroupInput>
                <Form.Field.Input
                  name="registrationNumber"
                  type="tel"
                  label="Registration Number"
                  value={rcNumber}
                  onChange={verifyRcNumber}
                  // tip="Kindly enter your user email address."
                />
                <button
                  type="button"
                  onClick={()=>{verifyRcNumber(rcNumber.toString(), true)}}
                >
                  <Icon type="search" size="30px" />
                </button>
              </GroupInput>
              

              <Form.Field.Input
                name="businessName"
                type="text"
                label="Business Name"
                value={nameLoading ? "verifying business..." : rcBusinessName}
                disabled={regtype === "REGISTERED" ? true : false}
                onChange={(e: any) => setRcBusinessName(e)}
                // tip="Kindly enter your user email address."
              />
              <div
                onClick={() => {
                  setSelExtend(true);
                }}
              >
                <Form.Field.Select
                  name="industryType"
                  label="Industry Category"
                  options={industryCatgory(categoryList)}
                  onChange={(e: any) => {
                    setIndustryType(e);
                  }}

                  // onClick={() => window.scrollTo({ top: bottomDiv.current.offsetTop, behavior: "smooth" })}
                  // tip="Kindly enter your user password."
                />
              </div>
              <div ref={bottomDiv}></div>
              <Selectors>
                <ActionButton
                  type="submit"
                  variant="primary"
                  isWorking={isLoading}
                  disabled={checkDisabled()}
                >
                  {checkExistingKycBoarding(
                    `rcNumber,rcBusinessName,industryType,regtype`,
                    kycBoardingRec
                  )
                    ? "Update"
                    : "Create"}{" "}
                  Business
                </ActionButton>
              </Selectors>
            </FormElement>
          </Form>
        </FormCont>
      </LeftPanel>

      <RightPanel>
        <RightPanelAuxCont>
          <CloseBtn onClick={close}>
            <Icon type={"close"} size={20} />
          </CloseBtn>
        </RightPanelAuxCont>

        <RightPanelAuxCon2>
          <DescriptionBox>
            <DescriptionTitle>Gateway</DescriptionTitle>

            <DescriptionText>
              Enjoy, quick, and secure way to send and receive money.
            </DescriptionText>

            <DescriptionBtn
              onClick={() =>
                window.open(
                  "https://medium.com/@fountainpay",
                  "noopener,noreferrer"
                )
              }
            >
              Learn more
            </DescriptionBtn>
          </DescriptionBox>
        </RightPanelAuxCon2>
      </RightPanel>
    </ParentCont>
  );
}

export default BusinessDetails;
