import React, { useEffect, useState } from 'react'
import Layout from 'Layout'
import SettlementList from './SettlementList'
import SettlementDetails from './SettlementDetails'
import DashboardSubActionHeader from 'shared/components/DashboardSubActionHeader'
import Logo from '../assets/logo.svg'
import { RootStateOrAny, useSelector, useDispatch } from 'react-redux'
import { makeLive } from 'store/actions/live'
import { Redirect, Route, Router, Switch, useHistory, useParams } from 'react-router-dom'
import { PageError } from 'shared/components'

const pathname=window.location.pathname
let splitPath=pathname.split("/");
splitPath.shift()

const Settlement = () => {
	
	const history = useHistory();
	// const [pageView, setPageView] = useState(splitPath.length<4? 0: 1)
	// const service = useSelector((store: RootStateOrAny) => store.service)
	// // const live = useSelector((store: RootStateOrAny) => store.live);
	// // const [live, setLive] = useState(true);
	// const dispatch = useDispatch()
	// const [countChanges, setCountChanges] = useState(0)

	// const setLive = (val: boolean) => {
	// 	dispatch(makeLive(val))
	// 	setCountChanges(countChanges + 1)
	// }
	// const handleToggleMode = () => {
	// 	// setLive(!live);
	// 	//Toggle to live
	// }

	
	// const renderComponent = () => {
	// 	switch (pageView) {
	// 		case 0:
	// 			return <SettlementList setPageView={setPageView} />
			
	// 		case 1:
	// 			return <SettlementDetails />
	// 	}
	// }

	return (
		// <>
		// 	{/* //<Layout PageTitle="Settlement"> */}
			
		// 	<div>{renderComponent()}</div>

		// 	{/* //</Layout> */}
		// </>
		<>
      <Router history={history}>
        <Switch>
          <Redirect exact from="/my-apps/:serviceId/settlements" to="/my-apps/:serviceId/settlements/lists" />
          <Route path="/my-apps/:serviceId/settlements/lists" component={SettlementList} />

          <Route path="/my-apps/:serviceId/settlements/:id" component={SettlementDetails} />
          <Route path="*" component={PageError} />
        </Switch>
      </Router>
    </>
	)
}

export default Settlement
