import styled from "styled-components";

export const ReportPage = styled.div``;

export const Card = styled.div`
  background: #fbfbfb;
  box-shadow: 0px 4px 100px rgba(0, 0, 0, 0.04);
  border-radius: 20px;
  max-width: 815px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
  padding: 30px 50px;
`;

export const CardRow = styled.div`
  display: flex;
  padding: 10px 0px;
`;

export const CardLeft = styled.div`
  font-weight: 400;
  font-size: 20px;
  line-height: 35px;
  color: #292929;
  width: 30%;
`;

export const CardRight = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 35px;
  color: #292929;
  width: 70%;
`;

export const DownloadButton = styled.div`
  cursor: pointer;
  background-color: #760000;
  color: #ffffff;
  border-radius: 5px;
  display: inline;
  padding: 20px 40px;
  &:hover {
    background-color: #ffffff;
    color: #760000;
  }
`;
