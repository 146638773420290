import React, { useEffect, useState } from "react";
import Layout from "Layout";
import DashboardSubHeader from "shared/components/DashboardSubHeader";
import {
  EmptyContainer,
  LittleTitle,
  PageTitleComp,
  PageTitleAmt,
  PageCustomer,
} from "./Style";
import EmptyState from "shared/components/FreeComp/EmptyState";
import Table from "shared/components/Table";
import { useHistory, useParams } from "react-router-dom";
import shouldPerform, { getRoleName } from "shared/utils/assignRoles";
import { RootStateOrAny, useSelector, useDispatch } from "react-redux";
import summaryData from "shared/utils/dataSummary";
import api from "shared/utils/api";
import consoleLog from "shared/utils/consoleLog";
import toast from "shared/utils/toast";
import { getStatusColor } from "shared/utils/statusColor";

const SettlementDetails = () => {
  const pathname = window.location.pathname;
  let splitPath = pathname.split("/");
  const [id, setId] = useState(splitPath[splitPath.length - 1]);
  const [settlement, setSettlement] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const currentUser = useSelector((store: RootStateOrAny) => store.currentUser);
  const history = useHistory();
  const business = useSelector((store: RootStateOrAny) => store.business);
  const service = useSelector((store: RootStateOrAny) => store.service);

  const params: any = useParams();
  // console.log("PARAMS: ", params);

  const historyHeaders = [
    { id: "date", label: "Date" },
    { id: "amount", label: "Amount" },
    { id: "bankAccountName", label: "Amount" },
    { id: "accountNumber", label: "Amount" },
    { id: "bankName", label: "Amount" },
    { id: "type", label: "Account Type" },
    { id: "status", label: "Status" },
  ];

  const transHeaders = [
    { id: "date", label: "Date" },
    { id: "customer", label: "Customer" },
    { id: "amount", label: "Amount" },
    { id: "settledAmount", label: "Settled Amount" },
    { id: "charge", label: "Charges" },
    { id: "currency", label: "Currency" },
    { id: "status", label: "Status" },
  ];

  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "NGN",
  });

  const getSettlementDetails = async () => {
    try {
      const response = await api.get(
        `/services/${service?.id}/settlements/${id}`
      );
      consoleLog("SettlementRes: ", response);
      if (response.status == true) {
        setSettlement(response.payload);
      }
    } catch (err) {
      consoleLog(err, "error");
      // @ts-ignore
      toast.error(err && err.detail);
    } finally {
      setIsLoading(false);
    }
  };
  const histories =
    settlement?.SettlementHistory instanceof Array &&
    settlement?.SettlementHistory.map((el: any) => {
      let obj: any = {};
      obj.type = el.BankAccount.type;
      obj.bankAccountName = el.BankAccount.bankAccountName;
      obj.accountNumber = el.BankAccount.accountNumber;
      obj.bankName = el.BankAccount.bank.name;
      obj.amount = el.amount && `${formatter.format(el.amount)}`;
      obj.amount = el.amount && `${formatter.format(el.amount)}`;
      obj.date = el.updatedAt;
      obj.status =
        el?.status &&
        (el?.status.toLowerCase().includes("init") ? (
          <div style={{ color: getStatusColor("pending") }}>In progress</div>
        ) : (
          <div style={{ color: getStatusColor(el?.status) }}>
            {el?.status?.toLowerCase()[0].toUpperCase() +
              el?.status?.toLowerCase().substring(1)}
          </div>
        ));

      return { ...el, ...obj };
    });

  const transactions =
    settlement?.SettlementDetail instanceof Array &&
    settlement?.SettlementDetail.map((el: any) => {
      let obj: any = {};
      obj.date = el.createdAt;
      obj.customer = (
        <div>
          <p>{el?.Transaction?.Customer?.fullName}</p>
          <span>{el?.Transaction?.Customer?.email}</span>
        </div>
      );
      obj.settledAmount = el.amount && `${formatter.format(el.amount)}`;
      obj.amount =
        el?.Transaction?.amount &&
        `${formatter.format(el?.Transaction?.amount)}`;
      obj.charge =
        el?.Transaction?.charge &&
        `${formatter.format(el?.Transaction?.charge)}`;
      obj.currency = el?.Transaction?.paymentCurrency;
      obj.status =
        el?.Transaction?.status &&
        (el?.Transaction?.status.toLowerCase().includes("init") ? (
          <div style={{ color: getStatusColor("pending") }}>In progress</div>
        ) : (
          <div style={{ color: getStatusColor(el?.Transaction?.status) }}>
            {el?.Transaction?.status?.toLowerCase()[0].toUpperCase() +
              el?.Transaction?.status?.toLowerCase().substring(1)}
          </div>
        ));

      return { ...el, ...obj };
    });

  const TitleComp = (
    <PageTitleComp>
      <PageTitleAmt>Settlement: </PageTitleAmt>
      <PageTitleAmt>
        {isNaN(settlement?.amount)
          ? "NGN 0.00"
          : `${formatter.format(settlement?.amount)}`}
      </PageTitleAmt>
      <PageCustomer>{settlement?.status}</PageCustomer>
      <PageCustomer>{settlement?.updatedAt}</PageCustomer>
    </PageTitleComp>
  );

  useEffect(() => {
    getSettlementDetails();
  }, []);

  if (!shouldPerform(getRoleName(currentUser, business?.id), "VSettlement")) {
    history.push("/authenticate");
    return null;
  }

  return (
    <>
      {TitleComp}
      <br />
      <br />
      <div>
        <h2>Settlement details</h2>
        <Table
          headData={historyHeaders}
          bodyData={histories}
          serialNumber={true}
          hoverLink={() => {}}
        />
      </div>
      <br />
      <br />
      <br />
      <br />
      <div>
        <h2>Transaction details</h2>
        <Table
          headData={transHeaders}
          bodyData={transactions}
          serialNumber={true}
          hoverLink={() => {}}
        />
      </div>
    </>
  );
};

export default SettlementDetails;
