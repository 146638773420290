import React from "react";
import Tooltip from "shared/components/Tooltip";
import { FeedbackDropdown, FeedbackParagraph } from "./Styles";

const NotificationTooltip = (tooltipProps: any) => (
  <Tooltip
    width={300}
    {...tooltipProps}
    renderContent={() => (
      <FeedbackDropdown>
        <FeedbackParagraph>&nbsp;</FeedbackParagraph>
        {/* <FeedbackParagraph>You are about to logout.</FeedbackParagraph>
        <FeedbackParagraph>
          Are you sure you still want to logout?
        </FeedbackParagraph> */}
      </FeedbackDropdown>
    )}
  />
);

export default NotificationTooltip;
