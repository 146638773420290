import { callServiceDetails, getServiceKeys } from "Services/serviceClass";
import React, { useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import consoleLog from "shared/utils/consoleLog";
import toast from "shared/utils/toast";
import { setService } from "store/actions/serviceAction";

//===========================================
// @Description: This custom hook is form
// fetching service details and keys and also
// updating the service global state.
// is exposes 'serviceDetails' obj  and
//'getServiceDetails' function.
//============================================

export default function useServiceDetails() {
  const service = useSelector((store: RootStateOrAny) => store.service);
  const business = useSelector((store: RootStateOrAny) => store.business);
  const [serviceDetails, setserviceDetails] = useState<any>({});

  const dispatch = useDispatch();

  const getServiceDetails = async (check?: number) => {
    try {
      let response = await callServiceDetails({ id: service?.id });
      if (response.status == true) {
        if (response.payload) {
          let sendData: any = response.payload;
          response = await getServiceKeys({
            mode: sendData.mode,
            id: service?.id,
          });

          if (response.status === true && response.payload instanceof Object) {
            if (response.payload) {
              let { privateKey, publicKey } = response.payload;
              sendData = {
                ...service,
                ...sendData,
                privateKey,
                publicKey,
              };
            } else {
              sendData = {
                ...service,
                ...sendData,
                businessId: business?.id,
                privateKey: "",
                publicKey: "",
              };
            }

            // alert(live);
          } else {
            toast.error("Unable to fetch keys");
          }
          sendData = { ...service, ...sendData };

          dispatch(setService(sendData));
          setserviceDetails(service);
        } else {
          toast.error("Unable to fetch Service Details");
        }
      } else {
        toast.error(response.message);
      }
    } catch (err) {
    } finally {
    }
  };

  useEffect(() => {
    getServiceDetails();
  }, []);

  return { serviceDetails, getServiceDetails };
}
