import { numberFormat_extra, numberWithCommas } from "shared/utils/utility";
import { InputContainer } from "./Style";

const Account=({agentDetail}: any)=>{
  // console.log("Agent details: ", agentDetail)
  return(
    <div style={{width: "60%"}}>
      <p style={{ fontSize: "28px" }}>Wallet details</p>
      <p
        style={{
          fontSize: "0.94rem",
          color: "#6e7787",
        }}
      >
        Balance: <strong> {numberFormat_extra(450000)}</strong>
      </p>
      <p>
        <hr style={{ opacity: "0.2" }} />
      </p>
      <p
        style={{
          fontSize: "0.94rem",
          color: "#6e7787",
        }}
      >
        Account Information
      </p>
      <InputContainer>
        Bank name: {agentDetail.bankName}
      </InputContainer>
      <InputContainer>
        Account no: {agentDetail.accountNumber}
      </InputContainer>
    </div>
  )
}

export default Account;