import { useLayoutEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { Route } from "react-router";
import { getStoredAuthToken } from "shared/utils/authToken";
import routes from "./Router";

import { Modal } from "shared/components/ModalBox/Styles";
import { FormBody, ModalBody, PageBody } from "./Styles";

const AppRoute = ({ component: Component, layout: Layout, ...rest }: any) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        ////<Layout>
          <Component {...props}></Component>
        ////</Layout>
      )}
    />
  );
};

const AccountRecovery = () => {
  const [isLoginIn, setIsLoginIn] = useState(false);

  const history = useHistory();

  useLayoutEffect(() => {
    const token = getStoredAuthToken();
    if (token) {
      history.push("/overview");
    }
  }, []);
  return (
    <>
      <PageBody>
        <ModalBody>
          <Modal>
            <FormBody>
              {routes.map((i, index) => {
                return (
                  <AppRoute
                    exact
                    key={i.path}
                    path={i.path}
                    layout={i.layout}
                    component={i.component}
                  />
                );
              })}
            </FormBody>
          </Modal>
        </ModalBody>
      </PageBody>
    </>
  );
};

export default AccountRecovery;
