import React, { useEffect, useState } from "react";
import { Button, Form, Icon } from "shared/components";
import {
  ActionButton,
  ComponentBody,
  ComponentFooter,
  FormBody,
  FormElement,
  LeftPane,
  ModalBody,
  PageBody,
  PasswordCont,
  StyledLink,
  Title,
} from "./Styles";
import toast from "shared/utils/toast";
import { useHistory } from "react-router-dom";
import api from "shared/utils/api";
// import { holdEmail } from "shared/utils/authToken";
import { authPath } from "apiServices/paths";
import { storeTempId } from "shared/utils/authToken";
import { omniSaver } from "shared/utils/authToken";
import { testEmail } from "shared/utils/formValidation";
import PasswordMeter from "AcceptInvite/PasswordMeter";
import { Modal } from "shared/components/ModalBox/Styles";
import Right from "./Right";
import consoleLog from "shared/utils/consoleLog";

const Index = () => {
  const [password, setPassword] = useState("");
  const [name, setname] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);

  const [isFormValid, setFormValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const onSubmit = async (value: any) => {
    setIsLoading(true);
    // consoleLog("Hello", value);
  };

  return (
    <>
      <PageBody>
        <ModalBody>
          <Modal>
            <FormBody>
              <LeftPane>
                <ComponentBody>
                  <Title>Create Account</Title>
                  {/* @ts-ignore */}
                  <Form
                    enableReinitialize
                    initialValues={{
                      name: "",
                      password: "",
                    }}
                    validations={{
                      name: [Form.is.required(), Form.is.maxLength(100)],
                      password: [Form.is.required(), Form.is.maxLength(200)],
                    }}
                    onSubmit={onSubmit}
                  >
                    <FormElement>
                      <Form.Field.Input
                        name="name"
                        label="Name"
                        onChange={(e: any) => setname(e)}
                        // tip="Kindly enter your name."
                      />

                      <PasswordCont>
                        <Form.Field.Input
                          name="password"
                          label="Password"
                          type={!passwordVisible ? "password" : "text"}
                          onChange={(e: any) => setPassword(e)}
                          // tip="Kindly enter your password."
                        />
                        <Icon
                          onClick={() => setPasswordVisible(!passwordVisible)}
                          type={!passwordVisible ? "eye" : "eye-blocked"}
                          style={{
                            color: "#760000",
                            fontSize: 18,
                            position: "absolute",
                            right: 5,
                            top: 40,
                          }}
                        />
                      </PasswordCont>

                      <ActionButton
                        type="submit"
                        variant="primary"
                        isWorking={isLoading}
                      >
                        Create Account
                      </ActionButton>

                      <PasswordMeter password={password} />
                    </FormElement>
                  </Form>
                </ComponentBody>
              </LeftPane>

              <Right />
            </FormBody>
          </Modal>
        </ModalBody>
      </PageBody>
    </>
  );
};

export default Index;
