import { useEffect, useState } from "react";
import Switch from "../Switch";
import { Activate } from "./Styles";

const ActivateButton = ({
  id,
  active,
  label,
  toggleMethod,
  disabled,
  ...others
}: any) => {
  const [isActive, setActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleClick = async (e: any) => {
    setIsLoading(true);
    let result = await toggleMethod();
    setIsLoading(false);
    if (result !== "off") setActive(!isActive);
  };

  const handleClick2 = (e: any) => {
    e.stopPropagation();
    // toggleMethod();
  };

  useEffect(() => {
    setActive(active);
  }, [active]);

  return (
    <Activate onClick={handleClick} {...others}>
      <Switch
        id={id}
        status={isActive}
        label={isLoading ? "Loading..." : isActive ? "Activate" : "Deactivate"}
        onClick={handleClick2}
        stopPropagating

        // toggleMethod={handleClick}
      />
    </Activate>
  );
};

export default ActivateButton;
