import { uploadFile } from "apiServices/authService";
import { useEffect, useRef, useState } from "react";

import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { Form, GradientLoader, Modal, Tabs } from "shared/components";
import { PageLayout } from "shared/section_components/Styles";

import api from "shared/utils/api";
import { onChangeTextHandler } from "shared/utils/formValidation";
import toast from "shared/utils/toast";
import { setCurrentUser } from "store/actions/userAction";
import {
  setBusinessLists,
  setCurrentBusiness,
} from "store/actions/businessAction";
import {
  BusinessFormCont,
  BusinessLogo,
  Dangerzone,
  DangerzoneTitle,
  DeleteButton,
  ExtraButton,
  FileInput,
  FormContainer,
  FormGroup,
  FormGroupContainer,
  ImageBoxFormContainer,
  ImageContainer,
  ImageFormContainer,
  SaveButton,
  TransferButton,
  UploadButton,
  UploadCon,
  UploadInfo,
  UploadWrapper,
} from "./Styles";
import consoleLog from "shared/utils/consoleLog";
import OtpModal from "shared/components/OtpModal";
import { useHistory } from "react-router-dom";
import ConfirmModal from "shared/quickComponents/ConfirmModal";
import { IoCameraOutline } from "react-icons/io5";
import { MdOutlineAddAPhoto } from "react-icons/md";

const BusinessSettings = () => {
  const [agencyBankingForm, setagencyBankingForm] = useState<boolean>(false);
  const [gatewayForm, setgatewayForm] = useState<boolean>(true);
  const [fstate, setFstate] = useState({} as any);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isLoading, setisLoading] = useState(true);
  const [isFormValid, setFormValid] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isTransfering, setIsTransfering] = useState(false);
  const [visible, setvisible] = useState(false);
  const [showIndicator, setshowIndicator] = useState(false);
  const [otpModal, setOtpModal] = useState(false);
  const [businessImage, setbusinessImage] = useState<string>();
  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState<any>("");

  const history = useHistory();

  const currentBusiness = useSelector(
    (store: RootStateOrAny) => store.business
  );

  const currentUser = useSelector((store: RootStateOrAny) => store.currentUser);

  const dispatch = useDispatch();

  const handleToogle = () => {
    setagencyBankingForm(!agencyBankingForm);
    setgatewayForm(!gatewayForm);
  };

  // =================== Local State and functions ====================

  const refetchBusineses = () => {};

  const handleUpload = async (event: any) => {
    try {
      const files = event.target.files;
      setIsUploading(true);
      let formData = new FormData();
      formData.append("certificate", files[0]);
      // consoleLog("FORM UPLOAD:", formData);
      const response = await uploadFile(formData);
      // console.log("Upload response: ", response)
      if (response.status) {
        const logoUrl = response?.payload?.fileUrl;
        const payload={ ...fstate, business_logo: logoUrl }
        setFstate(payload);
        setIsUploading(false);
      }
    } catch (error) {
      setIsUploading(false);
      // consoleLog(error.toString());
    }
  };

  // =================================================================

  const getBusinessInfo = async () => {
    try {
      const response = await api.get(`/client/business/${currentBusiness?.id}`);
      // consoleLog("BUSINESS DATARES  :", response);

      if (response.status === true) {
        consoleLog("BUSINESS DATA:", response.payload);
        const {
          businessName,
          phone,
          supportEmail,
          supportPhone,
          businessLogo,
          country,
        } = response.payload;
        setFstate({
          businessName,
          phone,
          supportEmail,
          supportPhone,
          businessLogo: businessLogo ?? "",
          country: "Nigeria",
        });
        setImageUrl(businessLogo);
        setisLoading(false);
      }
    } catch (error) {
      // toast.error("Couldn't get business information");
      // consoleLog(error, "ErrorBusiness Data");

      setisLoading(false);
    }
  };

  

  const sendOTP = async () => {
    setvisible(true);
    setConfirmModalOpen(false);
  };

  const inputArray = [
    {
      id: "businessName",
      title: "Business Name",
      type: "text",
      disabled: true,
    },
    {
      id: "phone",
      title: "Business Phone Number",
      type: "text",
    },
    {
      id: "supportEmail",
      title: "Support Email",
      type: "email",
      disabled: true,
    },
    {
      id: "supportPhone",
      title: "Support Phone Number",
      type: "text",
    },
    {
      id: "country",
      title: "Country",
      type: "text",
      disabled: true,
    },
  ];

  const displayFormInput = () => {
    return inputArray.map((elm, idx) => {
      switch (elm.type) {
        case "text":
          return (
            <FormGroup key={`INP${idx}`}>
              <Form.Field.Input
                maxLength={
                  elm.title === "Business Phone Number" ||
                  elm.title === "Support Phone Number"
                    ? 11
                    : "false"
                }
                key={`INP${idx}__`}
                name={elm.id}
                type={elm.type}
                label={elm.title}
                value={fstate[elm.id] ?? ""}
                disabled={elm?.disabled}
                onChange={(val: any) =>
                  onChangeTextHandler(val, elm.id, fstate, setFstate)
                }
                style={{ backgroundColor: "#fff" }}
              />
            </FormGroup>
          );
        case "email":
          return (
            <FormGroup key={`INP${idx}`}>
              <Form.Field.Input
                key={`INP${idx}__`}
                name={elm.id}
                type={elm.type}
                label={elm.title}
                value={fstate[elm.id] ?? ""}
                onChange={(val: any) =>
                  onChangeTextHandler(val, elm.id, fstate, setFstate)
                }
                style={{ backgroundColor: "#fff" }}
              />
            </FormGroup>
          );
      }
    });
  };

  const tabs = [
    {
      text: "Gateway",
      isActive: false,
    },
    {
      text: "Agency Banking",
      isActive: false,
    },
  ];

  const deleteBusiness = async () => {
    // seletedMember
  };

  const handleFileSelect = (event: any) => {
    const avatar = event.target.files[0];
    let _filesize: number;
    const reader = new FileReader();
    if (avatar) {
      reader.onloadend = () => {
        _filesize = avatar.size;
        if (_filesize / 1000 < 1000) {
          if (avatar.type == "image/jpeg" || avatar.type == "image/png") {
            const url = URL.createObjectURL(avatar);
            setImageUrl(url);
          } else {
            return;
          }
        } else {
          return;
        }
      };
      reader.readAsDataURL(avatar);
    }
  };

  const photoRef = useRef<any>(null);
  const handleRefClick = (Ref: any) => {
    Ref.current.click();
  };

  const onSubmit = async () => {
    // let obj = {};
    for (const key in fstate) {
      let value = fstate[key];
      if (value === null || value === "") {
        delete fstate[key];
        // obj = fstate;
      }
    }
    // const payload={...fstate, business_logo: imageUrl}
    // consoleLog('obj ', {...obj})
    consoleLog(fstate, "FstateBusi");
    // return;
    try {
      setIsProcessing(true);
      const data = { ...fstate };
      // const data = {
      //   phone: fstate.phone || '',
      //   supportEmail: fstate.supportEmail || '',
      //   supportPhone: fstate.supportPhone || '',
      //   country: fstate.country,
      //   businessLogo: fstate.businessLogo || '',
      //   business_logo: fstate.businessLogo || '',
      // };
      let resp;
      resp = await api.patch(`/business/${currentBusiness?.id}`, {
        ...data,
      });

      dispatch(setCurrentBusiness({ ...currentBusiness, ...data }));
      dispatch(setBusinessLists([], !!currentBusiness));
      dispatch(setCurrentUser());
      toast.success(resp.message);
      // onClose();
    } catch (error) {
      //@ts-ignore
      toast.error(error);
    } finally {
      setIsProcessing(false);
    }
  };

  useEffect(() => {
    getBusinessInfo();
  }, []);

  useEffect(() => {
    if (
      !fstate.phone ||
      !fstate.supportEmail ||
      !fstate.supportPhone ||
      !fstate.country
    ) {
      setFormValid(false);
      return;
    }

    setFormValid(true);
  }, [fstate]);

  return (
    <>
      <PageLayout>
        <div style={{ paddingBottom: "1rem" }}>
          <BusinessFormCont data-label="Gateway">
            <FormGroupContainer>
              <FormContainer>
                {/* @ts-ignore  */}
                <Form
                  enableReinitialize
                  initialValues={{
                    businessName: "",
                    businessPhone: 0,
                    supportEmail: 0,
                    supportPhone: 0,
                    country: 0,
                  }}
                  validations={{
                    businessName: Form.is.required(),
                    businessPhone: Form.is.required(),
                    supportEmail: Form.is.required(),
                    supportPhone: Form.is.required(),
                    country: Form.is.required(),
                  }}
                  onSubmit={onSubmit}
                >
                  {isLoading ? (
                    <GradientLoader key="SettingsLoader" />
                  ) : (
                    <>
                      {displayFormInput()}
                      <SaveButton
                        type="submit"
                        variant="primary"
                        onClick={onSubmit}
                        isWorking={isProcessing}
                        disabled={isProcessing}
                        style={{ marginTop: "25px" }}
                      >
                        Save
                      </SaveButton>
                      <br />
                      <br />
                      <DangerzoneTitle>Danger Zone</DangerzoneTitle>
                      <Dangerzone>
                        <div
                          style={{
                            display: "flex",
                            gap: "20px",
                            alignItems: "center",
                          }}
                        >
                          <div>
                            <b>Transfer ownership</b>
                            <br />
                            <p
                              style={{
                                fontSize: "0.88rem",
                                marginTop: "0.4rem",
                              }}
                            >
                              Transfer this business to another user within this
                              organization where you have the ability to create
                              applications.
                            </p>
                          </div>
                          <TransferButton
                            type="button"
                            variant="primary"
                            onClick={() => history.push("transfer")}
                            isWorking={isTransfering}
                            disabled={isTransfering}
                            style={{ marginTop: "25px" }}
                          >
                            Transfer buisness
                          </TransferButton>
                        </div>

                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div>
                            <b>Delete this business</b>
                            <br />
                            <p
                              style={{
                                fontSize: "0.88rem",
                                marginTop: "0.4rem",
                              }}
                            >
                              Once you delete a business, there is no going
                              back. Please be certain.{" "}
                            </p>
                          </div>
                          <TransferButton
                            type="button"
                            variant="primary"
                            onClick={() => setConfirmModalOpen(true)}
                            isWorking={isDeleting}
                            disabled={isDeleting}
                            style={{ marginTop: "25px" }}
                          >
                            Delete business
                          </TransferButton>
                        </div>
                      </Dangerzone>
                    </>
                  )}
                </Form>
              </FormContainer>
            </FormGroupContainer>
            <ImageBoxFormContainer>
              <ImageFormContainer>
                <p>Upload Business logo</p>
                <input
                  type="file"
                  ref={photoRef}
                  name="businessLogo"
                  hidden
                  onChange={(value: any) => {
                    handleFileSelect(value);
                    handleUpload(value);
                  }}
                />
                <ImageContainer onClick={() => handleRefClick(photoRef)}>
                  {/* {fstate?.businessLogo && (
                    <BusinessLogo src={fstate?.businessLogo} alt="" />
                  )} */}

                  <BusinessLogo src={imageUrl} alt="" />
                  <div
                    style={{
                      position: "absolute",
                      right: "1rem",
                      bottom: 0,
                      width: "28px",
                      height: "28px",
                      background: "#fff",
                      cursor: "pointer",
                      borderRadius: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.1)",
                      zIndex: "10",
                    }}
                  >
                    <MdOutlineAddAPhoto size={20} />
                  </div>
                </ImageContainer>

                <UploadCon>
                  {/* <UploadWrapper>
                    {imageUrl && (
                      <UploadButton>
                        {isUploading ? "Loading" : "Save"}
                      </UploadButton>
                    )}
                  </UploadWrapper> */}
                  <UploadInfo>
                    Please click "save" after upload to submit image
                  </UploadInfo>
                </UploadCon>
              </ImageFormContainer>
            </ImageBoxFormContainer>
          </BusinessFormCont>
        </div>
      </PageLayout>
      {visible && (
        <Modal
          isOpen={visible}
          width={800}
          withCloseIcon={true}
          onClose={() => setvisible(false)}
          // renderContent={() => <AddTeam />}
          renderContent={() => (
            <OtpModal
              callBackFn={deleteBusiness}
              startHere
              closeModal={setvisible}
              emailType="verification"
              type="auth-user"
            />
          )}
        />
      )}

      <ConfirmModal
        message="Are sure you want to remove this team member?"
        handleYesModalButton={() => {
          sendOTP();
        }}
        handleNoModalButton={() => setConfirmModalOpen(!isConfirmModalOpen)}
        isModalOpen={isConfirmModalOpen}
        showIndicator={showIndicator}
        handleCloseModal={() => setConfirmModalOpen(!isConfirmModalOpen)}
      />
    </>
  );
};

export default BusinessSettings;
