import React, { useState } from "react";
import Modal from "shared/components/Modal";
import {
  ButtonContainer,
  CCFormContainer,
  CategoryForm,
  CreateCatContainer,
} from "../Styles";
import InventoryBanner from "../../../App/assets/images/inventorybanner.svg";
import { Input } from "shared/components";
import { RootStateOrAny, useSelector } from "react-redux";
import { InventoryService } from "../inventory-service";
import Spinner from "shared/components/spinner/spinner";
import toast from "shared/utils/toast";

type PropT = {
  isopen: boolean;
  setisOpen: Function;
  data?: any;
  action: string;
  makeApiCall: Function;
};

export default function CreateCategory({
  isopen,
  setisOpen,
  action,
  data,
  makeApiCall,
}: PropT) {
  const [categoryName, setcategoryName] = useState(
    action == "Edit" ? data.name : ""
  );
  const [isloading, setisloading] = useState(false);

  const currentUser = useSelector((store: RootStateOrAny) => store.currentUser);
  const currentBusiness = useSelector(
    (store: RootStateOrAny) => store.business
  );

  const handleCreateCatgory = async (e: any) => {
    try {
      e.preventDefault();
      setisloading(true);
      const payload = {
        name: categoryName,
        business_id: currentBusiness?.id,
        user_id: currentUser?.id,
      };
      const response = await InventoryService.CreateCategory(payload);
      if (response.status) {
        toast.success("Asset created successfully.");
      }
      makeApiCall();
      setisOpen();
    } catch (err) {
      const error: any = err;
      toast.error(error.message);
      // // console.log(error);
    } finally {
      setisloading(false);
    }
  };

  const handleUpdateCatgory = async (e: any) => {
    try {
      e.preventDefault();
      setisloading(true);
      const payload = {
        name: categoryName,
        id: data.uuid,
        user_id: currentUser?.id,
        status: data.status,
      };
      // // console.log("update payload", data);
      const response = await InventoryService.UpdateCategory(payload);
      if (response.status) {
        toast.success("Category updated successfully.");
      }
      makeApiCall();
      setisOpen();
    } catch (err) {
      const error: any = err;
      toast.error(error.message);
      // // console.log(error);
    } finally {
      setisloading(false);
    }
  };

  return (
    <div>
      <Modal
        overFlow={true}
        isOpen={isopen}
        width={800}
        containerWidth={false}
        withCloseIcon={true}
        onClose={() => setisOpen()}
        renderContent={() => (
          <CreateCatContainer>
            <div>
              <h2>{action} Category</h2>
              <p>{action} category to manage your inventory</p>
            </div>
            <CCFormContainer>
              <CategoryForm
                onSubmit={
                  action !== "Edit" ? handleCreateCatgory : handleUpdateCatgory
                }
              >
                <p>Enter category name</p>
                {/* <input placeholder="Catgory name" required minLength={4} /> */}
                <Input
                  type="text"
                  name="input"
                  value={categoryName}
                  placeholder="Category"
                  onChange={(value: any) => setcategoryName(value)}
                  styleContainer={{ marginBottom: 0 }}
                />
                <ButtonContainer>
                  <button disabled={categoryName.length ? false : true}>
                    {isloading ? <Spinner /> : action}
                  </button>
                </ButtonContainer>
              </CategoryForm>
              <div>
                <img src={InventoryBanner} alt="" />
              </div>
            </CCFormContainer>
          </CreateCatContainer>
        )}
      />
    </div>
  );
}
