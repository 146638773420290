import styled from "styled-components";
import { color, font } from "shared/utils/styles";
import { Button, Form } from "shared/components";
import { Link } from "react-router-dom";

export const ComponentBody = styled.div`
  width: 100%;
  margin: 0em auto;
  margin-top: 2rem;
  padding: 40px;
  /* border: 1px solid red; */
`;

export const ActionButton = styled(Button)`
  margin: 2em 0;
  color: #fff;
  width: 28%;
  background-color: ${color.fountain};
`;

export const FormElement = styled(Form.Element)``;

export const StyledLink = styled(Link)`
  color: ${color.fountain} !important;
  ${font.size(14)}
`;

export const ComponentFooter = styled.p`
  color: #292929;
  ${font.size(14)}
`;

export const Title = styled.h1`
  text-align: left;
  color: #292929;
  ${font.size(24)}
`;

export const ErrorTitle = styled.h1`
  text-align: center;
  color: #760000;
  ${font.size(24)}
`;

export const LinkDescription = styled.p`
  text-align: left;
  color: #828282;
`;

export const FormInputContainer = styled.div`
  width: 100%;
  display: flex;
`;
export const InputLabel = styled.div`
  width: 5%;
  height: inherit;
  color: #828282;
`;

export const DivLabel = styled.div`
  display: block;
  padding-bottom: 5px;
  color: #5e6c84;
  font-family: "KumbhSansNormal";
  font-weight: normal;
  font-size: 13px;
  text-align: left;
`;

export const DivInput = styled.div`    
height: 54px;
width: 100%;
padding: 0 7px;
border-radius: 3px;
border: 1px solid #dfe1e6;
color: #172b4d;
background: #F4F5F7;
-webkit-transition: background 0.1s;
transition: background 0.1s;
font-family: "KumbhSansNormal";
font-weight: normal;
font-size: 15px;
display:flex;
justify-content: flex-start;
align-items: center;
}`;
