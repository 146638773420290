import React, { useEffect, useState } from "react";
import {
  ActionButton,
  EditButton,
  EmptyState,
  ModeSpan,
  ServicePageActionButtons,
  ServicePageContainer,
  ServicePageContent,
  ServicePageSubHeader,
  WalletEmptyStateDesc,
  WalletSummaryCards,
  WalletSummaryDetails,
} from "./Styles";
import { RootStateOrAny, useSelector } from "react-redux";
import { GradientLoader, Icon, Modal, Switch } from "shared/components";
import useServiceMode from "shared/hooks/useServiceMode";
import Table from "shared/components/Table";
import { getStatusDivColor } from "shared/utils/statusColor";
import { formateDate, formateDateString } from "shared/utils/utility";
import moment from "moment";
import WalletIco from "App/assets/images/alert.svg";
import consoleLog from "shared/utils/consoleLog";
import api from "shared/utils/api";
import toast from "shared/utils/toast";
import { useHistory } from "react-router-dom";
import { Message } from '../../ChargeBackDetails/ChargeBackOptions/Styles';

export default function WalletBase() {
  const service = useSelector((store: RootStateOrAny) => store.service);
  const { serviceMode, loadingMode, toogleMode } = useServiceMode(service);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isWallet, setIsWallet] = useState<boolean>(false);
  const [isCreating, setIsCreating] = useState(false);
  const [wallets, setWallets] = useState<any>([]);
  const [walletsDetails, setWalletsDetails] = useState<any>([]);
  const [transactions, setTransactions] = useState<any>([]);
  const { mode, name, serviceId, status, createdAt } = service;
  const [visible, setvisible] = useState(false);

  const history=useHistory()

  const columnHeaders = [
    { id: "serviceName", label: "Service Name" },
    { id: "serviceID", label: "Service Id" },
    { id: "accountNo", label: "Account No" },
    { id: "bankName", label: "Bank Name" },
    { id: "status", label: "Status" },
    { id: "dateCreated", label: "Date Activated" },
    { id: "action", label: "" },
  ];

  const handleCopy = () => {
    navigator.clipboard.writeText(wallets?.accountNumber);
    toast.success("Copied to clipboard!");
  };

  const activateWallet=async()=>{
    setIsCreating(true)
    try{
      const payload={
        service_id: service?.id
      }
      consoleLog(`services/${service?.id}/wallet`)
      const resp=await api.post(`/services/${service?.id}/wallet`, payload);
      if(resp.status){
        toast.success("Wallet activated successfully");
        getWalletAccount();
      }else{
        toast.error(resp.message || "Error activating wallet.");
      }
    }catch(err: any){
      consoleLog("Error: ", err);
      toast.error(err.message || "Error activating wallet.");
    }finally{
      setIsCreating(false)
    }
    
  }

  const reloadWallet = async()=>{
    setIsLoading(true)
    try{
      // console.log("Wallets details: ", wallets)
      const resp=await api.post(`/services/${service?.id}/wallet/transactions-re-query`, {account_no: wallets.accountNumber});
      
      if(resp.status){
        consoleLog(resp)
        getWalletAccount()
      }
      
    }catch(err: any){
      toast.error(err.message)
      consoleLog("Error: ", err)
    }finally{
      setIsLoading(false)
    }
  }
  const getWalletAccount=async()=>{
    setIsLoading(true)
    setWallets(null)
    setTransactions(null)
    try{
      const resp=await api.get(`/services/${service?.id}/wallet`);
      //consoleLog(resp.payload)
      if(resp.status){
        setIsWallet(true)
        setWallets(resp.payload)
        setWalletsDetails([{
          serviceName: name,
          serviceID: serviceId,
          accountNo: resp.payload.accountNumber,
          bankName: resp.payload.bankName,
          status: getStatusDivColor(resp.payload.status),
          dateCreated: formateDate(resp.payload.createdAt),
          action: <Icon type="copy" onClick={() => handleCopy()} />,
        }])
        
      }else{
        setIsWallet(false)
      }
    }catch(err: any){
      consoleLog("Error: ", err)
    }finally{
      setIsLoading(false)
    }
  }
  useEffect(() => {
    getWalletAccount()
  }, []);

  return (
    <div>
      <ServicePageContainer>
        <h1>
          Wallet
        </h1>
        <ServicePageSubHeader>
          <p>Find your wallet transactions here</p>
          {isWallet &&
            <ServicePageActionButtons>
              <EditButton onClick={reloadWallet}>Query transaction</EditButton>

              <EditButton onClick={()=>{
                history.push(`/my-apps/${service?.id}/wallet/transactions`)
              }}>Transactions histories</EditButton>      
            </ServicePageActionButtons>
            
          }
          
        </ServicePageSubHeader>

        <ServicePageContent>
          {!isLoading && isWallet && 
            <>
              <WalletSummaryDetails>
                <WalletSummaryCards>
                  <Icon className="fa" type="cloud-check" size={120}/>
                  <div>
                    <p>{wallets?.balance || "0.00"}</p>
                    <h1>Current balance</h1>
                    <p className="bookbalance">Book balance: {wallets?.bookBalance || "0.00"}</p>
                  </div>
                </WalletSummaryCards>
                <WalletSummaryCards>
                  <Icon className="fa" type="cloud-upload" size={120}/>
                  <div>
                    <p>{wallets?.credit || "0.00"}</p>
                    <h1>Total credit</h1>
                  </div>
                </WalletSummaryCards>
                <WalletSummaryCards>
                  <Icon className="fa" type="cloud-download" size={120}/>
                  <div>
                    <p>{wallets?.debit || "0.00"}</p>
                    <h1>Total debit</h1>
                  </div>
                </WalletSummaryCards>
              </WalletSummaryDetails>

              <Table
                headData={columnHeaders}
                bodyData={walletsDetails}
                clickFunction={() => ""}
                hoverLink
              />
            </>
          }

        {!isLoading && !isWallet && 
          <EmptyState>
            <div>
              <img src={WalletIco} width={250}/>
            </div>
            <WalletEmptyStateDesc>You do not have wallet activated yet. Kindly click the button below to activate wallet operation for your service.</WalletEmptyStateDesc>
            <ActionButton variant="primary" isWorking={isCreating} onClick={activateWallet}>
              Activate wallet
            </ActionButton>
          </EmptyState>
        }

        {isLoading && 
          <GradientLoader />
        }
        </ServicePageContent>
      </ServicePageContainer>
    </div>
  );
}
