import { Button } from "shared/components";
import { color } from "shared/utils/styles";
import styled from "styled-components";

export const AgencyPage = styled.div`
  overflow-y: auto;
  height: 100%;
`;

export const StatisticsRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 25px;
  @media (max-width: 520px) {
    flex-direction: column;
    padding-right: 25px;
  }
`;

export const StatisticsBox = styled.div`
  background-color: #ffffff;
  width: calc(${100 / 3}%);
  text-align: center;
  padding: 20px 15px;
  margin: 10px 15px;
  box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.05);
  border-radius: 18px;
  @media (max-width: 520px) {
    width: 100%;
  }
`;

export const StatisticsBoxTwo = styled.div`
  background-color: #DEB6FC;
  width: calc(${55}%);
  text-align: center;
  padding: 20px 15px;
  margin: 10px 15px;
  box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.05);
  border-radius: 18px;
  @media (max-width: 520px) {
    width: 100%;
  }
`;

export const StatisticsBoxTwoTwo = styled.div`
  background-color: #ffffff;
  width: calc(${45}%);
  text-align: center;
  padding: 20px 15px;
  margin: 10px 15px;
  box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.05);
  border-radius: 18px;
  @media (max-width: 520px) {
    width: 100%;
  }
`;

export const StatisticsBoxFour = styled.div`
  background-color: #ffffff;
  width: calc(${100 / 4}%);
  text-align: center;
  padding: 20px 15px;
  margin: 10px 15px;
  box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.05);
  border-radius: 18px;
  @media (max-width: 520px) {
    width: 100%;
  }
`;

export const StatisticsCircleBlue = styled.div`
  background: rgba(142, 7, 248, 0.23);
  width: 44px;
  height: 44px;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StatisticsCircleGreen = styled.div`
  background: rgba(4, 183, 118, 0.23);
  width: 44px;
  height: 44px;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StatisticsCircleRed = styled.div`
  background: rgba(248, 110, 127, 0.23);
  width: 44px;
  height: 44px;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StatisticsCircleBrown = styled.div`
  background: rgba(118, 0, 0, 0.23);
  width: 44px;
  height: 44px;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StatisticsHeading = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #292929;
  margin-bottom: 8px;
`;

export const StatisticsDate = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: rgba(130, 130, 130, 0.9);
  margin-bottom: 10px;
`;

export const StatisticsAmountBlue = styled.p`
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #8e07f8;
`;

export const StatisticsAmountGreen = styled.p`
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #04b776;
`;

export const StatisticsAmountRed = styled.p`
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #f86e7f;
`;

export const StatisticsAmountBrown = styled.p`
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #760000;
`;

export const ActionButton = styled(Button)`
  margin: 1em 0;
  color: #fff;
  width: 138px;
  /* width: 70%; */
  float: right;
  transition: 0.8s all ease;
  background-color: ${color.fountain};
  &:hover {
    background-color: #eff3f5 !important;
    color: #000000;
    border: 1px solid #000000;
  }
`;
