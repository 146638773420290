import styled from "styled-components";
import { color } from "shared/utils/styles";
import { sizes } from "shared/utils/styles";

const paddingLeft = sizes.appNavBarLeftWidth + sizes.secondarySideBarWidth + 40;

export const KycPage = styled.div`
  padding: 25px 32px 50px ${paddingLeft}px;
  margin-left: -38px;
  margin-top: -80px;
  @media (max-width: 1100px) {
    padding: -25px 20px 50px ${paddingLeft - 20}px;
  }
  @media (max-width: 999px) {
    padding-left: ${paddingLeft - 20 - sizes.secondarySideBarWidth}px;
  }
`;

export const LogoutModal = styled.div`
  position: fixed;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DialogBox = styled.div`
  background: #fbfbfb;
  box-shadow: 0px 4px 100px rgba(0, 0, 0, 0.04);
  border-radius: 20px;
  width: 665px;
  height: 286px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const LogoutModalText = styled.p`
  font-weight: 600;
  font-size: 32px;
  line-height: 38px;
  text-align: center;
  color: #292929;
`;

export const ButtonCont = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
`;

export const ActionBtn = styled.p`
  background: ${color.fountain};
  border-radius: 5px;
  width: 188px;
  height: 55px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  cursor: pointer;
  margin-bottom: 2rem;
`;
