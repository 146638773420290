import api from "shared/utils/api";
import toast from "shared/utils/toast";

export const callServiceDetails = async (payload: any) => {
  try {
    const response: any = await api.get(`/services/${payload?.id}`, payload);
    if (response.status) return response;
    else toast.error(response.message);
  } catch (error) {}
};

export const getServiceKeys = async (payload: any) => {
  try {
    const response: any = await api.get(
      `/services/${payload?.id}/keys`,
      payload
    );
    if (response.status) return response;
    else toast.error(response.message);
  } catch (error) {}
};

export const regenerateApiKeys = async (serviceID: string, payload: any) => {
  try {
    const response: any = await api.post(
      `/services/${serviceID}/keys`,
      payload
    );
    if (response.status) return response;
    else toast.error(response.message);
  } catch (error) {}
};

export const toogleServiceMode = async (serviceID: string, payload: any) => {
  try {
    const response: any = await api.put(`/services/${serviceID}`, payload);
    if (response.status) return response;
    else toast.error(response.message);
  } catch (error) {}
};

export const getRecentTransactions = async (
  serviceId: string,
  payload: any
) => {
  try {
    // console.log("Trans: ", payload)
    const response = await api.get(
      `/services/${serviceId}/transactions`,
      payload
    );
    if (response?.status) return response;
  } catch (error) {}
};

export const getRecentSettlements = async () => {};
