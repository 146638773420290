import {
  ExitLabel,
  ExitPageWrapper,
  ActionButtonExit1,
  ActionButtonExit2,
  ExitButtonWrapper,
} from "./Styles";
export default ({ setVisible, yesClick }: any) => {
  return (
    <ExitPageWrapper>
      <ExitLabel>Are you sure you want to exit this page?</ExitLabel>
      <ExitButtonWrapper>
        <ActionButtonExit1 onClick={() => yesClick()}>Yes</ActionButtonExit1>
        <ActionButtonExit2 onClick={() => setVisible(false)}>
          No
        </ActionButtonExit2>
      </ExitButtonWrapper>
    </ExitPageWrapper>
  );
};
