import { color } from "shared/utils/styles";
import styled from "styled-components";

interface PropT {
  url?: string;
}

export const PageContainer = styled.div`
  width: 100%;
`;

export const PageHead = styled.div`
  width: 80vw;
  margin-bottom: 1rem;

  h2 {
    font-size: 28px;
    color: ${color.fountain};
  }
`;

export const Main = styled.div`
  width: 80vw;
  margin: auto;
  margin-top: 1.5rem;
  display: flex;
  gap: 2rem;
  background-color: #fff;
  margin-bottom: 4rem;
`;

export const Container = styled.div`
  width: 70%;
  padding: 2.2rem 2.8rem;
`;

export const LeftSidebar = styled.div`
  padding: 2rem 0;
  width: 100%;
`;

export const RightSidebar = styled.div<PropT>`
  width: 30%;
  min-height: 100vh;
  background-image: url(${(props) => props.url});
  background-repeat: no-repeat;
  background-size: cover;
`;

export const ResolutionForm = styled.form`
  margin-top: 0.4rem;
`;

export const InputRow = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
  margin-bottom: 2rem;
  align-items: center;

  > div {
    width: 80%;
    > input {
      width: 100% !important;
    }
    > p {
      font-size: 12px;
    }
  }
`;

export const InputLabel = styled.label`
  font-size: 14px;
  font-weight: bold;
  width: 20%;
`;

export const InputField = styled.input`
  width: 80%;
  padding: 1rem;
  border: 1px solid ${color.backgroundMedium};
  border-radius: 5px;
`;

export const GroupedInput = styled.div`
  display: flex;
  width: inherit;
  width: 80%;
`;

export const GroupedInputField = styled.input`
  width: 90%;
  padding: 1rem;
  border: 1px solid ${color.backgroundMedium};
  border-radius: 5px;
`;

export const GroupedInputLabel = styled.div`
  background: #d7d7d7;
  display: flex;
  justify-content: center;
  align-items: center;
  /* color: ${color.backgroundLightest}; */
  width: 15%;
`;

export const SelectField = styled.select`
  width: 80%;
  padding: 0.9rem;
  border: 1px solid ${color.backgroundMedium};
  border-radius: 5px;
  color: ${color.textLight};
`;

export const SubmitButton = styled.button`
  height: 55px;
  width: 35%;
  background: ${color.fountain};
  color: #fff;
  border-radius: 5px;
  margin-left: 9rem;
  margin-top: 2rem;
`;
