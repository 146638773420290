import api from "shared/utils/api";
import toast from "shared/utils/toast";
import consoleLog from "shared/utils/consoleLog";

class Overview {
  //==========================================
  async getBusinessBalance(id: any) {
    try {
      const response = await api.get(`/client/business/${id}/balance`);
      // consoleLog(response);
      if (response.payload) return response.payload;
    } catch (error) {
      consoleLog("error fetching business balance", error);
    }
  }
  //==========================================

  //============ Today's transactions =======
  //This method is for grap on overview page
  async getTodayTractions(id: any) {
    try {
      const response = await api.get(
        // `/client/business/${id}/transactions`,
        `/business/${id}/transactions?filter=day`
      );
      return response?.payload;
    } catch (error) {
      consoleLog("can't fetch today's transactions", error);
    }
  }

  //=============================================

  //============ This week transactions =======
  async getTransactions(id: any, filterby: any) {
    try {
      const response = await api.get(
        // `/client/business/${id}/transactions?filter=${filterby}`
        `/business/${id}/transactions?filter=${filterby}`
      );
      consoleLog(response, "dataOverview");
      return response?.payload;
    } catch (error) {
      consoleLog("can't fetch today's transactions", error);
    }
  }

  //============ This Custom transactions =======
  async getCustomTransactions(
    filter: string,
    id: any,
    startdate: any,
    enddate: any
  ) {
    try {
      const response = await api.get(
        // `/client/business/${id}/transactions?filter=${filter}startDate=${startdate}&endDate=${enddate}`
        `/business/${id}/transactions?filter=${filter}&startDate=${startdate}&endDate=${enddate}`
      );
      consoleLog(response, "customdataOverview");
      return response?.payload;
    } catch (error) {
      // @ts-ignore
      toast.error(error);
      consoleLog("can't fetch custom transactions", error);
    }
  }
  //============================================
}

const overviewClass = new Overview();
export default overviewClass;
