import styled from 'styled-components'
import RightImg from 'App/assets/images/placeholder/right-img-2.svg'
import { color, font } from 'shared/utils/styles'
import breakpoints from 'shared/utils/breakpoints'
import { Button } from 'shared/components'

export const ParentCont = styled.div`
	background-color: #e5e5e5;
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	overflow-y: auto;
`

export const LeftPanel = styled.div`
	width: 60%;
	height: 100%;
	padding-left: 12%;
	padding-right: 12%;
	vertical-align: center;
	padding-top: 8%;
	background-color: transparent;
	@media ${breakpoints.device.max.md} {
		width: 100%;
	}
`

export const FormCont = styled.div`
	overflow-y: auto;
	width: 100%;
	height: 600px;
	margin: 0px auto;
	@media ${breakpoints.device.max.md} {
		width: 100%;
		height: 100%;
	}
	@media ${breakpoints.device.max.sm} {
		height: 100%;
	}
`

export const RightPanel = styled.div`
	width: 40%;
	height: 100vh;
	background-image: linear-gradient(
			180deg,
			rgba(0, 0, 0, 0.5),
			rgba(0, 0, 0, 0.5)
		),
		url(${RightImg});
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: space-between;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	@media ${breakpoints.device.max.sm} {
		display: none;
	}
`

export const RightPanelAuxCont = styled.div` 
    width: 100%
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-right: 5%;
    padding-top: 30px;
`

export const RightPanelAuxCon2 = styled.div` 
    width: 100%
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-bottom: 50px;
`

export const CloseBtn = styled.p`
	background-color: #fff;
	border-radius: 50%;
	width: 40px;
	height: 40px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	align-self: right;
	&:hover {
		cursor: pointer;
	}
`

export const DescriptionBox = styled.div`
	background-color: transparent;
	border-radius: 20px;
	border: 2px solid #fff;
	padding: 20px 2px;
	width: 70%;
	height: 170px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
`

export const DescriptionTitle = styled.p`
	width: 70%;
	text-align: center;
	font-weight: 600;
	${font.size(20)};
	color: #fff;
	line-height: 23.44px;
`

export const DescriptionText = styled.p`
	width: 70%;
	text-align: center;
	font-weight: 400;
	${font.size(16)};
	color: #fff;
	line-height: 18.75px;
`

export const DescriptionBtn = styled.button`
	background-color: transparent;
	border-radius: 10px;
	border: 1px solid white;
	padding: 10px;
	width: 100px;
	text-align: center;
	font-weight: 400;
	${font.size(14)};
	color: #fff;
	line-height: 16px;
	transition: ease-in-out 0.3s;
	&:hover {
		cursor: pointer;
	}
	&:clicked {
		color: #000;
		background-color: #fff;
	}
`

export const TitleCont = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 10px;
`

export const Back = styled.div`
	border-radius: 50%;
	height: 30px;
	width: 30px;
	margin-right: 15px;
	font-weight: 700;
	font-size: 18px;
	color: #828282;
	background-color: rgba(215, 215, 215, 0.38);
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	&:hover {
		cursor: pointer;
	}
`

export const FormTitle = styled.h1`
	font-weight: 700;
	${font.size(32)};
	line-height: 37.5px;
	color: black;
	text-align: left;
	width: 100%;
`

export const FormSubTitle = styled.p`
	margin-left: 8.5%;
	font-weight: 400;
	font-size: 14px;
	color: #828282;
	text-align: left;
	margin-bottom: 40px;
	@media ${breakpoints.device.max.md} {
		margin-left: 0%;
	}
`

export const SelectionBox = styled.div`
	width: 100%;
	height: 80px;
	padding: 10px;
	padding-top: 25px;
	border: 1px solid grey;
	background-color: #fff;
	border-radius: 7px;
	margin-bottom: 15px;
	font-weight: normal;
	transition: ease-in-out 0.3s;
	&:hover {
		cursor: pointer;
		border: 3px solid ${color.fountain};
	}
`

export const Selectors = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 2em;
	width: 100%;
`

export const ActionButton = styled(Button)`
	border-radius: 9px;
	color: #fff;
	width: 35%;
	float: right;
	background-color: ${color.fountain};
	@media ${breakpoints.device.max.sm} {
		width: 100%;
	}
`
