import api from "shared/utils/api";
import Api from "shared/utils/apii";
import ApiMultiPart from "shared/utils/apiiMultiPart";
import { storeAuthToken } from "shared/utils/authToken";
import { handleApiError } from "shared/utils/handleApiError";
import {
  // getClientDetails,
  decryptData,
} from "shared/utils/security";
import consoleLog from "shared/utils/consoleLog";
import toast from "shared/utils/toast";

export const login = async (value: any) => {
  try {
    // consoleLog("values", value);
    const data = await api.post("/authentication/login", { ...value });
    const authToken = data?.payload?.accessToken;
    if (!authToken) {
      toast.error("Unable to Login");
    }
    storeAuthToken(authToken);
    toast.success("Login was successful.");
    return data;
  } catch (error) {
    consoleLog(error, "Erorrr");
    //@ts-ignore
    toast.error(error ?? "Backend Error");
  }
};

export const uploadFile = async (value: any) => {
  consoleLog("selected size ", value.size);
  try {
    const res = await api.post("/business/kyc/upload", value);
    // consoleLog(res, "datta1");
    if (res.data) res.data = decryptData(res.data, false);
    // consoleLog(res, "datta");
    return res;
  } catch (error) {
    consoleLog(error);
    throw handleApiError(error);
  }
};

export const toggleMode = async (value: any) => {
  try {
    const data = await api.post(
      `/client/business/${value?.id}/toggle-mode`,
      value
    );
    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};
