import AccountSettings from "AccountSettings";
import Agency from "Agency";
import Authenticate from "Auth/Authenticate";
import history from "browserHistory";
import KYC from "KYC-refactored";
import { useState } from "react";
import { Redirect, Route, Router, Switch } from "react-router-dom";
import PageError from "shared/components/PageError";
import Recovery from "AccountRecovery";
import AcceptInvite from "../AcceptInvite/Home";
import Layout from "Layout";
import LinkUpUPS from "LinkUpUPS";
import LinkUpInt from "LinkUpInt";
import DisputeResolution from "DisputeResolution";
import PaymentLinkModal from "PaymentLinkModal";
import Reciept from "Reciept";
import Invitation from "Invitation";

const Routes = () => {
  const [isLoading, setisLoading] = useState(false);

  function urlChecker(stateSetter: Function, state: boolean) {}
  // const history = useHistory();

  return (
    <>
      {/* {isLoading ? (
        <GradientLoader />
      ) : (
        <Router history={history}>
          <Switch>
            <Route path="/authenticate" component={Authenticate} />
            <Route path="/"  component={Layout} />
            <Route path="/recovery" component={Recovery} />
            <Route component={PageError} />
          </Switch>
        </Router>
      )} */}

      <Router history={history}>
        <Switch>
          {/* <Route
            path="/authenticate/team/:id"
            component={AuthenticateNewMember}
          /> */}
          <Route path="/authenticate" component={Authenticate} />
          <Route
            path="/linkUp/:md/:paReq/:termUrl/:url"
            component={LinkUpUPS}
          />
          <Route
            path="/linkInt/:MD/:JWT/:TermUrl/:ACSUrl"
            component={LinkUpInt}
          />
          <Route path="/reciept/:transId/:payLink" component={Reciept} />
          <Route path="/kyc" component={KYC} />
          <Route path="/invitations" component={Invitation} />
          <Route path="/recovery" component={Recovery} />
          <Route path="/invite/:id" component={AcceptInvite} />
          <Route path="/link/:id" component={PaymentLinkModal} />
          <Route path="/dispute-resolution" component={DisputeResolution} />
          <Route path="/dispute-resolution/:id" component={DisputeResolution} />
          <Route path="/" component={Layout} />
          <Route path="*" component={PageError} />
        </Switch>
      </Router>
    </>
  );
};

export default Routes;
