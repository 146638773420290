import { authPath } from "apiServices/paths";
import Gmail from "App/assets/images/icons/gmail.svg";
import Outlook from "App/assets/images/icons/outlook.svg";
import Logo from "App/assets/images/logo.svg";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { OTPInput } from "shared/components";
import Preloader from "shared/components/preloader";
import api from "shared/utils/api";
import { getTempId } from "shared/utils/authToken";
import toast from "shared/utils/toast";
import {
  Actions,
  Footer,
  Info,
  OtpContainer,
  ReportBody,
  SubTitle,
  Title,
  OtpBtnContainer,
  OtpBtn,
  AuxOtpText,
} from "./Styles";
import { omniGetter, omniRemover } from "shared/utils/authToken";
import { storeTempId } from "shared/utils/authToken";
import { GradientLoader, Modal } from "shared/components";
import { RootStateOrAny, useSelector } from "react-redux";
import consoleLog from "shared/utils/consoleLog";

const OtpModal = ({
  callBackFn,
  closeModal,
  startHere,
  type,
  emailType,
}: any) => {
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isLoginIn, setIsLoginIn] = useState(false);
  const [sentOtp, setSentOtp] = useState(false);
  const history = useHistory();
  const currentUserEmail = useSelector(
    (store: RootStateOrAny) => store.currentUser.email
  );

  consoleLog("user", currentUserEmail, emailType);

  const [otp, setOtp] = useState("");

  const resendOtp = async () => {
    // let email = omniGetter('otp_email');
    setIsLoginIn(true);
    try {
      let data;
      if (type === "auth-user") {
        data = await api.post("/shared/send-auth-otp", {
          email: currentUserEmail,
          email_type: emailType
        });
      } else {
        data = await api.post("/shared/send-otp", {
          email: currentUserEmail,
        });
      }
      consoleLog(data, "dataOTP");

      const { payload }: any = data;
      consoleLog(payload);
      storeTempId(payload?.tempId);
      setIsLoginIn(false);
      setSentOtp(true);
    } catch (error) {
      consoleLog("OTP is here", error);
      // @ts-ignore
      toast.error(error ?? "Something went wrong");
      setIsLoginIn(false);
      setSentOtp(false);
    }
  };

  const handleChange = async (otp: string) => {
    let tempId: any = getTempId();
    setError(false);
    setOtp(otp);
    if (otp.length === 6) {
      const values: any = {
        tempId,
        otp,
      };
      // setIsLoginIn(true);
      try {
        const data = await api.post("/shared/verify-otp", {
          ...values,
        });

        consoleLog(data);
        closeModal(false);
        callBackFn();

        toast.success("OTP is Verified");
        // history.push(authPath.OTP_SUCCESS);
      } catch (error) {
        //history.push(authPath.OTP_FAILED)
        setError(true);
        toast.error("Invalid OTP");
      }
    }
  };
  useEffect(() => {
    if (startHere) resendOtp();
  }, [startHere]);
  if (isLoginIn) {
    return <GradientLoader />;
  }
  return (
    <ReportBody>
      <img src={Logo} alt="Logo" />
      <Title>Check your email for a code.</Title>
      <SubTitle>
        We have sent a 6 - digit code to your email address. <br /> The code
        expires shortly, please enter it soon.
      </SubTitle>

      <OtpContainer>
        {" "}
        <OTPInput
          autoFocus
          isNumberInput
          length={6}
          hasError={error}
          errorStyle={{ borderColor: "red" }}
          inputStyle={{
            border: "1px solid #828282",
            width: "74px",
            height: "78px",
            fontSize: "30px",
            color: "#000",
            fontWeight: "400",
            caretColor: "#000",
            textAlign: "center",
            borderRight: "0px",
          }}
          focusStyle={
            {
              //border: "1px solid #CFD3DB",
            }
          }
          firstIndexStyle={{
            borderTopLeftRadius: "8px",
            borderBottomLeftRadius: "8px",
            borderRight: "0px",
          }}
          lastIndexStyle={{
            borderTopRightRadius: "8px",
            borderBottomRightRadius: "8px",
            borderRight: "1px solid #828282",
            borderLeft: "1px solid #828282",
          }}
          className="otpContainer"
          inputClassName="otpInput"
          separator={
            <span
              style={{
                marginLeft: "10px",
                marginRight: "10px",
                marginTop: "10%",
                fontSize: "50px",
                color: "black",
                fontWeight: "bold",
              }}
            >
              -
            </span>
          }
          onChangeOTP={handleChange}
        />
      </OtpContainer>

      <OtpBtnContainer>
        <OtpBtn onClick={resendOtp}>
          Haven't gotten your OTP ?<AuxOtpText> Resend</AuxOtpText>
        </OtpBtn>
      </OtpBtnContainer>

      <Actions>
        <div
          onClick={() => window.open("https://www.gmail.com", "_blank")}
          style={{ cursor: "pointer" }}
        >
          <img src={Gmail} alt="Gmail" />
          <p>Open Gmail.</p>
        </div>

        <div
          onClick={() => window.open("https://outlook.office.com/", "_blank")}
          style={{ cursor: "pointer" }}
        >
          <img src={Outlook} alt="Outlook" />
          <p>Open Outlook.</p>
        </div>
      </Actions>

      <Info>Can’t find your code? Check your spam folder.</Info>
      <Footer>
        <p style={{ cursor: "pointer" }}>Privacy and terms</p>{" "}
        <p style={{ cursor: "pointer" }}>Contact us</p>
      </Footer>
    </ReportBody>
  );
};

export default OtpModal;
