import { useHistory } from "react-router-dom";
import { CardCont, CardInnerCont, InnerText } from "./styles";
import Icon from "../Icon";

interface CardInt {
  icon: string;
  text: string;
  link?: string;
  clickFn?: Function;
  target?: string;
  disabled?: boolean;
}

const OverviewTopCard = ({
  icon,
  text,
  link,
  clickFn,
  target,
  disabled,
}: CardInt) => {
  const history = useHistory();

  // const handleClick = () => {
  //   onClick();
  // };
  return (
    <>
      {link ? (
        <CardCont
          onClick={() =>
            target ? window.open(link, target) : history.push(link)
          }
          disabled={disabled}
        >
          <CardInnerCont>
            {/* <img src={icon} alt="" /> */}
            <Icon type={icon} size={25} color={'#760000'} />
            <InnerText>{text}</InnerText>
          </CardInnerCont>
        </CardCont>
      ) : (
        <CardCont onClick={() => clickFn && clickFn()} disabled={disabled}>
          <CardInnerCont>
            {/* <img src={icon} alt="" /> */}
            <Icon type={icon} size={25} color={'#760000'} />
            <InnerText>{text}</InnerText>
          </CardInnerCont>
        </CardCont>
      )}
    </>
  );
};

export default OverviewTopCard;
