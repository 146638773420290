import React, { useState, useEffect, useLayoutEffect } from "react";
import Table from "shared/components/Table";
import {
  ActionBtn,
  AddTeamBtn,
  ButtonsContainer,
  DeleteText,
  PageContainer,
  PageHeader,
  PaginationButtonsContainer,
  PaginationContainer,
  PaginationNext,
  PaginationPrev,
  TableActions,
  TableContainer,
  ViewAllBtn,
} from "./Styles";
import pen from "../../../App/assets/icons/pen.png";
import trash from "../../../App/assets/icons/trash.svg";
import { GradientLoader, Modal } from "shared/components";
import { useHistory } from "react-router-dom";
import ConfirmModal from "shared/quickComponents/ConfirmModal";
// import OtpModal from "shared/quickComponents/OtpModal";
import OtpModal from "../../../shared/components/OtpModal";

import MandatesClass from "../mandateClass";
import consoleLog from "shared/utils/consoleLog";
import { RootStateOrAny, useSelector } from "react-redux";
import shouldPerform, { getRoleName } from "shared/utils/assignRoles";
import EmptyState from "shared/components/FreeComp/EmptyState";
import FailedAuth from "../../../App/assets/failedAuth.png"
import { formatDateInSlashes } from "shared/utils/dateTime";
import { covertToNaira, formatAmount } from "shared/utils/formatAmount";
import DashboardSubHeader from "shared/components/DashboardSubHeader";

export default function Home() {
  const [mandates, setMandates] = useState([]);
  const [start, setStart] = useState("2023-03-20");
  const [end, setEnd] = useState("2023-03-30");

  const [isLoading, setIsLoading] = useState(false);

  const [openDownload, setOpenDownload] = useState<boolean>(false);
  const [fetchedData, setFetchedData] = useState<any>([]);
  const [fetchedPayload, setFetchedPayload] = useState<any>([]);

  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const [currentPage, setcurrentPage] = useState(1);
  const [totalPages, settotalPages] = useState(0);
  const [isData, setisData] = useState(false);



  const [modal, setModal] = useState(false);
  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);
  const [showIndicator, setshowIndicator] = useState(false);
  const [otpModalOpen, setOtpModalOpen] = useState(false);
  const [otp, setOtp] = useState("");
  const currentUser = useSelector((store: RootStateOrAny) => store.currentUser);
  const [seletedMember, setseletedMember] = useState<any>({});

  const service = useSelector((store: RootStateOrAny) => store.service);

  const currentBusiness = useSelector(
    (store: RootStateOrAny) => store.business
  );

  const history = useHistory();

  const sendOTP = async () => {
    // setOtpModalOpen(true);
    setModal(true);
    setConfirmModalOpen(false);
    // setshowIndicator(true);
  };

  const handleOTP = async (otp: string) => {
    setOtp(otp);
    if (otp.length === 6) {
    }
  };

  const handleChangeRole = (id: string) => {
    history.push(`/mandate-management/create-mandate`);
  };

  

  const handleRemoveTeamMember = (obj: any) => {
    setseletedMember(obj);
    setConfirmModalOpen(true);
  };

  const deleteTeamMember = async () => {
    // seletedMember
    await MandatesClass.deleteMandate(seletedMember);
    await getMandates();
  };

  const tableHeader = [
    { id: "mandateRef", label: "Reference No"},
    { id: "accountNumber", label: "Account No" },
    { id: "bankCode", label: "Bank" },
    { id: "accountName", label: "Account Name" },
    { id: "payerName", label: "Payer Name" },
    { id: "emailAddress", label: "Email" },
    { id: "amount", label: "Max permitted amount" },
    { id: "startDate", label: "Start Date"},
    { id: "endDate", label: "End Date" },
    { id: "createdAt", label: "Date"},
    { id: "status", label: "Status" },
    { id: "funcProps", label: "" },
  ];
 

  const getMandates = async () => {
    setIsLoading(true);
    try{
      const response = await MandatesClass.getMandates(
        {
          "service_id": service?.id,
          "start_date": start,
          "end_date": end,
          "page": currentPage,
          "limit": 10,
          "status": "Biller Initiated"
      }
      );
  
      //alert(JSON.stringify(response));
      if (response.totalItems>0) {
        setcurrentPage(response?.currentPage);
        settotalPages(response?.totalPages);
        setFetchedPayload(response.mandates)
        setMandates(response.mandates);
      }
    }catch(err: any){

    }finally{
      setIsLoading(false);
    }
    
  };

  const nextPage = () => {
    if (currentPage < totalPages) {
      setcurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setcurrentPage(currentPage - 1);
    }
  };

  const goToPage = (val: number) => {
    if (val < currentPage) {
      prevPage();
    } else if (val > currentPage) {
      nextPage();
    }
  }; //check Tansaction in Services
  const authorizeKey=async()=>{
    setIsAuthenticating(true)
    try{
      await MandatesClass.authorizeService(service?.publicKey);
      await getMandates();
      setIsAuthenticated(true)
    }catch(err: any){
      setIsAuthenticated(false)
    }finally{
      setIsAuthenticating(false)
    }
    
  }

  const mandateObjs = mandates instanceof Array ? 
    mandates?.map((obj: any, index: number) => {
    
      return {
        // sn: index + 1,
        mandateRef: `${obj?.mandateRef ?? ""}`,
        startDate: formatDateInSlashes(obj.startDate),
        endDate: formatDateInSlashes(obj.endDate),
        accountNumber: obj?.accountNumber,
        bankCode: obj?.bankCode,
        accountName: obj.accountName,
        payerName: obj.payerName,
        emailAddress: obj.emailAddress,
        amount: formatAmount(obj.amount),
        createdAt: obj.createdAt,
        status: obj?.status,
        funcProps: (
          <TableActions>
            
            {shouldPerform(
              getRoleName(currentUser, currentBusiness?.id),
              "CTeams"
            ) && (
              <ViewAllBtn onClick={() => handleRemoveTeamMember(obj)}>
                <ActionBtn src={trash} />
                <DeleteText>Delete</DeleteText>
              </ViewAllBtn>
            )}
          </TableActions>
        ),
      };
    })
    : [];

  const SearchForData = (e: any) => {
    let searchedArr: any = [];
    mandateObjs.forEach((transaction: any) => {
      if (transaction.narration.toLowerCase().indexOf(e.toLowerCase()) !== -1) {
        searchedArr.push(transaction);
      }
    });
    setFetchedData(searchedArr);
  };
  useLayoutEffect(() => {
    authorizeKey();
  }, [currentPage]);

  // useEffect(() => {
  // 	//consoleLog("team member", seletedMember);
  // }, [seletedMember])

  if (!shouldPerform(getRoleName(currentUser, currentBusiness?.id), "VTeams")) {
    history.push("/authenticate");
    return null;
  }

  return (
    <div>
      <PageContainer>
        {/* <DashboardSubHeader
          title={`${mandateObjs} Mandates`}
          btnTitle="Download"
          callBackFn={() => setOpenDownload(!openDownload)}
          openDownload={openDownload}
          setOpenDownload={setOpenDownload}
          dateFilter={false}
          setSearchData={SearchForData}
          withSearch={false}
          withFilter={mandates.length > 0 ? true : true}
          filterType="transaction"
          arrToFilter={transactionFilter}
          resetFilter={resetFilter}
          applyFilter={filterTransactions}
          getParams={setParams}
          clickedParam={"Transaction Description"}
          widthActionBtn={transactionData.length > 0 ? true : false}
          excelExport
          excelExportData={summaryData(rowCount, columnHeaders, data)}
          setFilterFormState
        /> */}
        <PageHeader>
          <h4>List of Mandates</h4>
          {shouldPerform(
            getRoleName(currentUser, currentBusiness?.id),
            "CTeams"
          ) && (
            <ButtonsContainer>
              <AddTeamBtn onClick={() => history.push(`/my-apps/${service?.id}/mandate-management/create-mandate`)}>
                Create new mandate
              </AddTeamBtn>
            </ButtonsContainer>
          )}
        </PageHeader>
        {isLoading ? (
          <GradientLoader />
        ) : (
          <>
            <TableContainer>
            <Table
              headData={tableHeader}
              bodyData={mandateObjs}
              rowStyle={{
                background: "#ffffff",
              }}
              serialNumber
              totalPages={totalPages ?? 0}
              currentpage={currentPage ?? 0}
              goToPage={goToPage}
              emptyStateMessage="No mandate set up yet. Kindly create mandate to activate this service."
              // hoverLink={hoverLink}
            />
            </TableContainer>
          </>
        )}
      </PageContainer>
      {shouldPerform(
        getRoleName(currentUser, currentBusiness?.id),
        "CTeams"
      ) && (
        <>
          <Modal
            isOpen={modal}
            width={800}
            withCloseIcon={true}
            onClose={() => setModal(false)}
            // renderContent={() => <AddTeam />}
            renderContent={() => (
              <OtpModal
                callBackFn={deleteTeamMember}
                startHere
                closeModal={setModal}
                emailType="verification"
                type="auth-user"
              />
            )}
          />
          <ConfirmModal
            message="Are sure you want to remove this mandate?"
            handleYesModalButton={sendOTP}
            handleNoModalButton={() => setConfirmModalOpen(!isConfirmModalOpen)}
            isModalOpen={isConfirmModalOpen}
            showIndicator={showIndicator}
            handleCloseModal={() => setConfirmModalOpen(!isConfirmModalOpen)}
          />
        </>
      )}

      {/* <OtpModal
        // callBackFn={changeDetails}
        callBackFn={() => {}}
        closeModal={setOtpModalOpen}
      /> */}
      {/* <OtpModal
        onChangeOTP={handleOTP}
        onClose={() => setOtpModalOpen(false)}
        isOpen={otpModalOpen}
      /> */}
    </div>
  );
}
