import { RootStateOrAny, useSelector } from "react-redux";
import { SettlementSummary, MainAmount} from "./Style";
import { useEffect, useState } from "react";
import api from "shared/utils/api";
import { TotalAmount } from "../../Agency/Transactions/Styled";
import { abbrNum } from "shared/utils/utility";
import consoleLog from "shared/utils/consoleLog";
import { formatMoney__ } from "shared/utils/moneyFormat";

const AmountPanel = () => {
  const [summary, setsummary] = useState<any>({});
  const service = useSelector((store: RootStateOrAny) => store.service);

  const [isloading, setisloading] = useState(false);
  const [isDatafetched, setisDatafetched] = useState(false);
  const { todayTransaction, weeklyTransaction, monthlyTransaction } = summary;
  const transactionsCount: number =
    todayTransaction?.totalTransaction +
    weeklyTransaction?.totalTransaction +
    monthlyTransaction?.totalTransaction;

  const getSummary = async () => {
    try {
      const response = await api.get(`/services/${service?.id}/today-summary`);

      consoleLog(response, "summaryResponse");
      if (response) {
        setsummary(response?.payload);
        setisDatafetched(true);
        setisloading(false);
      } else {
        setisDatafetched(true);
        setisloading(false);
      }
    } catch (err) {
      consoleLog(err);
    }
  };
  useEffect(() => {
    getSummary();

    // console.log("type testing", typeof transactionsCount);
  }, []);
  return (
    <SettlementSummary>
      <div className={"title"}>Pending settlement</div>
      <div>
        <MainAmount>
          <div className={"amount"}>{formatMoney__(todayTransaction?.totalVolume) ?? "0"}</div>
          <div className={"title"}>
            {abbrNum(transactionsCount, 1) ?? "0"} transactions
          </div>
        </MainAmount>
      </div>
    </SettlementSummary>
  );
};

export default AmountPanel;
