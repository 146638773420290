import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import lodash from "lodash";
import { useEffect } from "react";
import { Bar } from "react-chartjs-2";
import { GraphContent, GraphHeader } from "./Styles";
import consoleLog from "shared/utils/consoleLog";
import { covertToNaira } from "shared/utils/formatAmount";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

type PropT = {
  transaction: any;
  selectedLabel: any;
  startDate: any;
  endDate: any;
  setSendData: any;
};

const BarChart = ({
  transaction,
  selectedLabel,
  startDate,
  endDate,
  setSendData,
}: PropT) => {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom" as const,
        display: false,
      },
      title: {
        // display: true,
        text: "Chart.js Bar Chart",
      },
    },
  };

  const labels: any = {
    today: [
      1,
      2,
      3,
      4,
      5,
      6,
      7,
      8,
      9,
      10,
      11,
      12,
      13,
      14,
      15,
      16,
      17,
      18,
      19,
      20,
      21,
      22,
      23,
      24,
    ],
    week: ["Sun", "Mon", "Tues", "Wed", "Thur", "Fri", "Sat"],
    month: [
      1,
      2,
      3,
      4,
      5,
      6,
      7,
      8,
      9,
      10,
      11,
      12,
      13,
      14,
      15,
      16,
      17,
      18,
      19,
      20,
      21,
      22,
      23,
      24,
      25,
      26,
      27,
      28,
      29,
      30,
      31,
    ],
    year: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    // custom: [...lodash.range(1, transaction?.length + 1)],
    custom: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ].slice(new Date(startDate).getMonth(), new Date(endDate).getMonth() + 1),
  };

  const sortData = (selected: string) => {
    // if (selected === "custom") {
    //   return transaction?.map((data: any) => data.amount);
    // }
    return labels[selected]?.map((record: any, idx: number, arr: any) => {
      const getAllRelated = transaction?.filter((trans: any) => {
        switch (selected) {
          case "today":
            return idx + 1 === new Date(trans?.createdAt).getHours();

          case "week":
            return (
              idx === new Date(trans?.createdAt).getDay() &&
              new Date().getDate() - new Date(trans?.createdAt).getDate() <=
                new Date().getDay()
            );

          case "month":
            return idx + 1 === new Date(trans?.date).getDate();

          case "year":
            // return idx === new Date(trans?.createdAt).getMonth();
            return (
              arr[idx]?.trim().toLowerCase() ===
              trans?.label
                ?.toString()
                .trim()
                .toLowerCase()
                .substring(0, 3)
            );
          case "custom":
            return idx === new Date(trans?.createdAt).getMonth();

          default:
            return true;
        }
      });
      const allrec = getAllRelated?.map((rc: any) => +rc.amount);
      return covertToNaira(lodash.sum(allrec));
    });
  };

  const data = {
    labels: labels[selectedLabel],
    datasets: [
      {
        label: "",
        backgroundColor: "#b6dfba",
        borderColor: "rgb(255, 99, 132)",
        // data: [0, 10, 5, 2, 20, 30, 45],
        // data: amounts[selectedLabel].map((transaction:any)=>{

        // }),
        data: sortData(selectedLabel),
      },
    ],
  };

  //consoleLog(data, "datta");

  useEffect(() => {
    setSendData(data);
  }, [selectedLabel]);

  return (
    <>
      <GraphHeader></GraphHeader>
      <GraphContent>
        <Bar options={options} data={data} />
      </GraphContent>
    </>
  );
};

export default BarChart;
