import ContentLoader from 'react-content-loader'

import { Loader } from '../Styles'
import React from 'react'

const BoxTileLoader = () => (
	<Loader>
		<ContentLoader
			height={500}
			width={980}
			speed={2}
			primaryColor='#ebe4e4'
			secondaryColor='#ecebeb'>
			{[...Array(8)].map((_, index) => (
				<rect
					key={index}
					x={(index % 4) * 250}
					y={Math.floor(index / 4) * 151}
					rx='3'
					ry='3'
					width='240'
					height='140'
				/>
			))}
		</ContentLoader>
	</Loader>
)


export default BoxTileLoader
