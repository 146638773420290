import api from "shared/utils/api";
import consoleLog from "shared/utils/consoleLog";
import toast from "shared/utils/toast";
class Team {
  async getTeamMembers(id: any, page = 1) {
    try {
      const response = await api.get(`/business/${id}/team?page=${page}`);
      if (response?.status) return response?.payload;
    } catch (error) {
      consoleLog("error fetching team member list", error);
    }
  }

  async getInvitedTeamMembers(businessId: any, page = 1) {
    try {
      const response = await api.get(
        `/business/${businessId}/team/invitations?page=${page}`
      );
      if (response?.status) return response?.payload;
    } catch (error) {
      consoleLog("error fetching team member list", error);
    }
  }

  async inviteTeamMember(id: any, data: any) {
    consoleLog("invite payload business id", data);
    try {
      const response = await api.post(`/business/${id}/team`, data);
      if (response.status) return response;
    } catch (error) {
      consoleLog("error adding team member", error);
      return error;
    }
  }

  async updateTeamRole(id: any, data: any) {
    consoleLog("update Team role", data, id);

    try {
      const response = await api.put(
        `/business/${id}/team/${data?.teamId}`,
        data
      );
      if (response.status) return response;
    } catch (error) {
      consoleLog("error adding team member", error);
      return error;
    }
  }

  async resendInvite(data: any) {
    try {
      const response = await api.post(
        `/business/${data?.businessId}/team/${data?.id}/resend`
      );
      consoleLog(response, "resendInvite");
      if (response.status && response.message) {
        toast.success(response.message);
      }
      return response;
    } catch (error) {
      consoleLog("error resending Invite", error);
      return error;
    }
  }

  async deleteTeamMember(data: any) {
    try {
      const response = await api.delete(
        `/business/${data?.businessId}/team/${data?.id}`
      );
      consoleLog(response, "resendInvite");
      if (response.status && response.message) {
        toast.success(response.message);
      }

      return response;
    } catch (error) {
      consoleLog("error resending Invite", error);
      return error;
    }
  }
}

const teamOBJ = new Team();
export default teamOBJ;
