import api from "shared/utils/api";
import toast from "shared/utils/toast";
import consoleLog from "shared/utils/consoleLog";

interface Props {
  businessId: any;
  transactionId: any;
  error: any;
}

class dispute {
  async getUserDetails(transactionId: any) {
    try {
      const response = await api.get(`/client/transactions/${transactionId}`);
      consoleLog(response);
      if (response?.payload) return response?.payload;
    } catch (error) {
      // @ts-ignore
      toast.error(error);
      consoleLog("error fetching dispute", error);
    }
  }

  async sendDispute(payload: any) {
    consoleLog(payload);
    try {
      const response = await api.post("/chargeback/dispute", payload);
      consoleLog(response);
      if (response?.payload) return response?.payload;
      toast.success(response?.message);
    } catch (error) {
      toast.error(
        // @ts-ignore
        error || "Dispute has already been created for this transaction"
      );
      consoleLog("error sending dispute", error);
    }
  }
}

const disputeClass = new dispute();
export default disputeClass;
