import React, { useState, useEffect } from "react";
import DashboardSubActionHeader from "shared/components/DashboardSubActionHeader";
import Logo from "Services/assets/logo.svg";

import DashboardSubHeader from "shared/components/DashboardSubHeader";
import { EmptyContainer, StatusContainer } from "./Style";
import EmptyState from "shared/components/FreeComp/EmptyState";
import Table from "shared/components/Table";
import { filteredDataFn } from "shared/utils/filteredData";
import { RootStateOrAny, useSelector, useDispatch } from "react-redux";
import { GradientLoader } from "shared/components";
import api from "shared/utils/api";
import consoleLog from "shared/utils/consoleLog";
import toast from "shared/utils/toast";
import { useHistory, useParams } from "react-router-dom";
import shouldPerform, { getRoleName } from "shared/utils/assignRoles";
import summaryData from "shared/utils/dataSummary";
import ActivateButton from "shared/components/ActivateButton";
import { getStatusColor } from "shared/utils/statusColor";
import { storeToLocalStorage } from "shared/utils/authToken";

const SettlementList = ({ setPageView }: any) => {

  const [start, setStart] = useState<string | undefined>("");
  const [end, setEnd] = useState<string | undefined>("");

  const [searchData, setSearchData] = useState("");
  const [filteredColumn, setFilteredColumn] = useState("");
  const service = useSelector((store: RootStateOrAny) => store.service);
  const [settlementData, setSettlementData] = useState([] as any);
  const [fetchedData, setFetchedData] = useState<any>([]);
  const [openDownload, setOpenDownload] = useState<boolean>(false);
  const [recordTitle, setRecordTitle] = useState("");
  const business = useSelector((store: RootStateOrAny) => store.business);
  const currentUser = useSelector((store: RootStateOrAny) => store.currentUser);
  const [filteredFormState, setFilteredFormState] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const [param, setParams] = useState<any>({});

  const params: any = useParams();
  const history = useHistory();

  const columnHeaders = [
    { id: "date", label: "Date" },
    //{ id: "recipient", label: "Recipient" },
    { id: "amount", label: "Amount" },
    { id: "status", label: "Status" },
    { id: 'action', label: "",}
  ];

  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "NGN",
  });

  let data: any[] = [];
  let data1 = [
    {
      date: "Friday, June 5, 2022",
      recipient: "FUTA",
      amount: "NGN 500,000,000",
      settledAmount: "NGN 500,000,000",
      status: <StatusContainer variants="success">Paid</StatusContainer>,
    },
    {
      date: "Friday, June 5, 2022",
      recipient: "Fountain Tech",
      amount: "NGN 100,000,000",
      settledAmount: "NGN 500,000,000",
      status: <StatusContainer variants="warning">Paid</StatusContainer>,
    },
    {
      date: "Friday, June 5, 2022",
      recipient: "FUTA",
      amount: "NGN 500,000,000",
      settledAmount: "NGN 500,000,000",
      status: <StatusContainer variants="danger">Failed</StatusContainer>,
    },
  ];

  const settlementFilter = [
    {
      id: "createdAt",
      type: "date",
      title: "Date",
      list: [
        { title: "Start Date", name: "startDate", type: "start" },
        { title: "End Date", name: "endDate", type: "end" },
      ],
    },
  ];

  // data = Math.random() * 10 < 5 ? data : [];
  const getSettlements = async (obj = {}) => {
    try {
      const response = await api.get(`/services/${service?.id}/settlements`, {
        id: service?.id,
        ...obj,
      });
      if (response.status == true) {
        if (response.payload) {
          setFetchedData(response.payload.data);
          setSettlementData(response.payload);
          setRecordTitle(response.message ?? "");
          //await dispatch(setService(response.payload));
          //setServiceDetails(response.payload);
        } else {
          toast.error("Unable to fetch Service Details");
        }
      } else {
        toast.error(response.message);
      }
      //consoleLog(response, "responseServ");
    } catch (err) {
      consoleLog(err, "error");
      // @ts-ignore
      // toast.error(err && err.detail);
    } finally {
      setLoading(false);
    }
  };

  const filterSettlements = async (obj: any) => {
    consoleLog(obj, "Object");
    obj = obj instanceof Object ? obj : {};
    storeToLocalStorage("currentPage", 1);
    setFilteredFormState(obj);
    await getSettlements({
      ...obj,
      // page: 1,
      // limit: 10,
    });
  };
  


  data = settlementData.map((el: any) => {
    let obj: any = {};
    //obj.recipient = el.bankAccountName; //needs further explanation
    obj.date = el.createdAt;
    obj.amount= el.amount && `${formatter.format(el.amount)}`;
    // obj.amount= el.transactions.amount  && `${formatter.format(el.transactions.amount)}`;
    obj.status =
            el.status &&
            (el.status.toLowerCase().includes("init") ? (
              <div style={{ color: getStatusColor("pending") }}>
                In progress
              </div>
            ) : (
              <div style={{ color: getStatusColor(el?.status) }}>
                {el?.status?.toLowerCase()[0].toUpperCase() +
                  el?.status?.toLowerCase().substring(1)}
              </div>
            ));
    return { ...el, ...obj };
  });

  data = filteredDataFn(data, columnHeaders, searchData, filteredColumn);

  const rowCount = data.length;


  const resetFilter = () => {
    setFetchedData(settlementData);
  };

  const handleClick=(val: any)=>{
    window.history.replaceState(null, "", `${window.location.origin}/my-apps/${params.serviceId}/settlement/${val.id}`);
    //window.location.href=`${window.location.origin}/my-apps/${params.serviceId}/settlement/${val.id}`
    setPageView(1);
  }
  
  useEffect(() => {
    const date = new Date();
    let currentDay = String(date.getDate()).padStart(2, "0");
    let currentMonth = String(date.getMonth() + 1).padStart(2, "0");
    let currentYear = date.getFullYear();

    date.setUTCDate(date.getUTCDate() - 30);

    const last7Days = `${date.getUTCFullYear()}-${(date.getUTCMonth() + 1)
      .toString()
      .padStart(2, "0")}-${date
      .getUTCDate()
      .toString()
      .padStart(2, "0")}`;
      
    let endDate = `${currentYear}-${currentMonth}-${currentDay}`;
    setStart(last7Days);
    setEnd(endDate);
    
    filterSettlements({
      start: last7Days,
      end: endDate,
      successful: true,
      failed: true,
      terminated: true
    });
  }, []);

  if (!shouldPerform(getRoleName(currentUser, business?.id), "VSettlement")) {
    history.push("/authenticate");
    return null;
  }


  return (
    <>
      <DashboardSubHeader
        title={`${rowCount} Settlements`}
        btnTitle="Download Summary"
        callBackFn={() => setOpenDownload(!openDownload)}
        openDownload={openDownload}
        setOpenDownload={setOpenDownload}
        dateFilter={false}
        setSearchData={setSearchData}
        withSearch={false}
        withFilter //{transactionData.length > 0 ? true : true}
        filterType="settlement"
        arrToFilter={settlementFilter}
        resetFilter={resetFilter}
        applyFilter={filterSettlements}
        getParams={setParams}
        clickedParam={"Transaction Description"}
        widthActionBtn={settlementData.length > 0 ? true : false}
        excelExport
        excelExportData={summaryData(rowCount, columnHeaders, data)}
        setFilterFormState
      />
      {loading ? (
          <GradientLoader />
      ) : (
        <>
          {rowCount === 0 ? (
            <EmptyContainer>
              <EmptyState stateTitle={"No Transaction"} />
            </EmptyContainer>
          ) : (
            <Table
              headData={columnHeaders}
              bodyData={data}
              hoverLink={()=>{}}
              clickFunction={handleClick}
            />
          )}
        </>
      )}
    </>
  );
};

export default SettlementList;
