import styled from "styled-components";

export const OptionItem = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
`;
export const OptionText = styled.div`
  margin-left: 10px;
`;
