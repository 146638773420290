import { useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";

import { uploadFile } from "apiServices/authService";
import RightPic from "App/assets/images/services/rightImgService.svg";
import {
  UploadBox,
  UploadButton,
  UploadContainer,
  UploadInput,
  UploadLabel,
} from "Kyc/BusinessDetail2/Styles";
import { Form } from "shared/components";
import ImageLoader from "shared/components/ImageLoader";
import api from "shared/utils/api";
import { omniSaver } from "shared/utils/authToken";
import {
  internalValidations,
  isFileImage,
  onChangeTextHandler,
  validations,
} from "shared/utils/formValidation";
import toast from "shared/utils/toast";
import { setService } from "store/actions/serviceAction";
import {
  ActionButton,
  Back,
  ButtonContainer,
  Container,
  FormBody,
  FormContainer,
  FormInnerContainer,
  SubTitle,
  TitleCase,
} from "./Style";
import consoleLog from "shared/utils/consoleLog";
import { useHistory } from "react-router-dom";
import shouldPerform, { getRoleName } from "shared/utils/assignRoles";

const AddService = ({ fetchData, setVisible, setReload, edit }: any) => {
  const [isFormValid, setFormValid] = useState(false);
  const [formstate, setFormstate] = useState({} as any);
  const [isUploading, setIsUploading] = useState(false);
  const history = useHistory();
  const business = useSelector((store: RootStateOrAny) => store.business);
  const currentUser = useSelector((store: RootStateOrAny) => store.currentUser);
  const service = useSelector((store: RootStateOrAny) => store.service);
  const dispatch = useDispatch();

  const handleUpload = async (files: any, name: any) => {
    try {
      if (!isFileImage(files[0])) {
        toast.error("File is not an image");
        return;
      }

      setIsUploading(true);
      let formData = new FormData();
      // consoleLog("CHECK FILE:", typeof files[0]);
      formData.append("certificate", files[0]);
      const response = await uploadFile(formData);
      consoleLog("responseURL", response);
      if (response.status === true) {
        const url = response?.payload?.fileUrl;
        consoleLog(url, "URL");
        omniSaver(name, url);
        // localStorage.setItem("cac", cacUrl);
        onChangeTextHandler(
          url,
          //.replace(/^.*[\\\/]/, ""),
          name,
          formstate,
          setFormstate
        );
        setIsUploading(false);
      }
    } catch (error) {
      setIsUploading(false);
      // consoleLog(error.toString());
    }
  };

  const inputArray = [
    { id: "name", title: "Service Name", type: "text", required: true },

    {
      id: "description",
      title: "Description",
      type: "text",
      placeholder: "What does your service do?",
      required: true,
    },
    {
      id: "websiteUrl",
      title: "Website",
      type: "url",
      placeholder: "Your website URL?",
      required: true,
    },
    {
      id: "defaultCurrency",
      title: "Default currency",
      type: "select",
      options: [
        { label: "NGN", value: "NGN" },
        { label: "USD", value: "USD" },
        { label: "EURO", value: "EURO" },
        { label: "POUNDS", value: "POUNDS" },
      ],
      required: true,
    },
    {
      id: "businessLogo",
      title: "Upload service logo",
      type: "file",
      required: false,
    },
  ];

  const initialValues = () => {
    let obj: any = {};
    for (let elm of inputArray) {
      obj[elm.id] = "";
    }
    return obj;
  };

  const displayFormInput = () => {
    return inputArray.map((elm, idx) => {
      switch (elm.type) {
        case "text":
          return (
            <Form.Field.Input
              key={`INP${idx}`}
              name={elm.id}
              type={elm.type}
              value={formstate[elm.id] ?? ""}
              label={elm.title}
              placeholder={elm?.placeholder}
              onChange={(val: any) =>
                onChangeTextHandler(val, elm.id, formstate, setFormstate)
              }
              style={{ marginTop: 10 }}
              // tip="Kindly enter your BVN"
            />
          );
        case "url":
          return (
            <Form.Field.Input
              key={`INP${idx}`}
              name={elm.id}
              type={elm.type}
              value={formstate[elm.id] ?? ""}
              label={elm.title}
              placeholder={elm?.placeholder}
              onChange={(val: any) =>
                onChangeTextHandler(val, elm.id, formstate, setFormstate)
              }
              style={{ marginTop: 10 }}
              // tip="Kindly enter your BVN"
            />
          );
        case "select":
          return (
            <Form.Field.Select
              key={`INP${idx}`}
              name={elm.id}
              label={elm.title}
              options={elm?.options}
              value={formstate[elm.id] ?? ""}
              onChange={(val: any) =>
                onChangeTextHandler(val, elm.id, formstate, setFormstate)
              }
              style={{ marginTop: 10 }}
              //  renderOption={""}
              //  renderValue={""}
              // tip="Kindly enter your user password."
            />
          );
        case "file":
          let isUploading = false;
          return (
            <UploadContainer style={{ marginTop: 10 }}>
              <UploadLabel>{elm.title}</UploadLabel>
              <input
                type="file"
                onChange={(e) => handleUpload(e.target.files, elm.id)}
                name={elm.id}
                id={elm.id}
                disabled={isUploading}
                hidden
              />
              <UploadBox>
                <UploadInput>
                  {(formstate[elm.id] &&
                    formstate[elm.id].replace(/^.*[\\\/]/, "")) ||
                    "No file chosen"}
                </UploadInput>
                <UploadButton htmlFor={elm.id}>
                  {isUploading ? "...." : "Upload"}
                </UploadButton>
              </UploadBox>
              {/* {error.cac && (
                <FieldError>CAC Certificate is required</FieldError>
              )} */}
            </UploadContainer>
          );
      }
    });
  };

  const onSubmit = async () => {
    setIsUploading(true);
    if (isFormValid) {
      try {
        const {
          name,
          description,
          defaultCurrency,
          websiteUrl,
          businessLogo,
        } = formstate;
        consoleLog(
          {
            business_id: business && business.id,
            name,
            businessLogo,
            defaultCurrency,
            websiteUrl,
          },
          "business"
        );
        let response;
        //add description ...waiting for backend implementation
        if (edit) {
          response = await api.put(`/services/${service?.id}`, {
            name,
            description,
            defaultCurrency,
            businessLogo,
            websiteUrl,
          });
        } else {
          response = await api.post("/services/", {
            businessId: business && business.id,
            name,
            description,
            defaultCurrency,
            businessLogo,
            websiteUrl,
          });
        }

        if (response.status === true) {
          // && response.payload && response.payload instanceof Object
          if (edit) {
            let newObj: any = {
              name,
              description,
              defaultCurrency,
              websiteUrl,
            };
            if (businessLogo) newObj.businessLogo = businessLogo;
            await dispatch(
              // setService({ ...service, ...response.payload, id: service?.id })
              setService({ ...service, ...newObj })
            );
          }
          toast.success(response.message);
          setVisible(false);
          fetchData && fetchData();
          setReload && setReload(Math.random());
        } else {
          toast.error(response.message);
        }
      } catch (err) {
        // @ts-ignore
        toast.error(err);
      } finally {
        // setLoading(false);
        setIsUploading(false);
      }
    }
  };

  useEffect(() => {
    let obj = internalValidations(
      inputArray.filter((x) => x.required),
      formstate
    );
    // consoleLog(formstate, "formstate");
    consoleLog(obj, "obj");
    if (
      Object.values(obj).filter((el: any) => el === true).length ===
      inputArray.filter((x) => x.required).length
    ) {
      setFormValid(true);
    } else {
      setFormValid(false);
    }
  }, [formstate]);

  const setServiceFormstate = () => {
    if (edit) {
      let obj: any = {};
      for (let el of inputArray) {
        if (service[el.id]) {
          obj[el.id] = service[el.id];
        }
      }
      setFormstate({
        ...formstate,
        ...obj,
        businessLogo: service?.businessLogo ?? service?.logo,
      });
    } else {
      setFormstate({});
    }
  };

  useEffect(() => {
    setServiceFormstate();
  }, []);

  // consoleLog(validations(inputArray, Form), "validations");
  if (!shouldPerform(getRoleName(currentUser, business?.id), "CServ")) {
    history.push("/authenticate");
    return null;
  }
  return (
    <Container>
      <FormContainer>
        <TitleCase>{edit ? "Edit" : "Add"} Service</TitleCase>
        <SubTitle>
          This feature allows you to add to your business profile, a<br />
          complimentary service you render
        </SubTitle>
        <FormBody>
          {/* @ts-ignore */}
          <Form
            enableReinitialize
            initialValues={initialValues()}
            validations={validations(
              inputArray.filter((x) => x.required),
              Form
            )}
            onSubmit={() => {}}
          >
            <>
              <FormInnerContainer>{displayFormInput()}</FormInnerContainer>

              <ButtonContainer>
                <Back />
                <ActionButton
                  onClick={onSubmit}
                  isWorking={isUploading}
                  disabled={!isFormValid || isUploading}
                >
                  {edit ? "Save" : "Create"}
                </ActionButton>
              </ButtonContainer>
            </>
          </Form>
        </FormBody>
      </FormContainer>
      <ImageLoader
        source={RightPic}
        width="230"
        height="440"
        draggable="false"
        onDragStart={() => alert()}
        style={{ pointerEvents: "none" }}
      />
      {/* <Image
        src={RightPic}
        draggable="false"
        onDragStart={() => alert()}
        style={{ pointerEvents: "none" }}
      /> */}
    </Container>
  );
};

export default AddService;
