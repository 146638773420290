import React, { forwardRef } from "react";
import PropTypes from "prop-types";

import { StyledInput, InputElement, StyledIcon } from "./Styles";
import consoleLog from "shared/utils/consoleLog";

// const propTypes = {
//   className: PropTypes.string,
//   value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
//   icon: PropTypes.string,
//   invalid: PropTypes.bool,
//   filter: PropTypes.instanceOf(RegExp),
//   onChange: PropTypes.func,
// };

type PropT = {
  className: string;
  value: string | number;
  icon: string;
  invalid: boolean;
  filter: RegExp;
  placeHolder: string;
  onChange: (x: any, y: any) => void;
  [x: string]: any;
  styleContainer: object;
};

const defaultProps = {
  className: undefined,
  value: undefined,
  icon: undefined,
  invalid: false,
  filter: undefined,
  onChange: () => {},
  styleContainer: {},
};

const Input = forwardRef(
  (
    {
      icon,
      className,
      styleContainer,
      filter,
      onChange,
      placeHolder,
      ...inputProps
    }: PropT,
    ref: any
  ) => {
    const handleChange = (event: any) => {
      if (!filter || filter.test(event.target.value)) {
        onChange(event.target.value, event);
      }
    };

    return {
      ...(inputProps.type === "hidden" ? (
        <input type="hidden" {...inputProps} ref={ref} />
      ) : (
        <StyledInput className={className} style={styleContainer}>
          {icon && <StyledIcon type={icon} size={15} />}
          <InputElement
            placeholder={placeHolder}
            {...inputProps}
            onChange={handleChange}
            hasIcon={!!icon}
            ref={ref}
          />
        </StyledInput>
      )),
    };
  }
);

// Input.propTypes = propTypes;
Input.defaultProps = defaultProps;

export default React.memo(Input);
