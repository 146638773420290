import { useEffect, useState } from "react";
import { Form, GradientLoader } from "shared/components";
import consoleLog from "shared/utils/consoleLog";
import * as Yup from "yup";
import { OTPInput } from "shared/components";
import {
  PageLayout,
  PageTitleCont,
  PageTitle,
  FormCont,
  AuxFormCont,
  ActionButton,
  ParentFormCont,
  AuxInputCont,
} from "./styles";
import { Modal } from "shared/components";
import {
  OtpContainer,
  SubTitle,
  Title,
  ReportBody,
} from "Auth/pages/ReceiveOtp/Styles";
import toast from "shared/utils/toast";
import api from "shared/utils/api";
import Preloader from "shared/components/preloader";

function ChangeLoginDetails() {
  const [newEmail, setNewEmail] = useState<string>("");
  const [confirmEmail, setConfirmEmail] = useState<string>("");
  const [newPasseword, setNewPasseword] = useState<string>("");
  const [confirmPassword, setconfirmPassword] = useState<string>("");
  const [modal, setModal] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [otp, setOtp] = useState<string>("");
  const [tempId, setTempId] = useState<string>("");
  const [tempId1, setTempId1] = useState<string>("");

  const initialValues = {
    newEmail,
    confirmEmail,
    newPasseword,
    confirmPassword,
  };

  const handleChange = async (otp: string) => {
    setError(false);
    setIsLoading(true);
    if (otp.length === 6) {
      const values: any = {
        tempId,
        otp,
      };
      // setIsLoginIn(true);
      try {
        const data = await api.post("/account/access", {
          ...values,
        });

        toast.success("OTP is Verified");
        // consoleLog(data)
        setIsLoading(false);
        setModal(false);
      } catch (error) {
        setIsLoading(false);
        setError(true);
        toast.error("Invalid OTP");
        // toast.error(error);
      }
    }
  };

  const onSubmit = async () => {
    setIsLoading(true);
    try {
      const { message, payload } = await api.put("/account/access", {
        email: newEmail,
        password: newPasseword,
      });
      setIsLoading(false);
      toast.success(message);
      setOtp(payload.otp);
      setTempId(payload.tempId);
      setTempId1(payload.temp_id);
      setModal(true);
    } catch (error) {
      consoleLog("update login err ", error);
      setIsLoading(false);
      //   @ts-ignore
      toast.error(error);
    }
  };

  const preventCopyPaste = (e: any) => {
    e.preventDefault();
  };

  const Schema = Yup.object().shape({
    newEmail: Yup.string().required("Please Enter your password"),
    ["Confirm email"]: Yup.string()
      .required()
      .oneOf([Yup.ref("newEmail"), null], "Passwords must match"),
    newPasseword: Yup.string().required("Please Enter your password"),
    ["Confirm password"]: Yup.string()
      .required()
      .oneOf([Yup.ref("newPasseword"), null], "Passwords must match"),
  });

  if (isLoading) {
    <GradientLoader />;
  }

  return (
    <>
      <Modal
        isOpen={modal}
        width={800}
        withCloseIcon={true}
        onClose={() => {
          setModal(false);
        }}
        renderContent={() => (
          <ReportBody>
            <Title>Check your email for a code.</Title>
            <SubTitle>
              We have sent a 6 - digit code to your email address. <br /> The
              code expires shortly, please enter it soon.
            </SubTitle>
            <OtpContainer>
              {" "}
              <OTPInput
                autoFocus
                isNumberInput
                length={6}
                hasError={error}
                errorStyle={{ borderColor: "red" }}
                inputStyle={{
                  border: "1px solid #828282",
                  width: "74px",
                  height: "78px",
                  fontSize: "30px",
                  color: "#000",
                  fontWeight: "400",
                  caretColor: "#000",
                  textAlign: "center",
                  borderRight: "0px",
                }}
                focusStyle={
                  {
                    //border: "1px solid #CFD3DB",
                  }
                }
                firstIndexStyle={{
                  borderTopLeftRadius: "8px",
                  borderBottomLeftRadius: "8px",
                  borderRight: "0px",
                }}
                lastIndexStyle={{
                  borderTopRightRadius: "8px",
                  borderBottomRightRadius: "8px",
                  borderRight: "1px solid #828282",
                  borderLeft: "1px solid #828282",
                }}
                className="otpContainer"
                inputClassName="otpInput"
                separator={
                  <span
                    style={{
                      marginLeft: "10px",
                      marginRight: "10px",
                      marginTop: "10%",
                      fontSize: "50px",
                      color: "black",
                      fontWeight: "bold",
                    }}
                  >
                    -
                  </span>
                }
                onChangeOTP={handleChange}
              />
            </OtpContainer>
          </ReportBody>
        )}
      />

      <PageLayout>
        <PageTitleCont>
          <PageTitle>New Login Details</PageTitle>
        </PageTitleCont>
        {/* @ts-ignore */}
        <Form
          enableReinitialize
          initialValues={initialValues}
          validations={{
            newEmail: [Form.is.required(), Form.is.email()],
            confirmEmail: [
              Form.is.required(),
              Form.is.email(),
              Form.is.compareWords(newEmail, "Email"),
            ],
            newPasseword: Form.is.required(),
            confirmPassword: [
              Form.is.required(),
              Form.is.comparePassword(newPasseword),
            ],
          }}
          onSubmit={onSubmit}
          // validationSchema={Schema}

          // validateOnBlur={false}
        >
          {({ isValid, dirty }: any) => (
            <Form.Element>
              <ParentFormCont>
                <FormCont>
                  <AuxFormCont>
                    <AuxInputCont>
                      <Form.Field.Input
                        name="newEmail"
                        label="New Email Address"
                        type={"email"}
                        value={newEmail}
                        onChange={(e: any) => setNewEmail(e)}
                        style={{ backgroundColor: "#fff" }}
                        onCopy={(e: any) => preventCopyPaste(e)}
                        onPaste={(e: any) => preventCopyPaste(e)}
                        onCut={(e: any) => preventCopyPaste(e)}
                      />
                    </AuxInputCont>

                    <AuxInputCont>
                      <Form.Field.Input
                        name={`confirmEmail`}
                        label="Confirm Email Address"
                        type={"email"}
                        value={confirmEmail}
                        onChange={(e: any) => setConfirmEmail(e)}
                        style={{ backgroundColor: "#fff" }}
                        onCopy={(e: any) => preventCopyPaste(e)}
                        onPaste={(e: any) => preventCopyPaste(e)}
                        onCut={(e: any) => preventCopyPaste(e)}
                      />
                    </AuxInputCont>
                  </AuxFormCont>

                  <AuxFormCont>
                    <AuxInputCont>
                      <Form.Field.Input
                        name="newPasseword"
                        label="New Password"
                        value={newPasseword}
                        onChange={(e: any) => setNewPasseword(e)}
                        style={{ backgroundColor: "#fff" }}
                        type="password"
                      />
                    </AuxInputCont>

                    <AuxInputCont>
                      <Form.Field.Input
                        name={`confirmPassword`}
                        label="Confirm Password"
                        value={confirmPassword}
                        onChange={(e: any) => setconfirmPassword(e)}
                        style={{ backgroundColor: "#fff" }}
                        type="password"
                      />
                    </AuxInputCont>
                  </AuxFormCont>
                </FormCont>

                <ActionButton
                  onClick={onSubmit}
                  type="submit"
                  variant="primary"
                  isWorking={isLoading}
                  disabled={
                    !isValid ||
                    !newEmail ||
                    !confirmEmail ||
                    !newPasseword ||
                    !confirmPassword
                  }
                >
                  Save
                </ActionButton>
              </ParentFormCont>
            </Form.Element>
          )}
        </Form>
      </PageLayout>
    </>
  );
}

export default ChangeLoginDetails;
