import React, { useCallback, useEffect, useRef, useState } from "react";
import Logo from "App/assets/images/logo.svg";
import Gmail from "App/assets/images/icons/gmail.svg";
import Outlook from "App/assets/images/icons/outlook.svg";
import {
  ActionButton,
  ReportBody,
  SubTitle,
  Title,
  OtpContainer,
  Footer,
  Info,
  Actions,
} from "./Styles";
import OtpInput from "react-otp-input";
import { getStoredAuthToken, storeAuthToken } from "shared/utils/authToken";
import toast from "shared/utils/toast";
import { useHistory, useParams } from "react-router-dom";
import api from "shared/utils/api";
import Preloader from "shared/components/preloader";
import consoleLog from "shared/utils/consoleLog";
import { authPath } from "apiServices/paths";
import LoginFace2 from "shared/components/LoginFace/index2";
import { GradientLoader, OTPInput } from "shared/components";

const ReceiveResetOtp = () => {
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isLoginIn, setIsLoginIn] = useState(false);
  const history = useHistory();
  const params: any = useParams();

  const [token, setToken] = useState("");
  const otpRef: any = useRef(null);

  const handleChange = async (otpcode: any) => {
    // console.log("otp ====> ", otpcode);

    if (otpcode.length === 6) {
      let auth = params?.auth ?? "";
      let tempId: any = localStorage.getItem("tempId");
      const values: any = {
        tempId,
        otp: otpcode,
      };
      setIsLoginIn(true);
      consoleLog("confirm otp", values);
      try {
        const data = await api.post("/authentication/confirm-otp", {
          ...values,
        });

        setIsLoginIn(false);
        toast.success("OTP is Verified");
        // history.push(`${authPath.SET_PASSWORD}`);
        history.push(`${authPath.OTP_RESET_SUCCESS}/${auth}`);
      } catch (error) {
        setIsLoginIn(false);
        // @ts-ignore
        // console.log("error on confirm otp", error);
        toast.error("Something went wrong");
      }
    }
  };

  if (isLoginIn) {
    return <GradientLoader />;
  }

  const Component: any = () => (
    <ReportBody>
      <img src={Logo} alt="Logo" />
      <Title>Check your email for a code.</Title>
      <SubTitle>
        We have sent a 6 - digit code to your email address. <br /> The code
        expires shortly, please enter it soon.
      </SubTitle>

      <OtpContainer>
        {" "}
        {/* <OtpInput
          value={otp}
          onChange={handleChange}
          numInputs={6}
          errorStyle={{ border: "1px solid #823" }}
          separator={<span style={{ width: "8px" }}></span>}
          isInputNum={true}
          shouldAutoFocus={true}
          hasErrored={error}
          containerStyle={{
            color: "#000000",
          }}
          inputStyle={{
            border: "1px solid #828282",
            borderRadius: "8px",
            width: "54px",
            height: "54px",
            fontSize: "14px",
            color: "#000",
            fontWeight: "400",
            caretColor: "blue",
          }}
          focusStyle={{
            border: "1px solid #CFD3DB",
          }}
        /> */}
        {/* <OTPInput
          autoFocus
          isNumberInput
          length={6}
          hasError={error}
          errorStyle={{ borderColor: "red" }}
          inputStyle={{
            border: "1px solid #828282",
            borderRadius: "8px",
            width: "54px",
            height: "54px",
            fontSize: "14px",
            color: "#000",
            fontWeight: "400",
            caretColor: "blue",
          }}
          focusStyle={{
            border: "1px solid #CFD3DB",
          }}
          separator={<span style={{ width: "8px" }}></span>}
          className="otpContainer"
          inputClassName="otpInput"
          onChangeOTP={handleChange}
        /> */}
        <OTPInput
          autoFocus
          isNumberInput
          length={6}
          hasError={error}
          errorStyle={{ borderColor: "red" }}
          inputStyle={{
            border: "1px solid #828282",
            width: "74px",
            height: "78px",
            fontSize: "30px",
            color: "#000",
            fontWeight: "400",
            caretColor: "#000",
            textAlign: "center",
            borderRight: "0px",
          }}
          focusStyle={
            {
              //border: "1px solid #CFD3DB",
            }
          }
          firstIndexStyle={{
            borderTopLeftRadius: "8px",
            borderBottomLeftRadius: "8px",
            borderRight: "0px",
          }}
          lastIndexStyle={{
            borderTopRightRadius: "8px",
            borderBottomRightRadius: "8px",
            borderRight: "1px solid #828282",
            borderLeft: "1px solid #828282",
          }}
          className="otpContainer"
          inputClassName="otpInput"
          separator={
            <span
              style={{
                marginLeft: "10px",
                marginRight: "10px",
                marginTop: "10%",
                fontSize: "50px",
                color: "black",
                fontWeight: "bold",
              }}
            >
              -
            </span>
          }
          onChangeOTP={handleChange}
        />
      </OtpContainer>

      <Actions>
        <div
          style={{ cursor: "pointer" }}
          onClick={() => window.open("https://www.gmail.com", "_blank")}
        >
          <img src={Gmail} alt="Gmail" />
          <p>Open Gmail.</p>
        </div>

        <div
          style={{ cursor: "pointer" }}
          onClick={() => window.open("https://outlook.office.com/", "_blank")}
        >
          <img src={Outlook} alt="Outlook" />
          <p>Open Outlook.</p>
        </div>
      </Actions>

      <Info>Can’t find your code? Check your spam folder.</Info>
      <Footer>
        <p style={{ cursor: "pointer" }}>Privacy and terms</p>{" "}
        <p style={{ cursor: "pointer" }}>Contact us</p>
      </Footer>
    </ReportBody>
  );
  return (
    <>
      {params?.auth ? (
        <LoginFace2 chgValue={Math.random()}>
          <Component />
        </LoginFace2>
      ) : (
        <Component />
      )}
    </>
  );
};

export default ReceiveResetOtp;
