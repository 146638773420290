import styled, { css } from "styled-components";
import Button from "../Button";
import { color } from "shared/utils/styles";

type Propst={
  variant?: string;
}
export const Container = styled.div`
  width: 100%;
  display: inline-block;
  justify-content: center;
  text-align: center;
  margin: 10px 0px;
`;

export const Title = styled.p`
    color: #3C546F;
    font-weight: normal;
    font-size: 22px;
`;

export const Tag = styled.p`
    color: #A7A7A8;
    font-weight: normal;
    font-size: 16px;
`

export const ActionButton = styled(Button)<Propst>`
  margin: 1em 0;
  color: #fff;
  min-width: 138px;
  height: 54px;
  float: right;
  ${props=>props.variant ? css`
    background-color: ${props.variant};
  `:
  css`
    background-color: ${color.fountain};
  `
  }
  ${props=>props.variant==color.transparent && css`
    color: grey;
  `}
  
`;