import useQuery from "./query";
import useMutation from "./mutation";

/* eslint-disable react-hooks/rules-of-hooks */
export default {
  get: (...args: any) => useQuery(...args),
  post: (...args: any) => useMutation("post", ...args),
  put: (...args: any) => useMutation("put", ...args),
  patch: (...args: any) => useMutation("patch", ...args),
  delete: (...args: any) => useMutation("delete", ...args),
};
