import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import DashboardSubHeader from "shared/components/DashboardSubHeader";
import Table from "shared/components/Table";
import { InventoryService } from "../inventory-service";
import {
  IFecthedCategories,
  IFecthedCategory,
  IGetAssets,
} from "../inventory-models";
import { RootStateOrAny, useSelector } from "react-redux";
import { GradientLoader } from "shared/components";
import { relative } from "path";
import { numberWithCommas } from "shared/utils/utility";
import api from "shared/utils/api";

export default function InventoryAssets() {
  const [isloading, setisloading] = useState(false);
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [assets, setassets] = useState<any[]>([]);
  const [currentPage, setcurrentPage] = useState(0);
  const [seletedFilterObj, setseletedFilterObj] = useState<any>({});

  const history = useHistory();
  const currentBusiness = useSelector(
    (store: RootStateOrAny) => store.business
  );

  const headData: any = [
    { id: "assetName", label: "Asset Name" },
    { id: "category", label: "Category" },
    { id: "description", label: "Description" },
    { id: "quantity", label: "Quantity" },
    { id: "cost", label: `Cost(${"NGN"})` },

    { id: "action", label: "" },
  ];

  const viewFunction = (obj: any) => {
    history.push({
      pathname: "/agency/inventory/asset/overview/" + obj?.id,
      state: { data: obj },
    });
  };

  const SearchForData = () => {};

  const fetchAssets = async (startDate = "", endDate = "") => {
    setisloading(true);
    try {
      const businessId = currentBusiness?.id;
      const payload: IGetAssets = {
        item: 2,
        page: currentPage + 1,
        start_date: startDate,
        end_date: endDate,
        business_id: businessId,
      };
      // // console.log("Payload: ", payload)
      // const response: any = await InventoryService.GetAssets(payload);
      const response: any = await api.post(
        `/agency-banking/assets/business/query`,
        payload
      );
      // // console.log("assets==>", response);
      if (response.status) {
        const responseData = response.payload.data;
        const transformedDate = responseData.map((obj: any) => {
          return {
            id: obj.id ?? "--",
            assetName: obj.name ?? "---",
            category: obj?.Category?.name ?? "---",
            description: obj?.description ?? "---",
            quantity: obj?.quantity,
            cost: numberWithCommas(obj?.cost),
          };
        });
        setassets(transformedDate);
      }
    } catch (error) {
      // // console.log(error);
    } finally {
      setisloading(false);
    }
  };

  useEffect(() => {
    const date = new Date();
    let currentDay = String(date.getDate()).padStart(2, "0");
    let currentMonth = String(date.getMonth() + 1).padStart(2, "0");
    let currentYear = date.getFullYear();

    date.setUTCDate(date.getUTCDate() - 200);

    const last200Days = `${date.getUTCFullYear()}-${(date.getUTCMonth() + 1)
      .toString()
      .padStart(2, "0")}-${date
      .getUTCDate()
      .toString()
      .padStart(2, "0")}`;

    let endDate = `${currentYear}-${currentMonth}-${currentDay}`;
    setStartDate(last200Days);
    setEndDate(endDate);
    fetchAssets(last200Days, endDate);
  }, []);

  useEffect(() => {
    if (Object.keys(seletedFilterObj).length) {
      const { start, end } = seletedFilterObj;
      fetchAssets(start, end);
    }
  }, [seletedFilterObj]);

  return (
    <>
      <DashboardSubHeader
        // count={0}
        title={"Assets"}
        btnTitle="Create asset"
        callBackFn={() => history.push("/agency/inventory/asset/create")}
        setSearchData={SearchForData}
        withSearch={true}
        withFilter={true}
        dateFilter={true}
        filterType="assets"
        widthActionBtn={true}
        arrToFilter={[]}
        applyFilter={() => ""}
        clickedParam={""}
        getParams={() => ""}
        setStartDate={() => {}}
        setEndDate={() => {}}
        onFilterCallback={(obj: any) => setseletedFilterObj(obj)}
      />

      {isloading && <GradientLoader />}
      {!isloading && (
        <div style={{ position: "inherit" }}>
          <Table
            headData={headData}
            bodyData={assets}
            hoverLink
            serialNumber
            clickFunction={viewFunction}
            totalPages={0}
            currentpage={0}
            emptyStateMessage="No Asset"
          />
        </div>
      )}
    </>
  );
}
