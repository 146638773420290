import styled from "styled-components";

export const PageBody = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const PageBodyChild1 = styled.div`
  height: 350px;
  width: 60%;
  background: #FFFFFF;
  padding: 30px 20px;
`;

export const PageBodyChild2 = styled.div`
  width: 35%;
`;

export const DataContainer = styled.div`
  width: 392px;
  height: 200px;
  width: 100%;
  background: #FFFFFF;
  padding: 20px 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
`;

export const DataDesc = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
`;

export const DataLabel = styled.p`

  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  text-align: center;
  color: #828282;
`;

export const DataText = styled.p`

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 23px;
  text-align: center;
  color: #292929;
`;

export const SecondaryLabel = styled.p`

  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #828282;
  margin-bottom: 10px;
`;

export const SecondaryTitle = styled.p`

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #828282;
  margin-bottom: 10px;
`;

export const SecondaryData = styled.p`

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #292929;
  margin-bottom: 20px;
`;

export const TertiaryCont = styled.div`
    width: 100%;
    margin-top: 30px;
    padding: 10px 0px;
`;

export const TertiaryContHeader = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

export const TertiaryContTitle = styled.button`
    text-align: left;
    font-style: normal;
    font-size: 16px;
    line-height: 19px;
    color: #292929;
    width: 100%;
    padding-bottom: 0px 0px 30px 0px;
    cursor: pointer;
`;