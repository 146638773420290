import { useEffect, useState } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Form, Icon, Spinner } from "shared/components";
import api from "shared/utils/api";
import toast from "shared/utils/toast";
import {
  FormGroup,
  FormInput,
  FormInputGroup,
  InputLabel,
  PlusIcon,
  PricingForm,
  Container,
  ModalBtn,
} from "../styles";
import {
  ActionButton,
  FormBody,
  FormElement,
  SubTitle,
  TitleCase,
} from "Agency/Subagent/AddSubAgent/styles";
import { color } from "shared/utils/styles";
import consoleLog from "shared/utils/consoleLog";

let objBound = {
  flat: 0,
  percentage: 0,
  cap: 0,
  lower_bound: 0,
  upper_bound: 0,
  del: false,
};

const CreatePrice = () => {
  const [bounds, setBounds] = useState<any>([{ ...objBound }]);
  const [service, setService] = useState<string>("");
  const [pricingName, setpricingName] = useState("");
  const [percentageValue, setpercentageValue] = useState("");
  const [fixedValue, setfixedValue] = useState("");
  const [benchmark, setBenchmark] = useState("0");
  const [isLoading, setIsLoading] = useState(false);
  const [cap, setCap] = useState("0");
  const [pageAction, setpageAction] = useState("CREATE");
  const [showActivityIndicator, setshowActivityIndicator] = useState(false);
  const [selectedPlanID, setSelectedPlanID] = useState("");
  const history = useHistory();

  const params: any = useParams();
  const business = useSelector((store: RootStateOrAny) => store.business);

  const options = [
    { label: "Withdrawer", value: "Withdrawer" },
    { label: "Transfer", value: "Transfer" },
    { label: "Airtime", value: "Airtime" },
    { label: "Data", value: "Data" },
    { label: "Electricity", value: "Electricity" },
    { label: "Cable", value: "Cable" },
    { label: "Utility", value: "Utility" },
  ];

  let btnText = pageAction === "CREATE" ? "Create" : "Edit";

  const onKeyChange = (key: string, e: string, id: number) => {
    const newState = [...bounds];
    newState[id] = {
      ...newState[id],
      [key]: e,
    };
    setBounds(newState);
  };

  const getPrice = async () => {
    setIsLoading(true);
    try {
      let resp = await api.get(`/agency-banking/pricing/${params?.id}`);
      consoleLog("Response__: ", resp);
      if (resp.status) {
      } else {
        toast.error(resp.message);
      }
    } catch (error) {
      consoleLog("Error: ", error);
    } finally {
      setIsLoading(false);
    }
  };
  //===== This handles submit button on modal ==========
  const handleSubmit = async () => {
    //e.preventDefault();
    setshowActivityIndicator(true);
    try {
      const payload = {
        name: service.toString().toLocaleUpperCase(),
        value: bounds,
        app: "AGENCY_BANKING",
        channels: service.toString().toUpperCase(),
      };

      //switch is based on page action state
      switch (pageAction) {
        case "EDIT":
          const pricingId = selectedPlanID;
          const pricingPayload = {
            name: pricingName,
            value: {
              percentage: percentageValue ? percentageValue : 0,
              flat: fixedValue ? fixedValue : 0,
            },
          };
          let resp = await api.post(
            `/agency-banking/pricing/${business?.id}`,
            payload
          );
          //// console.log("Response: ", response);
          if (resp.status) {
            toast.success(resp?.message);
            setBounds([{ ...objBound }]);
          } else {
            toast.error(resp.message);
          }

          break;

        default:
          let response = await api.post(
            `/agency-banking/pricing/${business?.id}`,
            payload
          );
          //// console.log("Response: ", response);
          if (response.status) {
            toast.success(response?.message);
            setBounds([{ ...objBound }]);
          } else {
            toast.error(response.message);
          }
          break;
      }
    } catch (error) {
      // console.log(error)
    } finally {
      setshowActivityIndicator(false);
    }
  };
  //==========================================================
  const removeBound = (index: number) => {
    const oldState = bounds.filter((item: any, i: number) => i != index);
    setBounds(oldState);
  };
  const createBound = () => {
    objBound.del = true;
    const oldState = [...bounds, objBound];
    setBounds(oldState);
  };

  useEffect(() => {
    if (params?.id) {
      getPrice();
    }
  }, [params]);
  return (
    <Container>
      <TitleCase
        style={{
          display: "flex",
          justifyContent: "flex-start",
          gap: "10px",
          alignItems: "center",
        }}
      >
        <Icon
          style={{ cursor: "pointer" }}
          type="chevron-left"
          size="28"
          color="#ccc"
          onClick={() => history.goBack()}
        />
        <>Agent personal details</>
      </TitleCase>
      <SubTitle>
        With your corporate account number, you are guaranteed of your
        settlement
      </SubTitle>
      <FormBody>
        {/* @ts-ignore */}
        <Form
          enableReinitialize
          initialValues={{}}
          validations={() => {}}
          onSubmit={handleSubmit}
          validate={() => {}}
          validateOnBlur={false}
        >
          <FormElement>
            <FormInputGroup>
              <Form.Field.Select
                label="Select pricing name"
                name="service"
                options={options}
                value={service ?? ""}
                onChange={(val: any) => {
                  setService(val);
                }}
              />
            </FormInputGroup>
            <legend style={{ marginBottom: "5px" }}>Bounds</legend>
            {bounds instanceof Array &&
              bounds.map((item: any, i: number) => {
                return (
                  <div
                    key={i}
                    style={{
                      borderWidth: "1px",
                      borderStyle: "solid",
                      borderColor: "#ccc",
                      borderRadius: "5px",
                      padding: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    <FormGroup>
                      <FormInputGroup>
                        <InputLabel>Percentage (%)</InputLabel>
                        <FormInput
                          type="text"
                          placeholder=""
                          value={item.percentage}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            onKeyChange("percentage", event.target.value, i);
                          }}
                        />
                      </FormInputGroup>

                      <PlusIcon>+</PlusIcon>
                      <FormInputGroup>
                        <InputLabel>Flat</InputLabel>
                        <FormInput
                          type="text"
                          placeholder=""
                          value={item.flat}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            onKeyChange("flat", event.target.value, i);
                          }}
                        />
                      </FormInputGroup>
                    </FormGroup>
                    <FormGroup>
                      <FormInputGroup>
                        <InputLabel>Lower Bound</InputLabel>
                        <FormInput
                          type="text"
                          placeholder=""
                          value={item.lower_bound}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            onKeyChange("lower_bound", event.target.value, i);
                          }}
                        />
                      </FormInputGroup>
                      <FormInputGroup>
                        <InputLabel>Upper Bound</InputLabel>
                        <FormInput
                          type="text"
                          placeholder=""
                          value={item.upper_bound}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            onKeyChange("upper_bound", event.target.value, i);
                          }}
                        />
                      </FormInputGroup>
                    </FormGroup>
                    <FormGroup>
                      <FormInputGroup>
                        <InputLabel>Cap</InputLabel>
                        <FormInput
                          type="text"
                          placeholder=""
                          value={item.cap}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            onKeyChange("cap", event.target.value, i);
                          }}
                        />
                      </FormInputGroup>

                      {item.del && (
                        <Icon
                          type={"trash"}
                          size={50}
                          style={{ marginTop: "25px", cursor: "pointer" }}
                          onClick={() => removeBound(i)}
                        />
                      )}
                    </FormGroup>
                  </div>
                );
              })}
            <ActionButton
              type="button"
              width="100%"
              variant={color.fountainLight}
              onClick={createBound}
            >
              <Icon type={"plus"} size={20} /> Add bounds
            </ActionButton>

            <ActionButton
              type="submit"
              width="100%"
              isWorking={showActivityIndicator}
              disabled={!service}
              onClick={handleSubmit}
            >
              {btnText}
            </ActionButton>
          </FormElement>
        </Form>
      </FormBody>
    </Container>
  );
};

export default CreatePrice;
