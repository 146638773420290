export const getTextContentsFromHtmlString = (html: any) => {
  const el = document.createElement('div');
  el.innerHTML = html;
  return el.textContent;
};

export const copyToClipboard = (value: string) => {
  const $textarea = document.createElement('textarea');
  $textarea.value = value;
  document.body.appendChild($textarea);
  $textarea.select();
  document.execCommand('copy');
  document.body.removeChild($textarea);
};



export const isFocusedElementEditable = () =>
  // @ts-ignore
  !!document.activeElement?.getAttribute('contenteditable') ||  ['TEXTAREA', 'INPUT'].includes(document.activeElement.tagName);
