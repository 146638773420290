import styled from "styled-components";

type Propst={
    size?: string;
    bgColor?: string;
}
export const Container=styled.span<Propst>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${props=>props.size || '200px'};
    height: ${props=>props.size || '200px'};
    background-color: ${props=>props.bgColor || 'brown'};
    color: #ffffff;
    font-size: 60px;
    border-radius: 50%;
    font-weight: 800;
`