import moment from "moment";
import consoleLog from "shared/utils/consoleLog";

export const formatDate = (date: any, format = "MMMM D, YYYY") =>
  date ? moment(date).format(format) : date;

export const formatDateTime = (
  date: Date | number | string,
  format = "MMMM D, YYYY, h:mm A"
) => (date ? moment(date).format(format) : date);

export const formatDateTimeForAPI = (date: Date) =>
  date
    ? moment(date)
        .utc()
        .format()
    : date;

export const formatDateTimeConversational = (date: Date | number) =>
  date ? moment(date).fromNow() : date;

export const formatDateInSlashes = (x: any) => {
  return new Date(x).toLocaleDateString();
};

export const calenderDate = (date: any) => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

export const differnceTime = (date1: string, date2: string) => {
  return moment(date2).diff(moment(date1), "minutes");
};

export const getOnlyTime = (val: string) => {
  return moment(val).format("LT");
};


export const formatDateComma=(dateString: string)=>{
  return new Date(dateString).toLocaleString("en-GB", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  })
}