import { useState } from "react";
import FirstStep from "./firstStep";
import SecondStep from "./secondStep";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import shouldPerform, { getRoleName } from "shared/utils/assignRoles";

export default ({ setVisible, edit, setReload }: any) => {
  const history = useHistory();
  const currentUser = useSelector((store: RootStateOrAny) => store.currentUser);
  const currentbusiness = useSelector(
    (store: RootStateOrAny) => store.business
  );

  const [step, setStep] = useState(1);
  const displayContent = () => {
    switch (step) {
      case 1:
        return (
          <FirstStep
            setVisible={setVisible}
            setReload={setReload}
            edit={edit}
            setStep={setStep}
          />
        );
      case 2:
        return (
          <SecondStep
            setVisible={setVisible}
            setReload={setReload}
            edit={edit}
            setStep={setStep}
          />
        );
    }
  };
  if (
    !shouldPerform(getRoleName(currentUser, currentbusiness?.id), "CBankDet")
  ) {
    history.push("/authenticate");
    return null;
  }
  return <>{displayContent()}</>;
};
