import { useEffect, useState } from "react";
import { Checkbox, Input } from "shared/components";
import {
  FilterInputCont,
  FilterParamsCont,
  FilterSec,
  FilterText,
  InputHolder,
  PopUpSubHead,
  TextDescription,
} from "shared/components/DashboardSubHeader/Styles";
import { DateRangePicker } from "rsuite";
import Daterange from "./Daterange";

const date = new Date();
let currentDay = String(date.getDate()).padStart(2, "0");
let currentMonth = String(date.getMonth() + 1).padStart(2, "0");
let currentYear = date.getFullYear();
// we will display the date as DD-MM-YYYY
let currentDate = `${currentYear}-${currentMonth}-${currentDay}`;

date.setUTCDate(date.getUTCDate() - 30);
const last7Days = `${date.getUTCFullYear()}-${(date.getUTCMonth() + 1)
.toString()
.padStart(2, "0")}-${date
.getUTCDate()
.toString()
.padStart(2, "0")}`;

type PropT = {
  onProcessFilter: Function;
};
const SettlementFilter = ({ onProcessFilter }: PropT) => {
  const [startDate, setStartDate] = useState<string | undefined>(last7Days);
  const [endDate, setEndDate] = useState<string | undefined>(currentDate);

  const [successful, setSuccessful] = useState<boolean | undefined>(true);
  const [failed, setFailed] = useState<boolean | undefined>(true);
  const [terminated, setTerminated] = useState<boolean | undefined>(true);

  const onChangeTextHandler = async() => {
    //onProcessFilter({date:{start, end}, search, status:{successful, failed, terminated}, channel:{card, qrpay, internetBanking, directDebit}})
    const payload: any={
      start: startDate,
      end: endDate,
      successful,
      failed,
      terminated,
    }
    // console.log("Payload: ", payload);
    await onProcessFilter(payload);
  };

  const handleSelection = async (fromDate: string, toDate: string) => {
    //alert(`Selected dates 1: ${fromDate}, ${toDate}`)
    // console.log(`Filter dates: ${fromDate}, ${toDate}`)
    await setEndDate(toDate);
    await setStartDate(fromDate);
  };

  useEffect(()=>{
    onChangeTextHandler();
  }, [startDate, endDate, successful, failed, terminated])

  return (
    <FilterSec>
      <PopUpSubHead>Date range</PopUpSubHead>
      <FilterInputCont>
        <Daterange handleSelection={handleSelection} />
      </FilterInputCont>

      <PopUpSubHead>Status</PopUpSubHead>
      <FilterParamsCont>
        <Checkbox
          onChange={async(e: any) => {
            // alert(successful);
            await setSuccessful(!successful);
            await onChangeTextHandler();
          }}
          label={"Successful"}
          value={successful}
          name={"successful"}
          checked={!!successful}
        ></Checkbox>
        <Checkbox
          onChange={async(e: any) => {
            await setFailed(!failed);
            await onChangeTextHandler();
          }}
          label={"Failed"}
          value={failed}
          name={"failed"}
          checked={!!failed}
        ></Checkbox>
        <Checkbox
          onChange={async(e: any) => {
            await setTerminated(!terminated);
            await onChangeTextHandler();
          }}
          label={"In progress"}
          value={terminated}
          name={"terminated"}
          checked={!!terminated}
        ></Checkbox>
      </FilterParamsCont>
    </FilterSec>
  );
};

export default SettlementFilter;
