import React, { useEffect, useState } from "react";
import {
  DetailsRow,
  DetailsRowContent,
  EditButton,
  ModeSpan,
  ServicePageActionButtons,
  ServicePageContainer,
  ServicePageContent,
  ServicePageSubHeader,
} from "./Styles";
import { RootStateOrAny, useSelector } from "react-redux";
import { Modal, Switch } from "shared/components";
import useServiceMode from "shared/hooks/useServiceMode";
import Table from "shared/components/Table";
import { getStatusDivColor } from "shared/utils/statusColor";
import { formateDate, formateDateString } from "shared/utils/utility";
import moment from "moment";
import AddService from "Services/AddService";

export default function ServiceDetails() {
  const service = useSelector((store: RootStateOrAny) => store.service);
  const { serviceMode, loadingMode, toogleMode } = useServiceMode(service);
  const [live, setlive] = useState(service.mode === "TEST" ? false : true);
  const { mode, name, serviceId, status, createdAt } = service;
  const [visible, setvisible] = useState(false);

  const columnHeaders = [
    { id: "serviceName", label: "Service Name" },
    { id: "serviceID", label: "Service Id" },
    { id: "status", label: "Status" },
    { id: "dateCreated", label: "Date Created" },
    { id: "action", label: "Action" },
  ];

  const serviceDetails = [
    {
      serviceName: name,
      serviceID: serviceId,
      status: getStatusDivColor(status),
      dateCreated: formateDate(createdAt),
      action: <EditButton onClick={() => handleEdit()}>Edit</EditButton>,
    },
  ];

  const handleEdit = () => {
    setvisible(true);
  };

  // useEffect(() => {
  //   // console.log("service", service);
  // }, []);

  return (
    <div>
      <ServicePageContainer>
        <h1>
          App details
          <ModeSpan mode={service.mode == "LIVE" ? true : false}>
            {mode}
          </ModeSpan>
        </h1>
        <ServicePageSubHeader>
          <p>Find your app details here</p>
          <ServicePageActionButtons>
            <div>
              <Switch
                id={"SubAction"}
                status={serviceMode}
                label={loadingMode ? "Loading" : live ? "Live" : "Test"}
                toggleMethod={() => toogleMode()}
              />
            </div>
          </ServicePageActionButtons>
        </ServicePageSubHeader>

        <ServicePageContent>
          {/* <DetailsRow>
            <DetailsRowContent>
              <h4>Service Name</h4>
              <h4>Created by</h4>
            </DetailsRowContent>
            <button onClick={() => ""}>Edit</button>
          </DetailsRow> */}

          <Table
            headData={columnHeaders}
            bodyData={serviceDetails}
            clickFunction={() => ""}
            hoverLink
          />
        </ServicePageContent>
      </ServicePageContainer>

      <Modal
        isOpen={visible}
        width={800}
        withCloseIcon={true}
        onClose={() => setvisible(false)}
        overFlow={true}
        containerWidth={false}
        renderContent={() => (
          <AddService edit={true} setVisible={() => setvisible(false)} />
        )}
        {...{}}
      />
    </div>
  );
}
