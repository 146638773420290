import { ActionTypes } from "../constants/actionType";

export const logoutReducer = (state: any = false, action: any) => {
  switch (action.type) {
    case ActionTypes.LOGOUT:
      return true;
    default:
      return state;
  }
};
