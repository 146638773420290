import lodash from "lodash";
import { useEffect, useState } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import AddSubAccount from "Services/AddSubAccount";
import { Form } from "shared/components";
import api from "shared/utils/api";
import {
  internalValidations,
  onChangeTextHandler,
  validations,
} from "shared/utils/formValidation";
import toast from "shared/utils/toast";
import Modal from "../../../shared/components/Modal";
import {
  AddSubAccountBtn,
  AddSubAccountButtons,
  AddSubAccountSubmitBtn,
  CheckBox,
  CheckBoxLabel,
  CheckBoxWrapper,
  FullPaymentFormElement,
  PaymentForm,
  PaymentFormElement,
  SwitchContainer,
  SwitchLink,
  TextAreaContainer,
} from "../Styles";
import consoleLog from "shared/utils/consoleLog";

const frequencyOptions = {
  DAILY: lodash.range(1, 32).map((val) => ({ label: val, value: val })),
  WEEKLY: lodash.range(2, 54).map((val) => ({ label: val, value: val })),
  MONTHLY: lodash.range(2, 13).map((val) => ({ label: val, value: val })),
  QUARTERLY: lodash.range(2, 5).map((val) => ({ label: val, value: val })),
  YEARLY: lodash.range(2, 13).map((val) => ({ label: val, value: val })),
};

const subOptions = [
  {
    label: "Daily",
    value: "DAILY",
  },
  {
    label: "Weekly",
    value: "WEEKLY",
  },
  {
    label: "Monthly",
    value: "MONTHLY",
  },
  {
    label: "Quarterly",
    value: "QUARTERLY",
  },
  {
    label: "Yearly",
    value: "YEARLY",
  },
];

const Subscription = ({
  edit,
  fstate,
  existSplit,
  splitfstate,
  defaultSelect,
  verify,
}: any) => {
  const [showModal, setShowModal] = useState(false);
  // const [selectedTab, setSelectedTab] = useState("one-time");
  // const [needAmount, setNeedAmount] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [errorSlug, setErrorSlug] = useState(false);

  const [asExistingSplitting, setAsExistingSplitting] = useState(false);

  const [splittingFormState, setSplittingFormState] = useState({
    id: "",
    type: "PERCENTAGE",
    subAccountId: "",
    value: "",
    paymentLinkId: "",
    status: "",
  });

  const [isFormValid, setFormValid] = useState(false);
  const [formstate, setFormstate] = useState({ amountType: "FIXED" } as any);

  const [isValidatedSlug, setIsValidatedSlug] = useState(false);
  const history = useHistory();

  const service = useSelector((store: RootStateOrAny) => store.service);

  const initialValues = () => {
    let obj: any = {};
    for (let elm of inputArray) {
      obj[elm.id] =
        edit && defaultSelect === "subscription" ? fstate[elm.id] : "";
    }
    return obj;
  };

  const inputArray = [
    {
      id: "name",
      title: "Payment Name",
      type: "text",
      required: true,
    },
    {
      id: "amount",
      title: "Amount",
      type: "amount",
      prepend: "NGN",
      required: true,
    },
    {
      id: "description",
      title: "Description",
      type: "text",
      placeholder: "What does your service do?",
      required: true,
    },
    {
      id: "webhookUrl",
      // id: "redirectUrl",
      title: "Webhook Url after payment",
      type: "url",
      prepend: "URL",
      // required: true,
      required: false,
    },
    {
      id: "subscriptionPlan",
      title: "Payment Plan",
      type: "select",
      options: subOptions || "WEEKLY",
      required: true,
    },
    {
      id: "frequency",
      title: "Number of times",
      type: "select",
      //@ts-ignore
      options: frequencyOptions[formstate.subscriptionPlan || "DAILY"],
      required: true,
    },
    {
      id: "slug",
      title: "Slug",
      type: "fulltext",
    },
    {
      id: "amountType",
      title: "Do you want user to enter amount?",
      type: "toggle",
    },
  ];
  // alert(formatMoney(67999900000));
  const displayFormInput = () => {
    return inputArray.map((elm, idx) => {
      switch (elm.type) {
        case "fulltext":
          return (
            <FullPaymentFormElement key={idx}>
              <Form.Field.Input
                key={`INP${idx}`}
                name={elm.id}
                type="text"
                label={elm.title}
                // value={elm?.value}
                // @ts-ignore
                // onChange={(value: any) => elm.onChange(value)}
                value={formstate[elm.id] ?? ""}
                style={{ borderColor: errorSlug && "red" }}
                onChange={(val: any) => {
                  setErrorSlug(true);
                  val = val.replace(/ |\/|\\/g, "");
                  onChangeTextHandler(val, elm.id, formstate, setFormstate);
                }}
                // tip="Kindly enter your BVN"
              />
              <div style={{ marginTop: 3, color: "#760000" }}>
                {isValidatedSlug && (
                  <span>Validating your payment link...</span>
                )}
                {!isValidatedSlug &&
                  formstate[elm.id] &&
                  formstate[elm.id].trim() !== "" &&
                  `${window.location.origin}/link/${formstate[elm.id].trim()}`}
              </div>
            </FullPaymentFormElement>
          );

        case "text":
          return (
            <PaymentFormElement key={idx}>
              <Form.Field.Input
                key={`INP${idx}`}
                name={elm.id}
                type={elm.type}
                label={elm.title}
                // value={elm?.value}
                // @ts-ignore
                // onChange={(value: any) => elm.onChange(value)}
                value={formstate[elm.id] ?? ""}
                onChange={(val: any) =>
                  onChangeTextHandler(val, elm.id, formstate, setFormstate)
                }
                // tip="Kindly enter your BVN"
              />
            </PaymentFormElement>
          );
        case "amount":
          let curr = ["NGN", "USD", "EUR", "GBP"];
          return (
            <PaymentFormElement key={idx}>
              <Form.Field.InputGroup
                key={`INP${idx}`}
                name={elm.id}
                type="text"
                label={elm.title}
                // childLabel={elm.prepend}
                childLabelInput="select"
                childLabel={
                  <select
                    style={{
                      height: "100%",
                      border: 0,
                      borderRadius: "5px 0px 0px 5px",
                      fontSize: "1.1em",
                    }}
                    onChange={(e: any) =>
                      onChangeTextHandler(
                        e.target.value,
                        "currency",
                        formstate,
                        setFormstate
                      )
                    }
                    value={formstate?.currency}
                  >
                    {curr.map((el: any) => (
                      <option value={el}>{el}</option>
                    ))}
                  </select>
                }
                style={{ borderLeft: 0 }}
                // value={elm?.value || ""}
                // @ts-ignore
                // onChange={(value: any) => elm.onChange(value)}
                value={formstate[elm.id] ?? ""}
                onChange={(val: any) => {
                  if (!/^\d*\.?\d*$/.test(val) && val !== "") return;
                  onChangeTextHandler(val, elm.id, formstate, setFormstate);
                }}
                // tip="Kindly enter your BVN"
              />
            </PaymentFormElement>
          );
        case "select":
          return (
            <PaymentFormElement key={idx}>
              <Form.Field.Select
                key={`INP${idx}`}
                name={elm.id}
                label={elm.title}
                options={elm.options}
                value={formstate[elm.id] ?? ""}
                onChange={(val: any) =>
                  onChangeTextHandler(val, elm.id, formstate, setFormstate)
                }
                // onChange={(e: any) => {}}
              />
            </PaymentFormElement>
          );
        case "url":
          return (
            <PaymentFormElement key={idx}>
              <Form.Field.InputGroup
                key={`INP${idx}`}
                name={elm.id}
                // value={elm?.value}
                // @ts-ignore
                // onChange={(value: any) => elm.onChange(value)}
                value={formstate[elm.id] ?? ""}
                onChange={(val: any) =>
                  onChangeTextHandler(val, elm.id, formstate, setFormstate)
                }
                type="url"
                label={elm.title}
                childLabel={elm.prepend}
              />
            </PaymentFormElement>
          );
        case "textarea":
          return (
            <PaymentFormElement key={idx}>
              <TextAreaContainer>
                <Form.Field.Textarea
                  key={`INP${idx}`}
                  // onChange={(e: any) => {}}
                  value={formstate[elm.id] ?? ""}
                  onChange={(val: any) =>
                    onChangeTextHandler(val, elm.id, formstate, setFormstate)
                  }
                  name={elm.id}
                  label={elm.title}
                />
              </TextAreaContainer>
            </PaymentFormElement>
          );
        case "toggle":
          return (
            <PaymentFormElement style={{ width: "100%" }} key={idx}>
              <SwitchContainer>
                <CheckBoxWrapper>
                  <CheckBox
                    id="checkbox"
                    type="checkbox"
                    onChange={() =>
                      onChangeTextHandler(
                        formstate[elm.id] === "DYNAMIC" ? "FIXED" : "DYNAMIC",
                        elm.id,
                        formstate,
                        setFormstate
                      )
                    }
                    checked={formstate[elm.id] === "DYNAMIC"}
                  />
                  <CheckBoxLabel htmlFor="checkbox" />
                </CheckBoxWrapper>

                <SwitchLink
                  style={{
                    color:
                      formstate[elm.id] === "DYNAMIC" ? " #219653" : "#292929",
                  }}
                >
                  Do you want user to edit amount?
                </SwitchLink>
              </SwitchContainer>
            </PaymentFormElement>
          );
      }
    });
  };

  const onSubmit = async () => {
    if (isFormValid) {
      try {
        setIsProcessing(true);
        let obj: any = {};
        obj.type = "SUBSCRIPTION";
        obj.mode = service?.mode;
        if (formstate.slug && formstate.slug !== "") {
          obj.slug = formstate.slug;
        }
        obj.currency = formstate?.currency ?? "NGN";
        let arr = [
          "name",
          "description",
          "amount",
          "amountType",
          "webhookUrl",
          "frequency",
          "subscriptionPlan",
        ];
        arr.forEach((el: any) => {
          obj[el] = formstate[el];
        });

        let response;

        if (edit) {
          response = await api.patch(
            `/services/${service?.id}/payment-link/${formstate.id}`,
            { ...obj, id: formstate?.id }
          );
        } else {
          response = await api.post(`/services/${service?.id}/payment-link`, {
            ...obj,
          });
        }
        consoleLog("REsponse:", response);
        if (response.status === true) {
          if (
            splittingFormState.value &&
            response?.payload?.id &&
            response?.payload instanceof Object
          ) {
            splittingFormState.paymentLinkId = response?.payload?.id;
            let resp;
            if (edit) {
              if (asExistingSplitting) {
                // splittingFormState.paymentLinkId = response.payload.id;
                resp = await api.patch(
                  `/services/${service?.id}/payment-splitting/${formstate?.split?.id}`,
                  {
                    // id: splittingFormState.id,
                    paymentLinkId: splittingFormState.paymentLinkId,
                    type: splittingFormState.type,
                    value: splittingFormState.value,
                    subAccountId: splittingFormState.subAccountId,
                    status: splittingFormState.status,
                  }
                );
              } else {
                splittingFormState.paymentLinkId = formstate.id;
                resp = await api.post(
                  `/services/${service?.id}/payment-splitting`,
                  splittingFormState
                );
              }
            } else {
              resp = await api.post(
                `/services/${service?.id}/payment-splitting`,
                splittingFormState
              );
            }
            if (resp.status === true) {
              toast.success(resp.message);
            } else {
              toast.error(resp.message);
            }
          }
          setIsProcessing(false);
          history.push(`/my-apps/${service?.id}/payment-link-list`);
          toast.success(response.message);
        } else {
          toast.error(response.message);
        }
      } catch (error) {
        consoleLog(error, "errr");
        setIsProcessing(false);
        // @ts-ignore
        toast.error(error);
      }
    }
  };

  const checkValidSlug = async () => {
    setIsValidatedSlug(true);
    if (formstate.slug !== "") {
      if (edit && formstate.slug === fstate.slug) {
        setErrorSlug(false);
        return;
      }
      try {
        let slug = formstate.slug;

        const response = await api.get(`/checkout/payment-link/${slug}`);
        if (response) {
          toast.error("Slug already exist");
          setFormValid(false);
        }
        consoleLog(response, "resp");
      } catch (err) {
        consoleLog(err, "myerr");
        setErrorSlug(false);
      } finally {
        setIsValidatedSlug(false);
      }
    } else {
      setErrorSlug(false);
      setIsValidatedSlug(false);
    }
    return "";
  };

  useEffect(() => {
    let obj = internalValidations(
      inputArray.filter((x) => verify(x, formstate)),
      formstate
    );
    let objLength = Object.values(obj).filter((el: any) => el === true).length;

    consoleLog("obj compare: ", obj, objLength);
    consoleLog(
      "input array: ",
      inputArray.filter((x) => verify(x, formstate)).length
    );
    if (
      objLength === inputArray.filter((x) => verify(x, formstate)).length &&
      !errorSlug
    ) {
      setFormValid(true);
    } else {
      setFormValid(false);
    }
  }, [formstate, errorSlug]);

  useEffect(() => {
    let delay = 2;
    let timer1 = setTimeout(() => checkValidSlug(), delay * 1000);
    return () => {
      clearTimeout(timer1);
    };
  }, [formstate.slug]);

  useEffect(() => {
    if (edit && defaultSelect === "subscription") {
      setFormstate(fstate);
      setAsExistingSplitting(existSplit);
      setSplittingFormState(splitfstate);
    } else if (edit) {
      setFormstate({ id: fstate?.id });
    } else {
      setFormstate({ amountType: "FIXED" });
    }
  }, []);

  return (
    <>
      <PaymentForm>
        {/* @ts-ignore */}
        <Form
          enableReinitialize
          initialValues={initialValues()}
          validations={validations(
            inputArray.filter((x) => verify(x, formstate)),
            Form
          )}
          onSubmit={onSubmit}
        >
          <>
            {displayFormInput()}
            <AddSubAccountButtons>
              <AddSubAccountBtn
                onClick={() => setShowModal(true)}
                disabled={!formstate.amount}
                className={`${formstate.amount ? "active" : ""}`}
              >
                {splittingFormState.value ? "Update" : "Activate"} Splitting
              </AddSubAccountBtn>
              <AddSubAccountSubmitBtn
                type="submit"
                onClick={onSubmit}
                disabled={!isFormValid}
                isWorking={isProcessing}
              >
                {edit ? "Update" : "Create"} Link
              </AddSubAccountSubmitBtn>
            </AddSubAccountButtons>
          </>
        </Form>
      </PaymentForm>
      <Modal
        isOpen={formstate.amount && showModal}
        width={800}
        withCloseIcon={true}
        onClose={() => setShowModal(false)}
        renderContent={() => (
          <AddSubAccount
            amount={formstate.amount}
            title={"Add Subaccount"}
            splittingFormState={splittingFormState}
            setSplittingFormState={setSplittingFormState}
            setShowModal={setShowModal}
          />
        )}
        overFlow={true}
      />
    </>
  );
};

export default Subscription;
