import { AnimationDuration, FadeAnimation } from "shared/animations";
import { Button, Form } from "shared/components";
import breakpoints from "shared/utils/breakpoints";
import { font } from "shared/utils/styles";
import styled from "styled-components";
import { color } from "../../shared/utils/styles";

type tooglePropT = {
  isActive: boolean;
};

export const PersonalFormCont = styled.div`
  margin-top: 2rem;
  width: 65%;
  /* display: flex; */
`;

//Section header

export const SectionTitle = styled.h2`
  margin-bottom: 1rem;
`;

export const SectionHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${color.borderLight};
  min-height: 2rem;
`;

export const HeaderToogleContainer = styled.div`
  width: 30%;
  display: flex;
  height: 3rem;
  justify-content: space-between;
  align-items: center;
`;

export const ToogleItem = styled.div<tooglePropT>`
  background-color: ${(prop) => prop.isActive && "#fff"};
  border-left: ${(prop) => prop.isActive && `1px solid ${color.borderLight}`};
  border-right: ${(prop) => prop.isActive && `1px solid ${color.borderLight}`};
  border-top: ${(prop) => prop.isActive && `1px solid ${color.borderLight}`};
  border-bottom: ${(prop) => prop.isActive && `0px solid ${color.borderLight}`};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: inherit;
  padding: 0.4rem 0;
  border-top-right-radius: 0.4rem;
  border-top-left-radius: 0.4rem;

  cursor: pointer;
`;
export const Leftcurve = styled.div<tooglePropT>`
  position: absolute;
  bottom: 0px;
  left: -20px;
  height: 100%;
  width: 20px;
  background: #fff;
  display: ${(prop) => (prop.isActive ? "block" : "none")};
  &::before {
    content: " ";
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    border-bottom-right-radius: 20px;
    background: #338773;
  }
`;

export const Rightcurve = styled.div<tooglePropT>`
  position: absolute;
  right: -20px;
  top: 0px;
  height: 100%;
  width: 20px;
  background: #fff;
  display: ${(prop) => (prop.isActive ? "block" : "none")};

  &::before {
    content: " ";
    right: 0;
    position: absolute;
    width: 100%;
    top: 0;
    height: 100%;
    border-bottom-left-radius: 20px;
    background: #338773;
  }
`;

//Form Elements
export const FormInputContainer = styled.div`
  position: relative;
  width: 34%;
  display: flex;
  flex-direction: column;
`;

export const InputLabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.4rem;
`;

export const FormInput = styled.input`
  width: 100%;
  padding: 1rem 0.2rem;
  outline-style: none;
  border: 1px solid ${color.borderLight};
  border-radius: 0.4rem;
`;

export const BusinessFormCont = styled.div`
  margin-top: 2rem;
  width: 100%;
  display: flex;
  animation-name: ${FadeAnimation};
  animation-duration: ${AnimationDuration};
  transition: all ease-in-out 2.8s;
  @media ${breakpoints.device.max.xs} {
    flex-direction: column-reverse;
  }
`;

export const FormGroupContainer = styled.div`
  width: 70%;
  @media ${breakpoints.device.max.xs} {
    width: 100%;
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  @media ${breakpoints.device.max.xs} {
    display: block;
  }
`;

export const FormGroup = styled.div`
  width: 48%;
  /* display: flex; */
  /* gap: 1.5rem; */
  margin-bottom: 1rem;

  @media ${breakpoints.device.max.xs} {
    /* flex-direction: column; */
    width: 100%;
  }
`;

export const FormElement = styled(Form.Element)`
  width: 100%;
  max-width: 640px;
`;

export const FormHeading = styled.h1`
  padding: 6px 0 15px;
  ${font.size(24)}
  ${font.medium}
`;

export const ActionButton = styled(Button)`
  margin-top: 30px;
`;

export const Span = styled.span`
  color: ${color.textDark};
  font-size: 14px;
`;

export const SaveButton = styled(Button)`
  padding: 1.6rem;
  color: white;
  width: 100%;
  background: ${color.fountain};
  border-radius: 0.2rem;
`;
export const ImageBoxFormContainer = styled.div`
  width: 38%;
  padding: 1rem 2rem;
  @media ${breakpoints.device.max.xs} {
    padding: 0;
    width: 100%;
    margin-bottom: 25px;
  }
`;

export const ImageFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.4rem 0;
  margin-top: 0.5rem;
  width: 90%;
  min-height: 2rem;
  background-color: white;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
    rgba(17, 17, 26, 0.1) 0px 0px 8px;
  @media ${breakpoints.device.max.xs} {
    width: 100%;
    margin-top: 25px;
  }
`;

export const ImageContainer = styled.div`
  margin-top: 1.4rem;
  width: 8rem;
  height: 8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background-color: #d7d7d7;
  position: relative;
`;

export const BusinessLogo = styled.img`
  position: relative;
  width: 7.9rem;
  height: 7.9rem;
  border-radius: 100%;
  object-fit: cover;
`;

export const UploadWrapper = styled.div`
  position: relative;
  overflow: hidden;
  display: inline-block;
`;
export const UploadCon = styled.div`
  margin-top: 2rem;
  text-align: center;
`;
export const UploadInfo = styled.p`
  color: green;
  font-size: 10px;
`;
export const FileInput = styled.input.attrs({ type: "file" })`
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
`;

export const UploadButton = styled(Button)`
  color: white;
  background: #828282;
  padding: 1.5rem 4rem;
  border-radius: 0.2rem;
  font-weight: bold;
`;

export const DeleteButton = styled(Button)`
  color: white;
  background: red;
  padding: 1.5rem 4rem;
  border-radius: 0.2rem;
  font-weight: bold;
  width: 100%;
`;

export const DangerzoneTitle = styled.h2`
  margin-top: 50px;
  font-weight: normal;
  font-size: 30px;
  margin-bottom: 2rem;
`;
export const Dangerzone = styled.div`
  width: 100%;
  border: 1px solid red;
  border-radius: 5px;
  padding: 20px;

  & > div {
    width: 100%;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #ccc;
    padding: 5px 0px;

    & > :first-child {
      width: 80%;
    }
  }

  & > div:last-child {
    border-bottom: 0px solid #ccc;
  }
`;

export const TransferButton = styled(Button)`
  color: white;
  background: #828282;
  padding: 1.5rem 4rem;
  border-radius: 0.2rem;
  font-weight: bold;
  width: 20%;
  padding-left:5px;
  padding-right: 5px;
`;
export const ExtraButton = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 15px;
`;
