import breakpoints from "shared/utils/breakpoints";
import styled from "styled-components";

type Props = {
  color?: string;
};
export const SwitchContainer = styled.div`
  width: 75px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  @media ${breakpoints.device.max.xs} {
    width: auto;
  }
`;

export const SwitchLink = styled.p`
  font-size: 14.7px;
  font-weight: 400;
  line-height: 23px;
  position: relative;
  left: -10px;
  bottom: 3px;
  padding-left: 14px;
`;

export const CheckBoxWrapper = styled.div`
  position: relative;
`;

export const CheckBoxLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 32px;
  height: 22px;
  border-radius: 15px;
  background: red;
  cursor: pointer;
  &::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    margin: 3px;
    background: #ffffff;
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
  }
`;
// background: #bebebe;
export const CheckBox = styled.input<Props>`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 32px;
  height: 22px;

  &:checked + ${CheckBoxLabel} {
    background: ${(props) => props.color};
    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 16px;
      height: 16px;
      margin-left: 13px;
      transition: 0.2s;
    }
  }
`;

// background: ${props=>props.color? props.color: '#4fbe79'};
