
import { useState } from "react";
import style from  "./Checkbox.module.css";

const Checkbox = ({ label, value,onChange, ...props }: any) => {
    const [isChecked, setIsChecked] = useState(value);
    
    const handleClick=()=>{
        setIsChecked(!isChecked); 
        onChange()
    }

    return (
        <div className={style.checkboxwrapper}>
            <input
                type="checkbox"
                checked={value}
                onChange={onChange}
                {...props}
                />
            <label>{label}</label>
        </div>
    );
};
export default Checkbox;