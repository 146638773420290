import { useHistory } from 'react-router-dom'
import {
	ParentCont,
	ActionButton2,
	ReportBody,
	SubTitle2,
	SubTitle3,
	CloseBtn,
	CloseWrapper,
	ActionButtonSignOut,
	BusLabelWrapper,
	BusContentWrapper,
	BusContent,
	BusItem1,
	BusItem2,
	BusItem1_1,
	BusItem1_2,
	ReportBodyBusi,
} from './Styles'
import Success from 'App/assets/FPwelcome.gif'
import toast from 'shared/utils/toast'
import api from 'shared/utils/api'
import { useEffect, useState } from 'react'
import Preloader from 'shared/components/preloader'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import consoleLog from 'shared/utils/consoleLog'
import { setKycStartAddress } from 'store/actions/kycstartAction'

import { Icon, Modal } from 'shared/components'
import ExitPage from './exitPage'
import {
	setBusinessLists,
	setCurrentBusiness,
} from 'store/actions/businessAction'
import {
	clearLocalStorage,
	removeStoredAuthToken,
} from 'shared/utils/authToken'
import { logout } from 'store/actions/logout'

function ChooseBusiness() {
	const history = useHistory()
	const dispatch = useDispatch()

	const currentUser = useSelector((store: RootStateOrAny) => store.currentUser)
	const businesses = useSelector((store: RootStateOrAny) => store.businesses)
	consoleLog(businesses, 'businesses')

	const close = () => {
		history.push('/kyc/welcome')
	}

	const setBusiness = async (el: any) => {
		if (el) {
			await dispatch(setCurrentBusiness(el))
			history.push('/overview')
		}
	}

	const logOut = async () => {
		clearLocalStorage()
		await dispatch(logout())

		history.push('/authenticate')
	}

	const displayContent = () => {
		return (
			businesses instanceof Array &&
			businesses.map((el: any, idx: number) => {
				return (
					<BusContent key={`Bus${idx}`} onClick={() => setBusiness(el)}>
						<BusItem1>
							<BusItem1_1>{el?.businessName}</BusItem1_1>
							<BusItem1_2 status={el?.status === 'ACTIVE'}>
								{el?.status === 'ACTIVE' ? 'Verified' : 'Unverified'}
							</BusItem1_2>
						</BusItem1>
						<BusItem2>
							<Icon type={'chevron-right'} size={20} />
						</BusItem2>
					</BusContent>
				)
			})
		)
	}

	return (
		<>
			<ParentCont>
				<ReportBodyBusi>
					<BusLabelWrapper>List of Businesses</BusLabelWrapper>
					<BusContentWrapper>{displayContent()}</BusContentWrapper>
					<ActionButtonSignOut onClick={logOut}>Sign Out</ActionButtonSignOut>
				</ReportBodyBusi>
				<CloseWrapper>
					<CloseBtn onClick={close}>
						<Icon type={'close'} size={20} />
					</CloseBtn>
				</CloseWrapper>
			</ParentCont>
		</>
	)
}

export default ChooseBusiness
