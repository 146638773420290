import styled from "styled-components";

import breakpoints from "shared/utils/breakpoints";
import { color, sizes } from "shared/utils/styles";
import { Button } from "shared/components";

type Props = {
  isActive?: boolean;
};
const paddingLeft = sizes.appNavBarLeftWidth + sizes.secondarySideBarWidth + 40;

export const DashboardPage = styled.div`
  padding: 130px 32px 50px ${paddingLeft - sizes.secondarySideBarWidth - 80}px;
  margin-left: -38px;
  margin-top: -80px;
  @media (max-width: 1100px) {
    padding: -25px 20px 50px ${paddingLeft - 20}px;
  }
  @media (max-width: 999px) {
    padding-left: ${paddingLeft - 20 - sizes.secondarySideBarWidth}px;
  }
`;

export const CardTray = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  // flex-direction: column;
  // flex-wrap: wrap;
  margin-bottom: 20px;
`;

export const TraySubDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  flex-direction: row;
  width: 100%;
`;

export const ActionoloadButtonPdf = styled(Button)`
  background-color: ${color.fountain};
  color: #fff;
  padding: 0 2em;
  height: 50px;
  width: 100%;
  @media ${breakpoints.device.max.xs} {
    width: 100%;
    margin-top: 5px;
  }
  &:hover {
    cursor: pointer;
  }
`;
export const TRevenueText = styled.div`
  color: #292929;

  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
export const TRevenueFigure = styled.div`
  color: #292929;

  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const SubHeader = styled.div`
  height: 72px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.05);
  margin-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  @media ${breakpoints.device.max.xs} {
    flex-direction: column;
    height: auto;
    margin-bottom: 15px;
  }
`;

export const SubHeaderChildren = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* width: 300px; */
  @media ${breakpoints.device.max.xs} {
    width: 100%;
    margin-bottom: 15px;
    margin-top: 15px;
    flex-wrap: wrap;
  }
`;

export const SubHeaderChildren2 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 300px;
  @media ${breakpoints.device.max.xs} {
    width: 100%;
    margin-bottom: 15px;
  }
`;

export const FileDownloadSelect = styled.button<Props>`
  border: none;
  outline: none;
  background-color: ${(props) => (props.isActive ? "#760000" : "transparent")};
  font-weight: 400;
  font-size: 14.7px;
  line-height: 23.44px;
  color: ${(props) => (props.isActive ? "#fff" : "#000")};
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  &:hover {
    background-color: #fff;
  }
`;

export const GraphButton = styled.button<Props>`
  border: none;
  outline: none;
  background-color: ${(props) => (props.isActive ? "#eff3f5" : "transparent")};
  font-weight: 400;
  font-size: 14.7px;
  line-height: 23.44px;
  color: #292929;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  &:hover {
    background-color: #eff3f5;
  }
`;

export const CustomFilterBox = styled.div`
  position: relative;
`;

export const CustomFilterContent = styled.div`
  z-index: 99;
  position: absolute;
  width: 483px;
  /* height: 142px; */
  background: #ffffff;
  padding: 25px 25px 0px 25px;
  border: 1px solid rgba(215, 215, 215, 0.6);
  @media ${breakpoints.device.max.xs} {
    width: 100%;
    right: 0;
    left: 0;
    position: fixed;
  }
`;

export const DateRow = styled.div`
  display: flex;
  justify-content: space-between;
  @media ${breakpoints.device.max.xs} {
    display: block;
  }
`;

export const ButtonRow = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid rgba(215, 215, 215, 0.6);
  padding: 10px 0px;
  margin-top: 30px;
`;

export const CancelButton = styled.button`
  padding: 10px 15px;
  color: rgba(130, 130, 130, 0.9);
  border: 1px solid rgba(215, 215, 215, 0.6);
  cursor: pointer;
  border-radius: 5px;
`;

export const ApplyButton = styled.button`
  padding: 10px 15px;
  color: #ffffff;
  background: #760000;
  border: 1px solid rgba(215, 215, 215, 0.6);
  cursor: pointer;
  border-radius: 5px;
`;

export const CurrencyLabel = styled.p`
  font-weight: 400;
  font-size: 14.7px;
  line-height: 23px;
  color: #292929;
  @media ${breakpoints.device.max.xs} {
    margin-right: 15px;
  }
`;

export const DownloadSummaryBtn = styled.button`
  background-color: #760000;
  border: none;
  outline: none;
  height: 54px;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  line-height: 21px;
  width: 241px;
  cursor: pointer;
  border-radius: 5px;
`;

export const RightStatementDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 10px;
  height: 100%;
  width: 40%;
  // border: 1px solid #ccc;
  // flex-direction: column;
  @media ${breakpoints.device.max.md} {
    width: 100%;
    margin-top: 5px;
  }
`;

export const ExportDropdownDiv = styled.div`
  cursor: pointer;
  display: flex;
  width: 158px;
  height: 54px;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  border: 1px solid rgba(130, 130, 130, 0.9);
`;

export const ExportText = styled.div`
  color: rgba(130, 130, 130, 0.9);

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const PageMidBody = styled.div`
  /* height: 359px; */
  width: 100%;
  display: flex;
  gap: 20px;
  flex-direction: row;
  /* align-items: center; */
  justify-content: space-between;
  margin-bottom: 30px;
  @media ${breakpoints.device.max.xs} {
    flex-direction: column;
    height: auto;
    margin-bottom: 15px;
    gap: 15px;
  }
`;

export const GraphContainer = styled.div`
  /* height: 359px; */
  width: 60%;
  @media (max-width: 1430px) {
    width: 55%;
  }
  @media ${breakpoints.device.max.xs} {
    width: 100%;
    height: auto;
  }
`;

export const AmountLabel = styled.p`
  font-weight: 400;
  font-size: 14.7px;
  line-height: 23px;
  color: #292929;
  margin-bottom: 17px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
`;

export const Amount = styled.p`
  font-weight: 600;
  font-size: 18px;
  line-height: 21.08px;
  color: #292929;
`;

export const BalanceContainer = styled.div`
  position: relative;
  /* height: 435px; */
  width: 37%;
  padding: 20px;
  /* padding-top: 5%; */
  background-color: #fff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* margin-top: 7%; */
  @media ${breakpoints.device.max.xs} {
    width: 100%;
    height: auto;
  }
`;

export const BalanceLabel = styled.p`
  font-weight: 400;
  font-size: 14.7px;
  line-height: 23px;
  color: #292929;
  margin-bottom: 17px;
`;

export const Balance = styled.p`
  font-weight: 400;
  font-size: 14.7px;
  line-height: 23px;
  color: #760000;
  margin-bottom: 10px;
`;

export const LedgerLabel = styled.p`
  font-weight: 400;
  font-size: 14.7px;
  line-height: 23px;
  color: #292929;
  margin-bottom: 17px;
`;

export const BalanceInfo = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 16.41px;
  color: #292929;
  margin-top: 30px;
  margin-bottom: 50px;
  width: 90%;
`;

export const TransactionButton = styled.button`
  width: 100%;
  background-color: #828282;
  border: none;
  outline: none;
  height: 48px;
  color: #fff;
  font-weight: 400;
  font-size: 14px;
  line-height: 16.41px;
  cursor: pointer;
  border-radius: 5px;
  bottom: 2rem;
  right: 0;
`;

export const PageBottomBody = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1.5em;
  // display: flex;
  // flex-wrap: wrap;
  // justify-content: space-between;
  @media ${breakpoints.device.max.xs} {
    display: flex;
    flex-direction: column;
  }
`;

export const Transaction = styled.div`
  /* width: 498px; */
  height: 97px;
  background-color: #fff;
  margin-bottom: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  @media ${breakpoints.device.max.xs} {
    width: 100%;
  }
`;

export const TransactionDesc = styled.div`
  /* width: 100%; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
`;

export const Transaction2 = styled.div`
  /* width: 498px; */
  height: 97px;
  background-color: #fff;
  margin-bottom: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  padding: 20px;
  @media ${breakpoints.device.max.xs} {
    width: 100%;
  }
`;

export const TransactionDesc2 = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
`;

export const TransactionLabel = styled.p`
  font-weight: 400;
  font-size: 14.7px;
  line-height: 23px;
  color: #292929;
`;

export const TransactionAmount = styled.p`
  font-weight: 400;
  font-size: 14.7px;
  line-height: 23px;
  color: #760000;
`;

export const NoSettlement = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 16.41px;
  color: #292929;
`;

export const SettlementLink = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 18.75px;
  color: #292929;
  text-decoration: underline;
  cursor: pointer;
`;

export const ChartBackground = styled.div`
  width: 100%;
  //background-image: linear-gradient(rgba(0, 143, 14, 0),rgba(0, 143, 14, 1))
  background-color: #fff !important;
  padding: 30px;
  border-radius: 20px;
`;

export const GraphContainer2 = styled.div`
  width: 60%;
  padding: 1.8rem 1rem;
  border-radius: 1rem;
  background-color: #fff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
`;

export const GraphHeader = styled.div`
  display: flex;
  justify-content: end;
`;

export const GraphContent = styled.div`
  margin-top: 1.4rem;
  background: #fff;
`;
