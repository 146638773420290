import { useState, useCallback } from 'react';
import { isFunction } from 'lodash';

type PropT = {
  [x:string]: any
}
type PropT2= (x:PropT) =>void
  


const useMergeState :any = (initialState :PropT) => {
  const [state, setState] = useState(initialState || {});

  const mergeState  = useCallback((newState:PropT) => {
    if (isFunction(newState)) {
      setState(currentState => ({ ...currentState, ...newState(currentState) }));
    } else {
      setState(currentState => ({ ...currentState, ...newState }));
    }
  } , []);

  return [state, mergeState];
};

export default useMergeState;
