import styled from "styled-components";
import { color } from "../../utils/styles";

const Container = styled.div`
  display: flex;
  margin-left: 11.75px;
`;

const AttributeContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 12px;
  padding: 0.8rem 0;
`;

const Label = styled.label`
  position: relative;
  padding-left: 2.4rem;
  margin-left: 0.1rem;
  cursor: pointer;
  /* font-size: 16px; */
  color: ${color.textDark};
  -webkit-user-select: auto;
  -moz-user-select: auto;
  -ms-user-select: auto;
  user-select: auto;
  &:hover {
    color: ${color.textDark};
  }
`;

const Checkmark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border: 2px solid ${color.fountain};
  border-radius: 50%;
  &::after {
    content: "";
    position: absolute;
    display: none;
    top: 2px;
    left: 2px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: ${color.fountain};
  }
`;

const Input = styled.input`
  display: none;
  &:checked ~ ${Checkmark} {
    border: 2px solid ${color.fountain};
    transition: all 0.25s ease-in-out;
  }
  &:checked ~ ${Checkmark}:after {
    display: block;
  }
`;

export { Label, Input, Checkmark, Container, AttributeContainer };
