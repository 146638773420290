import styled from "styled-components";
import { color } from "shared/utils/styles";

type PropT = {
  variants?: string;
};

export const EmptyContainer = styled.div`
  margin-top: 20px;
`;

export const StatusContainer = styled.div<PropT>`
  color: ${(props) => color[props.variants ?? "primary"]};
`;
