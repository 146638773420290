import { authPath } from "apiServices/paths";
import Gmail from "App/assets/images/icons/gmail.svg";
import Outlook from "App/assets/images/icons/outlook.svg";
import Logo from "App/assets/images/logo.svg";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { GradientLoader, OTPInput } from "shared/components";
import Preloader from "shared/components/preloader";
import api from "shared/utils/api";
import { getTempId } from "shared/utils/authToken";
import toast from "shared/utils/toast";
import {
  Actions,
  Footer,
  Info,
  OtpContainer,
  ReportBody,
  SubTitle,
  Title,
  OtpBtnContainer,
  OtpBtn,
  AuxOtpText,
} from "./Styles";
import { omniGetter, omniRemover } from "shared/utils/authToken";
import consoleLog from "shared/utils/consoleLog";
import { storeTempId } from "shared/utils/authToken";

const ReceiveOtp = () => {
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isLoginIn, setIsLoginIn] = useState(false);
  const [sentOtp, setSentOtp] = useState(false);
  const history = useHistory();

  const [otp, setOtp] = useState("");

  const resendOtp = async () => {
    let email = omniGetter("otp_email");
    setIsLoginIn(true);
    try {
      const { payload }: any = await api.post("/authentication/send-otp", {
        email,
      });
      consoleLog(payload);
      storeTempId(payload.tempId);
      setIsLoginIn(false);
      setSentOtp(true);
    } catch (error) {
      // @ts-ignore
      toast.error(error ?? "Something went wrong");
      setIsLoginIn(false);
      setSentOtp(false);
    }
  };

  const handleChange = async (otp: string) => {
    let tempId: any = getTempId();
    setError(false);
    setOtp(otp);
    if (otp.length === 6) {
      const values: any = {
        tempId,
        otp,
      };
      // setIsLoginIn(true);
      try {
        const data = await api.post("/authentication/confirm-otp", {
          ...values,
        });

        toast.success("OTP is Verified");
        history.push(authPath.OTP_SUCCESS);
      } catch (error) {
        //history.push(authPath.OTP_FAILED)
        setError(true);
        toast.error("Invalid OTP");
      }
    }
  };

  if (isLoginIn) {
    return <GradientLoader />;
  }
  return (
    <ReportBody>
      {/* <img src={Logo} alt="Logo" /> */}
      <Title>Check your email for a code.</Title>
      <SubTitle>
        We have sent a 6 - digit code to your email address. <br /> The code
        expires shortly, please enter it soon.
      </SubTitle>

      <OtpContainer>
        {" "}
        <OTPInput
          autoFocus
          isNumberInput
          length={6}
          hasError={error}
          errorStyle={{ borderColor: "red" }}
          inputStyle={{
            border: "1px solid #828282",
            width: "13.2%",
            height: "78px",
            fontSize: "30px",
            color: "#000",
            fontWeight: "400",
            caretColor: "#000",
            textAlign: "center",
            borderRight: "0px",
          }}
          focusStyle={
            {
              //border: "1px solid #CFD3DB",
            }
          }
          firstIndexStyle={{
            borderTopLeftRadius: "8px",
            borderBottomLeftRadius: "8px",
            borderRight: "0px",
          }}
          lastIndexStyle={{
            borderTopRightRadius: "8px",
            borderBottomRightRadius: "8px",
            borderRight: "1px solid #828282",
            borderLeft: "1px solid #828282",
          }}
          className="otpContainer"
          inputClassName="otpInput"
          separator={
            <span
              style={{
                marginLeft: "10px",
                marginRight: "10px",
                marginTop: "10%",
                fontSize: "50px",
                color: "black",
                fontWeight: "bold",
              }}
            >
              -
            </span>
          }
          onChangeOTP={handleChange}
        />
      </OtpContainer>

      <OtpBtnContainer>
        <OtpBtn onClick={resendOtp}>
          Haven't gotten your OTP ?<AuxOtpText> Resend</AuxOtpText>
        </OtpBtn>
      </OtpBtnContainer>

      <Actions>
        <div
          style={{ cursor: "pointer" }}
          onClick={() => window.open("https://www.gmail.com", "_blank")}
        >
          <img src={Gmail} alt="Gmail" />
          <p>Open Gmail.</p>
        </div>

        <div
          style={{ cursor: "pointer" }}
          onClick={() => window.open("https://outlook.office.com/", "_blank")}
        >
          <img src={Outlook} alt="Outlook" />
          <p>Open Outlook.</p>
        </div>
      </Actions>

      <Info>Can’t find your code? Check your spam folder.</Info>
      <Footer>
        <p style={{ cursor: "pointer" }}>
          <a href="https://fountainpay.ng/privacy" target="_blank">
            Privacy and terms
          </a>
        </p>{" "}
        <p style={{ cursor: "pointer" }}>
          <a href="https://fountainpay.ng/" target="_blank">
            Contact us
          </a>
        </p>
      </Footer>
    </ReportBody>
  );
};

export default ReceiveOtp;
