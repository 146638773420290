import api from "shared/utils/api";
import { ActionTypes } from "../constants/actionType";
import consoleLog from "shared/utils/consoleLog";

export const setKycBoardingRec = (payload = {}) => {
  return async (dispatch: any) => {
    await dispatch({
      type: ActionTypes.KYC_BOARDING_RECORD,
      payload,
    });
  };
};
