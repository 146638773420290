import PaymentLinkModalImg from "App/assets/images/services/paymentLinkModal.svg";
import { useEffect, useState } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { Form, GradientLoader } from "shared/components";
import ImageLoader from "shared/components/ImageLoader";
import api from "shared/utils/api";
import { onChangeTextHandler } from "shared/utils/formValidation";
import AddSubAccountModal from "SubAccount/AddSubAccount";
import subaccountClass from "SubAccount/subaccountClass";
import {
  ActionButton,
  FormBody,
  FormContainer,
  FormElement,
  FormTitle,
  LeftPane,
  RightPane,
  Selectors,
} from "../PaymentLink/Styles";
import {
  AddSubAccountStyle,
  SplitInputRow,
  TransSplitContainer,
  TransSplitIcon,
  TransSplitTitle,
  TypeButton,
  TypeButtonGroup,
} from "./Styles";
import consoleLog from "shared/utils/consoleLog";

type PropT = {
  onCreate: Function;
  modalClose: Function;
  chargeType: String;
  handleBack: Function;
};

// const subAccounts = [
//   {
//     value: "main-account",
//     label: "Main Account",
//   },
// ];

type accountPropT = {
  id: number;
  sn: number;
  subaccountname: string;
  servicename: string;
  bank_account: any;
  Bank_name: string;
  account_name: string;
}[];

const PaymentSplitting = ({
  title,
  amount,
  splittingFormState,
  setSplittingFormState,
  setShowModal,
}: any) => {
  const [fstate, setFstate] = useState({} as any);
  const [subAccounts, setSubAccounts] = useState<accountPropT>([]);
  const [isLoading, setisLoading] = useState(false);

  //subaccount states
  const [showSubAccount, setShowSubAccount] = useState(false);
  const [bankList, setbankList] = useState([]);
  // const [subAccountForm, setSubAccountsForm] = useState<subAccountPropT>({
  //   name: "",
  //   bankName: "",
  //   accountNumber: "",
  //   accountName: "",
  //   bankCode: "",
  // });
  const [banknameStatus, setbanknameStatus] = useState("");
  const [accountError, setAccountError] = useState(false);
  const [isSubFormValid, setSubFormValid] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const [yourShare, setYourShare] = useState(100);
  const [sharePercentage, setSharePercentage] = useState(0);

  const [yourAmount, setYourAmount] = useState(0);
  const [fixedAmount, setFixedAmount] = useState(0);

  const [selected, setSelected] = useState("PERCENTAGE");

  const [isFormValid, setFormValid] = useState(false);
  const [launchSubAccount, setLaunchSubAccount] = useState(false);

  // const [amount, setAmount] = useState(20);

  // let accountList: accountPropT = [];

  const currentbusiness = useSelector(
    (store: RootStateOrAny) => store.business
  );

  // const [{ data, error, setLocalData }, fetchData] = useApi.get(
  //   `/client/business/${currentbusiness?.id}/sub-accounts`
  // );

  const getAllSubAccounts = async (val?: string) => {
    try {
      setisLoading(true);
      const response = await api.get(
        `/client/business/${currentbusiness?.id}/sub-accounts`
      );
      if (response.status === true) {
        const subs = response.payload;
        // setisLoading(false);
        const accountList = subs.map((account: any, index: any) => {
          const acc = {
            id: account.id,
            sn: index + 1,
            subaccountname: account.name,
            servicename: account.servicename
              ? account.servicename
              : "No service name",
            bank_account: account.accountNumber,
            Bank_name: account.bankName,
            account_name: account.accountName,
          };
          return acc;
        });
        setSubAccounts(accountList);
        if (val) {
          // alert(val);
          consoleLog(accountList, "accountList");
          let findObj = accountList.find(
            (el: any) => el.subaccountname === val
          );
          if (findObj) {
            // alert(2);
            setFstate({ ...fstate, subAccountId: findObj?.id });
          }
        }
        return accountList;
      }
    } catch (error) {
    } finally {
      setisLoading(false);
    }
  };

  const fetchData = (val?: string) => getAllSubAccounts(val);
  // consoleLog("sub account data:", subAccounts);

  const inputArray: any = [
    {
      id: "amount",
      title: "Amount",
      type: "text",
      value: amount,
      // condition: needAmount,
      onChange: () => {},
      disabled: true,
    },
    {
      id: "addsub",
      title: "addsub",
      type: "addsub",
    },
    {
      id: "subAccountId",
      title: "Sub Account",
      type: "select",
      options: () => [
        { label: "--- Select Sub Account ---", value: "" },
        ...subAccounts.map((sub) => ({
          label: sub?.subaccountname,
          value: sub?.id,
        })),
        // {
        //   label: (
        //     <div
        //       style={{ fontSize: 15, textAlign: "center", color: "#ffffff" }}
        //     >
        //       <span style={{ fontSize: 20, marginRight: 10 }}>&#x2B;</span> Add
        //       new subaccount
        //     </div>
        //   ),
        //   value: "99",
        //   background: "#760000",
        // },
      ],
    },
  ];

  const initialValues = () => {
    let obj: any = {};
    for (let elm of inputArray) {
      obj[elm.id] = "";
    }
    return obj;
  };

  const displayFormInput = () => {
    return inputArray.map((elm: any, idx: number) => {
      switch (elm.type) {
        case "addsub":
          return (
            <AddSubAccountStyle onClick={() => setLaunchSubAccount(true)}>
              Add Sub Account
            </AddSubAccountStyle>
          );
        case "text":
          return (
            <Form.Field.Input
              key={`INP${idx}`}
              name={elm.id}
              type={elm.type}
              label={elm.title}
              value={elm.value}
              // @ts-ignore
              onChange={(value: any) => elm.onChange(value)}
              disabled={elm.disabled}
              // tip="Kindly enter your BVN"
            />
          );
        case "select":
          return (
            <Form.Field.Select
              key={`INP${idx}`}
              name={elm.id}
              label={elm.title}
              options={elm.options()}
              onChange={(val: any) => {
                onChangeTextHandler(val, elm.id, fstate, setFstate);
              }}
              // @ts-ignore
              value={fstate.subAccountId}
            />
          );
      }
    });
  };

  const getAllBanks = async () => {
    try {
      const banks = await subaccountClass.getAllBanks();
      const sortedBanks = banks.sort((a: any, b: any) =>
        a.name > b.name ? 1 : b.name > a.name ? -1 : 0
      );
      if (sortedBanks) {
        setbankList(sortedBanks);
      }
    } catch (err) {
      // @ts-ignore
      toast.error(err);
    }
  };

  // const displaySubAccountFormInput = () => {
  //   return inputSubAccountArray.map((elm, idx) => {
  //     switch (elm.type) {
  //       case "text":
  //         return (
  //           <Form.Field.Input
  //             key={`INP${idx}`}
  //             name={elm.id}
  //             type={elm.type}
  //             label={elm.title}
  //             value={elm.value}
  //             // @ts-ignore
  //             onChange={(value: any) => elm.onChange(value)}
  //             disabled={elm.disabled}
  //             // tip="Kindly enter your BVN"
  //           />
  //         );
  //       case "accountNumber":
  //         return (
  //           <>
  //             <Form.Field.Input
  //               key={`INP${idx}`}
  //               name={elm.id}
  //               type={elm.type}
  //               label={elm.title}
  //               value={elm.value}
  //               maxLength={10}
  //               // @ts-ignore
  //               onChange={(value: any) => elm.onChange(value)}
  //               disabled={elm.disabled}
  //               // tip="Kindly enter your BVN"
  //             />
  //             <div style={{ textAlign: "left" }}>
  //               {banknameStatus}
  //               {accountError ? (
  //                 <small style={{ color: "red" }}>
  //                   Wrong account number, please check and try again.
  //                 </small>
  //               ) : null}
  //             </div>
  //           </>
  //         );
  //       case "select":
  //         return (
  //           <Form.Field.Select
  //             key={`INP${idx}`}
  //             name={elm.id}
  //             label={elm.title}
  //             options={elm.options}
  //             onChange={(val: any) => {
  //               onChangeTextHandler(
  //                 val,
  //                 elm.id,
  //                 subAccountForm,
  //                 setSubAccountsForm
  //               );
  //             }}
  //             value={subAccountForm.bankName}
  //           />
  //         );
  //     }
  //   });
  // };

  useEffect(() => {
    setSelected(
      splittingFormState.type === "PERCENTAGE" ? "PERCENTAGE" : "FLAT"
    );
    if (splittingFormState.type === "PERCENTAGE") {
      setSharePercentage(splittingFormState.value);
      handleShare(splittingFormState.value);
    } else {
      setFixedAmount(splittingFormState.value);
      handleFixedAmount(splittingFormState.value);
    }
    if (splittingFormState.subAccountId) {
      setFstate({ subAccountId: splittingFormState.subAccountId });
    }
  }, []);

  // useEffect(() => {
  //   if (data?.payload.length) {
  //     setisLoading(false);
  //     const accountList = data?.payload.map((account: any, index: any) => {
  //       const acc = {
  //         id: account.id,
  //         sn: index + 1,
  //         subaccountname: account.name,
  //         servicename: account.servicename
  //           ? account.servicename
  //           : "No service name",
  //         bank_account: account.accountNumber,
  //         Bank_name: account.bankName,
  //         account_name: account.accountName,
  //       };
  //       return acc;
  //     });
  //     setSubAccounts(accountList);
  //   }
  //   if (!data) {
  //     // setisLoading(true);
  //   }
  // }, [data]);

  useEffect(() => {
    fetchData();
  }, [launchSubAccount]);

  useEffect(() => {
    if (!fstate.subAccountId) {
      setFormValid(false);
      return;
    }
    if (
      //@ts-ignore
      (selected === "PERCENTAGE" && !sharePercentage) ||
      //@ts-ignore
      (selected === "FLAT" && !fixedAmount)
    ) {
      setFormValid(false);
      return;
    }
    setFormValid(true);
  }, [fstate, selected, sharePercentage, fixedAmount]);

  // useEffect(() => {
  //   if (
  //     !subAccountForm.name ||
  //     !subAccountForm.bankName ||
  //     !subAccountForm.accountName ||
  //     !subAccountForm.accountNumber
  //   ) {
  //     setSubFormValid(false);
  //     return;
  //   }
  //   setSubFormValid(true);
  // }, [subAccountForm]);

  const handleShare = (percentage: any) => {
    const regexp = new RegExp("^[0-9]*$");
    if (!regexp.test(percentage) || +percentage < 0 || +percentage > 100) {
      return;
    }
    setSharePercentage(+percentage);
    setYourShare(100 - +percentage);
  };

  const handleFixedAmount = (fixedAmount: any) => {
    const regexp = new RegExp("^[0-9]*$");
    if (
      !regexp.test(fixedAmount) ||
      +fixedAmount < 0 ||
      +fixedAmount > amount
    ) {
      return;
    }
    setFixedAmount(+fixedAmount);
    setYourAmount(amount - +fixedAmount);
  };

  const onSubmit = async () => {
    try {
      const formdata = {
        subAccountId: fstate.subAccountId,
        type: selected,
        value: selected === "PERCENTAGE" ? sharePercentage : fixedAmount,
      };
      setSplittingFormState(formdata);
      setShowModal(false);
      // consoleLog(formdata);
    } catch (error) {}
  };

  // useEffect(() => {
  //   if (fstate.subAccountId === "99") {
  //     setLaunchSubAccount(true);
  //   }
  // }, [fstate.subAccountId]);
  const openModal = () => {};
  const backFn = () => {
    setLaunchSubAccount(false);
    setFstate({ ...fstate, subAccountId: "" });
  };
  if (launchSubAccount)
    return (
      <AddSubAccountModal
        backFn={backFn}
        openModal={openModal}
        fetchData={fetchData}
      />
    );

  return (
    <FormBody>
      {isLoading ? (
        <GradientLoader />
      ) : (
        <LeftPane>
          <div style={{ width: "100%" }}>
            <FormTitle>{title}</FormTitle>

            <TypeButtonGroup>
              <TypeButton
                onClick={() => setSelected("PERCENTAGE")}
                className={selected === "PERCENTAGE" ? "selected" : ""}
              >
                Percentage
              </TypeButton>
              <TypeButton
                onClick={() => setSelected("FLAT")}
                className={selected === "FLAT" ? "selected" : ""}
              >
                Fixed
              </TypeButton>
            </TypeButtonGroup>

            {/* @ts-ignore */}

            <Form
              enableReinitialize
              initialValues={{
                paymentName: "",
                redirectLink: "",
                description: "",
                amount: "",
              }}
              validations={{
                subaccountperc: Form.is.required(),
                subaccountflat: Form.is.required(),
              }}
              onSubmit={onSubmit}
            >
              <FormElement>
                <FormContainer>
                  {displayFormInput()}

                  <TransSplitContainer>
                    <TransSplitTitle>Transaction Split</TransSplitTitle>
                  </TransSplitContainer>
                  {selected === "PERCENTAGE" ? (
                    <SplitInputRow
                      style={{
                        display: "flex",
                      }}
                    >
                      <Form.Field.Input
                        name="share"
                        type="text"
                        placeholder={"100%"}
                        label="Original amount"
                        disabled
                        value={"100%"}
                      />
                      <TransSplitIcon>-</TransSplitIcon>
                      <Form.Field.Input
                        name="subaccountperc"
                        type="text"
                        placeholder=""
                        label="Subaccount share"
                        value={sharePercentage}
                        required
                        onChange={(e: any) => handleShare(e)}
                      />
                      <>
                        <TransSplitIcon>=</TransSplitIcon>
                        <Form.Field.Input
                          name="subaccountperc"
                          type="text"
                          placeholder={"100%"}
                          disabled
                          label="Your share"
                          value={`${yourShare}%`}
                        />
                      </>
                    </SplitInputRow>
                  ) : (
                    <SplitInputRow
                      style={{
                        display: "flex",
                      }}
                    >
                      <Form.Field.Input
                        name="share"
                        type="text"
                        placeholder={amount}
                        label="Original amount"
                        disabled
                        value={amount}
                      />
                      <TransSplitIcon>-</TransSplitIcon>
                      <Form.Field.Input
                        name="subaccountflat"
                        type="text"
                        placeholder=""
                        required
                        label="Subaccount share"
                        value={fixedAmount}
                        onChange={(e: any) => handleFixedAmount(e)}
                      />
                      <>
                        <TransSplitIcon>=</TransSplitIcon>
                        <Form.Field.Input
                          name="subaccountperc"
                          type="text"
                          placeholder={"100%"}
                          disabled
                          label="Your share"
                          value={yourAmount}
                        />
                      </>
                    </SplitInputRow>
                  )}
                </FormContainer>

                <Selectors>
                  <ActionButton
                    type="submit"
                    variant="primary"
                    onClick={onSubmit}
                    disabled={!isFormValid}
                    style={{ marginRight: "10px" }}
                  >
                    Save
                  </ActionButton>
                </Selectors>
              </FormElement>
            </Form>
          </div>
        </LeftPane>
      )}
      <RightPane
        style={{
          backgroundImage: `url(${PaymentLinkModalImg}), backgroundSize: "contain"
    backgroundRepeat: "no-repeat"`,
        }}
      >
        <ImageLoader
          source={PaymentLinkModalImg}
          width="250"
          height="450"
          alt="Add account"
          style={{ width: "100%", height: "auto" }}
        />
        {/* <img
          src={PaymentLinkModalImg}
          alt="Add account"
          style={{ width: "100%", height: "auto" }}
        /> */}
      </RightPane>
    </FormBody>
  );
};

export default PaymentSplitting;
