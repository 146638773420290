import React from 'react';
import PropTypes from 'prop-types';

// const propTypes = {
//   className: PropTypes.string,
//   size: PropTypes.number,
// };

type PropT ={
  logo: string,
  className: string,
  size: number,
}

const defaultProps = {
  className: undefined,
  size: 30,
};

const ProjectAvatar = ({ logo, className, size }:PropT) => (
  <span className={className}>
    <img src={logo} />
  </span>
);

// ProjectAvatar.propTypes = propTypes;
ProjectAvatar.defaultProps = defaultProps;

export default ProjectAvatar;
