import { RootStateOrAny, useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import api from "shared/utils/api";
import consoleLog from "shared/utils/consoleLog";
import { useEffect, useState } from "react";
import { ContentHeader, ContentRow, InnerPanelLayout, PanelTitle, ViewAll } from "./Style";
import { copyToClipboard } from "shared/utils/browser";
import { Icon } from "shared/components";

export default () => {
  const [paymentLinkDetails, setPaymentLinkDetails] = useState([]);
  const service = useSelector((store: RootStateOrAny) => store.service);
  const dispatch = useDispatch();
  const history = useHistory();

  const getPaymentLinks = async (id = "") => {
    let mode =
      id === "1" ? (service?.mode === "LIVE" ? "TEST" : "LIVE") : service?.mode;
    try {
      const paymentLinkResp = await api.get(
        `/services/${service?.id}/payment-link`,
        {
          mode,
          id: service?.id,
          limit: 5,
          page: 1
        }
      );
      // console.log("Links: ", paymentLinkResp)
      if (
        paymentLinkResp?.status == true &&
        paymentLinkResp?.payload instanceof Array
      ) {
        setPaymentLinkDetails(paymentLinkResp.payload);
      } else {
        setPaymentLinkDetails([]);
      }
    } catch (err) {
      consoleLog(err);
    }
  };

  const truncate=(item: any)=> {
    const base=process.env.REACT_APP_URL ? process.env.REACT_APP_URL: "https://app.fountainpay.ng"; 
    const str: string=item?.slug.length > 20? item?.slug.substring(0, 7) + "...": item?.slug;
    const elipseBase: string=base.length > 5? base.substring(0, 7) + "...": base;
    const link: string=`${base}/link/${item?.slug}`
    return(
      <>
        {elipseBase + '/link/'+str}
        <Icon
          style={{ marginTop: "7px" }}
          type={"copy"}
          size="18"
          color="#ccc"
          isHover={true}
          onClick={() => copyToClipboard(link)}
        />
      </>
    )
  }
  
  const arrayDataItems = paymentLinkDetails.map((item: any, id: number) => (
    <ContentRow key={id}>
      <span>{item?.name}</span>
      {truncate(item)}
    </ContentRow>
  ));
  
  useEffect(() => {
    getPaymentLinks();
  }, []);

  return (
    <InnerPanelLayout>
      <PanelTitle>Payment links</PanelTitle>
      <ContentHeader>
          <span>Name</span>
          <span>Link</span>
      </ContentHeader>
      {arrayDataItems}
      <ViewAll onClick={()=>history.push(`/my-apps/${service?.id}/payment-link-list`)}>View all</ViewAll>
    </InnerPanelLayout>
  );
};
