import {
  GradientLoader,
  Icon,
  PageError,
  ProfilePicture,
} from "shared/components";
import {
  AccountOfficer,
  ActionArea,
  AssignOfficer,
  Container,
  Content,
  Header,
  Profile,
} from "./Style";
import { ActionButton, UpdateButton } from "../AddSubAgent/styles";
import { useEffect, useState } from "react";
import { color } from "shared/utils/styles";
import {
  Redirect,
  Route,
  BrowserRouter,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import api from "shared/utils/api";
import toast from "shared/utils/toast";
import Terminals from "./Terminals";
import Transactions from "./Transactions";
import AssignTerminal from "./AssignTerminal";
import Overview from "./Overview";
import { RootStateOrAny, useSelector } from "react-redux";
import { numberWithCommas } from "shared/utils/utility";
import { PanelContainer, Tab } from "Agency/Inventory/Styles";
import Assets from "./Assets";
import Account from "./Account";
import Profiles from "./Profile";

// console.log(window.location)


const AgentDetail = () => {
  const tabs=["overview", "profile", "transactions", "temrinals", "assets"]
  const business = useSelector((store: RootStateOrAny) => store.business);
  const [currentTab, setcurrentTab] = useState("overview");
  const [currentPath, setCurrentPath] = useState(window.location.href);
  const params: any = useParams();
  // // console.log("PARAMS: ", params)
  const [mapTerminal, setMapTerminal] = useState(false);
  const [loadedComponent, setLoadedComponent] = useState(
    params.tab ?? "overview"
  );

  const [isLoadingDetails, setIsLoadingDetails] = useState(false);
  const [isLoadingComponent, setIsLoadingComponent] = useState(false);
  const [agentDetail, setAgentDetail] = useState<any>({});

  const { url, path } = useRouteMatch();
  const history = useHistory();
  
  

  const getToLink = (val: string) => {
    window.history.replaceState(
      null,
      "",
      `${window.location.origin}/agency/agents/detail/${params.id}/${val}`
    );
    setLoadedComponent(val);
  };

  const getAgentDetails = async () => {
    setIsLoadingDetails(true);
    try {
      const res = await api.get(`/agency-banking/agents/${params.id}/detail`);
      if (res.status) {
        setAgentDetail(res.payload);
      }
    } catch (err) {
      const error: any = err;
      toast.error(error?.message);
    } finally {
      setIsLoadingDetails(false);
    }
  };

  const handleSuspendAgent = () => {
    setMapTerminal(!mapTerminal);
  };

  const updateAddressBar=(path: string)=>{
    setcurrentTab(path);
    const pathSplit=currentPath.split("/");
    let active= pathSplit[pathSplit.length-1];
    const found=tabs.includes(active);
    // console.log("Found path: ", found)
    let newPath=currentPath;
    if(found){
      newPath=currentPath.replace(active, '');
    }
    
    path=`${newPath}/${path}`;
    // console.log("Current path: ", path)
    window.history.pushState({}, "", path);
  }

  useEffect(() => {
    setIsLoadingComponent(true);
    setTimeout(() => {
      setIsLoadingComponent(false);
    }, 400);
  }, [loadedComponent]);

  useEffect(() => {
    getAgentDetails();
  }, []);

  useEffect(() => {
    
    const pathSplit=currentPath.split("/");
    let active= pathSplit[pathSplit.length-1];
    const found=tabs.includes(active);
    if(found){
      setcurrentTab(active)
    }
  }, []);


  return (
    <Container>
      <div style={{display:'flex', flexDirection: "row", gap: '10px'}}>
        <Icon
            style={{ paddingTop: "5%", cursor: "pointer", marginRight: "30px" }}
            type="chevron-left"
            size="48"
            color="#ccc"
            onClick={() => history.goBack()}
        />
        <ProfilePicture
            name={`${agentDetail.lastName} ${agentDetail.firstName} ${agentDetail.middleName}`}
            size="10rem"
            />
        <div style={{marginTop: "50px", marginLeft: "10px"}}>
          <div>{agentDetail.firstName} {agentDetail.middleName} {agentDetail.lastName}</div>
          <div>{agentDetail.email} | {agentDetail.phone}</div>
        </div>
      </div>
      <PanelContainer>
        <Tab
          isactive={currentTab === "overview" ? true : false}
          onClick={() => updateAddressBar("overview")}
        >
          Overview
        </Tab>
        <Tab
          isactive={currentTab === "profile" ? true : false}
          onClick={() => updateAddressBar("profile")}
        >
          Profile
        </Tab>
        <Tab
          isactive={currentTab === "transactions" ? true : false}
          onClick={() => updateAddressBar("transactions")}
        >
          Transactions
        </Tab>
        <Tab
          isactive={currentTab === "temrinals" ? true : false}
          onClick={() => updateAddressBar("terminals")}
        >
          Terminals
        </Tab>
        {/* <Tab
          isactive={currentTab === "account" ? true : false}
          onClick={() => updateAddressBar("account")}
        >
          Account Info
        </Tab> */}
        <Tab
          isactive={currentTab === "assets" ? true : false}
          onClick={() => updateAddressBar("assets")}
        >
          Assets
        </Tab>
      </PanelContainer>

      <Content>
        {currentTab === "overview" && <Overview agentDetail={agentDetail} />}
        {currentTab === "profile" && <Profiles agentDetail={agentDetail} />}
        {currentTab === "assets" && <Assets />}
        {currentTab === "terminals" && <Terminals />}
        {currentTab === "account" && <Account agentDetail={agentDetail} />}
        {currentTab === "transactions" && <Transactions
                businessId={business.id}
                agentId={params.id}
                terminalId={params.ext}
              />}
      </Content>
    </Container>
  );
};

export default AgentDetail;
