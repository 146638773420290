import { Button } from "shared/components";
import breakpoints from "shared/utils/breakpoints";
import styled from "styled-components";
import { color, font } from "../utils/styles";

type ContentRowProp = {
  role?: string;
};

export const PageLayout = styled.div`
  width: 100%;
  //padding: 0 0.6rem 1.4rem 1.4rem;
  margin-top: 2.4rem;
  /* background-color: white; */
  @media ${breakpoints.device.max.xs} {
    margin-top: 10px;
  }
`;

export const Sectionbutton = styled.button`
  background-color: ${color.fountain};
  color: white;
  padding: 1rem 2.2rem;
  border-radius: 0.2rem;
  cursor: pointer;

  &:hover {
    background-color: ${color.fountainAccent};
    transition: all ease 0.4s;
  }
  &:active {
    background-color: ${color.fountainAccent};
  }
`;

export const SectionHeader = styled.div`
  width: 100%;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background-color: #fff;
`;

export const SectionHeaderLabel = styled.h3`
  color: ${color.textDark};
`;

export const ContentHeaderContainer = styled.tr`
  /* display: flex; */
  padding: 1rem 2.4rem;
  /* gap: 2.5rem; */
  background-color: #f1e6e6;
  @media ${breakpoints.device.max.xs} {
    padding: 10px;
  }
`;

export const ContentHeader = styled.th`
  /* width: 18%; */
  color: ${color.fountain};
  font-size: 0.95rem;
  font-weight: ${font.bold};
  padding: 15px;
  text-align: left;
  @media ${breakpoints.device.max.xs} {
    text-align: left;
    padding: 0px 15px;
  }
`;

export const ContentRow = styled.tr<ContentRowProp>`
  /* display: flex; */
  /* gap: 2.5rem; */
  padding: 1.2rem 2.4rem;
  border-bottom: 1px solid ${color.backgroundMedium};
  cursor: pointer;
  ${(prop) =>
    prop.role === "Business/Admin"
      ? `background-color:${color.backgroundLightest}`
      : "background-color:#fff"};
  &:hover {
    background-color: ${color.backgroundLightest};
  }
  @media ${breakpoints.device.max.xs} {
    padding: 10px;
  }
`;

export const Content = styled.td`
  /* width: 18%; */
  color: ${color.textDark};
  font-size: 0.9rem;
  /* display: flex; */
  padding: 15px;
  text-align: left;
  @media ${breakpoints.device.max.xs} {
    padding: 0px 15px;
    text-align: left;
  }
`;

export const EditContainer = styled.div`
  display: flex;
  cursor: pointer;
  color: tomato;
`;

export const Divider = styled.div`
  margin-top: 17px;
  padding-top: 18px;
  border-top: 1px solid ${color.borderLight};
`;

export const GhostButton = styled(Button)`
  padding: 1.6rem 1.6rem !important;
  color: white;
  width: 100%;
  background: #828282;
  border-radius: 0.2rem;
`;
