import { IGetAssets } from "Agency/Inventory/inventory-models";
import { InventoryService } from "Agency/Inventory/inventory-service";
import { useEffect, useState } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Switch } from "shared/components";
import Table from "shared/components/Table";
import { numberWithCommas } from "shared/utils/utility";

const Assets = () => {
  const [isloading, setisloading] = useState(false);
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [assets, setassets] = useState<any[]>([]);
  const [currentPage, setcurrentPage] = useState(0);
  const currentBusiness = useSelector(
    (store: RootStateOrAny) => store.business
  );

  const param: any = useParams();
  const agentId = param.id;

  const headData: any = [
    { id: "assetName", label: "Asset Name" },
    { id: "category", label: "Category" },
    { id: "description", label: "Description" },
    { id: "cost", label: `Cost(${"NGN"})` },
    { id: "quantity", label: "Quantity" },
    { id: "assigned", label: `Assigned` },
    { id: "status", label: `Status` },
    { id: "action", label: "" },
  ];

  const fetchAssets = async (startDate = "", endDate = "") => {
    setisloading(true);
    const businessId = currentBusiness?.id;
    const payload: IGetAssets = {
      item: 2,
      page: currentPage + 1,
      start_date: startDate,
      end_date: endDate,
      business_id: businessId,
    };

    // console.log("agent id", agentId);
    const response: any = await InventoryService.GetAgentAssets(agentId);
    // console.log("agent assets data", response);
    if (response.status) {
      
      // const responseData = response.payload;
      const transformedDate = response.payload.map((obj: any) => {
        return {
          id: obj.id ?? "--",
          assetName: obj.name ?? "---",
          category: obj?.category ?? "---",
          description: obj?.description ?? "---",
          quantity: obj?.quantity,
          assigned: obj?.qtyAssigned,
          cost: numberWithCommas(obj?.cost),
          status: (
              <Switch
                id={`${obj.uuid}_status`}
                status={obj?.status=='ACTIVE'?true:false}
                label={obj?.status}
                toggleMethod={() => ""}
              />
          ),
        };
      });
      setassets(transformedDate);
      setisloading(false);
    } else {
      setisloading(false);
    }
  };

  useEffect(() => {
    const currentDate = new Date();
    const day = currentDate
      .getDate()
      .toString()
      .padStart(2, "0");
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0"); //
    const year = currentDate.getFullYear();
    const currentformattedDate = `${day}/${month}/${year}`;
    fetchAssets("04/4/2021", currentformattedDate);
  }, []);

  return (
    <div>
      <div style={{ position: "inherit" }}>
        <Table
          headData={headData}
          bodyData={assets}
          hoverLink
          serialNumber
          clickFunction={() => ""}
          totalPages={0}
          currentpage={0}
          emptyStateMessage="No Asset"
        />
      </div>
    </div>
  );
};

export default Assets;
