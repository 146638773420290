// import { storeCurrentBusiness } from "shared/utils/businessesData";
import { ActionTypes } from "../constants/actionType";

export const setService = (service: any = {}) => {
  return {
    type: ActionTypes.SET_SERVICE,
    payload: service,
  };
};

// export const setCurrentBusiness = (business?: any) => {
//   if (business) {
//     storeCurrentBusiness(business);
//     return {
//       type: ActionTypes.SET_CURRENT_BUSINESS,
//       payload: business,
//     };
//   }
// };
