import { get } from "lodash";
import pubsub from "sweet-pubsub";

const show = (toast: any) => pubsub.emit("toast", toast);

const success = (title: string) => show({ title });

const error = (err: string) => {
  show({
    type: "danger",
    // title: "Error",
    message: get(err, "message", err),
    // duration: 0,
  });
};

const warning = (err: string) => {
  show({
    type: "warning",
    // title: "Information",
    message: get(err, "message", err),
    // duration: 0,
  });
};

export default { show, error, success, warning };
