import { useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";

import { Form, Icon, Modal } from "shared/components";
import api from "shared/utils/api";
import {
  internalValidations,
  onChangeTextHandler,
  validations,
} from "shared/utils/formValidation";
import toast from "shared/utils/toast";
import { setService } from "store/actions/serviceAction";
import {
  ActionButton,
  ButtonContainer,
  Container,
  FormBody,
  FormContainer,
  FormElement,
  FormInnerContainer,
  SubTitle,
  TitleCase,
  WarningAlert,
} from "./Style";
import consoleLog from "shared/utils/consoleLog";
import { useHistory } from "react-router-dom";
import OtpModal from "shared/components/OtpModal";
import { setUserOtp } from "store/actions/userAction";
import { setBusinessLists } from "store/actions/businessAction";

const Transfer = ({ fetchData, setVisible, setReload, edit }: any) => {
  const [isReady, setIsReady] = useState(false);
  const [formstate, setFormstate] = useState({} as any);
  const [selectedEmail, setSelectedEmail] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const history = useHistory();
  const [teams, setTeams] = useState<any>(null);
  const dispatch = useDispatch();
  const [otpModal, setOtpModal] = useState(false);

  const currentBusiness = useSelector(
    (store: RootStateOrAny) => store.business
  );

  const currentUser = useSelector((store: RootStateOrAny) => store.currentUser);

  const onGetBusinessUsers = async()=>{
    setIsReady(true)
    try{
      const {status, message, payload}:any = await api.get(`/business/${currentBusiness?.id}/users`);
    
      if(status){
        let newPayload=payload.filter((item: any)=>item.User!=undefined && item.User.uuid != currentUser?.id)
        if(newPayload){
          const newTeams=newPayload?.map((team: any)=>{
            return {
              label: `${team.User.lastName} ${team.User.firstName}`, 
              value: team.User.uuid
            }
          })
          // console.log("Payload: ", newTeams)
          await setTeams(newTeams ? newTeams : [])
        }
        
      }
    }catch(error: any){}finally{
      setIsReady(false)
    }
    
  }


  const onSubmit = async () => {
    
    
    if (selectedEmail) {
      setIsUploading(true);
      try {
        // const {
        //   team
        // } = formstate;
        
        const response = await api.put(`/business/${currentBusiness?.id}/transfer`, {
          user_id: currentUser && currentUser.id,
          new_user_id: selectedEmail
        },);
        // console.log(response)
        if (response.status === true) {
          toast.success(response.message);
          await dispatch(setBusinessLists());
          
          window.location.reload();
        } else {
          toast.error(response.message);
        }
      } catch (error: any) {
        // console.log("Error: ", error)
        toast.error(error.message);
      } finally {
        setIsUploading(false);
      }
    }
  };


  useEffect(() => {
    onGetBusinessUsers();
  }, []);

  
 
  return (
    <Container>
      <FormContainer>
        <TitleCase>Transfer business: {currentBusiness?.name}</TitleCase>
        <SubTitle>
          Once this transfer is completed, you wont have access to this business or any apps in it.
        </SubTitle>

        <p>
          <i>Required fields are marked with an asterisk (*).</i>
        </p>
        
        
        <WarningAlert>
          <Icon type={'alert'} size={25} /> To understand admin access, teams, roles assignments, and redirects after a business is transferred, see Transferring a business on Blogs.
        </WarningAlert>
        <FormBody>
          {/* @ts-ignore */}
          <Form
            initialValues={{user: selectedEmail}}
            enableReinitialize
            onSubmit={() => {}}
          >
            <>
              <FormElement>
                <Form.Field.Select
                  name="user"
                  label="Team members"
                  options={teams}
                  value={selectedEmail}
                  disabled={isReady}
                  onChange={(val: any) =>setSelectedEmail(val)
                  }
                />  
              </FormElement>

              <ButtonContainer>
                <ActionButton
                  onClick={()=>{
                    if (selectedEmail){
                      // setOtpModal(true);
                      onSubmit()
                    }
                  }}
                  isWorking={isUploading}
                  disabled={!selectedEmail}
                >
                  Transfer now
                </ActionButton>
              </ButtonContainer>
            </>
          </Form>
        </FormBody>
      </FormContainer>
      <Modal
        isOpen={otpModal}
        width={800}
        withCloseIcon={true}
        onClose={() => setOtpModal(false)}
        // renderContent={() => <AddTeam />}
        renderContent={() => (
          <OtpModal
            callBackFn={onSubmit}
            startHere
            closeModal={setOtpModal}
            emailType="verification"
            type="auth-user"
          />
        )}
      />
    </Container>
  );
};

export default Transfer;
