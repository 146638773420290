import styled, { css } from "styled-components";

import Icon from "shared/components/Icon";
import breakpoints from "shared/utils/breakpoints";
import { color, mixin, zIndexValues } from "shared/utils/styles";

type PropT = {
  variant: string;
  containerWidth?: boolean;
  maxHeight?: number;
  radius?: number;
  minWidth?: number | string;
};

type PropT2 = {
  [x: string]: any;
};

type PropT3 = {
  width: number;
};

export const ScrollOverlay = styled.div`
  z-index: ${zIndexValues.modal};
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  ${mixin.scrollableY}
`;

export const ClickableOverlay = styled.div<PropT>`
  min-height: 100%;
  background: rgba(9, 30, 66, 0.54);
  ${(props) => clickOverlayStyles[props.variant]};
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;

const clickOverlayStyles: PropT2 = {
  center: css`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px;
    @media ${breakpoints.device.max.xs} {
      padding: 20px;
    }
  `,
  aside: "",
};

export const StyledModal = styled.div<PropT>`
  display: inline-block;
  position: relative;
  width: ${(props) => (props.containerWidth ? "100%" : false)};
  ${(props) => props.maxHeight && css` max-height ${props.maxHeight}`}
  background: #fff;
  ${(props) =>
    !props.radius &&
    css`
      border-radius: 20px !important;
    `};
  ${(props) =>
    props.radius &&
    css`
      border-radius: 0px !important;
    `};
  min-width:${(props) => props?.minWidth};
  ${(props) => modalStyles[props.variant]}
	@media ${breakpoints.device.max.xs} {
   width: 100%;
  }
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;

const modalStyles: PropT2 = {
  center: css<PropT3>`
	max-width: ${(props) => props.width}px;
	vertical-align: middle;
	border-radius: 20px;
	${mixin.boxShadowMedium}
	@media ${breakpoints.device.max.xs} {
   border-radius: 10px !important;
  }
  `,
  aside: css<PropT3>`
    min-height: 100vh;
    max-width: ${(props) => props.width}px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
  `,
};

export const CloseIcon = styled(Icon)<PropT>`
  position: absolute;
  font-size: 25px;
  background-color: #fff;
  right: -50px !important;
  top: -0px !important;
  color: ${color.textMedium};
  transition: all 0.1s;
  ${mixin.clickable}
  ${(props) => closeIconStyles[props.variant]}
	@media ${breakpoints.device.max.xs} {
   right: 5px !important;
   top: 5px !important;
	 background-color: #760000;
	 color: #ffffff;
  }
`;

const closeIconStyles: PropT2 = {
  center: css<PropT3>`
    top: 10px;
    right: 12px;
    padding: 3px 5px 0px 5px;
    border-radius: 50%;
    &:hover {
      background: ${color.backgroundLight};
    }
  `,
  aside: css<PropT3>`
    top: 10px;
    right: -30px;
    width: 50px;
    height: 50px;
    padding-top: 10px;
    border-radius: 3px;
    text-align: center;
    background: #fff;
    border: 1px solid ${color.borderLightest};
    ${mixin.boxShadowMedium};
    &:hover {
      color: ${color.primary};
    }
  `,
};

export const FooterStyle =styled.div`
  width: 100%;
  height: 40px;
  padding: 20px 10px;
  background-color: #F1F2F5;
  border-top: 1px #cccccc;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
`
