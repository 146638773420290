import React from "react";
import Failed from "App/assets/images/Auth/failed.svg";
import { ActionButton, ReportBody, SubTitle, Title } from "./Styles";
import { useHistory, useParams } from "react-router-dom";
import LoginFace2 from "shared/components/LoginFace/index2";
import { authPath } from "apiServices/paths";

const OtpFailed = () => {
  const history = useHistory();
  const params: any = useParams();
  let auth = params?.auth ?? "";

  const handleNext = () => {
    history.push(`${authPath.OTP}/${auth}`);
  };

  const Component: any = () => (
    <ReportBody>
      <img src={Failed} alt="Failed" />
      <Title>Authentication Failed</Title>
      <SubTitle>Your OTP Verification is unsuccesful</SubTitle>
      <ActionButton onClick={handleNext}>Retry</ActionButton>
    </ReportBody>
  );
  return (
    <>
      {params?.auth ? (
        <LoginFace2 chgValue={Math.random()}>
          <Component />
        </LoginFace2>
      ) : (
        <Component />
      )}
    </>
  );
};

export default OtpFailed;
