import React from "react";

import Button from "shared/components/Button";
import Tooltip from "shared/components/Tooltip";

import feedbackImage from "./assets/feedback.png";
import {
  FeedbackDropdown,
  FeedbackImageCont,
  FeedbackImage,
  FeedbackParagraph,
} from "./Styles";

const AboutTooltip = (tooltipProps: any) => (
  <Tooltip
    width={300}
    {...tooltipProps}
    renderContent={() => (
      <FeedbackDropdown>
        <FeedbackImageCont>
          <FeedbackImage src={feedbackImage} alt="Give feedback" />
        </FeedbackImageCont>

        <FeedbackParagraph></FeedbackParagraph>

        <FeedbackParagraph>
          {"Read about our usecase and reach out via our live chat "}
        </FeedbackParagraph>

        {/* <a href="https://getivor.com/" target="_blank" rel="noreferrer noopener">
          <Button>Live chat</Button>
        </a> */}

        <a
          href="https://fountainpay.ng/about"
          target="_blank"
          rel="noreferrer noopener"
        >
          <Button style={{ marginLeft: 10 }} icon="help">
            About
          </Button>
        </a>

        <a
          href="https://docs.fountainpay.ng/"
          target="_blank"
          rel="noreferrer noopener"
        >
          <Button style={{ marginLeft: 10 }} icon="github">
            Documentation
          </Button>
        </a>
      </FeedbackDropdown>
    )}
  />
);

export default AboutTooltip;
