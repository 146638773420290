import api from "shared/utils/api";
import consoleLog from "shared/utils/consoleLog";
import { ICreateAsset, ICreateCategory, IGetAssets } from "./inventory-models";

const CreateCategory = async (payload: ICreateCategory) => {
  try {
    const response = await api.post(`/agency-banking/assets/category`, payload);
    return response;
  } catch (error) {
    // // console.log(error);
  }
};

const GetCategories = async (businessId: string) => {
  try {
    const response = await api.get(
      `/agency-banking/assets/category/${businessId}`
    );
    return response;
  } catch (error) {
    // // console.log(error);
  }
};

const UpdateCategory = async (payload: any) => {
  const { id, ...rest } = payload;
  try {
    const response = await api.put(
      `/agency-banking/assets/category/${id}`,
      rest
    );
    return response;
  } catch (error) {
    // // console.log(error);
  }
};

const CreateAsset = async (payload: ICreateAsset) => {
  try {
    const response = await api.post(`/agency-banking/assets/business`, payload);
    return response;
  } catch (error) {
    // // console.log("create assets error ==>", error);
  }
};

const GetAssets = async (payload: IGetAssets) => {
  try {
    const response = await api.post(
      `/agency-banking/assets/business/query`,
      payload
    );
    return response;
  } catch (error) {
    // // console.log(error);
  }
};

const GetAsset = async (assetId: string) => {
  try {
    const response = await api.get(
      `/agency-banking/assets/business/${assetId}`
    );
    return response;
  } catch (error) {
    // // console.log(error);
  }
};

const GetAgentAssets = async (agentId: string) => {
  try {
    const response = await api.get(`/agency-banking/assets/agents/${agentId}`);
    return response;
  } catch (error) {
    // // console.log(error);
  }
};

const GetAgentsAssigned = async (assetId: string) => {
  try {
    const response = await api.get(`/agency-banking/asset/agents/${assetId}`);
    return response;
  } catch (error) {
    // // console.log(error);
  }
};

const GetAgentsList = async (payload: any) => {
  try {
    const response = await api.post("/agency-banking/agents", payload);
    return response;
  } catch (error) {
    // // console.log(error);
  }
};

const AssignAsset = async (payload: any) => {
  try {
    const response = await api.post("/agency-banking/assets/agent", payload);
    return response;
  } catch (error) {
    // // console.log(error);
  }
};

export const InventoryService = {
  CreateCategory,
  GetCategories,
  UpdateCategory,
  CreateAsset,
  GetAssets,
  GetAsset,
  GetAgentsAssigned,
  GetAgentsList,
  AssignAsset,
  GetAgentAssets,
};
