import React from "react";
import { TableLinkTdwContainer } from "../TableRow/Style";

const TableHead = ({ item, onClick, serialNumber }: any) => {
  return (
    <TableLinkTdwContainer onClick={onClick} title={item}>
      {item}
    </TableLinkTdwContainer>
  );
};

export default TableHead;
