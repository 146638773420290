import React, {
  useState,
  useEffect,
  useLayoutEffect,
  memo,
  useCallback,
} from "react";
import { NavLink, useRouteMatch } from "react-router-dom";
import { Icon, ProjectAvatar } from "shared/components";
import { RootStateOrAny, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { MENUS, APPMENUS } from "shared/utils/sitemap";
import {
  BusinessInfo,
  BusinessName,
  BusinessTexts,
  Label,
  LinkItem,
  LinkText,
  NotImplemented,
  ServiceInfo,
  SidebarContainer,
  TitleLabel,
} from "./Styles";

import shouldPerform, { getRoleName } from "shared/utils/assignRoles";
import { isItHere } from "shared/utils/security";

type PropT = {
  [x: string]: any;
};

const renderLinkItem = (
  id: string,
  text: string,
  iconType: string,
  path?: string,
  match?: any
) => {
  const isImplemented = !!path;
  const external = isItHere(["https://", "http://"], path);

  const linkItemProps: PropT =
    isImplemented && !external
      ? // ? { as: NavLink, exact: true, to: match ? `${match.path}${path}` : path }
        { as: NavLink, exact: true, to: match ? `${match.path}${path}` : path }
      : { as: "div" };
  return (
    <>
      {external ? (
        <LinkItem
          style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
          id={id}
          onClick={() => {
            window.open(path, "_blank");
          }}
        >
          <Icon type={iconType} />
          <LinkText>{text}</LinkText>
        </LinkItem>
      ) : (
        <LinkItem
          {...linkItemProps}
          style={{ display: "flex", alignItems: "center" }}
          id={id}
        >
          <Icon type={iconType} />
          <LinkText>{text}</LinkText>
          {!isImplemented && <NotImplemented>Not implemented</NotImplemented>}
        </LinkItem>
      )}
    </>
  );
};

const Sidebar = ({ onClick }: any) => {
  const [sideMenus, setSideMenus] = useState<any>([]);
  const [kycType, setkycType] = useState("");
  const [inService, setInService] = useState<boolean>(false);

  const match = useRouteMatch();
  const [user, setUser] = useState<any>({});
  const service = useSelector((store: RootStateOrAny) => store.service);
  const history = useHistory();

  const currentBusiness = useSelector(
    (store: RootStateOrAny) => store.business
  );

  const openHome = useCallback(() => {
    history.push("/");
  }, []);

  //Grouping menus into respective categories
  const gatewayList = MENUS.filter((tab) => tab.category === "GATEWAY");
  const agencyList = MENUS.filter((tab) => tab.category === "AGENCY");

  const settings = useSelector((store: RootStateOrAny) => store.settings);
  const currentUser = useSelector((store: RootStateOrAny) => store.currentUser);

  useEffect(() => {
    setkycType(
      currentBusiness?.BusinessKyc instanceof Array &&
        currentBusiness?.BusinessKyc[0] &&
        currentBusiness?.BusinessKyc[0].type
    );
    if (
      currentBusiness?.BusinessKyc &&
      currentBusiness?.BusinessKyc[0] &&
      currentBusiness?.BusinessKyc[0].type
    ) {
      if (currentBusiness?.BusinessKyc[0].type === "GATEWAY") {
        setSideMenus(gatewayList);
      } else {
        setSideMenus(agencyList);
      }
    } else setSideMenus(MENUS);
  }, [currentBusiness]);

  useEffect(() => {
    const url = history.location.pathname;
    if (url.includes(`/my-apps/${service?.id}/`)) {
      setInService(true);
    } else {
      setInService(false);
    }
  }, [history.location.pathname]);

  return (
    <SidebarContainer onClick={onClick}>
      {!inService && (
        <>
          <BusinessInfo>
            <ProjectAvatar logo={currentBusiness?.logo} />
            <BusinessTexts onClick={openHome}>
              <BusinessName>{currentBusiness?.businessName || ""}</BusinessName>
            </BusinessTexts>
          </BusinessInfo>

          <div style={{ marginTop: "1.4rem" }}>
            {MENUS?.map((q: any, i: any) => {
              if (
                q?.permitted &&
                !shouldPerform(
                  getRoleName(currentUser, currentBusiness?.id),
                  q?.permitted
                )
              )
                return null;
              return (
                <div key={`K${i}`}>
                  {!q.type &&
                    renderLinkItem(q.id, q.title, q.icon || "", q.url)}
                  {q.type && (
                    <TitleLabel margin={q.margin}>{q.title}</TitleLabel>
                  )}
                </div>
              );
            })}
          </div>
        </>
      )}
      {inService && (
        <>
          <ServiceInfo>
            <Icon
              type="arrow-left"
              size={20}
              onClick={() => {
                setInService(false);
              }}
              style={{ cursor: "pointer" }}
            />
            <BusinessTexts onClick={openHome}>
              <BusinessName>{service?.name || ""}</BusinessName>
            </BusinessTexts>
          </ServiceInfo>
          <div style={{ marginTop: "1.4rem" }}>
            {APPMENUS?.map((q: any, i: any) => {
              if (
                q?.permitted &&
                !shouldPerform(
                  getRoleName(currentUser, currentBusiness?.id),
                  q?.permitted
                )
              )
                return null;
              return (
                <div key={`K${i}`}>
                  {!q.type &&
                    renderLinkItem(
                      q.id,
                      q.title,
                      q.icon || "",
                      `/my-apps/${service?.id}${q.url}`
                    )}
                  {q.type && (
                    <TitleLabel margin={q.margin}>{q.title}</TitleLabel>
                  )}
                </div>
              );
            })}
          </div>
        </>
      )}

      <>
        <TitleLabel>
          <hr style={{ color: "#828282", marginTop: "40px", opacity: "0.2" }} />
        </TitleLabel>
      </>
      {renderLinkItem("invitation", "Invitations", "link", "/invitations", "")}
      {shouldPerform(
        getRoleName(currentUser, currentBusiness?.id),
        "CSettings"
      ) && renderLinkItem("settings", "Settings", "settings", "/settings", "")}
      {renderLinkItem(
        "systemStatus",
        "System Status",
        "reports",
        "/system-status",
        ""
      )}
      {renderLinkItem(
        "documentation",
        "Documentation",
        "issues",
        "https://docs.fountainpay.ng",
        match
      )}
      {/* {renderLinkItem('release', "Releases", "shipping", "", match)}
      {renderLinkItem('issues', "Issues and filters", "issues", "", match)}
      {renderLinkItem("Reports", "reports", "", match)} */}
    </SidebarContainer>
  );
};

export default memo(Sidebar);
