import styled from "styled-components";

export const Cont = styled.div``;

export const Para = styled.p`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #292929;
    margin-top: 20px;
`;

export const Input = styled.input`
    width: 100%;
    height: 54px;
    background: #FFFFFF;
    border: 1px solid #D7D7D7;
    border-radius: 5px;
    padding-left: 10px;
    margin: 20px 0px;
`;

export const Button = styled.button`
    width: 100%;
    height: 54px;
    border-radius: 5px;
    margin-top: 10px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
`;

export const Message = styled.textarea`
    width: 100%;
    height: 100px;
    margin-top: 10px;
    padding: 10px;
`;