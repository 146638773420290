import { ActionTypes } from "../constants/actionType";
import consoleLog from "shared/utils/consoleLog";

const initialState = {
  live: false,
};

export const liveReducer = (state: any = initialState.live, action: any) => {
  switch (action.type) {
    case ActionTypes.SET_LIVE:
      // consoleLog("PAYLOAD:", action.payload);
      return action.payload;
    default:
      return state;
  }
};
