import breakpoints from "shared/utils/breakpoints";
import styled from "styled-components";
import { color } from "../../shared/utils/styles";

export const SectionContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 0.4rem;
  margin-bottom: 1.8rem;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  @media ${breakpoints.device.max.xs} {
    flex-direction: column;
    padding: 25px 15px;
  }
`;

export const SectionTitle = styled.div`
  margin-bottom: 0.4rem;
  font-weight: 800;
`;

export const OptionsContainer = styled.div`
  display: flex;
  padding: 0.4rem;
  gap: 1rem;
  font-size: 0.98rem;
  @media ${breakpoints.device.max.xs} {
    flex-direction: column;
  }
`;

export const PreferenceContainer = styled.div`
  width: 85%;
`;

export const SaveButton = styled.button`
  padding: 1rem 4rem;
  border-radius: 0.4rem;
  color: #fff;
  background: ${color.fountain};
  cursor: pointer;
  @media ${breakpoints.device.max.xs} {
    width: 100%;
    margin-top: 15px;
  }
`;
