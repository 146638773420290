import React, { useState, useEffect } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { GradientLoader, Modal } from "shared/components";
import api from "shared/utils/api";
import {
  getStoreLocalStorage,
  storeToLocalStorage,
} from "shared/utils/authToken";
import { getStatusColor } from "shared/utils/statusColor";
import ResetAgent from "../ResetAgent";
import DashboardSubHeader from "shared/components/DashboardSubHeader";
import toast from "shared/utils/toast";
import Table from "shared/components/Table";
import consoleLog from "shared/utils/consoleLog";
const AgentLists = () => {
  const [modal, setModal] = useState(false);
  const [resetModal, setResetModal] = useState(false);
  const [resetAgentId, setResetAgentId] = useState("");
  const [searchData, setSearchData] = useState("");
  const [data, setData] = useState<any>([]);
  const [isLoading, setisLoading] = useState(false);
  const business = useSelector((store: RootStateOrAny) => store.business);
  const [addedAgent, setAddedAgent] = useState(false);
  const [params, setParams] = useState<any>({});
  const [fetchedData, setFetchedData] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredFormState, setFilteredFormState] = useState<any>({});
  const [start, setStart] = useState<string | undefined>("");
  const [end, setEnd] = useState<string | undefined>("");

  const currentUser = useSelector((store: RootStateOrAny) => store.currentUser);

  const history = useHistory();

  const columnHeaders = [
    { id: "tradeName", label: "Business Name"},
    { id: "name", label: "Name" },
    { id: "phone", label: "Phone Number" },
    { id: "email", label: "Email" },
    { id: "accountNo", label: "Account Number" },
    { id: "bankName", label: "Bank Name" },
    { id: "updatedAt", label: "Date Created" },
    { id: "status", label: "Status" },
  ];

  const viewFunction = (obj: any) => {
    history.push("/agency/agents/detail/" + obj?.id);
  };

  const goToPage = async (val: number) => {
    setCurrentPage(currentPage + 1);
    let obj: any = { ...filteredFormState };

    obj.page = val;
    await getSubAgents(obj);
  };

  const filterAgentList = async (obj: any) => {
    consoleLog("Search object: ", obj);
    obj = obj instanceof Object ? obj : {};
    setFilteredFormState(obj);
    await getSubAgents({ ...obj });
  };

  const getSubAgents = async (otherObj?: any) => {
    setisLoading(true);
    //consoleLog('biz id ', otherObj)
    try {
      let reqObj = {
        business_id: business.id,
        ...otherObj,
      };
      if (reqObj.start) {
        reqObj.start_date = reqObj.start;
        delete reqObj.start;
      }
      if (reqObj.end) {
        reqObj.end_date = reqObj.end;
        delete reqObj.end;
      }
      let currentPage = getStoreLocalStorage("currentPage");
      if (currentPage && !reqObj?.page) {
        reqObj.page = currentPage;
      } else {
        reqObj.page = 1;
      }
      storeToLocalStorage("currentPage", reqObj?.page);

      if (reqObj.start_date && reqObj.end_date) {
        // console.log("Me Objects: ", currentUser);
        const res = await api.post("/agency-banking/agents", reqObj);
        // console.log("Response: ", res);
        if (res.status) {
          if(res.payload.data){
            const index=res.payload.data.findIndex((x: any)=>x?.userReference && x?.userReference ==currentUser.id);
            // // console.log("Me: ", index)
            if(index>-1){
              res.payload.data.splice(index, 1);
            }
          }
          setFetchedData(res.payload);
          setData(res.payload);
        }
      }
    } catch (error) {
      // @ts-ignore
      toast.error(error?.message);
    } finally {
      setisLoading(false);
    }
  };

  let subAgentList: any =
    fetchedData?.data instanceof Array
      ? fetchedData?.data.map((el: any) => {
        let obj: any = {};
          obj.id = el?.id;
          obj.tradeName = el?.tradeName;
          obj.email = el?.email;
          obj.name = el?.firstName + " " + el?.lastName;
          obj.phone = el?.phone;
          obj.accountNo = el?.accountNumber;
          obj.bankName = el?.bankName;
          obj.updatedAt =
            el?.updatedAt &&
            new Date(el?.updatedAt).toLocaleString("en-GB", {
              year: "numeric",
              month: "numeric",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            });

          obj.status = !el.is_permitted ? (
            <div style={{ color: getStatusColor("terminated") }}>Off</div>
          ) : (
            <div style={{ color: getStatusColor("successful") }}>On</div>
          );

          return { ...el, ...obj };
        
      }) 
      : [];

  const SearchForData = (e: any) => {
    let searchedArr: any = [];
    data.forEach((subagent: any) => {
      let fullName = `${subagent.firstName} ${subagent.lastName}`;
      if (fullName.toLowerCase().indexOf(e.toLowerCase()) !== -1) {
        searchedArr.push(subagent);
      }
    });
    setFetchedData(searchedArr);
  };

  const subagentFilter = [
    {
      id: "status",
      type: "flag",
      title: "Status",
      list: [
        { title: "Active", filterParam: true },
        { title: "Inactive", filterParam: false },
      ],
    },
  ];

  const filterSubagents = () => {
    let filteredArr: any = [];
    data.forEach((agent: any) => {
      const permission = agent.isPermitted;
      if (permission === params.Status) {
        filteredArr.push(agent);
      }
    });
    setFetchedData(filteredArr);
  };

  useEffect(() => {
    const date = new Date();
    let currentDay = String(date.getDate()).padStart(2, "0");
    let currentMonth = String(date.getMonth() + 1).padStart(2, "0");
    let currentYear = date.getFullYear();

    date.setUTCDate(date.getUTCDate() - 50);

    const last30Days = `${date.getUTCFullYear()}-${(date.getUTCMonth() + 1)
      .toString()
      .padStart(2, "0")}-${date
      .getUTCDate()
      .toString()
      .padStart(2, "0")}`;
    let endDate = `${currentYear}-${currentMonth}-${currentDay}`;
    setStart(last30Days);
    setEnd(endDate);

    filterAgentList({
      start_date: last30Days,
      end_date: endDate,
      search: "",
    });
  }, []);

  return (
    <>
      <Modal
        isOpen={resetModal}
        width={400}
        withCloseIcon={true}
        onClose={() => setResetModal(false)}
        renderContent={() => (
          <ResetAgent agentId={resetAgentId} setResetModal={setResetModal} />
        )}
      />

      <DashboardSubHeader
        count={subAgentList?.length ?? 0}
        title={`Agent${subAgentList?.length > 1 ? "s" : ""}`}
        btnTitle="Create an agent"
        callBackFn={() => history.push("/agency/agents/add-new")} //setModal(true)
        setSearchData={SearchForData}
        withSearch={fetchedData?.totalPages > 0}
        withFilter={true}
        widthActionBtn={true}
        arrToFilter={subagentFilter}
        //applyFilter={filterSubagents}
        clickedParam={"Agent Name"}
        getParams={setParams}
        setStartDate={() => {}}
        setEndDate={() => {}}
        filterType="agentsList"
        onFilterCallback={filterAgentList}
      />

      {isLoading ? (
        <GradientLoader />
      ) : (
        <>
          <Table
            headData={columnHeaders}
            bodyData={subAgentList ?? []}
            hoverLink
            serialNumber
            clickFunction={viewFunction}
            totalPages={fetchedData?.totalPages ?? 0}
            currentpage={fetchedData?.currentPage ?? 0}
            goToPage={goToPage}
            emptyStateMessage="Yet to onboard any agent"
          />
        </>
      )}
    </>
  );
};

export default AgentLists;
