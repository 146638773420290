import { useEffect, useState } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { GradientLoader, PageError, PageOverlay } from "shared/components";
import { StateContainer } from "./Styles";

import Layout from "Layout";
import DashboardSubHeader from "shared/components/DashboardSubHeader";
import useApi from "shared/hooks/api";
import EmptyState from "../shared/components/FreeComp/EmptyState";
// import Modal from "../shared/components/FreeComp/Modal";
import { Modal, Switch } from "shared/components";
import api from "shared/utils/api";
import toast from "shared/utils/toast";
import AddSubAccount from "./AddSubAccount";

import Table from "../shared/components/Table";
import consoleLog from "shared/utils/consoleLog";
import shouldPerform, { getRoleName } from "shared/utils/assignRoles";
import { useHistory } from "react-router-dom";
import { Icon } from "shared/components";

type accountPropT = {
  id: number;
  sn: number;
  subaccountname: string;
  servicename: string;
  bank_account: any;
  Bank_name: string;
  account_name: string;
}[];

function Index() {
  const [isLoadingStatus, setIsLoadingStatus] = useState<boolean>(false);
  const [modal, setModal] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [isnewRow, setisnewRow] = useState<boolean>(false);
  const [searchData, setSearchData] = useState("");
  const [subAccounts, setSubAccounts] = useState<accountPropT>([]);
  const [rowData, setRowData] = useState<accountPropT>([]);
  const [columnOrder, setColumnHeader] = useState("ASC");
  const [collectFilterPara, setCollectFilterPara] = useState(
    "Account Name"
  );
  const [filteredFormState, setFilteredFormState] = useState<any>({});
  const [filteredData, setFilteredData] = useState<any>([]);
  const currentUser = useSelector((store: RootStateOrAny) => store.currentUser);
  const history = useHistory();

  let accountList: accountPropT = [];

  const currentbusiness = useSelector(
    (store: RootStateOrAny) => store.business
  );

  const addParams = (obj: any = "") => {
    if (!(obj instanceof Object)) return "";

    let strArr: any = [];
    for (let el in obj) {
      let val = `${el}=${obj[el]}`.trim();
      strArr.push(val);
    }
    return `?${strArr.join("&")}`;
  };

  const [{ data, error, setLocalData }, fetchData] = useApi.get(
    `/client/business/${currentbusiness?.id}/sub-accounts${addParams()}`
  );

  // getData();

  const openModal = () => {
    setModal(!modal);
  };

  const columnHeaders = [
    { id: "sn", label: "#" },
    { id: "subaccountid", label: "Account ID" },
    { id: "subaccountname", label: "Sub Account Name" },
    // { id: "servicename", label: "Service Name" },
    { id: "bank_account", label: "Account Number" },
    { id: "account_name", label: "Account Name" },
    { id: "Bank_name", label: "Bank name" },
    // { id: "deleteIcon", label: "" },
    { id: "action", label: "" },
  ];

  const changeStatus = async (
    status: boolean,
    subAccId: number,
    bizId: number
  ) => {
    setIsLoadingStatus(true);
    try {
      const { message }: any = await api.put(
        `/client/business/${bizId}/sub-accounts/${subAccId}`,
        {
          isActive: !status,
        }
      );
      toast.success(message);
      await fetchData();
      // if (message === "Sub account updated successfully") {
      //   //window.location.reload();

      // }
    } catch (error) {
      // @ts-ignore
      toast.error(error);
      // console.log(error);
    } finally {
      setIsLoadingStatus(false);
    }
  };

  const deleteSubaccount: any = async (bizId: number, subAccId: number) => {
    setIsLoadingStatus(true);
    try {
      const { message }: any = await api.delete(
        `/client/business/${bizId}/sub-accounts/${subAccId}`
      );
      toast.success(message);
      window.location.reload();
    } catch (error) {
      // @ts-ignore
      toast.error(error);
    } finally {
      setIsLoadingStatus(false);
    }
  };

  const SwitchComp = (isActive: boolean, subAccId: number, bizId: number) => {
    return (
      <Switch
        id={subAccId}
        status={isActive}
        toggleMethod={() => changeStatus(isActive, subAccId, bizId)}
        label={""}
        color={"blue"}
      />
    );
  };

  const subAccountFilter = [
    {
      id: "createdAt",
      type: "date",
      title: "Date",
      list: [
        { title: "Start Date", name: "startDate", type: "start" },
        { title: "End Date", name: "endDate", type: "end" },
      ],
    },

    {
      id: "search",
      type: "text",
      title: "Name of subaccount",
      name: "search",
    },
  ];

  const filterSubAccounts = async (obj: any) => {
    consoleLog(obj, "Object");
    obj = obj instanceof Object ? obj : {};
    setFilteredFormState(obj);
    await getData(obj);
  };

  const toggleMethod = async (account: any) => {
    try {
      const response = await api.put(`/sub-acount/update/${account?.id}`, {
        isActive: !account?.isActive,
      });
      if (response.status === true) {
      } else {
        toast.error(response.message);
      }
    } catch (err) {
    } finally {
    }
  };

  const fixData = (data: any) => {
    if (data?.payload?.length) {
      accountList = data?.payload.map((account: any, index: any) => {
        const acc = {
          id: account.id,
          sn: index + 1,
          subaccountid: account.subAccountId,
          subaccountname: account.name,
          servicename: account.servicename
            ? account.servicename
            : "No service name",
          bank_account: account.accountNumber,
          Bank_name: account?.bank?.name,
          account_name: account.bankAccountName,
          action: SwitchComp(account.isActive, account.id, account.businessId),
          deleteIcon: (
            <Icon
              type={"trash"}
              style={{ color: "red", cursor: "pointer" }}
              onClick={() => deleteSubaccount(account.businessId, account.id)}
            />
          ),
        };
        return acc;
      });

      //ts-ignore
      setSubAccounts([...accountList]);
      //ts-ignore
      setRowData([...accountList]);
    }
  };

  const getData = async (obj: any) => {
    try {
      const res: any = await api.get(
        `/client/business/${currentbusiness?.id}/sub-accounts${addParams(obj)}`
      );
      consoleLog(res, "res");
      fixData(res);
    } catch (error) {
      consoleLog("your err ", error);
    }
  };

  let para = collectFilterPara || "Sub Account Name";

  const updateInput = () => {
    if (searchData != "") {
      const name: any = columnHeaders.find((col) => col.label === para )?.id;
      //alert(name)
      consoleLog(subAccounts);
      const filterSearch = subAccounts.filter(
        //ts-ignore
        (account: any) =>
          account[name].toLowerCase().indexOf(searchData?.toLowerCase()) > -1
      );

      // if (filterSearch.length !== 0) {
      setFilteredData(filterSearch);
      // }
    } else {
      setFilteredData(subAccounts);
    }
  };

  useEffect(() => {
    if (!data) {
      // consoleLog("Data List: ", data);
      setisLoading(true);
    } else {
      setisLoading(false);
    }

    if (!data && !error) {
      setisLoading(false);
    }
    if (error) {
      toast.error(error);
      setisLoading(false);
      consoleLog("Data List Error: ", error);
    }

    fixData(data);
  }, [data, error]);

  useEffect(() => {
    updateInput();
  }, [collectFilterPara, searchData, rowData]);

  if (
    !shouldPerform(
      getRoleName(currentUser, currentbusiness?.id),
      "VPaySplitSub"
    )
  ) {
    history.push("/authenticate");
    return null;
  }

  return (
    <>
      {/* {modal && <Modal openModal={openModal} fetchData={fetchData} />} */}
      <Modal
        overFlow={true}
        isOpen={modal}
        width={800}
        containerWidth={false}
        withCloseIcon={true}
        onClose={() => setModal(false)}
        renderContent={() => (
          <AddSubAccount fetchData={fetchData} openModal={openModal} />
        )}
      />

      {isLoadingStatus && <PageOverlay top={200} right={400} />}
      {isLoading && <GradientLoader />}
      {error && <PageError />}
      {!isLoading && !error && (
        <>
          {subAccounts.length > 0 ? (
            <>
              <DashboardSubHeader
                count={subAccounts.length}
                title={`Subaccount`}
                btnTitle="Create subaccount"
                withSearch={subAccounts.length !== 0}
                widthActionBtn={data?.payload.length > 0}
                filterOptions={columnHeaders.map((col) => col.label)}
                setSearchData={setSearchData}
                callBackFn={openModal}
                //filterFn={updateInput}
                setCollectFilterPara={setCollectFilterPara}
                clickedParam={para}
                //withFilter={subAccounts.length > 0}
                arrToFilter={subAccountFilter}
                resetFilter={filterSubAccounts}
                applyFilter={filterSubAccounts}
                setFilterFormState
              />
              <Table headData={columnHeaders} bodyData={filteredData} />
            </>
          ) : (
            <StateContainer>
              {!error && (
                <EmptyState
                  openModal={openModal}
                  stateTitle={"No Subaccount"}
                  statePara={
                    "Subaccount allows you to split payment into multiple accounts"
                  }
                  stateBtnText={"Create Subaccount"}
                />
              )}
            </StateContainer>
          )}
        </>
      )}
    </>
  );
}

export default Index;
