import { toogleServiceMode } from "Services/serviceClass";
import React, { useState } from "react";
import useServiceDetails from "./useServiceDetails";

export default function useServiceMode(service: any) {
  const { serviceDetails, getServiceDetails } = useServiceDetails();
  const [loadingMode, setloadingMode] = useState(false);
  const [serviceMode, setserviceMode] = useState<any>(
    service.mode === "TEST" ? false : true
  );

  const toogleMode = async () => {
    setloadingMode(true);
    let mode = service.mode === "TEST" ? "LIVE" : "TEST";
    const response = await toogleServiceMode(service?.id, { mode });
    if (response?.status) {
      await getServiceDetails();
      setloadingMode(false);
      setserviceMode(mode === "TEST" ? false : true);
    } else {
      setloadingMode(false);
    }
  };
  return { serviceMode, loadingMode, toogleMode };
}
