import { Button, Form } from "shared/components";
import breakpoints from "shared/utils/breakpoints";
import { color, font } from "shared/utils/styles";
import styled, { css } from "styled-components";

type Propst = {
  variant?: string;
  width?: string;
};
export const FormElement = styled(Form.Element)``;

export const Container = styled.div`
  width: 74%;
  display: flex;
  justify-content: space-between;
`;

export const ImageContainer = styled.div`
  width: 35%;
  display: flex;
  justify-content: center;
  padding: 1rem 2rem;
  background-color: ${color.backgroundLight};
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
`;

export const Image = styled.img`
  object-fit: center;
  margin-right: 10%;
  @media ${breakpoints.device.max.xs} {
    display: none;
  }
`;

export const FormContainer = styled.div`
  width: 65%;
  padding: 2rem;
  @media ${breakpoints.device.max.xs} {
    margin: 15px;
  }
`;

export const SubAgentForm = styled.div`
  margin-top: 2rem;
  width: 100%;
  position: relative;
`;
export const InputLabel = styled.label`
  display: block;
`;

export const FromGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  gap: 2rem;
  margin-bottom: 2rem;
`;

export const InputGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
`;

export const Input = styled.input`
  width: 100%;
  height: 54px;
  padding: 0 0.4rem;
  border: 1px solid ${color.backgroundMedium};
  border-radius: 0.4rem;
`;

// export const ActionButton = styled.button`

// `

export const TitleCase = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: -0.02em;

  /* Primary Black */

  color: #292929;
`;

export const SubTitle = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  width: 20em;
  margin-top: 0.6rem;

  /* Grey Text 1 */

  color: #828282;
  @media ${breakpoints.device.max.xs} {
    width: 100%;
  }
`;

export const FormBody = styled.div`
  margin-top: 20px;
  height: auto;
  overflow: auto;
  padding-right: 20px;
`;

export const ButtonContainer = styled.div<Propst>`
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-top: 16px;
  ${(props) =>
    props.variant == "" &&
    css`
      justify-content: flex-end;
    `}
`;
export const Back = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  /* identical to box height */
  margin-top: 16px;
  cursor: pointer;

  /* Grey Text 1 */

  color: #828282;
`;

export const ActionButton = styled(Button)<Propst>`
  margin: 1em 0;
  color: #fff;
  min-width: 120px;
  height: 45px;
  float: right;
  ${(props) =>
    props.width &&
    css`
      width: ${props.width};
    `};

  ${(props) =>
    props.variant
      ? css`
          background-color: ${props.variant};
        `
      : css`
          background-color: ${color.fountain};
        `}
  ${(props) =>
    props.variant == color.transparent &&
    css`
      color: grey;
    `}
`;

export const UpdateButton = styled(Button)<Propst>`
  color: #fff;
  padding: 2px 10px;
  height: 35px;
  border-radius: 5px;
  ${(props) =>
    props.variant
      ? css`
          background-color: ${props.variant};
        `
      : css`
          background-color: ${color.fountain};
        `}
`;

//KYC stylings
export const UploadContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 15px;
`;

export const UploadLabel = styled.label`
  display: block;
  padding-bottom: 5px;
  color: #5e6c84;
  font-family: "KumbhSansNormal";
  font-weight: normal;
  font-size: 13px;
  text-align: left;
`;

export const UploadBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed grey;
  border-radius: 5px;
  padding: 10px;
  background-color: #ffffff;
`;

export const UploadInput = styled.div`
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-left: 4px solid green;
  padding: 10px;
  width: 100%;
  height: 50px;
  text-align: left;
  margin-bottom: 5px;
  ${font.size(12.5)}
`;

export const UploadedFile = styled.p`
  text-align: left;
  width: 300px;
  height: 20px;
  overflow: hidden;
`;

export const UploadButton = styled.label`
  line-height: 18.75px;
  font-weight: 400;
  ${font.size(16)};
  color: ${color.fountain};
  height: 39px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`;

export const TrashBtn = styled.p`
  background-color: lightGrey;
  padding: 5px 8px;
  border-radius: 7px;
  &:hover {
    cursor: pointer;
  }
`;

export const FieldError = styled.div`
  margin-top: 6px;
  text-align: left;
  line-height: 1;
  color: ${color.danger};
  ${font.medium}
  ${font.size(12.5)}
`;
export const HeadingCont = styled.div`
  display: flex;
  padding: 4px 0px;
  margin-right: 5px;
`;

export const DownldCnt = styled.div`
  display: flex;
  justify-content: space-between;
`;
