import React, { useEffect, useState } from "react";
import Modal from "shared/components/Modal";
import {
  AgentListContainer,
  AgentListRow,
  ButtonContainer,
  CCFormContainer,
  CategoryForm,
  CreateCatContainer,
} from "../Styles";
import InventoryBanner from "../../../App/assets/images/inventorybanner.svg";
import { Input } from "shared/components";
import { RootStateOrAny, useSelector } from "react-redux";
import { InventoryService } from "../inventory-service";
import Spinner from "shared/components/spinner/spinner";
import toast from "shared/utils/toast";
import { CheckBox } from "Services/PaymentLink/Styles";

type PropT = {
  isopen: boolean;
  setisOpen: Function;
  data: any;
  apiCall: Function;
};

export default function AssignAsset({
  isopen,
  setisOpen,
  data,
  apiCall,
}: PropT) {
  const [isloading, setisloading] = useState(false);
  const [agentsList, setagentsList] = useState<any>([]);
  const [list, setlist] = useState<any>([]);

  const [searchTerm, setsearchTerm] = useState("");
  const [start, setStart] = useState<string | undefined>("");
  const [end, setEnd] = useState<string | undefined>("");
  const [selectedId, setselectedId] = useState("");
  const [quantity, setquantity] = useState(0);

  const assetQuantity = data.quantity;

  const currentUser = useSelector((store: RootStateOrAny) => store.currentUser);
  const currentBusiness = useSelector(
    (store: RootStateOrAny) => store.business
  );

  const handleFilter = () => {
    const foundNamesArr = agentsList.filter((obj: any) =>
      obj.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    if (searchTerm == "") {
      setagentsList(list);
    } else {
      if (foundNamesArr.length > 0) {
        setagentsList(foundNamesArr);
      } else {
        setagentsList(foundNamesArr);
      }
    }
  };

  const filterAgentList = async (obj: any) => {
    obj = obj instanceof Object ? obj : {};
    await fetchAgents();
  };

  const fetchAgents = async () => {
    const payload = {
      business_id: currentBusiness.id,
      start_date: start,
      end_date: end,
    };
    if (start && end) {
      const response = await InventoryService.GetAgentsList(payload);
      if (response.status) {
        const agentsData = response.payload.data;
        const transformed = agentsData.map((obj: any) => {
          return {
            id: obj.id,
            name: `${obj.firstName} ${obj.lastName}`,
          };
        });
        setagentsList(transformed);
        setlist(transformed);
      }
    }
  };

  const handleAssign = async (e: any) => {
    e.preventDefault();
    if (isloading) return;
    setisloading(true);
    const payload = {
      agent_id: selectedId,
      quantity,
      remark: `${quantity} ${data.assetName}`,
      asset_id: data.id,
      status: "ACTIVE",
    };

    const response = await InventoryService.AssignAsset(payload);
    if (response.status) {
      toast.success(response.message);
      apiCall();
      setisOpen(false);
      setisloading(false);
    } else {
      toast.error(response.message);
    }
  };

  useEffect(() => {
    handleFilter();
  }, [searchTerm]);

  useEffect(() => {
    const date = new Date();
    let currentDay = String(date.getDate()).padStart(2, "0");
    let currentMonth = String(date.getMonth() + 1).padStart(2, "0");
    let currentYear = date.getFullYear();

    date.setUTCDate(date.getUTCDate() - 50);

    const last30Days = `${date.getUTCFullYear()}-${(date.getUTCMonth() + 1)
      .toString()
      .padStart(2, "0")}-${date
      .getUTCDate()
      .toString()
      .padStart(2, "0")}`;
    let endDate = `${currentYear}-${currentMonth}-${currentDay}`;
    setStart(last30Days);
    setEnd(endDate);

    filterAgentList({
      start_date: last30Days,
      end_date: endDate,
      search: "",
    });
  }, []);

  useEffect(() => {
    fetchAgents();
  }, [start]);

  // useEffect(() => {
  //   // console.log("data==>", data);
  // }, []);

  return (
    <div>
      <Modal
        overFlow={true}
        isOpen={isopen}
        width={800}
        containerWidth={false}
        withCloseIcon={true}
        onClose={() => setisOpen()}
        renderContent={() => (
          <CreateCatContainer>
            <div>
              <h2>Assign assets</h2>
              <p>Assign asset to any agent of your choice</p>
            </div>
            <CCFormContainer>
              <CategoryForm onSubmit={handleAssign}>
                <p>Search agent by name</p>
                {/* <input placeholder="Catgory name" required minLength={4} /> */}
                <Input
                  type="text"
                  name="input"
                  value={searchTerm}
                  placeholder="Search..."
                  onChange={(value: any) => setsearchTerm(value)}
                  styleContainer={{ marginBottom: 0 }}
                />

                <div style={{ marginTop: "2rem" }}>
                  <label style={{ marginBottom: "0.5rem", display: "block" }}>
                    Asset quantity ({assetQuantity})
                  </label>
                  <Input
                    type="text"
                    name="input"
                    value={quantity}
                    placeholder="Quantity"
                    onChange={(value: any) => {
                      if (isNaN(value)) {
                        return false;
                      } else {
                        if (value <= assetQuantity) {
                          setquantity(value);
                        } else {
                          toast.warning("Asset quantity exceeded!");
                        }
                      }
                    }}
                    styleContainer={{ marginBottom: 0 }}
                  />
                </div>

                <AgentListContainer>
                  {agentsList.map((obj: any, index: any) => (
                    <AgentListRow key={index}>
                      <input
                        type="checkbox"
                        id={`${obj.id}`}
                        name={obj.name}
                        value={obj.id}
                        checked={selectedId === obj.id ? true : false}
                        onChange={() => {
                          if (selectedId === obj.id) {
                            setselectedId("");
                          } else {
                            setselectedId(obj.id);
                          }
                        }}
                      />
                      <label htmlFor={`${obj.id}`}>{obj.name}</label>
                      <br></br>
                    </AgentListRow>
                  ))}
                </AgentListContainer>
                <ButtonContainer>
                  <button disabled={selectedId && quantity > 0 ? false : true}>
                    {isloading ? <Spinner /> : "Assign"}
                  </button>
                </ButtonContainer>
              </CategoryForm>

              <div>
                <img src={InventoryBanner} alt="" />
              </div>
            </CCFormContainer>
          </CreateCatContainer>
        )}
      />
    </div>
  );
}
