import React, { useState } from "react";
import Modal from "shared/components/Modal";
import {
  ButtonContainer,
  CCFormContainer,
  CategoryForm,
  CreateCatContainer,
  DeleteBtnContainer,
  DeleteCatContainer,
  DeleteContent,
  NoBtn,
} from "../Styles";
import InventoryBanner from "../../../App/assets/images/inventorybanner.svg";
import { Input } from "shared/components";
import { RootStateOrAny, useSelector } from "react-redux";
import { InventoryService } from "../inventory-service";
import Spinner from "shared/components/spinner/spinner";
import toast from "shared/utils/toast";

type PropT = {
  isopen: boolean;
  setisOpen: Function;
  data: any;
  action: string;
};

export default function DeleteCategory({
  isopen,
  setisOpen,
  action,
  data,
}: PropT) {
  const [categoryName, setcategoryName] = useState("");
  const [isloading, setisloading] = useState(false);

  const currentUser = useSelector((store: RootStateOrAny) => store.currentUser);
  const currentBusiness = useSelector(
    (store: RootStateOrAny) => store.business
  );

  const handleDeleteCatgory = async (e: any) => {
    try {
      e.preventDefault();
      setisloading(true);
      const payload = {
        name: categoryName,
        business_id: currentBusiness?.id,
        user_id: currentUser?.id,
        // status: "deleted",
      };
      const response = await InventoryService.CreateCategory(payload);
      // // console.log("create category", response, "payload==>", payload);
      if (response.status) {
        toast.success("Asset created successfully.");
      }
    } catch (err) {
      const error: any = err;
      toast.error(error.message);
      // // console.log(error);
    } finally {
      setisloading(false);
    }
  };

  return (
    <div>
      <Modal
        overFlow={true}
        isOpen={isopen}
        width={800}
        containerWidth={false}
        withCloseIcon={false}
        onClose={() => setisOpen()}
        renderContent={() => (
          <DeleteCatContainer>
            <DeleteContent>
              <h2>Are you sure you want to delete this category</h2>
            </DeleteContent>
            <DeleteBtnContainer>
              <button onClick={handleDeleteCatgory}>
                {isloading ? <Spinner /> : "Yes"}
              </button>
              <NoBtn onClick={() => setisOpen()}>No</NoBtn>
            </DeleteBtnContainer>
          </DeleteCatContainer>
        )}
      />
    </div>
  );
}
