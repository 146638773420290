import { Button, Form } from "shared/components";
import breakpoints from "shared/utils/breakpoints";
import { color } from "shared/utils/styles";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: auto;
`;

export const FormContainer = styled.div`
  margin: 30px 30px 30px 30px;
  justify-content: flex-start;
  width: 60%;
  @media ${breakpoints.device.max.xl} {
    margin: 20px 15px 30px 15px;
  }
`;

export const TitleCase = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: -0.02em;

  /* Primary Black */

  color: #292929;
`;

export const SubTitle = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  /* width: 382px; */
  margin-top: 5px;

  /* Grey Text 1 */

  color: #828282;
  width: 100%;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
  margin-bottom: 10px;
`;

export const FormBody = styled.div`
  margin-top: 20px;
`;

export const ButtonContainer = styled.div`
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  margin-top: 16px;
`;

export const ActionButton = styled(Button)`
  margin: 1em 0;
  color: #fff;
  width: 100%;
  height: 54px;
  float: right;
  background-color: ${color.fountain};
`;

export const FormInnerContainer=styled.div`
  width: 100%;
  height: auto;
  overflow: auto;
`

export const WarningAlert =  styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 15px;
  border-radius: 10px;
  margin: 10px 0px;
  border: 2px solid;
  background-color: rgba(187, 128, 9, 0.15);
  border-color: rgba(187, 128, 9, 0.4);
  &>:first-child{
    width: 5px;
  }
`;

export const FormElement = styled(Form.Element)``;