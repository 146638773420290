import React, { CSSProperties } from "react";
import {
  CloseSpan,
  ModalCloseBtn,
  ModalContainer,
  ModalContent,
  ModalMain,
  ModalMainHeader,
} from "./Styles";

type modalPropT = {
  children?: React.ReactNode;
  width?: string;
  marginTop?: string;
  isOpen: boolean;
  onClose: Function;
  closeButton?: boolean;
  opacity?: boolean;
  paddingTop?: string;
  paddingLeft?: string;
  paddingRight?: string;
  paddingBottom?: string;
  cssString?: CSSProperties
};

export default function Modal({
  children,
  width,
  isOpen,
  onClose,
  closeButton,
  opacity,
  marginTop,
  paddingTop,
  paddingLeft,
  paddingRight,
  paddingBottom,
  cssString
}: modalPropT) {
  return (
    <ModalContainer visibile={isOpen} isopacity={opacity ? opacity : false}>
      <ModalMain width={width} marginTop={marginTop} style={cssString}>
        <ModalMainHeader>
          {closeButton && (
            <ModalCloseBtn onClick={() => onClose()}>
              <CloseSpan></CloseSpan>
            </ModalCloseBtn>
          )}
        </ModalMainHeader>
        <ModalContent
          paddingBottom={paddingBottom}
          paddingLeft={paddingLeft}
          paddingTop={paddingTop}
          paddingRight={paddingRight}
        >
          {children}
        </ModalContent>
      </ModalMain>
    </ModalContainer>
  );
}
