import Layout from "Layout";

import history from "browserHistory";
import {
  Redirect,
  Route,
  Router,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { Icon, PageError } from "shared/components";
import Dashboard from "./Dashboard";
import EditPaymentLink from "./EditPaymentLink";
import Home from "./Home";
import PaymentLink from "./PaymentLink";
import Settlement from "./Settlement";
import Transactions from "./Transactions";
import PaymentLinkList from "./PaymentLink/PaymentLinkList";
import Overview from "./Overview";
import AccountDetails from "./AccountDetails/AccountDetails";
import Apikeys from "./ApiKeys/Apikeys";
import ServiceDetails from "./Details/service-details";
import AddSplitting from "./AddSplitting";
import WalletBase from "./Wallet/WalletBase";
import WalletTransactions from "./Wallet/WalletTransactions";
import MandateManagement from "./MandateManagement";

const Service = () => {
  const match = useRouteMatch();
  const location = useLocation();
  const history2 = useHistory();

  let title = [];
  let page =
    location.pathname.split("/")[2] === undefined
      ? location.pathname.split("/")[1]
      : location.pathname.split("/")[2];
  title[0] = page.charAt(0).toUpperCase();
  title[1] = page.slice(1, page.length);
  let Title = title.join("");
  //

  // consoleLog(location, "Location");
  let ServiceDiv = (
    <span
      onClick={() => {
        let link = "";
        if (
          location?.pathname &&
          (location?.pathname.indexOf("/my-apps/dashboard") > -1 ||
            location?.pathname.indexOf("/my-apps/list") > -1)
        ) {
          link = "/my-apps/list";
          history2.push(link);
        } else {
          link = "/my-apps/dashboard";

          // consoleLog(history2, "history");
          history2?.length > 1 ? history2.goBack() : history2.push(link);
        }
      }}
      style={{ cursor: "pointer" }}
    >
      {/*  */}
      {location?.pathname &&
      location?.pathname.indexOf("/my-apps/list") > -1 ? null : (
        <Icon type="arrow-left-circle" size={20} />
      )}
      <span style={{ marginLeft: 10 }}>Services</span>
    </span>
  );

  return (
    <div>
      <Router history={history}>
        <Switch>
          <Redirect exact from="/my-apps" to="/my-apps/list" />
          <Route path="/my-apps/list" component={Home} />
          <Route path="/my-apps/:serviceId/dashboard" component={Overview} />

          <Route
            path="/my-apps/:serviceId/details"
            component={ServiceDetails}
          />
          <Route
            path="/my-apps/:serviceId/account-details"
            component={AccountDetails}
          />
          <Route path="/my-apps/:serviceId/api-keys" component={Apikeys} />

          <Route
            path="/my-apps/:serviceId/wallet/transactions"
            component={WalletTransactions}
          />

          <Route path="/my-apps/:serviceId/wallet" component={WalletBase} />

          <Route path="/my-apps/:serviceId/settlements" component={Settlement} />
          {/* <Route path="/my-apps/:serviceId/settlement/:id" component={Settlement} /> */}
          <Route
            path="/my-apps/:serviceId/payment-link-list"
            component={PaymentLinkList}
          />
          <Route
            path="/my-apps/:serviceId/payment-links"
            component={PaymentLink}
          />
          <Route
            path="/my-apps/:serviceId/edit/payment-links/:id"
            component={EditPaymentLink}
          />
          <Route
            path="/my-apps/:serviceId/transactions"
            component={Transactions}
          />
          <Route
            path="/my-apps/:serviceId/payment-aplitting"
            component={AddSplitting}
          />
          <Route
            path="/my-apps/:serviceId/mandate-management"
            component={MandateManagement}
          />
          {/* <Route path="/my-apps/:id" component={ServiceDetail} /> */}
          <Route component={PageError} />
        </Switch>
      </Router>
    </div>
    ////<Layout PageTitle={ServiceDiv}>

    ////</Layout>*}
  );
};

export default Service;
