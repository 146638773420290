import { useEffect, useState } from "react";
import teamOBJ from "../teamClass";
import consoleLog from "shared/utils/consoleLog";
import Table from "shared/components/Table";
import { formatDateInSlashes } from "shared/utils/dateTime";
import {
  ActionBtn,
  DeleteText,
  TableActions,
  TableContainer,
  ViewAllBtn,
} from "./Styles";
import shouldPerform, { getRoleName } from "shared/utils/assignRoles";
import pen from "App/assets/icons/pen.png";
import trash from "App/assets/icons/trash.svg";

export default function InvitedTeam({
  businessId,
  currentBusiness,
  currentUser,
  handleResendInvite,
  handleRemoveTeamMember,
  handleChangeRole,
  services,
}: any) {
  const [currentPage, setcurrentPage] = useState(1);
  const [totalPages, settotalPages] = useState(0);
  const [invTeamMembers, setInvTeamMembers] = useState([]);

  const tableHeader = [
    { id: "date", label: "Date" },
    { id: "email", label: "Email", width: "20%" },

    { id: "service", label: "Service", width: "20%", allowShowmore: true },
    { id: "status", label: "Status", width: "20%" },

    { id: "roles", label: "Role", width: "5%" },
    { id: "funcProps", label: "" },
  ];

  const getInvitedTeamMebers = async () => {
    const response = await teamOBJ.getInvitedTeamMembers(
      businessId,
      currentPage
    );
    setInvTeamMembers(response);
    consoleLog(response);
  };
  const nextPage = () => {
    if (currentPage < totalPages) {
      setcurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setcurrentPage(currentPage - 1);
    }
  };

  const goToPage = (val: number) => {
    if (val < currentPage) {
      prevPage();
    } else if (val > currentPage) {
      nextPage();
    }
  };

  const team = invTeamMembers?.map((obj: any, index: number) => {
    consoleLog(services, "Serrvices", obj);
    return {
      // sn: index + 1,
      name: `${obj?.User?.lastName ?? ""} ${obj?.User?.firstName ?? ""} ${obj
        ?.User?.otherName ?? ""} `,
      phone: obj.phone,
      email: obj.email,
      // applications: obj.applications,
      service: (() => {
        if (services instanceof Array) {
          let newServiceArray = [];
          if (typeof obj?.serviceIds === "string") {
            let arrServiceIds = obj.serviceIds.split(",");
            for (let el of services) {
              if (arrServiceIds.includes(el.uuid))
                newServiceArray.push(el?.name);
            }
            return newServiceArray.join(", ");
          }
        }
        return "";
      })(),
      roles: obj.roles,
      status: obj?.status,
      date: formatDateInSlashes(obj?.updatedAt),
      funcProps: (
        <TableActions>
          {obj?.status !== "ACTIVE" && (
            <ViewAllBtn onClick={() => handleResendInvite(obj)}>
              <span>Resend Invite</span>
            </ViewAllBtn>
          )}{" "}
          {obj?.role !== "OWNER" && (
            <>
              <ViewAllBtn onClick={() => handleRemoveTeamMember(obj)}>
                <ActionBtn src={trash} />
                <DeleteText>Delete</DeleteText>
              </ViewAllBtn>
            </>
          )}{" "}
          {shouldPerform(
            getRoleName(currentUser, currentBusiness?.id),
            "CTeams"
          ) && (
            <ViewAllBtn onClick={() => handleChangeRole(obj?.id)}>
              <ActionBtn src={pen} />
              <span color="green">Change Role</span>
            </ViewAllBtn>
          )}
        </TableActions>
      ),
    };
  });

  useEffect(() => {
    getInvitedTeamMebers();
  }, []);
  return (
    // <TableContainer>
    <Table
      headData={tableHeader}
      bodyData={team}
      rowStyle={{
        background: "#ffffff",
      }}
      serialNumber
      totalPages={totalPages ?? 0}
      currentpage={currentPage ?? 0}
      goToPage={goToPage}
      // hoverLink={hoverLink}
    />
    // </TableContainer>
  );
}
