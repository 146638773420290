import styled from "styled-components";

import { Button } from "shared/components";
import { color, font } from "shared/utils/styles";
import breakpoints from "shared/utils/breakpoints";

export const ReportBody = styled.div`
  text-align: center;
  margin: 0 auto;
  height: 100%;
  padding: 3em 6em;
  @media ${breakpoints.device.max.md} {
    padding: 3em 0em;
  }
  /* padding-left: 6em; */
`;

export const Title = styled.h1`
  color: #292929;
  padding: 10px 0;
  ${font.size(32)}
`;

export const imagestyle = {
  width: "40px",
};

export const SubTitle = styled.p`
  color: #828282;
  ${font.size(16)}
`;

export const ActionButton = styled(Button)`
  margin: 2em 0;
  color: #fff;
  width: 200px;
  background-color: ${color.fountain};
`;

export const OtpContainer = styled.div`
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2em;
  margin-bottom: 2em;
  /* padding: 3em 1.5em; */
`;

export const Info = styled.p`
  color: #292929;
  ${font.size(14)}
`;

export const OtpBtnContainer = styled.div`
  margin-bottom: 10px;
  text-align: center;
  cursor: pointer;
`;

export const OtpBtn = styled.p`
  color: #292929;
  ${font.size(14)}
`;

export const AuxOtpText = styled.span`
  color: #000;
  font-weight: 700;
  ${font.size(14)}
`;

export const Actions = styled.div`
  color: #292929;
  display: flex;
  justify-content: space-between;
  width: 60%;
  margin: 0 auto;
  margin-bottom: 1em;
  ${font.size(12)}
  div {
    display: flex;
    // width:50%;
    align-items: center;
    > img:first-child {
      width: 20px;
    }
    > p {
      margin-left: 0.5em;
    }
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  color: rgba(130, 130, 130, 0.9);
  ${font.size(12)}
  width: 50%;
  margin: 0 auto;
  margin-top: 2em;
`;
