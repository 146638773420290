import React from "react";
import {
  PageLayout,
  SectionHeader,
  SectionHeaderLabel,
  Sectionbutton,
} from "shared/section_components/Styles";
import KeysComponent from "./KeysComponent";

export default function ApiSettings() {
  const keys = [
    {
      id: 1,
      keyName: "Public Key",
      keyValue: "PUBK_TEST-20a9a74584h329g388503j3245a23k48ed-X",
    },
    {
      id: 2,
      keyName: "Secret Key",
      keyValue: "SECK_TEST-20a9a74584h329g388503j3245a23k48ed-X",
    },

    {
      id: 3,
      keyName: "Encryption Key",
      keyValue: "ENCK_TEST-20a9a74584h329g388503j3245a23k48ed-X",
    },
  ];

  const keyLists = keys.map((key) => <KeysComponent APIkey={key} />);
  return (
    <PageLayout>
      <SectionHeader>
        <SectionHeaderLabel>Live Api Keys</SectionHeaderLabel>
        <Sectionbutton>Generate New Keys</Sectionbutton>
      </SectionHeader>
      <div style={{ marginTop: "2rem" }}>{keyLists}</div>
    </PageLayout>
  );
}
