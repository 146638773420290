import { any } from "cypress/types/bluebird";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { TabsWrapper, TabList, StyledMenu } from "./Styles";
import Tab from "./Tab";
import consoleLog from "shared/utils/consoleLog";

export default function SettingsTab() {
  interface PropT {
    id: number;
    title: string;
    tabActive: boolean;
    path: string;
  }

  let menus = [
    {
      id: 1,
      title: "General",
      tabActive:
        window.location.href.split("/")[4] === "general" ? true : false,
      path: "/general",
    },
    {
      id: 2,
      title: "Bank Account",
      tabActive:
        window.location.href.split("/")[4] === "bank-account" ? true : false,
      path: "/bank-account",
    },
    // {
    //   id: 3,
    //   title: "Web Hook",
    //   tabActive: window.location.href.split('/')[4] === 'web-hook' ? true : false,
    //   path: "/web-hook",
    // },
    // {
    //   id: 4,
    //   title: "Api",
    //   tabActive: window.location.href.split('/')[4] === 'api' ? true : false,
    //   path: "/api",
    // },
    
    // {
    //   id: 6,
    //   title: "Account Settings",
    //   tabActive:
    //     window.location.href.split("/")[4] === "account-settings"
    //       ? true
    //       : false,
    //   path: "/account-settings",
    // },
    {
      id: 7,
      title: "Account Recovery",
      tabActive:
        window.location.href.split("/")[4] === "account-recovery"
          ? true
          : false,
      path: "/account-recovery",
    },
    {
      id: 8,
      title: "Change Login Details",
      tabActive:
        window.location.href.split("/")[4] === "change-login-details"
          ? true
          : false,
      path: "/change-login-details",
    },
    {
      id: 5,
      title: "Teams",
      tabActive: window.location.href.split("/")[4] === "teams" ? true : false,
      path: "/teams",
    },
  ];

  const [data, setdata] = useState<PropT[]>(menus);

  const handleToogle = (id: number) => {
    consoleLog(id);
    // commented bellow, better implementation above
    // data.map((item) => {
    //   if (item.id === id) {
    //     item.tabActive = true;
    //   } else {
    //     item.tabActive = false;
    //   }
    // });
  };

  const menuList = data.map((menu) => (
    <Tab
      key={menu.id}
      id={menu.id}
      title={menu.title}
      path={menu.path}
      isActive={menu.tabActive}
      toogle={() => handleToogle(menu.id)}
    />
  ));

  return (
    <TabsWrapper>
      <TabList>{menuList}</TabList>
    </TabsWrapper>
  );
}
