// @ts-ignore
import { CSVLink, CSVDownload } from 'react-csv'
import { ActionButton, ActionButton2 } from '../DashboardSubHeader/Styles'
const csvData = [
	['firstname', 'lastname', 'email'],
	['Ahmed', 'Tomi', 'ah@smthing.co.com'],
	['Raed', 'Labes', 'rl@smthing.co.com'],
	['Yezzi', 'Min l3b', 'ymin@cocococo.com'],
]
export default ({ children, data, type }: any) => {
	// // console.log('download adata', data)
	return (
		<>
			<CSVLink
				filename={`Download Summary${new Date().getTime()}.csv`}
				data={data}
				target='_blank'>
				{
					type === 'beta' ? (
						<ActionButton2 disabled={!data.length}>{children}</ActionButton2>
					) : (
						<ActionButton disabled={!data.length}>{children}</ActionButton>
					)
				}
			</CSVLink>
		</>
	)
}
