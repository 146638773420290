import React, { useLayoutEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { range } from 'lodash';

import { formatDate, formatDateTimeForAPI } from 'shared/utils/dateTime';

import { TimeSection, Time } from './Styles';

// const propTypes = {
//   value: PropTypes.string,
//   onChange: PropTypes.func.isRequired,
//   setDropdownOpen: PropTypes.func.isRequired,
// };

type PropT= {
  value: string,
  onChange: ({})=>void,
  setDropdownOpen: (x:boolean)=>void,
}

const defaultProps = {
  value: undefined,
};

const DatePickerTimeSection = ({ value, onChange, setDropdownOpen }:PropT) => {
  const $sectionRef:any = useRef();

  useLayoutEffect(() => {
    scrollToSelectedTime($sectionRef.current, value);
  }, [value]);

  const handleTimeChange = (newTime:string) => {
    const [newHour, newMinute] = newTime.split(':');

    const existingDateWithNewTime:any = moment(value).set({
      hour: Number(newHour),
      minute: Number(newMinute),
    });
    onChange(formatDateTimeForAPI(existingDateWithNewTime));
    setDropdownOpen(false);
  };

  return (
    <TimeSection ref={$sectionRef}>
      {generateTimes().map((time : string) => (
        <Time
          key={time}
          data-time={time}
          isSelected={time === formatTime(value)}
          onClick={() => handleTimeChange(time)}
        >
          {time}
        </Time>
      ))}
    </TimeSection>
  );
};

const formatTime = (value : string | number) => formatDate(value, 'HH:mm');

const scrollToSelectedTime = ($scrollCont : HTMLElement, value: string | number) => {
  if (!$scrollCont) return;

  const $selectedTime:any = $scrollCont.querySelector(`[data-time="${formatTime(value)}"]`);
  if (!$selectedTime) return;

  $scrollCont.scrollTop = $selectedTime.offsetTop - 80;
};

const generateTimes = () =>
  range(48).map((i: number)=> {
    const hour = `${Math.floor(i / 2)}`;
    const paddedHour = hour.length < 2 ? `0${hour}` : hour;
    const minute = i % 2 === 0 ? '00' : '30';
    return `${paddedHour}:${minute}`;
  });

// DatePickerTimeSection.propTypes = propTypes;
DatePickerTimeSection.defaultProps = defaultProps;

export default DatePickerTimeSection;
