import { Button } from "shared/components";
import breakpoints from "shared/utils/breakpoints";
import { color } from "shared/utils/styles";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: auto;
`;

export const Image = styled.img`
  object-fit: center;
  pointer-events: none;
  @media ${breakpoints.device.max.xs} {
    display: none;
  }
`;

export const FormContainer = styled.div`
  margin: 20px 0 20px 30px;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
  justify-content: flex-start;
  @media ${breakpoints.device.max.xl} {
    margin: 20px 15px 30px 15px;
  }
`;

export const TitleCase = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: -0.02em;

  /* Primary Black */

  color: #292929;
`;

export const SubTitle = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  /* width: 382px; */
  margin-top: 5px;

  /* Grey Text 1 */

  color: #828282;
`;

export const FormBody = styled.div`
  // margin-top: 20px;
`;

export const ButtonContainer = styled.div`
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  margin-top: 16px;
`;
export const Back = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  /* identical to box height */
  margin-top: 16px;
  cursor: pointer;

  /* Grey Text 1 */

  color: #828282;
`;

export const ActionButton = styled(Button)`
  // margin: 1em 0;
  margin: 0;
  color: #fff;
  width: 138px;
  height: 54px;
  float: right;
  background-color: ${color.fountain};
`;

export const FormInnerContainer = styled.div`
  width: 100%;
  height: 500px;
  overflow: auto;
  // -ms-overflow-style: none; /* Internet Explorer 10+ */
  // scrollbar-width: 1px;
  // ::-webkit-scrollbar {
  //   display: none;
  // }
`;
