import React, { useState } from "react";

import { StateContainer, ViewBtn, ViewContainer } from "./Styles";
import { SubAccountData } from "../../shared/components/FakeDB/SubAccountData";
import EmptyState from "../../shared/components/EmptyState";
import Table from "shared/components/Table";
import Layout from "Layout";
import DashboardSubHeader from "shared/components/DashboardSubHeader";
import { Switch } from "shared/components";
import consoleLog from "shared/utils/consoleLog";
import { Modal } from "shared/components";
import { color } from "shared/utils/styles";
import { useHistory } from "react-router-dom";

function Index() {
  const [modal, setModal] = useState(false);
  const [searchData, setSearchData] = useState("");
  const history = useHistory();
  const openModal = () => {
    setModal(!modal);
  };

  const toggle = () => {
    consoleLog("toggle");
  };

  const headData: any = [
    { id: "terminalID", label: "Terminal ID" },
    { id: "agentName", label: "Agent Name" },
    { id: "action", label: "" },
  ];

  const SearchForData = () => {};
  const viewGeolocation = () => {};

  const dummyData = [
    {
      terminalID: "0388383332",
      agentName: "John Doe",
      action: (
        <ViewContainer
          onClick={() => {
            history.push("terminal");
          }}
        >
          <ViewBtn>View details</ViewBtn>
        </ViewContainer>
      ),
    },
  ];

  return (
    ////<Layout PageTitle="Geolocation">
    // <StateContainer>
    //   <EmptyState
    //     stateTitle={"Under construction"}
    //     statePara={"Great features awaits you here."}
    //   />
    // </StateContainer>
    ////</Layout>

    <div>
      <DashboardSubHeader
        count={0}
        title={"Geolocation"}
        btnTitle=""
        callBackFn={() => ""} //setModal(true)
        setSearchData={SearchForData}
        withSearch={true}
        withFilter={false}
        widthActionBtn={false}
        arrToFilter={[]}
        applyFilter={() => ""}
        clickedParam={""}
        getParams={() => ""}
        setStartDate={() => {}}
        setEndDate={() => {}}
      />
      <div>
        <Table
          headData={headData}
          bodyData={dummyData}
          hoverLink
          serialNumber
          clickFunction={viewGeolocation}
          totalPages={0}
          currentpage={0}
          emptyStateMessage="No Geolocation records"
        />
      </div>
    </div>
  );
}

export default Index;
