import { useEffect, useLayoutEffect, useState, useCallback } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Router, Switch, useHistory } from "react-router-dom";
import useNetwork from "shared/hooks/useNetwork";
import Modal from "shared/quickComponents/modal";
import { createQueryParamModalHelpers } from "shared/utils/queryParamModal";

import NoInternet from "shared/components/NoInternet";
import Header from "./Header";
import NavbarLeft from "./NavbarLeft";
import Sidebar from "./Sidebar";
import Notification from "./Notification";
import {
  ContentArea,
  Layouts,
  NavLeftMenu,
  NavWrapper,
  KycCheckerCont,
  CloseContainer,
  CloseBtn,
  CloseText,
  AlertBox,
  AlertTitle,
  AlertBtn,
  AlertText,
} from "./Styles";

import { GradientLoader, Icon, PageError } from "shared/components";
import { getStoredAuthToken, saveLastApiCallTime } from "shared/utils/authToken";
import { setBusinessLists } from "store/actions/businessAction";
import EmptyFile from "App/assets/images/placeholder/empty-file.svg";
import { getCurrentBusinessKycStep } from "shared/utils/utility";
import KYC from "KYC-refactored";
import Settings from "Settings";
import Services from "Services";
import Customers from "Customers";
import CustomerDetails from "Customers/CustomerDetails";
import Agency from "Agency";
import Overview from "Overview";
import AccountSettings from "AccountSettings";
import ChargeBacks from "ChargeBacks";
import Profile from "Profile";
import SubAccount from "SubAccount";
import SystemStatus from "SystemStatus";
import Team from "Settings/Team";
import ChargeBackDetails from "ChargeBackDetails";
import Test from "../Test/index";
import Preloader from "shared/components/preloader";
import { setKycBoardingRec } from "store/actions/boardingAction";
import { setKycStartAddress } from "store/actions/kycstartAction";
import Tour from "./tour";
import { AppProvider } from "./tour/context";
import { NotificationIcon } from "./Notification/Styles";
import { NotificationBall } from "./Header/Styles";
import { FaBell } from "react-icons/fa";
import Invitation from "Invitation";

const Layout = ({ children, PageTitle }: any) => {
  const [isLoading, setisLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const utilModalHelpers = createQueryParamModalHelpers("display");
  const businesses = useSelector((store: RootStateOrAny) => store.businesses);
  const [currentPage, setCurrentPage] = useState<string>("");
  const [showServiceButtons, setshowServiceButtons] = useState(false);
  const [expandNotification, setExpandNotification] = useState(false);

  //consoleLog("LayoutBusinesses", businesses);
  const currentbusiness = useSelector(
    (store: RootStateOrAny) => store.business
  );
  const currentBusinessKyc = useSelector(
    (store: RootStateOrAny) => store.businessKyc
  );

  history.listen((location, action) => {
    saveLastApiCallTime();
    // console.log(`The current route is ${location.pathname}`);
  });

  const currentUser = useSelector((store: RootStateOrAny) => store.currentUser);
  const service = useSelector((store: RootStateOrAny) => store.service);

  const [navOpened, setNavOpened] = useState(false);

  const isOnline = useNetwork();
  const kycStat = true;
  let currentPage2 = history?.location?.pathname;

  const toggleNav = useCallback(() => {
    setNavOpened(!navOpened);
  }, [navOpened]);

  const gotoKYC = () => {
    const page = window.location;
    if (!page.toString().includes("kyc")) {
      history.push("/kyc");
    }
  };

  const checkForKyc = async () => {
    if (!currentbusiness) {
      gotoKYC();
    }
  };

  //============Checking changes in url ==================
  let lastUrl = location.href;
  new MutationObserver(() => {
    const url = location.href;
    if (url !== lastUrl) {
      lastUrl = url;
      //onUrlChange();
    }
  }).observe(document, { subtree: true, childList: true });

  function onUrlChange() {
    setisLoading(true);
    setTimeout(() => {
      setisLoading(false);
    }, 400);
  }
  

  const jumpToKyc = async () => {
    const step = getCurrentBusinessKycStep(
      currentbusiness,
      currentPage2.includes("gateway") ? "GATEWAY" : "AGENCY"
    );
    await dispatch(setKycBoardingRec());

    if (!currentPage2.includes("agency")) {
      //gateway
      // await dispatch(setKycStartAddress("/kyc/gateway-upload-documents"));

      // history.push("/kyc/gateway-upload-documents");
      if (!step || step <= 1) {
        await dispatch(setKycStartAddress("/kyc/add-account-details"));
        history.push("/kyc/add-account-details");
      } else {
        await dispatch(setKycStartAddress("/kyc/gateway-upload-documents"));
        history.push("/kyc/gateway-upload-documents");
      }
    } else {
      // history.push("/kyc/residential-information");
      if (step === 1) {
        await dispatch(setKycStartAddress("/kyc/residential-information"));
        history.push("/kyc/residential-information");
      } else if (step === 2) {
        await dispatch(setKycStartAddress("/kyc/business-information"));
        history.push("/kyc/business-information");
      } else if (step === 3) {
        await dispatch(
          setKycStartAddress("/kyc/agency-banking-upload-documents")
        );

        history.push("/kyc/agency-banking-upload-documents");
      } else {
        await dispatch(setKycStartAddress("/kyc/residential-information"));
        history.push("/kyc/residential-information");
      }
    }
  };

  const getBusinessTypes = () => {
    let currentPath = window.location.pathname;

    let expectedUrlQueryStringArr = ["gateway", "agency"];
    let currentPathArray = String(currentPath).split("/");
    if (
      !(
        currentPathArray[1] &&
        expectedUrlQueryStringArr.includes(currentPathArray[1])
      )
    ) {
      currentPath = "/gateway" + currentPath;
    }
    // alert(currentPath)
    if (businesses && currentbusiness && currentbusiness.BusinessKyc) {
      // consoleLog(currentbusiness?.BusinessKyc, "BusinessKYC", currentPath);
      let filtered = currentbusiness?.BusinessKyc.find(
        (kyb: any) =>
          currentPath.includes(kyb.type.toLowerCase()) &&
          kyb.status === "APPROVED"
      );
      // consoleLog(
      //   filtered,
      //   "GotoKYC",
      //   currentbusiness?.BusinessKyc,
      //   "BusinessKYC"
      // );
      if (filtered) {
        return true;
      } else {
        return false;
      }
    } else {
      gotoKYC();
    }
  };

  const goBack = () => {
    window.history.go(-1);
  };

  useLayoutEffect(() => {
    const token = getStoredAuthToken();
    if (!token) {
      history.push("/authenticate");
    }
    ///getAccountSettings();
  }, []);

  useEffect(() => {
    return history.listen((location) => {
      //alert(`You changed the page to: ${location.pathname}`);
      localStorage.setItem("newPage", location.pathname);
      setisLoading(true);
      setTimeout(() => {
        setisLoading(false);
      }, 400);
    });
  }, [history]);

  useEffect(() => {
    // consoleLog("Loggin degug");
    (async () => {
      setCurrentPage(window.location.pathname);
      checkForKyc();
    })();
  }, [currentbusiness, businesses]);

  useEffect(() => {
    dispatch(setBusinessLists([], !!currentbusiness));
    // consoleLog("Current Business Done", !!currentbusiness);
    checkForKyc();
  }, []);

  return (
    <AppProvider>
      <Layouts>
        <NavWrapper navOpened={navOpened}>
          {navOpened && (
            <NavLeftMenu onClick={toggleNav}>
              <Icon type="close" size="26" color="white" />
            </NavLeftMenu>
          )}
          <NavbarLeft />
          <Sidebar onClick={toggleNav} />
        </NavWrapper>

        <Header
          PageTitle={PageTitle}
          toggleNav={toggleNav}
          showNotification={false}
          // showServiceButtons={service?.id ? true : false}
          showServiceButtons={showServiceButtons}
        />

        <>
          {!getBusinessTypes() ? (
            <KycCheckerCont>
              <CloseContainer>
                <CloseBtn onClick={goBack}>
                  <Icon
                    type={"close"}
                    style={{ color: "#000", paddingTop: 5 }}
                  />
                </CloseBtn>
                <CloseText>Close</CloseText>
              </CloseContainer>

              <AlertBox>
                <img src={EmptyFile} alt="empty file" />
                <AlertTitle>Whoops!!</AlertTitle>
                <AlertText>
                  To continue using{" "}
                  {currentPage2.includes("agency")
                    ? "agency banking"
                    : "gateway"}
                  , click the button below .
                </AlertText>
                <AlertBtn onClick={jumpToKyc}>
                  {currentPage2.includes("agency")
                    ? "Activate agency banking"
                    : "Activate gateway"}
                </AlertBtn>
              </AlertBox>
            </KycCheckerCont>
          ) : (
            <ContentArea
              addMarginTop={
                currentBusinessKyc.length && !currentBusinessKyc.status
              }
            >
              {isLoading ? (
                <>
                  <GradientLoader />
                </>
              ) : (
                <Router history={history}>
                  <Switch>
                    <Route path="/overview" component={Overview} />

                    <Route path="/kyc" component={KYC} />
                    <Route path="/invitations" component={Invitation} />
                    <Route path="/overview" component={Overview} />

                    <Route path="/settings" component={Settings} />

                    <Route path="/my-apps" component={Services} />

                    <Route path="/customers" component={Customers} exact />
                    <Route
                      path="/customers/transaction/:id"
                      component={CustomerDetails}
                      exact
                    />

                    <Route path="/agency" component={Agency} />

                    <Route path="/profile" component={Profile} />
                    <Route
                      path="/account-settings"
                      component={AccountSettings}
                    />

                    <Route path="/sub-account" component={SubAccount} />
                    <Route path="/team" component={Team} />

                    <Route path="/charge-backs" component={ChargeBacks} />

                    <Route
                      path="/charge-back-detail/:id"
                      component={ChargeBackDetails}
                    />

                    <Route path="/system-status" component={SystemStatus} />

                    {/* <Route path="/link" component={PaymentLinkModal} /> */}
                    {/* <Route
                      path="/dispute-resolution/:id"
                      component={DisputeResolution}
                    /> */}

                    {/* <Route path="/link/:id" component={PaymentLinkModal} /> */}

                    <Route path="/test" component={Test} />
                    <Redirect exact from="/" to="/overview" />
                    <Route path="*" component={PageError} />
                  </Switch>
                </Router>
              )}
              {/* <Tour /> */}
            </ContentArea>
          )}
          {/* <Notification /> */}
          <Tour />
        </>
        {/* {!isOnline && (
          <Modal
            isOpen={true}
            closeButton={false}
            onClose={() => ""}
            width="50%"
          >
            <NoInternet />
          </Modal>
        )} */}
      </Layouts>
    </AppProvider>
  );
};

export default Layout;


