import styled, { css } from "styled-components";
import RightImg from "App/assets/images/placeholder/right-img-1.svg";
import { color, font } from "shared/utils/styles";
import { Button, Form } from "shared/components";
import breakpoints from "shared/utils/breakpoints";

type Props={
    status?: string
}
export const Invite = styled.div<Props>`
    background-color: #e5e5e5;
    width: 100%;
    border-radius:5px;
    display: block;
    align-items: left;
    padding: 20px 10px;
    
    
    ${props => (props.status=='PENDING' && css`
            background-color: #F5E7B2;
            border: 1px solid #E0A75E;
            color: #E0A75E;
        `
    )}
    ${props => (props.status=='PROCESSING' && css`
            background-color: #A7E6FF;
            border: 1px solid #3572EF;
            color: #3572EF;
        `
    )}
    ${props => (props.status=='COMPLETED' && css`
            background-color: #E0FBE2;
            border: 1px solid #40A578;
            color: #40A578;
        `
    )}
    &>.date{
        font-size:14px;
    }

    &>.actions{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: end;
        align-items: end;
        gap: 30px;
        margin-top: 10px;

        &:first-child{
            cursor: pointer;
            border: 1px solid green;
            border-radius: 50px;
        }
        &:last-child{
            cursor: pointer;
        }
    }
`;

export const InviteTitle = styled.div`
    width: 100%;
`;
