import { useEffect, useState } from "react";
import Table from "shared/components/Table";
import api from "shared/utils/api";
import toast from "shared/utils/toast";
import { GradientLoader, Icon, Modal } from "shared/components";
import { ActionButton, ButtonContainer } from "../AddSubAgent/styles";
import { useHistory, useParams } from "react-router-dom";
import { color } from "shared/utils/styles";
import { getStatusColor } from "shared/utils/statusColor";
import EllipsisLink from "shared/components/EllipsisLink";
import { OptionItem } from "shared/components/EllipsisLink/Styles";
import { OptionText } from "Services/PaymentLink/PaymentLinkList/Styles";
import CopyIcon from "App/assets/icons/copy.svg";
import DeleteIcon from "App/assets/icons/trash.svg";
import AssignTerminal from "./AssignTerminal";

const currentPath = window.location.href;

const Terminals = () => {
  const [agentTerminals, setAgentTerminals] = useState<any>({});
  const [officerIncharge, setOfficerIncharge] = useState<any>({});
  const [isLoadingTerminals, setIsLoadingTerminals] = useState(false);
  const [isLoginIn, setIsLoginIn] = useState(false)

  const [modal, setModal] = useState(false);

  const openModal = () => {
    setModal(!modal);
  };

  const history = useHistory();
  const params: any = useParams();

  enum Actions {
    ACTIVE,
    INACTIVE,
    SUSPEND,
    TRANSFER,
    DELETE,
  }
  //alert(JSON.stringify(params))
  const terminalColumnHeaders = [
    { id: "terminalId", label: "Terminal Id" },
    { id: "terminalSerialNo", label: "Serial No" },
    { id: "bankName", label: "Bank"},
    { id: "account", label: "Account number"},
    { id: "accountName", label: "Account name"},
    { id: "updatedAt", label: "Date mapped" },
    { id: "status", label: "Status" },
    { id: "action", label: "" },
  ];

  const getMappedTerminals = async () => {
    setIsLoadingTerminals(true);
    try {
      const res = await api.get(
        `/agency-banking/${params?.id}/mapped-terminals`
      );
      // console.log(res)
      if (res.status) {
        setAgentTerminals(res.payload);
      }
    } catch (error) {
      //toast.error(error?.message)
    } finally {
      setIsLoadingTerminals(false);
    }
  };

  const viewTerminalTransactionFunction = (obj: any) => {
    history.push(`/agency/agents/detail/${params.id}/transactions/${obj.id}`);
  };

  const suspendTerminal = async(item: any, action: Actions) => {
    // alert(Actions[action])
    try{
      // console.log(item)
      const payload={
        terminal_id: item?.terminalId,
        business_id:item?.businessId,
        agent_id:item?.agentId,
        status: Actions[action]
      } 
      // // console.log(payload)
      const resp=await api.put(`/agency-banking/map-terminal/${item?.terminalId}`, payload);
      if(resp?.status){
        getMappedTerminals();
      }
    }catch(error: any){
      // console.log(error)
      toast.error(error?.message)
    }finally{}
  };

  const normalizeOptionStatus = (el: any, status: string) => {
    return el?.filter(
      (x: any) =>
        x.type.toString().toLowerCase() != status.toString().toLowerCase()
    );
  };
  const options = (item: any) => [
    {
      type: "Trans",
      title: (
        <OptionItem>
          <Icon type="reports" size={15} />
          <OptionText style={{ marginLeft: 20 }}>View Transactions</OptionText>
        </OptionItem>
      ),
      link: () => viewTerminalTransactionFunction(item),
    },

    {
      type: Actions.ACTIVE,
      title: (
        <OptionItem>
          <Icon type="pencil" size={15} />
          <OptionText style={{ marginLeft: 20 }}>Activate</OptionText>
        </OptionItem>
      ),
      link: () => suspendTerminal(item, Actions.ACTIVE),
    },
    {
      type: Actions.INACTIVE,
      title: (
        <OptionItem>
          <Icon type="pencil" size={15} />
          <OptionText style={{ marginLeft: 20 }}>Deactivate</OptionText>
        </OptionItem>
      ),
      link: () => suspendTerminal(item, Actions.INACTIVE),
    },
    {
      type: Actions.SUSPEND,
      title: (
        <OptionItem>
          <Icon type="pencil" size={15} />
          <OptionText style={{ marginLeft: 20 }}>Suspend</OptionText>
        </OptionItem>
      ),
      link: () => suspendTerminal(item, Actions.SUSPEND),
    },
    {
      type: Actions.DELETE,
      title: (
        <OptionItem>
          <img src={DeleteIcon} />
          <OptionText style={{ marginTop: 5 }}>Delete</OptionText>
        </OptionItem>
      ),
      link: () => suspendTerminal(item, Actions.DELETE),
    },
  ];
  const handleEdit = () => {};

  let agentTerminalLists =
    agentTerminals instanceof Array
      ? agentTerminals.map((el: any, ind: number) => {
          let obj: any = {};
          obj.id = el?.id;
          obj.terminalId = el?.terminalId;
          obj.terminalSerialNo = el?.terminalSerialNo;
          obj.bankName = el?.bankName;
          obj.account = el?.accountNo;
          obj.accountName = el?.accountName;
          obj.agent_id = el?.agent_id;
          obj.business_id = el?.business_id;
          obj.updatedAt =
            el?.updatedAt &&
            new Date(el?.updatedAt).toLocaleString("en-GB", {
              year: "numeric",
              month: "numeric",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            });

          obj.status =
            el.status !== "ACTIVE" ? (
              <div style={{ color: getStatusColor("terminated") }}>
                INACTIVE
              </div>
            ) : (
              <div style={{ color: getStatusColor("successful") }}>ACTIVE</div>
            );

          obj.action = (
            <>
              <EllipsisLink
                content={normalizeOptionStatus(options(el), el.status)}
                index={ind}
              >
                Edit
              </EllipsisLink>
            </>
          );

          return { ...el, ...obj };
        })
      : [];

  
  useEffect(() => {
    getMappedTerminals();
  }, []);

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div>
        <ActionButton
          type="button"
          variant={color.primary}
          onClick={() =>setModal(!modal)
            //history.push(`/agency/agents/detail/${params.id}/temrinals/assign-terminals`)
          }
        >
          Assign terminal
        </ActionButton>
      </div>
      <div>
        {isLoadingTerminals && <GradientLoader />}
        {!isLoadingTerminals && (
          <Table
            headData={terminalColumnHeaders}
            bodyData={agentTerminalLists ?? []}
            hoverLink
            serialNumber
            clickFunction={() => {}}
            totalPages={agentTerminals?.totalPages ?? 0}
            currentpage={agentTerminals?.currentPage ?? 0}
            emptyStateMessage="Yet to map terminal to this agent"
          />
        )}
      </div>
      <Modal
        overFlow={true}
        isOpen={modal}
        width={800}
        containerWidth={false}
        withCloseIcon={true}
        onClose={() => setModal(false)}
        renderContent={() => (
          <AssignTerminal path={currentPath}/>
        )}
      />
    </div>
  );
};

export default Terminals;
