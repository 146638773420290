import { Container, LeftSection, LogoImage, RightSection } from "./Styles";
import FpLogo from "App/assets/images/logo.svg";
import DynamicBackground from "./dynamicBackground";

export default ({ children }: any) => {
  return (
    <Container>
      <LeftSection>
        <DynamicBackground />
      </LeftSection>

      <RightSection>
        <LogoImage src={FpLogo} />
        {children}
      </RightSection>
    </Container>
  );
};
