import consoleLog from "shared/utils/consoleLog";

export const currencyFormat = (amount: number, curr: string = "NGN") => {
  let formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: curr,
  });

  return formatter.format(amount);
};

export const currencyFormat100 = (amount: number, curr: string = "NGN") => {
  let formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: curr,
  });

  // return formatter.format(amount / 100);
  return formatter.format(amount);
};

export const formatMoney__ = (
  amount: number,
  decimalCount = 2,
  decimal = ".",
  thousands = ","
) => {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    let i: any = parseInt(
      Math.abs(Number(amount) || 0).toFixed(decimalCount)
    ).toString();
    let j: any = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : "")
    );
  } catch (e) {
    consoleLog(e);
  }
};

export const formatMoney = (
  amount: number,
  decimalCount = 2,
  decimal = ".",
  thousands = ","
) => {
  try {
    let val = currencyFormat(Number(amount));
    consoleLog(val, "valER");
    let arr: any = val.split(",");
    arr[0] = Number.parseInt(arr[0]);
    return arr.join(",");
  } catch (e) {
    consoleLog(e);
  }
};

export const removeCommas = (val: string) => {
  let res = Number.parseFloat(val.replace(/,/g, ""));
  return res;
};

export const maskString = (val: string | number, pre = 5, post = 5) => {
  val = String(val);
  let newStr = "";
  for (let i = 0; i < val.length; i++) {
    newStr += i > pre && i < val.length - post ? "*" : val[i];
  }
  return newStr;
};
