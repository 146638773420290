import { useState, useEffect } from "react";
import {
  ParentCont,
  LeftPanel,
  RightPanel,
  RightPanelAuxCont,
  CloseBtn,
  DescriptionBox,
  RightPanelAuxCon2,
  DescriptionTitle,
  DescriptionText,
  DescriptionBtn,
  FormCont,
  TitleCont,
  Back,
  FormTitle,
  FormSubTitle,
  FormElement,
  Selectors,
  ActionButton,
  MiddlePanel,
  UploadContainer,
  UploadLabel,
  UploadBox,
  UploadInput,
  UploadButton,
  FieldError,
  TrashBtn,
  UploadedFile,
} from "./Styles";
import { Icon, Form } from "shared/components";
import { useHistory } from "react-router-dom";
import api from "shared/utils/api";
import {
  getStoreLocalStorage,
  omniGetter,
  omniRemover,
  omniSaver,
} from "shared/utils/authToken";
import toast from "shared/utils/toast";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import PageIndicator from "shared/components/PageIndicator";
import { GatewayPages } from "../../PageList";
import { uploadFile } from "apiServices/authService";
import consoleLog from "shared/utils/consoleLog";
import { setCurrentBusinessKyc } from "store/actions/businessAction";
import { shortenUrl } from "shared/utils/ellipsis";
import { setKycBoardingRec } from "store/actions/boardingAction";
import checkExistingKycBoarding from "shared/utils/checkExistingKycBoarding";

function UploadDocuments() {
  const business = useSelector((store: RootStateOrAny) => store.business);
  const kycStart = useSelector((store: RootStateOrAny) => store.kycStart);
  const kycBoardingRec = useSelector(
    (store: RootStateOrAny) => store.kycBoardingRec
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [isUploadingArticle, setIsUploadingArticle] = useState<boolean>(false);
  const [isUploadingAddress, setIsUploadingAddress] = useState<boolean>(false);
  const [isUploadingOthers, setIsUploadingOthers] = useState<boolean>(false);
  const [article, setArticle] = useState<string>(kycBoardingRec?.article ?? "");
  const [cac, setCAC] = useState<string>(kycBoardingRec?.cac ?? "");
  const [address, setAddress] = useState<string>("");
  const [others, setOthers] = useState<any>(kycBoardingRec?.others ?? []);
  const [error, setError] = useState<any>({
    article: false,
    cac: false,
    address: false,
    others: false,
  });

  const [fileObj, setFileObj] = useState<any>({});

  const history = useHistory();
  const dispatch = useDispatch();

  // const uploadedFileNames: any[] = [];

  const [isDisabled, setDisabled] = useState(true);

  const updateUploadedFiles = (file: any, keyName: string) => {
    // // console.log("selected file", file);
    const { name } = file;
    let uploadedFileNames = Object.values(fileObj).filter(
      (el: any) => el !== keyName
    );
    // console.log("selected file", name);
    const fileNameExists = uploadedFileNames.includes(name);
    if (fileNameExists) {
      toast.error("File already uploaded");
      return false;
    } else {
      // uploadedFileNames.push(name);
      setFileObj({ ...fileObj, [keyName]: name });
      return true;
    }
  };

  const initialValues = {
    accountNumber: "",
    bankName: "",
    bankAccountName: "",
  };

  const validateUploads = () => {
    const errors = {} as any;
    if (!article) {
      errors.article = true;
    }
    if (!cac) {
      errors.cac = true;
    }
    if (!address) {
      errors.address = true;
    }
    if (!others) {
      errors.others = true;
    }
    if (Object.keys(errors).length > 0) {
      setError(errors);
      return true;
    }
    return false;
  };

  const onSubmit = async () => {
    setIsLoading(true);
    try {
      let businessId = business?.id;
      // ?? omniGetter("business_id");
      // consoleLog(business, "Business", omniGetter("business_kyc_id"), "Omni");

      let findRecord =
        business?.BusinessKyc instanceof Array &&
        business?.BusinessKyc.find(
          (el: any) => el?.type.toLowerCase() === "gateway"
        );
      let res: any;

      if (findRecord) {
        let businessKycId = findRecord?.id;
        // (business?.BusinessKyc &&
        //   business?.BusinessKyc[0] &&
        //   business.BusinessKyc[0].id) ??
        // omniGetter("business_kyc_id");

        // `/business/${business.id}/kyc/${business?.BusinessKyc &&
        //   business?.BusinessKyc[0] &&
        //   business.BusinessKyc[0].id}`

        res = await api.patch(`/business/${businessId}/kyc/${businessKycId}`, {
          step: 2,
          status: "APPROVED",
          uploadMeta: {
            cac,
            article,
            others,
          },
        });
        toast.success(res.message);
        await dispatch(
          setKycBoardingRec(
            kycBoardingRec instanceof Object && {
              ...kycBoardingRec,
              cac,
              article,
              others,
              businessId: business?.id,
            }
          )
        );
        continueEvent();
        setIsLoading(false);
        return;
      } else {
        await api.post(`/business/${business?.id}/kyc`, {
          type: "GATEWAY",
          uploadMeta: {
            cac,
            article,
            others,
          },
        });
        let busList = await api.get("/client/business");
        if (busList?.payload instanceof Array) {
          let findBusiness = busList?.payload.find(
            (el: any) => business?.id === el?.id
          );
          if (findBusiness) {
            findRecord =
              findBusiness?.BusinessKyc instanceof Array &&
              findBusiness?.BusinessKyc.find(
                (el: any) => el?.type.toLowerCase() === "gateway"
              );
            let businessKycId = findRecord?.id;

            res = await api.patch(
              `/business/${businessId}/kyc/${businessKycId}`,
              {
                step: 2,
                status: "APPROVED",
                uploadMeta: {
                  cac,
                  article,
                  others,
                },
              }
            );
            toast.success(res.message);
            await dispatch(
              setKycBoardingRec(
                kycBoardingRec instanceof Object && {
                  ...kycBoardingRec,
                  cac,
                  article,
                  others,
                  businessId: business?.id,
                }
              )
            );
            continueEvent();
            setIsLoading(false);
            return;
          }
        }

        consoleLog("Postresmessage");
        toast.error(
          "Unable to to upload documentation, Kyc documentation not properly done."
        );
      }
    } catch (error) {
      // @ts-ignore
      toast.error(error);
      setIsLoading(false);
    }
  };

  const handleCacUpload = async (files: any) => {
    // // console.log("selected file", files[0]);
    if (files[0].size > 10000000) {
      return toast.error(
        "Please slected file should have a maximum size of 10MB."
      );
    }

    if (!updateUploadedFiles(files[0], "cac")) return false;

    try {
      setIsUploading(true);

      let formData = new FormData();
      formData.append("certificate", files[0]);
      const response: any = await uploadFile(formData);
      if (response.status) {
        //// console.log(response);
        const cacUrl = response.payload?.fileUrl;
        // omniSaver("cac", cacUrl);
        setCAC(cacUrl);
        setIsUploading(false);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      // @ts-ignore
      toast.error(error);
      setIsUploading(false);
      // consoleLog(error.toString());
    }
  };

  const handleArticleUpload = async (files: any) => {
    if (files[0].size > 10000000) {
      return toast.error(
        "Please slected file should have a maximum size of 10MB."
      );
    }
    if (!updateUploadedFiles(files[0], "article")) return false;
    try {
      setIsUploadingArticle(true);
      let formData = new FormData();
      formData.append("certificate", files[0]);
      const response = await uploadFile(formData);
      if (response.status) {
        const article = response.payload?.fileUrl;
        // omniSaver("article", article);
        setArticle(article);
        setIsUploadingArticle(false);
      }
    } catch (error) {
      // @ts-ignore
      toast.error(error);
      setIsUploadingArticle(false);
      // consoleLog(error.toString());
    }
  };

  const handleAddresseUpload = async (files: any) => {
    if (files[0].size > 10000000) {
      return toast.error(
        "Please slected file should have a maximum size of 10MB."
      );
    }
    if (!updateUploadedFiles(files[0], "address")) return false;
    try {
      setIsUploadingAddress(true);
      let formData = new FormData();
      formData.append("certificate", files[0]);
      const response = await uploadFile(formData);
      if (response.status === 200) {
        const address = response.payload?.fileUrl;
        // omniSaver("proof_address", address);
        setAddress(address);
        setIsUploadingAddress(false);
      }
    } catch (error) {
      // @ts-ignore
      toast.error(error);
      setIsUploadingAddress(false);
      // consoleLog(error.toString());
    }
  };

  const handleOthersUpload = async (files: any) => {
    if (files[0].size > 10000000) {
      return toast.error(
        "Please slected file should have a maximum size of 10MB."
      );
    }
    if (!updateUploadedFiles(files[0], `others${others.length}`)) return false;

    try {
      setIsUploadingOthers(true);
      let formData = new FormData();
      formData.append("certificate", files[0]);
      const response = await uploadFile(formData);
      if (response.status) {
        const otherUploadDoc = response.payload?.fileUrl;
        let newUpload: any = [...others, otherUploadDoc];
        // omniSaver("other_Upload_Doc", newUpload);
        setOthers(newUpload);
        setIsUploadingOthers(false);
      }
    } catch (error) {
      // @ts-ignore
      toast.error(error);
      setIsUploadingOthers(false);
      // consoleLog(error.toString());
    }
  };

  const deleteItem = (id: number) => {
    const filteredArr = others.filter((cur: any, index: any) => index !== id);
    omniSaver("other_Upload_Doc", filteredArr);
    setOthers(filteredArr);
  };

  const continueEvent = () => {
    history.push("/kyc/complete");
  };

  const goBack = () => {
    history.push("/kyc/add-account-details");
  };

  const close = () => {
    history.push("/overview");
  };

  let urlAddress = history?.location?.pathname;

  useEffect(() => {
    if (article && others && cac) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [article, others, cac]);

  return (
    <ParentCont>
      <LeftPanel>
        <FormCont>
          <TitleCont>
            {urlAddress !== kycStart && (
              <Back onClick={goBack}>
                <Icon type={"chevron-left"} size={20} />
              </Back>
            )}
            <FormTitle>Upload Documents</FormTitle>
          </TitleCont>

          <FormSubTitle>
            With your corporate account number, you are guaranteed of your
            settlement.
          </FormSubTitle>

          <Form
            enableReinitialize
            initialValues={initialValues}
            validations={{
              accountNumber: Form.is.required(),
              bankName: Form.is.required(),
              accountName: Form.is.required(),
            }}
            onSubmit={onSubmit}
            validate={() => {}}
            validateOnBlur={false}
          >
            <>
              <FormElement>
                <UploadContainer>
                  <UploadLabel>CAC Certificate</UploadLabel>
                  <input
                    type="file"
                    onChange={(e) => handleCacUpload(e.target.files)}
                    name="cac"
                    id="cac"
                    disabled={isUploading}
                    hidden
                  />
                  <UploadBox>
                    <Icon
                      type={"upload"}
                      color={"#760000"}
                      style={{ marginRight: 10 }}
                    />
                    <UploadButton htmlFor="cac">
                      {isUploading
                        ? "Loading"
                        : !cac
                        ? "Click to Add files"
                        : "Change file"}
                    </UploadButton>
                  </UploadBox>
                  {error.cac && (
                    <FieldError>
                      Uploading of necessary documents needed for validation
                    </FieldError>
                  )}
                </UploadContainer>
                {cac && (
                  <UploadInput>
                    <UploadedFile>{shortenUrl(cac)}</UploadedFile>

                    <TrashBtn onClick={() => setCAC("")}>
                      <Icon type={"trash"} color={"black"} />
                    </TrashBtn>
                  </UploadInput>
                )}

                <UploadContainer>
                  <UploadLabel>
                    Memorandum and Article of Association
                  </UploadLabel>
                  <input
                    type="file"
                    onChange={(e) => handleArticleUpload(e.target.files)}
                    name="article"
                    id="article"
                    disabled={isUploadingArticle}
                    hidden
                  />

                  <UploadBox>
                    <Icon
                      type={"upload"}
                      color={"#760000"}
                      style={{ marginRight: 10 }}
                    />
                    <UploadButton htmlFor="article">
                      {isUploadingArticle
                        ? "Loading"
                        : !article
                        ? "Click to Add files"
                        : "Change file"}
                    </UploadButton>
                  </UploadBox>
                  {error.article && (
                    <FieldError>
                      Uploading of necessary documents needed for validation
                    </FieldError>
                  )}
                </UploadContainer>
                {article && (
                  <UploadInput>
                    <UploadedFile>{shortenUrl(article)}</UploadedFile>

                    <TrashBtn onClick={() => setArticle("")}>
                      <Icon type={"trash"} color={"black"} />
                    </TrashBtn>
                  </UploadInput>
                )}

                <UploadContainer>
                  <UploadLabel>
                    Other Documents <br />
                    Please provided Int'l Passport, Drivers License or Voters
                    Card
                  </UploadLabel>
                  <input
                    type="file"
                    onChange={(e) => handleOthersUpload(e.target.files)}
                    name="others"
                    id="others"
                    disabled={isUploadingOthers}
                    hidden
                  />
                  <UploadBox>
                    <Icon
                      type={"upload"}
                      color={"#760000"}
                      style={{ marginRight: 10 }}
                    />
                    <UploadButton htmlFor="others">
                      {isUploadingOthers ? "Loading" : "Click to Add files"}
                    </UploadButton>
                  </UploadBox>
                  {error.others && (
                    <FieldError>
                      Uploading of necessary documents needed for validation
                    </FieldError>
                  )}
                </UploadContainer>

                {others.map((doc: any, index: number) => {
                  return (
                    <UploadInput key={index}>
                      <UploadedFile>{shortenUrl(doc)}</UploadedFile>

                      <TrashBtn onClick={() => deleteItem(index)}>
                        <Icon type={"trash"} color={"black"} />
                      </TrashBtn>
                    </UploadInput>
                  );
                })}
              </FormElement>
              <Selectors>
                <ActionButton
                  onClick={onSubmit}
                  // onClick={continueEvent}
                  type="submit"
                  variant="primary"
                  isWorking={isLoading}
                  disabled={isDisabled}
                >
                  {checkExistingKycBoarding(
                    `cac,article,others`,
                    kycBoardingRec
                  )
                    ? "Update"
                    : "Save"}{" "}
                  and Continue
                </ActionButton>
              </Selectors>
            </>
          </Form>
        </FormCont>
      </LeftPanel>

      <MiddlePanel>
        <PageIndicator pageArr={GatewayPages} page={2} />
      </MiddlePanel>

      <RightPanel>
        <RightPanelAuxCont>
          <CloseBtn onClick={close}>
            <Icon type={"close"} size={20} />
          </CloseBtn>
        </RightPanelAuxCont>

        <RightPanelAuxCon2>
          <DescriptionBox>
            <DescriptionTitle>Gateway</DescriptionTitle>

            <DescriptionText>
              Enjoy, quick, and secure way to send and receive money.
            </DescriptionText>

            <DescriptionBtn
              onClick={() =>
                window.open(
                  "https://medium.com/@fountainpay",
                  "noopener,noreferrer"
                )
              }
            >
              Learn more
            </DescriptionBtn>
          </DescriptionBox>
        </RightPanelAuxCon2>
      </RightPanel>
    </ParentCont>
  );
}

export default UploadDocuments;
