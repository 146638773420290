import { AnyNaptrRecord } from "dns";
import consoleLog from "./consoleLog";

let duties: any = {
  VTrans: "View Transaction",
  VServ: "View services",
  VPaySplitSub: "View splits and Sub-account",
  ManAPI: "Manage API keys",
  CPayLnkSub: "Create a Payment link and subscription",
  VTeams: "View Teams",
  CPaySplitSub: "Create splits and sub-account",
  CServ: "Create Service",
  CTeams: "Create a Team",
  CBankDet: "Add bank details",
  CSettings: "Change any settings",
  DSummary: "Download Summary",
  MDisputes: "Manage disputes",
  VCustomers: "View customers",
  VSettlement: "View settlement",
  // ITeam: "Can invite a team",
};

const roleDuties = [
  { role: "ADMIN", duties: "*" },
  { role: "OWNER", duties: "*" },
  { role: "CO_OWNER", duties: "*" },
  {
    role: "DEVELOPER_SUPPORT",
    duties: [
      "VTrans",
      "VServ",
      "VPaySplitSub",
      "ManAPI",
      "CPayLnkSub",
      "VTeams",
      "CPaySplitSub",
    ],
  },
  { role: "CUSTOMER_SUPPORT", duties: ["VTrans", "MDisputes", "VCustomers"] },
  { role: "CO_OWNER", duties: ["VSettlement", "VTeams", "CTeams", "VTrans", "MDisputes", "VCustomers", "VServ",] },
];

export const roles = roleDuties.map((el: any) => el.role);

export const getRoleName = (user: any, businessId: string) => {
  let findObj: any =
    user?.businesses instanceof Array &&
    user?.businesses.find((business: any) => business.id === businessId);
  if (findObj) {
    // alert(findObj.role)
    return findObj.role ?? "";
  }
  return "";
};

const shouldPerform: any = (roleName?: string, duty?: string) => {
  // return true;
  // consoleLog(roleName,"roleName")
  if (!roleName || !duty ) return false;
  // || !duties[duty]
  let theRole: any = roleDuties.find(
    (el: any) => el.role.trim().toLowerCase() === roleName.trim().toLowerCase()
  );
  
  //// console.log(JSON.stringify(theRole))
  if (!theRole) return false;
  if (theRole?.duties === "*") return true;
  if (duty.indexOf(",") >-1) {
    let dutyArr = duty.split(",");
    let truthValue = true
    for(let el of dutyArr) {
        truthValue &&= shouldPerform(roleName,el)
    }    
    return truthValue
  }else{
    
    let theDuty = theRole.duties instanceof Array && theRole.duties.find(
      (el: any) => el.trim().toLowerCase() === duty.trim().toLowerCase()
    );
    return theDuty ? true : false;
  }
 
};

export default shouldPerform;
