import { useEffect, useState } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { Form, GradientLoader } from "shared/components";
import Preloader from "shared/components/preloader";
import api from "shared/utils/api";
import consoleLog from "shared/utils/consoleLog";
import toast from "shared/utils/toast";
import {
  PageLayout,
  PageTitleCont,
  PageTitle,
  FormCont,
  AuxFormCont,
  ActionButton,
  ParentFormCont,
} from "./Styles";

function AccountRecovery() {
  // const questionData = useSelector(
  //   (store: RootStateOrAny) => store.questionData
  // );
  const [questions, setQuestions] = useState<any>([]);
  const [quesOne, setQuestOne] = useState<string>("");
  const [quesTwo, setQuesTwo] = useState<string>("");
  const [ansOne, setAnsOne] = useState<string>("");
  const [ansTwo, setAnsTwo] = useState<string>("");
  // const { loading, error, data } = questionData
  let loading = false;
  let data: any = [];
  let error = null;

  const [filteredQues1, setFilteredQues1] = useState<any>(data);
  const [filteredQues2, setFilteredQues2] = useState<any>(data);
  const [isLoading, setIsLoading] = useState<Boolean>(false);
  const [firstTime, setFirstTime] = useState<boolean>(true);

  const initialValues = {
    quesOne,
    quesTwo,
    ansOne,
    ansTwo,
  };
  const filterQuestions = (pointer: string, question: string) => {
    if (pointer === "ques1") {
      const arr = data.filter((cur: any) => cur.question !== question);
      consoleLog(arr);
      // setFilteredQues2(arr);
    } else {
      const arr = data.filter((cur: any) => cur.question !== question);
      // setFilteredQues1(arr);
      //
    }
  };

  const onSubmit = async () => {
    consoleLog({
      firstQuestion: quesOne,
      firstResponse: ansOne,
      secondQuestion: quesTwo,
      secondResponse: ansTwo,
    });
    setIsLoading(true);
    try {
      const res = await api.post("/account/security-question-response", {
        firstQuestion: quesOne,
        firstResponse: ansOne,
        secondQuestion: quesTwo,
        secondResponse: ansTwo,
      });
      toast.success(res?.message);
      // consoleLog('ques res ', res);
      setIsLoading(false);
    } catch (error) {
      // @ts-ignore
      toast.error(error);
      setIsLoading(false);
      // consoleLog('ques error', error)
      //   @ts-ignore
      toast.error(error);
    }
  };

  const updateQuestion = async () => {
    consoleLog({
      firstQuestion: quesOne,
      firstResponse: ansOne,
      secondQuestion: quesTwo,
      secondResponse: ansTwo,
    });
    setIsLoading(true);
    try {
      const res = await api.put("/account/security-question-response", {
        firstQuestion: quesOne,
        firstResponse: ansOne,
        secondQuestion: quesTwo,
        secondResponse: ansTwo,
      });
      toast.success(res?.message);
      // consoleLog('ques res ', res);
      setIsLoading(false);
    } catch (error) {
      // @ts-ignore
      toast.error(error);
      setIsLoading(false);
      // consoleLog('ques error', error)
      //   @ts-ignore
      toast.error(error);
    }
  };

  consoleLog(quesTwo, quesOne, "kkd");

  const questionOptions = questions.map((el: any) => ({
    value: el.question,
    label: el.question,
  }));

  const questionOptions1 = questionOptions.filter(
    (el: any) => el.value !== quesTwo
  );
  const questionOptions2 = questionOptions.filter(
    (el: any) => el.value !== quesOne
  );

  consoleLog(questionOptions, "questionOptions");

  const categoryTwo = Object.values(filteredQues2).map((question: any) => ({
    value: question.question,
    label: question.question,
  }));

  const getQuestions = async () => {
    try {
      const resp = await api.get("/account/security-question");
      consoleLog(resp, "respp");
      if (resp?.status === true && resp?.payload instanceof Array) {
        setQuestions(resp?.payload);
        await getQuestionsAnswered();
      }
    } catch (error) {
      consoleLog(error);
    }
  };

  const getQuestionsAnswered = async () => {
    try {
      const resp = await api.get("/account/security-question-response");
      if (resp?.status === true && resp?.payload instanceof Object) {
        setQuestOne(resp?.payload?.firstQuestion);
        setQuesTwo(resp?.payload?.secondQuestion);
        setAnsOne(resp?.payload?.firstResponse);
        setAnsTwo(resp?.payload?.secondResponse);
        setFirstTime(false)
      }
      setFirstTime(true)
      consoleLog(resp, "resAns");
    } catch (error) {
      consoleLog(error);
    }
  };

  useEffect(() => {
    getQuestions();
    // getQuestionsAnswered();
  }, []);

  if (loading || isLoading) {
    return <GradientLoader />;
  } else if (error !== null) {
    toast.error(error);
  }

  return (
    <PageLayout>
      <PageTitleCont>
        <PageTitle>Add Account Recovery Details</PageTitle>
      </PageTitleCont>

      <Form
        enableReinitialize
        initialValues={initialValues}
        validations={{
          quesOne: Form.is.required(),
          quesTwo: Form.is.required(),
          ansOne: Form.is.required(),
          ansTwo: Form.is.required(),
        }}
        onSubmit={onSubmit}
        validate={() => {}}
        validateOnBlur={false}
      >
        <ParentFormCont>
          <FormCont>
            <AuxFormCont>
              <Form.Field.Select
                name="quesOne"
                label="Question 1"
                options={questionOptions1}
                onChange={(e: any) => {
                  setQuestOne(e);
                  // filterQuestions("ques1", e);
                }}
                value={quesOne}
                style={{ backgroundColor: "#fff" }}
              />

              <Form.Field.Input
                name="ansOne"
                label="Answer 1"
                value={ansOne}
                onChange={(e: any) => setAnsOne(e)}
                style={{ backgroundColor: "#fff" }}
              />
            </AuxFormCont>

            <AuxFormCont>
              <Form.Field.Select
                name="quesTwo"
                label="Question 2"
                value={quesTwo}
                options={questionOptions2}
                onChange={(e: any) => {
                  setQuesTwo(e);
                  // filterQuestions("ques2", e);
                }}
                style={{ backgroundColor: "#fff" }}
              />

              <Form.Field.Input
                name="ansTwo"
                label="Answer 2"
                value={ansTwo}
                onChange={(e: any) => setAnsTwo(e)}
                style={{ backgroundColor: "#fff" }}
              />
            </AuxFormCont>
          </FormCont>

          <ActionButton
            onClick={() => firstTime ? onSubmit() : updateQuestion()}
            type="submit"
            variant="primary"
            // isWorking={isloading}
          >
            Save
          </ActionButton>
        </ParentFormCont>
      </Form>
    </PageLayout>
  );
}

export default AccountRecovery;
