import styled from "styled-components";

import { color, font } from "shared/utils/styles";

export const StyledField = styled.div`
  margin-top: 20px;
`;

export const FieldLabel = styled.label`
  display: block;
  // padding-bottom: 5px;
  padding-bottom: 0;
  color: ${color.textMedium};
  ${font.medium}
  ${font.size(13)}
  text-align: left;
`;

export const FieldTip = styled.div`
  padding-top: 6px;
  color: ${color.textMedium};
  ${font.size(12.5)}
`;

export const FieldError = styled.div`
  margin-top: 6px;
  line-height: 1;
  color: ${color.danger};
  ${font.medium}
  ${font.size(12.5)}
`;

export const InputGroup = styled.div`
  display: flex;
  align-items: center;
`;

export const ChildLabel = styled.div`
  font-size: 14px;
  padding: 0px 20px;
  border: 1px solid #dfe1e6;
  border-radius: 5px 0px 0px 5px;
  height: 54px;
  padding-top: 18px;
  margin-top: -10px;
`;

export const ChildLabelSelect = styled.div`
  font-size: 14px;
  padding: 0;
  border: 1px solid #dfe1e6;
  border-right: 0;
  border-radius: 5px 0px 0px 5px;
  height: 54px;
  // padding-top: 18px;
  margin-top: -10px;
`;
