import { useEffect, useState } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { Modal } from "shared/components";
import AddBankAccount from "../BankAccount/Modal";
import EditBankAccount from "../BankAccount/Modal/Edit";

import { GradientLoader } from "shared/components";

import EmptyState from "shared/components/FreeComp/EmptyState";
import {
  ContentHeader,
  ContentHeaderContainer,
  PageLayout,
  Sectionbutton,
  SectionHeader,
  SectionHeaderLabel,
} from "shared/section_components/Styles";
import api from "shared/utils/api";
import AccountRow from "./AccountRow";
import consoleLog from "shared/utils/consoleLog";

type AccountTypeProp = {
  acct: {
    bankname: string;
    accountname: string;
    accountnumber: string;
    currency: string;
  };
};

export default function BankAccount() {
  const [accounts, setaccounts] = useState([]);
  const [editData, setEditData] = useState({});
  const [modal, setModal] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const business = useSelector((store: RootStateOrAny) => store.business);
  const [isLoading, setisLoading] = useState(false);
  // const [visible, setVisible] = useState(false);

  const openModal = () => {
    setModal(!modal);
  };

  const openEditModal = () => {
    setModalEdit(!modalEdit);
  };

  const handleRemoveAccount = (id: any) => {
    const newlist = accounts.filter((acc: any) => acc.id !== id);
    setaccounts(newlist);
  };

  const handleEditAccount = (accountId: any) => {
    setEditData(accountId);
    setModalEdit(!modalEdit);
  };

  const accountLists = accounts.map((account: any) => (
    <AccountRow
      key={account.id}
      acct={account}
      removeAccount={handleRemoveAccount}
      editAccount={handleEditAccount}
    />
  ));

  const getBankAccounts = async () => {
    setisLoading(true);
    try {
      const res = await api.get(
        `/client/business/${business.id}/bank-accounts`
      );
      // consoleLog("BANK ACCOUNTS:", res.payload);
      setaccounts(res.payload);
      setisLoading(false);
    } catch (error) {
      setisLoading(false);
      consoleLog(error);
    }
  };

  useEffect(() => {
    getBankAccounts();
  }, [business]);

  return (
    <>
      <PageLayout>
        <SectionHeader>
          <SectionHeaderLabel>
            {accounts.length > 1
              ? `${accounts.length} Bank Accounts`
              : `${accounts.length} Bank Account`}
          </SectionHeaderLabel>
          {/* {modal && <Modal openModal={openModal} />} */}
          <Sectionbutton onClick={openModal}>
            Add New Bank Account
          </Sectionbutton>
          <Modal
            isOpen={modal}
            width={800}
            withCloseIcon={true}
            onClose={openModal}
            overFlow={true}
            containerWidth={false}
            renderContent={() => (
              <AddBankAccount
                openModal={openModal}
                fetchData={getBankAccounts}
              />
            )}
          />
          <Modal
            isOpen={modalEdit}
            width={800}
            withCloseIcon={true}
            onClose={openEditModal}
            overFlow={true}
            containerWidth={false}
            renderContent={() => (
              <EditBankAccount
                openModal={openEditModal}
                fetchData={getBankAccounts}
                accountId={editData}
              />
            )}
          />
        </SectionHeader>

        {isLoading ? (
          <GradientLoader />
        ) : (
          <div>
            {accounts.length < 1 ? (
              <EmptyState
                openModal={openModal}
                stateTitle="No Bank Account"
                statePara=""
                stateBtnText="Add New Bank Account"
              />
            ) : (
              <div
                style={{
                  marginTop: "2rem",
                  overflowX: "auto",
                }}
              >
                <table
                  cellSpacing="0"
                  cellPadding="0"
                  style={{
                    width: "100%",
                    border: "none",
                  }}
                >
                  <ContentHeaderContainer>
                    <ContentHeader>Bank Name</ContentHeader>
                    <ContentHeader>Account Number</ContentHeader>
                    <ContentHeader>Account Name</ContentHeader>
                    <ContentHeader>Currency</ContentHeader>
                    <ContentHeader></ContentHeader>
                    <ContentHeader></ContentHeader>
                  </ContentHeaderContainer>
                  {accountLists}
                </table>
              </div>
            )}
          </div>
        )}
      </PageLayout>
    </>
  );
}
