import Overview from "./Overview";
import { AgencyPage } from "./Styled";
import Transactions from "./Transactions";
import Geolocation from "./Geolocation";
import AgencyAd from "./Ad";

import React, { useEffect, useState } from "react";
import {
  Route,
  Redirect,
  useRouteMatch,
  Router,
  Switch,
  useHistory,
} from "react-router-dom";
import useApi from "shared/hooks/api";
import { useLocation } from "react-router-dom";
import history from "browserHistory";
import { GradientLoader, PageError } from "shared/components";
import Subagent from "./Subagent";
import Inventory from "./Inventory/inventory";
import { RootStateOrAny, useSelector } from "react-redux";
import AddSubAgent from "./Subagent/AddSubAgent";
import AgentDetail from "./Subagent/AgentDetail";
import Pricing from "./Pricing";
import Terminal from "./Geolocation/terminal/terminal";
import InventoryRoutes from "./Inventory/module-routes";

const Agency = () => {
  const [business, setbusiness] = useState<any>();

  const match = useRouteMatch();
  const location = useLocation();
  const history = useHistory();

  const currentBusiness = useSelector(
    (store: RootStateOrAny) => store.business
  );

  const KycTypeGuard = (intendedComponent: any, kycType: string) => {
    if (business?.BusinessKyc?.length === 1) {
      if (
        business?.BusinessKyc &&
        business?.BusinessKyc[0] &&
        business?.BusinessKyc[0]?.type === kycType
      ) {
        return intendedComponent;
      } else {
        return AgencyAd;
      }
    } else {
      return intendedComponent;
    }
  };

  // let title = [];
  // let page =
  //   location.pathname.split("/")[2] === undefined
  // ? location.pathname.split("/")[1]
  //     : location.pathname.split("/")[2];
  // title[0] = page.charAt(0).toUpperCase();
  // title[1] = page.slice(1, page.length);
  // let Title = title.join("");

  useEffect(() => {
    setbusiness(currentBusiness);
  }, [currentBusiness]);

  return (
    <>
      <AgencyPage>
        <Router history={history}>
          <Switch>
            <Redirect exact from="/" to="/overview" />
            <Route path="/agency/overview" component={Overview} />
            <Route
              path="/agency/transactions"
              component={KycTypeGuard(Transactions, "AGENCY")}
            />
            <Route
              path="/agency/agents"
              component={KycTypeGuard(Subagent, "AGENCY")}
            />

            <Route
              path="/agency/transaction/:id"
              component={KycTypeGuard(Subagent, "AGENCY")}
            />
            <Route
              path="/agency/inventory"
              component={KycTypeGuard(InventoryRoutes, "AGENCY")}
            />

            <Route
              path="/agency/geolocation"
              component={KycTypeGuard(Geolocation, "AGENCY")}
            />
            <Route
              path="/agency/terminal"
              component={KycTypeGuard(Terminal, "AGENCY")}
              exact
            />

            <Route
              path="/agency/pricing"
              component={KycTypeGuard(Pricing, "AGENCY")}
            />
            <Route component={PageError} />
          </Switch>
        </Router>
      </AgencyPage>
    </>
  );
};

export default Agency;
