import Icon from "shared/components/Icon";
import breakpoints from "shared/utils/breakpoints";
import { color, mixin } from "shared/utils/styles";
import styled from "styled-components";

type PropT = {
  variant: string;
};

export const Overlay = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
`;

export const Modal = styled.div`
  // position: fixed;
  z-index: 20;
  background: #fff;
  width: 700px;
  border-radius: 20px;
  /* Center the modal */
  // top: 50%;
  // left: 50%;
  // transform: translateX(-50%) translateY(-50%);
  box-shadow: 0px 4px 100px rgba(0, 0, 0, 0.1);
  @media ${breakpoints.device.max.xs} {
    width: 100%;
    top: 0;
    left: 0;
    transform: none;
    position: relative;
    border-radius: 0;
  }
`;

export const Header = styled.div`
  // background: orangered;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
`;
export const Footer = styled.div`
  background: orangered;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
`;
export const Main = styled.div`
  padding: 20px;
`;

export const CloseButton = styled(Icon)<PropT>`
  position: absolute;
  font-size: 25px;
  color: ${color.textMedium};
  transition: all 0.1s;
  ${mixin.clickable}
`;
// @ts-ignore
// ${props => closeIconStyles[props.variant]}
