import breakpoints from "shared/utils/breakpoints";
import styled, { css } from "styled-components";

type Props = {
  width?: string;
  backgroundColor?: string;
};
export const Dropdown = styled.div`
  position: relative;
`;

export const ServiceActionBtn = styled.button<Props>`
  display: flex;
  width: 194px;
  /* height: 54px;
  padding: 10px; */
  padding: 0.8rem 0.5rem;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  background: var(--fountain-color-primary, #760000);
  color: var(--primary-white, #fff);
  /* font-size: 14px; */
  font-size: 0.85rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 5px;
  margin-right: 0px;
  transition: all ease 800ms;
  cursor: pointer;
  ${(props) =>
    props.backgroundColor &&
    css`
      background: var(--fountain-color-primary, ${props.backgroundColor});
      color: var(--primary-white, #000);
      border-radius: 5px;
      border: 1px solid var(--grey-border-1, rgba(215, 215, 215, 0.6));
    `}

  ${(props) =>
    props.width &&
    css`
      width: ${props.width};
      margin-bottom: 5px;
      margin-right: 10px;
    `}

    :hover {
    background: #86221e;
    color: #fff;
  }
`;

export const Menu = styled.ul`
  position: absolute;
  list-style-type: none;
  margin: 1px 0;
  padding: 0;
  display: block;
  width: 194px;
  padding: 0px 0px;
  padding-left: 0px;
  align-items: center;
  gap: 10px;
  border: 1px solid var(--grey-border-1, #d7d7d7);
  background: var(--primary-white, #fff);
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);

  & > li {
    margin: 0;
    background-color: white;
    display: flex;
    padding: 10px;

    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-bottom: 1px solid var(--grey-border-1, #d7d7d7);
    background: var(--primary-white, #fff);
    transition: all ease 800ms;
    font-size: 0.88rem;
  }

  & > li:last-child {
    border-bottom: 0px solid var(--grey-border-1, #d7d7d7);
  }

  & > li:hover {
    background-color: #e7e7ea;
    color: #757579;
  }

  & > li > button {
    width: 100%;
    height: 100%;
    text-align: left;

    background: none;
    color: inherit;
    border: none;
    padding: 5px;
    margin: 0;
    font: inherit;
    cursor: pointer;
  }
`;
