import React, { useEffect, useState } from "react";
import { Button, Form, Icon } from "shared/components";
import {
  ActionButton,
  ComponentBody,
  ComponentFooter,
  FormElement,
  StyledLink,
  Title,
  PasswordCont,
} from "./Styles";
import toast from "shared/utils/toast";
import { useHistory } from "react-router-dom";
import api from "shared/utils/api";
import Preloader from "shared/components/preloader";
import * as Yup from "yup";
import PasswordMeter from "../PasswordMeter";
import { getTempId, holdPass, storeTempId } from "shared/utils/authToken";
import { authPath } from "apiServices/paths";

const Schema = Yup.object().shape({
  password: Yup.string().required("Please Enter your password"),
  confirmPassword: Yup.string()
    .required()
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
});

const SetPassword = () => {
  const [isLoginIn, setIsLoginIn] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordVisibleA, setPasswordVisibleA] = useState(false);
  const [passwordVisibleB, setPasswordVisibleB] = useState(false);
  const history = useHistory();

  const initlValues = {
    password: "",
    confirmPassword: "",
  };

  const onSubmit = async (value: any, form: any) => {
    holdPass(password);
    setIsLoginIn(true);
    let tempId: any = getTempId();
    const values: any = {
      tempId,
      password,
    };

    try {
      const { payload }: any = await api.post("", {
        ...values,
      });

      storeTempId(payload.tempId);
      setIsLoginIn(false);
      history.push(authPath.PASSWORD_SUCCESS);
    } catch (error:any) {
      toast.error(error ?? "Something went wrong");
      setIsLoginIn(false);
    }
  };

  const handleKeyPressEvent = (value: any) => {
    setPassword(value);
  };

  return (
    <ComponentBody>
      <Title>SET YOUR NEW PASSWORD</Title>
      {/* @ts-ignore */}
      <Form
        enableReinitialize
        initialValues={initlValues}
        validationSchema={Schema}
        onSubmit={onSubmit}
      >
        <FormElement>
          <PasswordCont>
            <Form.Field.Input
              name="password"
              type={!passwordVisibleA ? "password" : "text"}
              label="Password"
              onChange={handleKeyPressEvent}
              // tip="Kindly enter your user email address."
            />

            <Icon
              onClick={() => setPasswordVisibleA(!passwordVisibleA)}
              type={!passwordVisibleA ? "eye" : "eye-blocked"}
              style={{
                color: "#760000",
                fontSize: 18,
                position: "absolute",
                right: 5,
                top: 40,
              }}
            />
          </PasswordCont>

          <PasswordCont>
            <Form.Field.Input
              name="confirmPassword"
              type={!passwordVisibleB ? "password" : "text"}
              label="Confirm Password"
              // tip="Kindly enter your user password."
            />

            <Icon
              onClick={() => setPasswordVisibleB(!passwordVisibleB)}
              type={!passwordVisibleB ? "eye" : "eye-blocked"}
              style={{
                color: "#760000",
                fontSize: 18,
                position: "absolute",
                right: 5,
                top: 40,
              }}
            />
          </PasswordCont>

          <ActionButton type="submit" variant="primary" isWorking={isLoginIn}>
            Set Password
          </ActionButton>
        </FormElement>
      </Form>
      <PasswordMeter password={password} />
    </ComponentBody>
  );
};

export default SetPassword;
