import Layout from "Layout";
import RadioButton from "shared/components/RadioButton";
import SharedCheckList from "shared/section_components/SharedCheckList";
import { Divider, PageLayout } from "shared/section_components/Styles";
import {
  OptionsContainer,
  PreferenceContainer,
  SaveButton,
  SectionContainer,
  SectionTitle,
} from "./Styles";
import consoleLog from "shared/utils/consoleLog";

export default function AccountSettings() {
  const handleChange = (e: any) => {
    consoleLog("do soomething");
  };

  const accountSettings = [
    {
      id: 1,
      field: "Who should pay the transaction fees?",
      options: {
        Customers: false,
        "Charge me for the transaction": false,
      },
    },
  ];

  return (
    ////<Layout PageTitle="Account Settings">
      <PageLayout>
        <div style={{ marginTop: "2rem" }}>
          <SectionContainer>
            <PreferenceContainer>
              <SectionTitle>Who should pay the transaction fees?</SectionTitle>
              <OptionsContainer onChange={handleChange}>
                <RadioButton
                  label="Customers"
                  value="customer"
                  id="inside"
                  group="transaction fee"
                />

                <RadioButton
                  label="Charge me for the transaction fees"
                  value="me"
                  id="outside"
                  group="transaction fee"
                />
              </OptionsContainer>
            </PreferenceContainer>
            <SaveButton>Save</SaveButton>
          </SectionContainer>

          <SectionContainer>
            <PreferenceContainer>
              <SectionTitle>Transaction Emails</SectionTitle>
              <OptionsContainer>
                <div onChange={handleChange}>
                  <SharedCheckList
                    onChange={handleChange}
                    checked={true}
                    label="Email me every transactions"
                    id={4}
                  />
                </div>

                <div onChange={handleChange}>
                  <SharedCheckList
                    onChange={handleChange}
                    checked={true}
                    label="Email custormers every transactions"
                    id={4}
                  />
                </div>
              </OptionsContainer>
            </PreferenceContainer>
            <SaveButton>Save</SaveButton>
          </SectionContainer>

          <SectionContainer>
            <PreferenceContainer>
              <SectionTitle>What method of payment do you want?</SectionTitle>
              <OptionsContainer>
                <SharedCheckList
                  onChange={handleChange}
                  checked={true}
                  label="Enable QR Payment"
                  id={4}
                />

                <SharedCheckList
                  onChange={handleChange}
                  checked={true}
                  label="Enable dashboard payment options"
                  id={4}
                />

                <SharedCheckList
                  onChange={handleChange}
                  checked={true}
                  label="Enable card payment "
                  id={4}
                />
              </OptionsContainer>
            </PreferenceContainer>
            <SaveButton>Save</SaveButton>
          </SectionContainer>

          <SectionContainer>
            <PreferenceContainer>
              <SectionTitle>
                Send notification emails to team members
              </SectionTitle>
              <OptionsContainer onChange={handleChange}>
                <RadioButton
                  label="Send to business email address only"
                  value="business"
                  id="business email only"
                  group="team notification"
                />

                <RadioButton
                  label="Send to all dashboard users"
                  value="dashboard users"
                  id="dashboard users"
                  group="team notification"
                />

                <RadioButton
                  label="Send to specific users only"
                  value="specific users"
                  id="specific users"
                  group="team notification"
                />
              </OptionsContainer>
            </PreferenceContainer>
            <SaveButton>Save</SaveButton>
          </SectionContainer>

          <SectionContainer>
            <PreferenceContainer>
              <SectionTitle>How do you want to get your earnings?</SectionTitle>
              <OptionsContainer onChange={handleChange}>
                <RadioButton
                  label="Settle to bank account"
                  value="bank account"
                  id="bank account"
                  group="getearnings"
                />

                <RadioButton
                  label="Settle to wallet"
                  value="wallet"
                  id="wallet"
                  group="getearnings"
                />
              </OptionsContainer>
            </PreferenceContainer>
            <SaveButton>Save</SaveButton>
          </SectionContainer>

          <SectionContainer>
            <PreferenceContainer>
              <SectionTitle>Two Factor Authentication</SectionTitle>
              <OptionsContainer onChange={handleChange}>
                <RadioButton
                  label="Enable two factor authentication for transfers"
                  value="bank account"
                  id="authentication for transfers"
                  group="authentication for transfers"
                />

                <RadioButton
                  label="Enable two factor authentication for login"
                  value="authlogin"
                  id="authlogin"
                  group="authentication for transfers"
                />
              </OptionsContainer>
            </PreferenceContainer>
            <SaveButton>Save</SaveButton>
          </SectionContainer>

          <SectionContainer>
            <PreferenceContainer>
              <SectionTitle>Transfer Receipts</SectionTitle>
              <OptionsContainer>
                <SharedCheckList
                  onChange={handleChange}
                  checked={false}
                  label="Send email receipts for successful transfers"
                  id={4}
                />
              </OptionsContainer>
            </PreferenceContainer>
            <SaveButton>Save</SaveButton>
          </SectionContainer>

          <SectionContainer>
            <PreferenceContainer>
              <SectionTitle>Subscription Email</SectionTitle>
              <OptionsContainer>
                <SharedCheckList
                  onChange={handleChange}
                  checked={false}
                  label="Allow customers cancel subscription"
                  id={4}
                />
              </OptionsContainer>
            </PreferenceContainer>
            <SaveButton>Save</SaveButton>
          </SectionContainer>

          <SectionContainer>
            <PreferenceContainer>
              <SectionTitle>Balance History</SectionTitle>
              <OptionsContainer>
                <SharedCheckList
                  onChange={handleChange}
                  checked={false}
                  label="Enable balance history for ledger balance"
                  id={4}
                />
              </OptionsContainer>
            </PreferenceContainer>
            <SaveButton>Save</SaveButton>
          </SectionContainer>

          <Divider />
        </div>
      </PageLayout>
    ////</Layout>
  );
}
