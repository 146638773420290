import { useState, useEffect } from "react";
import {
  ParentCont,
  LeftPanel,
  RightPanel,
  RightPanelAuxCont,
  CloseBtn,
  DescriptionBox,
  RightPanelAuxCon2,
  DescriptionTitle,
  DescriptionText,
  DescriptionBtn,
  FormCont,
  TitleCont,
  Back,
  FormTitle,
  FormSubTitle,
  FormElement,
  Selectors,
  ActionButton,
} from "./Styles";
import { Icon, Form } from "shared/components";
import { useHistory } from "react-router-dom";
import { Industries } from "./industries";
import api from "shared/utils/api";
import { omniSaver, storeToLocalStorage } from "shared/utils/authToken";
import toast from "shared/utils/toast";
import PageIndicator from "shared/components/PageIndicator";
import consoleLog from "shared/utils/consoleLog";
import { AgencyBankingPages } from "KYC-refactored/PageList";
import { useDispatch, RootStateOrAny, useSelector } from "react-redux";
import { setBusinessLists } from "store/actions/businessAction";
import { setKycBoardingRec } from "store/actions/boardingAction";
import checkExistingKycBoarding from "shared/utils/checkExistingKycBoarding";
const categoryOptions = Object.values(Industries).map((role) => ({
  value: role,
  label: role,
}));

function BusinessDetails() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const kycBoardingRec = useSelector(
    (store: RootStateOrAny) => store.kycBoardingRec
  );
  const business = useSelector((store: RootStateOrAny) => store.business);

  const dispatch = useDispatch();
  const [regtype, setRegtype] = useState<string>(kycBoardingRec?.regtype ?? "");
  const [rcNumber, setRcNumber] = useState<string>(
    kycBoardingRec?.rcNumber ?? ""
  );
  const [rcBusinessName, setRcBusinessName] = useState<string>(
    kycBoardingRec?.rcBusinessName ?? ""
  );
  const [industryType, setIndustryType] = useState<string>(
    kycBoardingRec?.industryType ?? ""
  );
  const [nameLoading, setNameLoading] = useState<boolean>(false);
  const [loadingCategories, setLoadingCategories] = useState<boolean>(false);
  const [categoryList, setCategoryList] = useState<any>([]);
  const history = useHistory();

  const initialValues = {
    registrationNumber: rcNumber,
    businessName: rcBusinessName,
    industryType: industryType,
    registrationType: regtype,
  };

  const onSubmit = async (values: any, form: any) => {
    omniSaver("businessName", rcBusinessName);
    setIsLoading(true);
    try {
      let resp: any;
      let businessId: any;
      let businessKycId: any;
      if (kycBoardingRec?.businessId) {
        let findRecord =
          business?.BusinessKyc instanceof Array &&
          business?.BusinessKyc.find(
            (el: any) => el?.type.toLowerCase() === "agency"
          );
        if (findRecord?.id) {
          resp = await api.patch(
            `/business/${kycBoardingRec?.businessId}/kyc/${findRecord?.id}`,
            {
              step: 1,
              businessName: values.businessName,
              businessKycType: "AGENCY",
              categoryId: values.industryType,
              registrationType: values.registrationType,
              registrationNumber: values.registrationNumber.toString(),
            }
          );

          toast.success(resp?.message);
          businessId = kycBoardingRec?.businessId;
          businessKycId = findRecord?.id;
        } else {
          toast.error("Unable to update record");
          setIsLoading(false);
          return;
        }
      } else {
        resp = await api.post("/business/", {
          businessName: values.businessName,
          businessKycType: "AGENCY",
          categoryId: values.industryType,
          registrationType: values.registrationType,
          registrationNumber: values.registrationNumber.toString(),
        });
        let { message, payload } = resp;
        toast.success(message);
        businessId = payload.businessId;
        businessKycId = payload.businessKycId;
      }

      // consoleLog(resp, "BusinessDetailsKyc");
      // return;

      omniSaver("business_id", businessId);
      omniSaver("business_kyc_id", businessKycId);
      await dispatch(setBusinessLists([], true));

      await dispatch(
        setKycBoardingRec(
          kycBoardingRec instanceof Object && {
            ...kycBoardingRec,
            rcNumber,
            rcBusinessName,
            industryType,
            regtype,
            businessId: businessId,
            businessKycId,
          }
        )
      );
      continueEvent();
      setIsLoading(false);
    } catch (error) {
      // consoleLog(error);
      // @ts-ignore
      toast.error(error);
      setIsLoading(false);
    }
  };

  const verifyRcNumber = async (value: any) => {
    // console.log("here");
    if (!isNaN(Number.parseInt(value))) {
      value = Number.parseInt(value);
    } else return;
    if (value.toString().length === 7) {
      setRcNumber(value);
      setNameLoading(true);

      const values: any = {
        isRcn: true,
        number: value,
      };

      setIsLoading(true);

      try {
        const { status, message, payload }: any = await api.post(
          "/business/kyc/verify",
          {
            rcNumber: value,
          }
        );
        if (status && payload.rcNumber) {
          setRcNumber(payload.rcNumber);

          setRcBusinessName(payload.companyName);
          setIsLoading(false);
          setNameLoading(false);
          toast.success(message);
        } else {
          setIsLoading(false);
          setNameLoading(false);
          toast.error(message);
          toast.error("Invalid registration number, please try again.");
        }
      } catch (error) {
        setIsLoading(false);
        setNameLoading(false);
        toast.error("Please try again.");
      }
    } else if (value.length < 7) {
      setRcNumber(value);
    }
  };

  const continueEvent = () => {
    history.push("/kyc/residential-information");
  };

  const industryCatgory = (categoryList: any) =>
    categoryList.map((cat: any) => ({ value: cat?.id, label: cat?.name }));

  const getIndustryType = async () => {
    setLoadingCategories(true);
    try {
      const businessList: any = await api.get("/business/categories");
      if (!businessList) {
        toast.warning("No Business List");
        return;
      }
      const { payload } = businessList;

      setCategoryList(payload);
      setLoadingCategories(false);
    } catch (error) {
      setLoadingCategories(false);
      consoleLog(error);
      // @ts-ignore
      toast.error(error);
    }
  };

  useEffect(() => {
    getIndustryType();
    // quickPopulateUI();
  }, []);

  const goBack = () => {
    history.push("/kyc/business-type");
  };

  const close = () => {
    history.push("/overview");
  };

  return (
    <ParentCont>
      <LeftPanel>
        <FormCont>
          <TitleCont>
            <Back onClick={goBack}>
              <Icon type={"chevron-left"} size={20} />
            </Back>
            <FormTitle>Business Details</FormTitle>
          </TitleCont>

          <FormSubTitle>
            With your corporate account number, you are guaranteed of your
            settlement.
          </FormSubTitle>

          <Form
            enableReinitialize
            initialValues={initialValues}
            validations={{
              industryType: Form.is.required(),
              registrationType: Form.is.required(),
              registrationNumber: Form.is.required(),
              businessName: Form.is.required(),
            }}
            onSubmit={onSubmit}
            validate={() => {}}
            validateOnBlur={false}
          >
            <FormElement>
              <Form.Field.Select
                name="registrationType"
                label="Registration Type"
                options={categoryOptions}
                onChange={(e: any) => setRegtype(e)}
              />

              {/* {regtype === "REGISTERED" && ( */}
              <Form.Field.Input
                name="registrationNumber"
                type="number"
                label="Registration Number"
                value={rcNumber}
                onChange={(e: any) => {
                  setRcNumber(e);
                  verifyRcNumber(e);
                }}

                // tip="Kindly enter your user email address."
              />
              {/* )} */}

              <Form.Field.Input
                name="businessName"
                type="text"
                label="Business Name"
                value={nameLoading ? "verifying business..." : rcBusinessName}
                disabled={regtype === "REGISTERED" ? true : false}
                onChange={(e: any) => setRcBusinessName(e)}
                // tip="Kindly enter your user email address."
              />

              <Form.Field.Select
                name="industryType"
                label="Industry Category"
                options={industryCatgory(categoryList)}
                onChange={(e: any) => setIndustryType(e)}
                // tip="Kindly enter your user password."
              />

              <Selectors>
                <ActionButton
                  // onClick={onSubmit}
                  // onClick={continueEvent}
                  type="submit"
                  variant="primary"
                  isWorking={isLoading}
                  // disabled={(regtype || rcBusinessName || rcNumber || industryType) === ""}
                >
                  {checkExistingKycBoarding(
                    `rcNumber,rcBusinessName,industryType,regtype`,
                    kycBoardingRec
                  )
                    ? "Update"
                    : "Create"}{" "}
                  Business
                </ActionButton>
              </Selectors>
            </FormElement>
          </Form>
        </FormCont>
      </LeftPanel>

      <RightPanel>
        <RightPanelAuxCont>
          <CloseBtn onClick={close}>
            <Icon type={"close"} size={20} />
          </CloseBtn>
        </RightPanelAuxCont>

        <RightPanelAuxCon2>
          <DescriptionBox>
            <DescriptionTitle>Agency Banking</DescriptionTitle>

            <DescriptionText>
              Enjoy, quick, and secure way to send and receive money.
            </DescriptionText>

            <DescriptionBtn
              onClick={() =>
                window.open(
                  "https://medium.com/@fountainpay",
                  "noopener,noreferrer"
                )
              }
            >
              Learn more
            </DescriptionBtn>
          </DescriptionBox>
        </RightPanelAuxCon2>
      </RightPanel>
    </ParentCont>
  );
}

export default BusinessDetails;
