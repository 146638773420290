import React, { useState } from 'react'
import { useSelector, RootStateOrAny } from 'react-redux'
import {
	Container,
	FormContainer,
	Image,
	SubTitle,
	TitleCase,
	FormBody,
	ButtonContainer,
	Back,
	ActionButton,
	FormElement,
	ImageContainer,
	SubAgentForm,
	Input,
	InputGroup,
	InputLabel,
	FromGroup,
} from './styles'
import { Form } from 'shared/components'
import RightPic from 'App/assets/images/services/agent.svg'
import api from 'shared/utils/api'
import consoleLog from 'shared/utils/consoleLog'
import toast from 'shared/utils/toast'

const ResetAgent = ({ agentId, setResetModal }: any) => {
	const business = useSelector((store: RootStateOrAny) => store.business)
	const [isLoginIn, setIsLoginIn] = useState(false)
	
	const onReset = async () => {
		if (agentId) {
			setIsLoginIn(true)

			let subAgent = {
				agentId,
				businessId: business.id,
			}
			try {
				const res = await api.post('/agency-banking/reset-agents-password', subAgent)
				// consoleLog('success ', res);
				toast.success(res.message)
				setResetModal(false)
				setIsLoginIn(false)
			} catch (error:any) {
				setIsLoginIn(false)
				//// console.log('err ', error)
				const resMessage = error.message === 'Successfully retrieved business agents' ? 'Error sub agent already exists' : error.message;
				toast.error(`${resMessage}`)
			}
		}
	}

	return (
		<Container>
			<FormContainer>
				<TitleCase>Confirm! </TitleCase>
				
				<FormBody>
					{/* @ts-ignore */}
					<Form
						enableReinitialize
					>
						<FormElement>
						<div>Are you sure you want to reset this agent login password?</div>
						<ButtonContainer>
							<ActionButton
								type="button"
								variant="greyColor"
								onClick={()=>setResetModal(false)}
								>
								Cancel
							</ActionButton>
							<ActionButton
								type="button"
								variant="fountain"
								isWorking={isLoginIn}
								onClick={onReset}
								>
								Reset
							</ActionButton>
						</ButtonContainer>
						</FormElement>
					</Form>
				</FormBody>
			</FormContainer>
		</Container>
	)
}

export default ResetAgent
