import { useEffect, useState } from "react";
import OneTime from "./OneTime";
import {
  PaymentHeaderButton,
  PaymentHeaderLeft,
  PaymentHeaderRight,
  PaymentLinkHeader,
} from "./Styles";
import Subscription from "./Subscription";
import { GradientLoader, Icon } from "shared/components";
import api from "shared/utils/api";
import toast from "shared/utils/toast";
import { RootStateOrAny, useSelector } from "react-redux";
import shouldPerform, { getRoleName } from "shared/utils/assignRoles";
import { useHistory, useParams } from "react-router-dom";
import consoleLog from "shared/utils/consoleLog";

type PropT = {
  edit?: boolean;
  payLinkId?: string;
};
const PaymentLink = ({ edit, payLinkId }: PropT) => {
  const [selectedTab, setSelectedTab] = useState("one-time");
  const [loading, setLoading] = useState(true);
  const service = useSelector((store: RootStateOrAny) => store.service);
  consoleLog(service, "service");
  const [formstate, setFormstate] = useState({} as any);
  const [asExistingSplitting, setAsExistingSplitting] = useState(false);
  const [defaultSelect, setDefaultSelect] = useState("");
  const currentUser = useSelector((store: RootStateOrAny) => store.currentUser);
  const business = useSelector((store: RootStateOrAny) => store.business);
  const history = useHistory();

  const [splittingFormState, setSplittingFormState] = useState({
    id: "",
    type: "PERCENTAGE",
    subAccountId: "",
    value: "",
    paymentLinkId: "",
    status: "",
  });

  const getPaymentLink = async () => {
    try {
      setLoading(true);
      const paymentLinkResp = await api.get(
        `/services/${service?.id}/payment-link/${payLinkId}`,
        {
          // mode: service.live ? "LIVE" : "TEST",
          mode: service?.mode,
          id: service?.id,
          payment_link_id: payLinkId,
        }
      );
      consoleLog("paymentLinkResp", paymentLinkResp);
      if (
        paymentLinkResp.status == true &&
        paymentLinkResp?.payload instanceof Object
      ) {
        // let payLinkObj = paymentLinkResp?.payload.find(
        //   (el: any) => el.id === payLinkId
        // );
        let payLinkObj: any = paymentLinkResp?.payload;
        if (payLinkObj) {
          setFormstate(payLinkObj);
          let dfselect =
            payLinkObj.type === "ONE_TIME" ? "one-time" : "subscription";
          setDefaultSelect(dfselect);
          setSelectedTab(dfselect);
          if (payLinkObj && payLinkObj.split) {
            setSplittingFormState({
              id: payLinkObj?.split?.id,
              paymentLinkId: payLinkObj.split.paymentLinkId,
              type: payLinkObj.split.type,
              value: payLinkObj.split.value,
              subAccountId: payLinkObj.split.subAccountId,
              status: payLinkObj.split.status,
            });
            setAsExistingSplitting(true);
          }
        } else {
          toast.error("Payment link no found");
          history.push("/my-apps/overview");
        }
      } else {
        toast.error("Payment link no found");
        history.push("/my-apps/dashboard");
      }
    } catch (err) {
      // toast.error("Payment link no found");
      // history.push("/my-apps/overview");
      // @ts-ignore
    } finally {
      setLoading(false);
    }
  };

  let BackDiv = (
    <span
      onClick={() => {
        let link = "";
        if (
          location?.pathname &&
          (location?.pathname.indexOf("/my-apps/dashboard") > -1 ||
            location?.pathname.indexOf("/my-apps/list") > -1)
        ) {
          link = "/my-apps/list";
          history.push(link);
        } else {
          link = "/my-apps/dashboard";

          // consoleLog(history2, "history");
          history?.length > 1 ? history.goBack() : history.push(link);
        }
      }}
      style={{ cursor: "pointer", marginRight: "0.4rem", marginTop: "0.2rem" }}
    >
      {/*  */}
      {location?.pathname &&
      location?.pathname.indexOf("/my-apps/list") > -1 ? null : (
        <Icon type="arrow-left-circle" size={20} />
      )}
    </span>
  );
  useEffect(() => {
    if (service?.mode !== "LIVE") {
      history.push("/my-apps/dashboard");
    } else if (edit) {
      getPaymentLink();
    } else {
      setLoading(false);
    }
  }, []);
  let verify = (x: any, formstate: any) =>
    (x?.type === "url" && formstate?.webhookUrl?.trim() !== "") || x.required;
  if (!shouldPerform(getRoleName(currentUser, business?.id), "CPayLnkSub")) {
    history.push("/authenticate");
    return null;
  }
  if (loading) {
    return <GradientLoader />;
  }

  return (
    <>
      <PaymentLinkHeader>
        <PaymentHeaderLeft>
          {BackDiv}{" "}
          <h2 style={{ marginTop: 2 }}>
            {edit ? "Edit" : "Create"} Payment Link
          </h2>
        </PaymentHeaderLeft>
        <PaymentHeaderRight>
          <PaymentHeaderButton
            onClick={() => setSelectedTab("one-time")}
            className={selectedTab === "one-time" ? "selected" : ""}
          >
            One - Time
          </PaymentHeaderButton>
          <PaymentHeaderButton
            onClick={() => setSelectedTab("subscription")}
            className={selectedTab === "subscription" ? "selected" : ""}
          >
            Subscription
          </PaymentHeaderButton>
        </PaymentHeaderRight>
      </PaymentLinkHeader>
      {selectedTab === "one-time" ? (
        <OneTime
          fstate={formstate}
          existSplit={asExistingSplitting}
          splitfstate={splittingFormState}
          defaultSelect={defaultSelect}
          edit={edit}
          verify={verify}
        />
      ) : (
        <Subscription
          edit={edit}
          fstate={formstate}
          existSplit={asExistingSplitting}
          splitfstate={splittingFormState}
          defaultSelect={defaultSelect}
          verify={verify}
        />
      )}
    </>
  );
};

export default PaymentLink;
