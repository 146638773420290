import DashboardSubActionHeader from "shared/components/DashboardSubActionHeader";
import { RootStateOrAny, useSelector, useDispatch } from "react-redux";
import Logo from "../../assets/logo.svg";
import triggerHandleToggle from "shared/utils/handleToggle";
import { setService } from "store/actions/serviceAction";
import DashboardSubHeader from "shared/components/DashboardSubHeader";
import EmptyState from "shared/components/FreeComp/EmptyState";
import Table from "shared/components/Table";
import EllipsisLink from "shared/components/EllipsisLink";
import { useHistory } from "react-router-dom";
import api from "shared/utils/api";
import consoleLog from "shared/utils/consoleLog";
import { useEffect, useState } from "react";
import CopyIcon from "App/assets/icons/copy.svg";
import DeleteIcon from "App/assets/icons/trash.svg";
import { copyToClipboard } from "shared/utils/browser";
import { OptionItem, OptionText } from "./Styles";
import ActivateButton from "shared/components/ActivateButton";
import Icon from "shared/components/Icon";
import toast from "shared/utils/toast";
import { EmptyContainer } from "Services/Transactions/TransactionList/Styles";
import Modal from "shared/quickComponents/modal";

export default () => {
  const [paymentLinkDetails, setPaymentLinkDetails] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const service = useSelector((store: RootStateOrAny) => store.service);
  const dispatch = useDispatch();
  const history = useHistory();
  consoleLog("Service", service);
  const getPaymentLinks = async (id = "") => {
    let mode =
      id === "1" ? (service?.mode === "LIVE" ? "TEST" : "LIVE") : service?.mode;
    try {
      const paymentLinkResp = await api.get(
        `/services/${service?.id}/payment-link`,
        {
          mode,
          id: service?.id,
        }
      );
      if (
        paymentLinkResp?.status == true &&
        paymentLinkResp?.payload instanceof Array
      ) {
        consoleLog(paymentLinkResp, "paymentLinkDetails");
        setPaymentLinkDetails(paymentLinkResp.payload);
      } else {
        setPaymentLinkDetails([]);
      }
    } catch (err) {
      consoleLog(err);
    }
  };

  const checkLiveMode = (link: string) => {
    let isLive =
      String(service?.mode)
        .trim()
        .toLowerCase() === "live";
    if (isLive) {
      history.push(link);
    } else {
      setOpenModal(true);
    }
  };

  const handleToggleMode = async () => {
    await triggerHandleToggle(
      service,
      dispatch,
      setService,
      getPaymentLinks("1")
    );
  };

  const togglePaymentLinkStatus = async (item: any, indLink: number) => {
    try {
      // setLoading(true);
      consoleLog(item?.status, "id");

      const paymentLinkResp = await api.patch(
        `/services/${service?.id}/payment-link/${item?.id}`,
        {
          // ...paymentLinkDetails,
          currency: item?.currency,
          status: item?.status === "ACTIVE" ? "INACTIVE" : "ACTIVE",
        }
      );
      consoleLog(paymentLinkResp, "paymentLinkResp");
      if (paymentLinkResp.status == true) {
        toast.success("Successfully updated payment link status");
        // consoleLog(paymentLinkResp.payload);
      }
    } catch (err) {
      // @ts-ignore
      toast.error("Couldn't update payment link status");

      return "off";
    } finally {
      // setLoading(false);
    }
  };

  const columnHeaders = [
    { id: "name", label: "Page Name" },
    { id: "type", label: "Type" },
    { id: "amount", label: "Amount" },
    { id: "createdAt", label: "Created On" },
    { id: "status", label: "" },
    { id: "action", label: "Actions" },
  ];

  const deleteFn = async (item: any) => {
    if (
      window.confirm(`Do you want to delete this ${item?.name} payment Link?`)
    ) {
      try {
        const resp = await api.delete(
          `/services/${service?.id}/payment-link/${item?.id}`,
          {
            id: service?.id,
            payment_link_id: item?.id,
          }
        );
        if (resp?.status === true) {
          toast.success(resp.message);
        } else {
          toast.error("Encountered error deleting  ");
        }
        consoleLog(resp, "deletePaymentLink");
        await getPaymentLinks();
      } catch (err) {
        // @ts-ignore
        toast.error(err);
      }
    }
  };

  const options = (item: any) => [
    {
      title: (
        <OptionItem>
          <img src={CopyIcon} />
          <OptionText style={{ marginTop: 5 }}>Copy</OptionText>
        </OptionItem>
      ),
      link: () =>
        copyToClipboard(
          `${process.env.REACT_APP_CLIENT_URL ??
            "https://app.fountainpay.ng"}/link/${item?.slug}`
        ),
    },
    {
      title: (
        <OptionItem>
          <Icon type="pencil" size={15} />
          <OptionText style={{ marginLeft: 20 }}>Edit</OptionText>
        </OptionItem>
      ),
      link: () => checkLiveMode(`/my-apps/edit/payment-links/${item?.id}`),
    },
    {
      title: (
        <OptionItem>
          <img src={DeleteIcon} />
          <OptionText style={{ marginTop: 5 }}>Delete</OptionText>
        </OptionItem>
      ),
      link: () => deleteFn(item),
    },
  ];

  let data: any = [];
  data =
    paymentLinkDetails instanceof Array &&
    paymentLinkDetails.map((el: any, indLink: number) => {
      let obj: any = {};
      for (let elm of columnHeaders) {
        switch (elm?.id) {
          case "amount":
            obj[elm?.id] = `${el?.currency} ${el[elm?.id]}`;
            break;
          case "createdAt":
            obj[elm?.id] =
              el?.createdAt &&
              new Date(el?.createdAt).toLocaleString("en-GB", {
                year: "numeric",
                month: "numeric",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                hour12: true,
              });
            break;
          case "action":
            obj[elm?.id] = <EllipsisLink content={options(el)} />;

            break;
          case "status":
            obj[elm?.id] = (
              <ActivateButton
                id={`payment-link${el?.id}${Math.random()}`}
                active={el?.status === "ACTIVE"}
                toggleMethod={() => togglePaymentLinkStatus(el, indLink)}
                label={el?.status === "ACTIVE" ? "Deactivate" : "Activate"}
              />
            );

            break;

          default:
            obj[elm?.id] = el[elm?.id];
        }
      }
      return obj;
    });
  let rowCount = data.length;

  useEffect(() => {
    getPaymentLinks();
  }, []);

  return (
    <>
      {/*<DashboardSubActionHeader
        title={service?.name}
        logo={service?.businessLogo ?? Logo}
        live={service.mode === "LIVE"}
        handleToggleMode={handleToggleMode}
        setMasterReload={getPaymentLinks}
      /> */}
      <DashboardSubHeader
        title={`${rowCount} Payment Link`}
        btnTitle="Create Link"
        widthActionBtn
        callBackFn={() =>
          checkLiveMode(`/my-apps/${service?.id}/payment-links`)
        }
      />
      {rowCount === 0 ? (
        <EmptyContainer>
          <EmptyState stateTitle={"No Payment Link"} />
        </EmptyContainer>
      ) : (
        <>
          <Table
            headData={columnHeaders}
            bodyData={data}

            // clickFunction={myFunction}
            // totalPages={fetchedPayload?.totalPages ?? 0}
            // currentpage={fetchedPayload?.currentPage ?? 0}
            // goToPage={goToPage}
          />
        </>
      )}
      <Modal isOpen={openModal} onClose={() => setOpenModal(false)} closeButton>
        <div style={{ marginBottom: "20px" }}>
          Payment Link can only be viewed in live mode!
        </div>
      </Modal>
    </>
  );
};
