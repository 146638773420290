import chargeBackClass from "ChargeBacks/chargeBackClass";
import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Cont, Para, Input, Button } from "./Styles";
import { useParams } from "react-router-dom";
import toast from "shared/utils/toast";
import consoleLog from "shared/utils/consoleLog";

const initialValues = {
  message: "Accepted",
  type: "ACCEPTED",
};

function Accept({ toggle, getChargeBack }: any) {
  const { id }: any = useParams();

  consoleLog(toggle);
  const value = toggle();

  const onSubmit = async () => {
    const userData = { ...values };
    consoleLog(userData);
    try {
      const response = await chargeBackClass.changeStatus(id, userData);
      consoleLog(response);
      toast.success("Status Changed");
      getChargeBack(id);
    } catch (error) {
      consoleLog(error);
    }
  };

  const {
    handleChange,
    values,
    handleBlur,
    handleSubmit,
    errors,
    touched,
  } = useFormik({
    initialValues,
    onSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  return (
    <Cont>
      <Para>
        By accepting this chargeBack , you ‘re instructing us to process a
        refund that will be deducted from your next payout
      </Para>

      <form onSubmit={handleSubmit}>
        {/* {!value && (
          <Input
            value={values.message}
            name="message"
            onChange={handleChange}
            onBlur={handleBlur}
            type="text"
          />
        )} */}

        <Button
          type="submit"
          style={{ backgroundColor: "#219653", opacity: value ? 0.5 : 1 }}
          disabled={value}
        >
          Accept
        </Button>
      </form>

      <div></div>
    </Cont>
  );
}

export default Accept;
