import { Button } from "shared/components";
import breakpoints from "shared/utils/breakpoints";
import { color } from "shared/utils/styles";
import styled from "styled-components";

export const Container = styled.div`
  /* display: flex;
  justify-content: space-between;
  align-items: center; */
  overflow: auto;
`;

export const FormContainer = styled.div`
  margin: 30px 45px 30px 45px;
  justify-content: flex-start;
  @media ${breakpoints.device.max.xs} {
    /* margin: 30px 15px 30px 15px; */
    margin: 0;
    padding: 15px;
    width: 100%;
  }
`;

export const FormBody = styled.div`
  margin-top: 20px;
`;

export const FormRow = styled.div`
  /* display: flex; */
  /* justify-content: space-between;
  align-items: flex-start; */
  /* width: 400px; */
  /* margin-bottom: 15px; */
  @media ${breakpoints.device.max.xs} {
    /* flex-direction: column; */
    width: 100%;
  }
`;

export const FormRowLeft = styled.div`
  text-align: left;
  padding-top: 15px;
  margin-bottom: 15px;
`;

export const FormRowRight = styled.div`
  /* text-align: right; */
  /* width: 300px; */
  @media ${breakpoints.device.max.xs} {
    width: 100%;
  }
`;

export const FormDivider = styled.div`
  /* text-align: right; */
  background-color: grey;
  height: 1px;
  margin: 20px 0;
`;

export const ButtonContainer = styled.div`
  justify-content: flex-start;
  display: flex;
  flex-direction: row;
  margin-top: 16px;
  text-align: center;
`;
export const Back = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  /* identical to box height */
  margin-top: 16px;
  cursor: pointer;

  /* Grey Text 1 */

  color: #828282;
`;

export const ActionButton = styled(Button)`
  margin: 1em 0;
  color: #fff;
  width: 238px;
  height: 54px;
  float: right;
  background-color: ${color.fountain};
`;
