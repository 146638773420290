import { useLayoutEffect, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { GradientLoader, PageError } from "shared/components";
import { useParams } from "react-router";

import consoleLog from "shared/utils/consoleLog";
import api from "shared/utils/api";
import toast from "shared/utils/toast";
import { logout } from "store/actions/logout";
import { useDispatch } from "react-redux";
import { storeTempId, storeToLocalStorage } from "shared/utils/authToken";
import { authPath } from "apiServices/paths";

const AuthenticateNewMember = () => {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const [fstate, setFstate] = useState({});
  const { id: inivitationId }: any = useParams();
  const history = useHistory();
  
  const initialize = async () => {
    try {
      const resp = await api.patch(`/business/111111/team`, {
        verificationHash: inivitationId,
      });
      
      consoleLog(resp, "Response Invitation");
      const { status, message, payload } = resp;
      if (status) {
        toast.success(message);
        if (payload.userExists) {
          history.push(authPath.LOGIN);
        }
        if (!payload.userExists) {
          storeTempId(inivitationId);
          storeToLocalStorage("invitation", true);
          history.push(authPath.SET_PASSWORD);
        }
      } else {
        toast.error(message);
        await dispatch(logout());
      }
    } catch (error) {
      consoleLog(error, "Invitation");
      if (!String(error).includes("exist")) {
        // @ts-ignore
        toast.error(error);
      }
      //logout first
      // clearLocalStorage();
      await dispatch(logout());
      history.push("/authenticate");
    } finally {
      setIsLoading(false);
      // setNewPage(4); ///remove later
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  if (isLoading) {
    return <GradientLoader />;
  }

  return <></>;
};

export default AuthenticateNewMember;
