import React from "react";
import { Container } from "./Style";

type Propst={
  name: string;
  size?: string;
  bgColor?: string;
}
const ProfilePicture = ({name, size, bgColor} : Propst) => {
  const nameParts = name.split(" ");
  const firstNameInitial = nameParts[0] ? nameParts[0][0] : "";
  const lastNameInitial = nameParts[1] ? nameParts[1][0] : "";

  return (
    <Container size={size} bgColor={bgColor}>
      {firstNameInitial}
      {lastNameInitial}
    </Container>
  );
};
export default ProfilePicture;
