import { Button } from 'shared/components'
import breakpoints from 'shared/utils/breakpoints'
import { color } from 'shared/utils/styles'
import styled from 'styled-components'

type FirstCardT = {
	isSelected: boolean
}

type StepT = {
	isActive: boolean
}

type StepBoxT = {
	isActive: boolean
	border?: boolean
}

type ServiceBoxT = {
	isClicked?: boolean
}

type ModePropT = {
	mode: string
}

type StepLinT = {
	color?: string
}

export const PageContainer = styled.div`
	min-height: 40vh;
	width: 100%;
	display: flex;
	justify-content: space-between;
	@media ${breakpoints.device.max.xs} {
		flex-direction: column;
	}
`

export const TeamFormContainer = styled.div`
	width: 68%;
	display: flex;
	background: #fff;
	justify-content: space-between;
	@media ${breakpoints.device.max.xs} {
		width: 100%;
	}
`
export const FormContainer = styled.div`
	width: 90%;
	padding: 1rem;
`

export const StepsBox = styled.div<StepBoxT>`
	min-height: 10vh;
	margin-bottom: 4rem;

	display: ${(prop) => (prop.isActive ? 'block' : 'none')};
	opacity: ${(prop) => (prop.isActive ? 1 : 0)};
	transition: display 0s, opacity 3.5s linear;
	// padding-bottom: 1rem;
`

export const FirstStep = styled.div`
	display: flex;
	gap: 4rem;
	margin-bottom: 1rem;
`
export const FirstStepCard = styled.div<FirstCardT>`
	width: 28%;
	height: 6.5rem;
	display: grid;
	place-items: center;
	border-radius: 0.4rem;
	background: ${(prop) =>
		prop.isSelected ? '#0e314c' : color.backgroundMedium};
	color: ${(prop) => (prop.isSelected ? '#fff' : '#333')};
	cursor: pointer;
	transition: all ease 0.9s;
  padding: 10px;
	@media ${breakpoints.device.max.xs} {
		width: 100%;
		height: 4.5rem;
	}
`

export const StepContainer = styled.div`
  width: 10%;
  /* border: 1px solid ${color.backgroundMedium}; */
`

export const StepsContainer = styled.div`
	padding: 2rem 0;
	min-height: 40vh;
	display: flex;
	flex-direction: column;
	gap: 0.4rem;
	align-items: center;
`

export const Step = styled.div<StepT>`
	width: 2rem;
	height: 2rem;
	background: ${(prop) =>
		prop.isActive ? color.fountain : 'rgba(250, 214, 214, 0.55);'};
	display: grid;
	place-items: center;
	color: ${(prop) => (prop.isActive ? '#fff' : color.fountain)};
	border-radius: 4rem;
	font-size: 1.4rem;
	transition: all ease 0.6s;
`
export const StepLine = styled.div<StepLinT>`
	border-left: ${(prop) =>
		prop.color ? `1px solid ${prop.color}` : `1px solid ${color.fountain}`};
	min-height: 2rem;
`

export const ServicesBox = styled.div`
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: start;
	gap: 1.2rem;
	margin-bottom: 2rem;
	min-height: 2vh;
	/*max-height: 35vh;*/
	overflow-x: auto;
`

export const ServiceBox = styled.button<ServiceBoxT>`
  width: 30%;
  height: 6.8rem;
  display: flex;
  flex-direction:column;
  /*padding: 0.8rem;*/
  cursor: pointer;
  /* border: 2px solid ${color.backgroundMedium}; */
  border: ${(prop) =>
		prop.isClicked
			? `2px solid ${color.fountain}`
			: `2px solid ${color.backgroundMedium}`};
  border-radius: 0.4rem;
  padding:10px;
  p{
    margin-top:0.4rem;
    font-size:0.8rem;
  }
    @media ${breakpoints.device.max.xs} {
   width:46%;
  }
`

export const ServiceName = styled.span`
	display: block;
	font-size: 1.2rem;
	font-weight: 600;
	width: 100%;
  white-space: nowrap;
  overflow: hidden;
	text-overflow: ellipsis;
  text-align: left;
`

export const Mode = styled.span<ModePropT>`
	margin-top: 1rem;
	font-size: 0.8rem;
	color: ${(prop) => (prop.mode === 'LIVE' ? 'green' : 'red')};
`

export const AddTeamForm = styled.form`
	width: 100%;
	position: relative;
`
export const InputLabel = styled.label`
	display: block;
`

export const FromGroup = styled.div`
	width: 100%;
	display: flex;
	gap: 2rem;
	margin-bottom: 2rem;
`

export const InputGroup = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 0.4rem;
	margin-bottom: 2rem;
`

export const Input = styled.input`
	width: 100%;
	height: 54px;
	padding: 0 0.4rem;
	border: 1px solid ${color.backgroundMedium};
	border-radius: 0.4rem;
`

export const Select = styled.select`
	width: 100%;
	height: 54px;
	padding: 0 0.4rem;
	border: 1px solid ${color.backgroundMedium};
	border-radius: 0.4rem;
`

// export const ActionButton = styled.button`

// `

export const TitleCase = styled.div`
	font-family: 'KumbhSansNormal';
	font-style: normal;
	font-weight: 700;
	font-size: 24px;
	line-height: 28px;
	letter-spacing: -0.02em;

	/* Primary Black */

	color: #292929;
`

export const SubTitle = styled.div`
	font-family: 'KumbhSansNormal';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	width: 20em;
	margin-top: 0.6rem;

	/* Grey Text 1 */

	color: #828282;
	@media ${breakpoints.device.max.xs} {
		width: 100%;
	}
`

export const FormBody = styled.div`
	margin-top: 20px;
`

export const ButtonContainer = styled.div`
	justify-content: flex-end;
	display: flex;
	flex-direction: row;
	margin-top: 16px;
`
export const Back = styled.div`
	font-family: 'KumbhSansNormal';
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 21px;
	/* identical to box height */
	margin-top: 16px;
	cursor: pointer;

	/* Grey Text 1 */

	color: #828282;
`

export const ActionButton = styled.button`
	margin: 1em 0;
	color: #fff;
	width: 138px;
	height: 54px;
	float: right;
	border-radius: 0.4rem;
	cursor: pointer;
	background-color: ${color.fountain};
`

export const DetailsContainer = styled.div`
  width: 30%;
  /* background: #fff; */
  /* border: 1px solid ${color.backgroundMedium}; */
  	@media ${breakpoints.device.max.xs} {
		width: 100%;
    margin-top:20px;
	}
`
