import { ActionTypes } from "../constants/actionType";

const initialState = {
  kycStart: "",
};

export const kycStartReducer = (
  state: any = initialState.kycStart,
  action: any
) => {
  switch (action.type) {
    case ActionTypes.KYC_START_ADDR:
      return action.payload;
    default:
      return state;
  }
};
