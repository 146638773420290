import { NavLink } from "react-router-dom";
import styled from "styled-components";

import { Logo } from "shared/components";
import breakpoints from "shared/utils/breakpoints";
import { color, font, mixin, sizes, zIndexValues } from "shared/utils/styles";

type PropT = {
  to?: boolean;
  isActive?: boolean;
};

export const NavLeft = styled.aside`
  z-index: ${zIndexValues.navLeft};
  position: fixed;
  top: 0;
  left: 0;
  overflow-x: hidden;
  height: 100vh;
  width: ${sizes.appNavBarLeftWidth}px;
  background: ${color.fountain};
  transition: all 0.1s;
  ${mixin.hardwareAccelerate}
  &:hover {
    width: 250px;
    box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.6);
  }
  @media ${breakpoints.device.max.md} {
    position: absolute;
    &:hover {
      width: 200px;
      box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.6);
    }
  }
`;

export const ImageAvatar = styled.div`
  width: 100%;
  height: 50px;
  margin-left: 5px;
`;

export const LogoLink = styled(NavLink)`
  display: flex;
  position: relative;
  left: 0;
  margin: 20px 0 10px;
  transition: left 0.1s;
`;

export const LogoText = styled.div`
  position: absolute;
  left: 62px;
  top:12px;
  visibility: hidden;
  opacity: 0;
  text-transform: uppercase;
  transition: all 0.1s;
  transition-property: right, visibility, opacity;
  overflow: hidden;
  color: #fff;
  ${font.bold}
  ${font.size(12)}
  ${NavLeft}:hover & {
    right: 0;
    visibility: visible;
    opacity: 1;
  }
  p{
    font-style: italic;
    text-transform: capitalize;
  }
`;

export const StyledLogo = styled(Logo)`
  display: inline-block;
  margin-left: 8px;
  padding: 10px;
  ${mixin.clickable}
`;

export const Bottom = styled.div`
  margin-top: 20px;
  position: absolute;
  bottom: 5px;
  left: 0;
  width: 100%;
`;

export const Item = styled.div<PropT>`
  position: relative;
  width: 100%;
  line-height: 20px;
  height: 35px;
  /* line-height: 42px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 64px;
  padding-bottom: 30px;
  padding-top: 30px;
  color: #deebff;
  transition: color 0.1s;
  overflow: hidden;
  ${mixin.clickable};
  background: ${(props) => (props?.isActive ? "rgba(255, 255, 255, 0.1)" : "")};
  border-left: ${(props) => (props?.isActive ? "4px solid #D04848" : "")};
  &:hover {
    background: rgba(255, 255, 255, 0.1);
    border-left: 4px solid #d04848;
  }
  i {
    position: absolute;
    left: 18px;
  }
`;

export const BItem = styled.div<PropT>`
  position: relative;
  width: 100%;
  line-height: 20px;
  height: 22px;
  /* line-height: 42px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 64px;
  padding-bottom: 18px;
  padding-top: 18px;
  color: #deebff;
  transition: color 0.1s;
  overflow: hidden;
  ${mixin.clickable};
  background: ${(props) => (props?.isActive ? "rgba(255, 255, 255, 0.1)" : "")};

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }
  i {
    position: absolute;
    left: 18px;
  }
`;

export const ItemBody = styled.div`
  font-size: 15px;
`;

export const ItemText = styled.div`
  position: relative;
  right: 12px;
  visibility: hidden;
  opacity: 0;
  text-transform: uppercase;
  transition: all 0.1s;
  transition-property: right, visibility, opacity;
  width: 100%;
  overflow:hidden;
  font-size: 0.78rem;
  ${font.bold}
  /* ${font.size(12)} */
  ${NavLeft}:hover & {
    right: 0;
    visibility: visible;
    opacity: 1;
  }
`;

export const ItemId = styled.div`
  font-size: 0.78rem;
  color: #d8a6a6;
`;

export const LinkItem = styled.div<PropT>`
  position: relative;
  width: 100%;
  height: 42px;
  line-height: 42px;
  padding-left: 64px;
  color: #deebff;
  transition: color 0.1s;
  ${mixin.clickable}
  ${(props) =>
    !props.to
      ? `cursor: not-allowed;`
      : `&:hover { background: ${color.backgroundLight}; }`}
  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }
  i {
    position: absolute;
    left: 18px;
  }
  &.active {
    color: ${color.primary};
    background: ${color.backgroundLight};
    i {
      color: ${color.primary};
    }
  }
`;

export const LinkText = styled.div`
  padding-top: 2px;
  ${font.size(14.7)};
`;

export const NotImplemented = styled.div`
  display: inline-block;
  position: absolute;
  top: 7px;
  left: 40px;
  width: 140px;
  padding: 5px 0 5px 8px;
  border-radius: 3px;
  text-transform: uppercase;
  color: ${color.textDark};
  background: ${color.backgroundMedium};
  opacity: 0;
  ${font.size(11.5)};
  ${font.bold}
  ${ItemText}:hover & {
    opacity: 1;
  }
`;

export const MenuContainer = styled.div`
  max-height: 50vh;
  overflow: hidden;
  ::-webkit-scrollbar {
    /* display: none; */
    scrollbar-width: 11px !important;
  }
  &:hover {
    overflow-y: scroll;
    ${mixin.customScrollbar()}
  }
`;
