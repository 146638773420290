import styled from "styled-components";
import breakpoints from "shared/utils/breakpoints";
interface Props {
  expand?: boolean;
}
export const CardCont = styled.div<Props>`
  display: flex;
  width: ${(props) => (props.expand ? "100%" : "48%")};
  // width: 100%;
  height: 115px;
  padding: 20px;
  align-items: center;
  gap: 45px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #fff;
  justify-content: space-between;

  /* Style 3 */
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.05);

  @media ${breakpoints.device.max.md} {
    width: 100%;
    margin-top: 5px;
  }
`;

export const CardInnerCont = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 19px;
`;
export const CardTopText = styled.div`
  color: #828282;

  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const CardBottomText = styled.div`
  color: #292929;

  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const CardLinkDiv = styled.div`
  color: #760000;

  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
`;
