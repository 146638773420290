import React, { useState, useEffect } from "react";
import {
  ListPointer,
  Lists,
  ModuleHeader,
  PermissionList,
  PermissionListContainer,
  PermissionsContainer,
  PermModule,
  ViewMore,
} from "./Styles";

export default function PermissionDetails() {
  const [isMoreClicked, setisMoreClicked] = useState(false);
  const [currentModule, setcurrentModule] = useState("developer");
  const [moduleName, setmoduleName] = useState("");

  const ownerPermissions = [
    { id: 1, permission: "Enable dashboard payment option", status: true },
    { id: 2, permission: "Enable dashboard payment option", status: true },
    { id: 2, permission: "Enable dashboard payment option", status: true },
    { id: 2, permission: "Enable dashboard payment option", status: true },
  ];
  const developerPermissions = [
    { id: 1, permission: "Developer permission 1", status: true },
    { id: 2, permission: "Developer permission 2", status: false },
    { id: 2, permission: "Enable dashboard payment option", status: true },
  ];
  const customerPermissions = [
    { id: 1, permission: "Enable dashboard payment option", status: true },
    { id: 1, permission: "Enable dashboard payment option", status: true },
    { id: 1, permission: "Enable dashboard payment option", status: true },
  ];

  const ownerPermList = ownerPermissions.map((perm: any, ind: number) => (
    <PermissionList key={ind}>
      <ListPointer></ListPointer>
      <Lists>{perm.permission}</Lists>
    </PermissionList>
  ));

  const devPermList = developerPermissions.map((perm: any, ind: number) => (
    <PermissionList key={ind}>
      <ListPointer></ListPointer>
      <Lists>{perm.permission}</Lists>
    </PermissionList>
  ));

  const supportPermList = customerPermissions.map((perm: any, ind: number) => (
    <PermissionList key={ind}>
      <ListPointer></ListPointer>
      <Lists>{perm.permission}</Lists>
    </PermissionList>
  ));

  useEffect(() => {
    setmoduleName(currentModule);
  }, [currentModule, isMoreClicked]);

  return (
    <div style={{ overflowY: "auto", maxHeight: "100vh" }}>
      <PermissionsContainer>
        <h3>Permissions</h3>
        <br />
        {/* === Owner === */}
        <PermModule>
          <ModuleHeader>Owner</ModuleHeader>
          <PermissionListContainer>
            {moduleName === "owner" && isMoreClicked
              ? ownerPermList
              : ownerPermList.slice(0, 2)}
            <ViewMore
              onClick={() => {
                setisMoreClicked(!isMoreClicked);
                setcurrentModule("owner");
              }}
            >
              {moduleName === "owner" && isMoreClicked
                ? "View Less"
                : "View more"}
            </ViewMore>
          </PermissionListContainer>
        </PermModule>

        {/* ==== Developer === */}
        <PermModule>
          <ModuleHeader>Developer</ModuleHeader>
          <PermissionListContainer>
            {moduleName === "developer" && isMoreClicked
              ? devPermList
              : devPermList.slice(0, 2)}
            <ViewMore
              onClick={() => {
                setisMoreClicked(!isMoreClicked);
                setcurrentModule("developer");
              }}
            >
              {moduleName === "developer" && isMoreClicked
                ? "View Less"
                : "View more"}
            </ViewMore>
          </PermissionListContainer>
        </PermModule>

        {/* ==== Customer Support === */}
        <PermModule>
          <ModuleHeader>Customer Support</ModuleHeader>
          <PermissionListContainer>
            {moduleName === "support" && isMoreClicked
              ? supportPermList
              : supportPermList.slice(0, 2)}
            <ViewMore
              onClick={() => {
                setisMoreClicked(!isMoreClicked);
                setcurrentModule("support");
              }}
            >
              {moduleName === "support" && isMoreClicked
                ? "View Less"
                : "View more"}
            </ViewMore>
          </PermissionListContainer>
        </PermModule>
      </PermissionsContainer>
    </div>
  );
}
