export const BreakPoints = {
  mobile_smallest: "max-width:375px",
  mobile_medium: "min-width:375px",
  mobile_large: "min-width:425px",
  xs: "max-width:576px",
  small: "min-width:576px",
  medium: "min-width:768px",
  large: "min-width:992px",
  xl: "min-width:1200px",
  xxl: "min-width:1400px",
};

export default BreakPoints;
