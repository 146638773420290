import styled from "styled-components";

export const EmptyContainer = styled.div`
  margin-top: 20px;
`;

export const SectionDiv = styled.div`
  display: flex;
  flex-direction: column;

  padding: 20px;
  background: #ffffff;
  border-radius: 20px;
  width: 48%;
`;

export const SectionUnit = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const SectionTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #292929;
`;
export const LeftTitle = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: rgba(130, 130, 130, 0.9);
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const RightLabel = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #292929;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const TopDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
`;

export const Container=styled.div`
  height: 86vh;
  overflow: auto;
`;
