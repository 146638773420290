import React, { useEffect, useState } from "react";
import {
  Container,
  GroupedInput,
  GroupedInputField,
  GroupedInputLabel,
  InputField,
  InputLabel,
  InputRow,
  LeftSidebar,
  Main,
  PageContainer,
  PageHead,
  ResolutionForm,
  RightSidebar,
  SelectField,
  SubmitButton,
} from "./Styles";
import banner from "../App/assets/images/disputebanner.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import disputeClass from "./disputeClass";
import Preloader from "shared/components/preloader";
import { useParams } from "react-router-dom";
import consoleLog from "shared/utils/consoleLog";
import { GradientLoader } from "shared/components";

export default function DisputeResolution() {
  const { id }: any = useParams();
  const [userId, setUserId] = useState("");
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [customerData, setCustomerData] = useState<any>("");
  const [customerEmail, setCustomerEmail] = useState("");
  const [customerPhone, setCustomerPhone] = useState("");
  const [customerAmount, setCustomerAmount] = useState("");
  const [businessId, setBusinessId] = useState("");
  const [merchantName, setMerchantName] = useState("");
  const [transactionId, setTransactionId] = useState(id);

  const getUserDetails = async () => {
    const response = await disputeClass.getUserDetails(transactionId);
    consoleLog("PAYLOAD", response.Services.name);
    setCustomerData(response);
    setCustomerAmount(response.amount);
    setCustomerEmail(response.customer.email);
    setName(response.customer.fullname);
    // setCustomerPhone(response.services.phone);
    setBusinessId(response.Services.businessId);
    setMerchantName(response.Services.business.businessName);
    setUserId(response.Services.userId);
    consoleLog("PAYLOAD", response);
  };

  const initialValues = {
    businessId: businessId,
    userId,
    amount: customerAmount,
    transactionId: id,
    reason: "",
    bankName: "",
    accountName: "",
    accountNumber: "",
    email: customerEmail,
    merchant: merchantName,
    phoneNumber: customerPhone,
    name,
    paidAt: customerData.paidAt,
    charge: customerData.charge,
    refundRequest: "" || customerData.refundRequest,
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  // consoleLog(customerPhone);

  const validationSchema = Yup.object({
    reason: Yup.string().required("Please fill in this field"),
    bankName: Yup.string().required("Please fill in this field"),
    accountName: Yup.string().required("Please fill in this field"),
    accountNumber: Yup.string().required("Please fill in this field"),
  });

  const onSubmit = async (payload: any) => {
    setLoading(true);
    try {
      const userData = { ...payload };
      consoleLog("UserData", userData);
      await disputeClass.sendDispute(userData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
    // setLoading(false);
  };

  const {
    handleChange,
    values,
    handleBlur,
    handleSubmit,
    errors,
    touched,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const getError = (key: any) => {
    //@ts-ignore
    return touched[key] && errors[key];
  };

  if (loading) {
    return <GradientLoader />;
  }

  return (
    <>
      <PageContainer>
        <Main>
          <Container>
            <PageHead>
              <h2>Dispute Resolution Form</h2>
            </PageHead>
            <LeftSidebar>
              <ResolutionForm onSubmit={handleSubmit}>
                <InputRow>
                  <InputLabel>Name</InputLabel>
                  <InputField
                    type="text"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled
                  />
                  {getError("name")}
                </InputRow>

                <InputRow>
                  <InputLabel>Phone Number</InputLabel>

                  <InputField
                    type="text"
                    name="phoneNumber"
                    value={values.phoneNumber}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled
                  />
                  {getError("phoneNumber")}
                </InputRow>

                <InputRow>
                  <InputLabel>Reason for Dispute</InputLabel>
                  <div>
                    <InputField
                      type="text"
                      name="reason"
                      value={values.reason}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <p>{getError("reason")}</p>
                  </div>
                </InputRow>

                <InputRow>
                  <InputLabel>Account Details</InputLabel>
                  <div>
                    <InputField
                      type="text"
                      name="accountNumber"
                      value={values.accountNumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Enter account number"
                    />
                    <p> {getError("accountNumber")}</p>
                  </div>
                </InputRow>

                <InputRow>
                  <InputLabel></InputLabel>

                  <div>
                    <InputField
                      type="text"
                      name="accountName"
                      value={values.accountName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Enter account name"
                    />
                    <p> {getError("accountName")}</p>
                  </div>
                </InputRow>

                <InputRow>
                  <InputLabel></InputLabel>
                  <div>
                    <InputField
                      type="text"
                      name="bankName"
                      value={values.bankName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Enter Bank Name"
                    />

                    <p> {getError("bankName")}</p>
                  </div>
                </InputRow>

                <InputRow>
                  <InputLabel>Transaction ID</InputLabel>
                  <InputField
                    type="text"
                    name="transactionId"
                    value={values.transactionId}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled
                  />
                  {getError("transactionId")}
                </InputRow>

                <InputRow>
                  <InputLabel>Merchant Name</InputLabel>
                  <InputField
                    type="text"
                    name="merchant"
                    value={values.merchant}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled
                  />
                  {getError("merchantName")}
                </InputRow>

                <InputRow>
                  <InputLabel>Amount of Transaction</InputLabel>
                  <GroupedInput>
                    <GroupedInputLabel>NGN</GroupedInputLabel>
                    <GroupedInputField
                      type="text"
                      name="amount"
                      value={values.amount}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled
                    />
                  </GroupedInput>
                  {getError("amount")}
                </InputRow>

                <SubmitButton type="submit">Submit</SubmitButton>
              </ResolutionForm>
            </LeftSidebar>
          </Container>
          <RightSidebar url={banner}></RightSidebar>
        </Main>
      </PageContainer>
    </>
  );
}
