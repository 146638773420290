import { useState, useEffect } from "react";
import {
  ParentCont,
  LeftPanel,
  RightPanel,
  RightPanelAuxCont,
  CloseBtn,
  DescriptionBox,
  RightPanelAuxCon2,
  DescriptionTitle,
  DescriptionText,
  DescriptionBtn,
  FormCont,
  TitleCont,
  Back,
  FormTitle,
  FormSubTitle,
  FormElement,
  Selectors,
  ActionButton,
  MiddlePanel,
} from "./Styles";
import { Icon, Form } from "shared/components";
import { useHistory } from "react-router-dom";
import api from "shared/utils/api";
import toast from "shared/utils/toast";
import PageIndicator from "shared/components/PageIndicator";
import consoleLog from "shared/utils/consoleLog";
import { AgencyBankingPages } from "../../PageList";

import { Industries } from "./industries";
const categoryOptions = Object.values(Industries).map((role) => ({
  value: role,
  label: role,
}));
import { RootStateOrAny, useSelector, useDispatch } from "react-redux";
import { setKycBoardingRec } from "store/actions/boardingAction";
import checkExistingKycBoarding from "shared/utils/checkExistingKycBoarding";

function BusinessInformation() {
  const business = useSelector((store: RootStateOrAny) => store.business);
  const kycBoardingRec = useSelector(
    (store: RootStateOrAny) => store.kycBoardingRec
  );
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [regtype, setRegtype] = useState<string>("");
  const [rcBusinessName, setRcBusinessName] = useState<string>(
    kycBoardingRec?.rcBusinessName ?? ""
  );
  const [bizType, setBizType] = useState<string>(kycBoardingRec?.bizType ?? "");
  const [phoneNumber, setPhoneNumber] = useState<string>(
    kycBoardingRec?.phoneNumber ?? ""
  );
  const [state, setState] = useState<string>(kycBoardingRec?.state ?? "0");
  const [lga, setlga] = useState<string>(kycBoardingRec?.lga ?? "");
  const [address, setAddress] = useState<string>(kycBoardingRec?.address ?? "");

  const [loadingCategories, setLoadingCategories] = useState<boolean>(false);
  const [categoryList, setCategoryList] = useState<any>([]);
  const [loadingStates, setLoadingStates] = useState(false);
  const [loadingLga, setLoadingLga] = useState(false);
  const [stateList, setStateList] = useState([]);
  const [lgaList, setLgaList] = useState([]);

  const currentBusiness = useSelector(
    (store: RootStateOrAny) => store.business
  );
  const kycStart = useSelector((store: RootStateOrAny) => store.kycStart);

  const initialValues = {
    businessName: rcBusinessName,
    phone: phoneNumber,
    businessType: bizType,
  };
  consoleLog(business, "Business current");

  const [isDisabled, setDisabbled] = useState(true);

  useEffect(() => {
    if (phoneNumber && state && lga && address) {
      setDisabbled(false);
    } else {
      setDisabbled(true);
    }
  }, [phoneNumber, , state, lga, address]);

  const history = useHistory();
  const isCurrentBusinnes = () => {
    return currentBusiness.length;
  };

  const onSubmit = async (values: any, form: any) => {
    try {
      setIsLoading(true);
      try {
        if (!business?.id) {
          business.id = kycBoardingRec?.businessId;
        }
        let findRecord = kycBoardingRec?.businessKycId
          ? { id: kycBoardingRec?.businessKycId }
          : business?.BusinessKyc instanceof Array &&
            business?.BusinessKyc.find(
              (el: any) => el?.type.toLowerCase() === "agency"
            );
        if (findRecord) {
          const res: any = await api.patch(
            `/business/${business.id}/kyc/${findRecord.id}`,
            {
              step: 3,
              businessMeta: {
                rcBusinessName,
                phoneNumber,
                bizType,
                state,
                lga,
                address,
              },
            }
          );
          toast.success(res.message);
          await dispatch(
            setKycBoardingRec(
              kycBoardingRec instanceof Object && {
                ...kycBoardingRec,
                rcBusinessName,
                phoneNumber,
                bizType,
                state,
                lga,
                address,
                businessId: business.id,
              }
            )
          );
          continueEvent();
        } else {
          toast.error(
            "Unable to to add Business Location, Kyc documentation not properly done."
          );
        }

        setIsLoading(false);
      } catch (error) {
        // consoleLog(error);
        // @ts-ignore
        toast.error(error);
        setIsLoading(false);
      }
    } catch (error) {
      // consoleLog(error);
      // @ts-ignore
      toast.error(error);
    }
  };

  const industryCatgory = (categoryList: any) =>
    categoryList.map((cat: any) => ({ value: cat?.id, label: cat?.name }));

  const getIndustryType = async () => {
    setLoadingCategories(true);
    try {
      const businessList: any = await api.get("/business/categories");
      if (!businessList) {
        toast.warning("No Business List");
        return;
      }
      const { payload } = businessList;
      setCategoryList(payload);
      setLoadingCategories(false);
    } catch (error) {
      setLoadingCategories(false);
      // consoleLog(error);
      // @ts-ignore
      toast.error(error);
    }
  };

  const continueEvent = () => {
    history.push("/kyc/agency-banking-upload-documents");
  };

  const goBack = () => {
    history.push("/kyc/residential-information");
  };

  const close = () => {
    history.push("/overview");
  };

  const listOfStates = (list: any) =>
    list.map((item: any) => ({ value: item?.id, label: item?.state }));

  const listOfLga = (list: any) =>
    list.map((item: any) => ({ value: item?.id, label: item?.lga }));

  const getStates = async () => {
    setLoadingStates(true);
    try {
      const res: any = await api.get("/agency-banking/state-list");
      const { payload } = res;
      setStateList(payload);
      setLoadingStates(false);
    } catch (error) {
      setLoadingStates(false);
      // @ts-ignore
      toast.error(error);
    }
  };

  const getLga = async () => {
    setLoadingLga(true);
    try {
      const res: any = await api.post("/agency-banking/state-lgas", {
        stateId: state,
      });
      const { payload } = res;
      setLgaList(payload);
      setLoadingLga(false);
    } catch (error) {
      setLoadingLga(false);
      // @ts-ignore
      toast.error(error);
    }
  };

  useEffect(() => {
    if (state && state !== "0") getLga();
  }, [state]);

  useEffect(() => {
    getIndustryType();
    getStates();
  }, []);
  let urlAddress = history?.location?.pathname;

  return (
    <ParentCont>
      <LeftPanel>
        <FormCont>
          <TitleCont>
            {urlAddress !== kycStart && (
              <Back onClick={goBack}>
                <Icon type={"chevron-left"} size={20} />
              </Back>
            )}
            <FormTitle>Business Location Information</FormTitle>
          </TitleCont>

          <FormSubTitle>
            With your corporate account number, you are guaranteed of your
            settlement.
          </FormSubTitle>

          <Form
            enableReinitialize
            initialValues={initialValues}
            validations={{
              businessName: Form.is.required(),
              phone: Form.is.required(),
              businessType: Form.is.required(),
            }}
            onSubmit={onSubmit}
            validate={() => {}}
            validateOnBlur={false}
          >
            <>
              <FormElement>
                {currentBusiness ? (
                  <Form.Field.Input
                    type="hidden"
                    value={currentBusiness.businessName}
                    onChange={(e: any) =>
                      setRcBusinessName(currentBusiness.businessName)
                    }
                    // tip="Kindly enter your user email address."
                  />
                ) : (
                  <Form.Field.Input
                    name="businessName"
                    label="Business Name"
                    type="text"
                    value={rcBusinessName}
                    onChange={(e: any) => setRcBusinessName(e)}
                    // tip="Kindly enter your user email address."
                  />
                )}

                {currentBusiness.categoryId ? (
                  <Form.Field.Input
                    name="businessType"
                    type="hidden"
                    value={currentBusiness.categoryId}
                    // tip="Kindly enter your user email address."
                  />
                ) : (
                  <Form.Field.Select
                    name="businessType"
                    label="Business Type"
                    options={industryCatgory(categoryList)}
                    onChange={(e: any) => setBizType(e)}
                    // tip="Kindly enter your user password."
                  />
                )}

                <Form.Field.Input
                  name="phone"
                  type="text"
                  label="Phone Number"
                  minLength="11"
                  value={phoneNumber}
                  onChange={(e: any) => setPhoneNumber(e)}
                  // tip="Kindly enter your user email address."
                />
                <Form.Field.Select
                  name="state"
                  label="State"
                  options={listOfStates(stateList)}
                  onChange={(e: any) => {
                    setState(e);
                    consoleLog(e);
                  }}
                  value={loadingStates ? "loading states" : state}
                  // tip="Kindly enter your user password."
                />

                <Form.Field.Select
                  name="lga"
                  label="LGA"
                  options={listOfLga(lgaList)}
                  value={loadingLga ? "loading LGA" : lga}
                  onChange={(e: any) => setlga(e)}
                  // tip="Kindly enter your user password."
                />

                <Form.Field.Input
                  name="address"
                  type="text"
                  label="Business Address"
                  onChange={(e: any) => setAddress(e)}
                  // tip="Kindly enter your user email address."
                />
              </FormElement>
              <Selectors>
                <ActionButton
                  onClick={onSubmit}
                  // onClick={continueEvent}
                  type="submit"
                  variant="primary"
                  isWorking={isLoading}
                  disabled={isDisabled}
                >
                  {checkExistingKycBoarding(
                    `rcBusinessName,phoneNumber,bizType,state,lga,address`,
                    kycBoardingRec
                  )
                    ? "Update"
                    : "Save"}{" "}
                  and Continue
                </ActionButton>
              </Selectors>
            </>
          </Form>
        </FormCont>
      </LeftPanel>

      <MiddlePanel>
        <PageIndicator pageArr={AgencyBankingPages} page={2} />
      </MiddlePanel>

      <RightPanel>
        <RightPanelAuxCont>
          <CloseBtn onClick={close}>
            <Icon type={"close"} size={20} />
          </CloseBtn>
        </RightPanelAuxCont>

        <RightPanelAuxCon2>
          <DescriptionBox>
            <DescriptionTitle>Agency Banking</DescriptionTitle>

            <DescriptionText>
              Be ahead of late payment, switch to an instant epay platform.
            </DescriptionText>

            <DescriptionBtn
              onClick={() =>
                window.open(
                  "https://medium.com/@fountainpay",
                  "noopener,noreferrer"
                )
              }
            >
              Learn more
            </DescriptionBtn>
          </DescriptionBox>
        </RightPanelAuxCon2>
      </RightPanel>
    </ParentCont>
  );
}

export default BusinessInformation;
