import {
  ActionButton,
  ButtonContainer,
  FormBody,
  FormContainer,
  FormElement,
  SubAgentForm,
  SubTitle,
  TitleCase,
} from "./styles";
import { useEffect, useState } from "react";
import { Form } from "shared/components";
import api from "shared/utils/api";
import consoleLog from "shared/utils/consoleLog";
import { color } from "shared/utils/styles";
import toast from "shared/utils/toast";

type PropsT = {
  agentId?: string;
  initialValues: any;
  step: number;
  setStep: Function;
  setInitialValues: Function;
};
const Contact = ({
  agentId,
  initialValues,
  step,
  setStep,
  setInitialValues,
}: PropsT) => {
  // // console.log("Values: ", initialValues);
  const [isLoginIn, setIsLoginIn] = useState(false);
  const [defaultContactValues, setDefaultContactValues] = useState({
    address: initialValues?.address || "",
    landmark: initialValues?.landmark || "",
    town: initialValues?.town || "",
    lga: initialValues?.lga || "",
    state: initialValues?.state || "",
    country: initialValues?.country || "",
  });

  const onKeyChange = (key: string, value: any) => {
    let oldValues: any = initialValues;
    oldValues[key] = value;
    // setDefaultContactValues(oldValues);

    setInitialValues((prevFormValues: any) => ({
      ...prevFormValues,
      [key]: value,
    }));

    setDefaultContactValues((prevFormValues) => ({
      ...prevFormValues,
      [key]: value,
    }));
  };

  const onSubmit = async () => {
    try {
      if (defaultContactValues) {
        setIsLoginIn(true);

        let subAgent = {
          ...defaultContactValues,
          id: agentId,
        };

        const res = await api.post("/agency-banking/profile/update", subAgent);
        toast.success(res.message);
        setStep(3);
      }
    } catch (err) {
      const error: any = err;
      // // console.log(error);
      const resMessage =
        error.message === "Successfully retrieved business agents"
          ? "Error sub agent already exists"
          : error.message;
      toast.error(`${resMessage}`);
    } finally {
      setIsLoginIn(false);
    }
  };

  return (
    <>
      <TitleCase>Contact details</TitleCase>
      <SubTitle>
        With your corporate account number, you are guaranteed of your
        settlement
      </SubTitle>
      <FormBody>
        <Form
          enableReinitialize
          initialValues={initialValues}
          validations={{
            address: [Form.is.required()],
            landmark: [Form.is.required()],
            lga: [Form.is.required()],
            state: [Form.is.required()],
            country: [Form.is.required()],
          }}
          onSubmit={onSubmit}
          validate={function({}: {}) {
            // throw new Error("Function not implemented.");
          }}
          validateOnBlur={true}
        >
          <FormElement>
            <SubAgentForm>
              {/* @ts-ignore */}

              <Form.Field.Input
                name="Address"
                type="text"
                label="Address"
                value={initialValues?.address}
                onChange={(e: any) => onKeyChange("address", e)}
                // tip="Kindly enter your BVN"
              />
              <Form.Field.Input
                name="Landmark"
                type="text"
                label="Landmark"
                value={initialValues?.landmark}
                onChange={(e: any) => onKeyChange("landmark", e)}
                // tip="Kindly enter your BVN"
              />

              <Form.Field.Input
                name="town"
                type="text"
                label="Town"
                value={initialValues?.town}
                onChange={(e: any) => onKeyChange("town", e)}
                // tip="Kindly enter your BVN"
              />

              <Form.Field.Input
                name="country"
                type="text"
                label="Country"
                value={initialValues?.country}
                onChange={(e: any) => onKeyChange("country", e)}
                // tip="Kindly enter your BVN"
              />
              <Form.Field.Input
                name="state"
                type="text"
                label="State"
                value={initialValues?.state}
                onChange={(e: any) => onKeyChange("state", e)}
                // tip="Kindly enter your BVN"
              />
              <Form.Field.Input
                name="lga"
                type="text"
                label="Local govt"
                value={initialValues?.lga}
                onChange={(e: any) => onKeyChange("lga", e)}
                // tip="Kindly enter your BVN"
              />
            </SubAgentForm>
            <ButtonContainer variant={agentId}>
              {agentId != "" && (
                <ActionButton
                  variant={color.transparent}
                  onClick={() => {
                    setStep(step + 1);
                  }}
                >
                  Skip
                </ActionButton>
              )}
              <>
                {step > 1 && (
                  <ActionButton
                    type="button"
                    variant={color.greyColor}
                    onClick={() => {
                      setStep(step - 1);
                    }}
                  >
                    Back
                  </ActionButton>
                )}

                <ActionButton
                  type="submit"
                  isWorking={isLoginIn}
                  onClick={onSubmit}
                >
                  Next
                </ActionButton>
              </>
            </ButtonContainer>
          </FormElement>
        </Form>
      </FormBody>
    </>
  );
};

export default Contact;
