import { color as colors } from "shared/utils/styles";
import {
  CheckBox,
  CheckBoxLabel,
  CheckBoxWrapper,
  SwitchContainer,
  SwitchLink,
} from "./Styles";

interface ToggleProps {
  id: any;
  status: boolean;
  label: string;
  color?: string;
  style?: any;
  toggleMethod?: (item: any) => any;
  onClick?: any;
  stopPropagating?: boolean;
}
const Switch = ({
  id,
  status,
  label,
  toggleMethod,
  color,
  style,
  onClick,
  stopPropagating,
}: ToggleProps) => {
  const toggleNow = () => {
    toggleMethod && toggleMethod(!status);
  };
  return (
    <SwitchContainer style={{ ...style }}>
      <CheckBoxWrapper>
        <CheckBox
          id={id}
          type="checkbox"
          onChange={() => !stopPropagating && toggleNow()}
          checked={status}
          onClick={onClick}
          // color={color ? colors.fountain : ""}
          style={{ backgroundColor: "red" }}
          color={status ? "#219653" : "red"}
        />
        <CheckBoxLabel htmlFor={id} />
      </CheckBoxWrapper>
      {color && (
        <SwitchLink style={{ color: status ? colors.fountain : "red" }}>
          {/* "#292929" */}
          {label}
        </SwitchLink>
      )}
      {!color && (
        <SwitchLink style={{ color: status ? " #219653" : "red" }}>
          {/* "#292929" */}
          {label}
        </SwitchLink>
      )}
    </SwitchContainer>
  );
};

export default Switch;
