import React, { useEffect, useState } from "react";
import { Button, Form } from "shared/components";
import {
  ActionButton,
  ComponentBody,
  ComponentFooter,
  FormElement,
  StyledLink,
  StyledLinkAnchor,
  Title,
} from "./Styles";
import toast from "shared/utils/toast";
import { useHistory } from "react-router-dom";
import api from "shared/utils/api";
// import { holdEmail } from "shared/utils/authToken";
import { authPath } from "apiServices/paths";
import { storeTempId } from "shared/utils/authToken";
import { omniSaver } from "shared/utils/authToken";
import { testEmail } from "shared/utils/formValidation";

const Signup = () => {
  // window.location.replace("https://fountainpay.ng/coming-soon");
  // return null;
  const [isFormValid, setFormValid] = useState(false);
  const [isLoginIn, setIsLoginIn] = useState(false);
  const history = useHistory();

  const onSubmit = async (values: any, form: any) => {
    omniSaver("otp_email", values.email);
    if (isFormValid) {
      setIsLoginIn(true);
      try {
        const { payload }: any = await api.post("/authentication/send-otp", {
          ...values,
        });
        storeTempId(payload.tempId);
        setIsLoginIn(false);
        // toast.success("Login was suc cessful.");
        history.push(authPath.OTP);
      } catch (error) {
        // @ts-ignore
        toast.error(error ?? "Something went wrong");
        setIsLoginIn(false);
      }
    }
  };
  const validateEmail = (val: string) => {
    let obj = testEmail(val);
    setFormValid(obj);
  };
  return (
    <ComponentBody>
      <Title>WELCOME TO FOUNTAINPAY</Title>
      {/* @ts-ignore */}
      <Form
        enableReinitialize
        initialValues={{
          email: "",
        }}
        validations={{
          email: [Form.is.required(), Form.is.email()],
        }}
        onSubmit={onSubmit}
      >
        <FormElement>
          <Form.Field.Input
            name="email"
            label="Email address"
            onChange={(e: any) => validateEmail(e)}
            // tip="Kindly enter your user email address."
          />

          <ActionButton
            type="submit"
            variant="primary"
            isWorking={isLoginIn}
            disabled={!isFormValid}
          >
            Create Account
          </ActionButton>

          <ComponentFooter>
            By clicking the create your account button, you agree to
            Fountainpay’s{" "}
            <StyledLinkAnchor
              href="https://fountainpay.ng/privacy"
              target="_blank"
            >
              {" "}
              privacy policy
            </StyledLinkAnchor>
          </ComponentFooter>

          <ComponentFooter>
            Already have an account?{" "}
            <StyledLink to="/authenticate"> Login</StyledLink>
          </ComponentFooter>
        </FormElement>
      </Form>
    </ComponentBody>
  );
};

export default Signup;
