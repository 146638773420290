import { useRef, useState } from "react";

import Icon from "shared/components/Icon";
import { KeyCodes } from "shared/constants/keyCodes";
import useOnOutsideClick from "shared/hooks/onOutsideClick";

import Dropdown from "./Dropdown";
import {
  AddMore,
  ChevronIcon,
  Placeholder,
  StyledSelect,
  ValueContainer,
  ValueMulti,
  ValueMultiItem,
} from "./Styles";

type PropT2 = {
  [x: number]: any;
};

type PropT = {
  className: string;
  variant: "normal" | "empty";
  dropdownWidth: number;
  name: string;
  value: any[] | string | number;
  defaultValue: any;
  placeholder: string;
  invalid: boolean;
  styleTransparent?: boolean;
  options: any[];
  onChange: (x: any) => void;
  onCreate: () => void;
  isMulti: boolean;
  withClearValue: boolean;
  renderValue: (x: any) => void;
  renderOption: (x: any) => void;
};

const defaultProps = {
  className: undefined,
  variant: "normal",
  dropdownWidth: undefined,
  name: undefined,
  value: undefined,
  defaultValue: undefined,
  placeholder: "Select",
  invalid: false,
  styleTransparent: false,
  onCreate: undefined,
  isMulti: false,
  withClearValue: true,
  renderValue: undefined,
  renderOption: undefined,
};

const isStyleObject = (obj: any) => typeof obj === "object";

const Select = ({
  className,
  variant,
  dropdownWidth,
  name,
  value: propsValue,
  defaultValue,
  placeholder,
  invalid,
  styleTransparent,
  options,
  onChange,
  onCreate,
  isMulti,
  withClearValue,
  renderValue: propsRenderValue,
  renderOption: propsRenderOption,
}: PropT) => {
  const [stateValue, setStateValue] = useState(
    defaultValue || (isMulti ? [] : null)
  );
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const isControlled = propsValue !== undefined;
  const value = isControlled ? propsValue : stateValue;

  const $selectRef: any = useRef();
  const $inputRef: any = useRef();

  const activateDropdown = () => {
    if (isDropdownOpen) {
      $inputRef.current.focus();
    } else {
      setDropdownOpen(true);
    }
  };

  const deactivateDropdown = () => {
    setDropdownOpen(false);
    setSearchValue("");
    $selectRef.current.focus();
  };

  useOnOutsideClick($selectRef, isDropdownOpen, deactivateDropdown);

  const preserveValueType = (newValue: any[]) => {
    const areOptionValuesNumbers = options.some(
      (option) => typeof option.value === "number"
    );

    if (areOptionValuesNumbers) {
      if (isMulti) {
        return newValue.map(Number);
      }
      if (newValue) {
        return Number(newValue);
      }
    }
    return newValue;
  };

  const handleChange = (newValue: any[]) => {
    if (!isControlled) {
      setStateValue(preserveValueType(newValue));
    }
    
    onChange(preserveValueType(newValue));
  };

  const removeOptionValue = (optionValue: string | number) => {
    handleChange(value.filter((val: any) => val !== optionValue));
  };

  const handleFocusedSelectKeydown = (event: any) => {
    if (isDropdownOpen) return;

    if (event.keyCode === KeyCodes.ENTER) {
      event.preventDefault();
    }
    if (
      event.keyCode !== KeyCodes.ESCAPE &&
      event.keyCode !== KeyCodes.TAB &&
      !event.shiftKey
    ) {
      setDropdownOpen(true);
    }
  };

  const getOption = (optionValue: string | number) =>
    options?.find((option) => option.value === optionValue);
  const getOptionLabel = (optionValue: string | number) =>
    (getOption(optionValue) || { label: "" }).label;

  const isValueEmpty = isMulti ? !value.length : !getOption(value);

  return (
    <StyledSelect
      className={className}
      variant={variant}
      ref={$selectRef}
      // @ts-ignore
      tabIndex="0"
      onKeyDown={handleFocusedSelectKeydown}
      invalid={invalid}
      isTransparent={styleTransparent}>
      <ValueContainer
        variant={variant}
        data-testid={name ? `select:${name}` : "select"}
        onClick={activateDropdown}>
        {isValueEmpty && (
          <Placeholder isTransparent={styleTransparent}>
            {placeholder}
          </Placeholder>
        )}

        {!isValueEmpty && !isMulti && propsRenderValue
          ? propsRenderValue({ value })
          : getOptionLabel(value)}

        {!isValueEmpty && isMulti && (
          <ValueMulti variant={variant}>
            {value.map((optionValue: number | string) =>
              propsRenderValue ? (
                propsRenderValue({
                  value: optionValue,
                  removeOptionValue: () => removeOptionValue(optionValue),
                })
              ) : (
                <ValueMultiItem
                  key={optionValue}
                  onClick={() => removeOptionValue(optionValue)}>
                  {getOptionLabel(optionValue)}
                  <Icon type="close" size={14} />
                </ValueMultiItem>
              )
            )}
            <AddMore>
              <Icon type="plus" />
              Add more
            </AddMore>
          </ValueMulti>
        )}

        {!styleTransparent &&
          (!isMulti || isValueEmpty) &&
          variant !== "empty" && <ChevronIcon type="chevron-down" top={1} />}
      </ValueContainer>

      {isDropdownOpen && (
        <Dropdown
          dropdownWidth={dropdownWidth}
          value={value}
          isValueEmpty={isValueEmpty}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          $selectRef={$selectRef}
          $inputRef={$inputRef}
          deactivateDropdown={deactivateDropdown}
          // @ts-ignore
          options={options}
          onChange={handleChange}
          onCreate={onCreate}
          isMulti={isMulti}
          withClearValue={withClearValue}
          propsRenderOption={propsRenderOption}
        />
      )}
    </StyledSelect>
  );
};

// Select.propTypes = propTypes;
Select.defaultProps = defaultProps;

export default Select;
