import { ActionBtn } from "Kyc/Styles";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import DashboardSubHeader from "shared/components/DashboardSubHeader";
import Table from "shared/components/Table";
import ViewButton from "shared/components/Table/ViewButton";
import Modal from "shared/quickComponents/modal";
import {
  ModalContentLeft,
  ModalContentRight,
  ModalContentRow,
  UserModal,
} from "SystemStatus/Styled";

const headData = [
  { id: "description", label: "Activity Description" },
  { id: "useremail", label: "user" },
  { id: "date", label: "Date and Time" },
  { id: "funcProps", label: "" },
];

export const statusReport = [
  {
    id: 1,
    useremail: "olabode@me.com",
    description: "Logged into your account",
    date: "June 9, 2022 11:00PM",
  },
  {
    id: 2,
    useremail: "user@me.com",
    description: "Logged into your account",
    date: "June 9, 2022 11:00PM",
  },
];

const AllStatusPage = () => {
  const [showModal, setShowModal] = useState(false);
  const [User, setUser] = useState<any>("");

  const openModal = (activity: any) => {
    setUser(activity);
    setShowModal(true);
  };

  const activiies = statusReport.map((activity) => ({
    id: activity.id,
    useremail: activity.useremail,
    description: activity.description,
    date: activity.date,
    funcProps: (
      <div onClick={() => openModal(activity)}>
        <ViewButton />
      </div>
    ),
  }));
  return (
    <div>
      <DashboardSubHeader
        count={3}
        title="System Status"
        btnTitle="Download Summary"
        withFilter={true}
        withSearch={true}
      />
      <Table headData={headData} bodyData={activiies} />
      <Modal
        isOpen={showModal}
        onClose={() => setShowModal(!showModal)}
        closeButton={true}
        width="40%"
      >
        <UserModal>
          <ModalContentRow>
            <ModalContentLeft>User: </ModalContentLeft>
            <ModalContentRight>{User?.useremail} </ModalContentRight>
          </ModalContentRow>
          <ModalContentRow>
            <ModalContentLeft>Description: </ModalContentLeft>
            <ModalContentRight>{User?.description} </ModalContentRight>
          </ModalContentRow>
          <ModalContentRow>
            <ModalContentLeft>Merchant: </ModalContentLeft>
            <ModalContentRight>{User?.merchant} </ModalContentRight>
          </ModalContentRow>
          <ModalContentRow>
            <ModalContentLeft>Time: </ModalContentLeft>
            <ModalContentRight>{User?.time} </ModalContentRight>
          </ModalContentRow>
          <ModalContentRow>
            <ModalContentLeft>Date: </ModalContentLeft>
            <ModalContentRight>{User?.time} </ModalContentRight>
          </ModalContentRow>
        </UserModal>
        <ActionBtn>Download Report</ActionBtn>
      </Modal>
    </div>
  );
};

export default AllStatusPage;
