import breakpoints  from 'shared/utils/breakpoints';
import styled from "styled-components";
import { Button } from "shared/components";
import { color, font } from "shared/utils/styles";

export const ParentCont = styled.div`
    background-color: #E5E5E5;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;
