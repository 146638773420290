import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import useApi from "shared/hooks/api";
import { PageLayout } from "shared/section_components/Styles";
import { RootStateOrAny, useSelector } from "react-redux";

import {
  PersonalFormCont,
  FormGroup,
  Span,
  FormInputContainer,
  FormInput,
  InputLabelContainer,
} from "./Styles";
import { Divider } from "shared/section_components/Styles";
import { SharedInput } from "shared/section_components/ShareInput";
import { GhostButton } from "shared/section_components/Styles";
import Layout from "Layout";
import { useHistory } from "react-router";

const BusinessSettings = () => {
  const settings = useSelector((store: RootStateOrAny) => store.settings);
  const currentUser = useSelector((store: RootStateOrAny) => store.currentUser);
  const [agencyBankingForm, setagencyBankingForm] = useState<boolean>(false);
  const [gatewayForm, setgatewayForm] = useState<boolean>(true);
  const history = useHistory();

  const handleToogle = () => {
    setagencyBankingForm(!agencyBankingForm);
    setgatewayForm(!gatewayForm);
  };

  const currentBusiness = useSelector(
    (store: RootStateOrAny) => store.currentBusiness
  );

  const redirectToChangePaswword = () => {
    history.push("/settings/change-login-details");
  };

  const [{ isUpdating }, updateBusiness] = useApi.put("/business");

  const [{ data, error, setLocalData }, fetchbusiness] = useApi.get(
    `/business/${currentBusiness?.id}`
  );
  const { business } = data || {};

  // =================== Local State and functions ====================
  const [businessImage, setbusinessImage] = useState<string>();

  const imageChange = (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      const objectUrl: string = URL.createObjectURL(e.target.files[0]);
      setbusinessImage(objectUrl);
    }
  };
  // =================================================================

  useEffect(() => {
    // // console.log("user data", currentUser);
  }, []);

  return (
    <>
      {/* <Layout PageTitle="Profile"> */}
      {/* {!business && <PageLoader /> } */}
      {/* {error && <PageError /> } */}
      <PageLayout>
        <div>
          <h2 style={{ marginTop: "2rem" }}>Personal Information</h2>
          <Divider />
          <PersonalFormCont>
            <FormGroup>
              <SharedInput
                onChange={() => ""}
                placeholder={currentUser?.firstName}
                disabled={true}
                label="First name"
              />
              <SharedInput
                onChange={() => ""}
                placeholder={currentUser?.lastName}
                disabled={true}
                label="Last name"
              />
            </FormGroup>
            <SharedInput
              onChange={() => ""}
              placeholder={currentUser?.email}
              disabled={true}
              label="Email address"
            />

            <div style={{ marginTop: "1rem" }}>
              <Span>Password</Span>
              <div
                onClick={redirectToChangePaswword}
                style={{ marginTop: "0.4rem", width: "48%" }}
              >
                <GhostButton>Change login details</GhostButton>
              </div>
            </div>
          </PersonalFormCont>
        </div>
        <br />
        {currentUser?.phone && (
          <div style={{ marginTop: "8rem", marginBottom: "8rem" }}>
            <h2 style={{ marginTop: "2rem" }}>Phone Number</h2>
            <Divider />

            <div style={{ marginTop: "0.4rem" }}>
              <FormInputContainer>
                <InputLabelContainer>
                  <span>Phone Number</span>
                  {/* <span style={{ cursor: "pointer" }}>Edit</span> */}
                </InputLabelContainer>
                <FormInput
                  placeholder={`+${currentUser.phone}`}
                  disabled={true}
                />
              </FormInputContainer>
            </div>
          </div>
        )}
      </PageLayout>

      {/* </Layout> */}
    </>
  );
};

export default BusinessSettings;
