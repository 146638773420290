import { openDB } from "idb";
import consoleLog from "shared/utils/consoleLog";

const dbPromise = () => {
  if (!("indexedDB" in window)) {
    throw new Error("Browser does not support IndexedDB");
  }
  //consoleLog(openDB)

  return openDB("patrec", 1, {
    upgrade(db) {
      if (!db.objectStoreNames.contains("outlet")) {
        db.createObjectStore("businesses").createIndex(
          "businesses",
          "businesses",
          { unique: true }
        );
      }

      if (!db.objectStoreNames.contains("business")) {
        db.createObjectStore("business").createIndex("code", "code", {
          unique: true,
        });
      }

      if (!db.objectStoreNames.contains("user")) {
        db.createObjectStore("user").createIndex("id", "id", { unique: true });
      }

      if (!db.objectStoreNames.contains("settings")) {
        db.createObjectStore("settings").createIndex("code", "code", {
          unique: true,
        });
      }
    },
  });
};

const checkStorage = async (storeName: string) => {
  try {
    const db = await dbPromise();
    const tx = db.transaction(storeName, "readonly");
    const store = tx.objectStore(storeName);
    return store.get(storeName);
  } catch (error) {
    return error;
  }
};

const saveToStorage = async (storeName: string, tasks: object) => {
  try {
    //consoleLog(tasks)
    const db = await dbPromise();
    const tx: any = db.transaction(storeName, "readwrite");
    const store = tx.objectStore(storeName);

    store.put(tasks, storeName); //

    return tx.complete;
  } catch (error) {
    return error;
  }
};

const updateSorage = async (
  storeName: string,
  indices: number,
  search: string,
  value: any
) => {
  try {
    const db = await dbPromise();

    const tx = db.transaction(storeName, "readwrite");
    const index = tx.store.index("code");

    for await (const cursor of index.iterate(search)) {
      let found = { ...cursor.value };
      found = value;
      cursor.update(found);
    }
    await tx.done;
  } catch (error) {
    return error;
  }
};
export default {
  checkStorage,
  saveToStorage,
  updateSorage,
};
