import styled from "styled-components";

export const SubHeader = styled.div`
  height: 72px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #FFFFFF;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.05);
  margin-bottom: 50px;
  padding-left: 20px;
  padding-right: 20px;
`;

export const SubHeaderChildren = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const SubHeaderChildren2 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 580px;
`;

export const SubAccCount = styled.p`
  font-weight: 600;
  font-size: 18px;
  line-height: 21.01px;
  color:  #292929;
`;

export const CreateSubAccBtn = styled.button`
  background-color: #760000;
  border: none
  outline: none;
  height: 54px;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  line-height: 21px;
  width: 241px;
  cursor: pointer;
  border-radius: 5px;
`;

export const StateContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;