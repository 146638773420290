import { Edit } from "./Styles";

const ViewButton = () => {
  return (
    <Edit>
      <p>View</p>
    </Edit>
  );
};

export default ViewButton;
