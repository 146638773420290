import React, { useEffect, useState } from "react";
import { Button, Form, Icon } from "shared/components";
import {
  ActionButton,
  ComponentBody,
  ComponentFooter,
  FormElement,
  StyledLink,
  Title,
  PasswordCont,
} from "./Styles";
import toast from "shared/utils/toast";
import { useHistory } from "react-router-dom";
import api from "shared/utils/api";
import Preloader from "shared/components/preloader";
import * as Yup from "yup";
import PasswordMeter from "../PasswordMeter";
import { holdPass } from "shared/utils/authToken";
import { authPath } from "apiServices/paths";
import BackLogo from "App/assets/images/Auth/Back.svg";
import { BackImage, Back } from "../AccountRecovery/Styles";
import { setOptions } from "react-chartjs-2/dist/utils";
import consoleLog from "shared/utils/consoleLog";

const Schema = Yup.object().shape({
  password: Yup.string().required("Please Enter your password"),
  confirmPassword: Yup.string()
    .required()
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
});

const SetForgotPassword = ({
  opt,
  newMember,
  setNewPage,
  formstate,
  setFormstate,
}: any) => {
  const [isLoginIn, setIsLoginIn] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [passwordVisibleA, setPasswordVisibleA] = useState(false);
  const [passwordVisibleB, setPasswordVisibleB] = useState(false);
  const history = useHistory();

  const initlValues = {
    password: "",
    confirmPassword: "",
  };

  const onSubmit = async (value: any, form: any) => {
    holdPass(value);
    setIsLoginIn(true);
    let tempId: any = localStorage.getItem("tempId");
    const values: any = {
      tempId,
      password,
    };

    try {
      if (newMember) {
        values.firstName = firstName;
        values.lastName = lastName;
        values.confirmPassword = confirmPassword;
        values.invitationId = formstate?.invitationId;
        let res = await api.put("/business/111111/team", {
          ...values,
        });
        toast.success(res?.message);
      } else if (opt) {
        values.otp = otp;
        values.tempId = formstate?.tempId;
        await api.put("/account/recovery", {
          ...values,
        });
      } else {
        await api.post("/authentication/reset-password", {
          ...values,
        });
      }

      setIsLoginIn(false);
      history.push(authPath.PASSWORD_RESET_SUCCESS);
    } catch (error) {
      // @ts-ignore
      toast.error(error ?? "Something went wrong");
      setIsLoginIn(false);
    }
  };

  const handleKeyPressEvent = (value: any) => {
    setPassword(value);
  };

  useEffect(() => {
    if (
      (firstName &&
        firstName.trim() !== "" &&
        lastName &&
        lastName.trim() !== "" &&
        password !== "") ||
      (opt && otp.length === 6 && password !== "") ||
      (!opt && password !== "" && !firstName && !lastName)
    ) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [password, otp, firstName, lastName]);

  const handleBack = () => {
    if (opt) {
      setNewPage(4);
    } else {
      history.goBack();
    }
  };

  if (opt && opt !== 5) {
    history.push("/authenticate");
    return <></>;
  }

  return (
    <ComponentBody>
      {!newMember && (
        <Back onClick={handleBack}>
          <BackImage src={BackLogo} />
        </Back>
      )}
      <Title>{newMember ? "CREATE NEW ACCOUNT" : "SET NEW PASSWORD"}</Title>
      {/* @ts-ignore */}
      <Form
        enableReinitialize
        initialValues={initlValues}
        validationSchema={Schema}
        onSubmit={onSubmit}
      >
        <FormElement>
          {opt && (
            <Form.Field.Input
              name="otp"
              type="number"
              label="OTP"
              maxLength={6}
              value={otp}
              style={{ letterSpacing: 20, textAlign: "center" }}
              onChange={(e: any) => e.toString().length <= 6 && setOtp(e)}
              // tip="Kindly enter your user email address."
            />
          )}

          {newMember && (
            <>
              <Form.Field.Input
                name="lastName"
                type="text"
                label="Last Name"
                onChange={(e: any) => setLastName(e)}
              />
              <Form.Field.Input
                name="firstName"
                type="text"
                label="First Name"
                onChange={(e: any) => setFirstName(e)}
              />
            </>
          )}
          <PasswordCont>
            <Form.Field.Input
              name="password"
              type={!passwordVisibleA ? "password" : "text"}
              label="Password"
              onChange={handleKeyPressEvent}
              // tip="Kindly enter your user email address."
            />

            <Icon
              onClick={() => setPasswordVisibleA(!passwordVisibleA)}
              type={!passwordVisibleA ? "eye" : "eye-blocked"}
              style={{
                color: "#760000",
                fontSize: 18,
                position: "absolute",
                right: 5,
                top: 40,
              }}
            />
          </PasswordCont>

          <PasswordCont>
            <Form.Field.Input
              name="confirmPassword"
              type={!passwordVisibleB ? "password" : "text"}
              label="Confirm Password"
              onChange={(e: any) => setConfirmPassword(e)}
              // tip="Kindly enter your user email address."

              // tip="Kindly enter your user password."
            />

            <Icon
              onClick={() => setPasswordVisibleB(!passwordVisibleB)}
              type={!passwordVisibleB ? "eye" : "eye-blocked"}
              style={{
                color: "#760000",
                fontSize: 18,
                position: "absolute",
                right: 5,
                top: 40,
              }}
            />
          </PasswordCont>
          {/* need to add terms and agreement */}
          {newMember && <>{/*checkbox*/}</>}
          <ActionButton
            type="submit"
            variant="primary"
            disabled={!isFormValid}
            isWorking={isLoginIn}
          >
            {newMember ? "Submit" : "Set Password"}
          </ActionButton>
        </FormElement>
      </Form>
      <PasswordMeter password={password} />
    </ComponentBody>
  );
};

export default SetForgotPassword;
