import api from "shared/utils/api";
import { getStoreLocalStorage, storeToLocalStorage } from "shared/utils/authToken";
import consoleLog from "shared/utils/consoleLog";
import toast from "shared/utils/toast";
class MandatesClass {

  async authorizeService(apiKey: string) {
    
    try {
      const response = await api.post(`${process.env.REACT_APP_API_MODAL_URL}/sdk/v1/direct/get-authorization-token`, {"api_key":apiKey});
      // alert(JSON.stringify(response))
      if (response?.status) {
        storeToLocalStorage('apiToken', response?.payload.accessToken)
        
      }
    } catch (error) {
      //alert(JSON.stringify(error))
      consoleLog("Unable to authorize request", error);
    }
  }

  async getBanks() {
    try {
      const response = await api.get(`${process.env.REACT_APP_API_MODAL_URL}/checkout/bank`, {
        'x-api-client': "modal",
        'Authorization': `Bearer ${getStoreLocalStorage('apiToken')}`
      });
      if (response?.status) return response?.payload;
    } catch (error) {
      consoleLog("error fetching mandates", error);
    }
  }

  async getMandates(query: any) {
    try {
      const response = await api.post(`${process.env.REACT_APP_API_MODAL_URL}/sdk/v1/direct/list-mandates`, query, {
        'x-api-client': "modal",
        'Authorization': `Bearer ${getStoreLocalStorage('apiToken')}`
      });
      if (response?.status) return response?.payload;
    } catch (error) {
      consoleLog("error fetching mandates", error);
    }
  }

  async createMandate(data: any) {
    consoleLog("invite payload business id", data);
    try {
      const response = await api.post(`${process.env.REACT_APP_API_MODAL_URL}/sdk/v1/direct/create-e-mandate`, data, {
        'x-api-client': "modal",
        'Authorization': `Bearer ${getStoreLocalStorage('apiToken')}`
      });
      if (response.status) return response;
    } catch (error) {
      consoleLog("error creating mandate", error);
      return error;
    }
  }

  async fundTransfer(data: any) {

    try {
      const response = await api.post(
        `${process.env.REACT_APP_API_MODAL_URL}/sdk/v1/direct/transfer-funds`,
        data,
        {
          'x-api-client': "modal",
          'Authorization': `Bearer ${getStoreLocalStorage('apiToken')}`
        }
      );
      if (response.status) return response;
    } catch (error) {
      consoleLog("error transfering funds", error);
      return error;
    }
  }

  async nameEnquiry(data: any) {
    try {
      const response = await api.post(
        `${process.env.REACT_APP_API_MODAL_URL}/sdk/v1/direct/name-enquiry`, data, {
          'x-api-client': "modal",
          'Authorization': `Bearer ${getStoreLocalStorage('apiToken')}`
        }
      );
      consoleLog(response, "resendInvite");
      if (response.status && response.message) {
        toast.success(response.message);
      }
      return response;
    } catch (error) {
      consoleLog("error resending Invite", error);
      return error;
    }
  }

  async balanceEnquiry(data: any) {
    try {
      const response = await api.post(
        `${process.env.REACT_APP_API_MODAL_URL}/sdk/v1/direct/balance-enquiry`, data, {
          'x-api-client': "modal",
          'Authorization': `Bearer ${getStoreLocalStorage('apiToken')}`
        }
      );
      consoleLog(response, "resendInvite");
      if (response.status && response.message) {
        toast.success(response.message);
      }
      return response;
    } catch (error) {
      consoleLog("error resending Invite", error);
      return error;
    }
  }


  async deleteMandate(reference: string) {
    try {
      const response = await api.post(
        `${process.env.REACT_APP_API_MODAL_URL}/sdk/v1/direct/delete-mandate`,
        {
          "mandate_ref":reference
        },
        {
          'x-api-client': "modal",
          'Authorization': `Bearer ${getStoreLocalStorage('apiToken')}`
        }
      );
      if (response.status && response.message) {
        toast.success(response.message);
      }

      return response;
    } catch (error) {
      consoleLog("error resending Invite", error);
      return error;
    }
  }
}

const teamOBJ = new MandatesClass();
export default teamOBJ;
