import styled from "styled-components";
import { color } from "shared/utils/styles";

type ButtonT = {
  bg?: string;
};
type actitonColorT = {
  color: string;
};

export const PageContainer = styled.div`
  /* border: 1px solid red; */
  height: 90vh;
  /* overflow: scroll; */
  /* padding: 2rem; */
`;
export const PageHeader = styled.div`
  width: 100%;
  height: 68px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  background: #fff;
  margin-bottom: 20px;
  margin-top: 10px;
  h4 {
    color: ${color.textDark};
    width: 28%;
  }
`;

export const ButtonsContainer = styled.div`
  width: 45%;
  justify-content: end;
  display: flex;
  gap: 2rem;
`;

export const AddTeamBtn = styled.button<ButtonT>`
  padding: 1rem 4rem;
  border-radius: 0.2rem;
  background: ${(prop) => (prop.bg ? prop.bg : color.fountain)};
  color: #fff;
  cursor: pointer;
`;

export const TableContainer = styled.div`
  overflow: auto;
  height: 65vh;
`;

export const TableActions = styled.div`
  display: flex;
  justify-content: end;
  gap: 0.1rem;
`;

export const ViewAllBtn = styled.div`
  cursor: pointer;
  color: #fff;
  border-radius: 0.4rem;
  padding: 0.6rem 1rem;
  font-size: 12px;
  display: flex;
  gap: 0.2rem;
  align-items: center;
  span {
    color: green;
    font-size: 0.8rem;
  }
`;

export const DeleteText = styled.div`
  color: red;
  font-size: 0.8rem;
`;
export const ActionBtn = styled.img`
  width: 0.8rem;
  height: auto;
`;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem auto;
  background: #fff;
  padding: 0 1rem;
  height: 4.3rem;
  width: 100%;
  position: relative;
  bottom: 0;
  border: 1px solid ${color.backgroundMedium};
`;

export const PaginationButtonsContainer = styled.div`
  display: flex;
  gap: 1.4rem;
`;

export const PaginationNext = styled.button`
  background-color: ${color.fountain};
  color: #fff;
  padding: 0.4rem 0.6rem;
  border-radius: 0.4rem;
  cursor: pointer;
  font-size: 12px;
`;

export const PaginationPrev = styled.button`
  background-color: #bf7a79;
  color: #fff;
  padding: 0.4rem 0.6rem;
  border-radius: 0.4rem;
  font-size: 12px;
  cursor: pointer;
`;
