import { useEffect, useState } from "react";
import {
  ContentHeader,
  ContentRow,
  InnerPanelLayout,
  LoadingCont,
  PanelTitle,
  ViewAll,
} from "./Style";
import { useHistory } from "react-router-dom";
import { RootStateOrAny, useSelector } from "react-redux";
import api from "shared/utils/api";
import { Spinner } from "shared/components";
import { formatMoney__ } from "shared/utils/moneyFormat";
import { formateDate } from "shared/utils/utility";

const data = [
  { date: "27/07/2023", value: 1000 },
  { date: "27/07/2023", value: 1000 },
  { date: "27/07/2023", value: 1000 },
  { date: "27/07/2023", value: 1000 },
  { date: "27/07/2023", value: 1000 },
];
const PendingSettlement = () => {
  const [datas, setData] = useState<any>(data);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const service = useSelector((store: RootStateOrAny) => store.service);
  const history=useHistory()

  const [settlementDetails, setSettlementDetails] = useState<any>(null)

  const getAmountDetails=async()=>{
    setIsLoading(true);
    try{
      // var now = new Date();
      // const first=now.setDate(now.getDate() - 30);
      const details=await api.get(`/services/${service?.id}/settlements`)
      if(details.status){
        setSettlementDetails(details.payload)
      }
    }catch(error: any){
      // console.log("Pending Settlement: ", error)
    }finally{
      setIsLoading(false);
    }
    
  }
  useEffect(()=>{
    getAmountDetails();
  }, [])
  const arrayDataItems = settlementDetails && settlementDetails.slice(0, 5).map((item: any, id: number) => (
    <ContentRow key={id}>
      <span>{formateDate(item.updatedAt)}</span>
      <span>{formatMoney__(item.amount)}</span>
    </ContentRow>
  ));
  return (
    <InnerPanelLayout>
      <PanelTitle>Past Settlements</PanelTitle>
      <ContentHeader>
        <span>Date</span>
        <span>Total volume</span>
      </ContentHeader>
      {isLoading ? (
        <LoadingCont>
          <Spinner />
        </LoadingCont>
      ) : (
        <>
          {arrayDataItems}
        </>
      )}
      <ViewAll onClick={()=>history.push(`/my-apps/${service?.id}/settlement?type=pending`)}>View all</ViewAll>
    </InnerPanelLayout>
  );
};

export default PendingSettlement;
