export const ActionTypes = {
  SET_BUSINESS_LIST_REQUEST: "SET_BUSINESS_LIST_REQUEST",
  SET_BUSINESS_LIST_LOADING: "SET_BUSINESS_LIST_LOADING",
  SET_BUSINESS_LISTS: "SET_BUSINESS_LISTS",
  GET_BUSINESS_LISTS: "GET_BUSINESS_LISTS",
  SET_CURRENT_BUSINESS: "SET_CURRENT_BUSINESS",
  GET_CURRENT_BUSINESS: "GET_CURRENT_BUSINESS",
  SET_CURRENT_BUSINESS_KYC: "SET_CURRENT_BUSINESS_KYC",
  GET_CURRENT_BUSINESS_KYC: "GET_CURRENT_BUSINESS_KYC",
  GET_USERS: "GET_USERS",
  SET_USERS: "SET_USERS",
  SET_USER_OTP: "SET_USER_OTP",
  GET_USER_OTP: "GET_USER_OTP",
  GET_CURRENT_USER: "GET_CURRENT_USER",
  SET_CURRENT_USER: "SET_CURRENT_USER",
  SET_SETTINGS_LISTS: "SET_SETTINGS_LISTS",
  GET_SETTINGS_LISTS: "GET_SETTINGS_LISTS",
  GET_OVERVIEW_DATA: " GET_OVERVIEW_DATA ",
  GET_AUTH_ME_BUSINESSES: "GET_AUTH_ME_BUSINESSES",
  SET_SERVICE: "SET_SERVICE",
  SET_LIVE: "SET_LIVE",
  LOGOUT: "LOGOUT",
  GET_QUESTIONS_LOADING: "GET_QUESTIONS_LOADING",
  GET_QUESTIONS_SUCCESS: "GET_QUESTIONS_SUCCESS",
  GET_QUESTIONS_FAIL: "GET_QUESTIONS_FAIL",
  KYC_START_ADDR: "KYC_START_ADDR",
  KYC_BOARDING_RECORD: "KYC_BOARDING_RECORD",
  FILTER_RECORD: "FILTER_RECORD",
};
