import React, { useState, useEffect, useLayoutEffect } from "react";
import Table from "shared/components/Table";
import {
  ActionBtn,
  AddTeamBtn,
  ButtonsContainer,
  DeleteText,
  PageContainer,
  PageHeader,
  TabHead4,
  TableActions,
  TableContainer,
  ViewAllBtn,
} from "./Styles";
import pen from "App/assets/icons/pen.png";
import trash from "App/assets/icons/trash.svg";
import { GradientLoader, Modal } from "shared/components";
import { useHistory, useParams } from "react-router-dom";
import ConfirmModal from "shared/quickComponents/ConfirmModal";
// import OtpModal from "shared/quickComponents/OtpModal";
import OtpModal from "shared/components/OtpModal";

import teamOBJ from "Settings/Team/teamClass";
import consoleLog from "shared/utils/consoleLog";
import { RootStateOrAny, useSelector } from "react-redux";
import shouldPerform, { getRoleName } from "shared/utils/assignRoles";
import EmptyState from "shared/components/FreeComp/EmptyState";
import { formatDateInSlashes } from "shared/utils/dateTime";
import toast from "shared/utils/toast";
import InvitedTeam from "./invitedTeams";
import inviteOBJ from "../Invite/inviteClass";

export default function Home() {
  const { id }: any = useParams();
  const [teamMembers, setteamMembers] = useState([]);
  const [services, setservices] = useState([] as any);
  const [currentPage, setcurrentPage] = useState(1);
  const [totalPages, settotalPages] = useState(0);
  const [isData, setisData] = useState(false);

  const [modal, setModal] = useState(false);
  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);
  const [showIndicator, setshowIndicator] = useState(false);
  const [otpModalOpen, setOtpModalOpen] = useState(false);
  const [otp, setOtp] = useState("");
  const [toggleTab, setToggleTab] = useState(id ? parseInt(id) : 0);

  const currentUser = useSelector((store: RootStateOrAny) => store.currentUser);
  const [seletedMember, setseletedMember] = useState<any>({});

  const currentBusiness = useSelector(
    (store: RootStateOrAny) => store.business
  );

  const history = useHistory();

  consoleLog(toggleTab, "toggleTab", id);
  const sendOTP = async () => {
    // setOtpModalOpen(true);
    setModal(true);
    setConfirmModalOpen(false);
    // setshowIndicator(true);
  };

  const handleOTP = async (otp: string) => {
    setOtp(otp);
    if (otp.length === 6) {
    }
  };

  const handleChangeRole = (id: string) => {
    history.push(`/team/invite/${id}`);
  };

  const handleResendInvite = async (obj: any) => {
    try {
      consoleLog(obj, "OBJ");
      await teamOBJ.resendInvite(obj);
    } catch (err) {
      // @ts-ignore
      toast.error(String(err?.message));
    } finally {
    }
  };

  const handleRemoveTeamMember = (obj: any) => {
    setseletedMember(obj);
    setConfirmModalOpen(true);
  };


  const handleAccountStatus=async(obj: any) => {
    // activate or deactivate user account access
  };

  const deleteTeamMember = async () => {
    // seletedMember
    await teamOBJ.deleteTeamMember(seletedMember);
    await getTeamMebers();
  };

  const tableHeader = [
    // { id: "sn", label: "S/N", width: "2%" },
    // { id: "name", label: "Name", width: "30%" },
    { id: "date", label: "Date" },
    { id: "email", label: "Email", width: "20%" },
    // { id: "phone", label: "Phone", width: "10%" },
    { id: "service", label: "Service", width: "20%", allowShowmore: true },
    { id: "status", label: "Status", width: "20%" },

    { id: "roles", label: "Role", width: "5%" },
    { id: "funcProps", label: "" },
  ];
  const team = teamMembers?.map((obj: any, index: number) => {
    return {
      // sn: index + 1,
      name: `${obj?.User?.lastName ?? ""} ${obj?.User?.firstName ?? ""} ${obj
        ?.User?.otherName ?? ""} `,
      phone: obj.phone,
      email: obj.email,
      service: (() => {
        if (obj?.userBusinessServices instanceof Array) {
          let newServiceArray = [];
          for (let el of obj?.userBusinessServices) {
            if (el?.Service?.name) newServiceArray.push(el?.Service?.name);
          }
          return newServiceArray.join(", ");
        }
        return "";
      })(),
      roles: obj.role,
      status: obj?.status,
      date: formatDateInSlashes(obj?.updatedAt),
      funcProps: (
        <TableActions>
          {obj?.status !== "ACTIVE" && (
            <ViewAllBtn onClick={() => handleAccountStatus(obj)}>
              <span>obj?.status</span>
            </ViewAllBtn>
          )}{" "}
          {obj?.role !== "OWNER" && (
            <>
              <ViewAllBtn onClick={() => handleRemoveTeamMember(obj)}>
                <ActionBtn src={trash} />
                <DeleteText>Delete</DeleteText>
              </ViewAllBtn>
            </>
          )}{" "}
          {shouldPerform(
            getRoleName(currentUser, currentBusiness?.id),
            "CTeams"
          ) && (
            <ViewAllBtn onClick={() => handleChangeRole(obj?.id)}>
              <ActionBtn src={pen} />
              <span color="green">Change Role</span>
            </ViewAllBtn>
          )}
        </TableActions>
      ),
    };
  });

  const getServices = async () => {
    const response = await inviteOBJ.getServices(currentBusiness?.uuid);
    if (response) {
      consoleLog("serives", response);
      const servx = (response?.services instanceof Array
        ? response?.services
        : []
      ).map((service: any) => {
        return {
          name: service?.name,
          mode: service?.mode,
          id: service?.serviceId,
          uuid: service?.id,
        };
      });
      setservices(servx);

      return servx;
    }
  };

  const getTeamMebers = async () => {
    setisData(false);
    const response = await teamOBJ.getTeamMembers(
      currentBusiness?.uuid,
      currentPage
    );

    consoleLog(response, "teammembers", currentUser);
    if (response?.data) {
      setisData(true);

      const team = response?.data.filter(
        (el: any) => el?.userId !== currentUser?.id
      ); //.filter((obj: any) => obj.status === "ACTIVE");

      if (team.length) {
        setcurrentPage(response?.currentPage);
        settotalPages(response?.totalPages);
        setteamMembers(team);
      } else {
        setteamMembers([]);
      }
    }
  };

  const nextPage = () => {
    if (currentPage < totalPages) {
      setcurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setcurrentPage(currentPage - 1);
    }
  };

  const goToPage = (val: number) => {
    if (val < currentPage) {
      prevPage();
    } else if (val > currentPage) {
      nextPage();
    }
  }; //check Tansaction in Services

  useEffect(() => {
    getTeamMebers();
  }, [currentPage]);

  useEffect(() => {
    getServices();
  }, []);

  // useEffect(() => {
  // 	//consoleLog("team member", seletedMember);
  // }, [seletedMember])

  if (!shouldPerform(getRoleName(currentUser, currentBusiness?.id), "VTeams")) {
    history.push("/authenticate");
    return null;
  }
  return (
    <div>
      <PageContainer>
        <PageHeader>
          <TabHead4 active={toggleTab === 0} onClick={() => setToggleTab(0)}>
            Team Member
          </TabHead4>
          <TabHead4 active={toggleTab === 1} onClick={() => setToggleTab(1)}>
            Invitations
          </TabHead4>
          {shouldPerform(
            getRoleName(currentUser, currentBusiness?.id),
            "CTeams"
          ) && (
            <ButtonsContainer>
              <AddTeamBtn onClick={() => history.push("/team/invite")}>
                Invite Member
              </AddTeamBtn>
            </ButtonsContainer>
          )}
        </PageHeader>
        {!isData ? (
          <GradientLoader />
        ) : (
          <>
            {toggleTab === 1 ? (
              <InvitedTeam
                businessId={currentBusiness?.uuid}
                currentBusiness={currentBusiness}
                currentUser={currentUser}
                handleResendInvite={handleResendInvite}
                handleRemoveTeamMember={handleRemoveTeamMember}
                handleChangeRole={handleChangeRole}
                services={services}
              />
            ) : (
              <TableContainer>
                <Table
                  headData={tableHeader}
                  bodyData={team}
                  rowStyle={{
                    background: "#ffffff",
                  }}
                  serialNumber
                  totalPages={totalPages ?? 0}
                  currentpage={currentPage ?? 0}
                  goToPage={goToPage}
                  // hoverLink={hoverLink}
                />
              </TableContainer>
            )}

            {/* {totalPages > 0 && (
              <PaginationContainer>
                <div>
                  {currentPage} of {totalPages} pages
                </div>

                <PaginationButtonsContainer>
                  {currentPage > 1 && (
                    <PaginationPrev onClick={prevPage}>
                      {"< "}Prev
                    </PaginationPrev>
                  )}

                  {totalPages > currentPage && (
                    <PaginationNext onClick={nextPage}>
                      Next {` >`}
                    </PaginationNext>
                  )}
                </PaginationButtonsContainer>
              </PaginationContainer>
            )} */}
          </>
        )}
      </PageContainer>
      {shouldPerform(
        getRoleName(currentUser, currentBusiness?.id),
        "CTeams"
      ) && (
        <>
          <Modal
            isOpen={modal}
            width={800}
            withCloseIcon={true}
            onClose={() => setModal(false)}
            // renderContent={() => <AddTeam />}
            renderContent={() => (
              <OtpModal
                callBackFn={deleteTeamMember}
                startHere
                closeModal={setModal}
                emailType="verification"
                type="auth-user"
              />
            )}
          />
          <ConfirmModal
            message="Are sure you want to remove this team member?"
            handleYesModalButton={sendOTP}
            handleNoModalButton={() => setConfirmModalOpen(!isConfirmModalOpen)}
            isModalOpen={isConfirmModalOpen}
            showIndicator={showIndicator}
            handleCloseModal={() => setConfirmModalOpen(!isConfirmModalOpen)}
          />
        </>
      )}

      {/* <OtpModal
        // callBackFn={changeDetails}
        callBackFn={() => {}}
        closeModal={setOtpModalOpen}
      /> */}
      {/* <OtpModal
        onChangeOTP={handleOTP}
        onClose={() => setOtpModalOpen(false)}
        isOpen={otpModalOpen}
      /> */}
    </div>
  );
}
