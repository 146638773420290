import { Button, Form } from "shared/components";
import breakpoints from "shared/utils/breakpoints";
import { color } from "shared/utils/styles";
import styled from "styled-components";

export const PaymentLinkHeader = styled.div`
  display: flex;
  /* justify-content: space-between; */
  @media ${breakpoints.device.max.xs} {
    flex-direction: column;
  }
`;

export const PaymentHeaderLeft = styled.div`
  border-bottom: 0.5px solid rgba(130, 130, 130, 0.9);
  padding: 10px 0px;
  flex: 1;
  flex-direction: row;
  display: flex;
  justify-content: flex-start;
  h2 {
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: -0.02em;
    color: #292929;
  }
`;

export const PaymentHeaderRight = styled.div``;

export const PaymentHeaderButton = styled.button`
  /* width: 138px; */
  background: #0e314c;
  border-radius: 5px;
  color: #ffffff;
  padding: 15px 35px;
  cursor: pointer;
  background: #ffffff;
  color: rgba(130, 130, 130, 0.9);
  border: none;
  border-bottom: 1px solid rgba(130, 130, 130, 0.9);
  &.selected {
    background: rgba(250, 214, 214, 0.55);
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #292929;
    border: 1px solid rgba(130, 130, 130, 0.9);
    border-bottom: none;
  }
  @media ${breakpoints.device.max.xs} {
    width: 100%;
  }
`;

export const PaymentForm = styled.div`
  padding: 25px 0px;
  display: flex;
  flex-wrap: wrap;
  max-width: 700px;
`;

export const PaymentFormElement = styled.div`
  width: calc(50% - 25px);
  margin-right: 25px;
  @media ${breakpoints.device.max.xs} {
    width: 100%;
    margin-right: 0;
  }
`;

export const FullPaymentFormElement = styled.div`
  width: calc(100% - 25px);
  margin-right: 25px;
  @media ${breakpoints.device.max.xs} {
    width: 100%;
    margin-right: 0;
  }
`;

export const AddSubAccountButtons = styled.div`
  width: calc(50% - 25px);
  margin-top: 30px;
  @media ${breakpoints.device.max.xs} {
    width: 100%;
  }
`;

export const AddSubAccountBtn = styled.button`
  width: 100%;
  padding: 18px 0px;
  margin-top: 25px;
  background: rgba(130, 130, 130, 0.9);
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  color: #ffffff;
  &.active {
    background: #760000;
  }
`;

export const AddSubAccountSubmitBtn = styled(Button)`
  padding: 18px 0px;
  min-width: 325px;
  margin-top: 25px;
  background: #760000;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  color: #ffffff;
  @media ${breakpoints.device.max.xs} {
    width: 100%;
  }
`;

export const ActionButtons = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const PageBody = styled.div``;

export const FormContainer = styled.div`
  max-height: 450px;
  padding-right: 10px;
  overflow-y: auto;
`;

export const FormBody = styled.div`
  display: flex;
  justify-content: space-between;
  /*width: 100%;*/
  min-height: 60vh;
`;

export const RightPane = styled.div`
  /* width: 35%; */
  order: 2;
  display: flex;
  padding: 15px;
  @media ${breakpoints.device.max.xs} {
    display: none;
  }
`;

export const FormTitle = styled.h1`
  font-weight: 700;
  font-size: 20px;
  color: black;
  text-align: left;
`;
export const FormSubTitle = styled.p`
  font-weight: 400;
  font-size: 14px;
  color: #828282;
  text-align: left;
  margin-bottom: 40px;
`;

export const LeftPane = styled.div`
  min-width: 65%;
  /* min-width: 55%; */
  /* max-width: 65%; */
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  vertical-align: middle;
  padding-top: 30px;
  background: #fbfbfb;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  padding: 25px 40px 35px;
  @media ${breakpoints.device.max.xs} {
    width: 100%;
    padding: 15px;
  }
`;

export const Selectors = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  margin: 1em 0;
`;

export const SelectorButton = styled(Button)`
  margin: 2px 0;
  width: 43%;
  height: 48px;
  float: right;
  font-weight: 600;
  font-size: 18px;
  // background-color: ${color.fountain};
`;

export const Back = styled.p`
  font-weight: 400;
  font-size: 14px;
  color: #828282;
  cursor: pointer;
  // text-align: left;
`;

export const ActionButton = styled(Button)`
  margin: 1em 0;
  color: #fff;
  width: 138px;
  /* width: 70%; */
  float: right;
  background-color: ${color.fountain};
`;

export const FormElement = styled(Form.Element)`
  width: 100%;
`;

export const SwitchContainer = styled.div`
  /* width: 75px; */
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 30px;
  /* justify-content: space-between; */
  @media ${breakpoints.device.max.xs} {
    margin-top: 15px !important;
  }
`;

export const SwitchLink = styled.p`
  /* font-size: 20px; */
  /* font-weight: 400; */
  line-height: 23px;
  font-size: 14px;
  color: #5e6c84;
  /* font-family: "KumbhSansNormal"; */
`;

export const CheckBoxWrapper = styled.div`
  position: relative;
`;

export const CheckBoxLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 32px;
  height: 22px;
  border-radius: 15px;
  background: #bebebe;
  cursor: pointer;
  &::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    margin: 3px;
    background: #ffffff;
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
  }
`;

export const CheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 32px;
  height: 22px;
  margin-right: 15px;
  &:checked + ${CheckBoxLabel} {
    background: #4fbe79;
    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 16px;
      height: 16px;
      margin-left: 13px;
      transition: 0.2s;
    }
  }
`;

export const TextAreaContainer = styled.div`
  margin-top: 20px;
  text-align: left;
  font-size: 13px;
  padding-bottom: 5px;
  color: #5e6c84;
  font-family: "KumbhSansNormal";
  display: block;
  font-weight: normal;
  margin-bottom: 15px;
`;

export const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin-left: -0.4rem;
`;

export const StyledCheckbox = styled.div`
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-top: 5px;
  content: "";
  top: 50%;
  right: 0;
  border-radius: 4px;
  border: 2px solid ${color.fountain};
  background: ${color.fountainLight};
  transition: 0.2s;
`;

export const CheckBoxGroup = styled.div`
  display: flex;
  font-size: 14px;
  margin-top: 25px;
  position: relative;
`;

export const CheckBoxItem = styled.div`
  margin-right: 25px;
`;

// export const InputGroupContainer = styled.div`
//   text-align: left;
//   margin-top: 20px;
//   label {
//     display: block;
//     padding-bottom: 5px;
//     color: #5e6c84;
//     font-family: "KumbhSansNormal";
//     font-weight: normal;
//     font-size: 13px;
//     text-align: left;
//   }
// `;

// export const InputGroup = styled.div`
//   display: flex;
//   align-items: center;
//   border: 1px solid #dfe1e6;
//   border-radius: 5px;
// `;

// export const InputLeftLabel = styled.div`
//   font-size: 14px;
//   padding: 0px 20px;
// `;
