import React, { useState, useEffect } from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import pubsub from 'sweet-pubsub'
import { uniqueId } from 'lodash'

import {
	Container,
	StyledToast,
	CloseIcon,
	Title,
	Message,
	IconContainer,
} from './Styles'
import { Icon } from 'shared/components'
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from 'react-icons/ai'

type Props = {
	title: string | number
	message: string | number
	duration: string | number
	currentToasts: string | number
	type: string | number
	// setToasts: any;
}

type PropsT = {
	[x: string | number]: any
}

const Toast = ({}) => {
	const [toasts, setToasts] = useState([])

	useEffect(() => {
		const addToast = ({
			type = 'success',
			title,
			message,
			duration = 5,
		}: PropsT) => {
			const id: number | string = uniqueId('toast-')
			// @ts-ignore
			setToasts((currentToasts) => [
				...currentToasts,
				{ id, type, title, message },
			])

			if (duration) {
				setTimeout(() => removeToast(id), duration * 1000)
			}
		}

		pubsub.on('toast', addToast)

		return () => {
			pubsub.off('toast', addToast)
		}
	}, [])

	const removeToast = (id: any) => {
		setToasts((currentToasts) =>
			currentToasts.filter((toast: any) => toast.id !== id),
		)
	}

	return (
		<Container>
			<TransitionGroup>
				{toasts.map((toast: any) => (
					<CSSTransition key={toast.id} classNames='jira-toast' timeout={2000}>
						<StyledToast
							key={toast.id}
							type={toast.type}
							onClick={() => removeToast(toast.id)}>
							<CloseIcon type='close' />
							<IconContainer>
								{toast.type === 'success' ? (
									<AiOutlineCheckCircle style={{ color: 'green' }} size={26} />
								) : toast.type === 'danger' ? (
									<AiOutlineCloseCircle style={{ color: 'red' }} size={26} />
								) : toast.type === 'warning' ? (
									<Icon type='circle-warning' />
								) : toast.type === 'info' ? (
									<Icon type='circle-info' />
								) : null}
							</IconContainer>
							{toast.title && <Title>{toast.title}</Title>}
							{toast.message && <Message>{toast.message}</Message>}
						</StyledToast>
					</CSSTransition>
				))}
			</TransitionGroup>
		</Container>
	)
}

export default Toast
