import React, { useState } from "react";
import TransactionDetails from "./TransactionDetails";
import TransactionsList from "./TransactionList";
import { Redirect, Route, Router, Switch, useHistory } from "react-router-dom";
import { PageError } from "shared/components";

export default () => {
  const history = useHistory();

  return (
    <>
      <Router history={history}>
        <Switch>
          <Redirect exact from="/my-apps/:serviceId/transactions" to="/my-apps/:serviceId/transactions/lists" />
          <Route path="/my-apps/:serviceId/transactions/lists" component={TransactionsList} />

          <Route path="/my-apps/:serviceId/transactions/:id" component={TransactionDetails} />
          <Route path="*" component={PageError} />
        </Switch>
      </Router>
    </>
  );
};
