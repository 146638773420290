import breakpoints from "../../utils/breakpoints";
import styled from "styled-components";

type modalPropT = {
  visibile: boolean;
  isopacity: boolean;
};

type mainModalPropT = {
  width?: string;
  marginTop?: string;
};

type contentModalPropT = {
  paddingTop?: string;
  paddingLeft?: string;
  paddingRight?: string;
  paddingBottom?: string;
};

export const ModalContainer = styled.div<modalPropT>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${(prop) =>
    prop.isopacity ? "rgba(9, 30, 66, 0.24)" : "rgba(9, 30, 66, 0.54)"};
  z-index: 99999;
  visibility: ${(prop) => (prop.visibile ? "visible" : "hidden")};
  overflow-y: auto;
`;

export const ModalMain = styled.div<mainModalPropT>`
  width: ${(prop) => (prop.width ? prop.width : "35%")};
  margin: auto;
  margin-top: ${(prop) => (prop.marginTop ? prop.marginTop : "8rem")};
  padding: 0.5rem;
  @media ${breakpoints.device.max.xs} {
    width: 85%;
    margin-top: 0px;
  }
`;

export const ModalMainHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  margin-bottom: 1rem;
`;

export const ModalContent = styled.div<contentModalPropT>`
  width: 98%;
  padding-top: ${(prop) => (prop.paddingTop ? prop.paddingTop : "2rem")};
  padding-right: ${(prop) =>
    prop.paddingRight ? prop.paddingRight : "0.1rem"};
  padding-bottom: ${(prop) =>
    prop.paddingBottom ? prop.paddingBottom : "0.4rem"};

  padding-left: ${(prop) => (prop.paddingLeft ? prop.paddingLeft : "0.1rem")};
  border-radius: 0.8rem;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: auto;
`;

export const ModalCloseBtn = styled.div`
  position: relative;
  width: 2.4rem;
  height: 2.4rem;
  margin-right: -2.5rem;
  top: 2.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 50rem;
  position: relative;
  transition: all ease 0.4s;
  cursor: pointer;
  &:hover {
    top: 2rem;
  }
`;

export const CloseSpan = styled.span`
  display: block;
  background-color: #333;
  width: 1.3rem;
  height: 0.2rem;
  position: absolute;
  transform: rotate(45deg);

  &::before {
    display: block;
    background-color: #333;
    width: 1.3rem;
    height: 0.2rem;
    content: "";
    transform: rotate(-85deg);
  }
`;
