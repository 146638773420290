// import { getCurrentBusiness } from "shared/utils/businessesData";
import { ActionTypes } from "../constants/actionType";

const initialState = {
  filter: {},
};

export const filterReducer = (
  state: any = initialState.filter,
  action: any
) => {
  switch (action.type) {
    case ActionTypes.FILTER_RECORD:
      return action.payload;
    default:
      return state;
  }
};
