import axios, { AxiosError, AxiosResponse } from "axios";

import history from "browserHistory";
import toast from "shared/utils/toast";
import { objectToQueryString } from "shared/utils/url";

import {
  removeStoredAuthToken,
  storeAuthToken,
  saveLastApiCallTime,
  getStoreLocalStorage,
} from "shared/utils/authToken";
import {
  decryptData,
  encryptData,
  isItHere,
  retrieveApiToken,
  retrieveToken,
} from "./security";
import cachedResponse, { checkForResponses } from "./cachedResponse";
import consoleLog from "shared/utils/consoleLog";
import { emailFormat } from "./stringFormat";
import { FieldError } from "KYC-refactored/Gateway/UploadDocs/Styles";

type PropT = {
  [x: string]: any;
};

interface CallResponse {
  status_code?: number;
  message: string;
  status: boolean;
  payload?: any;
  errors?: any;
  meta?: any;
}
const checkBase = (url: any) => {
  return url.indexOf("https://") > -1 || url.indexOf("http://") > -1;
};
const defaults = {
  baseURL:
    process.env.REACT_APP_API_URL ?? "https://staging-5714397be.fountainpay.ng",

  headers: (checkFlag: boolean, url: any) => {
    if (retrieveToken(checkFlag)) {
      return {
        Accept: "application/json",
        "x-api-client": "api",
        "Content-Type": "application/json",

        Authorization: retrieveToken(checkFlag),
      };
    } else {
      return {
        Accept: "application/json",
        "x-api-client": "api",
        "Content-Type": "application/json",
      };
    }
  },
  error: {
    code: "INTERNAL_ERROR",
    message: "Something went wrong. Please contact our support.",
    status: 503,
    data: {},
  },
};

const api = (
  method: string,
  url?: string,
  variables?: any,
  headers = {},
  cache = true
) => {
  // consoleLog(`URL: ${defaults.baseURL}${url}`);

  saveLastApiCallTime();
  let exceptionUrlArray: any = [
    "authentication/confirm-otp",
    "authentication/forgot-password",
    "authentication/login",
    // "authentication/",
    "authentication/reset-password",
    "authentication/send-otp",
    "authentication/signup",
    "console/login",
    "console/system-logs/create",
    "console/send-otp",
    "console/confirm-otp",
    "console/set-password",
    "fp_consoleadmin/reset-password",
    "checkout/payment-link/",
    // "chargeback",
    "/agency-banking/agents",
    "/agency-banking/create-agents",
    "/agency-banking/reset-agents-password",
    "/account/security-question",
    "shared/send-otp",
    "send-auth-otp",
    "/sdk/v1/direct/",
    // "/business/***/kyc/***"
    // "business/kyc/upload",
  ];

  variables = emailFormat(variables);
  let strictlyUrlArray = ["/message", "/account/security-question-response"];

  let checkFlag = isItHere(exceptionUrlArray, url, strictlyUrlArray);

  const moreExceptional = (
    url: string = "",
    variables: any,
    checkFlag: boolean
  ) => {
    if (isItHere(["business/kyc/upload"], url)) return variables;
    if (checkBase(url)) return variables;

    return encryptData(variables, checkFlag);
  };

  let addToHeader = String(url).includes("/transactions/verify/")
    ? variables
    : {};
  //alert(JSON.stringify(moreExceptional(url, variables, checkFlag)))
  //alert(`url: ${url}=${checkFlag}`)
  return new Promise((resolve, reject) => {
    axios(<PropT>{
      url: checkBase(url) ? url : `${defaults.baseURL}${url}`,
      method,
      headers: {
        ...defaults.headers(checkFlag, url),
        ...addToHeader,
        ...headers,
      },
      params: method === "get" ? variables : undefined,
      data:
        method !== "get"
          ? moreExceptional(url, variables, checkFlag)
          : undefined,
      paramsSerializer: objectToQueryString,
    }).then(
      (response: any) => {
        const headers = response.headers;
        let decyptedDataContent;
        const data: CallResponse = response.data;
        decyptedDataContent = decryptData(data, checkFlag);

        resolve(decyptedDataContent);
      },
      (error: any) => {
        const headers = error?.response?.headers;
        // if (headers && headers["auth-token"]) {
        //   storeAuthToken(headers["auth-token"]);
        // }
        // // console.log("Error: ", error)
        if (error?.code == "ERR_BAD_RESPONSE") {
          reject({
            code: 500,
            status: false,
            message: "System error. Kindly report via our feedback channel.",
          });
        }

        if (error.response) {
          if (error?.response?.status == "404") {
            reject({
              code: 404,
              status: false,
              message:
                "System resource not found or dropped. Kindly report via our feedback channel.",
            });
          }

          if (error?.response?.data) {
            error.response.data = decryptData(error.response.data, checkFlag);
          }

          if (error?.response?.status == "400") {
            reject({
              code: 400,
              status: false,
              message: error?.response?.data?.message,
              error: error?.response?.data?.error,
            });
          }

          if (
            ["INVALID_TOKEN", "token_not_valid"].includes(error.code) ||
            ([401].includes(error.response.status) &&
              error?.response?.data?.message !== "Invalid credentials" &&
              !checkBase(url))
          ) {
            removeStoredAuthToken();
            if (
              !["/overview", "/authenticate"].includes(window.location.pathname)
            ) {
              toast.error(
                error?.response?.data?.message ?? "Request Timed Out"
              );
            }
            if (!["/authenticate"].includes(window.location.pathname)) {
              history.push("/authenticate");
            }
          } else {
            reject(error?.response?.data);
          }
        } else {
          reject(defaults.error);
        }
      }
    );
  });
};

const optimisticUpdate = async (
  url: string,
  { updatedFields, currentFields, setLocalData }: PropT
) => {
  try {
    setLocalData(updatedFields);
    await api("put", url, updatedFields);
  } catch (error) {
    setLocalData(currentFields);
    toast.error("Invalid credentials");
  }
};

export default <PropT>{
  get: (...args: any) => api("get", ...args),
  post: (...args: any) => api("post", ...args),
  put: (...args: any) => api("put", ...args),
  patch: (...args: any) => api("patch", ...args),
  delete: (...args: any) => api("delete", ...args),
  optimisticUpdate,
};
