import styled from "styled-components";
import { color, font } from "shared/utils/styles";
import { Button, Form } from "shared/components";
import { Link } from "react-router-dom";

type Props={
    color?: string;
}

export const Strength = styled.div`
  width: 100%;
  margin: 2em auto;
`;

export const StrengthBar = styled.div`
    display: inline;
	list-style: none;
	margin: 0;
	padding: 0;
	vertical-align: -15px;
`;

export const Point = styled.div`
    display: inline-block;
	margin-right: 10px;
	width: 20%;
    &:first-child{
        margin-left: 0px;
    }
    &:last-child{
        margin-right: 0px;
    }
`;

export const Bar = styled.div<Props>`
    background: ${props=>props.color};
	border-radius: 2px;
	height: 8px;
	min-width: 100%;
`;

export const Title = styled.div`
    text-align: center;
	margin-top: 5px;
    color: #ccc;
`;

export const StrengthMeter=styled.div`
	margin-top: 5px;

`
export const Subtitle=styled.div`
	margin-top: 8px;
	margin-bottom: 15px;
    color: #000;
`

export const IndicatorRemark=styled.div`
    display: flex;
	flex-direction: row;
	margin-bottom: 10px;
`

export const IndicatorRemarkLabel=styled.div`
    width: 50%;
	text-align: left;
    display: flex;
	flex-direction: row;
    span{
        width: 10%;
        img{
            width: 14px;
            height: 14px;
            vertical-align: middle;
        }
    }
`

export const Label=styled.div`
    margin-top: 2px;
	margin-left: 10px;
	font-size: 12px;
	font-weight: 600;
    color: #000;
`