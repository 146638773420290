import { color } from "shared/utils/styles";
import styled from "styled-components";

export const SubHeader = styled.div`
  height: 72px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.05);
  margin-bottom: 50px;
  padding-left: 20px;
  padding-right: 20px;
`;

export const SubHeaderChildren = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const SubHeaderChildren2 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 580px;
`;

export const StateContainer = styled.div`
  width: 100%;
  padding: 2rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${color.backgroundMedium};
`;

export const ViewContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ViewBtn = styled.div`
  background-color: ${color.fountain};
  padding: 0.4rem 1rem;
  text-align: center;
  color: #fff;
  font-size: 0.66rem;
  border-radius: 4rem;
`;

export const MapContainer = styled.div`
  /* width: 100vw;
  min-height: 80vh; */
`;
