import React, { useEffect, useState } from "react";
import {
  ApiActionButtons,
  ApiContainer,
  ApiContent,
  ApiPageSubHeader,
  KeyDetails,
  KeyRow,
  KeyWithIcon,
  ModeSpan,
} from "./Styles";
import { Button, Form, Icon, Input, Switch } from "shared/components";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import consoleLog from "shared/utils/consoleLog";
import shouldPerform, { getRoleName } from "shared/utils/assignRoles";
import { setService } from "store/actions/serviceAction";
import api from "shared/utils/api";
import toast from "shared/utils/toast";
import useServiceDetails from "shared/hooks/useServiceDetails";
import { regenerateApiKeys, toogleServiceMode } from "Services/serviceClass";
import { copyToClipboard } from "shared/utils/browser";
import useServiceMode from "shared/hooks/useServiceMode";
import { FormElement, SubAgentForm } from "Agency/Subagent/AddSubAgent/styles";
import { color } from "shared/utils/styles";
import { ActionButton } from "Auth/Styles";
import { isDisabled } from '@testing-library/user-event/dist/utils';

export default function Apikeys() {
  const service = useSelector((store: RootStateOrAny) => store.service);
  const currentUser = useSelector((store: RootStateOrAny) => store.currentUser);
  const business = useSelector((store: RootStateOrAny) => store.business);
  const [loadLive, setloadLive] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [clickAction, setclickAction] = useState("");
  const [LinkCopied, setLinkCopied] = useState(false);
  const [isPrivateKeyVisible, setIsPrivateKeyVisible] = useState(false);
  const [isPublicKeyVisible, setIsPublicKeyVisible] = useState(false);
  const [isSavingWebbok, setisSavingWebbok] = useState(false)

  const [webhook, setWebhook] = useState("");

  const { serviceDetails, getServiceDetails } = useServiceDetails();
  const { serviceMode, loadingMode, toogleMode } = useServiceMode(service);


  const [live, setlive] = useState(service.mode === "TEST" ? false : true);
  const [test, settest] = useState(false);
  const dispatch = useDispatch();

  const { privateKey, publicKey, mode } = service;

  const handleResetKeys = async () => {
    setclickAction("Regen");
    setLoading(true);
    try {
      if (!shouldPerform(getRoleName(currentUser, business?.id), "ManAPI")) {
        setLoading(false);
        setclickAction("");
        return;
      }
      let response = await regenerateApiKeys(service?.id, {
        businessId: service?.businessId,
        serviceId: service?.id,
      });

      if (response.status === true && response.payload) {
        let { testPrivateKey, testPublicKey } = response.payload;
        // // console.log("response", response?.payload);
        await getServiceDetails(); //call function from custom hook
        toast.success(response.message ?? "Successfully Reset");
        setclickAction("");
        setLoading(false);
      } else {
        toast.error(response.message ?? "Unable to reset keys");
        setLoading(false);
      }
    } catch (err) {
      setclickAction("");
      setLoading(false);
      consoleLog("RESET error:", err);

      // @ts-ignore
      toast.error(err);
    } finally {
      setclickAction("");
      setLoading(false);
    }
  };

  const handleLinkCopy = (text: string) => {
    setLinkCopied(true);
    setTimeout(() => setLinkCopied(false), 2000);
    let useString = text && text.trim() !== "" ? text : " ";
    copyToClipboard(useString);
    toast.success("Copied");
  };

  const getWebhook=async()=>{
    setisSavingWebbok(true)
    try{
      const payload={
        url: webhook,
        mode: service.mode
      }
      const response = await api.get(`/services/${service?.id}/webhooks?mode=LIVE`);
      // console.log("Webhook: ", response)
      if(response.status){
        toast.success("Your service is updated with the webhook.");
      }else{
        toast.error("Unable to update service");
      }

    }catch(error: any){
      // console.log("Error: ", error)
      toast.error(error?.Message);
    }finally{
      setisSavingWebbok(false)
    }
  }

  const onSubmit=async()=>{
    setisSavingWebbok(true)
    try{
      const payload={
        url: webhook,
        mode: service.mode
      }
      
      const response = await api.post(`/services/${service?.id}/webhooks`, payload);
      if(response.status){
        toast.success("Your service is updated with the webhook.");
      }else{
        toast.error("Unable to update service");
      }

    }catch(error: any){
      // console.log(error)
      toast.error(error?.Message);
    }finally{
      setisSavingWebbok(false)
    }
  }
  
  useEffect(()=>{
    getWebhook()
  }, [])
  return (
    <div>
      <ApiContainer>
        <h1>
          Api Keys <ModeSpan mode={service.mode=='LIVE'?true: false}>{mode}</ModeSpan>
        </h1>
        <ApiPageSubHeader>
          <p>Find your private and public api keys here</p>
          <ApiActionButtons>
            <button
              onClick={() => {
                handleResetKeys();
              }}
            >
              {Loading && clickAction === "Regen"
                ? "Processing.."
                : " Regenerate key"}
            </button>
            <div>
              <Switch
                id={"SubAction"}
                status={serviceMode}
                label={loadingMode ? "Loading" : live ? "Live" : "Test"}
                toggleMethod={() => toogleMode()}
              />
            </div>
          </ApiActionButtons>
        </ApiPageSubHeader>

        <ApiContent>
          <KeyRow>
            <KeyDetails>
              <h4>Public Key</h4>
              <KeyWithIcon>
                {!isPublicKeyVisible && <span>****************************************</span>}
                {isPublicKeyVisible && <span>{service.publicKey}</span>}
                <Icon
                    style={{ marginTop: "7px" }}
                    type={!isPublicKeyVisible?"eye-blocked":"eye"}
                    size="18"
                    color="#ccc"
                    isHover={true}
                    onClick={() => setIsPublicKeyVisible(!isPublicKeyVisible)}
                  />
                  <button onClick={() => handleLinkCopy(publicKey)}>Copy</button>
              </KeyWithIcon>
              {/* <span>{publicKey}</span> */}
            </KeyDetails>
            
          </KeyRow>

          <KeyRow>
            <KeyDetails>
              <h4>Private Key</h4>
              <KeyWithIcon>
                {!isPrivateKeyVisible && <span>****************************************</span>}
                {isPrivateKeyVisible && <span>{service.privateKey}</span>}
                <Icon
                    style={{ marginTop: "7px" }}
                    type={!isPrivateKeyVisible?"eye-blocked":"eye"}
                    size="18"
                    color="#ccc"
                    isHover={true}
                    onClick={() => setIsPrivateKeyVisible(!isPrivateKeyVisible)}
                  />
                <button onClick={() => handleLinkCopy(privateKey)}>Copy</button>
              </KeyWithIcon>
              {/* <span>{privateKey}</span> */}
            </KeyDetails>
            
          </KeyRow>
        </ApiContent>
      </ApiContainer>
      <br />
      {service.mode &&
        <ApiContainer>
          <h1>
            Webhook <ModeSpan mode={service.mode=='LIVE'?true: false}>{mode}</ModeSpan>
          </h1>
          <ApiPageSubHeader>
            <p>Let us send you transactions seemlessly.</p>
            <ApiActionButtons></ApiActionButtons>
          </ApiPageSubHeader>

          <ApiContent>
            <KeyRow>
            
              <KeyDetails>
                <h4>Webhook URL</h4>
                <KeyWithIcon>
                  <Input onChange={(e:any)=>{setWebhook(e)}} />
                  <ActionButton
                    type="submit"
                    variant={color.fountain}
                    onClick={() => onSubmit()}
                    isDisabled={!webhook}
                    isWorking={isSavingWebbok}
                  >
                    Update
                  </ActionButton>
                </KeyWithIcon>
                
              </KeyDetails>
              
            </KeyRow>

           
          </ApiContent>
        </ApiContainer>
      }
      
    </div>
  );
}
