import styled, { css } from "styled-components";
type Props={
  mode?: boolean
}
export const ApiContainer = styled.div`
  width: 100%;
  background: #fff;
  min-height: 40vh;
  padding: 2rem;

  h1 {
    font-weight: 700;
    font-size: 1.4rem;
    display: flex;
    align-items: center;
  }
`;

export const ApiPageSubHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.5px solid #000;
  margin-top: 0.6rem;
  padding-bottom: 0.8rem;

  p {
    font-size: 0.95rem;
  }
`;

export const ApiActionButtons = styled.div`
  display: flex;
  gap: 2rem;
  align-items: center;
  justify-content: end;

  button {
    /* padding: 0.8rem 1.5rem; */
    color: #fff;
    background: #1b222f;
    border-radius: 0.25rem;
    width: 10rem;
    height: 44px;
    cursor: pointer;
  }
`;

export const ApiContent = styled.div`
  width: 100%;
  margin-top: 1rem;
`;

export const KeyRow = styled.div`
  width: 100%;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  border-bottom: 1.2px solid #ddd;
  h4 {
    font-size: 0.88rem;
  }
  span {
    display: block;
    margin-top: 0.4rem;
    color: #1b222f;
    font-size: 0.88rem;
  }
  button {
    cursor: pointer;
    font-size: 0.85rem;
  }
`;

export const KeyDetails = styled.div`
  display: flex;
  width: 100%;
  height: 40px;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
`;

export const KeyWithIcon=styled.div`
  width:80%;
  display: flex;
  justify-content: space-between;
  align-items: left;
  gap: 2rem;
`;

export const ModeSpan = styled.span<Props>`
  background: #760000;
  color: #fff;
  padding: 0.2rem 0.4rem;
  font-size: 0.6rem;
  margin-bottom: 1rem;
  display: block;
  margin-left: 8px;
  border-radius: 20px;
  ${props=>props.mode && css`
    background: green
  `}
`;
