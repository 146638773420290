import React, { useState } from 'react'
import { TableLinkTdwContainer } from './Style'

const ShowMoreButton = React.memo(
	({ onClick, isExpanded }: { onClick: () => void; isExpanded: boolean }) => (
		<button style={{cursor: 'pointer'}} className='btn' onClick={onClick}>
			{isExpanded ? '...show less' : '...show more'}
		</button>
	),
)

const Content = React.memo(
	({
		id,
		allowShowmore,
		data,
	}: {
		id: string
		allowShowmore: boolean
		data: any
	}) => {
		
		const itemData = data[id]
		
		const [isExpanded, setIsExpanded] = useState(false)
		//// console.log("Row data: ", itemData)
		const shouldTruncate = itemData && itemData.length > 25

		if (allowShowmore && shouldTruncate) {
			const truncatedData = itemData.substring(0, 25)

			return (
				<>
					{isExpanded ? itemData : truncatedData}
					<ShowMoreButton
						onClick={() => setIsExpanded(!isExpanded)}
						isExpanded={isExpanded}
					/>
				</>
			)
		}

		return itemData
	},
)

const getContent = ({
	id,
	allowShowmore,
	data,
}: {
	id: string
	allowShowmore: boolean
	data: any
}) => <Content id={id} allowShowmore={allowShowmore} data={data} />

const TdwContainer = ({ item, data, ...props }: any) => (
	<TableLinkTdwContainer flag={data.flag} {...props}>
		{getContent({ id: item.id, allowShowmore: item.allowShowmore, data })}
	</TableLinkTdwContainer>
)

export default TdwContainer
