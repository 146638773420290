import { useEffect, useState } from "react";
import Table from "shared/components/Table";
import api from "shared/utils/api";
import toast from "shared/utils/toast";
import { AccountOfficer, AssignOfficer, Container, Profile } from "./Style";
import { Form, Icon, ProfilePicture } from "shared/components";
import { ActionButton, ButtonContainer, FormBody, FormContainer, FormElement, SubTitle, TitleCase } from "../AddSubAgent/styles";
import { useHistory, useParams } from "react-router-dom";
import { RootStateOrAny, useSelector } from "react-redux";
import consoleLog from "shared/utils/consoleLog";

const AssignTerminal=({path}: any)=>{
    const business = useSelector((store: RootStateOrAny) => store.business)
    const [terminalId, setTerminalId] =useState("");
    const [terminalSerialNo, setTerminalSerialNo] =useState("");
    const [isLoginIn, setIsLoginIn] = useState(false)

    const history = useHistory();
    const params: any = useParams();

    let initialValues={
        terminalId,
        terminalSerialNo
    }
    const onSubmit=async()=>{
        setIsLoginIn(true)
		try{
            let subAgent = {
                ...initialValues,
                business_id: business.id,
                agent_id: params.id
            }

            // console.log(subAgent)

            const res = await api.post('/agency-banking/map-terminal', subAgent)
            consoleLog('Response: ', res);
            toast.success(res.message)
            history.goBack()
        }catch(err: any){
            toast.error(err?.message)
        }finally{
            setIsLoginIn(false)
        }
        
    }
    return (
        <Container style={{width:"100%"}}>
			<FormContainer style={{width:"100%"}}>
				<TitleCase>Map terminal to agent</TitleCase>
				<SubTitle>
					Attach terminals to this agent for easy flow of transactions
				</SubTitle>
				<FormBody>
                    <Form
                        enableReinitialize
                        initialValues={initialValues}
                        validations={{
                            terminalId: [Form.is.required()],
                            terminalSerialNo: [Form.is.required()]
                        }}
                        onSubmit={onSubmit} 
                        validate={function ({ }: {}) {
                            //throw new Error('Function not implemented.')
                        } } 
                        validateOnBlur={true}					>
                        <FormElement>
                            <Form.Field.Input
                                name='terminalId'
                                type="text"
                                label="Terminal ID"
                                onChange={(e: any)=>setTerminalId(e)}
                                // tip="Kindly enter your BVN"
                            />
                            <Form.Field.Input
                                name='terminalSerialNo'
                                type="text"
                                label="Terminal Serial Number"
                                onChange={(e: any)=>setTerminalSerialNo(e)}
                                // tip="Kindly enter your BVN"
                            />

                            <ButtonContainer>
                                <ActionButton
                                    type="submit"
                                    isWorking={isLoginIn}
                                    onClick={onSubmit}
                                >
                                Map terminal
                                </ActionButton>
                            </ButtonContainer>
                        </FormElement>
                        
                    </Form>
                </FormBody>
            </FormContainer>
        </Container>
        
    )
}

export default AssignTerminal;