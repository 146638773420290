import styled from "styled-components";

export const Container = styled.div``;
export const DivText = styled.div`
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  //   border: 1px solid #ccc;
  width: 50%;
  padding-bottom: 10px;
  cursor: pointer;
`;

export const OptionDiv = styled.div`
  width: 15%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-left: 10px;
  min-height: 20px;
  position: absolute;
  background-color: white;
  z-index: 50;
`;

export const OptionItem = styled.div`
  cursor: pointer;
  padding: 5px 3px;
  width: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  &:hover {
    background-color: #e6e6e6;
  }
`;
