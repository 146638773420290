import React, { useEffect, useState } from "react";
import {
  BottomDivContainer,
  BottomFirstDiv,
  BottomInnerThirdDiv1,
  BottomInnerThirdDiv2,
  BottomSecondDiv,
  BottomThirdDiv,
  Container,
  Divider,
  FooterDiv,
  ImageDiv,
  LeftText,
  LeftTopDiv,
  RightText,
  RightTopDiv,
  TopDiv,
  TopDivContainer,
  TopText,
  TopText2,
  WholeDiv,
} from "./Styles";
import Logo from "App/assets/images/logo.svg";
import Downl from "App/assets/images/downl.svg";
import Facebook from "App/assets/images/facebook.svg";
import Twitter from "App/assets/images/twitter.svg";
import Linkedin from "App/assets/images/linkedin.svg";
// @ts-ignore
import html2pdf from "html2pdf.js";
import { useParams } from "react-router-dom";
import api from "shared/utils/api";
import consoleLog from "shared/utils/consoleLog";
import axios from "axios";
import e from "express";
import { GradientLoader } from "shared/components";
export default function() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [referenceNumber, setReferenceNumber] = useState<string>("");
  const [paymentDetails, setPaymentDetails] = useState<any>(null);

  const { payLink, transId }: any = useParams();

  const getPayment = async () => {
    setIsLoading(true);
    try {
      const response = await api.get(
        `/checkout/payment-link/requery/${payLink}`
      );
      // console.log(response.payload);
      if (response.status) {
        //== true && response.payload instanceof Object
        const { privateKey }: any = response.payload;
        let reqInstance = await axios({
          method: "GET",
          url: `https://api.fountainpay.ng/transaction/verify/${transId}`,
          headers: {
            Authorization: "Basic " + btoa(privateKey).toString(),
            Accept: "application/json",

            "Content-type": "application/json",
            "x-api-client": "api",
          },
        }).then((resp: any) => {
          if (resp.data.status) {
            setPaymentDetails(resp.data.payload);
          } else {
            setPaymentDetails(null);
          }
        });
        ///continue from here ...remember base64 encoded
      }
    } catch (err) {
      //// console.log(err, "error");
      // setLoading(typeof err === "string" ? err : false);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePrintToPdf = () => {
    const element = document.getElementById("divToPrint"); // Replace 'divToPrint' with the ID of your div element
    const options = {
      filename: "reciept.pdf",
      margin: 10,
      jsPDF: { format: "letter", orientation: "portrait" },
    };

    html2pdf()
      .set(options)
      .from(element)
      .save();
  };

  useEffect(() => {
    setReferenceNumber(transId);
    getPayment();
  }, []);

  return (
    <Container id="divToPrint">
      <div style={{ backgroundColor: "white", padding: 40, borderRadius: 20 }}>
        <ImageDiv>
          <img src={Logo} style={{ maxHeight: 30, marginBottom: 28 }} />
        </ImageDiv>
        <WholeDiv>
          {isLoading && !paymentDetails && <GradientLoader />}
          {!isLoading && paymentDetails && (
            <>
              <TopDiv>
                <TopDivContainer>
                  <LeftTopDiv>
                    <TopText>Your payment attempt</TopText>
                    <TopText2>
                      NGN {paymentDetails?.amount.toFixed(2) || "0.00"}
                    </TopText2>
                  </LeftTopDiv>
                  <RightTopDiv>
                    <img
                      src={Downl}
                      style={{ cursor: "pointer", pointerEvents: "auto" }}
                      onClick={handlePrintToPdf}
                    />
                  </RightTopDiv>
                </TopDivContainer>
              </TopDiv>
              <BottomDivContainer>
                <BottomFirstDiv>Transaction Details</BottomFirstDiv>
                <Divider />
                <>
                  <BottomSecondDiv>
                    <LeftText>Status</LeftText>
                    <RightText>
                      {paymentDetails && paymentDetails.status}
                    </RightText>
                  </BottomSecondDiv>
                  <BottomSecondDiv>
                    <LeftText>Reference</LeftText>
                    <RightText>
                      {paymentDetails && paymentDetails.reference}
                    </RightText>
                  </BottomSecondDiv>
                  <BottomSecondDiv>
                    <LeftText>Date</LeftText>
                    <RightText>
                      {paymentDetails && paymentDetails?.createdAt}
                    </RightText>
                  </BottomSecondDiv>
                  <BottomSecondDiv>
                    <LeftText>Channel</LeftText>
                    <RightText>
                      {paymentDetails && paymentDetails?.gatewayChannel}
                    </RightText>
                  </BottomSecondDiv>
                </>
                Customer Information:
                <Divider />
                <>
                  <BottomSecondDiv>
                    <LeftText>Name</LeftText>
                    <RightText>
                      {paymentDetails && paymentDetails.Customer.fullName}
                    </RightText>
                  </BottomSecondDiv>
                  <BottomSecondDiv>
                    <LeftText>Email</LeftText>
                    <RightText>
                      {paymentDetails && paymentDetails.Customer.email}
                    </RightText>
                  </BottomSecondDiv>
                  <BottomSecondDiv>
                    <LeftText>Phone number</LeftText>
                    <RightText>
                      {paymentDetails && paymentDetails?.Customer.phone}
                    </RightText>
                  </BottomSecondDiv>
                </>
                Beneficiary Information:
                <Divider />
                <>
                  <BottomSecondDiv>
                    <LeftText>Name</LeftText>
                    <RightText>
                      {paymentDetails && paymentDetails.Services.name}
                    </RightText>
                  </BottomSecondDiv>
                  <BottomSecondDiv>
                    <LeftText>Email</LeftText>
                    <RightText>
                      {paymentDetails && paymentDetails.Services.websiteUrl}
                    </RightText>
                  </BottomSecondDiv>
                </>
                <BottomThirdDiv>
                  <BottomInnerThirdDiv1>Mict Explorers</BottomInnerThirdDiv1>
                  <BottomInnerThirdDiv2>
                    Support@mictexplorers.com
                  </BottomInnerThirdDiv2>
                </BottomThirdDiv>
              </BottomDivContainer>
            </>
          )}
          {!isLoading && !paymentDetails && (
            <>
              This transaction with reference number {referenceNumber} doesn't
              exist.
            </>
          )}
        </WholeDiv>
        <FooterDiv>
          <img
            src={Facebook}
            style={{
              marginRight: 20,
              cursor: "pointer",
              pointerEvents: "auto",
            }}
          />
          <img
            src={Twitter}
            style={{
              marginRight: 20,
              cursor: "pointer",
              pointerEvents: "auto",
            }}
          />
          <img
            src={Linkedin}
            style={{ cursor: "pointer", pointerEvents: "auto" }}
          />
        </FooterDiv>
      </div>
    </Container>
  );
}
