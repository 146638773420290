import React, { useState } from "react";
import Success from "App/assets/images/Auth/success.svg";
import { ActionButton, ReportBody, SubTitle, Title } from "./Styles";
import {
  getTempId,
  removeTempId,
  storeAuthToken,
} from "shared/utils/authToken";
import api from "shared/utils/api";
import { useHistory } from "react-router-dom";
import toast from "shared/utils/toast";
import { pullPass, dumpPass } from "shared/utils/authToken";
import { overviewPath } from "apiServices/paths";
import consoleLog from "shared/utils/consoleLog";
import LoginFace2 from "shared/components/LoginFace/index2";

const PasswordSuccess = () => {
  const [isLogin, setIsLogin] = useState(false);
  const history = useHistory();

  const onSubmit = async (form: any) => {
    setIsLogin(true);

    let tempId: any = getTempId();
    let password: any = pullPass();
    const values: any = {
      tempId,
      password,
    };
    // consoleLog('tem id', tempId);
    // consoleLog('password ', password);
    try {
      const { payload }: any = await api.post("/authentication/", {
        ...values,
      });
      setIsLogin(false);
      const authToken = payload.accessToken;
      // // console.log("temp login", payload);
      storeAuthToken(authToken);
      // setIsLogin(false);
      // toast.success("Login was successful.");
      history.push(overviewPath.OVERVIEW);
    } catch (error) {
      // @ts-ignore
      // consoleLog(error)
      toast.error(error ?? "Something went wrong");
      setIsLogin(false);
    }

    // dumpPass();
    // removeTempId();
  };

  return (
    <LoginFace2>
      <ReportBody>
        <img src={Success} alt="Success" />
        <Title>Yes, nice one.</Title>
        <SubTitle>Your password was created successful.</SubTitle>
        <SubTitle> One more step to go.</SubTitle>
        <ActionButton onClick={onSubmit} isWorking={isLogin}>
          Launch Now
        </ActionButton>
      </ReportBody>
    </LoginFace2>
  );
};

export default PasswordSuccess;
