import { useState, useEffect } from "react";
import {
  ParentCont,
  LeftPanel,
  RightPanel,
  RightPanelAuxCont,
  CloseBtn,
  DescriptionBox,
  RightPanelAuxCon2,
  DescriptionTitle,
  DescriptionText,
  DescriptionBtn,
  FormCont,
  TitleCont,
  Back,
  FormTitle,
  FormSubTitle,
  FormElement,
  Selectors,
  ActionButton,
  MiddlePanel,
} from "./Styles";
import { Icon, Form } from "shared/components";
import { useHistory } from "react-router-dom";
import api from "shared/utils/api";
import toast from "shared/utils/toast";
import PageIndicator from "shared/components/PageIndicator";
import { AgencyBankingPages } from "../../PageList";
import consoleLog from "shared/utils/consoleLog";
import { RootStateOrAny, useSelector, useDispatch } from "react-redux";
import {
  setBusinessLists,
  setCurrentBusiness,
} from "store/actions/businessAction";
import { setKycBoardingRec } from "store/actions/boardingAction";
import checkExistingKycBoarding from "shared/utils/checkExistingKycBoarding";

function ResidentialInfo() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const kycBoardingRec = useSelector(
    (store: RootStateOrAny) => store.kycBoardingRec
  );
  const [nationality, setNationality] = useState<string>(
    kycBoardingRec?.nationality ?? "Nigeria"
  );
  const [state, setState] = useState<string>(kycBoardingRec?.state ?? "0");
  const [lga, setlga] = useState<string>(kycBoardingRec?.lga ?? "");
  const [address, setAddress] = useState<string>(kycBoardingRec?.address ?? "");
  const [landmark, setLandmark] = useState(kycBoardingRec?.landmark ?? "");
  const kycStart = useSelector((store: RootStateOrAny) => store.kycStart);
  const business = useSelector((store: RootStateOrAny) => store.business);
  const dispatch = useDispatch();
  consoleLog(kycBoardingRec, "kycBoardingRec");
  const [loadingStates, setLoadingStates] = useState(false);
  const [loadingLga, setLoadingLga] = useState(false);
  const [stateList, setStateList] = useState([]);
  const [lgaList, setLgaList] = useState([]);

  const initialValues = {
    residentialNationality: nationality,
    residentialState: state,
    residentialLga: lga,
    residentialAddress: address,
    closeLandmark: landmark,
  };

  const history = useHistory();

  const listOfStates = (list: any) =>
    list instanceof Array &&
    list.map((item: any) => ({ value: item?.id, label: item?.state }));

  const listOfLga = (list: any) =>
    list instanceof Array &&
    list.map((item: any) => ({ value: item?.id, label: item?.lga }));

  const getStates = async () => {
    setLoadingStates(true);
    try {
      const res: any = await api.get("/agency-banking/state-list");
      const { payload } = res;
      setStateList(payload);
      setLoadingStates(false);
    } catch (error) {
      setLoadingStates(false);
      // @ts-ignore
      toast.error(error);
    }
  };

  const getLga = async () => {
    setLoadingLga(true);
    consoleLog(state, "myState");
    try {
      const res: any = await api.post("/agency-banking/state-lgas", {
        stateId: state,
      });
      const { payload } = res;
      setLgaList(payload);
      setLoadingLga(false);
    } catch (error) {
      setLoadingLga(false);
      // @ts-ignore
      toast.error(error);
    }
  };

  consoleLog("Business Resi Info", business);

  const onSubmit = async (values: any, form: any) => {
    setIsLoading(true);
    try {
      //check if it has agency
      if (!business?.id) {
        business.id = kycBoardingRec?.businessId;
      }
      let findRecord = kycBoardingRec?.businessKycId
        ? { id: kycBoardingRec?.businessKycId }
        : business?.BusinessKyc instanceof Array &&
          business?.BusinessKyc.find(
            (el: any) => el?.type.toLowerCase() === "agency"
          );
      let res;
      if (findRecord) {
        res = await api.patch(`/business/${business.id}/kyc/${findRecord.id}`, {
          step: 2,
          residentialMeta: {
            nationality,
            state,
            lga,
            address,
            landmark,
          },
        });
      } else {
        let obj = {
          businessId: business.id,
          residentialMeta: {
            nationality,
            state,
            lga,
            address,
            landmark,
          },
        };

        consoleLog(obj, "Object for Kyc Input");
        res = await api.post(`/agency-banking/kyc`, obj);

        let busList = await api.get("/client/business");
        let findBusiness = busList?.payload.find(
          (el: any) => business?.id === el?.id
        );
        await dispatch(setBusinessLists(busList, true));
        await dispatch(setCurrentBusiness(findBusiness));
        consoleLog(res, "Response");
      }

      toast.success(res.message);
      await dispatch(
        setKycBoardingRec(
          kycBoardingRec instanceof Object && {
            ...kycBoardingRec,
            nationality,
            state,
            lga,
            address,
            landmark,
            businessId: business.id,
          }
        )
      );
      continueEvent();
      setIsLoading(false);
    } catch (error) {
      // consoleLog(error);
      // @ts-ignore
      toast.error(error);
      setIsLoading(false);
    }
  };

  const continueEvent = () => {
    history.push("/kyc/business-information");
  };

  const goBack = () => {
    history.push("/kyc/agency-banking-business-details");
  };

  const close = () => {
    history.push("/overview");
  };

  useEffect(() => {
    if (state && state !== "0") getLga();
  }, [state]);

  useEffect(() => {
    getStates();
    // getLga();
  }, []);

  let urlAddress = history?.location?.pathname;

  const [isDisabled, setDisabled] = useState(true);

  useEffect(() => {
    if (nationality && state && lga && address && landmark) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [nationality, state, lga, address, landmark]);

  return (
    <ParentCont>
      <LeftPanel>
        <FormCont>
          <TitleCont>
            {urlAddress !== kycStart && (
              <Back onClick={goBack}>
                <Icon type={"chevron-left"} size={20} />
              </Back>
            )}
            <FormTitle>Residential Information</FormTitle>
          </TitleCont>

          <FormSubTitle>
            With your corporate account number, you are guaranteed of your
            settlement.
          </FormSubTitle>

          <Form
            enableReinitialize
            initialValues={initialValues}
            validations={{
              nationality: Form.is.required(),
              state: Form.is.required(),
              lga: Form.is.required(),
              address: Form.is.required(),
              landmark: Form.is.required(),
            }}
            onSubmit={onSubmit}
            validate={() => {}}
            validateOnBlur={false}
          >
            <>
              <FormElement>
                <Form.Field.Input
                  name="nationality"
                  label="Nationality"
                  value={nationality}
                  disabled
                  // tip="Kindly enter your user password."
                />

                <Form.Field.Select
                  name="state"
                  label="State"
                  options={listOfStates(stateList)}
                  onChange={(e: any) => {
                    setState(e);
                    consoleLog(e);
                  }}
                  value={loadingStates ? "loading states" : state}
                  // tip="Kindly enter your user password."
                />

                <Form.Field.Select
                  name="lga"
                  label="LGA"
                  options={listOfLga(lgaList)}
                  value={loadingLga ? "loading LGA" : lga}
                  onChange={(e: any) => setlga(e)}
                  // tip="Kindly enter your user password."
                />

                <Form.Field.Input
                  name="address"
                  type="text"
                  label="Address"
                  onChange={(e: any) => setAddress(e)}
                  // tip="Kindly enter your user email address."
                />

                <Form.Field.Input
                  name="landmark"
                  type="text"
                  label="Close Landmark"
                  onChange={(e: any) => setLandmark(e)}
                  // tip="Kindly enter your user email address."
                />
              </FormElement>
              <Selectors>
                <ActionButton
                  onClick={onSubmit}
                  // onClick={continueEvent}
                  type="submit"
                  variant="primary"
                  isWorking={isLoading}
                  disabled={isDisabled}
                >
                  {checkExistingKycBoarding(
                    `nationality,state,lga,address,landmark`,
                    kycBoardingRec
                  )
                    ? "Update"
                    : "Save"}{" "}
                  and Continue
                </ActionButton>
              </Selectors>
            </>
          </Form>
        </FormCont>
      </LeftPanel>

      <MiddlePanel>
        <PageIndicator pageArr={AgencyBankingPages} page={1} status={true} />
      </MiddlePanel>

      <RightPanel>
        <RightPanelAuxCont>
          <CloseBtn onClick={close}>
            <Icon type={"close"} size={20} />
          </CloseBtn>
        </RightPanelAuxCont>

        <RightPanelAuxCon2>
          <DescriptionBox>
            <DescriptionTitle>Agency Banking</DescriptionTitle>

            <DescriptionText>
              Be ahead of late payment, switch to an instant epay platform.
            </DescriptionText>

            <DescriptionBtn
              onClick={() =>
                window.open(
                  "https://medium.com/@fountainpay",
                  "noopener,noreferrer"
                )
              }
            >
              Learn more
            </DescriptionBtn>
          </DescriptionBox>
        </RightPanelAuxCon2>
      </RightPanel>
    </ParentCont>
  );
}

export default ResidentialInfo;
