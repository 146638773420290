import { color } from "shared/utils/styles";
import styled from "styled-components";

export const TableRootContainer = styled.div`
  overflow-x: auto;
  overflow-y: auto;
`;
export const TableContainer = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
`;

export const Heading = styled.tr`
  text-align: center;
  height: 50px;
  background: #f1e6e6;
  color: ${color.fountain};
  font-weight: 400 !important;
  text-align: left;
  width: 100%;
`;

// export const TableDetails = styled.tr`
//   text-align: center;
//   height: 50px;
//   // display: flex;
//   margin: 0 1em;
// `;

export const TableDetails = styled.tbody`
  text-align: center;
  font-size: 14.7px !important;
`;

export const Removee = styled.td`
  height: 50px;
  display: flex;
  align-items: center;
  cursor: pointer;
  // background: blue;
  p {
    margin-left: 4px;
    color: ${color.danger};
  }
`;

export const Editt = styled.td`
  height: 50px;
  cursor: pointer;
  font-size: 14.7px;
  button {
    display: flex;
    align-items: center;
  }
  p {
    margin-left: 8px;
    color: ${color.success};
  }
`;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  background: ${color.light};
  padding: 0 1rem;
  height: 4.3rem;
  position: relative;
`;

export const PaginationButtonsContainer = styled.div`
  display: flex;
  gap: 1.4rem;
`;

export const PaginationPrev = styled.button`
  background-color: ${color.fountain};
  color: #fff;
  padding: 0.4rem 0.6rem;
  border-radius: 0.4rem;
  font-size: 12px;
  cursor: pointer;
`;

export const PaginationNext = styled.button`
  background-color: ${color.fountain};
  color: #fff;
  padding: 0.4rem 0.6rem;
  border-radius: 0.4rem;
  cursor: pointer;
  font-size: 12px;
`;
