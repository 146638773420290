import { useState, useEffect } from "react";
import {
  UploadContainer,
  UploadLabel,
  UploadBox,
  UploadInput,
  UploadButton,
  FieldError,
  TrashBtn,
  UploadedFile,
  HeadingCont,
  DownldCnt,
  SubAgentForm,
  ButtonContainer,
  ActionButton,
  FormContainer,
  SubTitle,
  TitleCase,
  FormBody,
  FormElement,
} from "./styles";
import { Icon, Form } from "shared/components";
import { useHistory } from "react-router-dom";
import api from "shared/utils/api";

import toast from "shared/utils/toast";
import { useDispatch } from "react-redux";
import { uploadFile } from "apiServices/authService";
import consoleLog from "shared/utils/consoleLog";
import { RootStateOrAny, useSelector } from "react-redux";
import { shortenUrl } from "shared/utils/ellipsis";
import { BsCloudDownload } from "react-icons/bs";
import { color } from "shared/utils/styles";

type PropsT={
  agentId?: string;
  initialValues: any;
  step: number;
  setStep: Function;
}
function UploadDocuments({agentId, initialValues, step, setStep}: PropsT) {
  // console.log("Values: ", initialValues)
  const [isLoginIn, setIsLoginIn] = useState(false)
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [isUploadingPass, setIsUploadingPass] = useState<boolean>(false);
  const [isUploadingBill, setIsUploadingBill] = useState<boolean>(false);
  const [isUploadingGuarantor, setIsUploadingGuarantor] = useState<boolean>(false);
  const [isUploadingProof, setIsUploadingProof] = useState<boolean>(false);
  const [identityCard, seIdentityCard] = useState<string>(initialValues?.agentKYC?.identityCard);
  const [passport, setPassport] = useState<string>(initialValues?.agentKYC?.passport);
  const [utilityBill, setBill] = useState<string>(initialValues?.agentKYC?.utilityBill);
  const [guarantor, setGuarantor] = useState<string>(initialValues?.agentKYC?.guarantorForm);
  const [proof, setProof] = useState<string>(initialValues?.agentKYC?.proof);

  
  const [error, setError] = useState<any>({
    identityCard: false,
    passport: false,
    utilityBill: false,
    guarantor: false,
    proof: false,
  });
  

  const uploadIdentityCard = async (files: any) => {
    if (files[0].size > 5000000) {
      return toast.error(
        "Please slected file should have a maximum size of 5mb."
      );
    }

    try {
      setIsUploading(true);
      let formData = new FormData();
      formData.append("certificate", files[0]);

      const response = await uploadFile(formData);
      consoleLog(response, "response");
      if (response.status === true) {
        const identity = response.payload?.fileUrl;
        // omniSaver("identity", identity);
        seIdentityCard(identity);
      }
    } catch (error) {
      // @ts-ignore
      toast.error(error);

      // consoleLog(error.toString());
    } finally {
      setIsUploading(false);
    }
  };

  const uploadPassport = async (files: any) => {
    if (files[0].size > 5000000) {
      return toast.error(
        "Please slected file should have a maximum size of 5mb."
      );
    }

    try {
      setIsUploadingPass(true);
      let formData = new FormData();
      formData.append("certificate", files[0]);
      const response = await uploadFile(formData);
      if (response.status === true) {
        const passport = response.payload?.fileUrl;
        // omniSaver("passport", passport);
        setPassport(passport);
        setIsUploadingPass(false);
      }
    } catch (error) {
      // @ts-ignore
      toast.error(error);
      setIsUploadingPass(false);
      // consoleLog(error.toString());
    }
  };

  const uploadUtilityBill = async (files: any) => {
    if (files[0].size > 5000000) {
      return toast.error(
        "Please slected file should have a maximum size of 5mb."
      );
    }

    try {
      setIsUploadingBill(true);
      let formData = new FormData();
      formData.append("certificate", files[0]);
      const response = await uploadFile(formData);
      if (response.status === true) {
        const utilityBill = response?.payload?.fileUrl;
        // omniSaver("utility_Bill", utilityBill);
        // localStorage.setItem("article", article);
        setBill(utilityBill);
        setIsUploadingBill(false);
      }
    } catch (error) {
      // @ts-ignore
      toast.error(error);
      setIsUploadingBill(false);
      // consoleLog(error.toString());
    }
  };

  const uploadGuarantorForm = async (files: any) => {
    if (files[0].size > 5000000) {
      return toast.error(
        "Please slected file should have a maximum size of 5mb."
      );
    }

    try {
      setIsUploadingGuarantor(true);
      let formData = new FormData();
      formData.append("certificate", files[0]);
      const response = await uploadFile(formData);
      if (response.status === true) {
        const guarantor = response?.payload?.fileUrl;
        // consoleLog('uploaded guarntora', guarantor)
        // omniSaver("gurantor_form", guarantor);
        setGuarantor(guarantor);
        setIsUploadingGuarantor(false);
      }
    } catch (error) {
      // @ts-ignore
      toast.error(error);
      setIsUploadingGuarantor(false);
      // consoleLog(error.toString());
    }
  };

  const uploadProofOfIdentity = async (files: any) => {
    if (files[0].size > 5000000) {
      return toast.error(
        "Please slected file should have a maximum size of 5mb."
      );
    }

    try {
      setIsUploadingProof(true);
      let formData = new FormData();
      formData.append("certificate", files[0]);
      const response = await uploadFile(formData);
      if (response.status === true) {
        const proof = response?.payload?.fileUrl;
        // omniSaver("proof_of_identity", proof);
        setProof(proof);
        setIsUploadingProof(false);
      }
    } catch (error) {
      // @ts-ignore
      toast.error(error);
      setIsUploadingProof(false);
      // consoleLog(error.toString());
    }
  };


  const onSubmit = async () => {
		
		try{
			if (!error?.identityCard && !error?.passport && !error?.utilityBill && !error?.guarantor && !error?.proof ) {
						setIsLoginIn(true)
			
						let subAgent = {
							identity_card: identityCard,
              passport: passport,
              utility_bill: utilityBill,
              guarantor_form: guarantor,
              proof: proof,
							agentId: agentId
						}
			
						consoleLog("Sub agent files: ", subAgent);
			
						const res = await api.post('/agency-banking/create-agents-kyc', subAgent)
						consoleLog('success ', res);
						toast.success(res.message)
						setStep(5)
					}
		}catch(error: any){
			// console.log("Error: ", error)
			toast.error(error.message)
		}finally{
			setIsLoginIn(false)
		}
	}

  return (
    
      <>
        <TitleCase>KYC documents</TitleCase>
						<SubTitle>
							With your corporate account number, you are guaranteed of your
							settlement
						</SubTitle>
						<FormBody>
							<Form
							enableReinitialize
							initialValues={initialValues}
							validations={{
								identityCard: [Form.is.required()],
								passport: [Form.is.required()],
								utilityBill: [Form.is.required()],
								guarantor: [Form.is.required()],
								proof: [Form.is.required()]
							}}
							onSubmit={onSubmit} 
							validate={function ({ }: {}) {
								//throw new Error('Function not implemented.')
							} } 
							validateOnBlur={true}					>
								<FormElement>
                  <SubAgentForm>
                    <UploadContainer>
                      <UploadLabel>Idenity Card</UploadLabel>
                      <input
                        type="file"
                        onChange={(e) => uploadIdentityCard(e.target.files)}
                        name="identityCard"
                        id="identityCard"
                        disabled={isUploading}
                        hidden
                      />
                      <UploadBox>
                        <Icon
                          type={"upload"}
                          color={"#760000"}
                          style={{ marginRight: 10 }}
                        />
                        <UploadButton htmlFor="identityCard">
                          {isUploading ? "Loading" : `Click to Add files`}
                        </UploadButton>
                      </UploadBox>
                      {error.identityCard && (
                        <FieldError>Identy Card is required</FieldError>
                      )}
                    </UploadContainer>
                    {identityCard && (
                      <UploadInput>
                        <UploadedFile>{shortenUrl(identityCard)}</UploadedFile>

                        <TrashBtn onClick={() => seIdentityCard("")}>
                          <Icon type={"trash"} color={"black"} />
                        </TrashBtn>
                      </UploadInput>
                    )}

                    <UploadContainer>
                      <UploadLabel>Passport Photograph</UploadLabel>
                      <input
                        type="file"
                        onChange={(e) => uploadPassport(e.target.files)}
                        name="passport"
                        id="passport"
                        disabled={isUploadingPass}
                        hidden
                      />
                      <UploadBox>
                        <Icon
                          type={"upload"}
                          color={"#760000"}
                          style={{ marginRight: 10 }}
                        />
                        <UploadButton htmlFor="passport">
                          {isUploadingPass ? "Loading" : `Click to Add files`}
                        </UploadButton>
                      </UploadBox>
                      {error.passport && (
                        <FieldError>Passport Photograph is required</FieldError>
                      )}
                    </UploadContainer>
                    {passport && (
                      <UploadInput>
                        <UploadedFile>{shortenUrl(passport)}</UploadedFile>

                        <TrashBtn onClick={() => setPassport("")}>
                          <Icon type={"trash"} color={"black"} />
                        </TrashBtn>
                      </UploadInput>
                    )}

                    <UploadContainer>
                      <UploadLabel>Utility Bill</UploadLabel>
                      <input
                        type="file"
                        onChange={(e) => uploadUtilityBill(e.target.files)}
                        name="utilityBill"
                        id="utilityBill"
                        disabled={isUploadingBill}
                        hidden
                      />
                      <UploadBox>
                        <Icon
                          type={"upload"}
                          color={"#760000"}
                          style={{ marginRight: 10 }}
                        />
                        <UploadButton htmlFor="utilityBill">
                          {isUploadingBill ? "Loading" : `Click to Add files`}
                        </UploadButton>
                      </UploadBox>
                      {error.utilityBill && (
                        <FieldError>Utility Bill is required</FieldError>
                      )}
                    </UploadContainer>
                    {utilityBill && (
                      <UploadInput>
                        <UploadedFile>{shortenUrl(utilityBill)}</UploadedFile>

                        <TrashBtn onClick={() => setBill("")}>
                          <Icon type={"trash"} color={"black"} />
                        </TrashBtn>
                      </UploadInput>
                    )}

                    <UploadContainer>
                      <UploadLabel>Guarantor Form</UploadLabel>
                      <input
                        type="file"
                        onChange={(e) => uploadGuarantorForm(e.target.files)}
                        name="guarantor"
                        id="guarantor"
                        disabled={isUploadingGuarantor}
                        hidden
                      />
                      <UploadBox>
                        <Icon
                          type={"upload"}
                          color={"#760000"}
                          style={{ marginRight: 10 }}
                        />
                        <UploadButton htmlFor="guarantor">
                          {isUploadingGuarantor ? "Loading" : `Click to Add files`}
                        </UploadButton>
                      </UploadBox>
                      {error.utilityBill && (
                        <FieldError>Guarantor Form is required</FieldError>
                      )}
                    </UploadContainer>
                    {guarantor && (
                      <UploadInput>
                        <UploadedFile>{shortenUrl(guarantor)}</UploadedFile>

                        <TrashBtn onClick={() => setGuarantor("")}>
                          <Icon type={"trash"} color={"black"} />
                        </TrashBtn>
                      </UploadInput>
                    )}

                    <UploadContainer>
                      <UploadLabel>Proof of Identity</UploadLabel>
                      <input
                        type="file"
                        onChange={(e) => uploadProofOfIdentity(e.target.files)}
                        name="proof"
                        id="proof"
                        disabled={isUploadingProof}
                        hidden
                      />
                      <UploadBox>
                        <Icon
                          type={"upload"}
                          color={"#760000"}
                          style={{ marginRight: 10 }}
                        />
                        <UploadButton htmlFor="proof">
                          {isUploadingProof ? "Loading" : `Click to Add files`}
                        </UploadButton>
                      </UploadBox>
                      {error.proof && (
                        <FieldError>Proof Of Identity is required</FieldError>
                      )}
                    </UploadContainer>
                    {proof && (
                      <UploadInput>
                        <UploadedFile>{shortenUrl(proof)}</UploadedFile>

                        <TrashBtn onClick={() => setProof("")}>
                          <Icon type={"trash"} color={"black"} />
                        </TrashBtn>
                      </UploadInput>
                    )}  
                  </SubAgentForm>
                  <ButtonContainer variant={agentId}>
                      {agentId !='' && 
                          <ActionButton
                          variant={color.transparent}
                          onClick={()=>{setStep(step+1)}}
                          >
                              Skip
                          </ActionButton>
                      }
                      <>
                          {step>1 && 
                              <ActionButton
                              type="button"
                              variant={color.greyColor}
                              onClick={()=>{setStep(step-1)}}
                              >
                                  Back
                              </ActionButton>
                          }
                          
                          <ActionButton
                              type="submit"
                              isWorking={isLoginIn}
                              onClick={onSubmit}
                          >
                              Next
                          </ActionButton>
                      </>
                  </ButtonContainer>
                </FormElement>
							</Form>	
						</FormBody>
      </>
  );
}

export default UploadDocuments;
