import styled from "styled-components";
import { color, font } from "shared/utils/styles";

type modalButtonPropT = {
  option?: boolean;
};

export const ModalContent = styled.div`
  width: 90%;
  margin: auto;
  padding: 1rem;
  padding-bottom: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;

  & p {
    padding: 0.4rem;
    text-align: center;
  }
`;

export const ModalButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  gap: 1rem;
`;

export const ModalButton = styled.button<modalButtonPropT>`
  padding: 0rem 2rem;
  height: 40px;
  background-color: ${(prop) =>
    prop.option ? color.fountainLight : color.fountain};
  color: ${(prop) => (prop.option ? "#000" : "#fff")};
  border-radius: 0.4rem;
  cursor: pointer;
`;
