import React, { useEffect, useState } from "react";
import DashboardSubHeader from "shared/components/DashboardSubHeader";
import Table from "shared/components/Table";
import { ActionTab, ActionsContainer } from "../Styles";
import { GradientLoader, Icon, Switch } from "shared/components";
import EllipsisLink from "shared/components/EllipsisLink";
import { OptionItem } from "shared/components/EllipsisLink/Styles";
import { OptionText } from "Services/PaymentLink/PaymentLinkList/Styles";
import CreateCategory from "./create-category";
import { InventoryService } from "../inventory-service";
import { RootStateOrAny, useSelector } from "react-redux";
import { IFecthedCategories, IFecthedCategory } from "../inventory-models";
import DeleteCategory from "./delete-category";
import toast from "shared/utils/toast";

export default function InventoryCategory() {
  const [isDatafetched, setisDatafetched] = useState(false);
  const currentUser = useSelector((store: RootStateOrAny) => store.currentUser);

  const [categoryList, setcategoryList] = useState<any[]>([]);
  const [selectedCategory, setselectedCategory] = useState<
    IFecthedCategory | {}
  >({});

  const [currentAction, setcurrentAction] = useState("");
  const [ismodalOpen, setismodalOpen] = useState(false);
  const currentBusiness = useSelector(
    (store: RootStateOrAny) => store.business
  );

  const headData: any = [
    { id: "category", label: "Category" },
    { id: "action", label: "" },
  ];

  const handleUpdateCatgory = async (data: any) => {
    // // console.log(data.uuid);
    try {
      const payload = {
        name: data.name,
        id: data.uuid,
        user_id: currentUser?.id,
        status: data.status === "ACTIVATED" ? "DEACTIVATED" : "ACTIVATED",
      };
      const response = await InventoryService.UpdateCategory(payload);
      if (response.status) {
        getcategpries();
        toast.success("Category updated successfully.");
      }
    } catch (err) {
      const error: any = err;
      toast.error(error.message);
      // // console.log(error);
    } finally {
    }
  };

  const normalizeOptionStatus = (el: any, status: string) => {
    return el?.filter(
      (x: any) =>
        x.type.toString().toLowerCase() != status.toString().toLowerCase()
    );
  };
  const options = (item: any) => [
    {
      type: "Trans",
      title: (
        <OptionItem>
          <Icon type="reports" size={15} />
          <OptionText style={{ marginLeft: 20 }}>
            Activate / Deactivate
          </OptionText>
        </OptionItem>
      ),
      // link: () => viewTerminalTransactionFunction(item),
    },
  ];

  const dummyData = [
    {
      category: "Hardware",
      action: (
        <ActionsContainer>
          <Switch
            id={"SubAction"}
            status={true}
            label={"Active"}
            toggleMethod={() => ""}
          />
          <ActionTab
            onClick={() => {
              setcurrentAction("Edit");
              setismodalOpen(true);
            }}
          >
            <Icon type="pencil" size={12} />
            <span>Edit</span>
          </ActionTab>
          <ActionTab>
            <Icon type="trash" size={15} />
            <span>Delete</span>
          </ActionTab>
        </ActionsContainer>
      ),
    },
    {
      category: "Materials",
      action: (
        <ActionsContainer>
          <Switch
            id={"SubAction"}
            status={true}
            label={"Active"}
            toggleMethod={() => ""}
          />
          <ActionTab
            onClick={() => {
              setcurrentAction("Edit");
              setismodalOpen(true);
            }}
          >
            <Icon type="pencil" size={12} />
            <span>Edit</span>
          </ActionTab>
          <ActionTab>
            <Icon type="trash" size={15} />
            <span>Delete</span>
          </ActionTab>
        </ActionsContainer>
      ),
    },
  ];
  const SearchForData = () => {};

  const getcategpries = async () => {
    const response = await InventoryService.GetCategories(currentBusiness?.id);
    if (response.status) {
      const data: IFecthedCategories = Object.values(response.payload);
      // // console.log("categories", response.payload);
      const transformedList = data.map((category: any) => {
        const name = category.name;
        return {
          id: category.id,
          category: name.toUpperCase(),
          businessId: category.businessId,
          action: (
            <ActionsContainer>
              <Switch
                id={category.uuid}
                status={category.status === "ACTIVATED" ? true : false}
                label={category.status === "ACTIVATED" ? "Active" : "INACTIVE"}
                toggleMethod={() => {
                  handleUpdateCatgory(category);
                }}
              />
              <ActionTab
                onClick={() => {
                  setcurrentAction("Edit");
                  setselectedCategory(category);
                  setismodalOpen(true);
                }}
                style={{ marginLeft: "2rem" }}
              >
                <Icon type="pencil" size={12} />
                <span>Edit</span>
              </ActionTab>
              <ActionTab
                onClick={() => {
                  setcurrentAction("Delete");
                  setselectedCategory(category);
                  setismodalOpen(true);
                }}
              >
                <Icon type="trash" size={15} />
                <span>Delete</span>
              </ActionTab>
            </ActionsContainer>
          ),
        };
      });
      setcategoryList(transformedList);
      setisDatafetched(true);
    }
  };

  useEffect(() => {
    if (!isDatafetched) {
      getcategpries();
    }
  }, []);

  return (
    <div>
      <DashboardSubHeader
        // count={0}
        title={"Category"}
        btnTitle="Create Category"
        callBackFn={() => {
          setcurrentAction("Create");
          setismodalOpen(true);
        }} //setModal(true)
        setSearchData={SearchForData}
        withSearch={true}
        withFilter={false}
        widthActionBtn={true}
        arrToFilter={[]}
        applyFilter={() => ""}
        clickedParam={""}
        getParams={() => ""}
        setStartDate={() => {}}
        setEndDate={() => {}}
      />

      {!isDatafetched ? (
        <GradientLoader />
      ) : (
        <Table
          headData={headData}
          bodyData={categoryList}
          hoverLink
          serialNumber
          totalPages={0}
          currentpage={0}
          emptyStateMessage="No Category"
        />
      )}

      {currentAction === "Create" && ismodalOpen && (
        <CreateCategory
          makeApiCall={getcategpries}
          isopen={ismodalOpen}
          action={currentAction}
          setisOpen={() => setismodalOpen(!ismodalOpen)}
        />
      )}

      {currentAction === "Edit" && ismodalOpen && (
        <CreateCategory
          makeApiCall={getcategpries}
          data={selectedCategory}
          isopen={ismodalOpen}
          action={currentAction}
          setisOpen={() => setismodalOpen(!ismodalOpen)}
        />
      )}

      {currentAction === "Delete" && ismodalOpen && (
        <DeleteCategory
          data={selectedCategory}
          isopen={ismodalOpen}
          action={currentAction}
          setisOpen={() => setismodalOpen(!ismodalOpen)}
        />
      )}
    </div>
  );
}
