import React, { useState } from "react";

import {
  SubHeader,
  SubHeaderChildren,
  StateContainer,
  PanelContainer,
  Tab,
} from "./Styles";
import EmptyState from "../../shared/components/EmptyState";
import Table from "shared/components/Table";
import Layout from "Layout";
import DashboardSubHeader from "shared/components/DashboardSubHeader";
import { Switch } from "shared/components";
import consoleLog from "shared/utils/consoleLog";
import { Modal } from "shared/components";
import InventoryCategory from "./inventory-category/inventory-category";
import InventoryAssets from "./inventory-assets/inventory-asset";

function Inventory() {
  const [modal, setModal] = useState(false);
  const [searchData, setSearchData] = useState("");
  const [currentTab, setcurrentTab] = useState("categories");

  const openModal = () => {
    setModal(!modal);
  };

  const toggle = () => {
    consoleLog("toggle");
  };

  const headData: any = [
    { id: "agentName", label: "Agent Name" },
    { id: "agentPhoneNumber", label: "Agent Phone Number" },
    { id: "email", label: "Email" },
    { id: "permission", label: "Permission" },
  ];

  const SearchForData = () => {};

  return (
    // <Layout PageTitle="Inventory">
    //   <StateContainer>
    //     <EmptyState
    //       stateTitle={"Under construction"}
    //       statePara={"Great features awaits you here."}
    //     />
    //   </StateContainer>
    // </Layout>

    <div style={{ minHeight: "80vh" }}>
      <PanelContainer>
        <Tab
          isactive={currentTab === "categories" ? true : false}
          onClick={() => setcurrentTab("categories")}
        >
          Categories
        </Tab>
        <Tab
          isactive={currentTab === "assets" ? true : false}
          onClick={() => setcurrentTab("assets")}
        >
          Assets
        </Tab>
      </PanelContainer>
      {currentTab === "categories" && <InventoryCategory />}
      {currentTab === "assets" && <InventoryAssets />}
    </div>
  );
}

export default Inventory;
