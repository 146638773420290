import { Button } from "shared/components";
import breakpoints from "shared/utils/breakpoints";
import { color } from "shared/utils/styles";
import styled from "styled-components";

export const InputHolder = styled.div`
  width: 45%;
`;

export const LabelHolder = styled.p`
  text-align: left;
`;

export const Header = styled.div`
  display: flex;
  background: #ffffff;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.05);
  align-items: center;
  height: 74px;
  padding: 10px;
  justify-content: space-between;
  margin-bottom: 15px;
  transition: ease-in-out 0.3s;
  /* overflow: hidden; */
  @media ${breakpoints.device.max.xs} {
    flex-direction: column;
    height: auto;
  }
`;

export const DownloadOptCont = styled.div`
  height: 108px;
  width: 241px;
  background: #FFFFFF;
  box-shadow: 0px 4px 250px rgba(118, 0, 0, 0.2);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const SearchInput = styled.div`
  min-width: 250px;
  margin-right: 5px;
  @media ${breakpoints.device.max.xs} {
    min-width: 100%;
  }
`;

export const Title = styled.p`
  margin-left: 1em;
  @media ${breakpoints.device.max.xs} {
    margin-bottom: 10px;
  }
  align-items: center;
  justify-content: center;
  display: flex;
`;

export const ResetButton = styled(Button)`
  background-color: transparent;
  color: ${color.fountain};
  padding: 0 2em;
  @media ${breakpoints.device.max.xs} {
    width: 100%;
  }
  &:hover {
    cursor: pointer;
  }
`;

export const ActionButton = styled(Button)`
  background-color: ${color.fountain};
  color: #fff;
  padding: 0 2em;
  @media ${breakpoints.device.max.xs} {
    width: 100%;
    margin-top: 5px;
  }
  &:hover {
    cursor: pointer;
  }
`;

export const ActionButton2 = styled(Button)`
  background-color: transparent;
  color: #000;
  padding: 0 2em;
  @media ${breakpoints.device.max.xs} {
    width: 100%;
    margin-top: 5px;
  }
  &:hover {
    cursor: pointer;
    background-color: #fff;
  }
`;

export const FilterButton = styled(Button)`
  background-color: ${color.textDarkest};
  color: #fff;
  margin: 0 1em;
  padding: 0 2em;
  div {
    display: flex;
    align-items: center;
  }
  img {
    margin-left: 10px;
  }
  @media ${breakpoints.device.max.xs} {
    width: 100%;
    margin: 0;
    margin-top: 5px;
  }
`;

export const PopUpHead = styled.div`
  width: 439px;
  height: 54px;
  background-color: #fad6d6;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  @media ${breakpoints.device.max.xs} {
    width: 100%;
  }
`;

export const PopUpSubHead = styled.div`
  width: 100%;
  height: 40px;
  background-color: #FBFBFB;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 5px 30px;
  font-size: 14px;
  font-weight: bold;
  margin-bottom:10px;
  margin-top:10px;
`;

export const PopContent = styled.div`
  margin: 5px 0px;
  /* height:300px; */
  overflow: auto;
  margin: 10px 0px;
`;
export const FilterTitle = styled.p`
  color: ${color.fountain};
  font-size: 16px;
  font-weight: 600;
`;

export const CloseFilter = styled.p`
  padding-top: 3px;
  background-color: #fff;
  width: 24px;
  height: 24px;
  border-radius: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`;

export const FilterSec = styled.div`
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
`;

export const DownloadSec = styled.div`
  width: 300px;
  height: 120px;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
`;

export const DownloadSec2 = styled.div`
  margin-top: 15px;
  width: 300px;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
`;

export const FormRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start; */
  /* width: 400px; */
  /* margin-bottom: 15px; */
  @media ${breakpoints.device.max.xs} {
    /* flex-direction: column; */
    width: 100%;
  }
`;

export const FilterParamsCont = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 35px;
`;

export const FilterText = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 0px 35px;
`;

export const TextDescription = styled.div`
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: left;
  color: #828282;
`;

export const FilterInputCont = styled.div`
  margin-bottom: 5px;
  width: 85%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const SingleInputCont = styled.div`
  text-align: center;
  margin: 0px auto 15px auto;
  width: 80%;
`;

export const FilterParams = styled.p`
  font-size: 14px;
  font-weight: 400px;
  color: #292929;
  &:hover {
    cursor: pointer;
  }
`;

export const PopUpFooter = styled.div`
  background-color: #fff;
  width: 439px;
  border-top: 1px solid #d7d7d7;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 10px 10% 10px 0px;
  @media ${breakpoints.device.max.xs} {
    width: 100%;
    flex-direction: column;
  }
`;

export const LeftPane = styled.div`
  display: flex;
  align-items: center;
  // flex-direction: column;
  @media ${breakpoints.device.max.xs} {
    width: 100%;
    flex-direction: column;
  }
`;

export const PopUpCont = styled.div`
  transition: ease-in-out 0.5s;
  position: relative;
  @media ${breakpoints.device.max.xs} {
    width: 100%;
  }
`;

export const PopUp = styled.div`
  /* visibility: visible;
  width: 100%;
  margin-top: 15px;
  // z-index: 9999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 0px;
  transition: ease-in-out 0.6s; */
  position: absolute;
  background-color: #fff;
  z-index: 99;
  font-size: 14px;
  text-align: center;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  right: 5px;
  @media ${breakpoints.device.max.xs} {
    width: 100%;
  }
`;

export const PopUpItem = styled.p`
  /* margin-bottom: 5px; */
  padding: 5px 10px;
  border-bottom: 1px solid #d7d7d7;
  &:hover {
    cursor: pointer;
  }
`;

export const DownloadOptTitle = styled.div`
  color: black;
  font-size: 16px;
  font-weight: 600;
  width: 100%;
  text-align: left;
  margin-bottom: 10px;
`;

export const DownloadOptBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 268px;
  height: 120px;
  padding: 0px 5px;
`;

export const DownloadOptFooter = styled.div`
  background-color: #fff;
  width: 100%;
  border-top: 1px solid #d7d7d7;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 10px 5% 10px 0px;
  @media ${breakpoints.device.max.xs} {
    width: 100%;
    flex-direction: column;
  }
`;

export const ResetDownloadButton = styled(Button)`
  background-color: transparent;
  color: ${color.fountain};
  padding: 0 2em;
  height: 36px;
  @media ${breakpoints.device.max.xs} {
    width: 100%;
  }
  &:hover {
    cursor: pointer;
  }
`;

export const ActionoloadButton = styled(Button)`
  background-color: ${color.fountain};
  color: #fff;
  padding: 0 2em;
  height: 36px;
  @media ${breakpoints.device.max.xs} {
    width: 100%;
    margin-top: 5px;
  }
  &:hover {
    cursor: pointer;
  }
`;
