import styled from "styled-components";
import { color, font } from "../../utils/styles";

type PropT = {
  onChange: Function;
};

export const FormInput = styled.input<PropT>`
  width: 100%;
  padding: 1rem 0.4rem;
  border: 1px solid ${color.backgroundLightPrimary};
  border-radius: 0.2rem;

  &:disabled {
    background: #dddddd;
  }
`;

export const FormDisabledInput = styled.input`
  width: 100%;
  padding: 1rem 0.4rem;
  border: 1px solid ${color.backgroundLightPrimary};
  border-radius: 0.2rem;
`;

export const InputLabel = styled.label`
  color: ${color.textDark};
  margin-bottom: 0.4rem;
  display: block;
  font-size: 14px;
`;
