export const shortenUrl = (
  url: string,
  prefix: boolean = true,
  num: number = 10
) => {
  if (!url) return "";
  let urlArray = url.split("/");
  return prefix
    ? `...${urlArray[urlArray.length - 1]}`
    : `${url.substring(0, num)}...`;
};
