import React from "react";
import NoRecord from "../../../App/assets/addnewitem.svg";
import { Container, Title, Tag, ActionButton } from "./Styles";
import { useHistory } from "react-router-dom";

type Props={
  stateTitle: string;
  statePara?: string;
  action?: boolean;
  actionText?: string; 
  actionPath?: string;
}
const EmptyState=({ stateTitle, statePara, action, actionText, actionPath}: Props)=> {
  const history = useHistory();

  const getToLink=(val?:string)=>{
    history.push(val || ""); 
  }
  return (
    <Container>
      <img src={NoRecord} />
      <Title style={{fontWeight:"normal"}}>{stateTitle}</Title>
      <Tag>{statePara}</Tag>
      {action &&
        <ActionButton
            type="button"
            
            onClick={()=>getToLink(actionPath)}
        >{actionText}
        </ActionButton>
      }
      
    </Container>
  );
}

export default EmptyState;
