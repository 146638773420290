import { useState } from "react";
import {
  ParentCont,
  LeftPanel,
  RightPanel,
  RightPanelAuxCont,
  CloseBtn,
  DescriptionBox,
  RightPanelAuxCon2,
  DescriptionTitle,
  DescriptionText,
  DescriptionBtn,
  FormCont,
  TitleCont,
  Back,
  FormTitle,
  FormSubTitle,
  SelectionBox,
  ActionButton,
  Selectors,
} from "./Styles";
import { Icon } from "shared/components";
import { useHistory } from "react-router-dom";
import { RootStateOrAny, useSelector, useDispatch } from "react-redux";
import { setKycBoardingRec } from "store/actions/boardingAction";
import checkExistingKycBoarding from "shared/utils/checkExistingKycBoarding";

function BusinessType() {
  const kycBoardingRec = useSelector(
    (store: RootStateOrAny) => store.kycBoardingRec
  );
  const dispatch = useDispatch();
  const [bizType, setBizType] = useState<string>(
    kycBoardingRec?.bizType ?? "Gateway"
  );

  const businesses = useSelector((store: RootStateOrAny) => store.businesses);
  const history = useHistory();

  const goBack = () => {
    history.push("/kyc/personal-details");
  };

  const check = () => {
    if (businesses[0]?.status === "ACTIVE") {
      jump();
    } else {
      jump();
    }
  };

  const jump = async () => {
    await dispatch(
      setKycBoardingRec(
        kycBoardingRec instanceof Object && {
          ...kycBoardingRec,
          bizType,
        }
      )
    );
    if (bizType === "Gateway") {
      history.push("/kyc/gateway-business-details");
    } else if (bizType === "Agency Banking") {
      history.push("/kyc/agency-banking-business-details");
    }
  };

  const close = () => {
    history.push("/overview");
  };

  return (
    <ParentCont>
      <LeftPanel>
        <FormCont>
          <TitleCont>
            {/*<Back onClick={goBack}>
                        <Icon type={'chevron-left'} size={20} />
                    </Back>*/}
            <FormTitle>Choose Your Business Type</FormTitle>
          </TitleCont>

          <FormSubTitle>
            With your corporate account number, you are guaranteed of your
            settlement.
          </FormSubTitle>

          <SelectionBox
            onClick={() => setBizType("Gateway")}
            style={{
              borderColor: bizType === "Gateway" ? "#760000" : "grey",
              borderWidth: bizType === "Gateway" ? 3 : 1,
            }}
          >
            <h3>Gateway</h3>
          </SelectionBox>

          <SelectionBox
            onClick={() => setBizType("Agency Banking")}
            style={{
              borderColor: bizType === "Agency Banking" ? "#760000" : "grey",
              borderWidth: bizType === "Agency Banking" ? 3 : 1,
            }}
          >
            <h3>Agency Banking</h3>
          </SelectionBox>

          <Selectors>
            <ActionButton onClick={check} type="submit" variant="primary">
              {checkExistingKycBoarding(`bizType`, kycBoardingRec)
                ? "Update"
                : "Save"}{" "}
              and Continue
            </ActionButton>
          </Selectors>
        </FormCont>
      </LeftPanel>

      <RightPanel>
        <RightPanelAuxCont>
          <CloseBtn onClick={close}>
            <Icon type={"close"} size={20} />
          </CloseBtn>
        </RightPanelAuxCont>

        <RightPanelAuxCon2>
          <DescriptionBox>
            <DescriptionTitle>{bizType}</DescriptionTitle>

            <DescriptionText>
              {bizType === "Gateway"
                ? "Enjoy, quick, and secure way to send and receive money."
                : "Be ahead of late payment, switch to an instant epay platform."}
            </DescriptionText>

            <DescriptionBtn
              onClick={() =>
                window.open(
                  "https://medium.com/@fountainpay",
                  "noopener,noreferrer"
                )
              }
            >
              Learn more
            </DescriptionBtn>
          </DescriptionBox>
        </RightPanelAuxCon2>
      </RightPanel>
    </ParentCont>
  );
}

export default BusinessType;
