import React, { useState, useEffect } from "react";
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";

import toast from "shared/utils/toast";
import AddBankAccount from "Settings/BankAccount/Modal";
import consoleLog from "shared/utils/consoleLog";
import api from "shared/utils/api";

const SecondStep = ({ setVisible, setStep, setReload }: any) => {
  const service = useSelector((store: RootStateOrAny) => store.service);

  const addRecord = async (val: any) => {
    try {
      let response;

      response = await api.put(`/services/${service?.id}`, {
        bankAccountId: val,
      });

      if (response.status === true) {
        toast.success(response.message);
        setVisible(false);
        setReload();
      } else {
        toast.error(response.message);
      }
      consoleLog(response, "response");
    } catch (err) {
      consoleLog(err, "error");
      // @ts-ignore
      toast.error(err);
    } finally {
      // setLoading(false);
    }
  };
  return (
    <AddBankAccount
      openModal={setVisible}
      addRecord={addRecord}
      setStep={setStep}
      addBack
    />
  );
};

export default SecondStep;
