import React, { useEffect, useState } from "react";
import {
  ActionButton,
  EditButton,
  EmptyState,
  ModeSpan,
  ServicePageActionButtons,
  ServicePageContainer,
  ServicePageContent,
  ServicePageSubHeader,
  WalletEmptyStateDesc,
  WalletSummaryCards,
  WalletSummaryDetails,
} from "./Styles";
import { RootStateOrAny, useSelector } from "react-redux";
import { GradientLoader, Icon, Modal, Switch } from "shared/components";
import useServiceMode from "shared/hooks/useServiceMode";
import Table from "shared/components/Table";
import { getStatusColor, getStatusDivColor } from "shared/utils/statusColor";
import { formateDate, formateDateString } from "shared/utils/utility";
import moment from "moment";
import AddService from "Services/AddService";
import WalletIco from "App/assets/addnewitem.svg";
import consoleLog from "shared/utils/consoleLog";
import api from "shared/utils/api";
import toast from "shared/utils/toast";
import { currencyFormat100 } from "shared/utils/moneyFormat";

export default function WalletTransactions() {
  const service = useSelector((store: RootStateOrAny) => store.service);
  const [isWallet, setIsWallet] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [transactions, setTransactions] = useState<any>([]);
  const { mode, name, serviceId, status, createdAt } = service;

  const columnHeaders = [
    { id: "reference", label: "Reference" },
    { id: "paymentCurrency", label: "Currency" },
    { id: "amount", label: "Amount" },
    { id: "narration", label: "Narration" },
    { id: "transactionType", label: "Type" },
    { id: "status", label: "Status" },
    { id: "updatedAt", label: "Date Created" },
  ];

  const truncate = (item: string) => {
    if (!item) {
      return "";
    }

    const str: string = item.length > 15 ? item.substring(0, 15) + "..." : item;

    return <>{str}</>;
  };
  
  const getWalletTransactions=async()=>{
    setIsLoading(true)
    setIsWallet(false)
    try{
      const date = new Date();

      let day = date.getDate();
      let month = date.getMonth() - 1;
      let thiMonth=date.getMonth();
      let year = date.getFullYear();

      const resp=await api.post(`/services/${service?.id}/wallet/transactions`, {
        service_id: service.id,
        date:{
          start: `${year}/${month}/${day}`,
          end: `${year}/${thiMonth}/${day}`
        }
      });
      if(resp.status){
          setIsWallet(true)
          setTransactions(resp.payload)
      }
    }catch(err: any){
      setIsWallet(false)
      // console.log(err)
    }finally{
      setIsLoading(false)
    }
  }

  // console.log("Transactions: ", transactions)
  let data =transactions.data instanceof Array
      ? transactions?.data.map((el: any) => {
          let obj: any = {};
          obj.reference = truncate(el?.reference);
          obj.paymentCurrency = el?.paymentCurrency;
          obj.narration = el?.narration;
          obj.transactionType = el?.transactionType.toLowerCase()=='credit' ? (
              <><Icon type={"arrow-down"} size={20} color={el?.status.toLowerCase().includes("init")?getStatusColor("pending"):getStatusColor(el?.status)} />{el?.transactionType}</>
            ) : (
              <><Icon type={"arrow-up"} size={20} color={el?.status.toLowerCase().includes("init")?getStatusColor("pending"):getStatusColor(el?.status)} />{el?.transactionType}</>
            );
            
          obj.updatedAt =
            el?.updatedAt &&
            new Date(el?.updatedAt).toLocaleString("en-GB", {
              year: "numeric",
              month: "numeric",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            });
          obj.amount =
            el.amount && `${currencyFormat100(el.amount, el.paymentCurrency)}`;
          
          obj.status =
            el.status &&
            (el.status.toLowerCase().includes("init") ? (
              <div style={{ color: getStatusColor("pending") }}>
                In progress
              </div>
            ) : (
              <div style={{ color: getStatusColor(el?.status) }}>
                {el?.status?.toLowerCase()[0].toUpperCase() +
                  el?.status?.toLowerCase().substring(1)}
              </div>
            ));

          return { ...el, ...obj };
        })
      : [];

  useEffect(() => {
    getWalletTransactions()
  }, []);

  return (
    <div>
      <ServicePageContainer>
        <h1>
          Wallet transactions
        </h1>
        <ServicePageSubHeader>
          <p>Find your wallet transactions here</p>
        </ServicePageSubHeader>

        <ServicePageContent>
          {isWallet && !isLoading &&
            <>
              <Table
                headData={columnHeaders}
                bodyData={data}
                clickFunction={() => ""}
                hoverLink
              />
            </>
          }

        {!isWallet && !isLoading && 
          <EmptyState>
            <div>
              <img src={WalletIco} width={250}/>
            </div>
            <WalletEmptyStateDesc>You do not have any transaction in your wallet. </WalletEmptyStateDesc>
            
          </EmptyState>
        }
        
        {isLoading && 
          <GradientLoader />
        }
        </ServicePageContent>
      </ServicePageContainer>

    </div>
  );
}
