import { Button, Form } from "shared/components";
import breakpoints from "shared/utils/breakpoints";
import { color } from "shared/utils/styles";
import styled from "styled-components";

export const FormElement = styled(Form.Element)``;
type PropT={
  variant?: string;
}
export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  overflow: auto;
`;

export const ImageContainer = styled.div`
  width: 30%;
  display: flex;
  justify-content: center;
  padding: 2rem 0;
  background-color: ${color.backgroundLight};
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
`;

export const Image = styled.img`
  object-fit: center;
  margin-right: 10%;
  @media ${breakpoints.device.max.xs} {
    display: none;
  }
`;

export const FormContainer = styled.div`
  width: 100%;
  padding: 2rem;
  @media ${breakpoints.device.max.xs} {
    margin: 15px;
  }
`;

export const SubAgentForm = styled.form`
  margin-top: 2rem;
  width: 100%;
  position: relative;
`;
export const InputLabel = styled.label`
  display: block;
`;

export const FromGroup = styled.div`
  width: 100%;
  display: flex;
  gap: 2rem;
  margin-bottom: 2rem;
`;

export const InputGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
`;

export const Input = styled.input`
  width: 100%;
  height: 54px;
  padding: 0 0.4rem;
  border: 1px solid ${color.backgroundMedium};
  border-radius: 0.4rem;
`;

// export const ActionButton = styled.button`

// `

export const TitleCase = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: -0.02em;

  /* Primary Black */

  color: #292929;
`;

export const SubTitle = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  width: 20em;
  margin-top: 0.6rem;

  /* Grey Text 1 */

  color: #828282;
  @media ${breakpoints.device.max.xs} {
    width: 100%;
  }
`;

export const FormBody = styled.div`
  margin-top: 20px;
`;

export const ButtonContainer = styled.div`
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  margin-top: 16px;
`;
export const Back = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  /* identical to box height */
  margin-top: 16px;
  cursor: pointer;

  /* Grey Text 1 */

  color: #828282;
`;

export const ActionButton = styled(Button)<PropT>`
  margin: 1em 0;
  color: #fff;
  width: 138px;
  height: 54px;
  float: right;
  //background-color: ${color.fountain};
  background: ${(props: any) => color[props.variant]};
`;
