import { ActionTypes } from "../constants/actionType";
import consoleLog from "shared/utils/consoleLog";

export const kycBoardingRecReducer = (state: any = {}, action: any) => {
  switch (action.type) {
    case ActionTypes.KYC_BOARDING_RECORD:
      return { ...action.payload };
    default:
      return state;
  }
};
