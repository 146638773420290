import styled, { css } from "styled-components";
import { color, font } from "shared/utils/styles";

type PropT = {
  variants?: string;
};

export const SubHeader = styled.div`
  height: 72px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.05);
  margin-bottom: 50px;
  padding-left: 20px;
  padding-right: 20px;
`;

export const SubHeaderChildren = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const SubHeaderChildren2 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 580px;
`;

export const TrsactionCount = styled.p`
  font-weight: 600;
  font-size: 18px;
  line-height: 21.01px;
  color: #292929;
`;

export const SearchBar = styled.div`
  width: 180px;
  height: 54px;
`;

export const SearchInput = styled.input`
  height: 54px;
  width: 180px;
  padding: 0 7px;
  border-radius: 3px;
  border: 1px solid grey;
  color: ${color.textDarkest};
  background: white;
  transition: background 0.1s;
  ${font.regular}
  ${font.size(15)}
  &:hover {
    background: ${color.backgroundLight};
  }
  &:focus {
    background: #fff;
    border: 1px solid ${color.borderInputFocus};
    box-shadow: 0 0 0 1px ${color.borderInputFocus};
  }
`;

export const FilterButton = styled.button`
  background-color: #0e314c;
  width: 129px;
  height: 54px;
  border: none;
  border-radius: 5px;
  color: #fff;
  font-weight: 600px;
  font-size: 18px;
  line-height: 21px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const DownloadSummaryBtn = styled.button`
  background-color: #760000;
  border: none;
  outline: none;
  height: 54px;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  line-height: 21px;
  width: 241px;
  cursor: pointer;
  border-radius: 5px;
`;

export const TransactionTable = styled.div`
  width: 100%;
  text-align: center;
  border-collapse: collapse;
`;

export const TransactionTableHead = styled.div`
  background-color: #f1e6e6;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
`;

export const TransactionTableHeadRow = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #760000;
  width: 100px;
`;

export const TransactionTableBodyRow = styled.div`
  background-color: #fff;
  background: #ffffff;
  border-bottom: 0.5px solid #828282;
  height: 50px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  &:hover {
    transition: ease-in-out 0.5s;
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

export const TransactionTableBodyRowData = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #292929;
  width: 100px;
  overflow: hidden;
`;

export const AuxLink = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #292929;
`;

export const PageBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const PageBodyChild1 = styled.div`
  width: 532px;
  background: #ffffff;
  padding: 30px 20px;
  @media (max-width: 1427px) {
    width: 450px;
  }
`;

export const PageBodyChild2 = styled.div`
  width: 392px;
  @media (max-width: 1427px) {
    width: 300px;
  }
`;

export const AnalyticsContainer = styled.div`
  width: 392px;
  height: 261px;
  width: 100%;
  background: #ffffff;
  padding: 30px 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const TransactionDesc = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

export const TranactionLabel = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #828282;
`;

export const TranactionData = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #292929;
`;

export const AnalyticsLabel = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #828282;
  margin-bottom: 10px;
`;

export const AnalyticsTitle = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #828282;
  margin-bottom: 10px;
`;

export const AnalyticsData = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #292929;
  margin-bottom: 20px;
`;
export const EmptyContainer = styled.div`
  margin-top: 20px;
`;

export const StatusContainer = styled.div<PropT>`
  color: ${(props) => color[props.variants ?? "primary"]};
`;

export const TableActions = styled.div`
  display: flex;
  justify-content: end;
  gap: 0.1rem;
`;

export const ViewAllBtn = styled.div`
  cursor: pointer;
  color: #fff;
  border-radius: 0.4rem;
  padding: 0.6rem 1rem;
  font-size: 12px;
  display: flex;
  gap: 0.2rem;
  align-items: center;
  span {
    color: green;
    font-size: 0.8rem;
  }
`;
export const ActionBtn = styled.img`
  width: 0.8rem;
  height: auto;
`;