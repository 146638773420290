export const tourSteps = [
  {
    target: "body",
    content: (
      <>
        <h2>Welcome to our tour guide</h2>
        <p>
          When you click "next", it will stop the tour, navigate to route A, and
          continue the tour.
        </p>
      </>
    ),
    locale: { Skip: <strong>SKIP</strong> },
    placement: "bottom",
    disableBeacon: true,
  },
  {
    target: "#overview",
    content: (
      <>
        <h2>Overview</h2>
        <p>
          Summarizes your accounts, highlighting the dashboard tour, knowledge
          base, documentation, account settings, and more
        </p>
      </>
    ),
    locale: { Skip: <strong>SKIP</strong> },
    disableBeacon: true,
    placement: "bottom",
  },
  {
    target: "#myApps",
    content: (
      <>
        <h2>My Apps</h2>
        <p>
          Here, core functions, such as services, app details, app
          configuration, wallets, and webhooks, are carried out in the central
          area where key actions and transactions are performed.
        </p>
      </>
    ),
    locale: { Skip: <strong>SKIP</strong> },
    disableBeacon: true,
    placement: "right",
  },
  {
    target: "#customers",
    content: (
      <>
        <h2>Customers</h2>
        <p>
          Enclosed are the details of the clients that initiate transactions
          with the merchant.
        </p>
      </>
    ),
    locale: { Skip: <strong>SKIP</strong> },
    disableBeacon: true,
    placement: "right",
  },

  {
    target: "#subAccounts",
    content: (
      <>
        <h2>Sub Account</h2>
        <p>
          Here, you can create multiple small accounts within a main account.
        </p>
      </>
    ),
    locale: { Skip: <strong>SKIP</strong> },
    disableBeacon: true,
    placement: "right",
  },

  {
    target: "#chargeBacks",
    content: (
      <>
        <h2>Charge backs</h2>
        <p>
          Here, disputes with transactions initiated by payment are reversed/
          settled in the client's account.
        </p>
      </>
    ),
    locale: { Skip: <strong>SKIP</strong> },
    disableBeacon: true,
    placement: "right",
  },
];
