import { ActionTypes } from "store/constants/actionType";

const initialState = {
    questions: {
        loading: false,
        error: null,
        data: [],
    }
};

export const questionReducer = (state:any = initialState.questions, actions:any) => {
    switch (actions.type) {
        case ActionTypes.GET_QUESTIONS_LOADING:
            return {...state, loading: true};
        case ActionTypes.GET_QUESTIONS_SUCCESS:
            return {...state, loading: false, data: actions.payload};
        case ActionTypes.GET_QUESTIONS_FAIL:
            return {...state, Loading: false, error: actions.payload};
        default:
            return state
    };
};