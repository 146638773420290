import styled from "styled-components";
import { color } from "../shared/utils/styles";

import { Button, Form } from "shared/components";
import breakpoints from "shared/utils/breakpoints";
import { font } from "shared/utils/styles";

type tooglePropT = {
  isActive: boolean;
};

export const PersonalFormCont = styled.div`
  margin-top: 2rem;
  width: 65%;
  /* display: flex; */
  @media ${breakpoints.device.max.xs} {
    width: 100%;
    margin-top: 15px;
  }
`;

//Section header

export const SectionTitle = styled.h2`
  margin-bottom: 1rem;
`;

export const SectionHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${color.borderLight};
  min-height: 2rem;
`;

export const HeaderToogleContainer = styled.div`
  width: 30%;
  display: flex;
  height: 3rem;
  justify-content: space-between;
  align-items: center;
`;

export const ToogleItem = styled.div<tooglePropT>`
  background-color: ${(prop) => prop.isActive && color.fountainLight};
  border-left: ${(prop) => prop.isActive && `1px solid ${color.borderLight}`};
  border-right: ${(prop) => prop.isActive && `1px solid ${color.borderLight}`};
  border-top: ${(prop) => prop.isActive && `1px solid ${color.borderLight}`};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: inherit;
  padding: 0.4rem 0;
  border-top-right-radius: 0.4rem;
  border-top-left-radius: 0.4rem;
  cursor: pointer;
`;

//Form Elements
export const FormInputContainer = styled.div`
  position: relative;
  width: 34%;
  display: flex;
  flex-direction: column;
  @media ${breakpoints.device.max.xs} {
    width: 100%;
  }
`;

export const InputLabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.4rem;
`;

export const FormInput = styled.input`
  width: 100%;
  padding: 1rem 0.2rem;
  outline-style: none;
  border: 1px solid ${color.borderLight};
  border-radius: 0.4rem;
`;

export const BusinessFormCont = styled.div`
  margin-top: 2rem;
  width: 100%;
  display: flex;
`;

export const FormGroup = styled.div`
  width: 100%;
  display: flex;
  gap: 1.5rem;
  margin-bottom: 1rem;
  @media ${breakpoints.device.max.xs} {
    flex-direction: column;
  }
`;

export const FormElement = styled(Form.Element)`
  width: 100%;
  max-width: 640px;
`;

export const FormHeading = styled.h1`
  padding: 6px 0 15px;
  ${font.size(24)}
  ${font.medium}
`;

export const ActionButton = styled(Button)`
  margin-top: 30px;
`;

export const Span = styled.span`
  color: ${color.textDark};
  font-size: 14px;
`;

export const SaveButton = styled(Button)`
  padding: 1.6rem;
  color: white;
  width: 100%;
  background: ${color.fountain};
  border-radius: 0.2rem;
`;

export const FileInput = styled.input.attrs({ type: "file" })`
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
`;
