import { useHistory } from "react-router-dom";
import {
  ParentCont,
  ActionButton2,
  ReportBody,
  SubTitle2,
  SubTitle3,
  CloseBtn,
  CloseWrapper,
} from "./Styles";
import Success from "App/assets/FPwelcome.gif";
import toast from "shared/utils/toast";
import api from "shared/utils/api";
import { useEffect, useState } from "react";
import Preloader from "shared/components/preloader";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import consoleLog from "shared/utils/consoleLog";
import { setKycStartAddress } from "store/actions/kycstartAction";
import { setKycBoardingRec } from "store/actions/boardingAction";
import {
  clearLocalStorage,
  removeStoredAuthToken,
} from "shared/utils/authToken";
import { logout } from "store/actions/logout";

import { GradientLoader, Icon, Modal } from "shared/components";
import ExitPage from "./exitPage";

function Welcome() {
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [comp, setComp] = useState(1);
  const currentUser = useSelector((store: RootStateOrAny) => store.currentUser);
  const businesses = useSelector((store: RootStateOrAny) => store.businesses);

  const dispatch = useDispatch();
  const checkValidUser = async () => {
    await dispatch(setKycStartAddress(""));
    await dispatch(setKycBoardingRec());

    if (
      !currentUser ||
      !(currentUser instanceof Object) ||
      Object.keys(currentUser).length === 0
    ) {
      history.push("/authenticate");
      return null;
    }
  };

  const checkForKyc = async () => {
    setLoading(true);
    try {
      const { message, payload }: any = await api.get("/authentication/me");
      consoleLog("Personal KYC: ", message);
      if (
        Object.keys(payload).length > 0 &&
        payload.firstName &&
        payload.lastName  && 
        (payload.bvn || payload.nimc)
      ) {
        history.push("/kyc/business-type");
      } else {
        history.push("/kyc/personal-details");
      }
    } catch (error) {
      consoleLog(error);
      // @ts-ignore
      toast.error(error);
      
    }finally{
      setLoading(false);
    }
  };
  const redirectToChoose = async () => {
    if (businesses instanceof Array && businesses.length > 0) {
      history.push("/kyc/choose-business");
    } else {
      clearLocalStorage();
      await dispatch(logout());

      history.push("/authenticate");
    }
    return;
  };

  const showComponent = () => {
    switch (comp) {
      default:
        return <ExitPage setVisible={setVisible} yesClick={redirectToChoose} />;
    }
  };

  useEffect(() => {
    checkValidUser();
  }, []);

  if (loading) {
    return <GradientLoader />;
  }

  return (
    <>
      <ParentCont>
        <ReportBody>
          <img src={Success} alt="Success" />
          <SubTitle2>Welcome! we are glad to have you.</SubTitle2>
          <SubTitle3>
            Please provide necessary Details to have you onboarded.
          </SubTitle3>
          <ActionButton2 onClick={checkForKyc}>Proceed</ActionButton2>
        </ReportBody>
        <CloseWrapper>
          <CloseBtn onClick={() => setVisible(true)}>
            <Icon type={"close"} size={20} />
          </CloseBtn>
        </CloseWrapper>
      </ParentCont>
      <Modal
        isOpen={visible}
        width={800}
        withCloseIcon={false}
        onClose={() => setVisible(false)}
        overFlow={true}
        containerWidth={false}
        renderContent={showComponent}
        // {...addedProps}
      />
    </>
  );
}

export default Welcome;
