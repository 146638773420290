import {
  ActionButton,
  ButtonContainer,
  FormBody,
  FormContainer,
  FormElement,
  SubAgentForm,
  SubTitle,
  TitleCase,
} from "./styles";
import { useState } from "react";
import { Form, Icon } from "shared/components";
import toast from "shared/utils/toast";
import api from "shared/utils/api";
import { color } from "shared/utils/styles";

type PropsT = {
  agentId?: string;
  initialValues: any;
  step: number;
  setStep: Function;
  setInitialValues: Function;
};
const Nextofkin = ({
  agentId,
  initialValues,
  step,
  setStep,
  setInitialValues,
}: PropsT) => {
  const [initialNextValues, setinitialNextValues] = useState({
    name: initialValues?.agentKYC?.nextOfkin?.name || "",
    gender: initialValues?.agentKYC?.nextOfkin?.gender || "",
    relationship: initialValues?.agentKYC?.nextOfkin?.relationship || "",
    phone: initialValues?.agentKYC?.nextOfkin?.phone || "",
    email: initialValues?.agentKYC?.nextOfkin?.email || "",
    address: initialValues?.agentKYC?.nextOfkin?.address || "",
    landmark: initialValues?.agentKYC?.nextOfkin?.landmark || "",
    town: initialValues?.agentKYC?.nextOfkin?.town || "",
    lga: initialValues?.agentKYC?.nextOfkin?.lga || "",
    state: initialValues?.agentKYC?.nextOfkin?.state || "",
    country: initialValues?.agentKYC?.nextOfkin?.country || "",
  });
  const [isLoginIn, setIsLoginIn] = useState(false);
  const [phoneError, setPhoneError] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<boolean>(false);

  const testMobileNumber = (val: string) => {
    return /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(
      val
    );
  };

  const testEmail = (val: string) => {
    return /.+@.+\..+/.test(val);
  };

  const onKeyChange = (key: string, value: any) => {
    if (key == "phoneNo" && !testMobileNumber(value)) {
      setPhoneError(true);
    } else {
      let oldValues: any = initialValues;
      oldValues[key] = value;
      //   setinitialNextValues(oldValues);

      setInitialValues((prevFormValues: any) => ({
        ...prevFormValues,
        [key]: value,
      }));

      setinitialNextValues((prevFormValues) => ({
        ...prevFormValues,
        [key]: value,
      }));
    }
  };

  const onSubmit = async () => {
    try {
      if (initialNextValues) {
        setIsLoginIn(true);

        let subAgent = {
          next_ofkin: initialNextValues,
          agent_id: agentId,
        };

        // // console.log("Values: ", subAgent);

        const res = await api.post(
          "/agency-banking/create-agents-kyc",
          subAgent
        );
        // consoleLog('success ', res);
        toast.success(res.message);
        setStep(4);
      }
    } catch (err) {
      const error: any = err;
      // // console.log(error);
      const resMessage =
        error.message === "Successfully retrieved business agents"
          ? "Error sub agent already exists"
          : error.message;
      toast.error(`${resMessage}`);
    } finally {
      setIsLoginIn(false);
    }
  };

  return (
    <>
      <TitleCase>Next of kin details</TitleCase>
      <SubTitle>
        With your corporate account number, you are guaranteed of your
        settlement
      </SubTitle>
      <FormBody>
        <Form
          enableReinitialize
          initialValues={initialNextValues}
          validations={{
            name: [Form.is.required()],
            phone: [Form.is.required()],
            address: [Form.is.required()],
            landmark: [Form.is.required()],
            lga: [Form.is.required()],
            state: [Form.is.required()],
            country: [Form.is.required()],
          }}
          onSubmit={onSubmit}
          validate={function({}: {}) {
            // throw new Error("Function not implemented.");
          }}
          validateOnBlur={true}
        >
          <FormElement>
            <SubAgentForm>
              {/* @ts-ignore */}
              <Form.Field.Input
                name="name"
                type="text"
                label="Full name"
                value={initialValues?.name}
                onChange={(e: any) => onKeyChange("name", e)}
                // tip="Kindly enter your BVN"
              />

              <Form.Field.Input
                name="gender"
                type="text"
                label="Gender"
                value={initialValues?.gender}
                onChange={(e: any) => onKeyChange("gender", e)}
                // tip="Kindly enter your BVN"
              />
              <Form.Field.Input
                name="relationship"
                type="email"
                label="Relationship"
                value={initialValues?.relationship}
                onChange={(e: any) => onKeyChange("relationship", e)}
                // tip="Kindly enter your BVN"
              />
              <Form.Field.Input
                name="email"
                type="email"
                label="Email"
                value={initialValues?.email}
                onChange={(e: any) => onKeyChange("email", e)}
                // tip="Kindly enter your BVN"
              />
              <Form.Field.Input
                name="phone"
                type="tel"
                label="Phone number"
                value={initialValues?.phone}
                onChange={(e: any) => {
                  if (isNaN(e)) {
                    return false;
                  } else {
                    onKeyChange("phone", e);
                  }
                }}
                // tip="Kindly enter your BVN"
              />
              <hr style={{ color: "grey", opacity: "0.2" }} />
              <Form.Field.Input
                name="address"
                type="text"
                label="Address"
                value={initialValues?.address}
                onChange={(e: any) => onKeyChange("address", e)}
                // tip="Kindly enter your BVN"
              />
              <Form.Field.Input
                name="landmark"
                type="text"
                label="Landmark"
                value={initialValues?.landmark}
                onChange={(e: any) => onKeyChange("landmark", e)}
                // tip="Kindly enter your BVN"
              />

              <Form.Field.Input
                name="town"
                type="text"
                label="Town"
                value={initialValues?.town}
                onChange={(e: any) => onKeyChange("town", e)}
                // tip="Kindly enter your BVN"
              />
              <Form.Field.Input
                name="country"
                type="text"
                label="Country"
                value={initialValues?.country}
                onChange={(e: any) => onKeyChange("country", e)}
                // tip="Kindly enter your BVN"
              />

              <Form.Field.Input
                name="state"
                type="text"
                label="State"
                value={initialValues?.state}
                onChange={(e: any) => onKeyChange("state", e)}
                // tip="Kindly enter your BVN"
              />

              <Form.Field.Input
                name="lga"
                type="text"
                label="Local govt"
                value={initialValues?.lga}
                onChange={(e: any) => onKeyChange("lga", e)}
                // tip="Kindly enter your BVN"
              />
            </SubAgentForm>
            <ButtonContainer variant={agentId}>
              {agentId != "" && (
                <ActionButton
                  variant={color.transparent}
                  onClick={() => {
                    setStep(step + 1);
                  }}
                >
                  Skip
                </ActionButton>
              )}
              <>
                {step > 1 && (
                  <ActionButton
                    type="button"
                    variant={color.greyColor}
                    onClick={() => {
                      setStep(step - 1);
                    }}
                  >
                    Back
                  </ActionButton>
                )}

                <ActionButton
                  type="submit"
                  isWorking={isLoginIn}
                  onClick={onSubmit}
                >
                  Next
                </ActionButton>
              </>
            </ButtonContainer>
          </FormElement>
        </Form>
      </FormBody>
    </>
  );
};

export default Nextofkin;
