import breakpoints from "shared/utils/breakpoints";
import { color } from "shared/utils/styles";
import styled from "styled-components";

type PropT = {
  bg?: boolean;
};

type ServicePropT = {
  mode: string;
};
export const GetStartedContainer = styled.div`
  background-color: #ffffff;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
`;

export const GetStartedLeftBox = styled.div`
  padding: 25px;
  & h2 {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #292929;
    margin-bottom: 15px;
  }
`;

export const GetStartedLeftButton = styled.button`
  border: 1px solid rgba(215, 215, 215, 0.6);
  padding: 15px 30px;
  color: rgba(130, 130, 130, 0.9);
  margin-top: 20px;
  border-radius: 5px;
  &:hover {
    background-color: #760000;
    color: #ffffff;
    cursor: pointer;
  }
`;

export const GetStartedRightBox = styled.div`
  padding-top: 15px;
  @media ${breakpoints.device.max.xs} {
    display: none;
  }
`;

export const ServicesListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
  margin-top: 25px;
  @media ${breakpoints.device.max.xs} {
    flex-direction: column;
  }
`;

export const ServicesList = styled.div<PropT>`
  position: relative;
  width: 23.5%;
  background: ${(props) => (props.bg ? "rgba(240, 236, 236, 0.4)" : "#ffffff")};
  border: 0.08rem solid #d7d7d7;
  border-radius: 10px;
  padding: 20px 24px 20px 24px;
  margin-right: 15px;
  margin-bottom: 15px;
  height: 190px;
  overflow: hidden;
  /* min-height: 190px; */
  cursor: pointer;
  &:last-child {
    margin-right: 0px;
  }
  &:hover {
    border: 0.08rem solid ${color.fountain};
    transition: all ease 0.8s;
  }
  @media ${breakpoints.device.max.xs} {
    width: 100%;
  }
`;

export const Avatar = styled.div`
  border-radius: 50%;
  border: 1px solid #760000;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const HeadWord = styled.div`
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #292929;
  margin-top: 15px;
  margin-bottom: 10px;
  text-align: left !important;
  /*white-space: nowrap;*/
  overflow: hidden;
  text-overflow: ellipsis;
  /* height: 50px;*/
  /* max-width: 200px; */
  /* text-transform: capitalize; */
`;

export const ServiceListId = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #292929;
  margin-bottom: 10px;
`;

export const ServiceMode = styled.div<ServicePropT>`
  width: ${(prop) =>
    ["LIVE", "TEST"].includes(prop.mode) ? "120px" : "120px"};
  height: ${(prop) =>
    ["LIVE", "TEST"].includes(prop.mode) ? "60px" : "60px"};;
  background: ${(prop) =>
    prop.mode === "LIVE"
      ? "green"
      : prop.mode === "TEST"
      ? "#fff6f6"
      : "#808080"};
  /* border-radius: 8px; */
  font-weight: 400;
  font-size: ${(prop) =>
    ["LIVE", "TEST"].includes(prop.mode) ? "12px" : "10px"};
  color: ${(prop) =>
    prop.mode === "LIVE" ? "#fff" : prop.mode === "TEST" ? "#ff2424" : "#fff"};
  display: flex;
  justify-content: center;
  padding-top: 0.5rem;
  /* align-items: center; */
  margin-top: 25px;
  position: absolute;
  bottom: -12px;
  right: ${(prop) =>
    ["LIVE", "TEST"].includes(prop.mode) ? "-35px" : "-45px"};
  /* border: ${(prop) =>
    prop.mode === "LIVE" ? "1px solid green" : "1px solid red"}; */
  transform: rotate(-45deg);
`;

// export const ServiceLiveMode = styled.div`
//   width: 77px;
//   height: 29px;
//   background: #f6fbf8;
//   border-radius: 8px;
//   font-weight: 400;
//   font-size: 12px;
//   color: #219653;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   margin-top: 25px;
// `;

// export const ServiceTestMode = styled.div`
//   width: 77px;
//   height: 29px;
//   background: #fff6f6;
//   border-radius: 8px;
//   font-weight: 400;
//   font-size: 12px;
//   color: #ff2424;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   margin-top: 25px;
// `;

export const ServiceListDescription = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: rgba(130, 130, 130, 0.9);
  text-transform: capitalize;
`;

export const ConnectBtn = styled.button`
  cursor: pointer;
  background: rgba(118, 0, 0, 0.07);
  border-radius: 8px;
  font-size: 12px;
  padding: 10px;
`;

export const AddServiceBox = styled.button`
  height: 200px;
  width: 25%;
  background: rgba(240, 236, 236, 0.4);
  border-radius: 10px;
  padding: 20px 100px 20px 24px;
  cursor: pointer;
  display: block;
`;

export const AddAvatar = styled.div`
  border-radius: 50%;
  background-color: #760000;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
