import SecureLS from "secure-ls";
import consoleLog from "shared/utils/consoleLog";

let ls = new SecureLS({ encodingType: "aes" });

// save anything to secure ls
export const omniSaver = (title: string, data: any) => ls.set(`${title}`, data);
// get anything from secure ls
export const omniGetter = (title: string) => ls.get(`${title}`);
// remove anything from secure ls
export const omniRemover = (title: string) => ls.remove(`${title}`);

export const getTempId = () => {
  try {
    return ls.get("tempId");
  } catch {
    return;
  }
};
export const storeTempId = (tempId: any) => ls.set("tempId", tempId);

//cachedData
export const getCachedData = () => {
  try {
    return JSON.parse(ls.get("cachedData"));
  } catch (err) {
    // consoleLog(err);
    return [];
  }
};
export const storeCachedData = (cachedData: any) => {
  try {
    ls.set("cachedData", JSON.stringify(cachedData));
  } catch {}
};

export const removeTempId = () => ls.remove("tempId");
export const storeLogs = (data: any) => {
  const storeData = ls.get("logs" || "");
  var reservedData: any[] = storeData ? JSON.parse(storeData) : [];
  reservedData.push(data);
  ls.set("logs", JSON.stringify(reservedData));
};

export const getStoredLogs = () => {
  const storeData = ls.get("logs" || "");
  return storeData ? JSON.parse(storeData) : [];
};

// save user email and password to LS
// export const holdEmail = (email:any) => ls.set('user_email', email);
export const holdPass = (password: any) => ls.set("user_pass", password);

export const pullPass = () => ls.get("user_pass");

// delete user email and password to LS
// export const dumpEmail = () => ls.remove('user_email');
export const dumpPass = () => ls.remove("user_pass");

export const getStoredAuthToken = () => ls.get("token");
// export const getStoredAuthToken = () =>
//   "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjY0OTYzMzUyLCJpYXQiOjE2NjQ4NzY5NTIsImp0aSI6IjkwZTcwYTM4YjIyNDQzMDA5YWNhMTRhYTU2NmM1NTk3IiwidXNlcl9pZCI6IjQ5NzlhMzM3LTA3NzgtNGU2Mi1iNjJlLTk2MDJmOTUwNmVhMiIsImtleSI6IjU4MDM2NTUyMjMzMDE2NjQ4NzY5NTIzNzgifQ.j0F3FwO5DQE51Xu-AfmQJ3R9wCmB0dWE-GCt90-kamo";

export const storeAuthToken = (token: string) => ls.set("token", token);

export const removeStoredAuthToken = () => ls.remove("token");

export const saveLastApiCallTime = () => {
  if(!window.location.href.includes("kyc")){
    const data = {
      path: window.location.href,
      when: Date.now(),
    };
    ls.set("lastCallTime", data); 
  }
};
export const getLastApiCallTime = () => ls.get("lastCallTime");
export const removeLastApiCallTime = () => ls.remove("lastCallTime");

const compareJwtToken = (token: string) => {
  if (typeof token !== "string" || !token)
    throw new Error("Invalid token provided");

  let isJwtExpired = false;
  const data: any = []; //jwt_decode(token);
  const currentTime = new Date().getTime() / 1000;

  if (currentTime > data.exp) isJwtExpired = true;

  return isJwtExpired;
};

export const storeToLocalStorage = (key: string, value: any) => {
  ls.set(key, value);
};

export const getStoreLocalStorage = (key: string) => {
  return ls.get(key);
};

export const clearLocalStorage = () => ls.clear();
