import {
    ActionButton,
    StatisticsAmountBlue,
    StatisticsAmountBrown,
    StatisticsAmountGreen,
    StatisticsAmountRed,
    StatisticsBox,
    StatisticsBoxFour,
    StatisticsBoxTwo,
    StatisticsBoxTwoTwo,
    StatisticsCircleBlue,
    StatisticsCircleBrown,
    StatisticsCircleGreen,
    StatisticsCircleRed,
    StatisticsDate,
    StatisticsHeading,
    StatisticsRow,
  } from "Agency/Styled";
  import Layout from "Layout";
  import EmptyState from "shared/components/FreeComp/EmptyState";
  import Table from "shared/components/Table";
  import BrownIcon from "../../../App/assets/images/icons/agency-brown-statistics.svg";
  import BlueIcon from "../../../App/assets/images/icons/agency-blue-statistics.svg";
  import GreenIcon from "../../../App/assets/images/icons/agency-green-statistics.svg";
  import RedIcon from "../../../App/assets/images/icons/agency-red-statistics.svg";
  import TotalIcon from "../../../App/assets/images/icons/agency-total-transaction.svg";
  import {
    TotalAmount,
    TotalContainer,
    TotalContainerLeft,
    TotalContainerRIght,
    TotalContainerRIghtCircle,
    TotalHeading,
  } from "Agency/Transactions/Styled";
  import { currencyFormat, numberFormat_extra } from "shared/utils/utility";
  import { useEffect, useState } from "react";
  import { RootStateOrAny, useSelector } from "react-redux";
  import api from "shared/utils/api";
  import { Spinner } from "shared/components";
import { OverviewPage } from "Agency/Overview/Styles";
  
  const todaysDate = new Date().toLocaleDateString("en-us", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });

const Overview=({agentDetail}: any)=>{
    const [isLoading, setIsLoading] = useState<boolean>(false);
  const [transactions, setTransactions] = useState<any>({});
  const [agents, setAgents] = useState<number>(0);
  const [mappedTerminal, setMappedTerminal] = useState<number>(0);
  const [daily, setDaily] = useState<string>("0.00");
  const [weekly, setWeekly] = useState<string>("0.00");
  const [monthly, setMonthly] = useState<string>("0.00");
  const [yearly, setAYearly] = useState<string>("0.00");
  const [summary, setsummary] = useState<any>({});

  const business = useSelector((store: RootStateOrAny) => store.business);

  const getTransactionSummary = async () => {
    setIsLoading(true);
    try {
      const payload={
        start_date:Date(),
        agent_id: agentDetail?.id,
        business_id: business?.id
      }
      const res = await api.post(`/agent-transactions/summary`, payload);
      setTransactions(res.payload)
      
    } catch (error) {
      // console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getTransactionSummary();
  }, []);
  return (
    <>
      <OverviewPage>
        
        <StatisticsRow>
          <StatisticsBoxTwo>
            <StatisticsCircleBlue>
              <img alt="current balance" src={BlueIcon} />
            </StatisticsCircleBlue>
            <StatisticsHeading>Wallet balance</StatisticsHeading>
            <StatisticsDate>as at {todaysDate}</StatisticsDate>
            <StatisticsAmountBlue>
            {isLoading ? <Spinner /> : currencyFormat(0)}
            </StatisticsAmountBlue>
          </StatisticsBoxTwo>
          <StatisticsBoxTwoTwo>
            <StatisticsCircleGreen>
              <img alt="current balance" src={GreenIcon} />
            </StatisticsCircleGreen>
            <StatisticsHeading>Total transactions</StatisticsHeading>
            <StatisticsDate>as at {todaysDate}</StatisticsDate>
            <StatisticsAmountGreen>
            {isLoading ? <Spinner /> : currencyFormat(0)}
            </StatisticsAmountGreen>
          </StatisticsBoxTwoTwo>
          
        </StatisticsRow>

        <StatisticsRow>
          <StatisticsBox>
            <StatisticsCircleBlue>
              <img alt="current balance" src={BlueIcon} />
            </StatisticsCircleBlue>
            <StatisticsHeading>Total terminals</StatisticsHeading>
            <StatisticsDate>as at {todaysDate}</StatisticsDate>
            <StatisticsAmountBlue>
              {isLoading ? <Spinner /> : mappedTerminal}
            </StatisticsAmountBlue>
          </StatisticsBox>
          <StatisticsBox>
            <StatisticsCircleGreen>
              <img alt="current balance" src={GreenIcon} />
            </StatisticsCircleGreen>
            <StatisticsHeading>Total active terminals</StatisticsHeading>
            <StatisticsDate>as at {todaysDate}</StatisticsDate>
            <StatisticsAmountGreen>
              {isLoading ? <Spinner /> : summary?.totalActiveTerminals}
            </StatisticsAmountGreen>
          </StatisticsBox>
          <StatisticsBox>
            <StatisticsCircleRed>
              <img alt="current balance" src={RedIcon} />
            </StatisticsCircleRed>
            <StatisticsHeading>Total redundant terminals</StatisticsHeading>
            <StatisticsDate>as at {todaysDate}</StatisticsDate>
            <StatisticsAmountRed>
              {isLoading ? <Spinner /> : mappedTerminal- summary?.totalActiveTerminals}
            </StatisticsAmountRed>
          </StatisticsBox>
        </StatisticsRow>

        <StatisticsRow>
          <StatisticsBoxFour>
            <StatisticsCircleBlue>
              <img alt="current balance" src={BlueIcon} />
            </StatisticsCircleBlue>
            <StatisticsHeading>Daily Transaction</StatisticsHeading>
            <StatisticsAmountBlue>
              {isLoading ? <Spinner /> : numberFormat_extra(+daily)}
            </StatisticsAmountBlue>
          </StatisticsBoxFour>
          <StatisticsBoxFour>
            <StatisticsCircleGreen>
              <img alt="current balance" src={GreenIcon} />
            </StatisticsCircleGreen>
            <StatisticsHeading>Weekly Transaction</StatisticsHeading>
            <StatisticsAmountGreen>
              {isLoading ? <Spinner /> : numberFormat_extra(+weekly)}
            </StatisticsAmountGreen>
          </StatisticsBoxFour>
          <StatisticsBoxFour>
            <StatisticsCircleRed>
              <img alt="current balance" src={RedIcon} />
            </StatisticsCircleRed>
            <StatisticsHeading>Monthly Transaction</StatisticsHeading>
            <StatisticsAmountRed>
              {isLoading ? <Spinner /> : numberFormat_extra(+monthly)}
            </StatisticsAmountRed>
          </StatisticsBoxFour>
          <StatisticsBoxFour>
            <StatisticsCircleBrown>
              <img alt="current balance" src={BrownIcon} />
            </StatisticsCircleBrown>
            <StatisticsHeading>Yearly Transaction</StatisticsHeading>
            <StatisticsAmountBrown>
              {isLoading ? <Spinner /> : numberFormat_extra(+yearly)}
            </StatisticsAmountBrown>
          </StatisticsBoxFour>
        </StatisticsRow>

        <StatisticsRow>
          <StatisticsBox>
            <StatisticsCircleBlue>
              <img alt="current balance" src={BlueIcon} />
            </StatisticsCircleBlue>
            <StatisticsHeading>Pending Transactions</StatisticsHeading>
            <StatisticsDate>as at {todaysDate}</StatisticsDate>
            <StatisticsAmountBlue>
              {isLoading ? (
                <Spinner />
              ) : (
                numberFormat_extra(transactions?.pending?.value ?? 0.0)
              )}
            </StatisticsAmountBlue>
          </StatisticsBox>
          <StatisticsBox>
            <StatisticsCircleGreen>
              <img alt="current balance" src={GreenIcon} />
            </StatisticsCircleGreen>
            <StatisticsHeading>Successful Transactions</StatisticsHeading>
            <StatisticsDate>as at {todaysDate}</StatisticsDate>
            <StatisticsAmountGreen>
              {isLoading ? (
                <Spinner />
              ) : (
                numberFormat_extra(transactions?.success?.value ?? 0.0)
              )}
            </StatisticsAmountGreen>
          </StatisticsBox>
          <StatisticsBox>
            <StatisticsCircleRed>
              <img alt="current balance" src={RedIcon} />
            </StatisticsCircleRed>
            <StatisticsHeading>Failed Transactions</StatisticsHeading>
            <StatisticsDate>as at {todaysDate}</StatisticsDate>
            <StatisticsAmountRed>
              {isLoading ? (
                <Spinner />
              ) : (
                numberFormat_extra(transactions?.failed?.value ?? 0.0)
              )}
            </StatisticsAmountRed>
          </StatisticsBox>
        </StatisticsRow>
      </OverviewPage>
    </>
  );
};

export default Overview;