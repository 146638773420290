import api from "shared/utils/api";
import { ActionTypes } from "../constants/actionType";
import consoleLog from "shared/utils/consoleLog";

export const setUsers = () => {
  return async (dispatch: any) => {
    const userList = await api.get("/teams");
    await dispatch({
      type: ActionTypes.SET_USERS,
      payload: userList.payload ?? [],
    });
  };
};

// export const setCurrentUser = () => {
//   return async (dispatch: any) => {
//     try {
//       const res = await api.get("/authentication/me");
//       consoleLog("USER:", res.payload);
//       await dispatch({
//         type: ActionTypes.SET_CURRENT_USER,
//         payload: res.payload ?? {},
//       });
//     } catch (error) {
//       consoleLog(error);
//     }
//   };
// };

// export const setCurrentUser = async () => {
//   try {
//     const res = await api.get("/authentication/me");
//     consoleLog(res, "res");
//     return res?.payload;
//   } catch (error) {
//     consoleLog(error);
//   }
// };
export const setCurrentUser = () => {
  return async (dispatch: any) => {
    try {
      const res = await api.get("/authentication/me");
      consoleLog(res, "res___res?.payload ?? {}");
      return await dispatch({
        type: ActionTypes.SET_CURRENT_USER,
        payload: res?.payload ?? {},
      });
    } catch (error) {
      consoleLog(error);
    }
  };
};


export const setUserOtp = (email: string) => {
  return async (dispatch: any) => {
    try {
      const res = await api.post("/authentication/send-auth-otp", {email: email});
      
      return await dispatch({
        type: ActionTypes.SET_USER_OTP,
        payload: res?.payload ?? {},
      });
    } catch (error) {
      consoleLog(error);
    }
  };
};