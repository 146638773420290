import styled from "styled-components";

import { sizes } from "shared/utils/styles";

const paddingLeft = sizes.appNavBarLeftWidth + sizes.secondarySideBarWidth + 40;

export const SettingsPage = styled.div`
  /*padding: 25px 0.4rem 50px 0.4rem;
  padding: 25px 32px 50px ${paddingLeft}px; 
  margin-left: -38px;
  margin-top: -54px;
  @media (max-width: 1100px) {
    padding: -25px 20px 50px ${paddingLeft - 20}px;
  }
  @media (max-width: 999px) {
    padding-left: ${paddingLeft - 20 - sizes.secondarySideBarWidth}px;
  }*/
`;
