import styled from "styled-components";
import { color, font } from "shared/utils/styles";
import { Button, Form } from "shared/components";
import { Link } from "react-router-dom";

export const ComponentBody = styled.div`
  width: 100%;
  margin: 0 auto;
`;

export const ActionButton = styled(Button)`
  margin: 2em 0;
  color: #fff;
  width: 100%;
  background-color: ${color.fountain};
`;

export const FormElement = styled(Form.Element)``;

export const StyledLink = styled(Link)`
  color: ${color.fountain} !important;
  ${font.size(14)}
`;

export const StyledLinkAnchor = styled.a`
  color: ${color.fountain} !important;
  ${font.size(14)}
`;

export const ComponentFooter = styled.p`
  color: #292929;
  text-align: center;
  ${font.size(14)}
`;

export const Title = styled.h1`
  color: ${color.fountain};
  ${font.size(24)}
`;
