import styled from "styled-components";
import { color } from "shared/utils/styles";
import { ReactNode } from "react";
import breakpoints from "shared/utils/breakpoints";

type Props = {
  backgroundImage?: string;
  left?: string;
  top?: string;
  color?: string;
  width?: string;
};

export const Container = styled.div`
  width: 100%
  min-height: 100vh;
  background-color: #fff
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  display: flex;
`;

export const LeftSection = styled.div`
  width: 50%;
  min-height: 100vh;
  // border-right: 1px solid #ccc;
  @media ${breakpoints.device.max.lg} {
    display: none;
  }
`;
export const RightSection = styled.div`
  width: 50%;
  min-width: 50%;
  height: 100vh;
  overflow-y: auto;
  padding: 2.5em;
  padding-right: 5.5em;
  padding-left: 5.5em;
  @media ${breakpoints.device.max.lg} {
    min-width: 100%;
    padding: 10%;
  }
`;

export const LogoImage = styled.img`
  height: 36px;
  margin-bottom: 60px;
`;

export const BkgroundImageDiv = styled.div<Props>`
  background-image: url(${(props) => props?.backgroundImage});

  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  display: flex;
  justify-content: center;
  align-items: center;

  overflow: hidden;
`;

export const SaveButton = styled.button`
  padding: 1rem 4rem;
  border-radius: 0.4rem;
  color: #fff;
  background: ${color.fountain};
  cursor: pointer;
`;

export const OvalDiv = styled.div<Props>`
  width: 90%;
  height: 90%;

  background: ${(props) => props.color};
  border-radius: 42px;
  overflow: hidden;
`;

export const UpperCircle = styled.div<Props>`
  box-sizing: border-box;

  position: relative;
  width: 296px;
  height: 296px;
  left: ${(props) => props.left};
  top: ${(props) => props.top};

  border: 10px solid ${(props) => props.color};
  border-radius: 50%;
`;

export const TextDiv = styled.div<Props>`
  width: ${(props) => props.width};
  margin-left: 10%;
  text-align: left;
  position: relative;
  top: -85%;
`;

export const TopTitle = styled.div`
  font-family: "KumbhSansNormal";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */

  color: #ffffff;
  margin-bottom: 15%;
`;

export const TBody = styled.div`
  font-weight: 600;
  font-size: 4em;
  line-height: 1.3em;

  color: #ffffff;
`;

export const ButtonDiv = styled.button<Props>`
  background-color: #fff;
  border-radius: 5px;
  padding: 10px 0px 10px 10px;
  color: ${(props) => props.color};
  position: absolute;
  bottom: 20%;
  width: 25%;
  left: 11%;
  cursor: pointer;
`;

export const Image = styled.img`
  // height: 90%;
  width: 100%;
  cursor: pointer;
  pointer-events: auto;
`;
