import styled, { css } from "styled-components";

import { Icon, DropdownButton } from "shared/components";
import breakpoints from "shared/utils/breakpoints";
import { color, font, mixin, sizes, zIndexValues } from "shared/utils/styles";

type PropT = {
  color?: string;
  kycStatus?: boolean;
};

export const NavLeftMenu = styled.div`
  display: none;
  @media ${breakpoints.device.max.md} {
    display: block;
  }
`;

export const NavTop = styled.aside<PropT>`
  z-index: ${zIndexValues.navtop};
  position: fixed;
  top: 0;
  left: ${sizes.appNavBarLeftWidth + sizes.secondarySideBarWidth}px;
  width: 80%;
  height: auto;
  background: ${color.backgroundLightWhite};
  border-bottom: 1px solid ${color.borderLightest};
  transition: all 0.1s;
  ${mixin.hardwareAccelerate}
  box-shadow: 0 0 0px 0 rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  padding-left: 30px;
  padding-right: 30px;

  @media ${breakpoints.device.max.md} {
    left: 0;
    width: 100%;
    padding-right: 0px;
  }
  ${(props) =>
    props.kycStatus &&
    css`
      background-color: pink !important;
    `}
`;
export const KycHeader = styled.div`
  width: 100%;
  height: ${sizes.appNavBarLeftWidth}px;
  display: flex;
  flex-direction: row;
  background-color: pink;
  margin-top: 0px;
  margin-right: 0px;
  padding-top: 10px;
  padding-left: 7px;
  justify-content: space-between;
  align-items: center;
`;

export const AlertBtn = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 10px;
  width: 150px;
  height: 50px;
  background: #760000;
  border-radius: 5px;
  color: white;
  margin-top: -15px !important;
  margin-right: 42px;
  &:hover {
    cursor: pointer;
  }
`;

export const MainHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: ${sizes.appNavBarLeftWidth}px;
  justify-content: space-between;
  align-items: center;
  padding-top: 7px;
  padding-right: 11px;
  @media ${breakpoints.device.max.xs} {
    left: 0;
    width: 100%;
  }
`;

export const SubContainer = styled.div`
  height: 50px;
  //width: 300px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-right: 0px !important;
`;

export const BellIcon = styled(Icon)`
  font-size: 22px;
  cursor: pointer;
  color: red;
`;

export const PageTitle = styled.p`
  font-size: 18px;
  font-weight: 600;
  line-height: 21px;
  color: #292929;
  padding-left: 10px;
`;

export const Title = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  font-size: 18px;
  font-weight: 600;
  line-height: 21px;
  color: #292929;
  padding-left: 10px;
  border: 0px solid red;

  & > i {
    margin-bottom: 10px;
    cursor: pointer;
  }

  & > i:hover {
    color: #760000;
  }
`;

export const AuxLinks = styled.p`
  font-size: 14.7px;
  font-weight: 400;
  line-height: 23px;
  color: #292929;
`;

export const NotificationContainer = styled.button<PropT>`
  border: 1px solid ${(props) => props.color};
  border-radius: 20px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const NotificationBall = styled.div`
  border: 2px solid white;
  border-radius: 5px;
  width: 10px;
  height: 10px;
  background-color: grey;
  position: absolute;
  z-index: 9999;
  right: 6px;
`;

export const Item = styled.div`
  position: relative;
  width: 42px;
  height: 100%;
  padding-left: 64px;
  padding-top: 15px;
  color: #deebff;
  transition: color 0.1s;
  ${mixin.clickable}
  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }
  i {
    position: absolute;
    left: 18px;
  }
`;

export const ItemText = styled.div`
  position: relative;
  right: 12px;
  visibility: hidden;
  opacity: 0;
  text-transform: uppercase;
  transition: all 0.1s;
  transition-property: right, visibility, opacity;
  ${font.bold}
  ${font.size(12)}
  ${NavTop}:hover & {
    right: 0;
    visibility: visible;
    opacity: 1;
  }
`;

export const Bottom = styled.div`
  position: absolute;
  right: 20px;
  top: 0;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const TitleSpan = styled.span`
  @media ${breakpoints.device.max.xs} {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 4rem;
    display: inline-block;
  }
`;

export const ServiceBtn = styled.button`
  //background-color: #760000;
  border: none;
  outline: none;
  height: 50px;
  color: #760000;
  font-size: 14px;
  font-weight: 500;
  line-height: 42px;
  display: flex;
  // width: 241px;
  //padding:0 3em;
  padding-top: 5px;
  margin-right: 20px;
  cursor: pointer;
  border-radius: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media ${breakpoints.device.max.xs} {
    span, DropdownButton {
      display: none;
    }

  }
`;

export const IconText = styled.div`
  margin-top: -2px;
`;

export const ServiceFilterButton = styled.button`
  display: flex;
  width: 116px;
  height: 54px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 5px;
  border: 1px solid var(--grey-border-1, rgba(215, 215, 215, 0.6));
  color: var(--primary-black, #292929);
  text-align: center;
  margin-bottom: 5px;
  margin-right: 10px;
  cursor: pointer;

  /* Body Text/16px/regular
	font-family: KumbhSansNormal; */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
