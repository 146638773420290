import { decrypt, encrypt, getKey } from "fp-cypher-lib-ts/lib";
import { getStoreLocalStorage, getStoredAuthToken } from "./authToken";
//import IPinfoWrapper, { IPinfo, AsnResponse } from "node-ipinfo";
// require("./location.js");
import consoleLog from "shared/utils/consoleLog";

export const retrieveToken = (checkFlag: boolean = false) => {
  if (checkFlag) return undefined;
  return getStoredAuthToken() ? `Bearer ${getStoredAuthToken()}` : undefined;
};

export const retrieveApiToken = (checkFlag: boolean = false) => {
  if (checkFlag) return undefined;
  return getStoreLocalStorage('apiToken') ? `Bearer ${getStoreLocalStorage('apiToken')}` : undefined;
};


export const encryptData = (payload: any, checkFlag: boolean) => {
  
  payload = JSON.stringify(payload);
  //// console.log(payload)
  const token =checkFlag? undefined : getStoredAuthToken();
  let key=getKey(getTokenKey(token));
  //// console.log(key)
  let convertedData = encrypt(
    btoa(payload),
    key,
    256
  );
  // consoleLog('convertedData',convertedData)
  return { data: convertedData };
};

export const decryptData = (payload: any = {}, checkFlag: boolean) => {
  try {
	  if(!payload.data) return payload;
	
    let { data } = payload;
    //const aesCtr = new AesCtr();
    const token =checkFlag? undefined : getStoredAuthToken();
    let key=getKey(getTokenKey(token));
    let convertedData = decrypt(
      data,
      key,
      256
    );
    
    convertedData = JSON.parse(atob(convertedData));
    convertedData = convKeyToCamel(convertedData);
    return convertedData;
  } catch (err) {
    consoleLog("Decription Error: ", err);
  }
};


const getTokenKey = (token: string) => {
  return token ? parseJwt(token) : "";
};

const parseJwt = (token: string) => {
  const base64Url = token?.split(".")[1];

  const base64 = base64Url?.replace("-", "+")?.replace("_", "/");

  const key = base64?.replace(/^.*?(.{32})$/, "$1");

  return key;
};

const getIPLocation = () => {
  let ip = `curl -s https://api.ipify.org`;
  consoleLog(`My public IP address is: ${ip}`);
  //const ipinfoWrapper = new IPinfoWrapper("7b9e370de09c09");
  //consoleLog(ipinfoWrapper)
};

const toCamel = (s: string) => {
  return s.replace(/([-_][a-z])/gi, ($1) => {
    return $1
      .toUpperCase()
      .replace("-", "")
      .replace("_", "");
  });
};

const convKeyToCamel = (obj: any) => {
  if (obj instanceof Array) {
    for (let i = 0; i < obj.length; i++) {
      if (obj[i] instanceof Object) {
        // alert("yes");
        obj[i] = convKeyToCamel(obj[i]);
      }
    }
  } else if (obj instanceof Object) {
    for (let el in obj) {
      if (typeof el === "string" && el.includes("_")) {
        obj[toCamel(el)] = obj[el];
      }

      if (obj[el] instanceof Object) obj[el] = convKeyToCamel(obj[el]);
    }
  }
  return obj;
};

export const isItHere = (arr: any[], str?: string,arrStrict: any =[]) => {
  
  if (!str) {    
    return false;
  }
  if(arrStrict.length > 0){
    for(let elx of arrStrict){
      let truthValue = true;
      let elArr = elx.split('***');
      for(let elm of elArr){
        truthValue &&= str.indexOf(elm) > -1;
      }
      if(truthValue === true) return false
    }
  }
  
  for (let el of arr) {
    let truthValue = true;
    let elArr = el.split('***');
    for(let elm of elArr){
      truthValue &&= str.indexOf(elm) > -1;      
    }
   
    if(truthValue === true) return true
    // if (str.indexOf(el) > -1) return true;
  }
  return false;
};
export const getClientDetails = () => {
  // // console.log(navigator);

  navigator.geolocation.getCurrentPosition(
    (details: any) => {
      // // console.log(details);
    },
    (error) => {
      // // console.log("User deny", error);
    }
  );
};
