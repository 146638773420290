import styled from "styled-components";
import Background from "../App/assets/images/Auth/bg.png";

import { Button, Form } from "shared/components";
import { font } from "shared/utils/styles";

type Props = {
  withBottomMargin: string;
};

export const PageBody = styled.div`
  width: 100%;
  min-height: 100vh;
  // display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  // overflow-y: auto;
  // background: red;
  background-image: url(${Background});
  background-repeat: no-repeat;
  background-size: 100% 100%;
`;

export const ModalBody = styled.div`
  background-image: url("../../App/assets/images/Auth/bg.png");
  color: white;
  background-repeat: no-repeat;
  background-size: cover;

  width: 100%;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LeftPane = styled.div`
  min-width: 65%;
  max-width: 70%;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  vertical-align: middle;
  padding-top: 30px;
  background: #fbfbfb;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
`;

export const RightPane = styled.div`
  width: 35%;
  min-height: 100%;
  margin: 2em 1em;
  // margin-left: auto;
  order: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #fff;
`;

export const imageStyle = {
  width: "70%",
  margin: "auto",
};

export const ImageContainer = styled.div`
  background: #fbfbfb;
  border-radius: 20px;
  padding: 15px 5px;
  width: 75%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ImageContainer2 = styled.div`
  background: #fbfbfb;
  border-radius: 20px;
  padding: 15px 5px;
  width: 75%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: end;
`;

export const RightTopPane = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
`;
export const RightBottomPane = styled.p`
  color: #828282;
  text-align: center;
  ${font.size(14)}
`;

export const FormBody = styled.div`
  display: flex;
  // padding: 25px 40px 35px;
  justify-content: space-between;
  min-height: 70vh;
  align-items: center;
`;

export const ImageAvatar = styled.div`
  width: 100%;
  height: 50px;
  margin-bottom: 190px;
`;

export const FormTitle = styled.div`
  text-transform: uppercase;
  padding-top: 20px;
  padding-bottom: 15px;
  color: #fff;
  ${font.size(31)}
`;
export const FormElement = styled(Form.Element)`
  // padding: 25px 40px 35px;
`;

export const FormHeading = styled.div`
  padding-bottom: 15px;
  ${font.size(21)}
`;

export const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 30px;
`;

export const ActionButton = styled(Button)`
  margin-left: 10px;
`;
