import styled, { css } from "styled-components";

export const Loader = styled.div`
  display: block;
  width: 100%;
  margin-top: 40px;
`;

export const Loader2 = styled.div`
  display: block;
  width: 100%;
  min-width: 400px;
  margin-top: 40px;
`;
