import React, { useState } from "react";
import Success from "App/assets/images/Auth/success.svg";
import { ActionButton, ReportBody, SubTitle, Title } from "./Styles";
import { storeAuthToken } from "shared/utils/authToken";
import api from "shared/utils/api";
import { useHistory } from "react-router-dom";
import toast from "shared/utils/toast";
import { authPath } from "apiServices/paths";
import LoginFace2 from "shared/components/LoginFace/index2";
import LoginFace from "shared/components/LoginFace";

const ResetPasswordSuccess = () => {
  const [isLogin, setIsLogin] = useState(false);
  const history = useHistory();
  const [isLoginIn, setIsLoginIn] = useState(false);

  const handleLogin = () => {
    history.push(authPath.LOGIN);
    localStorage.removeItem("tempId");
  };

  return (
    <LoginFace2>
      <ReportBody>
        <img src={Success} alt="Success" />
        <Title>Nice one.</Title>
        <SubTitle>Password reset was Successful.</SubTitle>
        <ActionButton onClick={handleLogin} isWorking={isLoginIn}>
          Launch Now
        </ActionButton>
      </ReportBody>
    </LoginFace2>
  );
};

export default ResetPasswordSuccess;
