import React from "react";
import PropTypes from "prop-types";
import {
  Formik,
  Form as FormikForm,
  Field as FormikField,
  FormikValues,
} from "formik";
import { get, mapValues } from "lodash";

import toast from "shared/utils/toast";
import { is, generateErrors } from "shared/utils/validation";

import Field from "./Field";
import consoleLog from "shared/utils/consoleLog";

type PropT = {
  validate: ({}) => any;
  validations: {};
  validateOnBlur: boolean;
  [x: string]: any;
};

const Form = ({ validate, validations, ...otherProps }: PropT) => (
  // @ts-ignore
  <Formik
    {...otherProps}
    validate={(values) => {
      if (validate) {
        return validate(values);
      }
      if (validations) {
        return generateErrors(values, validations);
      }
      return {};
    }}
  />
);

Form.Element = (props: FormikValues) => <FormikForm noValidate {...props} />;

Form.Field = mapValues(
  Field,
  (FieldComponent) => ({ name, validate, ...props }: FormikValues) => (
    <FormikField name={name} validate={validate}>
      {({ field, form: { touched, errors, setFieldValue } }: FormikValues) => (
        <FieldComponent
          {...field}
          {...props}
          name={name}
          error={get(touched, name) && get(errors, name)}
          onChange={(value: any) => {
            // consoleLog("Select: ", name,value);
            setFieldValue(name, value);
            {
              props.onChange && props?.onChange(value);
            }
          }}
        />
      )}
    </FormikField>
  )
);

Form.initialValues = (data: any, getFieldValues: any) =>
  getFieldValues((key: any, defaultValue = "") => {
    const value = get(data, key);
    return value === undefined || value === null ? defaultValue : value;
  });

Form.handleAPIError = (error: any, form: any) => {
  if (error.data.fields) {
    form.setErrors(error.data.fields);
  } else {
    toast.error(error);
  }
};

Form.is = is;

export default Form;
