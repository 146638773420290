import api from "shared/utils/api";
import consoleLog from "shared/utils/consoleLog";

class bankAccount {
  async addBankAccount(data: any, businessId: any) {
    try {
      consoleLog("Bank Accounts Payload", {
        status: data.status,
        bank_id: data.bankId,
        // bankName: data.bankName,
        accountNumber: data.accountNumber,
        bankAccountName: data.bankAccountName,
      });
      const response = await api.post(
        `/client/business/${businessId}/bank-accounts`,
        {
          status: data.status,
          // bankName: data.bankName,
          bank_id: data.bankId,
          accountNumber: data.accountNumber,
          bankAccountName: data.bankAccountName,
        }
      );
      return response;
    } catch (error) {
      consoleLog(error, "Error adding account");
      consoleLog("Error: Unable to add abnk account", error);
    }
  }

  async editBankAccount(data: any, businessId: any, accountId: any) {
    try {
      const response = await api.patch(
        `/client/business/${businessId}/bank-accounts/${accountId}`,
        {
          status: data.status,
          bankName: data.bankName,
          accountNumber: data.accountNumber,
          bankAccountName: data.bankAccountName,
        }
      );
      return response;
    } catch (error) {
      consoleLog("Error: Unable to add edit abnk account", error);
    }
  }

  async getBankAccounts(businessId: any) {
    try {
      const response = await api.get(
        `/client/business/${businessId}/bank-accounts`
      );
      if (response.payload.length) {
        return response.payload;
      } else {
        return [];
      }
    } catch (error) {
      consoleLog("error: Unable to retrieve bank accounts", error);
    }
  }
}

const bankAccountClass = new bankAccount();
export default bankAccountClass;
