import { ActionTypes } from '../constants/actionType';

const initialState = {
  settings: null
};

export const settingsReducer = (state: any = initialState.settings, action: any) => {
  switch (action.type) {
    case ActionTypes.SET_SETTINGS_LISTS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
