import React, { useEffect } from "react";
import { login } from "apiServices/authService";
import { overviewPath } from "apiServices/paths";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Form, Icon } from "shared/components";
import {
  ActionButton,
  ComponentBody,
  ComponentFooter,
  FormElement,
  StyledLink,
  Title,
  PasswordCont,
  ComponentFooter2,
} from "./Styles";
import api from "shared/utils/api";
import logger from "shared/utils/logger";
import { getClientDetails } from "shared/utils/location";
import { setCurrentUser } from "store/actions/userAction";
import {
  setBusinessLists,
  setCurrentBusiness,
} from "store/actions/businessAction";
import consoleLog from "shared/utils/consoleLog";
import {
  getLastApiCallTime,
  getTempId,
  removeLastApiCallTime,
} from "shared/utils/authToken";
import { useFormikContext } from "formik";

const Login = () => {
  const [isLoginIn, setIsLoginIn] = useState(false);

  const [passwordVisible, setPasswordVisible] = useState(true);
  const history = useHistory();
  const dispatch = useDispatch();

  const onSubmit = async (values: any, form: any) => {
    setIsLoginIn(true);

    const data: any = await login(values);

    if (data) {
      consoleLog(data, "data");

      await logger.logActivities("remote", {
        date: new Date().toString(),
        author: values.email,
        resource: "client",
        action: "LOGIN",
        payload: {
          userAgent: getClientDetails(),
          data: {
            detail: `${values.email} logs in to the client console`,
          },
        },
      });

      ///Add buisinesses and current business
      await dispatch(setCurrentUser());

      await dispatch(setBusinessLists());

      const lastCallDetails = getLastApiCallTime();
      if (lastCallDetails && lastCallDetails.path) {
        window.location.href = lastCallDetails.path;
        removeLastApiCallTime();
      } else {
        history.push(overviewPath.OVERVIEW);
      }
    } else {
      setIsLoginIn(false);
      await logger.logActivities("remote", {
        date: new Date().toString(),
        author: values.email,
        resource: "client",
        action: "LOGIN",
        payload: {
          userAgent: getClientDetails(),
          data: {
            detail: `${values.email} login attempt failed.`,
          },
        },
      });
    }
  };

  return (
    <ComponentBody>
      <Title>LOGIN</Title>
      {/* @ts-ignore */}
      <Form
        enableReinitialize
        initialValues={{
          email: "",
          password: "",
        }}
        validations={{
          email: [Form.is.required(), Form.is.email()],
          password: [Form.is.required(), Form.is.maxLength(200)],
        }}
        onSubmit={onSubmit}
      >
        {({ isValid }: any) => (
          <FormElement>
            <Form.Field.Input
              name="email"
              type="email"
              label="Enter Email address"
              style={{ marginTop: "10px" }}
            />

            <PasswordCont style={{ marginTop: "30px" }}>
              <Form.Field.Input
                name="password"
                type={passwordVisible ? "password" : "text"}
                label="Enter Your Password"
                style={{ marginTop: "10px" }}
              />

              <Icon
                onClick={() => setPasswordVisible(!passwordVisible)}
                type={passwordVisible ? "eye-blocked" : "eye"}
                style={{
                  color: "#760000",
                  fontSize: 18,
                  position: "absolute",
                  right: 5,
                  top: 50,
                  cursor: "pointer",
                }}
              />
            </PasswordCont>

            <ActionButton
              type="submit"
              variant="primary"
              disabled={!isValid}
              isWorking={isLoginIn}
            >
              Login
            </ActionButton>

            <ComponentFooter>
              Don't have an account?{" "}
              <StyledLink to="/authenticate/signup"> Sign Up</StyledLink>
            </ComponentFooter>
            <ComponentFooter2>
              <StyledLink to="/authenticate/account-recovery">
                Forgot password?
              </StyledLink>
            </ComponentFooter2>
          </FormElement>
        )}
      </Form>
    </ComponentBody>
  );
};

export default Login;
