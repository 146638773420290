import { useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import AddService from "Services/AddService";
import { GradientLoader, Modal } from "shared/components";
import AddServiceIcon from "shared/components/AddServiceIcon";
import DashboardSubHeader from "shared/components/DashboardSubHeader";

import api from "shared/utils/api";
import { filteredDataFn } from "shared/utils/filteredData";
import { truncate } from "shared/utils/styles";
import toast from "shared/utils/toast";
import { setService } from "store/actions/serviceAction";
import headerImg from "../../App/assets/images/sevicesheader.png";
import {
  AddAvatar,
  GetStartedContainer,
  GetStartedLeftBox,
  GetStartedLeftButton,
  GetStartedRightBox,
  HeadWord,
  ServiceListDescription,
  ServiceListId,
  // ServiceLiveMode,
  ServiceMode,
  ServicesList,
  ServicesListContainer,
  // ServiceTestMode,
} from "./Styles";
import shouldPerform, { getRoleName } from "shared/utils/assignRoles";

import consoleLog from "shared/utils/consoleLog";
import BoxTileLoader from "shared/components/GradientLoader/BoxLoader";

const Home = () => {
  const history = useHistory();
  const [visible, setVisible] = useState(false);
  const [reload, setReload] = useState(0);
  const [loading, setLoading] = useState(true);
  const business = useSelector((store: RootStateOrAny) => store.business);
  const [serviceList, setServiceList] = useState(null as any);
  const [searchData, setSearchData] = useState("");
  const [filteredData, setFilteredData] = useState([] as any);
  const currentUser = useSelector((store: RootStateOrAny) => store.currentUser);
  const [filteredColumn, setFilteredColumn] = useState("");
  const dispatch = useDispatch();

  // const [{ data, error, setLocalData }, fetchData] = useApi.get("/services", {
  //   business_id: business && business.id,
  // });

  const filterService = (arr: any) => {
    if (!(arr instanceof Array)) return [];
    let findBusiness =
      currentUser?.businesses instanceof Array &&
      currentUser?.businesses.find((el: any) => el?.id === business?.id);
    if (!findBusiness || !findBusiness?.role) return [];
    // alert(findBusiness?.role.toString().toLowerCase());
    if (
      findBusiness?.role
        .toString()
        .trim()
        .toLowerCase() === "owner"
    )
      return arr;
    if (!(findBusiness?.services instanceof Array)) return [];
    let useArr = [];
    for (let el of arr) {
      if (findBusiness?.services.find((elm: any) => elm.id === el.id)) {
        useArr.push(el);
      }
    }
    return useArr;
  };

  const handleClick = async (val: number) => {
    let data = serviceList.find((el: any) => el.id === val);
    if (data) {
      await dispatch(setService(data));
      history.push(`/my-apps/${data?.id}/dashboard`);
    } else {
      history.push("/authenticate");
    }
  };

  const getServiceList = async (flag = true) => {
    try {
      setLoading(true);

      let addObj = flag ? { checkCache: Math.random() } : {};

      // const response = await api.get("/services", {
      const response = await api.get(
        `/client/business/${business?.id}/services`,
        {
          business_id: business && business.id,
          ...addObj,

          // mode: "LIVE",
        }
      );
      consoleLog("List of services", response);
      if (response.status == true) {
        if (response.payload && response.payload instanceof Array) {
          let data = filterService(response.payload);
          setServiceList(data);
          setFilteredData(data);
        } else {
          toast.error("Unable to fetch Services");
        }
      } else {
        toast.error(response.message);
      }
      // consoleLog(response, "response");
    } catch (err) {
      consoleLog(err, "error");
      // @ts-ignore
      toast.error(err && err.detail);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const filterData = async () => {
      let columnHeaders = [
        { id: "name", label: "Name" },
        { id: "serviceId", label: "Service Id" },
        { id: "mode", label: "Mode" },
      ];
      let useData = await filteredDataFn(
        // serviceList.payload.services,
        serviceList,
        columnHeaders,
        searchData,
        filteredColumn
      );
      setFilteredData(useData);
    };

    // call the function
    filterData()
      // make sure to catch any error
      .catch(console.error);
  }, [searchData, serviceList]);

  useEffect(() => {
    if (serviceList === null) {
      // consoleLog("hi I am here");
      getServiceList(false);
    }
    return;
  }, []);

  const displayContent = () => {
    let dispArr: any = null;
    const tagLabel = (elm?: any) => {
      if (elm?.status === "ACTIVE") {
        return elm?.mode;
      }
      return "Deactivated";
    };
    if (filteredData && filteredData.length) {
      dispArr = filteredData.map((el: any, idx: number) => {
        return (
          <ServicesList key={`${idx}SERV`} onClick={() => handleClick(el.id)}>
            <div>
              {/* <Avatar>
              {el.businessLogo ? (
                <img src={el.businessLogo} style={{ height: 50 }} />
              ) : (
                "FP"
              )}
            </Avatar> */}
              <HeadWord style={{ textTransform: "capitalize" }}>
                {el.name.length > 30 ? el.name.slice(0, 30) + "..." : el.name}

                <div
                  style={{
                    textAlign: "center",
                  }}
                ></div>
              </HeadWord>
            </div>
            <ServiceListId>({el?.serviceId})</ServiceListId>
            <ServiceMode mode={tagLabel(el)}>{tagLabel(el)}</ServiceMode>
            {/* <ServiceListDescription>
              {el?.description && truncate(el?.description)}
            </ServiceListDescription> */}
            {/* <ConnectBtn>Connect</ConnectBtn> */}
          </ServicesList>
        );
      });
    }
    return (
      <>
        {shouldPerform(getRoleName(currentUser, business?.id), "CServ") && (
          <ServicesList onClick={() => setVisible(true)} bg={true}>
            <AddAvatar>
              <AddServiceIcon />
            </AddAvatar>
            <HeadWord style={{ textAlign: "center" }}>
              Add an app
            </HeadWord>
          </ServicesList>
        )}
        {dispArr}
      </>
    );
  };

  let rowCount = (filteredData && filteredData.length) || 0;
  return (
    <>
      <DashboardSubHeader
        title={`${rowCount} apps`}
        btnTitle="Add an app"
        callBackFn={() => setVisible(true)}
        setSearchData={setSearchData}
        withSearch={true}
        // withFilter={true}
        // widthActionBtn={true}
      />
      <GetStartedContainer>
        <GetStartedLeftBox>
          <h2>Get started with FountainPay apps</h2>
          <p>All Active Apps</p>
          <a
            href="https://medium.com/@fountainpay/learn-more-about-fountainpays-services-dd20011051b7"
            target="_new"
          >
            <GetStartedLeftButton>Learn more</GetStartedLeftButton>
          </a>
        </GetStartedLeftBox>
        <GetStartedRightBox>
          <img
            alt="services"
            src={headerImg}
            style={{ marginBottom: "-5px" }}
          />
        </GetStartedRightBox>
      </GetStartedContainer>
      <ServicesListContainer>
        {loading ? <BoxTileLoader /> : <>{displayContent()}</>}
      </ServicesListContainer>

      <Modal
        overFlow={true}
        isOpen={visible}
        width={800}
        containerWidth={false}
        withCloseIcon={true}
        onClose={() => setVisible(false)}
        renderContent={() => (
          <AddService
            fetchData={getServiceList}
            // fetchData={fetchData}
            setVisible={setVisible}
            setReload={setReload}
          />
        )}
      />
    </>
  );
};

export default Home;
