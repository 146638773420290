import { SpinnerCircularFixed } from "spinners-react";

export default function Spinner() {
  return (
    <SpinnerCircularFixed
      thickness={200}
      color="#ffffff"
      size={26}
      enabled={true}
    />
  );
}
