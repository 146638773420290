export const getIpAddress=()=>{
    return '192.168.1.1';
}

export const getLocation=()=>{
  return watchLocation();
}

export const getClientDetails=()=>{
    return navigator.userAgent;
}

const watchLocation=()=>{
    if (navigator.geolocation) {
      navigator.geolocation.watchPosition(
        (details: any)=>{
           return details.coords
        },
        (error: any)=>{
            let errorStr;
            switch (error.code) {
            case error.PERMISSION_DENIED:
                errorStr = 'User denied the request for Geolocation.';
                break;
            case error.POSITION_UNAVAILABLE:
                errorStr = 'Location information is unavailable.';
                break;
            case error.TIMEOUT:
                errorStr = 'The request to get user location timed out.';
                break;
            case error.UNKNOWN_ERROR:
                errorStr = 'An unknown error occurred.';
                break;
            default:
                errorStr = 'An unknown error occurred.';
            }
            return (errorStr)
        }
      );
    }else{
        return "Geolocation not supported by this browser."
    }
}
  
const handleError=(error: any)=>{
    let errorStr;
    switch (error.code) {
      case error.PERMISSION_DENIED:
        errorStr = 'User denied the request for Geolocation.';
        break;
      case error.POSITION_UNAVAILABLE:
        errorStr = 'Location information is unavailable.';
        break;
      case error.TIMEOUT:
        errorStr = 'The request to get user location timed out.';
        break;
      case error.UNKNOWN_ERROR:
        errorStr = 'An unknown error occurred.';
        break;
      default:
        errorStr = 'An unknown error occurred.';
    }
    console.error('Error occurred: ' + errorStr);
}
  
const showPosition=(position: any)=>{
    return position.coords;
}