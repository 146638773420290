import React, { useEffect, useState } from "react";
import {
  ContentHeader,
  ContentRow,
  InnerPanelLayout,
  LoadingCont,
  NoRecordCont,
  PanelTitle,
  ViewAll,
} from "./Style";
import { useHistory } from "react-router-dom";
import { RootStateOrAny, useSelector } from "react-redux";
import Spinner from "shared/components/spinner/spinner";
import { getRecentTransactions } from "Services/serviceClass";
import useServiceDetails from "shared/hooks/useServiceDetails";
import { formateDate } from "shared/utils/utility";
import { Icon } from "shared/components";
import { getStatusColor } from "shared/utils/statusColor";
import { currencyFormat100 } from "shared/utils/moneyFormat";



export default function RecentTransactions(props: any) {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [datas, setData] = useState<any>([]);
  const [isLoading, setisLoading] = useState(false);
  const [isDataFetched, setisDataFetched] = useState(false);
  const [isRecord, setisRecord] = useState(false);

  const service = useSelector((store: RootStateOrAny) => store.service);
  const history = useHistory();

  const truncate = (item: string) => {
    if (!item) {
      return "";
    }

    const str: string = item.length > 15 ? item.substring(0, 15) + "..." : item;

    return <>{str}</>;
  };

  const arrayDataItems = datas.map((item: any, id: number) => (
    <ContentRow key={id}>
      <span>{formateDate(item.date)}</span>
      <Icon type={"arrow-down"} size={20} color={item?.status.toLowerCase().includes("init")?getStatusColor("pending"):getStatusColor(item?.status)} />
      <span>
        {currencyFormat100(item.value, item?.currency)}
      </span>
    </ContentRow>
  ));

  const fetchTransactions = async () => {
    setisLoading(true);
    
    // const sevenDaysAgo: Date = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
    let payload = {
      id: service?.id,
      mode: service?.mode,
      start_date: startDate,
      end_date: endDate,
      page: 1,
      limit: 5,
    };
    // let payload = { id: service?.id, mode: service?.mode };
    const response: any = await getRecentTransactions(service?.id, payload);
    //// console.log("Transactions: ", response)
    if (response?.status) {
      const { data } = response?.payload;
      if (data.length) {
        setisRecord(true);
        const transformedData = data.map((obj: any) => {
          return {
            id: obj?.id,
            date: obj?.createdAt,
            value: obj?.amount,
            currency: obj?.paymentCurrency,
            status: obj?.status,
          };
        });

        setData(transformedData.slice(0, 5));
      } else setisRecord(false);
      setisLoading(false);
      setisDataFetched(true);
    }
  };

  useEffect(() => {
    if (!isDataFetched) {
      setisLoading(true);
      fetchTransactions();
    }
  }, []);

  useEffect(() => {
    console.log("Props: ", props)
    
    const fetch=async()=>{
      await setEndDate(props.endDate);
      await setStartDate(props.startDate);
      await fetchTransactions();
    }
    fetch();
  }, [props]);
  return (
    <InnerPanelLayout>
      <PanelTitle>Recent Transactions</PanelTitle>
      <ContentHeader>
        <span>Date</span>
        <span>Status</span>
        <span>Total volume</span>
      </ContentHeader>
      {isLoading ? (
        <LoadingCont>
          <Spinner />
        </LoadingCont>
      ) : (
        <>
          {isRecord ? (
            <>
              {arrayDataItems}
              <ViewAll
                onClick={() =>
                  history.push(`/my-apps/${service?.id}/transactions`)
                }
              >
                View all
              </ViewAll>
            </>
          ) : (
            <NoRecordCont>No recent transactions</NoRecordCont>
          )}
        </>
      )}
    </InnerPanelLayout>
  );
}
