import { ActionTypes } from "../constants/actionType";
import consoleLog from "shared/utils/consoleLog";

const initialState = {
  users: {},
};

const initialOtpState = {
  otpV: {},
};

export const userReducer = (state: any = initialState.users, action: any) => {
  switch (action.type) {
    case ActionTypes.SET_USERS:
      // consoleLog("PAYLOAD:", action.payload);
      return { ...action.payload };
    default:
      return state;
  }
};

export const currentUserReducer = (
  state: any = initialState.users,
  action: any
) => {
  switch (action.type) {
    case ActionTypes.SET_CURRENT_USER:
      consoleLog("PAYLOAD:", action.payload);
      return action.payload;
    // return { ...state, users: { em: "dddhhd" } };
    default:
      return state;
  }
};


export const userOtpReducer = (
  state: any = initialOtpState.otpV,
  action: any
) => {
  switch (action.type) {
    case ActionTypes.SET_USER_OTP:
      consoleLog("PAYLOAD:", action.payload);
      return action.payload;
    // return { ...state, users: { em: "dddhhd" } };
    default:
      return state;
  }
};