import React, {
  useState,
  useEffect,
  useCallback,
  useLayoutEffect,
} from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { GradientLoader, Spinner } from "shared/components";
import { roles } from "shared/utils/assignRoles";
// import useApi from "shared/hooks/api";
import {
  ActionButton,
  AddTeamForm,
  DetailsContainer,
  FirstStep,
  FirstStepCard,
  FormContainer,
  Input,
  InputGroup,
  InputLabel,
  Mode,
  PageContainer,
  Select,
  ServiceBox,
  ServiceName,
  ServicesBox,
  Step,
  StepContainer,
  StepLine,
  StepsBox,
  StepsContainer,
  TeamFormContainer,
} from "./Styles";
import PermissionDetails from "./Permission/index";
import inviteOBJ from "./inviteClass";
import teamOBJ from "Settings/Team/teamClass";
import consoleLog from "shared/utils/consoleLog";
import shouldPerform, { getRoleName } from "shared/utils/assignRoles";

import toast from "shared/utils/toast";
import OtpModal from "../../../shared/components/OtpModal";
import { is, isEmail } from "shared/utils/validation";

type StepsT = {
  id: number;
  status: boolean;
  title: string;
}[];

export default function InviteMember() {
  const [services, setservices] = useState([] as any);
  const { id: teamId }: any = useParams();
  // consoleLog(teamId, "teamID");

  const [seletedApp, setSelectedApp] = useState("ALL");
  const [firstCardSeleted, setFirstCardSelected] = useState("All");
  const [stepFirstStatus, setstepFirstStatus] = useState(true);
  const [stepTwoStatus, setstepTwoStatus] = useState(false);
  const [stepThreeStatus, setstepThreeStatus] = useState(false);
  const [stepFourStatus, setstepFourStatus] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [btnText, setbtnText] = useState(
    teamId ? "Update Role" : "Send Invite"
  );
  const currentUser = useSelector((store: RootStateOrAny) => store.currentUser);

  const [selectedServices, setselectedServices] = useState<any>([]);
  const [selectedRole, setselectedRole] = useState("");
  const [email, setemail] = useState("");
  const [validEmail, setValidEmail] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const history = useHistory();

  // const currentbusiness = useSelector(
  //   (store: RootStateOrAny) => store.business
  // );
  const business = useSelector((store: RootStateOrAny) => store.business);

  const [steps, setsteps] = useState<StepsT>([
    { id: 1, status: true, title: "1" },
    { id: 2, status: false, title: "2" },
    { id: 3, status: false, title: "3" },
    { id: 4, status: false, title: "4" },
  ]);

  //===== check if service is selected =====
  const handleisSelected = useCallback(
    
    (obj: any) => {
      const check = selectedServices.filter((item: any) => item && item.uuid === obj.uuid);
      if (check.length) return true;
      else return false;
    },
    [selectedServices]
  );

  //==== Handle selected service ===
  const handleSelected = (obj: any) => {
    const check = selectedServices.filter( (item: any) => item && item.uuid === obj.uuid );
    if (check.length) {
      const check = selectedServices.filter( (item: any) => item.uuid !== obj.uuid);
      setselectedServices([...check]);
    } else {
      setstepThreeStatus(true);
      setselectedServices([...selectedServices, obj]);
    }
  };

  const handleStepTwo = () => {
    if (selectedServices.length) {
      const newStep = steps.map((step) => {
        if (step.id === 2) {
          step.status = true;
        }
        return step;
      });

      setsteps([...newStep]); //set step new status
    } else {
      const newStep = steps.map((step) => {
        if (step.id === 2) {
          step.status = false;
        }
        return step;
      });

      setsteps([...newStep]);
    }
  };

  const handleStepThree = () => {
    if (selectedServices.length) {
      const newStep = steps.map((step) => {
        if (step.id === 1 || step.id === 3) {
          step.status = true;
        }
        return step;
      });

      setsteps([...newStep]); //set step new status
    } else {
      const newStep = steps.map((step) => {
        if (step.id === 1 || step.id === 2) {
          step.status = false;
        }
        if (step.id === 1 && firstCardSeleted === "All") {
          step.status = true;
        }
        if (step.id === 2 && firstCardSeleted === "All") {
          step.status = true;
        }
        // consoleLog(step);
        return step;
      });

      setsteps([...newStep]);
    }
  };

  // ====> 4 ====== handle submit============
  const handleSubmit = async (e: React.FormEvent) => {
    setIsProcessing(true);
    try {
      e.preventDefault();
      let payload: any = {};
      if (
        firstCardSeleted === "Services" &&
        selectedServices &&
        selectedRole &&
        email
      ) {
        const formattedServices: any=selectedServices.map((svx: any) => {
          if(svx){
            return svx.uuid
          }
        })
        payload = {
          applications: seletedApp,
          email,
          role: selectedRole,
          serviceId: formattedServices,
        };
      } else if (firstCardSeleted === "All" && selectedRole && email) {
        payload = {
          applications: seletedApp,
          email,
          role: selectedRole,
          serviceId: services.map((svx: any) => svx.uuid),
        };
      }
      if (
        String(currentUser.email)
          .trim()
          .toLowerCase() ===
        String(email)
          .trim()
          .toLowerCase()
      ) {
        toast.error("User cannot invite himself /update  his role ");
        return;
      }
      // consoleLog(payload, "payload");
      if (payload) {
        setbtnText(teamId ? "Loading" : "Sending...");
        //irregular ... writing code to comply with the backend
        // if (payload.serviceId instanceof Array && teamId) {
        //   payload.serviceId = payload.serviceId.join(",");
        // }
        const response = teamId
          ? await teamOBJ.updateTeamRole(business.id, { ...payload, teamId })
          : await teamOBJ.inviteTeamMember(business.id, payload);
        // consoleLog(response, "teamResponse");
        if (response?.status) {
          toast.success(response.message);
          setbtnText(teamId ? "Update Role" : "Send Invite");
          history.push("/team/overview/1");
        } else {
          toast.error(
            teamId
              ? response?.message ?? "Error Updating Role"
              : response?.message ?? "Error sending Invite"
          );
          setbtnText(teamId ? "Update Role" : "Send Invite");
        }
      }
      setstepFourStatus(true);
      handleStepThree();
    } catch (error) {
      // // console.log("Error: ", error);
    } finally {
      setIsProcessing(false);
    }
  };

  //====================================

  //========== for steps ============
  const LineCheck = (step: any, index: number) => {
    if (step.status && index < steps.length - 2) {
      return <StepLine></StepLine>;
    } else if (step.status && index < steps.length - 1) {
      return <StepLine></StepLine>;
    }
  };

  const stepsList = steps?.map((step, index) => (
    <>
      <Step key={index} isActive={step.status}>
        {step.title}
      </Step>
      {LineCheck(step, index)}
    </>
  ));

  //==== Service list ===
  const serviceList = services.map((obj: any, index: number) => (
    <ServiceBox
      key={index}
      isClicked={handleisSelected(obj)}
      onClick={() => {
        handleSelected(obj);
        setsteps([
          { id: 1, status: false, title: "1" },
          { id: 2, status: false, title: "2" },
          { id: 3, status: false, title: "3" },
          { id: 4, status: true, title: "4" },
        ]);
        // consoleLog("selected service", obj);
      }}
    >
      <ServiceName>{obj?.name}</ServiceName>
      <p>({obj?.id})</p>
      <Mode mode={obj?.mode}>{obj?.mode}</Mode>
    </ServiceBox>
  ));

  //Roles
  // const roles = ["MEMBER", "OWNER", "DEVELOPERS", "CUSTOMER_SUPPORT"]; ///OWNER -> ADMIN
  // const roles = [
  //   "ADMIN",
  //   "OWNER",
  //   "DEVELOPER SUPPORT",
  //   "CUSTOMER SUPPORT",
  //   "CO-OWNER",
  // ];

  const getServices = async () => {
    setisLoading(true);
    const response = await inviteOBJ.getServices(business.id);
    if (response) {
      // consoleLog("serives", response);
      const servx = response?.services.map((service: any) => {
        return {
          name: service?.name,
          mode: service?.mode,
          id: service?.serviceId,
          uuid: service?.id,
        };
      });
      setservices(servx);
      setisLoading(false);
      return servx;
    }
  };

  const getTeamMemberService = async (servx: any = undefined) => {
    const responseData = await inviteOBJ.getTeamMemberDetails(
      business.id,
      teamId
    );

    // consoleLog(responseData);
    if (responseData === null) {
      toast.error("Invalid Team Member");
      history.push("/team/overview");
    } else {
      setemail(responseData?.email);
      //   let arr: any =
      //     responseData?.userBusinessServices instanceof Array &&
      //     responseData?.userBusinessServices.map((service: any) => {
      //       return {
      //         name: service?.Service?.name,
      //         uuid: service?.serviceId,
      //       };
      //     });
      //   consoleLog(arr, "Arrr");
      //   if (arr instanceof Array) {
      let selSercices =
        typeof responseData?.serviceIds === "string" && servx instanceof Array
          ? responseData?.serviceIds.split(",").map((servId: any) => {
              let svx = servx.find((s: any) => s.uuid === servId);
              return svx ? { uuid: svx.uuid, name: svx.name } : null;
            })
          : [];

      // consoleLog(selSercices, responseData?.serviceIds, servx, "selServices");
      setselectedServices(selSercices);
      setFirstCardSelected("Services");
      setstepTwoStatus(true);
      setstepThreeStatus(true);
      setselectedRole(responseData?.roles);
      //   }
    }
  };

  useEffect(() => {
    (async () => {
      let servx = await getServices();
      if (teamId) {
        ///get details of the particular team.
        await getTeamMemberService(servx);
      }
    })();
  }, []);

  useEffect(() => {
    handleStepTwo(); //handle step 2 listening for changes
    if (!selectedServices.length && firstCardSeleted === "Services") {
      setstepThreeStatus(false);
    }
  }, [selectedServices]);

  // <OtpModal callBackFn={changeDetails} closeModal={setOpenOtp} />
  if (!shouldPerform(getRoleName(currentUser, business?.id), "CTeams")) {
    history.push("/authenticate");
    return null;
  }

  return (
    <PageContainer>
      <TeamFormContainer>
        {isLoading ? (
          <GradientLoader />
        ) : (
          <>
            <FormContainer>
              <StepsBox isActive={true} border={true}>
                <FirstStep>
                  <FirstStepCard
                    isSelected={seletedApp === "ALL" ? true : false}
                    onClick={() => {
                      setsteps([
                        { id: 1, status: false, title: "1" },
                        { id: 2, status: true, title: "2" },
                        { id: 3, status: false, title: "3" },
                        { id: 4, status: false, title: "4" },
                      ]);
                      setSelectedApp("ALL");
                      setstepFirstStatus(false);
                      setstepTwoStatus(true);
                      setstepThreeStatus(false);
                      setstepFourStatus(false);
                    }}
                  >
                    All
                  </FirstStepCard>
                  <FirstStepCard
                    isSelected={seletedApp === "GATEWAY" ? true : false}
                    onClick={() => {
                      setsteps([
                        { id: 1, status: false, title: "1" },
                        { id: 2, status: true, title: "2" },
                        { id: 3, status: false, title: "3" },
                        { id: 4, status: false, title: "4" },
                      ]);
                      setSelectedApp("GATEWAY");
                      setstepFirstStatus(false);
                      setstepTwoStatus(true);
                      setstepThreeStatus(false);
                      setstepFourStatus(false);
                    }}
                  >
                    Gateway
                  </FirstStepCard>
                  <FirstStepCard
                    isSelected={seletedApp === "AGENCY_BANKING" ? true : false}
                    onClick={() => {
                      setsteps([
                        { id: 1, status: true, title: "1" },
                        { id: 2, status: false, title: "2" },
                        { id: 3, status: false, title: "3" },
                        { id: 4, status: false, title: "4" },
                      ]);
                      setSelectedApp("AGENCY_BANKING");
                      setstepFirstStatus(false);
                      setstepTwoStatus(true);
                      setstepThreeStatus(false);
                      setstepFourStatus(false);
                    }}
                  >
                    Agency Banking
                  </FirstStepCard>
                </FirstStep>
              </StepsBox>
              {(seletedApp === "ALL" || seletedApp === "GATEWAY") && (
                <>
                  <StepsBox isActive={stepTwoStatus} border={true}>
                    <FirstStep>
                      <FirstStepCard
                        isSelected={firstCardSeleted === "All" ? true : false}
                        onClick={() => {
                          setsteps([
                            { id: 1, status: false, title: "1" },
                            { id: 2, status: false, title: "2" },
                            { id: 3, status: true, title: "3" },
                          ]);
                          setFirstCardSelected("All");
                          setstepFirstStatus(false);
                          setstepTwoStatus(true);
                          setstepThreeStatus(true);
                          setstepFourStatus(false);
                          setselectedServices([]);
                        }}
                      >
                        All My Apps
                      </FirstStepCard>
                      <FirstStepCard
                        isSelected={
                          firstCardSeleted === "Services" ? true : false
                        }
                        onClick={() => {
                          if (steps.length < 4) {
                            setsteps([
                              { id: 1, status: false, title: "1" },
                              { id: 2, status: false, title: "2" },
                              { id: 3, status: true, title: "3" },
                              { id: 4, status: false, title: "4" },
                            ]);
                          }
                          if (firstCardSeleted !== "Services") {
                            setFirstCardSelected("Services");
                            setstepFirstStatus(false);
                            setstepTwoStatus(true);
                            setstepThreeStatus(true);
                            setstepFourStatus(false);
                            // setselectedServices([]);
                          }
                        }}
                      >
                        Selected Apps
                      </FirstStepCard>
                    </FirstStep>
                  </StepsBox>

                  {/* ===step 2 */}
                  <StepsBox
                    isActive={
                      stepThreeStatus && firstCardSeleted === "Services"
                    }
                    border={true}
                  >
                    <ServicesBox>{serviceList}</ServicesBox>
                  </StepsBox>
                </>
              )}

              {/* ===step 3 */}
              <StepsBox isActive={true}>
                <AddTeamForm onSubmit={handleSubmit}>
                  <InputGroup>
                    <InputLabel>Email Address</InputLabel>
                    {teamId ? (
                      email
                    ) : (
                      <Input
                        value={email}
                        type="email"
                        placeholder="Email"
                        required
                        onBlur={() => {
                          if (!isEmail(email)) {
                            setValidEmail(false);
                          } else {
                            setValidEmail(true);
                          }
                        }}
                        onChange={(event) => {
                          setemail(event.target.value);
                        }}
                      />
                    )}
                  </InputGroup>

                  <InputGroup>
                    <InputLabel>Role</InputLabel>
                    <Select
                      value={selectedRole}
                      onChange={(event) => {
                        setselectedRole(event.target.value);
                      }}
                    >
                      <>
                        <option value=""></option>
                        {roles.map((role, index) => {
                          if (role === "OWNER") return null;
                          let roleValue = role
                            .toString()
                            .trim()
                            .replace(/ |-/, "_");
                          return (
                            <option key={index} value={roleValue}>
                              {role}
                            </option>
                          );
                        })}
                      </>
                    </Select>
                  </InputGroup>
                  <ActionButton type="submit">
                    {isProcessing ? <Spinner color="#fff" /> : btnText}
                  </ActionButton>
                </AddTeamForm>
              </StepsBox>
            </FormContainer>
            <StepContainer>
              <StepsContainer>{stepsList}</StepsContainer>
            </StepContainer>
          </>
        )}
      </TeamFormContainer>

      <DetailsContainer>
        <PermissionDetails />
      </DetailsContainer>
    </PageContainer>
  );
}
