import React from "react";

import {
  Container,
  EsclamationImg,
  StateTitle,
  StatePara,
  CreateSubAccBtn,
} from "./Styles";

import AddNewItem from "App/assets/addnewitem.svg";

function index({ openModal, stateTitle, statePara, stateBtnText }: any) {
  return (
    <Container>
      <EsclamationImg
        src={AddNewItem}
        alt="No record found"
      />
      {stateTitle && <StateTitle>{stateTitle}</StateTitle>}

      {statePara && <StatePara>{statePara}</StatePara>}

      {stateBtnText && (
        <CreateSubAccBtn onClick={openModal}>{stateBtnText}</CreateSubAccBtn>
      )}
    </Container>
  );
}

export default index;
