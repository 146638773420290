import Layout from "Layout";
import { useEffect, useState } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { GradientLoader, Modal } from "shared/components";
import DashboardSubHeader from "shared/components/DashboardSubHeader";
import EmptyState from "shared/components/FreeComp/EmptyState";
import Table from "shared/components/Table";
import api from "shared/utils/api";
import AddCustomer from "./AddCustomer";
import { EmptyContainer, Container } from "./Style";
import { useHistory } from "react-router-dom";
import shouldPerform, { getRoleName } from "shared/utils/assignRoles";
import consoleLog from "shared/utils/consoleLog";
import { filterRecords } from "shared/utils/utility";

const Customers = () => {
  // const match = useRouteMatch();
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(1);
  const [numOfRowsPerPage, setNumOfRowsPerPage] = useState(10);
  const [visible, setVisible] = useState(false);
  const [searchData, setSearchData] = useState("");
  const [filteredColumn, setFilteredColumn] = useState("");
  const business = useSelector((store: RootStateOrAny) => store.business);
  const [data, setData] = useState<any>([]);
  const currentUser = useSelector((store: RootStateOrAny) => store.currentUser);
  const [isLoading, setisLoading] = useState(false);
  const [fetchedPayload, setFetchedPayload] = useState<any>({});
  const [filteredRecords, setFilteredRecords] = useState<any>({});

  const deleteM = (data: any) => {};

  const columnHeaders = [
    { id: "serviceName", label: "Service Name" },
    { id: "fullName", label: "Full Name" },
    { id: "email", label: "Email Address" },
    { id: "phone", label: "Phone Number" },
    // { id: "dateCreated", label: "Date Created" },
    // { id: "funcProps", label: "" },
  ];

  ///Add Search Function
  // data = filteredDataFn(data, columnHeaders, searchData, filteredColumn);
  // const rowCount = data.length;

  const getCustomers = async (obj?: any) => {
    setisLoading(true);
    try {
      let payload={
        page: currentPage,
        limit: numOfRowsPerPage, 
      }

      payload={...payload, ...obj}
      const res: any = await api.post(`/business/${business.id}/customers/query`, payload);
      //consoleLog("res ", res);
      setFetchedPayload(res.payload)
      let resultData = res?.payload?.data;
      resultData = resultData instanceof Array ? resultData : [];
      let resData = [];
      for (let el of resultData) {
        let obj: any = {};
        obj["serviceName"] = el?.Service?.name;
        resData.push({ ...el, ...obj });
      }
      resData.map((cur) => {
        if(cur.fullName === "undefined undefined") {
          cur.fullName = " "
        }
      })

      // console.log("Full records: ", resData)
      setData(resData);
      setFilteredRecords(resData)
    } catch (error) {
      consoleLog(error);
    }finally{
      setisLoading(false);
    }
  };

  const searchRecords=(search: string)=>{
    setFilteredRecords(filterRecords(data, search))
  }

  const goToPage = async (val: number) => {
    //let obj: any = { ...filteredFormState };
    setCurrentPage(val)
    //obj.page = val;
    await getCustomers();
  };

  const myFunction = (val: any) => {
    history.push(`/customers/transaction/${val?.id}`);
  };

  const filterCallback=(obj: any)=>{
    // console.log("Filtered object: ", obj)
    getCustomers(obj)
  }

  useEffect(() => {
    getCustomers();
  }, []);

  if (!shouldPerform(getRoleName(currentUser, business?.id), "VCustomers")) {
    history.push("/authenticate");
    return null;
  }

  return (
    <Container>
      <DashboardSubHeader
        count={data.length}
        title={`Customers`}
        btnTitle="Add Customer"
        callBackFn={myFunction}
        searchCallbackFn={searchRecords}
        withSearch={data.length === 0 ? false : true}
        withFilter={data.length === 0 ? false : true}
        widthActionBtn={false}
        filterOptions={["Name", "Date"]}
        filterType="customers"
        onFilterCallback={filterCallback}
      />

      {isLoading && <GradientLoader />}
      {data.length === 0 &&
        <EmptyContainer>
          <EmptyState
            openModal={() => setVisible(true)}
            stateTitle="No Customer"
            statePara=""
            stateBtnText=""
          />
        </EmptyContainer>
      }
      {data.length > 0 &&
        <>
          <Table
            headData={columnHeaders}
            bodyData={filteredRecords}
            clickFunction={myFunction}
            hoverLink
            serialNumber
            totalPages={fetchedPayload?.totalPages ?? 0}
            currentpage={fetchedPayload?.currentPage ?? 0}
            goToPage={goToPage}
          />
        </>
      }

      <Modal
        isOpen={visible}
        width={800}
        withCloseIcon={true}
        onClose={() => setVisible(false)}
        renderContent={() => <AddCustomer />}
      />
    </Container>
  );
};

export default Customers;
