import { useEffect, useState } from "react";
import { FaBell } from "react-icons/fa";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import { DropdownButton, Icon, NotificationTooltip } from "shared/components";

// import useCurrentUser from "shared/hooks/currentUser";
import { toggleMode } from "apiServices/authService";
import AddService from "Services/AddService";
import { Modal } from "shared/components";
import { setCurrentBusiness } from "store/actions/businessAction";
import { makeLive } from "store/actions/live";
import consoleLog from "shared/utils/consoleLog";

import {
  AlertBtn,
  IconText,
  KycHeader,
  MainHeader,
  NavLeftMenu,
  NavTop,
  NotificationBall,
  NotificationContainer,
  PageTitle as PageTitleS,
  ServiceBtn,
  ServiceFilterButton,
  SubContainer,
  Title,
  TitleSpan,
} from "./Styles";
import { getCurrentBusinessKycStep } from "shared/utils/utility";
import shouldPerform, { getRoleName } from "shared/utils/assignRoles";
import Settings from "Services/Settings";

type PropT = {
  [x: string]: any;
};

const Header = ({
  PageTitle = "",
  toggleNav,
  showNotification = false,
  showServiceButtons = false,
}: any) => {
  const currentBusiness = useSelector(
    (store: RootStateOrAny) => store.business
  );
  const urlPath = window.location.pathname;

  const currentBusinessKyc = useSelector(
    (store: RootStateOrAny) => store.businessKyc
  );

  // consoleLog(currentBusinessKyc, "KYCccc", currentBusiness, "Busi");
  const live = useSelector((store: RootStateOrAny) => store.live);
  const [visible, setVisible] = useState(false);
  const [reload, setReload] = useState(0);
  // const [live, setLive] = useState(true);
  const [label, setLabel] = useState(live ? "Live" : "Test");
  const [countChanges, setCountChanges] = useState(0);
  const [notifications, setNotifications] = useState<any[]>([]);
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState<string>("");

  const [settingVisible, setSettingVisible] = useState(false);
  const [addedProps, setAddedProps] = useState<object>({});

  const currentUser = useSelector((store: RootStateOrAny) => store.currentUser);
  const service = useSelector((store: RootStateOrAny) => store.service);
  //consoleLog(currentUser, "currentUser");
  const history = useHistory();
  const dispatch = useDispatch();

  const currentbusiness = useSelector(
    (store: RootStateOrAny) => store.business
  );
  // const currentUser = useCurrentUser();
  const business = useSelector((store: RootStateOrAny) => store.business);
  //consoleLog(business, "currentUserbusi");

  const addBusinesses = () => history.push("/business/add");

  // const createService = () => history.push("/services/list");
  const createService = () => setVisible(true);

  const setLive = (val: boolean) => {
    dispatch(makeLive(val));
    setCountChanges(countChanges + 1);
  };

  // const handleToggleMode = async (status: boolean) => {
  //   try {
  //     setLive(status);
  //     const values = { ...currentBusiness };
  //     const response: any = await toggleMode(values);
  //     if (response.status && response.payload.length) {
  //       if (values.mode.test !== "TEST") {
  //         setLive(false);
  //       } else {
  //         setLive(true);
  //       }
  //       dispatch(setCurrentBusiness(response?.payload));
  //     }
  //   } catch (error) {
  //     setLive(live);
  //   }
  // };

  const jumpToKyc = () => {
    const step = getCurrentBusinessKycStep(
      currentbusiness,
      currentPage.includes("gateway") ? "GATEWAY" : "AGENCY"
    );
    if (!currentPage.includes("agency")) {
      if (step == 1) {
        history.push("/kyc/add-account-details");
      } else {
        history.push("/kyc/gateway-upload-documents");
      }
    } else {
      history.push("/kyc/residential-information");
      if (step === 1) {
        history.push("/kyc/residential-information");
      } else if (step === 2) {
        history.push("/kyc/business-information");
      } else if (step === 3) {
        history.push("/kyc/agency-banking-upload-documents");
      }
    }
  };

  const dropDownButtonClickEvent = (selected: string) => {
    switch (selected) {
      case "settings":
        setAddedProps({
          variant: "aside",
          width: "600",
          radius: 1,
          testid: "modal:service-setting",
        });
        setSettingVisible(true);
        break;
      case "account":
        history.push(`/my-apps/${service?.id}/account-details`);
        break;
      case "splitting":
        history.push(`/my-apps/${service?.id}/payment-aplitting`);
        break;
      case "wallet":
        history.push(`/my-apps/${service?.id}/wallet`);
        break;
      case "apiKeys":
        history.push(`/my-apps/${service?.id}/api-keys`);
        break;

      case "service":
        history.push(`/my-apps/${service?.id}/details`);
        break;

      case "account":
        history.push(`/my-apps/${service?.id}/account-details`);
        break;
      default:
        // console.log(selected);
    }
  };
  const filterDropDownButtonClickEvent = (selected: string) => {
    // console.log(selected);
  };

  useEffect(() => {
    setCurrentPage(window.location.pathname);
    if (reload !== 0) {
      if (
        location?.pathname &&
        location?.pathname.indexOf("/my-apps/list") > -1
      ) {
        window.location.reload();
      } else {
        history.push("/my-apps/list");
      }
    }
  }, [reload]);
  return (
    <>
      <NavTop>
        {currentBusinessKyc.length && !currentBusinessKyc.status && (
          <KycHeader>
            <div>
              You are yet to complete your KYC. Kindly folow this link to
              complete your KYC
            </div>{" "}
            <AlertBtn onClick={jumpToKyc}>Go to KYC</AlertBtn>
          </KycHeader>
        )}

        <MainHeader>
          <SubContainer>
            {!urlPath.includes("/my-apps") && (
              <>
                <NavLeftMenu onClick={toggleNav}>
                  <Icon
                    style={{ marginTop: "7px" }}
                    type="menu"
                    size="28"
                    color="#760000"
                  />
                </NavLeftMenu>
                <PageTitleS>{PageTitle}</PageTitleS>
              </>
            )}

            {urlPath.includes("/my-apps/") && (
              <>
                <NavLeftMenu onClick={toggleNav}>
                  <Icon
                    style={{ marginTop: "7px" }}
                    type="menu"
                    size="28"
                    color="#760000"
                  />
                </NavLeftMenu>

                {!urlPath.includes("/my-apps/list") && (
                  <Title>
                    <Icon
                      style={{ marginTop: "7px" }}
                      type="chevron-left"
                      size="28"
                      color="#ccc"
                      onClick={() => history.goBack()}
                    />
                    <TitleSpan>{service?.name}</TitleSpan>
                    <Icon
                      style={{ marginTop: "7px" }}
                      type="home"
                      size="28"
                      color="#ccc"
                      onClick={() =>
                        history.push(`/my-apps/${service?.id}/dashboard`)
                      }
                    />
                  </Title>
                )}
              </>
            )}
          </SubContainer>
          <SubContainer>
            {shouldPerform(getRoleName(currentUser, business?.id), "CServ") && (
              <ServiceBtn onClick={createService}>
                <Icon type="plus" color="#760000" size={27} />
                <IconText>
                  <span>Add an app</span>
                </IconText>
              </ServiceBtn>
            )}

            {showNotification && (
              <NotificationTooltip
                placement="left"
                offset={{ top: 80, left: 70 }}
                renderLink={(linkProps: PropT) => (
                  <NotificationContainer
                    color={notifications.length > 0 ? "red" : "grey"}
                    {...linkProps}
                  >
                    <div>
                      <NotificationBall />
                      <FaBell
                        color={notifications.length > 0 ? "red" : "grey"}
                        size={20}
                      />
                    </div>
                  </NotificationContainer>
                )}
              />
            )}
            {urlPath.includes("/my-apps/") &&
              !urlPath.includes("/my-apps/list") && (
                <>
                  <DropdownButton
                    btnTtitle="Last 7 days"
                    menus={[
                      { title: "Last 7 days", value: "Last 7 days", icon: "" },
                      {
                        title: "Last 30 days",
                        value: "Last 30 days",
                        icon: "",
                      },
                      {
                        title: "Last 60 days",
                        value: "Last 60 days",
                        icon: "",
                      },
                      {
                        title: "Last 90 days",
                        value: "Last 90 days",
                        icon: "",
                      },
                    ]}
                    labelTitle={true}
                    backgroundColor={"transparent"}
                    width={"116px"}
                    onClickEvent={filterDropDownButtonClickEvent}
                  />

                  <DropdownButton
                    btnTtitle="App Configuration"
                    menus={[
                      { title: "App details", value: "service", icon: "" },
                      { title: "App accounts", value: "account", icon: "" },
                      {
                        title: "Payment splitting",
                        value: "splitting",
                        icon: "",
                      },
                      { title: "Wallet", value: "wallet", icon: "" },
                      { title: "API keys", value: "apiKeys", icon: "" },
                      { title: "App settings", value: "settings", icon: "" },
                    ]}
                    onClickEvent={dropDownButtonClickEvent}
                  />
                </>
              )}
          </SubContainer>
        </MainHeader>
      </NavTop>
      <Modal
        overFlow={true}
        isOpen={visible}
        width={900}
        containerWidth={false}
        withCloseIcon={true}
        onClose={() => setVisible(false)}
        renderContent={() => (
          <AddService setVisible={setVisible} setReload={setReload} />
        )}
      />

      <Modal
        isOpen={settingVisible}
        width={800}
        withCloseIcon={true}
        onClose={() => setSettingVisible(false)}
        overFlow={true}
        containerWidth={false}
        renderContent={() => (
          <Settings setReload={setReload} setVisible={setSettingVisible} />
        )}
        {...addedProps}
      />
    </>
  );
};
export default Header;
