import Filter from "App/assets/images/services/filter.svg";
import { ReactNode, useState, useEffect } from "react";
import { Checkbox, Icon, Input } from "shared/components";
import SharedCheckList from "shared/section_components/SharedCheckList";
import { onChangeTextHandler } from "shared/utils/formValidation";
import Select from "shared/components/Select";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import ExcelExport from "shared/components/ExcelExport";
import shouldPerform, { getRoleName } from "shared/utils/assignRoles";
import PdfExport from "shared/components/PdfExport/Index";
import toast from "shared/utils/toast";

import {
  ActionButton,
  CloseFilter,
  FilterButton,
  FilterInputCont,
  FilterParams,
  FilterParamsCont,
  FilterSec,
  FilterTitle,
  Header,
  InputHolder,
  LabelHolder,
  LeftPane,
  PopUp,
  PopUpCont,
  PopUpFooter,
  PopUpHead,
  PopUpSubHead,
  PopContent,
  ResetButton,
  SearchInput,
  Title,
  DownloadOptBody,
  DownloadOptTitle,
  DownloadOptFooter,
  ResetDownloadButton,
  ActionoloadButton,
  DownloadSec,
  DownloadSec2,
  FilterText,
  TextDescription,
  DownloadOptCont,
} from "./Styles";
import consoleLog from "shared/utils/consoleLog";
import { useHistory } from "react-router-dom";
import RadioButton from "../RadioButton";
import {
  PDFDownloadLink,
  Document,
  Page,
  Text,
  View,
  StyleSheet,
} from "@react-pdf/renderer";
import { ActionoloadButtonPdf, FileDownloadSelect } from "Overview/Styles";

import {
  AgencyTransactionFilter,
  SubaccountFilter,
  TransactionFilter,
  CustomerFilter,
  AgentsFilter,
  AssetsFilter,
  SettlementFilter,
} from "../Filters";
import api from "shared/utils/api";

type PropT = {
  count?: number;
  title: string | ReactNode;
  btnTitle: string | ReactNode;
  withSearch?: boolean;
  withFilter?: boolean;
  callBackFn?: any;
  setSearchData?: any;
  searchCallbackFn?: any;
  widthActionBtn?: boolean;
  filterOptions?: any;
  padHorizontalOff?: boolean;
  sortFn?: any;
  filterFn?: any;
  setCollectFilterPara?: any;
  clickedParam?: any;
  setStartDate?: any;
  setEndDate?: any;
  resetFilter?: any;
  getParams?: any;
  getMedium?: any;
  filterType?: string;
  onFilterCallback?: any;
  arrToFilter?: any;
  fstate?: any;
  setFstate?: any;
  applyFilter?: any;
  openDownload?: any;
  setOpenDownload?: any;
  downloadData?: any;
  downloadParameter?: any;
  downloadUrl?: string;
  excelExport?: boolean;
  excelExportData?: Array<object>;
  pdfExport?: boolean;
  setFilterFormState?: boolean;
  useBack?: boolean;
  withPrintBtn?: boolean;
  withQueryBtn?: boolean;
  withShareBtn?: boolean;
  dateFilter?: boolean;
  initialState?: Object;
};

const DashboardSubHeader = ({
  count,
  title,
  btnTitle,
  withSearch,
  withFilter,
  widthActionBtn,
  callBackFn,
  setSearchData,
  searchCallbackFn,
  clickedParam,
  setStartDate, //remove laterr
  setEndDate, //remove laterr
  resetFilter,
  filterType,
  onFilterCallback,
  arrToFilter,
  getParams,
  getMedium,
  fstate,
  setFstate,
  applyFilter,
  openDownload,
  setOpenDownload,
  downloadData,
  downloadParameter,
  downloadUrl,
  excelExport,
  pdfExport,
  excelExportData = [],
  setFilterFormState,
  useBack,
  withPrintBtn,
  withQueryBtn,
  withShareBtn,
  dateFilter,
  initialState,
}: PropT) => {
  const [input, setInput] = useState("");
  const [openPopUp, setOpenPopUp] = useState(false);
  const [checkedValues, setCheckedValues] = useState<any>({});
  const [filterFormValues, setFilterFormValues] = useState<any>({});

  const [filterPayload, setFIlterPayload] = useState<any>({});
  const [downloadLoading, setDownloadLoading] = useState<boolean>(false);

  const currentUser = useSelector((store: RootStateOrAny) => store.currentUser);
  const business = useSelector((store: RootStateOrAny) => store.business);
  const history = useHistory();
  const [fileToDownload, setFileToDownload] = useState<any>({
    value: "Excel Sheet",
  });

  const downloadFileOpt = [
    {
      label: "Excel Sheet",
      value: true,
    },
    {
      label: "Pdf File",
      value: false,
    },
  ];

  const resetFilterParams = () => {
    setStartDate && setStartDate(""); // remove later
    setEndDate && setEndDate(""); // remove later

    setFilterFormValues({});
    applyFilter && applyFilter({});
    // setCheckedValues({});
    // setFstate({});
    resetFilter();
    setOpenPopUp(false);
  };

  const setFilterDateParams = (value: string, pin: string) => {
    let formatedDate = [];
    formatedDate.push(value.split("-")[2]);
    formatedDate.push(value.split("-")[1]);
    formatedDate.push(value.split("-")[0]);
    let properDate = formatedDate.join("/");
    if (pin === "Start Date") {
      setStartDate(properDate);
    } else if (pin === "End Date") {
      setEndDate(properDate);
    } else if (pin === "Download Start Date") {
      setStartDate(value);
    } else if (pin === "Download End Date") {
      setEndDate(value);
    }
  };

  const queryTransaction = async () => {
    const response = "transaction/verify/907263364";
  };
  const logMessage = () => {
    toast.warning("Please this feature is coming soon.");
  };

  const processFilterValues = (obj: any) => {
    // console.log("Returned data: ", obj)
    setFIlterPayload(obj);
  };

  const renderFilterContent = () => {
    switch (filterType) {
      case "transaction":
        return (
          <TransactionFilter
            initialState={initialState}
            onProcessFilter={processFilterValues}
          />
        );

      case "subaccount":
        return <SubaccountFilter onProcessFilter={processFilterValues} />;

      case "customers":
        return <CustomerFilter onProcessFilter={processFilterValues} />;

      case "assets":
        return <AssetsFilter onProcessFilter={processFilterValues} />;

      case "agentsList":
        return <AgentsFilter onProcessFilter={processFilterValues} />;

      case "agencyTransaction":
        return (
          <AgencyTransactionFilter onProcessFilter={processFilterValues} />
        );

      case "settlement":
        return <SettlementFilter onProcessFilter={processFilterValues} />;
    }
  };

  const onFilterProcess = () => {
    applyFilter && applyFilter(filterPayload);
    onFilterCallback && onFilterCallback(filterPayload);
  };

  const flatten = (obj: any) => {
    var empty = true;
    if (obj instanceof Array) {
      var str = "[";
      empty = true;
      for (var i = 0; i < obj.length; i++) {
        empty = false;
        str += flatten(obj[i]) + ", ";
      }
      return (empty ? str : str.slice(0, -2)) + "]";
    } else if (obj instanceof Object) {
      var str = "[";
      empty = true;
      for (var k in obj) {
        empty = false;
        str += k + ":" + flatten(obj[k]) + ", ";
      }
      return (empty ? str : str.slice(0, -2)) + "]";
    } else {
      return obj; // not an obj, don't stringify me
    }
  };

  const handleChange = (value: any) => {
    setInput(value);
    searchCallbackFn && searchCallbackFn(value);
    setSearchData && setSearchData(value);
  };

  const handleDisplay = () => {
    callBackFn();
  };

  const opt = ["Today", "Week", "30 days"];

  let BackDiv = useBack && (
    <span
      onClick={() => {
        let link = "";
        if (
          location?.pathname &&
          (location?.pathname.indexOf("/my-apps/dashboard") > -1 ||
            location?.pathname.indexOf("/my-apps/list") > -1)
        ) {
          link = "/my-apps/list";
          history.push(link);
        } else {
          link = "/my-apps/dashboard";

          // consoleLog(history2, "history");
          history?.length > 1 ? history.goBack() : history.push(link);
        }
      }}
      style={{ cursor: "pointer", marginRight: "0.4rem", marginTop: "0.2rem" }}
    >
      {/*  */}
      {location?.pathname &&
      location?.pathname.indexOf("/my-apps/list") > -1 ? null : (
        <Icon type='arrow-left-circle' size={20} />
      )}
    </span>
  );

  const downloadRecords = async () => {
    setDownloadLoading(true);
    try {
      const data = await api.post(downloadUrl, downloadParameter);
    } catch (error) {
    } finally {
      setDownloadLoading(false);
    }
  };

  const MyDoc = ({ data }: any) => {
    const styles = StyleSheet.create({
      page: {
        backgroundColor: "#fff",
        color: "#000",
        display: "flex",
        flexDirection: "column",
      },
      section: {
        // margin: 10,
        padding: 10,
        display: "flex",
        flexDirection: "row",
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
        borderColor: "#000",
        borderBottomWidth: 1,
        marginTop: 20,
      },
      text: {
        flexWrap: "wrap",
        width: 100,
        textAlign: "center",
      },
      viewer: {
        zIndex: 9999,
        width: window.innerWidth, //the pdf viewer will take up all of the width and height
        height: window.innerHeight,
      },
    });
    return (
      <Document>
        <Page size='A3' style={styles.page}>
          {data.map((cur: any, id: any) => (
            <View key={id} style={styles.section}>
              {cur.map((entry: any, idx: any) => (
                <Text key={idx} style={styles.text}>
                  {entry}
                </Text>
              ))}
            </View>
          ))}
        </Page>
      </Document>
    );
  };

  useEffect(() => {
    const date = new Date();
    let currentDay = String(date.getDate()).padStart(2, "0");
    let currentMonth = String(date.getMonth() + 1).padStart(2, "0");
    let currentYear = date.getFullYear();
    // we will display the date as DD-MM-YYYY
    let currentDate = `${currentYear}/${currentMonth}/${currentDay}`;
    setFIlterPayload({
      start: currentDate,
      end: currentDate,
      search: "",
      successful: true,
      failed: true,
      terminated: true,
      card: true,
      qr: true,
      transfer: true,
      direct_debit: true,
      ebanking: true,
      mobile_money: true,
    });
    // onFilterProcess();
  }, []);

  return (
    <Header>
      <Title>
        {BackDiv}
        {count} {title}
      </Title>

      <LeftPane>
        {withSearch && (
          <SearchInput>
            <Input
              type='text'
              name='input'
              value={input}
              placeholder={`Search ${clickedParam ?? ""} `}
              onChange={handleChange}
              styleContainer={{ marginBottom: 0 }}
            />
          </SearchInput>
        )}

        {/* With Filter */}
        {withFilter && (
          <PopUpCont>
            <FilterButton onClick={() => setOpenPopUp(!openPopUp)}>
              Filter <img src={Filter} alt='' />
            </FilterButton>

            {openPopUp && (
              <PopUp>
                <PopUpHead>
                  <FilterTitle>Filter</FilterTitle>

                  <CloseFilter onClick={() => setOpenPopUp(false)}>
                    <Icon type={"close"} />
                  </CloseFilter>
                </PopUpHead>
                <PopContent>{renderFilterContent()}</PopContent>

                <PopUpFooter>
                  <ResetButton onClick={resetFilterParams}>Reset</ResetButton>
                  <ActionButton
                    onClick={() => {
                      onFilterProcess();
                    }}
                  >
                    Apply
                  </ActionButton>
                </PopUpFooter>
              </PopUp>
            )}
          </PopUpCont>
        )}

        {shouldPerform(getRoleName(currentUser, business?.id), "DSummary") &&
          widthActionBtn && (
            <PopUpCont>
              {btnTitle ? (
                excelExport && dateFilter === false ? (
                  // <ExcelExport data={excelExportData}>{btnTitle}</ExcelExport>
                  <ActionButton onClick={handleDisplay}>
                    {btnTitle}
                  </ActionButton>
                ) : typeof btnTitle === "string" ? (
                  <ActionButton onClick={handleDisplay}>
                    {btnTitle}
                  </ActionButton>
                ) : (
                  btnTitle
                )
              ) : null}

              {openDownload && (
                <PopUp>
                  {dateFilter === false ? (
                    <DownloadOptCont>
                      <ExcelExport data={excelExportData} type={"beta"}>
                        <Icon
                          type={"file-text2"}
                          style={{
                            color: "#760000",
                            marginRight: 10,
                            borderRadius: 20,
                          }}
                        />
                        Excel Sheet
                      </ExcelExport>

                      <FileDownloadSelect>
                        <Icon
                          type={"file-empty"}
                          style={{
                            color: "#760000",
                            marginRight: 10,
                            borderRadius: 20,
                          }}
                        />
                        <PDFDownloadLink
                          document={<MyDoc data={excelExportData} />}
                          fileName={`download-summary-${new Date().getTime()}.pdf`}
                        >
                          {({ blob, url, loading, error }) =>
                            loading ? "Loading..." : "Pdf File"
                          }
                        </PDFDownloadLink>
                      </FileDownloadSelect>
                    </DownloadOptCont>
                  ) : (
                    <div>
                      <DownloadSec2>
                        {downloadFileOpt.map((elm) => (
                          <RadioButton
                            key={elm.label}
                            label={elm.label}
                            value={elm.value}
                            checked={elm.label === fileToDownload.value}
                            id={elm.label}
                            group={elm?.label}
                            onChange={() => {
                              setFileToDownload({ value: elm.label });
                            }}
                          />
                        ))}
                      </DownloadSec2>
                      <DownloadSec>
                        <InputHolder>
                          <LabelHolder>Start Date</LabelHolder>
                          <Input
                            name={`downloadStartDate`}
                            type={"date"}
                            onChange={(e: any) =>
                              setFilterDateParams(e, "Download Start Date")
                            }
                          />
                        </InputHolder>

                        <InputHolder>
                          <LabelHolder>End Date</LabelHolder>
                          <Input
                            name={`downloadStartDate`}
                            type={"date"}
                            onChange={(e: any) =>
                              setFilterDateParams(e, "Download End Date")
                            }
                          />
                        </InputHolder>
                      </DownloadSec>

                      <DownloadOptFooter>
                        <ResetDownloadButton
                          onClick={() => {
                            setOpenDownload(false);
                            setFileToDownload({ value: "Excel Sheet" });
                          }}
                        >
                          Cancel
                        </ResetDownloadButton>
                        {downloadData ? (
                          <ActionoloadButton
                            type='button'
                            onClick={() => {
                              setOpenDownload(false);
                              downloadData();
                            }}
                          >
                            Download
                          </ActionoloadButton>
                        ) : excelExport &&
                          fileToDownload.value === "Excel Sheet" ? (
                          <ExcelExport data={excelExportData}>
                            <ActionoloadButton
                              onClick={() => {
                                setOpenDownload(false);
                              }}
                              type='button'
                            >
                              Download
                            </ActionoloadButton>
                          </ExcelExport>
                        ) : excelExport &&
                          fileToDownload.value === "Pdf File" ? (
                          <ActionoloadButtonPdf>
                            <PDFDownloadLink
                              document={<MyDoc data={excelExportData} />}
                              fileName={`download-summary-${new Date().getTime()}.pdf`}
                            >
                              {({ blob, url, loading, error }) =>
                                loading ? "Loading..." : "Download"
                              }
                            </PDFDownloadLink>
                          </ActionoloadButtonPdf>
                        ) : null}
                      </DownloadOptFooter>
                    </div>
                  )}
                </PopUp>
              )}
            </PopUpCont>
          )}

        {withQueryBtn && (
          <ActionButton style={{ marginLeft: 15 }} onClick={queryTransaction}>
            Query status
          </ActionButton>
        )}

        {withPrintBtn && (
          <ActionButton
            style={{ marginLeft: 15 }}
            onClick={() => window.print()}
          >
            Print
          </ActionButton>
        )}

        {withShareBtn && (
          <ActionButton style={{ marginLeft: 15 }} onClick={logMessage}>
            Send Reciept
          </ActionButton>
        )}
      </LeftPane>
    </Header>
  );
};

export default DashboardSubHeader;
