import { useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";

import {
  ActionButton,
  ButtonContainer,
  Container,
  FormBody,
  FormContainer,
  FormDivider,
  FormRow,
  FormRowLeft,
  FormRowRight,
} from "./Styles";

import { Form } from "shared/components";
import { onChangeTextHandler, validations } from "shared/utils/formValidation";
import toast from "shared/utils/toast";

import { useHistory } from "react-router-dom";
import GradientLoader from "shared/components/GradientLoader/index2";
import RadioButton from "shared/components/RadioButton";
import SharedCheckList from "shared/section_components/SharedCheckList";
import shouldPerform, { getRoleName } from "shared/utils/assignRoles";
import consoleLog from "shared/utils/consoleLog";
import api from "shared/utils/api";

const ServiceSettings = ({ setVisible, setStep, setReload }: any) => {
  const business = useSelector((store: RootStateOrAny) => store.business);
  const service = useSelector((store: RootStateOrAny) => store.service);
  const currentUser = useSelector((store: RootStateOrAny) => store.currentUser);

  const dispatch = useDispatch();
  const history = useHistory();

  const [fstate, setFstate] = useState({} as any);
  const [loading, setLoading] = useState(false);

  const inputArray = [
    {
      id: "chargeSource",
      title: "Who should pay the transaction fees",
      type: "radio",
      options: [
        {
          label: "Customers",
          value: "CUSTOMER",
        },
        {
          label: "Charge me for the transaction fee from transaction",
          value: "MERCHANT",
        },
        {
          label: "Charge me for the transaction fee from wallet",
          value: "WALLET",
        },
      ],
    },
    {
      type: "divider",
      id: "divider",
      title: "divider",
    },
    {
      id: "methods",
      title: "What method of payment do you want?",
      type: "checkbox",
      options: [
        { label: "Card payment", name: "cardPayment", disabled: false  },
        { label: "Enable pay with QR code", name: "qrPay", disabled: false },
        { label: "Enable internet banking", name: "directDebit", disabled: false },
        { label: "Enable pay with transfer", name: "transfer", disabled: false },
        { label: "Pay directly from account", name: "directPay", disabled: true },
        { label: "Enable pay with wallet", name: "wallet", disabled: true },
        { label: "Enable pay with USSD", name: "ussd", disabled: true },
        { label: "Enable pay with Paga", name: "paga", disabled: true },
      ],
    },
    {
      type: "divider",
      id: "divider",
      title: "divider",
    },
    {
      id: "auhentication",
      title: "Two Factor Authentication",
      type: "radio",
      options: [
        {
          label: "Enable Two factor auth for transfers",
          value: "transferTwoFactorAuth",
        },
        {
          label: "Enable Two factor auth for login",
          value: "loginTwoFactorAuth",
        },
      ],
      useOptionValue: true,
    },
    {
      type: "divider",
      id: "divider",
      title: "divider",
    },
    {
      id: "balanceHistory",
      title: "Balance History",
      type: "checkbox",
      options: [
        {
          label: "Allow customers cancel subscriptions",
          name: "canCancelSubscription",
        },
      ],
    },
    {
      type: "divider",
      id: "divider",
      title: "divider",
    },
    {
      id: "transactionEmail",
      title: "Transaction Email",
      type: "checkbox",
      options: [
        { label: "Email me every tranactions", name: "emailMeTransaction" },
        {
          label: "Email customers every tranactions",
          name: "emailCustomerTransaction",
        },
      ],
    },
    {
      type: "divider",
      id: "divider",
      title: "divider",
    },
    {
      id: "notificationMails",
      title: "Send notification emails to team members",
      type: "radioOpt",
      options: [
        {
          label: "Send to business email address only",
          value: "BUSINESS_EMAIL",
        },
        { label: "Send to all dashboard users", value: "ALL_DASHBOARD_USERS" },
        { label: "Send to specific users only", value: "SPECIFIC_USERS" },
      ],
    },
    {
      type: "divider",
      id: "divider",
      title: "divider",
    },
    {
      id: "earningsCheckbox",
      title: "How do you want to get your earnigs?",
      type: "checkboxOpt",
      options: [
        {
          label: "Settle to bank account",
          name: "getEarnings",
          value: "SETTLE_TO_BANK_ACCOUNT",
        },
      ],
    },
    {
      type: "divider",
      id: "divider",
      title: "divider",
    },
    {
      id: "recieptsTransfer",
      title: "Transfer Receipts",
      type: "checkbox",
      options: [
        {
          label: "Send email receipts for successful transfer",
          name: "sendReceipt",
        },
      ],
    },
  ];

  const initialValues = () => {
    let obj: any = {};
    for (let elm of inputArray) {
      obj[elm.id] = "";
    }
    return obj;
  };

  const displayFormInput = () => {
    return inputArray.map((elm, idx) => {
      switch (elm.type) {
        case "divider":
          return <FormDivider key={idx} />;
        case "radio":
          return (
            <FormRow key={idx}>
              <FormRowLeft>{elm?.title}</FormRowLeft>
              <FormRowRight>
                {elm?.options?.map((item: any) => (
                  <RadioButton
                    label={item.label}
                    // checked={fstate[elm.id]===item}
                    value={item.value}
                    checked={item.value === fstate[elm.id]}
                    id={item.label}
                    group={elm?.id}
                    onChange={(val: any) => {
                      let itemValue = item.value;
                      let itemId = elm.id;
                      onChangeTextHandler(itemValue, itemId, fstate, setFstate);
                    }}
                  />
                ))}
              </FormRowRight>
            </FormRow>

            // <Form.Field.Ra
            //   key={`INP${idx}`}
            //   name={elm.id}
            //   label={elm.title}
            //   // options={elm.options()}
            //   onChange={(val: any) => {
            //     onChangeTextHandler(val, elm.id, fstate, setFstate);
            //   }}
            //   value={fstate[elm.id] ?? selectedValue()}
            // />
          );
        case "radioOpt":
          return (
            <FormRow key={idx}>
              <FormRowLeft>{elm?.title}</FormRowLeft>
              <FormRowRight>
                {elm?.options?.map((item: any, index: number) => (
                  <RadioButton
                    key={index}
                    label={item.label}
                    // checked={fstate[elm.id]===item}
                    value={item.value}
                    checked={item.value === fstate[elm.id]}
                    id={item.label}
                    group={elm?.id}
                    onChange={(val: any) => {
                      let itemValue =
                        item.value === fstate[elm.id] ? undefined : item.value;
                      onChangeTextHandler(itemValue, elm.id, fstate, setFstate);
                    }}
                  />
                ))}
              </FormRowRight>
            </FormRow>

            // <Form.Field.Ra
            //   key={`INP${idx}`}
            //   name={elm.id}
            //   label={elm.title}
            //   // options={elm.options()}
            //   onChange={(val: any) => {
            //     onChangeTextHandler(val, elm.id, fstate, setFstate);
            //   }}
            //   value={fstate[elm.id] ?? selectedValue()}
            // />
          );
        case "checkbox":
          return (
            <FormRow key={idx}>
              <FormRowLeft>{elm?.title}</FormRowLeft>
              <FormRowRight>
                {elm?.options?.map((item: any) => (
                  <SharedCheckList
                    label={item.label}
                    checked={!!fstate[item.name]}
                    id={item.name}
                    disabled={item.disabled}
                    onChange={(val: any) => {
                      onChangeTextHandler(
                        !fstate[item.name],
                        item.name,
                        fstate,
                        setFstate
                      );
                    }}
                  />
                ))}
              </FormRowRight>
            </FormRow>
          );

        case "checkboxOpt":
          return (
            <FormRow key={idx}>
              <FormRowLeft>{elm?.title}</FormRowLeft>
              <FormRowRight>
                {elm?.options?.map((item: any) => (
                  <SharedCheckList
                    label={item.label}
                    checked={fstate[item.name] == item.value}
                    id={item.name}
                    onChange={(val: any) => {
                      let itemValue =
                        item.value === fstate[item.name]
                          ? undefined
                          : item.value;

                      onChangeTextHandler(
                        itemValue,
                        item.name,
                        fstate,
                        setFstate
                      );
                    }}
                  />
                ))}
              </FormRowRight>
            </FormRow>
          );
      }
    });
  };

  // consoleLog(fstate, "fstate");
  const onSubmit = async () => {
    setLoading(true);
    try {
      let response;
      let arrUseOptionValue = inputArray.filter((el: any) => el.useOptionValue);
      // consoleLog(arrUseOptionValue, "Dataa");
      let sendObj: any = {};
      for (let el in fstate) {
        let findObj: any = arrUseOptionValue.find((elm: any) => elm?.id === el);
        // consoleLog(findObj, "dataaa");
        if (findObj && findObj?.id && fstate[el]) {
          sendObj[fstate[el]] = true;
          sendObj[findObj?.id] = undefined;
        }
      }

      for (const key in fstate) {
        let value = fstate[key];
        if (value === undefined) {
          delete fstate[key];
        }
      }

      consoleLog("DATA:", { ...fstate });

      //consoleLog("DATA:", { ...fstate, ...sendObj });
      if (fstate?.ussd || fstate?.paga || fstate?.wallet) {
        toast.error(
          "Transfer, USSD, Paga and Wallet payment channels are coming just very soon."
        );
        return;
      }
      if (!fstate?.cardPayment && !fstate?.qrPay && !fstate?.directDebit) {
        toast.error("You need to select atleast 1 method of payment");
        return;
      }
      // console.log("Settings: ", {
        // settings: { ...fstate, ...sendObj }
      // })
      response = await api.put(`/services/${service?.id}`, {
        settings: { ...fstate, ...sendObj },
      });
      setLoading(false);
      if (response.status === true) {
        toast.success(response.message);

        // await dispatch(setService({ ...service, ...fstate }));
        setVisible(false);
        setReload();
        history.go(0)
        //history.push("/my-apps/dashboard");
      } else {
        toast.error(response.message);
      }
      // consoleLog(response, "response");
    } catch (err) {
      setLoading(false);
      consoleLog(err, "error");
      // @ts-ignore
      toast.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // console.log("Service settings: ", service?.settings)
    if (service) {
      const mailNotif = service?.settings?.notificationMails;
      setFstate({
        chargeSource:  service?.settings?.chargeSource ?? false,
        chargeMe: service?.settings?.chargeMe ?? false,
        cardPayment: service?.settings?.cardPayment ?? false,
        directDebit: service?.settings?.directDebit ?? false,
        qrPay: service?.settings?.qrPay ?? false,
        transfer: service?.settings?.transfer ?? false,
        wallet: service?.settings?.wallet ?? false,
        ussd: service?.settings?.ussd ?? false,
        paga: service?.settings?.paga ?? false,
        transferTwoFactorAuth:
          service?.settings?.transferTwoFactorAuth ?? false,
        loginTwoFactorAuth: service?.settings?.loginTwoFactorAuth ?? false,
        canCancelSubscription:
          service?.settings?.canCancelSubscription ?? false,
        emailMeTransaction: service?.settings?.emailMeTransaction ?? false,
        emailCustomerTransaction:
          service?.settings?.emailCustomerTransaction ?? false,
        // notificationMails: service?.settings?.notificationMails ?? false,
        notificationMails:
          mailNotif === "BUSINESS_EMAIL_ADDRESS_ONLY"
            ? "BUSINESS_EMAIL"
            : mailNotif !== undefined || mailNotif !== null
            ? mailNotif
            : false,
        getEarnings: service?.settings?.getEarnings ?? false,
        sendReceipt: service?.settings?.sendReceipt ?? false,
        auhentication: service?.settings?.loginTwoFactorAuth
          ? "loginTwoFactorAuth"
          : service?.settings?.transferTwoFactorAuth
          ? "transferTwoFactorAuth"
          : undefined,
      });
    }
  }, []);

  // if (loading) return <GradientLoader />; ///look for another loader
  if (!shouldPerform(getRoleName(currentUser, business?.id), "CSettings")) {
    history.push("/authenticate");
    return null;
  }
  return (
    <>
      {loading ? (
        <GradientLoader />
      ) : (
        <Container>
          <FormContainer>
            <FormBody>
              {/* @ts-ignore */}
              <Form
                enableReinitialize
                initialValues={initialValues()}
                validations={validations(inputArray, Form)}
                onSubmit={onSubmit}
              >
                <>
                  {displayFormInput()}
                  <ButtonContainer>
                    <ActionButton onClick={onSubmit}>Save</ActionButton>
                  </ButtonContainer>
                </>
              </Form>
            </FormBody>
          </FormContainer>
        </Container>
      )}
    </>
  );
};

export default ServiceSettings;
