import { useEffect, useState } from "react";
import { Checkbox, Input } from "shared/components";
import { FilterInputCont, FilterParamsCont, FilterSec, FilterText, InputHolder, PopUpSubHead, TextDescription } from "shared/components/DashboardSubHeader/Styles";
import { DateRangePicker } from "rsuite";
import Daterange from "./Daterange";



type PropT = {
    startDate?: string; 
    endDate?: string;
    searchStr?: string;
    successfulStat?: boolean;
    failedStat?: boolean;
    terminatedStat?: boolean;
    cardChannel?: boolean;
    qrpayChannel?: boolean;
    transferChannel?: boolean;
    onProcessFilter: Function;
}
const AgencyTransactionFilter=({startDate, endDate, searchStr, successfulStat, failedStat, terminatedStat, cardChannel, qrpayChannel, transferChannel, onProcessFilter}: PropT)=>{
    const [start, setStart] = useState<string|undefined>(startDate);
    const [end, setEnd] = useState<string|undefined>(endDate);
    const [search, setSearch] = useState<string|undefined>(searchStr);
    const [successful, setSuccessful] = useState<boolean|undefined>(successfulStat);
    const [failed, setFailed] = useState<boolean|undefined>(failedStat);
    const [terminated, setTerminated] = useState<boolean|undefined>(terminatedStat);

    const [card, setCard] = useState<boolean|undefined>(cardChannel);
    const [qrpay, setQrpay] = useState<boolean|undefined>(qrpayChannel);
    const [transfer, setTransfer] = useState<boolean|undefined>(transferChannel);

    const onChangeTextHandler=()=>{
        //onProcessFilter({date:{start, end}, search, status:{successful, failed, terminated}, channel:{card, qrpay, internetBanking, directDebit}})
        // console.log(`Selected dates 2: ${start}, ${end}`)
        onProcessFilter({start, end, search, successful, failed, terminated, card, qrpay, transfer})
    }

    const handleSelection=(fromDate: string, toDate: string)=>{
        // console.log(`Selected dates 1: ${fromDate}, ${toDate}`)
        setEnd(toDate);
        setStart(fromDate)
        //onChangeTextHandler();
        // console.log(`${fromDate}, ${toDate}`)
        onProcessFilter({start: fromDate, end: toDate, search, successful, failed, terminated, card, qrpay, transfer})
        
    }
    
    return (
        <FilterSec>
            <PopUpSubHead>Search transaction</PopUpSubHead>
            <FilterText>
                <TextDescription>Search</TextDescription>
                <Input
                  name={"search"}
                  type="text"
                  placeholder="Terminal ID, Agent name, customer name, account number..."
                  value={search}
                  onChange={(e: any) => {// console.log("Inputs: ", e);setSearch(e); onChangeTextHandler();
                }}
                />
              </FilterText>

            <PopUpSubHead>Date range</PopUpSubHead>
            <FilterInputCont>
                <div>
                    <Daterange handleSelection={handleSelection} />
                </div>
            </FilterInputCont>

            <PopUpSubHead>Payment channels</PopUpSubHead>
            <FilterParamsCont>
                <Checkbox
                    onChange={(e: any) => {setCard(card); onChangeTextHandler();}}
                    label={"Card"}
                    value={card}
                    name={"card"}
                ></Checkbox>
                <Checkbox
                    onChange={(e: any) => {setQrpay(qrpay); onChangeTextHandler();}}
                    label={"QR Pay"}
                    value={qrpay}
                    name={"qrpay"}
                ></Checkbox>
                <Checkbox
                    onChange={(e: any) => {setTransfer(transfer); onChangeTextHandler();}}
                    label={"Transfer"}
                    value={transfer}
                    name={"transfer"}
                ></Checkbox>
            </FilterParamsCont>

            <PopUpSubHead>Transaction status</PopUpSubHead>
            <FilterParamsCont>
                <Checkbox
                    onChange={(e: any) => {alert(successful); setSuccessful(successful); onChangeTextHandler();}}
                    label={"Successful"}
                    value={successful}
                    name={"successful"}
                ></Checkbox>
                <Checkbox
                    onChange={(e: any) => {setFailed(failed); onChangeTextHandler();}}
                    label={"Failed"}
                    value={failed}
                    name={"failed"}
                ></Checkbox>
                <Checkbox
                    onChange={(e: any) => {setTerminated(terminated); onChangeTextHandler();}}
                    label={"Terminated"}
                    value={terminated}
                    name={"terminated"}
                ></Checkbox>
            </FilterParamsCont>
        </FilterSec>
    );
}

export default AgencyTransactionFilter;