import MainLayout from "./Layouts/MainLayout"
import NewMail from "./pages/NewMail"
import Questions from "./pages/Questions"
import Recovery from "./pages/Recovery"
import ResetPassword from "./pages/ResetPassword"
import SetPassword from "./pages/SetPassword"




const routes = [
  {
    path: '/recovery',
    component: Recovery,
    layout: MainLayout
  },
  {
    path: '/recovery/questions',
    component: Questions,
    layout: MainLayout
  },
  {
    path: '/recovery/email',
    component: NewMail,
    layout: MainLayout
  },
  {
    path: '/recovery/set-password',
    component: SetPassword,
    layout: MainLayout
  },
  {
    path: '/recovery/reset-password',
    component: ResetPassword,
    layout: MainLayout
  },
]


export default  routes