import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
`;

export const Header = styled.div`
  width: 100%;
  height: 300px;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  padding: 0px 10px;
  border: 0.56px solid #ccc;
  border-radius: 4px;

  & > div {
    margin: 30px 0px 0px 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
    & > div {
      margin: 5% 0px 0px 20px;
    }
  }

  & > div:nth-child(2) {
    width: 40%;
    margin: 6% 0px 0px 20px;
    display: block !important;
  }
`;
export const ActionArea = styled.div`
  margin-top: 0.4rem;
  width: 20%;
  height: auto;
  display: flex;
  flex-direction: row;
  //flex-wrap: wrap;
  gap: 10px;
`;

export const Content = styled.div`
  width: 98%;
  height: auto;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-between;
  padding: 10px;
  border-radius: 5px;

  & > :first-child {
    width: 100%;
  }
`;
export const AccountOfficer = styled.div`
  width: 40%;
  margin-top: 10px;
  padding-right: 10px;
`;

export const Profile = styled.div`
  margin-top: 5%;
  display: flex;
  flex-direction: row;
  gap: 5px;
  justify-content: space-between;
  & > :nth-child(2) {
    padding-top: 5%;
  }
`;
export const AssignOfficer = styled.div`
  display: flex !important;
  width: 100%;
  flex-direction: column;
  align-items: center;
`;

export const InputContainer =  styled.div`
  background-color: #eee;
  /* height: 40px; */
  width: 100%;
  padding: 10px;
  margin-bottom: 5px;
  text-transform: capitalize;
  &label{
    margin-bottom: 5px;
    font-size: 12px;
  }
`;