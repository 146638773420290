import Login from "Auth/pages/Login";
import { useEffect, useState } from "react";
import { Modal } from "shared/components";
import consoleLog from "shared/utils/consoleLog";

export default ({ children, chgValue }: any) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    setVisible(true);
  }, [chgValue]);

  consoleLog(Math.random());
  return (
    <>
      <Login />
      {/* the children will come up as a modal */}
      <Modal
        isOpen={visible}
        minWidth={window.innerWidth >= 480 ? "480px" : undefined}
        withCloseIcon={true}
        onClose={() => {
          setVisible(false);
        }}
        overFlow={true}
        containerWidth={window.innerWidth <= 480}
        renderContent={() => <div style={{ padding: "20px" }}>{children}</div>}
      />
    </>
  );
};
