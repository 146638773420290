import React, { useState } from "react";
import moment from "moment";
import { times, range } from "lodash";

import { formatDate, formatDateTimeForAPI } from "shared/utils/dateTime";
import Icon from "shared/components/Icon";

import {
  DateSection,
  YearSelect,
  SelectedMonthYear,
  Grid,
  PrevNextIcons,
  DayName,
  Day,
} from "./Styles";
import consoleLog from "shared/utils/consoleLog";

// const propTypes = {
//   withTime: PropTypes.bool,
//   value: PropTypes.string,
//   onChange: PropTypes.func.isRequired,
//   setDropdownOpen: PropTypes.func.isRequired,
// };

type PropT = {
  withTime: boolean;
  value?: string;
  onChange: ({}) => void;
  setDropdownOpen: (x: boolean) => void;
};

const defaultProps = {
  withTime: true,
  value: undefined,
};

const DatePickerDateSection = ({
  withTime,
  value,
  onChange,
  setDropdownOpen,
}: PropT) => {
  const [selectedMonth, setSelectedMonth] = useState(
    moment(value).startOf("month")
  );

  const handleYearChange = (year: string | number) => {
    let val: any = selectedMonth.set({ hour: 0, minute: 0 });
    let oldDate: string = String(formatDateTimeForAPI(val));
    let newVal = String(year).trim() + oldDate.trim().substring(4);
    let useVal: any = moment(newVal);
    consoleLog(newVal, "nyear");
    consoleLog(moment(newVal), "nyear");
    // setSelectedMonth(moment(selectedMonth).set({ year: Number(year) }));
    onChange(newVal);
    setSelectedMonth(useVal);
  };

  const handleMonthChange = (addOrSubtract: string) => {
    if (addOrSubtract == "subtract") {
      setSelectedMonth(moment(selectedMonth).subtract(1, "month"));
    } else {
      setSelectedMonth(moment(selectedMonth).add(1, "month"));
    }
  };

  const handleDayChange = (newDate: any) => {
    const existingHour = value ? moment(value).hour() : "00";
    const existingMinute = value ? moment(value).minute() : "00";

    const newDateWithExistingTime = newDate.set({
      hour: existingHour,
      minute: existingMinute,
    });
    consoleLog(formatDateTimeForAPI(newDateWithExistingTime), "yyear");
    onChange(formatDateTimeForAPI(newDateWithExistingTime));

    if (!withTime) {
      setDropdownOpen(false);
    }
  };

  return (
    <DateSection>
      <SelectedMonthYear>
        {formatDate(selectedMonth, "MMM YYYY")}
      </SelectedMonthYear>

      <YearSelect onChange={(event) => handleYearChange(event.target.value)}>
        {generateYearOptions().map((option) => (
          <option key={option.label} value={option.value}>
            {option.label}
          </option>
        ))}
      </YearSelect>

      <PrevNextIcons>
        <Icon type="arrow-left" onClick={() => handleMonthChange("subtract")} />
        <Icon type="arrow-right" onClick={() => handleMonthChange("add")} />
      </PrevNextIcons>

      <Grid>
        {generateWeekDayNames().map((name) => (
          <DayName key={name}>{name}</DayName>
        ))}
        {generateFillerDaysBeforeMonthStart(selectedMonth).map((i) => (
          <Day key={`before-${i}`} isFiller />
        ))}
        {generateMonthDays(selectedMonth).map((date) => (
          <Day
            // @ts-ignore
            key={date}
            isToday={moment().isSame(date, "day")}
            isSelected={moment(value).isSame(date, "day")}
            onClick={() => handleDayChange(date)}
          >
            {formatDate(date, "D")}
          </Day>
        ))}
        {generateFillerDaysAfterMonthEnd(selectedMonth).map((i) => (
          <Day key={`after-${i}`} isFiller />
        ))}
      </Grid>
    </DateSection>
  );
};

const currentYear = moment().year();

const generateYearOptions = () => [
  { label: "Year", value: "" },
  ...times(50, (i) => ({
    label: `${i + currentYear - 10}`,
    value: `${i + currentYear - 10}`,
  })),
];

const generateWeekDayNames = () => moment.weekdaysMin(true);

const generateFillerDaysBeforeMonthStart = (selectedMonth: any) => {
  const count = selectedMonth.diff(
    moment(selectedMonth).startOf("week"),
    "days"
  );
  return range(count);
};

const generateMonthDays = (selectedMonth: any) =>
  times(selectedMonth.daysInMonth()).map((i: any) =>
    moment(selectedMonth).add(i, "days")
  );

const generateFillerDaysAfterMonthEnd = (selectedMonth: any) => {
  const selectedMonthEnd = moment(selectedMonth).endOf("month");
  const weekEnd = moment(selectedMonthEnd).endOf("week");
  const count = weekEnd.diff(selectedMonthEnd, "days");
  return range(count);
};

// DatePickerDateSection.propTypes = propTypes;
DatePickerDateSection.defaultProps = defaultProps;

export default DatePickerDateSection;
