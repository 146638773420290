import React, { useState, useEffect, useLayoutEffect } from "react";

import AddSubAgent from "./AddSubAgent";
import { Redirect, Route, Router, Switch, useHistory } from "react-router-dom";
import AgentLists from "./AgentLists";
import AgentDetail from "./AgentDetail";
import { PageError } from "shared/components";
import { getStoredAuthToken } from "shared/utils/authToken";
import AssignTerminal from "./AgentDetail/AssignTerminal";

function Index() {
  const history = useHistory();
  useLayoutEffect(() => {
    const token = getStoredAuthToken();
    if (!token) {
      history.push("/authenticate");
    }
  }, []);
  return (
    <>
      <Router history={history}>
        <Switch>
          <Route exact path="/agency/agents/add-new" component={AddSubAgent} />
          <Route exact path="/agency/agents" component={AgentLists} />
          <Route
            exact
            path="/agency/agents/update/:id"
            component={AddSubAgent}
          />

          <Route
            exact
            path="/agency/agents/detail/:id"
            component={AgentDetail}
          />
          {/* <Route
            exact
            path="/agency/agents/detail/:id/assign-terminals"
            component={AssignTerminal}
          /> */}
          <Route
            exact
            path="/agency/agents/detail/:id/:tab/:ext"
            component={AgentDetail}
          />

          <Route
            exact
            path="/agency/agents/detail/:id/:tab"
            component={AgentDetail}
          />

          <Route component={PageError} />
        </Switch>
      </Router>
    </>
  );
}

export default Index;
