import breakpoints from 'shared/utils/breakpoints'
import { color } from 'shared/utils/styles'
import styled from 'styled-components'

export const Container = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	overflow: auto;
`

export const Button = styled.button<ButtonPropT>`
	width: 138px;
	height: 54px;
	background: ${(prop) =>
		prop.isActive ? color.fountain : color.backgroundLight};
	border-radius: 5px;
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 21px;
	color: #ffffff;
	cursor: ${(prop) => (prop.isActive ? 'pointer' : 'not-allowed')};
`

export const Image = styled.img`
	object-fit: center;
	pointer-events: none;
	@media ${breakpoints.device.max.xs} {
		display: none;
	}
`

export const FormContainer = styled.div`
	margin: 30px 0 30px 45px;
	justify-content: flex-start;
	@media ${breakpoints.device.max.xl} {
		margin: 20px 15px 30px 15px;
	}
`

export const TitleCase = styled.div`
	font-style: normal;
	font-weight: 700;
	font-size: 24px;
	line-height: 28px;
	letter-spacing: -0.02em;
	/* Primary Black */

	color: #292929;
`

export const SubTitle = styled.div`
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	/* width: 382px; */
	margin-top: 5px;

	/* Grey Text 1 */

	color: #828282;
`

export const FormBody = styled.div`
	margin-top: 20px;
`

export const ButtonContainer = styled.div`
	justify-content: space-between;
	display: flex;
	flex-direction: row;
	margin-top: 16px;
`
export const Back = styled.div`
	font-family: 'KumbhSansNormal';
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 21px;
	/* identical to box height */
	margin-top: 16px;
	cursor: pointer;
	margin-top: -5px;

	/* Grey Text 1 */

	color: #828282;
`

export const ActionButton = styled(Button)`
	margin: 1em 0;
	color: #fff;
	width: 138px;
	height: 54px;
	float: right;
	background-color: ${color.fountain};
`

type ButtonPropT = {
	isActive?: boolean
}

export const Background = styled.div`
	position: fixed;
	z-index: 9999;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: auto; /* Enable scroll if needed */
	background-color: rgba(0, 0, 0, 0.2);
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 100px;
`

export const ContChild1 = styled.div`
	width: 92%;
	/* height: 100%; */
	overflow-y: scroll;
	background: #fbfbfb;
	box-shadow: 0px 4px 100px rgba(0, 0, 0, 0.04);
	border-radius: 20px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding-bottom: 8rem;
`

export const ContChild2 = styled.button`
	width: 54px;
	height: 54px;
	background: #ffffff;
	border-radius: 50px;
	border: none;
	outline: none;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
`

export const FormHolder = styled.div`
	width: 80%;
	height: 100%;
	padding: 4rem 40px 20rem 40px;
	margin-bottom: 4rem;
`

export const ImageHolder = styled.div`
	width: 20%;
	height: 100%;
`

export const FormTitle = styled.p`
	font-style: normal;
	font-weight: 700;
	font-size: 24px;
	line-height: 28px;
	letter-spacing: -0.02em;
	color: #292929;
	margin-bottom: 15px;
`

export const FormPara = styled.p`
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	color: #828282;
	width: 68%;
`

export const Img1 = styled.img`
	width: 100%;
`

export const InputCont = styled.div`
	width: 100%;
	margin: 2rem 0px 15px 0px;
`

export const InputLabel = styled.p`
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	color: #292929;
	margin-bottom: 15px;
`

export const Dropdown = styled.div`
	position: relative;
	display: block;
	width: 100%;
	border: 1px solid #d7d7d7;
	background: #ffffff;
	border-radius: 5px;
	&:hover {
		transition: ease-in-out 0.5s;
		border: 1px solid lightblue;
	}
	&:focus {
		border: 1px solid lightblue;
	}
`

export const DropdownBtn = styled.button`
	display: flex;
	justify-content: flex-end;
	height: 54px;
	width: 100%;
	background-color: #fff;
	color: white;
	padding: 16px;
	font-size: 16px;
	border: none;
	cursor: pointer;
	border-radius: 5px;
`

export const DropdownContent = styled.div`
	width: 100%;
	position: absolute;
	background-color: rgba(255, 255, 255, 0.9);
	min-width: 160px;
	box-shadow: 0px 8px 16px 0px rgba(255, 255, 255, 0.9);
	z-index: 999;
	height: 200px;
	overflow: hidden;
	overflow-y: scroll;
`

export const DropdownItem = styled.div`
	color: black;
	padding: 12px 16px;
	display: block;
	cursor: pointer;
`

export const DropdownText = styled.p`
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	color: #292929;
`

export const InputEntry = styled.input`
	padding: 0px 10px;
	height: 54px;
	width: 100%;
	background: #ffffff;
	border: 1px solid #d7d7d7;
	border-radius: 5px;
	&:hover {
		transition: ease-in-out 0.5s;
		border: 1px solid lightblue;
	}
	&:focus {
		border: 1px solid lightblue;
	}
`

export const LastInputCont = styled.div`
	margin: 20px 0px;
`

export const LastInputContChild = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
`

export const LastInputContLabel = styled.p`
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 19px;
	color: #292929;
`

export const ButtonCont = styled.div`
	margin-top: 20px;
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
`

export const ImageDiv = styled.div`
	@media ${breakpoints.device.max.xs} {
		display: none;
	}
`
export const Break = styled.br`
	@media ${breakpoints.device.max.xs} {
		display: none;
	}
`
