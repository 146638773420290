import React from 'react';

import {Container} from  './styles';
interface PropT{
  top?: number;
  left?: number;
  right?: number;
}

const index=({top, left, right}: PropT)=> {
  return (
    <Container top={top} left={left}>
        <svg 
        xmlns="http://www.w3.org/2000/svg" 
        style={{margin: 'auto', background: 'rgba(255, 255, 255, 0) none repeat scroll 0% 0%', display: 'block', shapeRendering: 'auto'}} 
        width={"200px"} 
        height={"200px"} 
        viewBox="0 0 100 100" 
        preserveAspectRatio="xMidYMid">
          <g transform="translate(20 50)">
            <circle cx="0" cy="0" r="6" fill="rgba(118, 0, 0, 0.1016129032258064)">
              <animateTransform attributeName="transform" type="scale" begin="-0.5597014925373134s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1.4925373134328357s" repeatCount="indefinite"></animateTransform>
            </circle>
          </g>
          <g transform="translate(40 50)">
            <circle cx="0" cy="0" r="6" fill="rgba(118, 0, 0, 0.31967741935483873)">
              <animateTransform attributeName="transform" type="scale" begin="-0.3731343283582089s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1.4925373134328357s" repeatCount="indefinite"></animateTransform>
            </circle>
          </g>
          <g transform="translate(60 50)">
            <circle cx="0" cy="0" r="6" fill="rgba(118, 0, 0, 0.7306451612903225)">
              <animateTransform attributeName="transform" type="scale" begin="-0.18656716417910446s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1.4925373134328357s" repeatCount="indefinite"></animateTransform>
            </circle>
          </g>
          <g transform="translate(80 50)">
            <circle cx="0" cy="0" r="6" fill="#760000">
              <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1.4925373134328357s" repeatCount="indefinite"></animateTransform>
            </circle>
          </g>
        </svg>
    </Container>
  )
};

export default index;