import styled from "styled-components";

type PropT = {
  height?: string;
  width?: string;
};

export const ImageContainer = styled.div``;

export const ImagePlaceholder = styled.div<PropT>`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
`;
