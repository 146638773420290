import React, { useEffect, useState } from "react";
import { Button, Form } from "shared/components";
import {
  ActionButton,
  ComponentBody,
  ComponentFooter,
  FormElement,
  StyledLink,
  Title,
} from "./Styles";
import { getStoredAuthToken, storeAuthToken } from "shared/utils/authToken";
import toast from "shared/utils/toast";
import { useHistory } from "react-router-dom";
import api from "shared/utils/api";
import { authPath } from "apiServices/paths";
import BackLogo from "App/assets/images/Auth/Back.svg";
import { BackImage, Back } from "../AccountRecovery/Styles";
import consoleLog from "shared/utils/consoleLog";
import LoginFace2 from "shared/components/LoginFace/index2";

const ResetPassword = () => {
  const [isLoginIn, setIsLoginIn] = useState(false);
  const history = useHistory();

  const handleBack = () => {
    history.goBack();
  };
  const onSubmit = async (values: any, form: any) => {
    consoleLog(values);
    setIsLoginIn(true);

    try {
      const { payload }: any = await api.post(
        "/authentication/forgot-password",
        {
          ...values,
        }
      );
      consoleLog("reset password screen", payload);
      localStorage.setItem("tempId", payload.tempId);
      setIsLoginIn(false);
      // toast.success("Login was successful.");
      history.push(`${authPath.RESET_OTP}/1`);
    } catch (error) {
      // toast.error(error);
      setIsLoginIn(false);
    }
  };
  return (
    <LoginFace2>
      <ComponentBody>
        <Back onClick={handleBack}>
          <BackImage src={BackLogo} />
        </Back>
        <Title>RESET PASSWORD</Title>
        {/* @ts-ignore */}
        <Form
          enableReinitialize
          initialValues={{
            email: "",
          }}
          validations={{
            email: [Form.is.required(), Form.is.email()],
          }}
          onSubmit={onSubmit}
        >
          <FormElement>
            <Form.Field.Input
              name="email"
              label="Enter Email address"
              // tip="Kindly enter your user email address."
            />

            <ActionButton type="submit" variant="primary" isWorking={isLoginIn}>
              Send Request
            </ActionButton>
          </FormElement>
        </Form>
      </ComponentBody>
    </LoginFace2>
  );
};

export default ResetPassword;
