import { useEffect } from "react";
import { useHistory } from "react-router-dom";

import Joyride, { CallBackProps } from "react-joyride";
import { useAppContext } from "./context";
import consoleLog from "shared/utils/consoleLog";

const Tour = () => {
  const history = useHistory();

  const {
    setState,
    state: { run, stepIndex, steps, tourActive },
  } = useAppContext();

  const handleCallback = (data: CallBackProps) => {
    const { action, index, lifecycle, type } = data;
    // alert(JSON.stringify(data));
    if (
      type === "step:after" &&
      (index === 0 /* or step.target === '#home' */ ||
        (action === "prev" && index === 2))
    ) {
      setState({ run: false });

      history.push("/overview");
    } else if (type === "step:after" && index === 1) {
      if (action === "next") {
        setState({ run: false });
        history.push("/customers");
      } else {
        history.push("/charge-backs");
        setState({ run: true, stepIndex: 0 });
      }
    } else if (action === "reset" || lifecycle === "complete") {
      setState({ run: false, stepIndex: 0, tourActive: false });
    }
  };

  const joyrideCallback = (data: CallBackProps) => {
    const { action, index, lifecycle, type } = data;
    consoleLog(data, "joyride");
    if (action === "reset") {
      setState({ run: false, tourActive: false, steps: [] });
    }
  };

  useEffect(() => {
    // setState({
    //   steps: [
    //     {
    //       target: "body",
    //       content: (
    //         <>
    //           <h2>Welcome to our tour guide</h2>
    //           <p>
    //             When you click "next", it will stop the tour, navigate to route
    //             A, and continue the tour.
    //           </p>
    //         </>
    //       ),
    //       locale: { Skip: <strong>SKIP</strong> },
    //       placement: "bottom",
    //       disableBeacon: true,
    //     },
    //     {
    //       target: "#overview",
    //       content: (
    //         <>
    //           <h2>This is the home page</h2>
    //           <p>
    //             When you click "next", it will stop the tour, navigate to route
    //             A, and continue the tour.
    //           </p>
    //         </>
    //       ),
    //       locale: { Skip: <strong>SKIP</strong> },
    //       placement: "bottom",
    //     },
    //     {
    //       target: "#myApps",
    //       content: (
    //         <>
    //           <h2>This is Route A</h2>
    //           <p>
    //             The loader that appeared in the page was a simulation of a real
    //             page load, and now the tour is active again
    //           </p>
    //         </>
    //       ),
    //       locale: { Skip: <strong>SKIP</strong> },
    //       placement: "bottom",
    //     },
    //     {
    //       target: "#customers",
    //       content: (
    //         <>
    //           <h2>This is Route B</h2>
    //           <p>
    //             Yet another loader simulation and now we reached the last step
    //             in our tour!
    //           </p>
    //         </>
    //       ),
    //       locale: { Skip: <strong>SKIP</strong> },
    //       placement: "bottom",
    //     },
    //   ],
    // });
  }, [tourActive]);

  return (
    <Joyride
      // callback={handleCallback}
      callback={joyrideCallback}
      continuous
      run={run}
      scrollToFirstStep
      showProgress
      hideCloseButton
      hideBackButton
      // showSkipButton
      // stepIndex={1}
      steps={tourActive ? steps : []}
      styles={{
        options: {
          arrowColor: "#000",
          backgroundColor: "#F5DDE0",
          overlayColor: "rgba(0, 0, 0, 0.1)",
          primaryColor: "purple",
          textColor: "#000",
          zIndex: 999999999999,
        },
      }}
    />
  );
};

export default Tour;
