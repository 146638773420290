import { Button, Form } from "shared/components";
import breakpoints from "shared/utils/breakpoints";
import { color, font } from "shared/utils/styles";
import styled, { css } from "styled-components";

type PropTs = {
  isActive?: boolean;
  variant?: string;
};
export const Container = styled.div`
  width: 60%;
  display: block;;
`
export const FormGroup = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
`

export const PlusIcon = styled.span`
  font-size: 1.5rem;
  color: ${color.fountain};
  position: relative;
  padding: 0px 10px;
  padding-top: 40px;
`;


export const PricingForm = styled.form`
  margin-top: 2rem;
  padding: 2rem 4rem;
`;


export const FormInputGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.6rem;
  width: 100%;
  margin-bottom: 2rem;
`;
export const InputLabel = styled.label`
  color: ${color.textMedium};
`;

export const FormInput = styled.input`
  width: 100%;
  padding: 1rem 0.4rem;
  background: ${color.backgroundLight};
  outline-style: none;
  border: none;
  border-radius: 0.4rem;
`;

export const ModalBtn = styled(Button)<PropTs>`
  border-radius: 0.4rem;
  width: 100%;
  color: #fff;
  background: ${(props)=>props.variant || color.fountain};
  // padding: 1rem 2rem;
  height: 55px;
  margin-bottom: 20px;
  cursor: pointer;
  :hover {
    background: ${color.fountainAccent};
  }
`;
