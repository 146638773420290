import styled from "styled-components";
import { color, font } from "shared/utils/styles";
import breakpoints from "shared/utils/breakpoints";
import { Button, Form } from "shared/components";
import { Link } from "react-router-dom";

export const ComponentBody = styled.div`
  width: 80%;
  margin: 2em auto;
  margin-top: 0.1em;
`;

export const ActionButton = styled(Button)`
  margin: 2em 0;
  color: #fff;
  width: 100%;
  background-color: ${color.fountain};
`;

export const FormElement = styled(Form.Element)`
  color: black;
`;

export const StyledLink = styled(Link)`
  color: ${color.fountain} !important;
  ${font.size(14)}
`;

export const ComponentFooter = styled.p`
  color: #292929;
  ${font.size(14)}
`;

export const Title = styled.h1`
  color: ${color.fountain};
  ${font.size(24)}
`;

export const PasswordCont = styled.div`
  position: relative;
`;

export const FormRow = styled.div`
  /* display: flex; */
  /* justify-content: space-between;
  align-items: flex-start; */
  /* width: 400px; */
  /* margin-bottom: 15px; */
  @media ${breakpoints.device.max.xs} {
    /* flex-direction: column; */
    width: 100%;
  }
`;

export const FormRowLeft = styled.div`
  text-align: left;
  padding-top: 15px;
  margin-bottom: 15px;
`;

export const FormRowRight = styled.div`
  /* text-align: right; */
  /* width: 300px; */
  @media ${breakpoints.device.max.xs} {
    width: 100%;
  }
`;

export const Back = styled.div`
  width: fit-content;
  cursor: pointer;
`;

export const BackImage = styled.img`
  position: relative;
  top: 0px;
`;
export const OverflowDiv = styled.div`
  max-height: 50vh;
  overflow-y: auto;
`;
