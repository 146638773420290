import styled from "styled-components";

export const Edit = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #760000;
  color: #ffffff;
  border-radius: 20px;
  width: 80px;
  height: 30px;
  p {
    /* margin-left: 8px; */
    color: white;
    font-size: 12px;
  }
`;
