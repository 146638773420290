import React, { useState, useEffect, useLayoutEffect } from "react";
import { Redirect, Route, Router, Switch, useHistory } from "react-router-dom";

import { PageError } from "shared/components";
import { getStoredAuthToken } from "shared/utils/authToken";
import Inventory from "./inventory";
import AgencyAd from "Agency/Ad";
import { RootStateOrAny, useSelector } from "react-redux";
import CreateAsset from "./inventory-assets/create-asset";
import AssetsOverview from "./inventory-assets/assets-overview";

function InventoryRoutes() {
  const [business, setbusiness] = useState<any>();
  const currentBusiness = useSelector(
    (store: RootStateOrAny) => store.business
  );
  const history = useHistory();

  const KycTypeGuard = (intendedComponent: any, kycType: string) => {
    if (business?.BusinessKyc?.length === 1) {
      if (
        business?.BusinessKyc &&
        business?.BusinessKyc[0] &&
        business?.BusinessKyc[0]?.type === kycType
      ) {
        return intendedComponent;
      } else {
        return AgencyAd;
      }
    } else {
      return intendedComponent;
    }
  };

  useLayoutEffect(() => {
    const token = getStoredAuthToken();
    if (!token) {
      history.push("/authenticate");
    }
  }, []);
  return (
    <>
      <Router history={history}>
        <Switch>
          <Route exact path="/agency/inventory" component={Inventory} />
          <Route
            path="/agency/inventory/asset/create"
            exact
            component={KycTypeGuard(CreateAsset, "AGENCY")}
          />
          <Route
            exact
            path="/agency/inventory/asset/overview"
            component={KycTypeGuard(AssetsOverview, "AGENCY")}
          />

          <Route
            exact
            path="/agency/inventory/asset/overview/:id"
            component={KycTypeGuard(AssetsOverview, "AGENCY")}
          />

          <Route component={PageError} />
        </Switch>
      </Router>
    </>
  );
}

export default InventoryRoutes;
