import React from "react";
import icon from "./icon.png";
import {
  Container,
  Dot,
  FirstContainer,
  LoaderContainer,
  SecondContainer,
  SpinImage,
} from "./Styles";

export default function Preloader() {
  return (
    <>
      <Container>
        <LoaderContainer>
          <FirstContainer>
            <Dot></Dot>
          </FirstContainer>
          <SecondContainer>
            <SpinImage src={icon} alt="" />
          </SecondContainer>
        </LoaderContainer>
      </Container>
    </>
  );
}
