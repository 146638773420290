import { useEffect, useState } from "react";
import Table from "shared/components/Table";
import api from "shared/utils/api";
import toast from "shared/utils/toast";
import { AccountOfficer, AssignOfficer, Profile } from "./Style";
import { GradientLoader, Icon, ProfilePicture } from "shared/components";
import { ActionButton } from "../AddSubAgent/styles";
import { useHistory, useParams } from "react-router-dom";

type Props = {
  agentId: string;
  businessId: string;
  terminalId?: string;
};
const Transactions = ({ agentId, businessId, terminalId }: Props) => {
  const [agentTransactions, setAgentTransactions] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);

  const history = useHistory();

  const terminalColumnHeaders = [
    { id: "createdAt", label: "Date" },
    { id: "refrence", label: "Refrence No" },
    { id: "terminalId", label: "Terminal ID" },
    { id: "amount", label: "Amount" },
    { id: "charge", label: "Charges" },
    { id: "settleAmount", label: "Remitted Amount" },
    { id: "channel", label: "Channel" },
    { id: "type", label: "Type" },
    { id: "status", label: "Status" },
  ];

  const getMappedTerminalsTransaction = async () => {
    setIsLoading(true);
    try {
      //  let payload = {
      //    business_id: business.id,
      //    page: page,
      //    page_size: limit,
      //  };

      let payload = {
        business_id: businessId,
        agent_id: agentId,
        page: page,
        page_size: limit,
        terminalId: terminalId,
      };
      
      const res = await api.post(`/agency-banking/transactions`, payload);
      // console.log("get transactions payload==>", res);
      if (res.status) {
        setAgentTransactions(res.payload);
      }
    } catch (err) {
      const error: any = err;
      // console.log(error);
      toast.error(error?.message);
    } finally {
      setAgentTransactions(false);
    }
  };

  const viewTransactionDetailFunction = (obj: any) => {
    history.push(`/agency/agents/${agentId}/${obj.id}`);
  };

  useEffect(() => {
    getMappedTerminalsTransaction();
  }, []);
  return (
    <>
      {isLoading && <GradientLoader />}
      {!isLoading && 
        <Table
          headData={terminalColumnHeaders}
          bodyData={agentTransactions?.data ?? []}
          hoverLink
          serialNumber
          clickFunction={viewTransactionDetailFunction}
          totalPages={agentTransactions?.totalPages ?? 0}
          currentpage={agentTransactions?.currentPage ?? 0}
          emptyStateMessage="No transaction yet from this agent"
        />
      }
    </>
  );
};

export default Transactions;
