import { any } from "cypress/types/bluebird";
import { format } from "date-fns";
import moment from "moment";
import { RootStateOrAny, useSelector } from "react-redux";
import consoleLog from "shared/utils/consoleLog";

// import { ROLES, MENUS } from "./sitemap";
// import { getCurrentUser } from "shared/utils/authToken";

// export const checkRoles = (role: any) => {
//   // const filter=roles.filter(x:any => x.code===role);
//   const filter = ROLES.filter((therole: any) => therole.code === role);
//   return filter && filter.length > -1 ? true : false;
// };

// export const privilege = (role: any) => {
//   const currentUser = getCurrentUser();
//   if (!currentUser.permissions) return false;
//   return currentUser.persistStore[role];
// };

// export const getRole = (role: any) => {
//   const filter = ROLES.filter((therole: any) => therole.code === role);
//   // consoleLog(filter);
//   return filter && filter.length > -1 ? filter[0].name : false;
// };

// export const getUserMenus = (role: any) => {
//   const filter = MENUS.filter((menu: any) => menu.roles.includes(role));
//   if (filter.length > -1) {
//     return filter;
//   }
//   return false;
// };

export const formateDateString = (dateStr: string) => {
  var date = new Date(dateStr);
  var test = date.toISOString();
  return moment(test, "YYYYMMDD");
};

export const formateDate = (dateStr: string) => {
  return moment(dateStr).format("MM/DD/YYYY");
};

export const tableToExcel = () => {};

const convertToCSV = (objArray: any) => {
  var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
  var str = "";

  for (var i = 0; i < array.length; i++) {
    var line = "";
    for (var index in array[i]) {
      if (line != "") line += ",";

      line += array[i][index];
    }

    str += line + "\r\n";
  }

  return str;
};

export const exportCSVFile = async (
  headers: any,
  items: any,
  fileTitle: any
) => {
  if (headers) {
    items.unshift(headers);
  }

  // Convert Object to JSON
  var jsonObject = JSON.stringify(items);

  var csv = await convertToCSV(jsonObject);

  var exportedFilenmae = fileTitle + ".csv" || "export.csv";

  var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  if ((window.navigator as any)?.msSaveBlob) {
    // IE 10+
    (window.navigator as any)?.msSaveBlob(blob, exportedFilenmae);
  } else {
    var link = document.createElement("a");
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      var url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", exportedFilenmae);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};

export const getDates = () => {
  var today = new Date();
  var endDate =
    String(today.getFullYear()).padStart(2, "0") +
    "-" +
    String(today.getMonth() + 1).padStart(2, "0") +
    "-" +
    String(today.getDate()).padStart(2, "0");

  var startDate =
    String(today.getFullYear()).padStart(2, "0") +
    "-" +
    String(today.getMonth() + 1).padStart(2, "0") +
    "-" +
    String(today.getDate()).padStart(2, "0");

  return [startDate, endDate];
};

export function numFormatter(num: number) {
  if (num > 999 && num < 1000000) {
    return (num / 1000).toFixed(1) + "K";
  } else if (num > 1000000) {
    return (num / 1000000).toFixed(1) + "M";
  } else if (num < 900) {
    return num;
  }
}
export function currencyFormat(num: number, currencySymbol = "NGN") {
  const newNumber = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return `${currencySymbol} ${newNumber}`;
}

export function numberFormat_extra(num: number, currencySymbol = "₦") {
  //const newNumber = parseFloat(num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")).toFixed(2);
  const newNumber = parseFloat(num.toString()).toFixed(2);
  return `${currencySymbol}${newNumber}`;
}

export const getCurrentBusinessKycStep = (
  currentbusiness: any,
  type: string
): number => {
  const getKycType = currentbusiness?.BusinessKyc.find(
    (kyc: any) => kyc.type === type
  );
  if (getKycType) {
    if (getKycType.residentialMeta === null) {
      return 1;
    } else if (getKycType.businessMeta === null) {
      return 2;
    } else if (getKycType.uploadMeta === null) {
      return 3;
    } else {
      return 0;
    }
  } else {
    return 0;
  }
};

export const getDateFromString = (str: string) => {
  const [date, time] = str.split("T");
  return date;
};

export const formatDate = (dateobj: any) => {
  const dateObject = new Date(dateobj);
  const formattedDate = format(dateObject, "dd MMM yyyy");
  return formattedDate;
};
export const formatTime = (dateobj: any) => {
  const dateObject = new Date(dateobj);
  const formattedDate = format(dateObject, "hh:mm a");
  return formattedDate;
};

export const formatDateTime = (dateobj: any) => {
  const dateObject = new Date(dateobj);
  const formattedDate = format(dateObject, "MM/DD/YYYY hh:mm a");
  return formattedDate;
};

export function abbrNum(number: any, decPlaces: number) {
  // 2 decimal places => 100, 3 => 1000, etc
  decPlaces = Math.pow(10, decPlaces);

  // Enumerate number abbreviations
  var abbrev = ["K", "M", "B", "T"];

  // Go through the array backwards, so we do the largest first
  for (var i = abbrev.length - 1; i >= 0; i--) {
    // Convert array index to "1000", "1000000", etc
    var size = Math.pow(10, (i + 1) * 3);

    // If the number is bigger or equal do the abbreviation
    if (size <= number) {
      // Here, we multiply by decPlaces, round, and then divide by decPlaces.
      // This gives us nice rounding to a particular decimal place.
      number = Math.round((number * decPlaces) / size) / decPlaces;

      // Handle special case where we round up to the next abbreviation
      if (number == 1000 && i < abbrev.length - 1) {
        number = 1;
        i++;
      }

      // Add the letter for the abbreviation
      number += abbrev[i];

      // We are done... stop
      break;
    }
  }

  return isNaN(number) ? 0 : number;
}

export const filterRecords = (data: any, search: string) => {
  if (!search) return data;

  const re = RegExp(
    `.*${search
      .toLowerCase()
      .split("")
      .join(".*")}.*`
  );
  const filtered = data.filter((x: any) => x.fullName.toLowerCase().match(re));
  return filtered;
};

export const numberWithCommas = (x: any) => {
  const checkx = x;
  x = x.toString();
  var pattern = /(-?\d+)(\d{3})/;
  while (pattern.test(x)) x = x.replace(pattern, "$1,$2");
  if (isNaN(checkx)) {
    return 0;
  } else {
    return x;
  }
};

export const toTitleCase=(str: string)=>{
  return str.replace(
    /\w\S*/g,
    text => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
  );
}