import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import TextareaAutoSize from 'react-textarea-autosize';

import { StyledTextarea } from './Styles';

// const propTypes = {
//   className: PropTypes.string,
//   invalid: PropTypes.bool,
//   minRows: PropTypes.number,
//   value: PropTypes.string,
//   onChange: PropTypes.func,
// };

type PropT = {
  className: string,
  invalid: boolean,
  minRows: number,
  value: string,
  onChange: (x:any,y:any)=> void,
  [x:string]:any
}

const defaultProps = {
  className: undefined,
  invalid: false,
  minRows: 2,
  value: undefined,
  onChange: () => {},
};

const Textarea = forwardRef(({ className, invalid, onChange, ...textareaProps }:PropT, ref: any) => (
  <StyledTextarea className={className} invalid={invalid}>
    <TextareaAutoSize
      {...textareaProps}
      onChange={(event:any) => onChange(event.target.value, event)}
      inputRef={ref || undefined}
    />
  </StyledTextarea>
));

// Textarea.propTypes = propTypes;
Textarea.defaultProps = defaultProps;

export default Textarea;
