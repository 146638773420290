import { useEffect, useState } from "react";

import {
  ContentHeader,
  ContentRow,
  InnerPanelLayout,
  PanelTitle,
} from "./Style";
import { RootStateOrAny, useSelector } from "react-redux";
import api from "shared/utils/api";
import { currencyFormat100, formatMoney__ } from "shared/utils/moneyFormat";

const data = [
  { name: "Card", value: 1000 },
  { name: "Internet Banking", value: 1000 },
  { name: "EBanking", value: 1000 },
  { name: "QRpay", value: 1000 },
  { name: "Transfer", value: 1000 },
];
const Channels = (props: any) => {
  const [filter, setFilter] = useState<string>("");
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [datas, setData] = useState<any>(data);
  const service = useSelector((store: RootStateOrAny) => store.service);

  const [channelDetails, setChannelDetails] = useState<any>(null)


  const getAmountDetails=async()=>{
    let url=`/services/${service?.id}/channel-summary?filter=${props.filter}`;
    if(startDate){
      url +=`&startDate=${startDate}`;
    }
    if(endDate){
      url +=`&endDate=${endDate}`;
    }
    const details=await api.get(url)
    // console.log("Channels: ", details)
    if(details.status){
      setChannelDetails(details.payload)
    }
  }

  useEffect(()=>{
    if(props.filter){
      setFilter(props.filter)
    }
    if(props.filter){
      setStartDate(props.startDate)
    }
    if(props.filter){
      setEndDate(props.endDate)
    }
  }, [props]);

  useEffect(()=>{
    getAmountDetails();
  }, []);

  const arrayDataItems = channelDetails && channelDetails.map((item: any, id: number) => (
    <ContentRow key={id} removeLastchildBorder={true}>
      <span>{item.channel}</span>
      <span>{formatMoney__(item.value)}</span>
    </ContentRow>
  ));


  return (
    <InnerPanelLayout>
      <>
        <PanelTitle>Channels</PanelTitle>
        <ContentHeader>
          <span>Name</span>
          <span>Total volume</span>
        </ContentHeader>

        {arrayDataItems}
      </>
    </InnerPanelLayout>
  );

};

export default Channels;
