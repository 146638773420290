type PropT = (x: string | number, y?: any[]) => any;
type PropT3 = (x: any, y: any) => any;
type PropT2 = { [x: string]: any };

export const is = {
  comparePassword: (password: string) => (value: string) =>
    !!value && value !== password && `Password must match as above`,
  compareWords: (str: string, label: string) => (value: string) =>
    !!value && value !== str && `${label} must match as above`,

  match: (testFn: any, message = "") => (value: any, fieldValues: any[]) =>
    !testFn(value, fieldValues) && message,

  required: () => (value: any) =>
    isNilOrEmptyString(value) && "This field is required",

  minLength: (min: number) => (value: string) =>
    !!value && value.length < min && `Must be at least ${min} characters`,

  maxLength: (max: number) => (value: string) =>
    !!value && value.length > max && `Must be at most ${max} characters`,

  notEmptyArray: () => (value: any[]) =>
    Array.isArray(value) &&
    value.length === 0 &&
    "Please add at least one item",

  email: () => (value: string) =>
    !!value && !/.*\@.*\.\w{2,3}/g.test(value) && "Must be a valid email",

  url: () => (value: string) =>
    !!value &&
    // eslint-disable-next-line no-useless-escape
    !/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/.test(
      value
    ) &&
    "Must be a valid URL",
};

const isNilOrEmptyString = (value: any) =>
  value === undefined || value === null || value === "";

export const generateErrors = (
  fieldValues: PropT2,
  fieldValidators: PropT2
) => {
  const errors: PropT2 = {};

  Object.entries(fieldValidators).forEach(([fieldName, validators]) => {
    [validators].flat().forEach((validator) => {
      const errorMessage = validator(fieldValues[fieldName], fieldValues);
      if (errorMessage && !errors[fieldName]) {
        errors[fieldName] = errorMessage;
      }
    });
  });
  return errors;
};

export const isEmail = (value: string) => {
  return (
    !!value &&
    !/.*\@.*\.\w{2,3}/g.test(value) &&
    value.indexOf("+") > -1 &&
    "Must be a valid email"
  );
};

export const testMobileNumber = (val: string) => {
  return /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(
    val
  );
};
