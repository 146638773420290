import breakpoints from "shared/utils/breakpoints";
import styled from "styled-components";

export const Activate = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 1px solid #ccc5c5;
  padding: 0px 10px;
  padding-top: 7px;
  border-radius: 5px;
  margin-left: 10px;
  width: 135px;
  &:hover {
    background-color: #e9e9e9;
  }
  p {
    margin-left: 5px;
    margin-bottom: 3px;
    color: #292929;
  }

  @media ${breakpoints.device.max.xs} {
    width: auto;
    p {
      display: none;
    }
  }
`;
