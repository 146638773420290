import styled from "styled-components";

import { AnimationDuration, FadeAnimation } from "shared/animations";
import breakpoints from "shared/utils/breakpoints";
import { color, sizes } from "shared/utils/styles";
import { Button } from "shared/components";
const paddingLeft = sizes.appNavBarLeftWidth + sizes.secondarySideBarWidth + 40;

type nav = {
  navOpened: boolean;
};

type PropT = {
  addMarginTop: boolean;
};

export const KycCheckerCont = styled.div`
  width: 100%;
  height: 100%;
  margin-top: ${sizes.appNavBarLeftWidth - 25}px;
  padding: 28px 0px;
`;

export const CloseContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const CloseBtn = styled.div`
  width: 32px;
  height: 32px;
  background: #ffffff;
  border-radius: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  margin-right: 10px;
  &:hover {
    cursor: pointer;
  }
`;

export const CloseText = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #292929;
`;

export const AlertBox = styled.div`
  margin-top: 40px;
  padding: 20px 0px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

export const AlertTitle = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #828282;
  margin: 20px 0px 10px 0px;
`;

export const AlertText = styled.p`
  font-family: "KumbhSansNormal";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 124.69%;
  text-align: center;
  letter-spacing: -0.02em;
  color: #828282;
  margin: 10px 0px 20px 0px;
`;

export const AlertBtn = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 10px;
  width: 241px;
  height: 54px;
  background: #760000;
  border-radius: 5px;
  color: white;
  &:hover {
    cursor: pointer;
  }
`;

export const Layouts = styled.div`
  z-index: 999;
  padding: 25px 32px 50px ${paddingLeft - 10}px;
  background: ${color.backgroundLightest};
  height: 100vh;
  margin-bottom: -20px;
  @media (max-width: 1100px) {
    padding: 25px 20px 50px ${paddingLeft - 20}px;
  }
  @media (max-width: 999px) {
    padding-left: ${paddingLeft - 20 - sizes.secondarySideBarWidth}px;
  }
  @media ${breakpoints.device.max.md} {
    /* padding-left: 15px; */
    padding: 0px;
  }
`;

export const NavWrapper = styled.div<nav>`
  position: absolute;
  /* display: none; */
  z-index: 10000;
  /* width: 0px; */
  left: ${(props) => (props.navOpened ? 0 : `-284px`)};
  /* right: 0; */
  top: 0;
  /* height: 100%; */
  height: 100vh;
  width: 284px;
  /* width: 100%; */
  -webkit-transition: left 0.2s ease-in-out;
  -moz-transition: left 0.2s ease-in-out;
  -o-transition: left 0.2s ease-in-out;
  transition: left 0.2s ease-in-out;
  background-color: red;
`;

export const NavLeftMenu = styled.div`
  display: none;
  @media ${breakpoints.device.max.md} {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    height: 40px;
    width: 40px;
    padding-top: 3px;
    top: 10px;
    border-radius: 50%;
    z-index: 10000000;
    right: 10px;
    background-color: #760000;
  }
`;

export const ContentArea = styled.div<PropT>`
  margin-top: ${(props) =>
    props.addMarginTop ? "10%" : `${sizes.appNavBarLeftWidth - 25}px`};
  padding: 28px 0px;
  height: 100% !important;
  overflow-y: auto !important;
  animation-name: ${FadeAnimation};
  animation-duration: ${AnimationDuration};
  transition: all ease-in-out 2.8s;

  /* border: 1px solid red; */
  @media ${breakpoints.device.max.md} {
    height: calc(100vh - 64px);
    overflow-y: auto;
    padding: 40px 20px;
  }
`;

export const AlertCont = styled.div`
  width: 100%;
  padding: 10px 2%;
  background-color: rgba(250, 214, 214, 0.55);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

// export const AlertText = styled.p`
//   color: rgba(41, 41, 41, 1);
//   font-weight: 400;
//   font-size: 14px;
//   line-height: 16.41px;
// `;

export const AlertBtnCont = styled.div`
  width: 100%;
`;

export const ActionButton = styled(Button)`
  background-color: rgba(14, 49, 76, 1);
  color: #fff;
  padding: 0 2em;
  width: 240px;
  @media ${breakpoints.device.max.xs} {
    width: 100%;
    margin-top: 5px;
  }
  &:hover {
    cursor: pointer;
  }
`;
